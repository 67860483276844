import { useMachine } from '@xstate/react';
import {
  machine,
  UnsubscribeMachineOptions,
} from '../../machines/UnsubscribeMachine';
import { UnsubscribeView } from './UnsubscribeView';

type UnsubscribeControllerProps = {
  options: UnsubscribeMachineOptions;
};

export function UnsubscribeController({ options }: UnsubscribeControllerProps) {
  const [state, send] = useMachine(machine, options);

  return <UnsubscribeView state={state} send={send} />;
}
