import { Icon } from '../../components/Icon';
import cn from 'classnames';
import { ErrorPage } from '../ErrorPage';
import {
  SubscribeMachineState,
  SubscribeMachineSender,
} from '../../machines/SubscribeMachine';

import styles from './Subsubscribe.module.scss';

interface SubscribeViewProps {
  state: SubscribeMachineState;
  send: SubscribeMachineSender;
}

export function SubscribeView({ state }: SubscribeViewProps) {
  if (state.matches('error')) {
    return <ErrorPage />;
  }

  return (
    <div>
      {state.matches('done') && (
        <div className={cn(styles.container, 'max-w-md')}>
          <Icon name="mail" className={styles.icon} />
          <h1 className={styles.heading}>You’ve Subscribed Successfully!</h1>
          <p className={styles.bodyText}>
            You will now receive our quarterly newsletter.
          </p>
        </div>
      )}
    </div>
  );
}
