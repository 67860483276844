import { useEffect } from 'react';

export function useOnKeyPress(
  key: string,
  shouldHandle: () => boolean,
  onPress: () => void
) {
  useEffect(() => {
    const onKeyDown = (e: KeyboardEvent) => {
      if (e.key === key && shouldHandle()) {
        onPress();
      }
    };

    document.addEventListener('keydown', onKeyDown);
    return () => document.removeEventListener('keydown', onKeyDown);
  }, [key, shouldHandle, onPress]);
}
