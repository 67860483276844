import classNames from 'classnames';
import {
  ResidentMachineState,
  ResidentMachineSender,
} from '../../../machines/Eligibility/ResidentMachine';
import { Button } from '../../Button';
import { Feedback } from '../../Feedback';
import { RadioOption } from '../../RadioOption';
import { Link } from '../../Link';
import { PageHeader } from '../../PageHeader';
import { Form } from '../../Form';
import { FormControls } from '../../FormControls';

import styles from './Resident.module.scss';

interface ResidentViewProps {
  state: ResidentMachineState;
  send: ResidentMachineSender;
}

export function ResidentView({ state, send }: ResidentViewProps) {
  return (
    <Form
      className={styles.form}
      onSubmit={() => {
        send('NEXT');
      }}
    >
      <PageHeader
        heading="Welcome"
        paginationSteps={5}
        paginationActive={1}
        includeBackButton={true}
        backButtonOnClick={() => send('BACK')}
      >
        We have a few questions to make sure you are eligible to apply for
        funding.
      </PageHeader>
      <div className={classNames(styles.formFields, 'max-w-md')}>
        {/*This doesn't exactly fit the FieldSet and I feel like
           altering it to work for this single case doesn't make sense. */}
        <div className={styles.heading}>
          Are you (or the majority of your group) a citizen or Permanent
          Resident of Canada?
        </div>
        <div className={styles.buttons}>
          <RadioOption
            label="Yes"
            checked={state.matches('yes')}
            onChange={() => send('YES')}
          />
          <RadioOption
            label="No"
            checked={state.matches('no.option.selected')}
            onChange={() => send('NO')}
          />
        </div>
      </div>
      <FormControls>
        {state.matches('no') && (
          <Feedback
            title="You or the majority of your group must be a citizen of or Permanent Resident of Canada to be eligible."
            pulse={state.matches('no.highlight.on')}
            type="warning"
          >
            <span>
              Learn more about the{' '}
              <Link type="feedback" href="/requirements">
                requirements and rules
              </Link>
              .
            </span>
          </Feedback>
        )}
        <div className="max-w-md">
          <Button
            variant="primary"
            endIcon="rightChevron"
            label="Next"
            type="submit"
          />
        </div>
      </FormControls>
    </Form>
  );
}
