import { GraphQLClient } from 'graphql-request';
import { DeleteTourDateDocument } from '../../graphql/operations';
import { DeleteTourDate } from '../../events/DeleteTourDate';
import { deleteTourDateResultSchema } from '../../schemas/tourDates/deleteTourDateResultSchema';

export const createDeleteTourDatePromise =
  (client: GraphQLClient) =>
  async (_: unknown, { id }: DeleteTourDate) => {
    const result = await client.request(DeleteTourDateDocument, {
      deleteId: { id },
    });

    return deleteTourDateResultSchema.parse(
      result.deleteTourDateById?.tourDate
    );
  };

export type CreateDeleteTourDatePromise = ReturnType<
  typeof createDeleteTourDatePromise
>;

export type CreateDeleteTourDatePromiseResult = Awaited<
  ReturnType<CreateDeleteTourDatePromise>
>;
