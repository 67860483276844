import { Button } from './Button';

import styles from './ArtistCard.module.scss';

export interface ArtistCardProps {
  name: string;
  linkTo: string;
}

export function ArtistCard({ name, linkTo }: ArtistCardProps) {
  return (
    <div className={styles.card}>
      <div className={styles.row}>
        <div className={styles.col}>
          <div className={styles.title}>
            <h3 className={styles.heading}>{name}</h3>
          </div>
        </div>
        <div className={styles.col}>
          <Button
            variant="secondary"
            label="View"
            size="small"
            linkTo={linkTo}
          />
        </div>
      </div>
    </div>
  );
}
