import type {
  Sender,
  EventFrom,
  MachineOptionsFrom,
  ActorRefFrom,
} from 'xstate';
import { createMachine, assign, sendParent } from 'xstate';
import { StateFrom } from '../../utils/StateFrom';
import { logMachineError } from '../../utils/logError';
import { refreshApplication } from '../../events/RefreshApplication';
import { CreateReopenClaimsPromiseResult } from '../../promises/Admin/createReopenClaimsPromise';
import { CreateUpdateReopenClaimsNotesPromiseResult } from '../../promises/Admin/createUpdateReopenClaimsNotesPromise';

type Events =
  | { type: 'UPDATE_NOTES'; notes: string }
  | { type: 'REOPEN_CLAIMS' }
  | { type: 'SAVE_REOPEN_CLAIMS_NOTES' };

export type Context = {
  applicationId: string;
  notes: string | null;
};

type Services = {
  reopenClaims: {
    data: CreateReopenClaimsPromiseResult;
  };
  saveNotes: {
    data: CreateUpdateReopenClaimsNotesPromiseResult;
  };
};

export const machine = createMachine(
  {
    id: 'reopenClaimsMachine',
    predictableActionArguments: true,
    tsTypes: {} as import('./ReopenClaimsMachine.typegen').Typegen0,
    schema: {
      context: {} as Context,
      events: {} as Events,
      services: {} as Services,
    },
    initial: 'initializing',
    states: {
      initializing: {
        always: [
          { cond: 'isEdit', target: 'editNotesFormOpen' },
          { target: 'reopenFormOpen' },
        ],
      },
      reopenFormOpen: {
        on: {
          REOPEN_CLAIMS: 'reopening',
          UPDATE_NOTES: { actions: 'updateNotes' },
        },
      },
      editNotesFormOpen: {
        on: {
          SAVE_REOPEN_CLAIMS_NOTES: 'savingNotes',
          UPDATE_NOTES: { actions: 'updateNotes' },
        },
      },
      reopening: {
        invoke: {
          id: 'reopenClaims',
          src: 'reopenClaims',
          onDone: {
            actions: ['refreshApplication'],
            target: 'done',
          },
          onError: 'error',
        },
      },
      savingNotes: {
        invoke: {
          id: 'saveNotes',
          src: 'saveNotes',
          onDone: {
            actions: ['refreshApplication'],
            target: 'done',
          },
          onError: 'error',
        },
      },
      done: {
        type: 'final',
      },
      error: { entry: 'logMachineError' },
    },
  },
  {
    guards: {
      isEdit: ({ notes }) => notes !== null,
    },
    actions: {
      logMachineError,
      refreshApplication: sendParent(refreshApplication()),
      updateNotes: assign({
        notes: (_, { notes }) => notes,
      }),
    },
  }
);

type Machine = typeof machine;

export type ReopenClaimsMachineState = StateFrom<Machine>;
export type ReopenClaimsMachineSender = Sender<EventFrom<Machine>>;
export type ReopenClaimsMachineOptions = MachineOptionsFrom<Machine, true> & {
  context: Context;
};
export type ReopenClaimsMachineEvent = EventFrom<Machine>;
export type ReopenClaimsMachineActor = ActorRefFrom<Machine>;
