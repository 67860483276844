import { useActor } from '@xstate/react';
import { SalesMachineActor } from '../../../machines/Eligibility/SalesMachine';
import { SalesView } from './SalesView';

type SalesControllerProps = {
  actor: SalesMachineActor;
};

export function SalesController({ actor }: SalesControllerProps) {
  const [state, send] = useActor(actor);

  return <SalesView state={state} send={send} />;
}
