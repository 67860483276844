import { P, match } from 'ts-pattern';

import { Feedback } from '../Feedback';
import { Link } from '../Link';

interface IneligibilityFeedback {
  round: string;
  aboveSalesRequirement: boolean;
  aboveLifetimeFundingLimit: boolean;
}

export function IneligibilityFeedback({
  round,
  aboveSalesRequirement,
  aboveLifetimeFundingLimit,
}: IneligibilityFeedback) {
  return (
    <Feedback
      icon="xCircle"
      type="warning"
      title={`You are NOT eligible to apply for Starmaker round #${round} funding.`}
    >
      {match([aboveLifetimeFundingLimit, aboveSalesRequirement])
        .with(
          [true, P._],
          () => 'You have exceeded the maximum lifetime funding total.'
        )
        .with(
          [P._, true],
          () => 'You have a release that has sales of over 150,000 units.'
        )
        .otherwise(
          () => 'You have no releases that meet the eligibility requirements.'
        )}
      {aboveLifetimeFundingLimit === false && (
        <p>
          Please visit the <Link href="releases">releases page</Link> to check
          your current releases or to add new ones.
        </p>
      )}
    </Feedback>
  );
}
