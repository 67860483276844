import { useActor } from '@xstate/react';
import { ManagementView } from './ManagementView';
import { ManagementMachineActor } from '../../../machines/RedeemAccountInvitation/ManagementMachine';

export function ManagementController({
  actor,
}: {
  actor: ManagementMachineActor;
}) {
  const [state, send] = useActor(actor);

  return <ManagementView state={state} send={send} />;
}
