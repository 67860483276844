import { match, P } from 'ts-pattern';
import { Tooltip } from './Tooltip';
import { FormFieldProps } from './FormField';
import cn from 'classnames';

import styles from './FormField.module.scss';

type Props = Pick<
  FormFieldProps,
  | 'id'
  | 'htmlFor'
  | 'label'
  | 'tooltipContent'
  | 'tooltipStatus'
  | 'labelSize'
  | 'stretch'
  | 'formFieldStatus'
  | 'className'
>;

export function FormFieldLabel({
  id,
  label,
  tooltipContent,
  tooltipStatus = 'default',
  htmlFor,
  labelSize,
  stretch,
  formFieldStatus = 'default',
  className,
}: Props) {
  // Join the Tooltip with the last word of the label in a non-breaking span so it does
  // not wrap to a newline on its own.
  const labelElementChildren = match({
    label,
    tooltipContent,
  })
    .with(
      { label: P.nullish, tooltipContent: P._, labelWordCount: P._ },
      () => null
    )
    .with({ label: P.string, tooltipContent: P.nullish }, () => label)
    .with(
      { label: P.string, tooltipContent: P.not(P.nullish) },
      ({ label, tooltipContent }) => {
        const words = label.split(' ');
        const lastWord = words.slice(-1);
        const preceedingWords = words.slice(0, -1);
        return (
          <>
            {preceedingWords.join(' ')}
            {preceedingWords.length > 0 ? ' ' : null}
            <span className={styles.noBreak}>
              {lastWord}
              {tooltipContent && (
                <Tooltip icon={'help'} status={tooltipStatus}>
                  {tooltipContent}
                </Tooltip>
              )}
            </span>
          </>
        );
      }
    )
    .otherwise(() => null);
  return (
    <label
      id={id}
      htmlFor={htmlFor}
      className={cn(styles.label, className, {
        [styles.labelLarge]: labelSize === 'large',
        [styles.stretch]: stretch === 'full',
        [styles.text]: formFieldStatus === 'text',
        [styles.error]: formFieldStatus === 'error',
      })}
    >
      {labelElementChildren}
    </label>
  );
}
