import { Storage, createMemoryStorage } from '../../utils/storage';

import { AddArtistState } from './state';

export type AddArtistStorage = Omit<Storage<AddArtistState>, 'add'> & {
  add: (state: AddArtistState, isReplay?: true) => void;
};

const mergeAddArtistStates = (
  newState: AddArtistState,
  oldState: AddArtistState
): AddArtistState => ({
  ...oldState,
  ...newState,
  artist: {
    ...oldState.artist,
    ...newState.artist,
  },
  company: {
    ...oldState.company,
    ...newState.company,
  },
  // Only create the partial object if there is management information otherwise
  // leave the property set to null. The schema that validates the overall state
  // expects this to undefined or fully defined. It would fail when it was just
  // an empty object.
  management:
    oldState.management !== undefined || newState.management !== undefined
      ? {
          ...oldState.management,
          ...newState.management,
        }
      : undefined,
});

const createAddArtistStorage = (
  storage: Storage<AddArtistState>
): AddArtistStorage => {
  const { add, ...rest } = storage;

  return {
    add: (state: AddArtistState, isReplay?: true) => {
      if (isReplay !== true) {
        add(state);
      }
    },
    ...rest,
  };
};

export const createMemoryAddArtistStorage = (initial?: AddArtistState) =>
  createAddArtistStorage(
    createMemoryStorage<AddArtistState>(initial ?? {}, mergeAddArtistStates)
  );
