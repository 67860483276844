import { Helmet } from 'react-helmet';
import errorImage from '../assets/images/not-found.svg';
import { Button } from '../components/Button';

import styles from './NotFound.module.scss';

export function NotFound() {
  return (
    <div className={styles.container}>
      <Helmet>
        <title>Page Not Found | Canadian Starmaker Fund</title>
        <meta name="robots" content="noindex" />
      </Helmet>
      <img
        src={errorImage}
        alt="Cloud with a warning icon"
        className={styles.image}
      />
      <p className={styles.text}>
        Sorry, we couldn’t find the page you were looking for.
      </p>
      <Button linkTo="/" label="Back to Home Page" />
    </div>
  );
}
