import type { ActorRefFrom, EventFrom, Sender, StateFrom } from 'xstate';
import { assign, createMachine } from 'xstate';
import { EligibilityNoticeTypes } from '../graphql/operations';
import { ArtistEligibilityNotice } from '../schemas/artistEligibilityNoticeSchema';
import {
  UpsertEligibilityNoticePromiseResult,
  isSuccess as isUpserted,
} from '../promises/createUpsertEligibilityNoticePromise';
import {
  DeleteEligibilityNoticePromiseResult,
  isSuccess as isDeleted,
} from '../promises/deleteEligibilityNoticePromise';
import { logMachineError } from '../utils/logError';
import { sendParent } from 'xstate/lib/actions';

export type Context = {
  artistId: string;
  notice: ArtistEligibilityNotice;
  adminForm: {
    isOpen: boolean;
    message: string;
    type?: EligibilityNoticeTypes;
  };
};

type SetFormMessageEvent = { type: 'SET_FORM_MESSAGE'; message: string };
type SetFormNoticeTypeEvent = {
  type: 'SET_FORM_NOTICE_TYPE';
  noticeType: EligibilityNoticeTypes;
};

type Events =
  | { type: 'OPEN_FORM' }
  | { type: 'CLOSE_FORM' }
  | { type: 'CANCEL_FORM' }
  | { type: 'SUBMIT_FORM' }
  | { type: 'DELETE_NOTICE' }
  | SetFormMessageEvent
  | SetFormNoticeTypeEvent;

type Services = {
  upsertEligibilityNoticePromise: {
    data: UpsertEligibilityNoticePromiseResult;
  };
  deleteEligibilityNoticePromise: {
    data: DeleteEligibilityNoticePromiseResult;
  };
};

export function createContext(
  artistId: string,
  notice: ArtistEligibilityNotice
): Context {
  return {
    artistId,
    notice,
    adminForm: {
      isOpen: false,
      message: notice?.message ?? '',
      type: notice?.type ?? undefined,
    },
  };
}

const actionableState = {
  on: {
    OPEN_FORM: { actions: 'openForm', target: 'ready' },
    CANCEL_FORM: { actions: 'cancelForm', target: 'ready' },
    SET_FORM_NOTICE_TYPE: { actions: 'setFormNoticeType' },
    SET_FORM_MESSAGE: { actions: 'setFormMessage' },
    SUBMIT_FORM: [
      { cond: 'isMissingInput', target: 'inputError' },
      { target: 'submittingForm' },
    ],
    DELETE_NOTICE: { target: 'deletingNotice' },
  },
};

export const machine = createMachine(
  {
    predictableActionArguments: true,
    tsTypes: {} as import('./EligibilityNoticeMachine.typegen').Typegen0,
    id: 'eligibilityNotice',
    schema: {
      context: {} as Context,
      events: {} as Events,
      services: {} as Services,
    },
    initial: 'ready',
    states: {
      ready: { type: 'atomic', ...actionableState },
      operationError: {
        type: 'atomic',
        ...actionableState,
        entry: 'logMachineError',
      },
      inputError: { type: 'atomic', ...actionableState },
      submittingForm: {
        type: 'atomic',
        invoke: {
          id: 'upsertEligibilityNoticePromise',
          src: 'upsertEligibilityNoticePromise',
          onDone: [
            {
              cond: 'isUpserted',
              actions: 'updateDashboard',
              target: 'ready',
            },
            {
              target: 'operationError',
            },
          ],
          onError: {
            target: 'operationError',
          },
        },
      },
      deletingNotice: {
        type: 'atomic',
        invoke: {
          id: 'deleteEligibilityNoticePromise',
          src: 'deleteEligibilityNoticePromise',
          onDone: [
            {
              cond: 'isDeleted',
              actions: 'updateDashboard',
              target: 'ready',
            },
            {
              target: 'operationError',
            },
          ],
          onError: {
            target: 'operationError',
          },
        },
      },
    },
  },
  {
    actions: {
      logMachineError,
      openForm: assign(({ adminForm }) => ({
        adminForm: { ...adminForm, isOpen: true },
      })),
      cancelForm: assign(({ notice }) => ({
        adminForm: {
          isOpen: false,
          message: notice?.message ?? '',
          type: notice?.type ?? undefined,
        },
      })),
      setFormNoticeType: assign(({ adminForm }, { noticeType }) => ({
        adminForm: { ...adminForm, type: noticeType },
      })),
      setFormMessage: assign(({ adminForm }, { message }) => ({
        adminForm: { ...adminForm, message },
      })),
      updateDashboard: sendParent(({ adminForm }, { type }) => ({
        type: 'UPDATE_ELIGIBILITY_NOTICE',
        notice:
          type === 'done.invoke.upsertEligibilityNoticePromise'
            ? { type: adminForm.type, message: adminForm.message }
            : null,
      })),
    },
    guards: {
      isMissingInput: ({ adminForm }) =>
        !adminForm.type || !adminForm.message?.trim(),
      isUpserted: (_, { data }) => isUpserted(data),
      isDeleted: (_, { data }) => isDeleted(data),
    },
  }
);

export type EligibilityNoticeMachine = typeof machine;
export type EligibilityNoticeMachineState = Omit<
  StateFrom<typeof machine>,
  'missingImplementations'
>;
export type EligibilityNoticeMachineEvents =
  EventFrom<EligibilityNoticeMachine>;
export type EligibilityNoticeMachineSender =
  Sender<EligibilityNoticeMachineEvents>;
export type EligibilityNoticeMachineActor =
  ActorRefFrom<EligibilityNoticeMachine>;
