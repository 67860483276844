import { useMachine } from '@xstate/react';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { getLandingPagePath } from '../utils/getLandingPagePath';
import { useAuth } from '../components/AuthProvider';
import { machine } from '../machines/SignInMachine';
import { Button } from '../components/Button';
import { Loading } from '../components/Loading';
import { TextInput } from '../components/TextInput';
import { createSignInPromise } from '../promises/createSignInPromise';
import { Link } from '../components/Link';
import { Form } from '../components/Form';
import { ErrorFeedback } from '../components/ErrorFeedback';
import { FormField } from '../components/FormField';

import styles from './SignIn.module.scss';

export function SignIn() {
  const navigate = useNavigate();
  const { signIn, artists, roles, isSignedIn, isInitializing } = useAuth();
  const [state, send] = useMachine(machine, {
    actions: {
      signIn: (_, { data }) => {
        if (data.message === 'signed-in') {
          signIn(data.id, data.roles, data.artists);
          navigate(getLandingPagePath(data.artists, data.roles));
        }
      },
    },
    services: {
      createSignInPromise,
    },
  });

  useEffect(() => {
    if (isSignedIn) {
      navigate(getLandingPagePath(artists, roles));
    }
  }, [isSignedIn]);

  const isSigningIn = state.matches('waitingResponse');

  if (isSignedIn || isInitializing) {
    return <Loading />;
  }

  return (
    <div className={styles.wrapper}>
      <Form
        className="max-w-md"
        onSubmit={() => {
          send('SUBMIT');
        }}
      >
        <h1>
          Welcome to
          <br />
          Starmaker
        </h1>
        <p>Create an account to apply for funding.</p>
        {state.matches('ready.username.error.empty') && (
          <ErrorFeedback type="noEmail" />
        )}
        {state.matches('ready.username.error.badFormat') && (
          <ErrorFeedback type="badEmail" />
        )}
        <FormField label="Email">
          <TextInput
            id="email"
            label="Email"
            value={state.context.username}
            error={state.matches('ready.username.error')}
            onChange={(username) => send({ type: 'UPDATE_USERNAME', username })}
          />
        </FormField>
        {state.matches('ready.password.error.empty') && (
          <ErrorFeedback type="noPassword" />
        )}
        <FormField label="Password">
          <TextInput
            id="password"
            label="Password"
            type="password"
            value={state.context.password}
            error={state.matches('ready.password.error')}
            onChange={(password) => send({ type: 'UPDATE_PASSWORD', password })}
          />
        </FormField>
        <div>
          <Link href="/forgot-password">Forgot Password?</Link>
        </div>
        {state.matches('ready.auth.error.credentials') && (
          <ErrorFeedback type="credentials" />
        )}
        {state.matches('ready.auth.error.network') && (
          <ErrorFeedback type="network" />
        )}
        {state.matches('ready.auth.error.internal') && <ErrorFeedback />}
        <Button
          type="submit"
          label={isSigningIn ? 'Signing in...' : 'Sign in'}
          disabled={isSigningIn}
        />
        <span className={styles.or}>or</span>
        <Button
          type="button"
          variant="secondary"
          label="Create Account"
          linkTo="/eligibility"
        />
        <p>
          Are you an artist manager?
          <br />
          Check your email for your invitation.
        </p>
      </Form>
    </div>
  );
}
