import z from 'zod';
import { parseAbsolute } from '@internationalized/date';
import { moneySchema } from '../moneySchema';
import { applicationTypeSchema } from '../application/applicationTypeSchema';
import { applicationStatusSchema } from '../application/applicationStatusSchema';
import { serverDateSchema } from '../dateSchema';
import { serverDateTimeSchema } from '../dateTimeSchema';
import { parseAbsoluteOrNull } from '../../utils/parseAbsoluteOrNull';
import { applicationStageSchema } from '../application/applicationStageSchema';

export const activeApplicationSchema = z
  .object({
    id: z.string(),
    timezone: z.string(),
    createdAt: serverDateTimeSchema.unwrap(),
    submittedAt: serverDateTimeSchema,
    fundingRound: z
      .object({
        id: z.string(),
        title: z.string(),
        timezone: z.string(),
        boardMeetingDate: serverDateSchema,
        claimDeadline: serverDateTimeSchema.unwrap(),
      })
      .transform(({ timezone, claimDeadline, ...rest }) => ({
        claimDeadline: parseAbsolute(claimDeadline, timezone),
        ...rest,
      }))
      .nullable(),
    totalNumberOfTourDates: z.number().int(),
    totalNumberOfFundingRequests: z.number().int(),
    requestedTotal: moneySchema,
    approvedTotal: moneySchema,
    totalPaid: moneySchema,
    type: applicationTypeSchema,
    status: applicationStatusSchema,
    stage: applicationStageSchema,
    claimsResubmissionDeadline: serverDateTimeSchema,
    reopenClaimsNotes: z.string().nullable(),
  })
  .transform(
    ({
      timezone,
      createdAt,
      submittedAt,
      claimsResubmissionDeadline,
      ...rest
    }) => ({
      timezone,
      createdAt: parseAbsolute(createdAt, timezone),
      submittedAt: parseAbsoluteOrNull(submittedAt, timezone),
      claimsResubmissionDeadline: parseAbsoluteOrNull(
        claimsResubmissionDeadline,
        timezone
      ),
      ...rest,
    })
  );

export type ActiveApplication = z.infer<typeof activeApplicationSchema>;
