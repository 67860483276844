import { useActor } from '@xstate/react';
import { AccountsBlockMachineActor } from '../../../machines/components/AccountsBlockMachine';
import { AccountsBlockView } from './AccountsBlockView';
import { useIsAdmin } from '../../../hooks/useIsAdmin';

interface AccountsBlockControllerProps {
  actor: AccountsBlockMachineActor;
}

export function AccountsBlockController({
  actor,
}: AccountsBlockControllerProps) {
  const [state, send] = useActor(actor);
  const isAdmin = useIsAdmin();

  return <AccountsBlockView state={state} send={send} isAdmin={isAdmin} />;
}
