import { signOutResponseSchema } from '../schemas/signOutResponse';

export const createSignOutPromise = async () => {
  try {
    const response = await fetch(`${import.meta.env.VITE_API_BASE}/sign-out`, {
      method: 'POST',
      credentials: 'include',
      headers: { 'Content-Type': 'application/json' },
    });

    const { message } = signOutResponseSchema.parse(await response.json());

    return {
      success: response.ok,
      status: response.status,
      message,
    };
  } catch (e) {
    // Fetch API rejecting the promise means there was a network error.
    // Use `0` as a sentinel value to indicate this.
    return {
      success: false,
      status: 0,
      message: 'network-error' as const,
    };
  }
};

export type CreateSignOutPromise = typeof createSignOutPromise;
export type CreateSignOutPromiseResult = Awaited<
  ReturnType<CreateSignOutPromise>
>;
