import { z } from 'zod';

const completeAddReleaseStateSchema = z.object({
  release: z.object({
    title: z.string(),
    labelId: z.string(),
    otherLabel: z.string().optional(),
    distributorId: z.string(),
    otherDistributor: z.string().optional(),
    date: z.string(),
    numberOfTracks: z.string(),
    numberOfMinutes: z.string(),
    isMajorityEnglish: z.boolean(),
  }),
  sales: z.object({
    genreId: z.string(),
    releasesScanned: z.string(),
    tracksDownloaded: z.string(),
    tracksStreamed: z.string(),
  }),
  master: z.object({
    company: z.string(),
    website: z.string().optional(),
    contact: z.string(),
    address: z.string(),
    inCanada: z.boolean(),
    phoneNumber: z.string(),
    email: z.string(),
    notes: z.string().optional(),
  }),
});

const addReleaseStateSchema = completeAddReleaseStateSchema
  .deepPartial()
  .extend({
    step: z
      .union([
        z.literal('release'),
        z.literal('sales'),
        z.literal('masterRecording'),
      ])
      .optional(),
  });

export type AddReleaseState = z.infer<typeof addReleaseStateSchema>;
export type CompleteAddReleaseState = z.infer<
  typeof completeAddReleaseStateSchema
>;

export const parseAddReleaseState = (data: unknown) =>
  addReleaseStateSchema.parse(data);

export const parseCompleteAddReleaseState = (data: unknown) =>
  completeAddReleaseStateSchema.parseAsync(data);
