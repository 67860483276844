import type {
  Sender,
  EventFrom,
  MachineOptionsFrom,
  ActorRefFrom,
} from 'xstate';
import { assign, createMachine } from 'xstate';
import { StateFrom } from '../../utils/StateFrom';
import { isEmailValid } from '../../utils/isEmailValid';
import { SubscribeToNewsletterPromiseResult } from '../../promises/createSubscribeToNewsletterPromise';

type Events =
  | { type: 'SET_EMAIL'; email: string }
  | { type: 'BLUR_EMAIL' }
  | { type: 'SUBMIT' };

type Context = {
  email: string;
};

type Services = {
  subscribeToNewsletter: {
    data: SubscribeToNewsletterPromiseResult;
  };
};

export const machine = createMachine(
  {
    predictableActionArguments: true,
    tsTypes: {} as import('./NewsletterMachine.typegen').Typegen0,
    schema: {
      context: {} as Context,
      events: {} as Events,
      services: {} as Services,
    },
    context: {
      email: '',
    },
    id: 'form',
    initial: 'email',
    states: {
      email: {
        initial: 'pristine',
        states: {
          pristine: {},
          invalid: {},
          valid: {
            on: {
              SUBMIT: '#form.sending',
            },
          },
        },
        on: {
          SET_EMAIL: {
            actions: 'setEmail',
          },
          BLUR_EMAIL: [
            { cond: 'isEmailInvalid', target: '.invalid' },
            '.valid',
          ],
        },
      },
      sending: {
        invoke: {
          id: 'subscribeToNewsletter',
          src: 'subscribeToNewsletter',
          onDone: 'done',
          onError: 'error',
        },
      },
      error: {},
      done: {},
    },
  },
  {
    guards: {
      isEmailInvalid: ({ email }) => !isEmailValid(email),
    },
    actions: {
      setEmail: assign((_, { email }) => ({
        email,
      })),
    },
  }
);

type Machine = typeof machine;

export type NewsletterMachine = Machine;
export type NewsletterMachineState = StateFrom<Machine>;
export type NewsletterMachineSender = Sender<EventFrom<Machine>>;
export type NewsletterMachineEvent = EventFrom<Machine>;
export type NewsletterMachineOptions = MachineOptionsFrom<Machine, true>;
export type NewsletterMachineActor = ActorRefFrom<NewsletterMachine>;
