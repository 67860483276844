import type {
  Sender,
  EventFrom,
  ActorRefFrom,
  StateMachine,
  BaseActionObject,
  ResolveTypegenMeta,
  MarkAllImplementationsAsProvided,
} from 'xstate';
import { createMachine, assign, spawn } from 'xstate';
import { StateFrom } from '../../utils/StateFrom';
import {
  completeExpenseSchema,
  Expense,
  PartialExpense,
} from '../../schemas/fundingRequests/expenses/expenseSchema';
import {
  machine as ExpenseMachine,
  ExpenseMachineActor,
  ExpenseMachineEvent,
} from './ExpenseMachine';
import { FundingRequest } from '../../schemas/fundingRequests/fundingRequestSchema';
import { LocalDateTime } from '../../schemas/dateTimeSchema';
import { ApplicationStatus } from '../../graphql/operations';
import { MachineOptionsWithContextFrom } from '../../utils/MachineOptionsWithContextFrom';
import { AddExpense } from '../../events/Expense/AddExpense';
import { OpenExpense } from '../../events/Expense/OpenExpense';
import { refreshApplication } from '../../events/RefreshApplication';
import { closeExpense, CloseExpense } from '../../events/Expense/CloseExpense';
import { sendParent } from 'xstate/lib/actions';
import { CreateAddExpensePromiseResult } from '../../promises/FundingRequests/Expenses/createAddExpensePromise';
import { updateExpenses } from '../../events/Expense/UpdateExpenses';
import { Typegen0 } from './ExpenseBlockMachine.typegen';
import { CreateDeleteExpensePromiseResult } from '../../promises/FundingRequests/Expenses/createDeleteExpensePromise';
import { DeleteExpense } from '../../events/Expense/DeleteExpense';
import { logMachineError } from '../../utils/logError';
import {
  createEditContext,
  EditContext,
} from '../../events/Expense/RefreshExpenses';
import { CreateUpdateExpensePromiseResult } from '../../promises/FundingRequests/Expenses/createUpdateExpensePromise';
import { UpdateExpense } from '../../events/Expense/UpdateExpense';

type Events =
  | AddExpense
  | DeleteExpense
  | UpdateExpense
  | OpenExpense
  | CloseExpense;

type ExtendedExpense = {
  expense: Expense;
  open: boolean;
  ref: ExpenseMachineActor;
};

type Services = {
  createAddExpensePromise: {
    data: CreateAddExpensePromiseResult;
  };
  createDeleteExpensePromise: {
    data: CreateDeleteExpensePromiseResult;
  };
  createUpdateExpensePromise: {
    data: CreateUpdateExpensePromiseResult;
  };
};

export type Context = {
  request: FundingRequest;
  extendedExpenses: ExtendedExpense[];
  openExpense: ExpenseMachineActor | null;
  applicationStatus: ApplicationStatus;
  applicationSubmittedAt: LocalDateTime;
  isOpen: boolean;
};

export const createContext = (
  request: FundingRequest,
  applicationStatus: ApplicationStatus,
  isOpen: boolean,
  applicationSubmittedAt: LocalDateTime
): Context => ({
  request,
  extendedExpenses: [],
  openExpense: null,
  applicationStatus,
  applicationSubmittedAt,
  isOpen,
});

const createReplayEvents = (expense: Expense): ExpenseMachineEvent[] => [
  { type: 'FOCUS_VENDOR' },
  { type: 'SET_VENDOR', vendor: expense.vendor },
  { type: 'BLUR_VENDOR' },
  { type: 'FOCUS_COST' },
  { type: 'SET_COST', cost: expense.cost },
  { type: 'BLUR_COST' },
  { type: 'FOCUS_INVOICE_NUMBER' },
  { type: 'SET_INVOICE_NUMBER', invoiceNumber: expense.invoiceNumber },
  { type: 'BLUR_INVOICE_NUMBER' },
  { type: 'FOCUS_DATE' },
  { type: 'SET_DATE', date: expense.date },
  { type: 'BLUR_DATE' },
  { type: 'SET_DESCRIPTION', description: expense.description ?? '' },
];

const createRef = (
  editContext: EditContext | undefined,
  request: FundingRequest,
  expense: PartialExpense,
  applicationSubmittedAt: LocalDateTime
) =>
  spawn(
    ExpenseMachine.withConfig({
      services: {
        validateCompleteExpense: ({ expense }) =>
          completeExpenseSchema.parseAsync(expense),
      },
    }).withContext({
      editContext,
      request,
      expense,
      applicationSubmittedAt,
    })
  );

const createNewExpense = (
  request: FundingRequest,
  applicationSubmittedAt: LocalDateTime
) =>
  createRef(
    undefined,
    request,
    {
      vendor: '',
      cost: '',
      fundingRequestId: request.id,
      invoiceNumber: '',
      date: null,
    },
    applicationSubmittedAt
  );

const createExtendedExpense = (
  request: FundingRequest,
  expense: Expense,
  applicationSubmittedAt: LocalDateTime
): ExtendedExpense => ({
  expense,
  open: false,
  ref: createRef(
    createEditContext(expense),
    request,
    expense,
    applicationSubmittedAt
  ),
});

export const machine = createMachine(
  {
    predictableActionArguments: true,
    tsTypes: {} as import('./ExpenseBlockMachine.typegen').Typegen0,
    schema: {
      context: {} as Context,
      events: {} as Events,
      services: {} as Services,
    },
    initial: 'ready',
    id: 'expenseBlockMachine',
    states: {
      ready: {
        entry: 'createExtendedExpenses',
        initial: 'default',
        states: {
          default: {},
        },
      },
      error: { onEntry: 'logMachineError' },
      add: {
        invoke: {
          id: 'createAddExpensePromise',
          src: 'createAddExpensePromise',
          onDone: {
            target: 'ready',
            actions: ['setRequest', 'updateExpenses', 'refreshApplication'],
          },
          onError: 'error',
        },
      },
      delete: {
        invoke: {
          id: 'createDeleteExpensePromise',
          src: 'createDeleteExpensePromise',
          onDone: {
            target: 'ready',
            actions: ['setRequest', 'updateExpenses', 'refreshApplication'],
          },
          onError: 'error',
        },
      },
      update: {
        invoke: {
          id: 'createUpdateExpensePromise',
          src: 'createUpdateExpensePromise',
          onDone: {
            target: 'ready',
            actions: ['setRequest', 'updateExpenses'],
          },
          onError: 'error',
        },
      },
    },
    on: {
      OPEN_EXPENSE: {
        actions: ['resetExpenseForm', 'openExpense'],
      },
      ADD_EXPENSE: '.add',
      DELETE_EXPENSE: '.delete',
      UPDATE_EXPENSE: '.update',
      CLOSE_EXPENSE: {
        actions: ['closeExpense', 'sendCloseToCard'],
      },
    },
  },
  {
    actions: {
      logMachineError,
      updateExpenses: sendParent(({ request }) => updateExpenses(request)),
      setRequest: assign((_, { data }) => ({
        request: data.fundingRequest,
      })),
      refreshApplication: sendParent(refreshApplication()),
      createExtendedExpenses: assign(({ request, applicationSubmittedAt }) => ({
        extendedExpenses: request.expenses.map((expense) =>
          createExtendedExpense(request, expense, applicationSubmittedAt)
        ),
      })),
      openExpense: assign((context, { id }) => ({
        extendedExpenses: context.extendedExpenses.map((exExpense) => ({
          ...exExpense,
          open: exExpense.expense.id === id ? true : exExpense.open,
        })),
        openExpense:
          id === undefined
            ? createNewExpense(context.request, context.applicationSubmittedAt)
            : context.openExpense,
      })),
      sendCloseToCard: sendParent(closeExpense()),
      closeExpense: assign(({ extendedExpenses }, { id }) => ({
        openExpense: null,
        extendedExpenses: extendedExpenses.map((exExpense) => ({
          ...exExpense,
          open: exExpense.expense.id === id ? false : exExpense.open,
        })),
      })),
      resetExpenseForm: ({ extendedExpenses }, { id }) => {
        extendedExpenses
          .filter(({ expense }) => expense.id === id)
          .forEach(({ expense, ref }) => {
            createReplayEvents(expense).forEach((event) => ref.send(event));
          });
      },
    },
  }
);

type Machine = typeof machine;

export type ExpenseBlockMachine = Machine;
export type ExpenseBlockMachineState = StateFrom<Machine>;
export type ExpenseBlockMachineSender = Sender<EventFrom<Machine>>;
export type ExpenseBlockMachineOptions = MachineOptionsWithContextFrom<Machine>;
export type ExpenseBlockMachineEvent = EventFrom<Machine>;
export type ExpenseBlockMachineActor = ActorRefFrom<Machine>;
export type ExpenseBlockMachineWithImplementations = StateMachine<
  Context,
  any,
  Events,
  {
    value: any;
    context: Context;
  },
  BaseActionObject,
  Services,
  | MarkAllImplementationsAsProvided<
      ResolveTypegenMeta<Typegen0, Events, BaseActionObject, Services>
    >
  | ResolveTypegenMeta<Typegen0, Events, BaseActionObject, Services>
>;
