import { ApplicationType } from '../graphql/operations';

export function getFundingRequestLabels(applicationType: ApplicationType) {
  return applicationType === ApplicationType.Orion
    ? {
        title: 'Funding Requests',
        buttonText: 'Funding Request',
        iconName: 'dollarSign' as const,
      }
    : {
        title: 'Digital Content',
        buttonText: 'Digital Content Request',
        iconName: 'radio' as const,
      };
}
