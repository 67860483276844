import { match, P } from 'ts-pattern';
import {
  BoardMeetingMachineSender,
  BoardMeetingMachineState,
} from '../../machines/BoardMeeting/BoardMeetingMachine';
import { Loading } from '../../components/Loading';
import { ErrorPage } from '../ErrorPage';
import { NoMeetings } from './NoMeetings';
import { BoardMeetingDashboard } from './BoardMeetingDashboard';

interface BoardMeetingViewProps {
  state: BoardMeetingMachineState;
  send: BoardMeetingMachineSender;
}

export function BoardMeetingView({ state, send }: BoardMeetingViewProps) {
  const { round, nextMeetingDate } = state.context;

  return match({ state, round })
    .when(
      () => state.matches('loading'),
      () => <Loading />
    )
    .when(
      () => state.matches('error'),
      () => <ErrorPage />
    )
    .with({ round: P.not(P.nullish) }, ({ round }) => (
      <BoardMeetingDashboard round={round} state={state} send={send} />
    ))
    .otherwise(() => <NoMeetings nextMeetingDate={nextMeetingDate} />);
}
