import { GraphQLClient } from 'graphql-request';
import {
  GetAdminBoardMeetingContextDocument,
  GetOpenBoardMeetingContextDocument,
} from '../graphql/operations';
import { boardMeetingContextQuerySchema } from '../schemas/boardMeetingContextQuerySchema';

export const createGetBoardMeetingContextPromise =
  (client: GraphQLClient, isAdmin: boolean, roundTitle?: string) =>
  async () => {
    const result =
      isAdmin && roundTitle
        ? await client.request(GetAdminBoardMeetingContextDocument, {
            roundTitle,
          })
        : await client.request(GetOpenBoardMeetingContextDocument);

    return boardMeetingContextQuerySchema.parse({
      ...result,
      pageBlocks: result.round?.pageBlocks ?? [],
    });
  };

export type GetBoardMeetingContextPromise = ReturnType<
  typeof createGetBoardMeetingContextPromise
>;
export type GetBoardMeetingContextPromiseResult = NonNullable<
  Awaited<ReturnType<GetBoardMeetingContextPromise>>
>;
