import { CSVLink } from 'react-csv';
import { GenericPreview } from '../components/GenericPreview';
import usePromise from 'react-use-promise';
import { useGraphQLClient } from '../components/GraphQLClientProvider';
import { Loading } from '../components/Loading';
import { withDelay } from '../utils/withDelay';
import { withErrorLogging } from '../utils/withErrorLogging';
import { ErrorPage } from './ErrorPage';
import { createGetReportsPromise } from '../promises/getReports';
import { IndexPage } from '../components/IndexPage';
import { formatCalendarDate } from '../utils/formatCalendarDate';

import styles from './Reports.module.scss';

export function Reports() {
  const client = useGraphQLClient();
  const [result, , state] = usePromise(
    withDelay(withErrorLogging(createGetReportsPromise(client))),
    []
  );

  if (state === 'pending') {
    return <Loading />;
  }

  if (state === 'rejected') {
    return <ErrorPage />;
  }

  return (
    <IndexPage
      heading={
        <>
          <span>Annual Reports </span>
          <span className={styles.fiscalYear}>
            for Fiscal Year Ending {formatCalendarDate(result.fiscalYearEnd)}
          </span>
        </>
      }
      breadcrumbs={[{ text: 'Admin Dashboard', to: '/admin' }]}
    >
      <div className={styles.list}>
        <CSVLink
          className={styles.csvLink}
          filename={`${result.newArtists.name}-${result.newArtists.params.year}.csv`}
          data={[result.newArtists.headers, ...result.newArtists.rows]}
        >
          <GenericPreview
            title="New Artists To Canadian Starmaker Fund"
            iconName="download"
            iconAriaLabel="download"
            note="CSV File"
          />
        </CSVLink>
        <CSVLink
          className={styles.csvLink}
          filename={`${result.submitted.name}-${result.submitted.params.year}.csv`}
          data={[result.submitted.headers, ...result.submitted.rows]}
        >
          <GenericPreview
            title="Applications Submitted vs. Applications Approved"
            iconName="download"
            iconAriaLabel="download"
            note="CSV File"
          />
        </CSVLink>
        <CSVLink
          className={styles.csvLink}
          filename={`${result.genre.name}-${result.genre.params.year}.csv`}
          data={[result.genre.headers, ...result.genre.rows]}
        >
          <GenericPreview
            title="Allocation Of Funding By Genre"
            iconName="download"
            iconAriaLabel="download"
            note="CSV File"
          />
        </CSVLink>
        <CSVLink
          className={styles.csvLink}
          filename={`${result.program.name}-${result.program.params.year}.csv`}
          data={[result.program.headers, ...result.program.rows]}
        >
          <GenericPreview
            title="Allocation Of Funding By Program"
            iconName="download"
            iconAriaLabel="download"
            note="CSV File"
          />
        </CSVLink>
        <CSVLink
          className={styles.csvLink}
          filename={`${result.province.name}-${result.province.params.year}.csv`}
          data={[result.province.headers, ...result.province.rows]}
        >
          <GenericPreview
            title="Allocation Of Funding By Province"
            iconName="download"
            iconAriaLabel="download"
            note="CSV File"
          />
        </CSVLink>
      </div>
    </IndexPage>
  );
}
