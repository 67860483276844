import type {
  Sender,
  EventFrom,
  MachineOptionsFrom,
  ActorRefFrom,
} from 'xstate';
import { createMachine, assign, spawn, sendParent } from 'xstate';
import { pure, sendTo } from 'xstate/lib/actions';
import { CalendarDate, ZonedDateTime } from '@internationalized/date';

// Utils
import { StateFrom } from '../../utils/StateFrom';
import { logMachineError } from '../../utils/logError';
import { scrollElementIntoView } from '../../utils/scrollElementIntoView';

// Machines, actors, events
import {
  machine as FundingRequestFormMachine,
  FundingRequestFormMachineActor,
  FundingRequestFormMachineEvent,
} from './FundingRequestFormMachine';
import {
  machine as FundingRequestCardMachine,
  FundingRequestCardMachineActor,
  createContext as createFundingRequestCardMachineContext,
} from './FundingRequestCardMachine';
import {
  ExpenseBlockMachineWithImplementations,
  createContext as createExpenseBlockContext,
} from '../components/ExpenseBlockMachine';
import {
  SubmittedFundingRequestCardMachineActor,
  machine as SubmittedFundingRequestCardMachine,
} from './SubmittedFundingRequestCardMachine';

import {
  refreshApplication,
  RefreshApplicationEvent,
} from '../../events/RefreshApplication';
import { AddFundingRequest } from '../../events/AddFundingRequest';
import {
  UpdateAdminFundingRequest,
  UpdateFundingRequest,
} from '../../events/UpdateFundingRequest';
import { DeleteFundingRequest } from '../../events/DeleteFundingRequest';
import {
  EditContext,
  createEditContext,
  refreshFundingRequestTotals,
} from '../../events/RefreshFundingRequestTotals';
import { CloseFundingRequest } from '../../events/CloseFundingRequest';
import { CancelFundingRequest } from '../../events/CancelFundingRequest';
import { UpdateFundingRequests } from '../../events/UpdateFundingRequests';
import { UpdateExpenses } from '../../events/Expense/UpdateExpenses';

// Schemas, schema-based types
import {
  AdminFundingRequest,
  FundingRequest,
  PartialFundingRequest,
  completeFundingRequestSchema,
} from '../../schemas/fundingRequests/fundingRequestSchema';
import { LocalDateTime } from '../../schemas/dateTimeSchema';

// GraphQL types
import {
  ApplicationStage,
  ApplicationStatus,
  ApplicationType,
} from '../../graphql/operations';

// Promise result types
import { CreateAddFundingRequestPromiseResult } from '../../promises/FundingRequests/createAddFundingRequestPromise';
import { CreateDeleteFundingRequestPromiseResult } from '../../promises/FundingRequests/createDeleteFundingRequest';
import { CreateUpdateFundingRequestPromiseResult } from '../../promises/FundingRequests/createUpdateFundingRequestPromise';
import { CreateCancelFundingRequestPromiseResult } from '../../promises/FundingRequests/createCancelFundingRequestPromise';

type Events =
  | AddFundingRequest
  | UpdateFundingRequest
  | DeleteFundingRequest
  | CloseFundingRequest
  | CancelFundingRequest
  | UpdateAdminFundingRequest
  | UpdateFundingRequests
  | {
      type: 'OPEN_REQUEST';
      id?: FundingRequest['id'];
    }
  | RefreshApplicationEvent
  | UpdateExpenses;

export type ExtendedFundingRequest = {
  request: FundingRequest;
  isOpen: boolean;
  ref: FundingRequestFormMachineActor;
  cardRef: FundingRequestCardMachineActor;
  submittedCardRef: SubmittedFundingRequestCardMachineActor;
};

export type Context = {
  requests: FundingRequest[];
  extendedRequests: ExtendedFundingRequest[];
  openRequest: FundingRequestFormMachineActor | null;
  applicationId: string;
  presentCutoffDate: CalendarDate;
  futureCutoffDate: CalendarDate | undefined;
  year: number;
  maximumYearlyTotalForFundingRequests: number;
  remainingYearlyTotalForFundingRequests: number;
  budgetForFundingRequests: number;
  submittedAt: ZonedDateTime | null;
  claimsSubmittedAt: ZonedDateTime | null;
  stage: ApplicationStage;
  applicationType: ApplicationType;
  applicationStatus: ApplicationStatus;
  isAdmin: boolean;
  isBoard: boolean;
  expenseBlockMachine: ExpenseBlockMachineWithImplementations;
};

type Services = {
  addFundingRequest: {
    data: CreateAddFundingRequestPromiseResult;
  };
  deleteFundingRequest: {
    data: CreateDeleteFundingRequestPromiseResult;
  };
  updateFundingRequest: {
    data: CreateUpdateFundingRequestPromiseResult;
  };
  cancelFundingRequest: {
    data: CreateCancelFundingRequestPromiseResult;
  };
};

const createReplayEvents = (
  request: FundingRequest
): FundingRequestFormMachineEvent[] => [
  { type: 'FOCUS_FUNDING_TYPE' },
  { type: 'SET_FUNDING_TYPE', fundingType: request.fundingType },
  { type: 'BLUR_FUNDING_TYPE' },
  { type: 'FOCUS_COST' },
  { type: 'SET_COST', cost: request.cost },
  { type: 'BLUR_COST' },
  { type: 'FOCUS_DATE' },
  { type: 'SET_DATE', date: request.date },
  { type: 'SET_STRATEGY', strategy: request.strategy },
  ...((request.alternate
    ? [
        { type: 'FOCUS_REASON' },
        { type: 'SET_REASON', reason: request.reason },
        { type: 'BLUR_REASON' },
      ]
    : []) satisfies FundingRequestFormMachineEvent[]),
];

export const createContext = (
  applicationId: string,
  applicationType: ApplicationType,
  applicationStatus: ApplicationStatus,
  stage: ApplicationStage,
  requests: FundingRequest[],
  year: number,
  maximumYearlyTotalForFundingRequests: number,
  remainingYearlyTotalForFundingRequests: number,
  budgetForFundingRequests: number,
  submittedAt: ZonedDateTime | null,
  claimsSubmittedAt: ZonedDateTime | null,
  presentCutoffDate: CalendarDate,
  futureCutoffDate: CalendarDate | undefined,
  isAdmin: boolean,
  isBoard: boolean,
  expenseBlockMachine: ExpenseBlockMachineWithImplementations
): Context => ({
  applicationId,
  applicationType,
  applicationStatus,
  stage,
  requests,
  year,
  maximumYearlyTotalForFundingRequests,
  remainingYearlyTotalForFundingRequests,
  budgetForFundingRequests,
  submittedAt,
  claimsSubmittedAt,
  extendedRequests: [],
  openRequest: null,
  presentCutoffDate,
  futureCutoffDate,
  isAdmin,
  isBoard,
  expenseBlockMachine,
});

const createRef = (
  editContext: EditContext | undefined,
  request: PartialFundingRequest,
  year: number,
  budgetForFundingRequests: number,
  applicationType: ApplicationType,
  applicationStatus: ApplicationStatus,
  presentCutoffDate: CalendarDate,
  futureCutoffDate: CalendarDate | undefined
) =>
  spawn(
    FundingRequestFormMachine.withConfig({
      services: {
        validateFundingRequest: ({ request }) =>
          completeFundingRequestSchema.parseAsync(request),
      },
    }).withContext({
      editContext,
      request,
      year,
      budgetForFundingRequests,
      presentCutoffDate,
      futureCutoffDate,
      applicationType,
      alternate: applicationStatus === ApplicationStatus.Approved,
    })
  );

const createCardRef = (
  request: FundingRequest,
  adminRequest: AdminFundingRequest,
  isAdmin: boolean,
  isBoard: boolean,
  submittedAt: ZonedDateTime | null,
  claimsSubmittedAt: ZonedDateTime | null,
  stage: ApplicationStage,
  presentCutoffDate: CalendarDate,
  futureCutoffDate: CalendarDate | undefined,
  applicationStatus: ApplicationStatus,
  expenseBlockMachine: ExpenseBlockMachineWithImplementations
) => {
  return spawn(
    FundingRequestCardMachine.withContext(
      createFundingRequestCardMachineContext(
        request,
        adminRequest,
        isAdmin,
        isBoard,
        submittedAt !== null,
        claimsSubmittedAt !== null,
        stage,
        presentCutoffDate,
        futureCutoffDate,
        applicationStatus,
        expenseBlockMachine,
        submittedAt
      )
    )
  );
};

const createSubmittedCardRef = (
  request: FundingRequest,
  applicationStatus: ApplicationStatus,
  applicationSubmittedAt: LocalDateTime,
  expenseBlockMachine: ExpenseBlockMachineWithImplementations
) => {
  return spawn(
    SubmittedFundingRequestCardMachine.withContext({
      request,
      applicationStatus,
      expenseBlockRef: spawn(
        expenseBlockMachine.withContext(
          createExpenseBlockContext(
            request,
            applicationStatus,
            true,
            applicationSubmittedAt
          )
        )
      ),
    })
  );
};

const createNewRequest = (
  applicationId: string,
  year: number,
  budgetForFundingRequests: number,
  applicationType: ApplicationType,
  applicationStatus: ApplicationStatus,
  presentCutoffDate: CalendarDate,
  futureCutoffDate: CalendarDate | undefined
) =>
  createRef(
    undefined,
    {
      applicationId,
      cost: '',
      date: null,
      fundingType: '',
      strategy: '',
      reason: '',
    },
    year,
    budgetForFundingRequests,
    applicationType,
    applicationStatus,
    presentCutoffDate,
    futureCutoffDate
  );

const createExtendedRequest = (
  request: FundingRequest,
  year: number,
  budgetForFundingRequests: number,
  applicationType: ApplicationType,
  applicationStatus: ApplicationStatus,
  stage: ApplicationStage,
  presentCutoffDate: CalendarDate,
  futureCutoffDate: CalendarDate | undefined,
  isAdmin: boolean,
  isBoard: boolean,
  submittedAt: ZonedDateTime | null,
  claimsSubmittedAt: ZonedDateTime | null,
  expenseBlockMachine: ExpenseBlockMachineWithImplementations
): ExtendedFundingRequest => ({
  request,
  isOpen: false,
  ref: createRef(
    createEditContext(request),
    {
      applicationId: request.applicationId,
      cost: '',
      date: null,
      fundingType: '',
      strategy: '',
      reason: '',
    },
    year,
    budgetForFundingRequests,
    applicationType,
    applicationStatus,
    presentCutoffDate,
    futureCutoffDate
  ),
  cardRef: createCardRef(
    request,
    {
      approvedCostOverride: request.amountApproved,
      status: request.status,
    },
    isAdmin,
    isBoard,
    submittedAt,
    claimsSubmittedAt,
    stage,
    presentCutoffDate,
    futureCutoffDate,
    applicationStatus,
    expenseBlockMachine
  ),
  submittedCardRef: createSubmittedCardRef(
    request,
    applicationStatus,
    submittedAt,
    expenseBlockMachine
  ),
});

export const machine = createMachine(
  {
    predictableActionArguments: true,
    tsTypes: {} as import('./FundingRequestsBlockMachine.typegen').Typegen0,
    schema: {
      context: {} as Context,
      events: {} as Events,
      services: {} as Services,
    },
    initial: 'ready',
    id: 'form',
    states: {
      ready: {
        entry: 'createExtendedRequests',
        initial: 'default',
        states: {
          default: {},
          error: { entry: 'logMachineError' },
          save: {
            invoke: {
              id: 'addFundingRequest',
              src: 'addFundingRequest',
              onDone: {
                actions: [
                  'addRequest',
                  'sendRefreshFundingRequestTotals',
                  'sendRefreshApplication',
                  'scrollIntoView',
                ],
                target: 'default',
              },
              onError: 'error',
            },
          },
          delete: {
            invoke: {
              id: 'deleteFundingRequest',
              src: 'deleteFundingRequest',
              onDone: {
                actions: [
                  'deleteRequest',
                  'sendRefreshFundingRequestTotals',
                  'sendRefreshApplication',
                ],
                target: 'default',
              },
              onError: 'error',
            },
          },
          update: {
            invoke: {
              id: 'updateFundingRequest',
              src: 'updateFundingRequest',
              onDone: {
                actions: [
                  'updateRequest',
                  'sendRefreshFundingRequestTotals',
                  'sendRefreshApplication',
                  'scrollIntoView',
                ],
                target: 'default',
              },
              onError: 'error',
            },
          },
          cancel: {
            invoke: {
              id: 'cancelFundingRequest',
              src: 'cancelFundingRequest',
              onDone: {
                actions: [
                  'cancelFundingRequest',
                  'sendRefreshFundingRequestTotals',
                  'sendRefreshApplication',
                ],
                target: 'default',
              },
              onError: 'error',
            },
          },
        },
        on: {
          ADD_REQUEST: '.save',
          DELETE_REQUEST: '.delete',
          OPEN_REQUEST: {
            actions: ['openRequest', 'resetRequestForm'],
          },
          CLOSE_REQUEST: {
            actions: ['closeRequest', 'scrollIntoView'],
            target: '.default',
          },
          UPDATE_REQUEST: '.update',
          UPDATE_REQUESTS: {
            actions: ['updateRequests', 'sendRefreshFundingRequests'],
          },
          UPDATE_ADMIN_REQUEST: '.update',
          CANCEL_REQUEST: '.cancel',
          REFRESH_APPLICATION: {
            actions: 'sendRefreshApplication',
          },
          UPDATE_EXPENSES: {
            actions: 'updateExpenses',
          },
        },
      },
    },
  },
  {
    actions: {
      logMachineError,
      addRequest: assign(
        (
          {
            requests,
            extendedRequests,
            applicationType,
            applicationStatus,
            stage,
            presentCutoffDate,
            futureCutoffDate,
            isAdmin,
            isBoard,
            submittedAt,
            claimsSubmittedAt,
            expenseBlockMachine,
          },
          {
            data: {
              application: { year, budgetForFundingRequests },
              ...request
            },
          }
        ) => ({
          requests: [...requests, request].sort(
            ({ date: dateA }, { date: dateB }) => dateA.compare(dateB)
          ),
          extendedRequests: [
            ...extendedRequests,
            createExtendedRequest(
              request,
              year,
              budgetForFundingRequests,
              applicationType,
              applicationStatus,
              stage,
              presentCutoffDate,
              futureCutoffDate,
              isAdmin,
              isBoard,
              submittedAt,
              claimsSubmittedAt,
              expenseBlockMachine
            ),
          ].sort(({ request: a }, { request: b }) => a.date.compare(b.date)),
          year,
          budgetForFundingRequests,
          openRequest: null,
        })
      ),
      closeRequest: assign(({ extendedRequests, openRequest }, { id }) => ({
        extendedRequests: extendedRequests.map((entry) => ({
          ...entry,
          isOpen: entry.request.id === id ? false : entry.isOpen,
        })),
        openRequest: id === undefined ? null : openRequest,
      })),
      deleteRequest: assign(
        (
          { extendedRequests },
          { data: { id, year, budgetForFundingRequests } }
        ) => ({
          extendedRequests: extendedRequests.filter(
            (exRequest) => exRequest.request.id !== id
          ),
          year,
          budgetForFundingRequests,
        })
      ),
      updateExpenses: assign((context, event) => ({
        requests: context.requests.map((request) =>
          request.id === event.request.id ? event.request : request
        ),
        extendedRequests: context.extendedRequests.map((extendedRequest) =>
          extendedRequest.request.id === event.request.id
            ? {
                ...extendedRequest,
                request: event.request,
                cardRef: createCardRef(
                  event.request,
                  {
                    approvedCostOverride: event.request.amountApproved,
                    status: event.request.status,
                  },
                  context.isAdmin,
                  context.isBoard,
                  context.submittedAt,
                  context.claimsSubmittedAt,
                  context.stage,
                  context.presentCutoffDate,
                  context.futureCutoffDate,
                  context.applicationStatus,
                  context.expenseBlockMachine
                ),
                submittedCardRef: createSubmittedCardRef(
                  event.request,
                  context.applicationStatus,
                  context.submittedAt,
                  context.expenseBlockMachine
                ),
              }
            : extendedRequest
        ),
      })),
      updateRequest: assign(
        (
          context,
          {
            data: {
              application: { year, budgetForFundingRequests },
              ...data
            },
          }
        ) => ({
          requests: context.requests.map((request) =>
            request.id === data.id ? data : request
          ),
          extendedRequests: context.extendedRequests.map((extendedRequest) =>
            extendedRequest.request.id === data.id
              ? {
                  ...extendedRequest,
                  request: data,
                  isOpen: false,
                  cardRef: createCardRef(
                    data,
                    {
                      approvedCostOverride: data.amountApproved,
                      status: data.status,
                    },
                    context.isAdmin,
                    context.isBoard,
                    context.submittedAt,
                    context.claimsSubmittedAt,
                    context.stage,
                    context.presentCutoffDate,
                    context.futureCutoffDate,
                    context.applicationStatus,
                    context.expenseBlockMachine
                  ),
                  submittedCardRef: createSubmittedCardRef(
                    data,
                    context.applicationStatus,
                    context.submittedAt,
                    context.expenseBlockMachine
                  ),
                }
              : extendedRequest
          ),
          year,
          budgetForFundingRequests,
        })
      ),
      sendRefreshFundingRequests: pure(
        (
          {
            extendedRequests,
            isAdmin,
            submittedAt,
            presentCutoffDate,
            futureCutoffDate,
            applicationStatus,
          },
          { requests }
        ) =>
          extendedRequests
            .reduce<
              Array<{
                cardRef: FundingRequestCardMachineActor;
                request: FundingRequest;
              }>
            >((acc, { cardRef, request: { id: findId } }) => {
              const request = requests.find(({ id }) => id === findId);

              return request !== undefined
                ? [...acc, { cardRef, request }]
                : acc;
            }, [])
            .map(({ cardRef, request }) =>
              sendTo(cardRef, {
                type: 'UPDATE_CONTEXT',
                context: {
                  request,
                  isAdmin,
                  isSubmitted: submittedAt !== null,
                  presentCutoffDate,
                  futureCutoffDate,
                  applicationStatus,
                },
              })
            )
      ),
      updateRequests: assign(
        (
          context,
          {
            requests,
            maximumYearlyTotalForFundingRequests,
            remainingYearlyTotalForFundingRequests,
            stage,
          }
        ) => ({
          requests: context.requests.map(
            (request) => requests.find(({ id }) => id === request.id) ?? request
          ),
          extendedRequests: context.extendedRequests.map((extendedRequest) => {
            const request = requests.find(
              ({ id }) => id === extendedRequest.request.id
            );

            return request !== undefined
              ? {
                  ...extendedRequest,
                  cardRef: extendedRequest.cardRef,
                  submittedCardRef: createSubmittedCardRef(
                    request,
                    context.applicationStatus,
                    context.submittedAt,
                    context.expenseBlockMachine
                  ),
                }
              : extendedRequest;
          }),
          maximumYearlyTotalForFundingRequests,
          remainingYearlyTotalForFundingRequests,
          stage,
        })
      ),
      cancelFundingRequest: assign(
        (
          {
            requests,
            extendedRequests,
            applicationType,
            presentCutoffDate,
            futureCutoffDate,
            isAdmin,
            isBoard,
            submittedAt,
            claimsSubmittedAt,
            applicationStatus,
            stage,
            expenseBlockMachine,
          },
          {
            data: {
              fundingRequest: {
                application: { year, budgetForFundingRequests },
                ...data
              },
            },
          }
        ) => ({
          requests: requests.map((request) =>
            request.id === data.id ? data : request
          ),
          extendedTourDates: extendedRequests.map((extendedRequest) =>
            extendedRequest.request.id === data.id
              ? createExtendedRequest(
                  data,
                  year,
                  budgetForFundingRequests,
                  applicationType,
                  applicationStatus,
                  stage,
                  presentCutoffDate,
                  futureCutoffDate,
                  isAdmin,
                  isBoard,
                  submittedAt,
                  claimsSubmittedAt,
                  expenseBlockMachine
                )
              : extendedRequest
          ),
          year,
          budgetForFundingRequests,
        })
      ),
      createExtendedRequests: assign(
        ({
          requests,
          year,
          budgetForFundingRequests,
          applicationType,
          applicationStatus,
          stage,
          presentCutoffDate,
          futureCutoffDate,
          isAdmin,
          isBoard,
          submittedAt,
          claimsSubmittedAt,
          expenseBlockMachine,
        }) => ({
          extendedRequests: requests.map((request) =>
            createExtendedRequest(
              request,
              year,
              budgetForFundingRequests,
              applicationType,
              applicationStatus,
              stage,
              presentCutoffDate,
              futureCutoffDate,
              isAdmin,
              isBoard,
              submittedAt,
              claimsSubmittedAt,
              expenseBlockMachine
            )
          ),
        })
      ),
      resetRequestForm: ({ extendedRequests }, { id }) => {
        extendedRequests
          .filter(({ request }) => request.id === id)
          .forEach(({ request, ref }) => {
            createReplayEvents(request).forEach((event) => ref.send(event));
          });
      },
      openRequest: assign(
        (
          {
            extendedRequests,
            applicationId,
            openRequest,
            year,
            budgetForFundingRequests,
            applicationType,
            applicationStatus,
            presentCutoffDate,
            futureCutoffDate,
          },
          { id }
        ) => ({
          extendedRequests: extendedRequests.map((entry) => ({
            ...entry,
            isOpen: entry.request.id === id ? true : entry.isOpen,
          })),
          openRequest:
            id === undefined
              ? createNewRequest(
                  applicationId,
                  year,
                  budgetForFundingRequests,
                  applicationType,
                  applicationStatus,
                  presentCutoffDate,
                  futureCutoffDate
                )
              : openRequest,
        })
      ),
      sendRefreshFundingRequestTotals: pure(
        ({ extendedRequests, year, budgetForFundingRequests, openRequest }) =>
          extendedRequests
            .map(
              ({
                ref,
                request,
              }): {
                ref: FundingRequestFormMachineActor;
                request?: FundingRequest;
              } => ({
                ref,
                request,
              })
            )
            // Ensure we send to the add machine as well.
            .concat(
              openRequest !== null
                ? [{ ref: openRequest, request: undefined }]
                : []
            )
            .map(({ ref, request }) =>
              sendTo(
                ref,
                refreshFundingRequestTotals(
                  year,
                  budgetForFundingRequests,
                  createEditContext(request)
                )
              )
            )
      ),
      sendRefreshApplication: sendParent(refreshApplication()),
      scrollIntoView: (_, event) => {
        if (event.type === 'CLOSE_REQUEST') {
          scrollElementIntoView(event.id ?? 'addFundingRequestButton');
        } else {
          scrollElementIntoView(event.data.id, true);
        }
      },
    },
  }
);

type Machine = typeof machine;

export type FundingRequestsBlockMachineState = StateFrom<Machine>;
export type FundingRequestsBlockMachineSender = Sender<EventFrom<Machine>>;
export type FundingRequestsBlockMachineOptions = MachineOptionsFrom<
  Machine,
  true
> & { context: Context };
export type FundingRequestsBlockMachineEvent = EventFrom<Machine>;
export type FundingRequestsBlockMachineActor = ActorRefFrom<Machine>;
