import { GraphQLClient } from 'graphql-request';
import {
  SearchAccountsDocument,
  SearchAccountsQueryVariables,
} from '../../graphql/operations';
import { z } from 'zod';

export const createSearchAccountsPromise =
  (client: GraphQLClient) =>
  async (variables: SearchAccountsQueryVariables) => {
    const result = await client.request(SearchAccountsDocument, variables);

    return z
      .object({
        id: z.string().uuid(),
        name: z.string(),
        email: z.string(),
        count: z.number(),
      })
      .array()
      .parseAsync(result.accounts);
  };

export type CreateSearchAccountsPromise = ReturnType<
  typeof createSearchAccountsPromise
>;

export type CreateSearchAccountsPromiseResult = Awaited<
  ReturnType<CreateSearchAccountsPromise>
>;
