import { Button } from '../Button';
import { formatCalendarDate } from '../../utils/formatCalendarDate';
import { TourDate } from '../../schemas/tourDates/tourDateSchema';
import { InlineFormHeading } from '../InlineFormHeading';

import styles from './SubmittedTourDate.module.scss';

interface TourDateViewProps {
  tourDate: TourDate;
  onClose: () => void;
}

export function SubmittedTourDate({ tourDate, onClose }: TourDateViewProps) {
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.col}>
          <div className={styles.dateRow}>
            <h3 className={styles.date}>{formatCalendarDate(tourDate.date)}</h3>
            {tourDate.confirmed && (
              <div className={styles.confirmed}>CONFIRMED</div>
            )}
          </div>
          <div>
            <dd>Type: </dd>
            <dt>{tourDate.tourDateType.title}</dt>
          </div>
        </div>
        <Button
          variant="secondary"
          size="small"
          label="Close"
          startIcon="x"
          onClick={onClose}
        />
      </div>
      <InlineFormHeading>Location</InlineFormHeading>
      <div className={styles.grid}>
        <div>
          <dd>City, Prov: </dd>
          <dt>{tourDate.countrySubdivision}</dt>
        </div>
        <div>
          <dd>Venue: </dd>
          <dt>{tourDate.venueName}</dt>
        </div>
        <div>
          <dd>Country: </dd>
          <dt>{tourDate.tourDateCountry.name}</dt>
        </div>
        <div>
          <dd>Capacity: </dd>
          <dt>{tourDate.venueCapacity}</dt>
        </div>
      </div>
      <InlineFormHeading>Finances</InlineFormHeading>
      <div className={styles.grid}>
        <div>
          <dd>Avg ticket price: </dd>
          <dt>{tourDate.averageTicketPrice}</dt>
        </div>
        <div>
          <dd>Guarantee: </dd>
          <dt>{tourDate.paymentGuarantee}</dt>
        </div>
      </div>
      <InlineFormHeading>Booking & Promotion</InlineFormHeading>
      <div className={styles.grid}>
        <div>
          <dd>Booking agency: </dd>
          <dt>{tourDate.bookingAgency}</dt>
        </div>
        <div>
          <dd>Booking agent: </dd>
          <dt>{tourDate.bookingAgent}</dt>
        </div>
        <div>
          <dd>Promoter: </dd>
          <dt>{tourDate.promoter}</dt>
        </div>
      </div>
    </div>
  );
}
