import { match } from 'ts-pattern';

import {
  RoundMachineSender,
  RoundMachineState,
} from '../../../machines/Admin/RoundMachine';
import { InlineFormContainer } from '../../InlineFormContainer';
import { FormField } from '../../FormField';
import { TextInput } from '../../TextInput';
import { RadioOption } from '../../RadioOption';
import { InlineFormHeading } from '../../InlineFormHeading';
import { FieldSet } from '../../FieldSet';
import { Feedback } from '../../Feedback';
import { DatePicker } from '../../DatePicker/DatePicker';
import { DatePickerWithTime } from '../../DatePicker/DatePickerWithTime';

import { ErrorFeedback } from '../../ErrorFeedback';

import styles from './RoundListing.module.scss';

interface RoundListingProps {
  state: RoundMachineState;
  send: RoundMachineSender;
}

export function RoundListing({ state, send }: RoundListingProps) {
  const { id, round } = state.context;

  return (
    <InlineFormContainer
      primaryButtonText={id === undefined ? 'Create New Round' : 'Save'}
      secondaryButtonText="Cancel"
      primaryButtonOnClick={() => send({ type: 'SAVE_ROUND' })}
      secondaryButtonOnClick={() => send({ type: 'CANCEL' })}
    >
      <InlineFormHeading>
        {id === undefined ? 'Add a New Round' : 'Edit Round'}
      </InlineFormHeading>
      <div className={styles.container}>
        <FormField
          label="Round title"
          feedbackType="warning"
          formFieldStatus={
            state.matches('form.title.invalid') ? 'error' : 'default'
          }
          infoLabel={match(state)
            .when(
              () => state.matches('form.title.invalid'),
              () => 'This field is required.'
            )
            .otherwise(() => '')}
        >
          <TextInput
            id="title"
            value={round.title}
            onChange={(title) => send({ type: 'SET_ROUND_TITLE', title })}
            onFocus={() => send('FOCUS_ROUND_TITLE')}
            onBlur={() => send('BLUR_ROUND_TITLE')}
          />
        </FormField>
        <div className={styles.row}>
          <FormField
            label="Round start date"
            feedbackType="warning"
            formFieldStatus={
              state.matches('form.start-date.invalid') ? 'error' : 'default'
            }
            infoLabel={match(state)
              .when(
                () => state.matches('form.start-date.invalid'),
                () => 'This field is required.'
              )
              .otherwise(() => '')}
          >
            <DatePickerWithTime
              aria-label="Round start date"
              value={round.startDate}
              timezone={round.timezone}
              onChange={(startDate) =>
                send({ type: 'SET_ROUND_START_DATE', startDate })
              }
              onFocus={() => send('FOCUS_ROUND_START_DATE')}
              onBlur={() => send('BLUR_ROUND_START_DATE')}
            />
          </FormField>
          <FormField
            label="Round end date"
            feedbackType="warning"
            formFieldStatus={
              state.matches('form.end-date.invalid') ? 'error' : 'default'
            }
            infoLabel={match(state)
              .when(
                () => state.matches('form.end-date.invalid.before-start'),
                () => 'Round end must be after start.'
              )
              .when(
                () => state.matches('form.end-date.invalid'),
                () => 'This field is required.'
              )
              .otherwise(() => '')}
          >
            <DatePickerWithTime
              aria-label="Round end date"
              value={round.endDate}
              timezone={round.timezone}
              onChange={(endDate) =>
                send({ type: 'SET_ROUND_END_DATE', endDate })
              }
              onFocus={() => send('FOCUS_ROUND_END_DATE')}
              onBlur={() => send('BLUR_ROUND_END_DATE')}
            />
          </FormField>
        </div>
        <div className={styles.row}>
          <FormField label="Board start date" feedbackType="warning">
            <DatePickerWithTime
              aria-label="Board start date"
              value={round.boardStartDate}
              timezone={round.timezone}
              onChange={(boardStartDate) =>
                send({ type: 'SET_BOARD_MEETING_START_DATE', boardStartDate })
              }
              onFocus={() => send('FOCUS_BOARD_MEETING_START_DATE')}
              onBlur={() => send('BLUR_BOARD_MEETING_START_DATE')}
            />
          </FormField>
          <FormField
            label="Board end date"
            feedbackType="warning"
            formFieldStatus={
              state.matches('form.board-meeting-end-date.invalid')
                ? 'error'
                : 'default'
            }
            infoLabel={match(state)
              .when(
                () =>
                  state.matches(
                    'form.board-meeting-end-date.invalid.before-end'
                  ),
                () => 'Board end access must be after start access.'
              )
              .when(
                () => state.matches('form.board-meeting-end-date.invalid'),
                () => 'This field is required.'
              )
              .otherwise(() => '')}
          >
            <DatePickerWithTime
              aria-label="Board end date"
              value={round.boardEndDate}
              timezone={round.timezone}
              onChange={(boardEndDate) =>
                send({ type: 'SET_BOARD_MEETING_END_DATE', boardEndDate })
              }
              onFocus={() => send('FOCUS_BOARD_MEETING_END_DATE')}
              onBlur={() => send('BLUR_BOARD_MEETING_END_DATE')}
            />
          </FormField>
        </div>
        <div className={styles.row}>
          <FormField
            label="Board meeting date"
            feedbackType="warning"
            formFieldStatus={
              state.matches('form.board-meeting-date.invalid')
                ? 'error'
                : 'default'
            }
            infoLabel={match(state)
              .when(
                () => state.matches('form.board-meeting-date.invalid'),
                () => 'This field is required.'
              )
              .otherwise(() => '')}
          >
            <DatePicker
              aria-label="Board meeting date"
              value={round.boardMeetingDate}
              onChange={(boardMeetingDate) =>
                send({ type: 'SET_BOARD_MEETING_DATE', boardMeetingDate })
              }
              onFocus={() => send('FOCUS_BOARD_MEETING_DATE')}
              onBlur={() => send('BLUR_BOARD_MEETING_DATE')}
            />
          </FormField>
          <FormField
            label="Round type"
            feedbackType="warning"
            formFieldStatus={
              state.matches('form.allow-orion.invalid') ? 'error' : 'default'
            }
            infoLabel={match(state)
              .when(
                () => state.matches('form.allow-orion.invalid'),
                () => 'This field is required.'
              )
              .otherwise(() => '')}
          >
            <FieldSet direction="horizontal">
              <RadioOption
                label="Core only"
                checked={round.allowOrion === false}
                onChange={() =>
                  send({ type: 'SET_ALLOW_ORION', allowOrion: false })
                }
              />
              <RadioOption
                label="Core and Orion"
                checked={round.allowOrion === true}
                onChange={() =>
                  send({ type: 'SET_ALLOW_ORION', allowOrion: true })
                }
              />
            </FieldSet>
          </FormField>
        </div>
      </div>

      {(state.matches('form.warning.on') ||
        state.matches('form.warning.pulse')) && (
        <Feedback type="warning" pulse={state.matches('form.warning.pulse')}>
          Please fill in all the fields above.
        </Feedback>
      )}
      {state.matches('form.warning.error') && <ErrorFeedback />}
    </InlineFormContainer>
  );
}
