import { GraphQLClient } from 'graphql-request';
import { UpdateArtistDetailsDocument } from '../graphql/operations';
import { Context } from '../machines/components/ArtistDetailsBlockMachine';

export const createUpdateArtistDetailsPromise =
  (client: GraphQLClient) =>
  ({ artist, form }: Context) =>
    client.request(UpdateArtistDetailsDocument, {
      input: {
        id: artist.id,
        artistPatch: {
          companyName: form.companyName,
          companyLegalStatus: form.legalStatus,
          companyLegalProvince: form.companyLegalProvince,
          name: form.name,
          provinceId: form.provinceId,
        },
      },
    });

export type CreateUpdateArtistDetailsPromise = ReturnType<
  typeof createUpdateArtistDetailsPromise
>;
export type CreateUpdateArtistDetailsPromiseResult = Awaited<
  ReturnType<CreateUpdateArtistDetailsPromise>
>;
