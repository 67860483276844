import { FundingRoundStatusType } from '../../../graphql/operations';
import { getRoundStatusLabel } from '../../../utils/getRoundStatusLabel';

import styles from './RoundSubtitle.module.scss';

interface RoundSubtitleProps {
  status: FundingRoundStatusType;
  allowOrion: boolean;
}

export function RoundSubtitle({
  status,
  allowOrion = false,
}: RoundSubtitleProps) {
  return (
    <div className={styles.container}>
      <p className={styles.status}>{getRoundStatusLabel(status)}</p>
      <p className={styles.type}>
        {allowOrion ? 'Core and Orion' : 'Core Only'}
      </p>
    </div>
  );
}
