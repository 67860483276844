import { useActor } from '@xstate/react';
import { ReleaseSingleMachineActor } from '../../../machines/Release/ReleaseSingleMachine';
import { SingleForm } from './SingleForm';

export function SingleAddFormController({
  actor,
}: {
  actor: ReleaseSingleMachineActor;
}) {
  const [state, send] = useActor(actor);

  return (
    <SingleForm
      type="add"
      state={state}
      onTitleUpdate={(title) => {
        send({ type: 'UPDATE_TITLE', title });
      }}
      onSpinsUpdate={(spins) => send({ type: 'UPDATE_SPINS', spins })}
      onTitleBlur={() => send({ type: 'BLUR_TITLE' })}
      onSpinsBlur={() => send({ type: 'BLUR_SPINS' })}
      onSaveClick={() => {
        send({ type: 'BLUR_TITLE' });
        send({ type: 'BLUR_SPINS' });
        send({ type: 'ATTEMPT_SAVE' });
      }}
      onCancelClick={() => send({ type: 'CANCEL_EDITING' })}
    />
  );
}
