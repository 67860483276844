import { Attachment } from '../../schemas/attachment';
import { BoardMeetingBlock } from '../../schemas/boardMeetingBlockSchema';
import { deleteFile } from './utils';

interface Props {
  applicationId?: string;
  block?: BoardMeetingBlock;
}
interface DeleteFileProps {
  file: Attachment;
}

export const createDeleteFilePromise =
  ({ directory }: { directory: string }) =>
  async ({ applicationId, block }: Props, { file }: DeleteFileProps) => {
    const referenceId = applicationId || block?.id;

    if (!referenceId) {
      throw Error('No reference id provided');
    }
    return deleteFile({
      referenceId,
      directory,
      fileName: file.name,
    });
  };

export type DeleteFilePromise = ReturnType<typeof createDeleteFilePromise>;
export type DeleteFilePromiseResult = Awaited<ReturnType<DeleteFilePromise>>;
