import {
  History,
  createBrowserHistory,
  createMemoryHistory,
} from '../../utils/history';

import {
  RedeemArtistInvitationState,
  parseRedeemArtistInvitationState,
} from './state';

export type RedeemArtistInvitationHistory =
  History<RedeemArtistInvitationState>;

export const createBrowserRedeemArtistInvitationHistory =
  (): RedeemArtistInvitationHistory =>
    createBrowserHistory(parseRedeemArtistInvitationState);

export const createMemoryRedeemArtistInvitationHistory =
  (): RedeemArtistInvitationHistory =>
    createMemoryHistory<RedeemArtistInvitationState>();
