import { BoardMeetingBlock } from '../../../schemas/boardMeetingBlockSchema';
import { Block } from '../../Block';
import { AdminBoardMeetingBlockMachineSender } from '../../../machines/BoardMeeting/AdminBoardMeetingBlockMachine';
import { Button } from '../../Button';
import { UploadButton } from '../../UploadButton';
import { AttachmentPreview } from '../../Attachments/AttachmentPreview';
import { ErrorFeedback } from '../../ErrorFeedback';

import styles from './MeetingBlock.module.scss';

interface MeetingBlockProps {
  block: BoardMeetingBlock;
  isFirstBlock?: boolean;
  isLastBlock?: boolean;
  isUploading: boolean;
  hasAttachmentError?: boolean;
  send: AdminBoardMeetingBlockMachineSender;
}

export function MeetingBlock({
  block,
  isFirstBlock,
  isLastBlock,
  isUploading,
  hasAttachmentError,
  send,
}: MeetingBlockProps) {
  const { title, content, attachments } = block;

  return (
    <Block id={block.id}>
      <div className={styles.header}>
        {title && <h2 className={styles.title}>{title}</h2>}
        <div className={styles.buttons}>
          <Button
            className={styles.button}
            size="small"
            variant="neutral"
            label="Remove"
            disabled={isUploading}
            onClick={() => {
              send('DELETE_BLOCK');
            }}
          />
          <Button
            className={styles.button}
            size="small"
            variant="neutral"
            startIcon="upChevron"
            disabled={isFirstBlock || isUploading}
            onClick={() => {
              send({ type: 'MOVE_BLOCK', direction: 'up' });
            }}
          />
          <Button
            className={styles.button}
            size="small"
            variant="neutral"
            startIcon="downChevron"
            disabled={isLastBlock || isUploading}
            onClick={() => {
              send({ type: 'MOVE_BLOCK', direction: 'down' });
            }}
          />
          <Button
            className={styles.button}
            size="small"
            variant="secondary"
            label="Edit"
            disabled={isUploading}
            onClick={() => {
              send('OPEN_FORM');
            }}
          />
        </div>
      </div>
      {content && <p className={styles.content}>{content}</p>}
      {hasAttachmentError ? (
        <ErrorFeedback />
      ) : (
        <>
          {attachments.map((file) => (
            <AttachmentPreview
              key={file.id}
              attachment={file}
              onClick={(file) => send({ type: 'DOWNLOAD_FILE', file })}
              onDelete={(file) => send({ type: 'DELETE_FILE', file })}
              isButtonDisabled={isUploading}
              isOwner={true}
            />
          ))}
          <UploadButton
            buttonLabel="Upload a File"
            isUploading={isUploading}
            onClick={(files) => send({ type: 'UPLOAD_FILES', files })}
            className={styles.uploadButton}
          />
        </>
      )}
    </Block>
  );
}
