import { GraphQLClient } from 'graphql-request';
import {
  UpsertEligibilityNoticeDocument,
  UpsertEligibilityNoticeMutation,
  UpsertEligibilityNoticeResponseCode,
} from '../graphql/operations';
import { Context } from '../machines/EligibilityNoticeMachine';
import { eligibilityNoticeSchema } from '../schemas/artistEligibilityNoticeSchema';

export const isSuccess = ({ result }: UpsertEligibilityNoticeMutation) =>
  result?.upsertEligibilityNoticeResponse?.code ===
  UpsertEligibilityNoticeResponseCode.Success;

export const createUpsertEligibilityNoticePromise =
  (client: GraphQLClient) => (context: Context) => {
    return client.request(UpsertEligibilityNoticeDocument, {
      input: {
        artistId: context.artistId,
        ...eligibilityNoticeSchema.parse({
          message: context.adminForm.message,
          type: context.adminForm.type,
        }),
      },
    });
  };

export type UpsertEligibilityNoticePromise = ReturnType<
  typeof createUpsertEligibilityNoticePromise
>;
export type UpsertEligibilityNoticePromiseResult = Awaited<
  ReturnType<UpsertEligibilityNoticePromise>
>;
