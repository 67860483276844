import { ReactNode, RefObject, useEffect } from 'react';
import { Popover as ReactAriaPopover } from 'react-aria-components';

import styles from './Popover.module.scss';

interface PopoverProps {
  open: boolean;
  divRef: RefObject<HTMLElement>;
  children: ReactNode;
  onClose: () => void;
}

export function Popover({ open, divRef, children, onClose }: PopoverProps) {
  useEffect(() => {
    if (!open) {
      setTimeout(() => {
        divRef.current?.blur();
      }, 0);
    }
  }, [open]);
  return (
    <ReactAriaPopover triggerRef={divRef} isOpen={open} onOpenChange={onClose}>
      {open && (
        <>
          <div className={styles.popover}>{children}</div>
        </>
      )}
    </ReactAriaPopover>
  );
}
