import { GraphQLClient } from 'graphql-request';
import { UpdateApplicationDocument } from '../graphql/operations';
import { LinkedAccount } from '../schemas/linkedAccountSchema';

export const createUpdateApplicationPromise =
  (client: GraphQLClient) =>
  ({
    applicationId,
    primaryContact,
    statement,
  }: {
    applicationId: string;
    primaryContact?: LinkedAccount;
    statement?: string;
  }) =>
    client.request(UpdateApplicationDocument, {
      applicationId,
      statement,
      primaryContactId: primaryContact?.accountId,
    });

export type UpdateApplicationPromise = ReturnType<
  typeof createUpdateApplicationPromise
>;
export type UpdateApplicationPromiseResult = Awaited<
  ReturnType<UpdateApplicationPromise>
>;
