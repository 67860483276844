import { assign, createMachine, sendParent } from 'xstate';
import type {
  ActorRefFrom,
  EventFrom,
  MachineOptionsFrom,
  Sender,
} from 'xstate';

import {
  CreateAccountInvitationPromiseResult,
  getInvitationId,
  isSuccess as isCreateSuccess,
} from '../../promises/createAccountInvitationPromise';
import {
  UpdateAccountInvitationPromiseResult,
  isSuccess as isUpdateSuccess,
} from '../../promises/updateAccountInvitationPromise';
import { StateFrom } from '../../utils/StateFrom';
import { back } from '../navigation';
import { EligibilityStorage } from './storage';
import { isEmailValid } from '../../utils/isEmailValid';
import { logMachineError } from '../../utils/logError';

export type Context = {
  invitationId: string;
  email: string;
};

type Events =
  | { type: 'SET_EMAIL'; email: string }
  | { type: 'SET_INVITATION_ID'; invitationId: string }
  | { type: 'SUBMIT' }
  | { type: 'BACK' };

type Services = {
  createAccountInvitationPromise: {
    data: CreateAccountInvitationPromiseResult;
  };
  updateAccountInvitationPromise: {
    data: UpdateAccountInvitationPromiseResult;
  };
};

const isEmailEmpty = (email: string) => email.length === 0;

export const machine = createMachine(
  {
    predictableActionArguments: true,
    tsTypes: {} as import('./EmailInvitationMachine.typegen').Typegen0,
    id: 'createAccountForm',
    schema: {
      context: {} as Context,
      events: {} as Events,
      services: {} as Services,
    },
    initial: 'ready',
    context: {
      invitationId: '',
      email: '',
    },
    states: {
      ready: {
        type: 'parallel',
        states: {
          email: {
            initial: 'noError',
            states: {
              noError: {},
              error: {
                initial: 'empty',
                states: {
                  empty: {},
                  badFormat: {},
                },
              },
            },
            on: {
              SET_EMAIL: { actions: 'setEmail' },
              SUBMIT: [
                { cond: 'isEmailEmpty', target: '.error.empty' },
                { cond: 'isEmailBadFormat', target: '.error.badFormat' },
              ],
            },
          },
          action: {
            initial: 'create',
            states: {
              create: {
                on: {
                  SUBMIT: {
                    cond: 'isFormValid',
                    target: '#createAccountForm.creatingInvitation',
                  },
                  BACK: { actions: 'back' },
                },
              },
              update: {
                on: {
                  SUBMIT: {
                    cond: 'isFormValid',
                    target: '#createAccountForm.updatingInvitation',
                  },
                },
              },
            },
          },
        },
      },
      creatingInvitation: {
        invoke: {
          id: 'createAccountInvitationPromise',
          src: 'createAccountInvitationPromise',
          onDone: [
            { cond: 'isCreateSuccess', target: 'complete' },
            { target: 'createError' },
          ],
          onError: 'createError',
        },
      },
      createError: {
        entry: 'logMachineError',
        on: {
          BACK: 'ready.action.create',
        },
      },
      updatingInvitation: {
        invoke: {
          id: 'updateAccountInvitationPromise',
          src: 'updateAccountInvitationPromise',
          onDone: [
            { cond: 'isUpdateSuccess', target: 'complete' },
            { target: 'updateError' },
          ],
          onError: 'updateError',
        },
      },
      updateError: {
        entry: 'logMachineError',
        on: {
          BACK: 'ready.action.update',
        },
      },
      complete: {
        entry: ['resetEligibilityState', 'setInvitationId'],
        on: {
          BACK: 'ready.action.update',
        },
      },
    },
  },
  {
    guards: {
      isFormValid: ({ email }) => !isEmailEmpty(email) && isEmailValid(email),
      isEmailEmpty: ({ email }) => isEmailEmpty(email),
      isEmailBadFormat: ({ email }) => !isEmailValid(email),
      isCreateSuccess: (_, { data }) => isCreateSuccess(data),
      isUpdateSuccess: (_, { data }) => isUpdateSuccess(data),
    },
    actions: {
      logMachineError,
      setEmail: assign((_, { email }) => ({ email })),
      setInvitationId: assign(({ invitationId }, event) => ({
        invitationId:
          event.type === 'done.invoke.createAccountInvitationPromise'
            ? getInvitationId(event.data)
            : invitationId,
      })),
      back: sendParent(back()),
    },
  }
);

type Machine = typeof machine;

export type EmailInvitationMachineState = StateFrom<Machine>;
export type EmailInvitationMachineSender = Sender<EventFrom<Machine>>;
export type EmailInvitationMachineOptions = MachineOptionsFrom<Machine, true>;
export type EmailInvitationMachineActor = ActorRefFrom<Machine>;

export const createEmailInvitationMachineSaveActions = (
  storage: EligibilityStorage
) => ({
  resetEligibilityState: storage.reset,
});
