import { GraphQLClient } from 'graphql-request';
import {
  GetReleasePageDocument,
  GetReleasePageQueryVariables,
} from '../graphql/operations';

export const createGetReleasePagePromise =
  (client: GraphQLClient, variables: GetReleasePageQueryVariables) => () =>
    client.request(GetReleasePageDocument, variables);

export type GetReleasePage = ReturnType<typeof createGetReleasePagePromise>;
export type GetReleasePageResult = Awaited<ReturnType<GetReleasePage>>;
