import { match } from 'ts-pattern';
import type {
  Sender,
  EventFrom,
  MachineOptionsFrom,
  ActorRefFrom,
  RaiseAction,
} from 'xstate';
import { createMachine, assign, raise } from 'xstate';
import { StateFrom } from '../../utils/StateFrom';
import {
  ApplicationType,
  InsertDirectDepositAndLinkToApplicationInput,
} from '../../graphql/operations';
import { CreateSaveApplicationDirectDepositInfoPromiseResult } from '../../promises/saveApplicationDirectDepositInfo';
import { logMachineError } from '../../utils/logError';
import { DirectDepositAccount } from '../../schemas/directDepositAccount';

type Events =
  | { type: 'SAVE' }
  | { type: 'CANCEL' }
  | { type: 'SELECT_NEW_ACCOUNT' }
  | { type: 'SELECT_EXISTING_ACCOUNT'; directDepositAccountId: string }
  | { type: 'BLUR_NAME' }
  | { type: 'BLUR_TRANSIT' }
  | { type: 'BLUR_INSTITUTION' }
  | { type: 'BLUR_ACCOUNT' }
  | { type: 'UPDATE_NAME'; name: string }
  | { type: 'UPDATE_TRANSIT'; transit: string }
  | { type: 'UPDATE_INSTITUTION'; institution: string }
  | { type: 'UPDATE_ACCOUNT'; account: string };

export type Context = {
  depositInfoType: InsertDirectDepositAndLinkToApplicationInput['type'];
  applicationId: string;
  artistId: string;
  existingAccounts: DirectDepositAccount[];
  selectedExistingAccountId?: string;
  applicationType: ApplicationType;
  isChange: boolean;
  name: string;
  transit: string;
  institution: string;
  account: string;
  data?: CreateSaveApplicationDirectDepositInfoPromiseResult;
};

type Services = {
  saveApplicationDirectDepositInfo: {
    data: CreateSaveApplicationDirectDepositInfoPromiseResult;
  };
};

export const createContext = (
  context: Omit<Context, 'name' | 'transit' | 'institution' | 'account'>
): Context => ({
  ...context,
  name: '',
  transit: '',
  institution: '',
  account: '',
});

const fieldConfig = {
  initial: 'pristine',
  states: {
    pristine: {},
    noError: {},
    empty: {},
    invalid: {},
  },
};

export const machine = createMachine(
  {
    id: 'setDepositInfoMachine',
    predictableActionArguments: true,
    tsTypes: {} as import('./SetDepositInfoMachine.typegen').Typegen0,
    schema: {
      context: {} as Context,
      events: {} as Events,
      services: {} as Services,
    },
    initial: 'initializing',
    states: {
      initializing: {
        always: [
          {
            cond: 'existingAccountIsSelected',
            target: 'selectingExistingAccount',
          },
          {
            target: 'inputtingNewAccount',
          },
        ],
      },
      selectingExistingAccount: {
        on: {
          SAVE: '.saving',
          CANCEL: 'done',
          SELECT_EXISTING_ACCOUNT: {
            actions: ['updateSelectedExistingAccountId'],
            target: 'selectingExistingAccount',
          },
          SELECT_NEW_ACCOUNT: 'inputtingNewAccount',
        },
        initial: 'idle',
        states: {
          idle: {},
          saving: {
            invoke: {
              src: 'saveApplicationDirectDepositInfo',
              onDone: {
                target: '#setDepositInfoMachine.done',
                actions: 'updateData',
              },
              onError: '#setDepositInfoMachine.selectingExistingAccount.error',
            },
          },
          error: { entry: 'logMachineError' },
        },
      },
      inputtingNewAccount: {
        entry: 'clearSelectedExistingAccountId',
        on: {
          SELECT_EXISTING_ACCOUNT: {
            actions: ['updateSelectedExistingAccountId'],
            target: 'selectingExistingAccount',
          },
          UPDATE_NAME: { actions: 'updateName' },
          UPDATE_TRANSIT: { actions: 'updateTransit' },
          UPDATE_INSTITUTION: { actions: 'updateInstitution' },
          UPDATE_ACCOUNT: { actions: 'updateAccount' },
          SAVE: [
            { cond: 'isFormValid', target: '.network.saving' },
            {
              actions: [
                'blurName',
                'blurAccount',
                'blurTransit',
                'blurInstitution',
              ],
            },
          ],
          CANCEL: 'done',
          BLUR_NAME: [
            {
              cond: 'isEmpty',
              target: '#setDepositInfoMachine.inputtingNewAccount.name.empty',
            },
            {
              target: '#setDepositInfoMachine.inputtingNewAccount.name.noError',
            },
          ],
          BLUR_TRANSIT: [
            {
              cond: 'isEmpty',
              target:
                '#setDepositInfoMachine.inputtingNewAccount.transit.empty',
            },
            {
              cond: 'isInvalid',
              target:
                '#setDepositInfoMachine.inputtingNewAccount.transit.invalid',
            },
            {
              target:
                '#setDepositInfoMachine.inputtingNewAccount.transit.noError',
            },
          ],
          BLUR_INSTITUTION: [
            {
              cond: 'isEmpty',
              target:
                '#setDepositInfoMachine.inputtingNewAccount.institution.empty',
            },
            {
              cond: 'isInvalid',
              target:
                '#setDepositInfoMachine.inputtingNewAccount.institution.invalid',
            },
            {
              target:
                '#setDepositInfoMachine.inputtingNewAccount.institution.noError',
            },
          ],
          BLUR_ACCOUNT: [
            {
              cond: 'isEmpty',
              target:
                '#setDepositInfoMachine.inputtingNewAccount.account.empty',
            },
            {
              cond: 'isInvalid',
              target:
                '#setDepositInfoMachine.inputtingNewAccount.account.invalid',
            },
            {
              target:
                '#setDepositInfoMachine.inputtingNewAccount.account.noError',
            },
          ],
        },
        type: 'parallel',
        states: {
          name: { ...fieldConfig },
          transit: { ...fieldConfig },
          institution: { ...fieldConfig },
          account: { ...fieldConfig },
          network: {
            initial: 'idle',
            states: {
              idle: {},
              saving: {
                invoke: {
                  src: 'saveApplicationDirectDepositInfo',
                  onDone: {
                    target: '#setDepositInfoMachine.done',
                    actions: 'updateData',
                  },
                  onError:
                    '#setDepositInfoMachine.inputtingNewAccount.network.error',
                },
              },
              error: { entry: 'logMachineError' },
            },
          },
        },
      },
      done: {
        type: 'final',
        data: (context) => context.data,
      },
    },
  },
  {
    guards: {
      existingAccountIsSelected: ({
        selectedExistingAccountId,
        existingAccounts,
      }) =>
        Boolean(
          existingAccounts.find(
            (account) => account.id === selectedExistingAccountId
          )
        ),
      isEmpty: (context, { type }) =>
        match(type)
          .with('BLUR_NAME', () => Boolean(context.name === ''))
          .with('BLUR_TRANSIT', () => Boolean(context.transit === ''))
          .with('BLUR_INSTITUTION', () => Boolean(context.institution === ''))
          .with('BLUR_ACCOUNT', () => Boolean(context.account === ''))
          .otherwise(() => false),
      isInvalid: ({ transit, institution, account }, { type }) =>
        match(type)
          .with('BLUR_TRANSIT', () => !/^[0-9]{4,5}$/.test(transit ?? ''))
          .with('BLUR_INSTITUTION', () => !/^[0-9]{3}$/.test(institution ?? ''))
          .with('BLUR_ACCOUNT', () => !/^[0-9]{7,12}$/.test(account ?? ''))
          .otherwise(() => false),
      isFormValid: (_, __, { state }) =>
        ['name', 'transit', 'institution', 'account'].every((field) =>
          state.matches(`inputtingNewAccount.${field}.noError`)
        ),
    },
    actions: {
      logMachineError,
      updateSelectedExistingAccountId: assign((_, event) => ({
        selectedExistingAccountId: event.directDepositAccountId,
      })),
      updateName: assign((_, event) => ({
        name: event.name,
      })),
      updateTransit: assign((_, event) => ({
        transit: event.transit,
      })),
      updateInstitution: assign((_, event) => ({
        institution: event.institution,
      })),
      updateAccount: assign((_, event) => ({
        account: event.account,
      })),
      updateData: assign((_, event) => ({
        data: event.data,
      })),
      blurName: raise({ type: 'BLUR_NAME' }) as RaiseAction<{
        type: 'BLUR_NAME';
      }>,
      blurAccount: raise({ type: 'BLUR_ACCOUNT' }) as RaiseAction<{
        type: 'BLUR_ACCOUNT';
      }>,
      blurTransit: raise({ type: 'BLUR_TRANSIT' }) as RaiseAction<{
        type: 'BLUR_TRANSIT';
      }>,
      blurInstitution: raise({ type: 'BLUR_INSTITUTION' }) as RaiseAction<{
        type: 'BLUR_INSTITUTION';
      }>,
      clearSelectedExistingAccountId: assign((_) => ({
        selectedExistingAccountId: undefined,
      })),
    },
  }
);

type Machine = typeof machine;

export type SetDepositInfoMachineState = StateFrom<Machine>;
export type SetDepositInfoMachineSender = Sender<EventFrom<Machine>>;
export type SetDepositInfoMachineOptions = MachineOptionsFrom<Machine, true> & {
  context: Context;
};
export type SetDepositInfoMachineEvent = EventFrom<Machine>;
export type SetDepositInfoMachineActor = ActorRefFrom<Machine>;
