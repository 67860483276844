import type {
  Sender,
  EventFrom,
  MachineOptionsFrom,
  ActorRefFrom,
} from 'xstate';
import { assign, sendParent, createMachine } from 'xstate';
import { StateFrom } from '../../utils/StateFrom';
import {
  ApplicationStage,
  ApplicationStatus,
  FundingRequestStatus,
} from '../../graphql/operations';
import {
  openFundingRequest,
  OpenFundingRequest,
} from '../../events/OpenFundingRequest';
import {
  deleteFundingRequest,
  DeleteFundingRequest,
} from '../../events/DeleteFundingRequest';
import { updateAdminFundingRequest } from '../../events/UpdateFundingRequest';
import { FundingRequest } from '../../schemas/fundingRequests/fundingRequestSchema';
import { CalendarDate } from '@internationalized/date';
import { StatesConfig } from '../../utils/StateConfig';
import { parsePositiveFloat } from '../../utils/parsePositiveFloat';
import { formatNumber } from '../../utils/formatNumber';
import {
  cancelFundingRequest,
  CancelFundingRequest,
} from '../../events/CancelFundingRequest';
import { AddExpense } from '../../events/Expense/AddExpense';
import { CloseExpense } from '../../events/Expense/CloseExpense';
import {
  ExpenseBlockMachineActor,
  ExpenseBlockMachineWithImplementations,
  createContext as createExpenseBlockContext,
} from '../components/ExpenseBlockMachine';
import { sendTo } from 'xstate/lib/actions';
import { OpenExpense, openExpense } from '../../events/Expense/OpenExpense';
import {
  refreshApplication,
  RefreshApplicationEvent,
} from '../../events/RefreshApplication';
import {
  updateExpenses,
  UpdateExpenses,
} from '../../events/Expense/UpdateExpenses';
import { LocalDateTime } from '../../schemas/dateTimeSchema';

export type Events =
  | { type: 'TOGGLE_FORM_VISIBILITY' }
  | { type: 'SET_STATUS'; status: FundingRequestStatus }
  | { type: 'SET_AMOUNT_APPROVED'; amountApproved: string }
  | { type: 'BLUR_AMOUNT_APPROVED' }
  | { type: 'FOCUS_AMOUNT_APPROVED' }
  | { type: 'UPDATE_ADMIN_REQUEST' }
  | { type: 'UPDATE_CONTEXT'; context: Partial<Context> }
  | OpenFundingRequest
  | DeleteFundingRequest
  | CancelFundingRequest
  | AddExpense
  | CloseExpense
  | OpenExpense
  | RefreshApplicationEvent
  | UpdateExpenses;

interface AdminFundingRequest {
  status: FundingRequestStatus;
  approvedCostOverride: string;
}

const inputStates: StatesConfig<Context, Events> = {
  pristine: { on: { UPDATE_ADMIN_REQUEST: 'invalid' } },
  valid: { type: 'final' },
  invalid: {},
};

export type Context = {
  request: FundingRequest;
  adminRequest: AdminFundingRequest;
  expenseBlockRef: ExpenseBlockMachineActor | null;
  isAdmin: boolean;
  isBoard: boolean;
  isSubmitted: boolean;
  isClaimsSubmitted: boolean;
  stage: ApplicationStage;
  presentCutoffDate: CalendarDate;
  futureCutoffDate: CalendarDate | undefined;
  applicationStatus: ApplicationStatus;
  applicationSubmittedAt: LocalDateTime;
  hasOpenExpense: boolean;
  expenseBlockMachine: ExpenseBlockMachineWithImplementations;
};

export const createContext = (
  request: FundingRequest,
  adminRequest: AdminFundingRequest,
  isAdmin: boolean,
  isBoard: boolean,
  isSubmitted: boolean,
  isClaimsSubmitted: boolean,
  stage: ApplicationStage,
  presentCutoffDate: CalendarDate,
  futureCutoffDate: CalendarDate | undefined,
  applicationStatus: ApplicationStatus,
  expenseBlockMachine: ExpenseBlockMachineWithImplementations,
  applicationSubmittedAt: LocalDateTime
): Context => ({
  request,
  adminRequest,
  isAdmin,
  isBoard,
  isSubmitted,
  isClaimsSubmitted,
  stage,
  presentCutoffDate,
  futureCutoffDate,
  applicationStatus,
  applicationSubmittedAt,
  expenseBlockRef: null,
  hasOpenExpense: false,
  expenseBlockMachine,
});

export const machine = createMachine(
  {
    predictableActionArguments: true,
    tsTypes: {} as import('./FundingRequestCardMachine.typegen').Typegen0,
    schema: {
      context: {} as Context,
      events: {} as Events,
    },
    id: 'fundingRequestCardMachine',
    type: 'parallel',
    states: {
      form: {
        initial: 'ready',
        states: {
          ready: {
            on: {
              TOGGLE_FORM_VISIBILITY: 'editing',
              OPEN_REQUEST: {
                actions: 'openFundingRequest',
              },
              DELETE_REQUEST: {
                actions: 'deleteFundingRequest',
              },
            },
          },
          editing: {
            entry: ['setAdminRequest', 'formatCostValue'],
            type: 'parallel',
            states: {
              amount: {
                initial: 'valid',
                states: {
                  ...inputStates,
                  invalid: {
                    initial: 'empty',
                    states: {
                      empty: {},
                      value: {},
                      high: {},
                    },
                  },
                  checking: {
                    always: [
                      { cond: 'isAmountEmpty', target: 'invalid.empty' },
                      { cond: 'isAmountNonNumeric', target: 'invalid.value' },
                      'formating',
                    ],
                  },
                  formating: {
                    entry: 'formatCostValue',
                    always: [
                      { cond: 'isAmountTooHigh', target: 'invalid.high' },
                      'valid',
                    ],
                  },
                },
                on: {
                  SET_AMOUNT_APPROVED: {
                    actions: 'setAmountApproved',
                  },
                  BLUR_AMOUNT_APPROVED: '.checking',
                },
              },
              warning: {
                initial: 'off',
                states: {
                  on: {
                    type: 'final',
                    on: { UPDATE_ADMIN_REQUEST: 'pulse' },
                  },
                  off: {
                    type: 'final',
                    on: { UPDATE_ADMIN_REQUEST: 'on' },
                  },
                  pulse: {
                    type: 'final',
                    after: { 1000: 'on' },
                  },
                },
              },
            },
            onDone: 'complete',
            on: {
              TOGGLE_FORM_VISIBILITY: 'ready',
            },
          },
          complete: {
            on: {
              FOCUS_AMOUNT_APPROVED: {
                target: ['editing.amount.pristine'],
              },
              UPDATE_ADMIN_REQUEST: {
                target: 'ready',
                actions: 'updateAdminFundingRequest',
              },
              TOGGLE_FORM_VISIBILITY: 'ready',
            },
          },
        },
      },
      expenses: {
        invoke: {
          id: 'expenseBlockMachine',
          src: (context) => context.expenseBlockMachine,
          data: (context) =>
            createExpenseBlockContext(
              context.request,
              context.applicationStatus,
              false,
              context.applicationSubmittedAt
            ),
        },
      },
    },
    on: {
      SET_STATUS: {
        actions: 'setStatus',
      },
      CANCEL_REQUEST: {
        actions: 'cancelFundingRequest',
      },
      UPDATE_CONTEXT: {
        actions: 'updateContext',
      },
      OPEN_EXPENSE: {
        actions: ['openExpense', 'hideExpenseButtons'],
      },
      CLOSE_EXPENSE: {
        actions: ['showExpenseButtons'],
      },
      REFRESH_APPLICATION: {
        actions: 'refreshApplication',
      },
      UPDATE_EXPENSES: {
        actions: 'updateExpenses',
      },
    },
  },
  {
    guards: {
      isAmountEmpty: ({ adminRequest }) =>
        adminRequest.approvedCostOverride === '',
      isAmountNonNumeric: ({ adminRequest }) =>
        isNaN(parsePositiveFloat(adminRequest.approvedCostOverride)),
      isAmountTooHigh: ({ request, adminRequest }) =>
        parsePositiveFloat(request.cost) <
        parsePositiveFloat(adminRequest.approvedCostOverride),
    },
    actions: {
      setAdminRequest: assign(({ request }) => ({
        adminRequest: {
          approvedCostOverride: request.amountApproved,
          status: request.status,
        },
      })),
      setStatus: assign((context, { status }) => ({
        adminRequest: {
          ...context.adminRequest,
          status,
        },
      })),
      setAmountApproved: assign((context, event) => ({
        adminRequest: {
          ...context.adminRequest,
          approvedCostOverride: event.amountApproved,
        },
      })),
      updateAdminFundingRequest: sendParent(({ request, adminRequest }) =>
        updateAdminFundingRequest(request.id, adminRequest)
      ),
      openFundingRequest: sendParent(({ request }) =>
        openFundingRequest(request.id)
      ),
      deleteFundingRequest: sendParent(({ request }) =>
        deleteFundingRequest(request.id)
      ),
      formatCostValue: assign(
        ({ adminRequest: { approvedCostOverride, ...request } }) => ({
          adminRequest: {
            ...request,
            approvedCostOverride: formatNumber(
              parsePositiveFloat(approvedCostOverride)
            ),
          },
        })
      ),
      cancelFundingRequest: sendParent(({ request }) =>
        cancelFundingRequest(request.id)
      ),
      updateContext: assign((_, { context }) => ({
        ...context,
      })),
      openExpense: sendTo('expenseBlockMachine', openExpense()),
      hideExpenseButtons: assign((_) => ({
        hasOpenExpense: true,
      })),
      showExpenseButtons: assign((_) => ({
        hasOpenExpense: false,
      })),
      refreshApplication: sendParent(refreshApplication()),
      updateExpenses: sendParent((_, event) => updateExpenses(event.request)),
    },
  }
);

type Machine = typeof machine;

export type FundingRequestCardMachine = Machine;
export type FundingRequestCardMachineState = StateFrom<Machine>;
export type FundingRequestCardMachineSender = Sender<EventFrom<Machine>>;
export type FundingRequestCardMachineEvent = EventFrom<Machine>;
export type FundingRequestCardMachineOptions = MachineOptionsFrom<
  Machine,
  true
>;
export type FundingRequestCardMachineActor =
  ActorRefFrom<FundingRequestCardMachine>;
