import { useMachine } from '@xstate/react';
import { BoardMeetingView } from './BoardMeetingView';
import {
  machine,
  BoardMeetingMachineOptions,
} from '../../machines/BoardMeeting/BoardMeetingMachine';

type BoardMeetingControllerProps = {
  options: BoardMeetingMachineOptions;
};

export function BoardMeetingController({
  options,
}: BoardMeetingControllerProps) {
  const [state, send] = useMachine(machine, options);

  return <BoardMeetingView state={state} send={send} />;
}
