import { useMachine } from '@xstate/react';
import { useAuth } from '../../components/AuthProvider';
import { useIsBoard } from '../../hooks/useIsBoard';
import {
  AllApplicationsMachineOptions,
  machine,
} from '../../machines/Application/AllApplicationsMachine';
import { AllApplicationsView } from './AllApplicationsView';

interface AllApplicationsControllerProps {
  options: AllApplicationsMachineOptions;
}

export function AllApplicationsController({
  options,
}: AllApplicationsControllerProps) {
  const [state, send] = useMachine(machine, options);
  const isBoard = useIsBoard();
  const { artists } = useAuth();
  const hasBindingToArtist = Boolean(
    artists.find((artist) => artist.id === state.context.artistId)
  );

  return (
    <AllApplicationsView
      state={state}
      send={send}
      showBoardVersion={isBoard && !hasBindingToArtist}
    />
  );
}
