import { useActor } from '@xstate/react';
import { useIsAdmin } from '../../../hooks/useIsAdmin';
import { ArtistDetailsBlockMachineActor } from '../../../machines/components/ArtistDetailsBlockMachine';
import { ArtistDetailsBlockView } from './ArtistDetailsBlockView';

interface ArtistDetailsBlockControllerProps {
  actor: ArtistDetailsBlockMachineActor;
}

export function ArtistDetailsBlockController({
  actor,
}: ArtistDetailsBlockControllerProps) {
  const [state, send] = useActor(actor);
  const isAdmin = useIsAdmin();

  return <ArtistDetailsBlockView state={state} send={send} isAdmin={isAdmin} />;
}
