import { useActor } from '@xstate/react';
import { ArtistManagementMachineActor } from '../../../machines/components/ArtistManagementMachine';
import { ArtistManagementView } from './ArtistManagementView';
import { useIsAdmin } from '../../../hooks/useIsAdmin';

interface ArtistManagementControllerProps {
  actor: ArtistManagementMachineActor;
}

export function ArtistManagementController({
  actor,
}: ArtistManagementControllerProps) {
  const [state, send] = useActor(actor);
  const isAdmin = useIsAdmin();

  return <ArtistManagementView state={state} send={send} isAdmin={isAdmin} />;
}
