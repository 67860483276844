import type {
  Sender,
  EventFrom,
  MachineOptionsFrom,
  ActorRefFrom,
} from 'xstate';
import { assign, createMachine, sendParent } from 'xstate';
import { ZonedDateTime } from '@internationalized/date';
import { StateFrom } from '../../utils/StateFrom';
import { refreshApplication } from '../../events/RefreshApplication';
import { logMachineError } from '../../utils/logError';

type Events =
  | {
      type: 'SAVE_STATEMENT';
      value: string;
    }
  | { type: 'CLEAR_ERROR' };

export type Context = {
  applicationId: string;
  statement: string;
  submittedAt: ZonedDateTime | null;
  previousStatement?: string;
};

export const createContext = (
  applicationId: string,
  statement: string,
  submittedAt: ZonedDateTime | null
) => ({
  applicationId,
  statement,
  submittedAt,
});

export const machine = createMachine(
  {
    predictableActionArguments: true,
    tsTypes:
      {} as import('./ApplicationStatementBlockMachine.typegen').Typegen0,
    schema: {
      context: {} as Context,
      events: {} as Events,
    },
    id: 'form',
    initial: 'default',
    states: {
      default: {},
      error: { entry: 'logMachineError' },
      invalid: {},
      save: {
        invoke: {
          id: 'updateApplication',
          src: 'updateApplication',
          onDone: {
            actions: 'sendRefreshApplication',
            target: 'default',
          },
          onError: {
            actions: 'resetStatement',
            target: 'error',
          },
        },
      },
    },
    on: {
      SAVE_STATEMENT: [
        {
          actions: 'saveStatement',
          target: 'save',
        },
      ],
      CLEAR_ERROR: { target: 'default' },
    },
  },
  {
    actions: {
      logMachineError,
      saveStatement: assign(({ statement }, { value }) => ({
        statement: value,
        previousStatement: statement,
      })),
      resetStatement: assign(({ previousStatement }) => ({
        statement: previousStatement,
        previousStatement: undefined,
      })),
      sendRefreshApplication: sendParent(refreshApplication()),
    },
  }
);

type Machine = typeof machine;

export type ApplicationStatementBlockMachineState = StateFrom<Machine>;
export type ApplicationStatementBlockMachineSender = Sender<EventFrom<Machine>>;
export type ApplicationStatementBlockMachineOptions = MachineOptionsFrom<
  Machine,
  true
> & {
  context: Context;
};
export type ApplicationStatementBlockMachineEvent = EventFrom<Machine>;
export type ApplicationStatementBlockMachineActor = ActorRefFrom<Machine>;
