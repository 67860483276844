import { isDateOnlyISOString } from './isDateOnlyISOString';
import { isValidDate } from './isValidDate';

export const formatDate = (
  date: string,
  monthFormat: Intl.DateTimeFormatOptions['month'] = 'long'
) => {
  const dateFormat = new Date(date);

  return isValidDate(dateFormat)
    ? dateFormat.toLocaleDateString('en-CA', {
        month: monthFormat,
        day: 'numeric',
        year: 'numeric',
        timeZone: isDateOnlyISOString(date) ? 'UTC' : 'America/Toronto',
      })
    : '—';
};
