import { match, P } from 'ts-pattern';
import { Block } from '../Block';
import { Button } from '../Button';

import {
  ContactsBlockMachineSender,
  ContactsBlockMachineState,
} from '../../machines/components/ContactsBlockMachine';
import { InlineFormContainer } from '../InlineFormContainer';
import { FormField } from '../FormField';
import { TextInput } from '../TextInput';
import { Select } from '../Select';
import { CheckOption } from '../CheckOption';
import { FieldSet } from '../FieldSet';
import { FormFieldLabel } from '../FormFieldLabel';
import { ErrorFeedback } from '../ErrorFeedback';

import styles from './ContactsBlockView.module.scss';

interface ContactsBlockViewProps {
  state: ContactsBlockMachineState;
  send: ContactsBlockMachineSender;
}

export function ContactsBlockView({ state, send }: ContactsBlockViewProps) {
  const {
    primaryContact,
    linkedAccounts,
    contacts: secondaryContacts,
    isFormOpen,
  } = state.context;

  const optionalContacts = linkedAccounts.filter(
    ({ accountId }) => accountId !== state.context.primaryContact.accountId
  );
  const linkedContacts = linkedAccounts.filter((contact) =>
    secondaryContacts.some((account) => account.email === contact.email)
  );
  const customContacts = secondaryContacts.filter(
    (contact) =>
      !linkedContacts.some((account) => account.email === contact.email)
  );

  return (
    <Block id="Contacts" headerText="Contacts" iconName="mail">
      {match(state.context.submittedAt)
        .with(P.not(P.nullish), () => (
          <dl className={styles.summary}>
            <div>
              <dt>Primary application contact: </dt>
              <dd>
                <span className="body-text-bold">{primaryContact.name} </span>
                &lt;
                {primaryContact.email}&gt;
              </dd>
            </div>
            {linkedContacts.length > 0 && (
              <div>
                <dt className={styles.block}>Other contacts to be notified:</dt>
                <dd>
                  <ul className={styles.list}>
                    {linkedContacts.map(({ email, name }) => (
                      <li key={email}>
                        <span className="body-text-bold">{name}</span> &lt;
                        {email}&gt;
                      </li>
                    ))}
                  </ul>
                </dd>
              </div>
            )}
            {customContacts.length > 0 && (
              <div>
                <dt>Additional email contacts: </dt>
                <dd className="body-text-bold">
                  {customContacts.map(({ email }) => email).join(', ')}
                </dd>
              </div>
            )}
          </dl>
        ))
        .otherwise(() => (
          <>
            <p>
              Choose who will be the primary contact for the application and
              other accounts and addresses to be notified of application status.
            </p>
            {state.matches('error') && <ErrorFeedback pulse={true} />}
            {optionalContacts.length === 0 ? (
              <>
                <FormFieldLabel label="Primary Contact" />
                <p>
                  {primaryContact.name} {`<${primaryContact.email}>`}
                </p>
              </>
            ) : (
              <>
                <FormField
                  id="primary-contact"
                  label="Primary Contact"
                  feedbackType="warning"
                  formFieldStatus="default"
                >
                  <Select
                    id="primary-contact"
                    label="Email contact"
                    items={state.context.linkedAccounts}
                    itemToKey={(item) => item?.accountId}
                    selectedItem={primaryContact}
                    onChange={(account) =>
                      send({
                        type: 'SET_PRIMARY_CONTACT',
                        primaryContact: account,
                      })
                    }
                    getItemText={(item) => item.email}
                  />
                </FormField>
                <FormField
                  id="other-accounts"
                  label="Select other accounts to be notified of application status:"
                  feedbackType="warning"
                  formFieldStatus="default"
                >
                  <FieldSet>
                    {optionalContacts.map((account) => (
                      <CheckOption
                        key={account.email}
                        label={`${account.name} <${account.email}>`}
                        checked={secondaryContacts.some(
                          (contact) => contact.email === account.email
                        )}
                        onChange={(e) => {
                          e.target.checked
                            ? send({
                                type: 'SELECT_CONTACT',
                                email: account.email,
                              })
                            : send({
                                type: 'DELETE_CONTACT',
                                email: account.email,
                              });
                        }}
                      />
                    ))}
                  </FieldSet>
                </FormField>
              </>
            )}
            <div className={styles.contacts}>
              {customContacts.map((contact) => (
                <div className={styles.contact} key={contact.email}>
                  <p>{contact.email}</p>
                  <Button
                    variant="neutral"
                    size="small"
                    label="Remove"
                    onClick={() =>
                      send({ type: 'DELETE_CONTACT', email: contact.email })
                    }
                  />
                </div>
              ))}
            </div>
            {isFormOpen ? (
              <div>
                <InlineFormContainer
                  primaryButtonText="Add Email"
                  primaryButtonOnClick={() =>
                    send({ type: 'ADD_CONTACT', email: state.context.email })
                  }
                  secondaryButtonText="Cancel"
                  secondaryButtonOnClick={() => send('CANCEL_FORM')}
                >
                  <FormField
                    id="email"
                    label="Email contact"
                    feedbackType="warning"
                    formFieldStatus={
                      state.matches('invalid') ? 'error' : 'default'
                    }
                    infoLabel={match(state)
                      .when(
                        () => state.matches('invalid.default'),
                        () => "This email isn't valid."
                      )
                      .when(
                        () => state.matches('invalid.duplicate'),
                        () => 'This email was already added.'
                      )
                      .otherwise(() => '')}
                  >
                    <TextInput
                      id="email"
                      label="Email contact"
                      value={state.context.email}
                      onChange={(email) => send({ type: 'SET_EMAIL', email })}
                      onBlur={() => send({ type: 'BLUR_EMAIL' })}
                    />
                  </FormField>
                </InlineFormContainer>
              </div>
            ) : (
              <Button
                variant="addnew"
                label="Add Another Email Contact"
                onClick={() => send({ type: 'OPEN_FORM' })}
              />
            )}
          </>
        ))}
    </Block>
  );
}
