import { GraphQLClient } from 'graphql-request';
import { z } from 'zod';
import { GetBoardMembersDocument } from '../../graphql/operations';
import { boardMemberSchema } from '../../schemas/boardMemberSchema';
import { contactSchema } from '../../schemas/contactSchema';

export const createGetBoardMembersPromise =
  (client: GraphQLClient) => async () => {
    const result = await client.request(GetBoardMembersDocument);
    return z
      .object({
        boardMembers: z.array(boardMemberSchema),
        pendingMembers: z.array(contactSchema),
      })
      .parse(result);
  };

export type GetBoardMembersPromise = ReturnType<
  typeof createGetBoardMembersPromise
>;

export type GetBoardMembersPromiseResult = Awaited<
  ReturnType<GetBoardMembersPromise>
>;
