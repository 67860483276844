import { GraphQLClient } from 'graphql-request';
import usePromise from 'react-use-promise';
import { Loading } from '../../components/Loading';
import { isIdNotFound } from '../../errors/IdNotFoundError';
import { createGetAllReleasesPagePromise } from '../../promises/createGetAllReleasesPagePromise';
import { withDelay } from '../../utils/withDelay';
import { withErrorLogging } from '../../utils/withErrorLogging';
import { ErrorPage } from '../ErrorPage';
import { NotFound } from '../NotFound';
import { AllReleasesView } from './AllReleasesView';

type Props = {
  client: GraphQLClient;
  artistId: string;
};

export function AllReleasesController({ client, artistId }: Props) {
  const [data, error, state] = usePromise(
    withDelay(
      withErrorLogging(createGetAllReleasesPagePromise(client, { artistId }))
    ),
    []
  );

  if (state === 'pending') {
    return <Loading />;
  }

  if (state === 'rejected') {
    return isIdNotFound(error) ? <NotFound /> : <ErrorPage />;
  }

  return <AllReleasesView data={data} />;
}
