import { ApplicationStage } from '../schemas/application/applicationStageSchema';
import { FundingRequest } from '../schemas/fundingRequests/fundingRequestSchema';

export type UpdateFundingRequests = {
  type: 'UPDATE_REQUESTS';
  requests: FundingRequest[];
  maximumYearlyTotalForFundingRequests: number;
  remainingYearlyTotalForFundingRequests: number;
  stage: ApplicationStage;
};

export const updateFundingRequests = (
  requests: UpdateFundingRequests['requests'],
  maximumYearlyTotalForFundingRequests: number,
  remainingYearlyTotalForFundingRequests: number,
  stage: ApplicationStage
): UpdateFundingRequests => ({
  type: 'UPDATE_REQUESTS',
  requests,
  maximumYearlyTotalForFundingRequests,
  remainingYearlyTotalForFundingRequests,
  stage,
});
