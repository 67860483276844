import { GraphQLClient } from 'graphql-request';
import {
  VerifyNewsletterSubscriptionDocument,
  VerifyNewsletterSubscriptionMutationVariables,
} from '../graphql/operations';

export const createVerifyNewsletterSubscriptionPromise =
  (client: GraphQLClient) =>
  (variables: VerifyNewsletterSubscriptionMutationVariables) =>
    client.request(VerifyNewsletterSubscriptionDocument, variables);

export type VerifyNewsletterSubscriptionPromise = ReturnType<
  typeof createVerifyNewsletterSubscriptionPromise
>;
export type VerifyNewsletterSubscriptionPromiseResult = Awaited<
  ReturnType<VerifyNewsletterSubscriptionPromise>
>;
