import { Button } from '../../Button';
import { formatCalendarDate } from '../../../utils/formatCalendarDate';
import {
  SubmittedFundingRequestCardMachineSender,
  SubmittedFundingRequestCardMachineState,
} from '../../../machines/components/SubmittedFundingRequestCardMachine';

import styles from './FundingRequestCard.module.scss';
import { Icon } from '../../Icon';
import { parsePositiveFloat } from '../../../utils/parsePositiveFloat';
import { InlineFormHeading } from '../../InlineFormHeading';
import { ExpenseBlockController } from '../Expenses/ExpenseBlockController';
import {
  ApplicationStatus,
  FundingRequestStatus,
} from '../../../graphql/operations';
import { formatMoney } from '../../../utils/formatMoney';

interface SubmittedFundingRequestCardProps {
  state: SubmittedFundingRequestCardMachineState;
  send: SubmittedFundingRequestCardMachineSender;
  showExpenseEdit: boolean;
  showExpenseDelete: boolean;
}

export function SubmittedFundingRequestCardView({
  state,
  send,
  showExpenseEdit,
  showExpenseDelete,
}: SubmittedFundingRequestCardProps) {
  const { request, applicationStatus } = state.context;

  const shouldShowApproval =
    applicationStatus === ApplicationStatus.Approved &&
    request.status === FundingRequestStatus.Approved;
  const shouldShowExpenses = request.expenses.length > 0;

  return (
    <div className={styles.container} id={request.id}>
      <div className={styles.row}>
        <div className={styles.col}>
          <h3 className={styles.heading}>{request.fundingType}</h3>
          <div className={styles.darkerBold}>
            {formatCalendarDate(request.date, 'short')}
          </div>
        </div>
        <div className={styles.buttonsContainer}>
          <Button
            variant="secondary"
            size="small"
            label="Close"
            onClick={() => send({ type: 'CLOSE_REQUEST', id: request.id })}
          />
        </div>
      </div>
      <div className={styles.statuses}>
        <div>
          <span className="body-text-bold">
            ${parseFloat(request.cost).toLocaleString()}
          </span>{' '}
          requested
        </div>
        {shouldShowApproval && (
          <div className={styles.approved}>
            <Icon name="checkCircle" />
            <div>
              <span className="body-text-bold">
                ${parseFloat(request.amountApproved).toLocaleString()}
              </span>{' '}
              approved
            </div>
          </div>
        )}
      </div>
      {shouldShowExpenses && (
        <div className={styles.expenses}>
          <Icon name="file" />
          <span className="body-text-bold">
            {formatMoney(
              state.context.request.expenses.reduce(
                (acc, cur) => acc + parsePositiveFloat(cur.cost),
                0
              )
            )}
          </span>
          spent ({state.context.request.expenses.length} expense(s))
        </div>
      )}

      <div>
        <div className="body-text-bold">Digital content strategy:</div>
        <p className={styles.note}>{request.strategy || '-'}</p>
      </div>
      {shouldShowExpenses && (
        <div className={styles.expenseContainer}>
          <InlineFormHeading>Expense(s)</InlineFormHeading>
          <ExpenseBlockController
            actor={state.context.expenseBlockRef}
            showEdit={showExpenseEdit}
            showDelete={showExpenseDelete}
          />
        </div>
      )}
    </div>
  );
}
