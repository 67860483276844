import { match } from 'ts-pattern';
import {
  UpsertPaymentMachineState,
  UpsertPaymentMachineSender,
} from '../../machines/ClaimFunds/UpsertPaymentMachine';
import { DatePicker } from '../DatePicker/DatePicker';
import { Feedback } from '../Feedback';
import { FormField } from '../FormField';
import { FormFieldDuoInGrid } from '../FormFieldDuoInGrid';
import { InlineFormContainer } from '../InlineFormContainer';
import { InlineFormHeading } from '../InlineFormHeading';
import { TextArea } from '../TextArea';
import { TextInput } from '../TextInput';

interface UpsertPaymentViewProps {
  state: UpsertPaymentMachineState;
  send: UpsertPaymentMachineSender;
}

export function UpsertPaymentView({ state, send }: UpsertPaymentViewProps) {
  return (
    <InlineFormContainer
      primaryButtonText="Add Payment"
      primaryButtonOnClick={() => send({ type: 'SAVE' })}
      secondaryButtonText="Cancel"
      secondaryButtonOnClick={() => send({ type: 'CANCEL' })}
      feedbackTitle="A network error occurred."
      feedbackType="warning"
    >
      <InlineFormHeading>Add Payment</InlineFormHeading>
      <FormField
        id="transactionNumber"
        label="Transaction Number"
        feedbackType="warning"
        formFieldStatus={
          state.matches('inputting.transactionNumber.empty')
            ? 'error'
            : 'default'
        }
        infoLabel={match(state)
          .when(
            ({ matches }) => matches('inputting.transactionNumber.empty'),
            () => 'This field is required.'
          )
          .otherwise(() => '')}
      >
        <TextInput
          id="transactionNumber"
          label="Transaction Number"
          value={state.context.transactionNumber ?? ''}
          onChange={(transactionNumber) =>
            send({ type: 'UPDATE_TRANSACTION_NUMBER', transactionNumber })
          }
          onBlur={() => send({ type: 'BLUR_TRANSACTION_NUMBER' })}
        />
      </FormField>
      <FormFieldDuoInGrid
        left={{
          label: 'Amount',
          feedbackType: 'warning',
          formFieldStatus: state.matches('inputting.amount.empty')
            ? 'error'
            : 'default',
          infoLabel: match(state)
            .when(
              (state) => state.matches('inputting.amount.empty'),
              () => 'This field is required.'
            )
            .when(
              (state) => state.matches('inputting.amount.invalid'),
              () => 'This field must be numeric.'
            )
            .otherwise(() => ''),

          children: (
            <TextInput
              id="amount"
              value={state.context.amount}
              onChange={(amount) => {
                send({
                  type: 'UPDATE_AMOUNT',
                  amount,
                });
              }}
              onBlur={() => send({ type: 'BLUR_AMOUNT' })}
            />
          ),
        }}
        right={{
          label: 'Invoice Date',
          feedbackType: 'warning',
          formFieldStatus: state.matches('inputting.invoiceDate.empty')
            ? 'error'
            : 'default',
          infoLabel: match(state)
            .when(
              () => state.matches('inputting.invoiceDate.empty'),
              () => 'This field is required.'
            )
            .otherwise(() => ''),
          children: (
            <DatePicker
              aria-label="Invoice Date"
              value={state.context.invoiceDate ?? null}
              onChange={(invoiceDate) =>
                send({ type: 'UPDATE_INVOICE_DATE', invoiceDate })
              }
              onBlur={() => send({ type: 'BLUR_INVOICE_DATE' })}
            />
          ),
        }}
      />
      <FormField label="Notes">
        <TextArea
          label="Notes"
          size="small"
          id="notes"
          value={state.context.notes ?? ''}
          onChange={(notes) => {
            send({
              type: 'UPDATE_NOTES',
              notes,
            });
          }}
          onBlur={() => send({ type: 'BLUR_NOTES' })}
        />
      </FormField>
      {(state.matches('inputting.warning.on') ||
        state.matches('inputting.warning.pulse')) && (
        <Feedback
          type="warning"
          pulse={state.matches('inputting.warning.pulse')}
        >
          Please address all issues in the fields above.
        </Feedback>
      )}
    </InlineFormContainer>
  );
}
