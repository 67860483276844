import { GraphQLClient } from 'graphql-request';
import { DeleteContactDocument } from '../../graphql/operations';
import { z } from 'zod';
import { Context } from '../../machines/components/ContactsBlockMachine';

export const createDeleteContactPromise =
  (client: GraphQLClient) =>
  async (
    { applicationId, contacts, primaryContact }: Context,
    event: { type: string; email?: string }
  ) => {
    const email =
      event.email ||
      contacts.find(({ email }) => primaryContact.email === email)?.email;

    if (!email) {
      throw Error('Contact email not found.');
    }

    const result = (
      await client.request(DeleteContactDocument, {
        applicationId: applicationId,
        email,
      })
    ).deleteContactByApplicationIdAndEmail?.contact?.email;

    return z.string().parseAsync(result);
  };

export type DeleteContactPromise = ReturnType<
  typeof createDeleteContactPromise
>;

export type DeleteContactPromiseResult = Awaited<
  ReturnType<DeleteContactPromise>
>;
