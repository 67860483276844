import { ApplicationStage } from '../graphql/operations';

export function isReviewStage(stage: ApplicationStage) {
  return [
    ApplicationStage.Unsubmitted,
    ApplicationStage.ContractAndDirectDepositUploaded,
    ApplicationStage.ClaimsReopened,
  ].includes(stage);
}

export function hasSubmittedClaims(stage: ApplicationStage) {
  return [
    ApplicationStage.ClaimsSubmitted,
    ApplicationStage.ClaimsReopened,
    ApplicationStage.ClaimsResubmitted,
    ApplicationStage.Completed,
  ].includes(stage);
}
