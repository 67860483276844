import React from 'react';
import { Status as TooltipStatus } from './Tooltip';
import { Feedback, FeedbackSize, FeedbackType } from './Feedback';
import cn from 'classnames';
import { FormFieldLabel } from './FormFieldLabel';

import styles from './FormField.module.scss';

export interface FormFieldProps {
  id?: string;
  htmlFor?: string;
  label?: string;
  labelSize?: 'default' | 'large';
  stretch?: 'none' | 'full';
  direction?: 'horizontal' | 'vertical';
  formFieldStatus?: 'default' | 'text' | 'error';
  description?: string;
  infoLabel?: string;
  note?: string;
  children: React.ReactElement;
  className?: string;
  tooltipStatus?: TooltipStatus;
  tooltipContent?: React.ReactNode;
  feedbackTitle?: string;
  feedbackType?: FeedbackType;
  feedbackSize?: FeedbackSize;
}

export function FormField({
  id,
  htmlFor,
  label,
  labelSize = 'default',
  stretch = 'full',
  direction = 'vertical',
  formFieldStatus = 'default',
  description = '',
  infoLabel = '',
  note,
  children,
  className,
  tooltipStatus = 'default',
  tooltipContent,
  feedbackTitle,
  feedbackType = 'neutral',
  feedbackSize = 'compact',
}: FormFieldProps) {
  return (
    <div className={cn(styles.formfield, className)}>
      {label && (
        <div className={styles.row}>
          <div className={styles.labelContainer}>
            <div className={styles.labelRow}>
              <FormFieldLabel
                id={id}
                htmlFor={htmlFor}
                label={label}
                tooltipContent={tooltipContent}
                tooltipStatus={tooltipStatus}
                labelSize={labelSize}
                stretch={stretch}
                formFieldStatus={formFieldStatus}
              />
            </div>
            <span className={styles.description}>{description}</span>
          </div>

          {note && <div className={styles.requiredLabel}>{note}</div>}
        </div>
      )}

      <div className={cn(styles.options, styles[direction])}>
        {React.cloneElement(children, { formFieldStatus })}
      </div>

      {infoLabel && (
        <Feedback title={feedbackTitle} type={feedbackType} size={feedbackSize}>
          {infoLabel}
        </Feedback>
      )}
    </div>
  );
}
