import { GraphQLClient } from 'graphql-request';
import {
  GetRedeemArtistInvitationContextDocument,
  GetRedeemArtistInvitationContextQueryVariables,
  ArtistInvitationStatusResponseCode,
} from '../graphql/operations';

import { InvitationInvalidStatusError } from '../errors/InvitationInvalidStatusError';
import { redeemArtistInvitationContextSchema } from '../schemas/redeemArtistInvitationContextSchema';

export const createGetRedeemArtistInvitationContext =
  (
    client: GraphQLClient,
    variables: GetRedeemArtistInvitationContextQueryVariables
  ) =>
  async () => {
    const result = await client.request(
      GetRedeemArtistInvitationContextDocument,
      variables
    );

    const {
      status: { code, email },
      minimumPasswordLength,
    } = redeemArtistInvitationContextSchema.parse(result);

    if (
      code !== ArtistInvitationStatusResponseCode.Success &&
      code !== ArtistInvitationStatusResponseCode.Linkable
    ) {
      throw new InvitationInvalidStatusError();
    }

    return {
      tag: variables.tag,
      link: code === ArtistInvitationStatusResponseCode.Linkable,
      email,
      minimumPasswordLength,
      isOrion: false,
    };
  };

export type GetRedeemArtistInvitationContextPromise = ReturnType<
  typeof createGetRedeemArtistInvitationContext
>;
export type GetRedeemArtistInvitationContextPromiseResult = Awaited<
  ReturnType<GetRedeemArtistInvitationContextPromise>
>;
