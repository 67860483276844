import { useActor } from '@xstate/react';
import { FundingRequestsBlockMachineActor } from '../../machines/components/FundingRequestsBlockMachine';
import { FundingRequestsBlockView } from './FundingRequestsBlockView';

interface FundingRequestsBlockControllerProps {
  actor: FundingRequestsBlockMachineActor;
}

export function FundingRequestsBlockController({
  actor,
}: FundingRequestsBlockControllerProps) {
  const [state, send] = useActor(actor);

  return <FundingRequestsBlockView state={state} send={send} />;
}
