import { match } from 'ts-pattern';
import { ApplicationStage } from '../../graphql/operations';
import {
  DirectDepositsMachineSender,
  DirectDepositsMachineState,
} from '../../machines/ClaimFunds/DirectDepositsMachine';
import {
  DirectDepositMachineSender,
  DirectDepositMachineState,
} from '../../machines/ClaimFunds/DirectDepositMachine';

import { SetDepositInfoController } from './SetDepositInfoController';
import { SetDepositInfoMachineActor } from '../../machines/ClaimFunds/SetDepositInfoMachine';
import { DirectDepositAccountPreview } from './DirectDepositAccountPreview';
import { DepositInfoChooser } from './DepositInfoChooser';
import { formatDirectDepositNumbers } from './utils';
import { UpsertPaymentController } from './UpsertPaymentController';
import { UpsertPaymentMachineActor } from '../../machines/ClaimFunds/UpsertPaymentMachine';
import { hasSubmittedClaims } from '../../utils/applicationStages';
import styles from './ClaimFundsView.module.scss';

interface Props {
  directDepositsState: DirectDepositsMachineState;
  directDepositsSend: DirectDepositsMachineSender;
  touringDirectDepositState: DirectDepositMachineState;
  touringDirectDepositSend: DirectDepositMachineSender;
  fundingRequestDirectDepositState: DirectDepositMachineState;
  stage: ApplicationStage;
  claimedTotal: number;
}

export function CollapsedDepositInfoView({
  directDepositsState,
  directDepositsSend,
  touringDirectDepositState,
  touringDirectDepositSend,
  fundingRequestDirectDepositState,
  stage,
  claimedTotal,
}: Props) {
  if (
    !touringDirectDepositState.context.directDepositAccount ||
    !fundingRequestDirectDepositState.context.directDepositAccount
  ) {
    return null;
  }

  return (
    <div className={styles.previewWrapper}>
      <DirectDepositAccountPreview
        showChangeButton={match(stage)
          .with(
            ApplicationStage.ContractAndDirectDepositPending,
            ApplicationStage.ContractAndDirectDepositUploaded,
            () => true
          )
          .otherwise(() => false)}
        isAdmin={directDepositsState.context.isAdmin}
        title={formatDirectDepositNumbers(
          touringDirectDepositState.context.directDepositAccount
        )}
        totalPaid={touringDirectDepositState.context.directDepositAccount.paid}
        showClaimed={hasSubmittedClaims(stage)}
        claimedTotal={claimedTotal}
        payments={
          touringDirectDepositState.context.directDepositAccount.payments
        }
        paymentRefs={touringDirectDepositState.context.paymentRefs}
        approvedAmount={
          directDepositsState.context.approvedTotalForTourDates +
          directDepositsState.context.approvedTotalForFundingRequests
        }
        note="Tour and Digital Content Deposit Info"
        onChange={() =>
          directDepositsSend({ type: 'OPEN_DEPOSIT_INFO_CHOOSER' })
        }
        onAddPaymentClick={() =>
          touringDirectDepositSend({ type: 'ADD_PAYMENT' })
        }
      >
        {directDepositsState.matches('depositInfoChooser.choosing') && (
          <DepositInfoChooser send={directDepositsSend} />
        )}

        {touringDirectDepositState.context.directDepositAccount &&
          touringDirectDepositState.children.setDepositMachine && (
            <div>
              <SetDepositInfoController
                actor={
                  touringDirectDepositState.children
                    .setDepositMachine as SetDepositInfoMachineActor
                }
              />
            </div>
          )}

        {fundingRequestDirectDepositState.context.directDepositAccount &&
          fundingRequestDirectDepositState.children.setDepositMachine && (
            <div>
              <SetDepositInfoController
                actor={
                  fundingRequestDirectDepositState.children
                    .setDepositMachine as SetDepositInfoMachineActor
                }
              />
            </div>
          )}

        {touringDirectDepositState.children.upsertPaymentMachine && (
          <div>
            <UpsertPaymentController
              actor={
                touringDirectDepositState.children
                  .upsertPaymentMachine as UpsertPaymentMachineActor
              }
            />
          </div>
        )}
      </DirectDepositAccountPreview>
    </div>
  );
}
