import { match, Pattern } from 'ts-pattern';
import { Select } from '../Select';
import {
  ApplicationReviewStatus,
  ApplicationStatus,
  ApplicationType,
  SearchApplicationsAlternateRequest,
  ApplicationStage,
  SearchApplicationsQueryVariables,
} from '../../graphql/operations';
import { HorizontalRule } from '../HorizontalRule';
import { ToggleButton } from '../ToggleButton';
import { CheckOption } from '../CheckOption';
import { Button } from '../Button';

import styles from './SearchFilters.module.scss';

interface Props {
  roundTitle: string;
  roundTitles: string[];
  onRoundTitleChange: (value: string) => void;
  applicationType: SearchApplicationsQueryVariables['applicationType'] | 'ALL';
  onApplicationTypeChange: (
    value: SearchApplicationsQueryVariables['applicationType'] | 'ALL'
  ) => void;
  applicationStatus: SearchApplicationsQueryVariables['applicationStatus'];
  onApplicationStatusChange: (
    value: SearchApplicationsQueryVariables['applicationStatus']
  ) => void;
  stage: SearchApplicationsQueryVariables['stage'];
  onStageChange: (stage: SearchApplicationsQueryVariables['stage']) => void;
  alternateRequest: SearchApplicationsQueryVariables['alternateRequest'];
  onAlternateRequestChange: (
    altRequest: SearchApplicationsQueryVariables['alternateRequest']
  ) => void;
  reviewStatus: SearchApplicationsQueryVariables['reviewStatus'];
  onReviewStatusChange: (
    altRequest: SearchApplicationsQueryVariables['reviewStatus']
  ) => void;
  initialPaymentPaid: SearchApplicationsQueryVariables['initialPaymentPaid'];
  onInitialPaymentPaidChange: (
    initialPaymentPaid: SearchApplicationsQueryVariables['initialPaymentPaid']
  ) => void;
  onClear: () => void;
  hideClaimsFilters?: boolean;
}

export function SearchFilters({
  roundTitle,
  roundTitles,
  onRoundTitleChange,
  applicationType,
  onApplicationTypeChange,
  applicationStatus,
  onApplicationStatusChange,
  stage,
  onStageChange,
  alternateRequest,
  onAlternateRequestChange,
  reviewStatus,
  onReviewStatusChange,
  initialPaymentPaid,
  onInitialPaymentPaidChange,
  onClear,
  hideClaimsFilters,
}: Props) {
  const stages = match(stage)
    .with(undefined, () => [])
    .with(null, () => [])
    .with(Pattern.string, (str) => [str])
    .with(Pattern.array(Pattern.string), (arr) => arr)
    .otherwise(() => []);

  const alternateRequests = match(alternateRequest)
    .with(undefined, () => [])
    .with(null, () => [])
    .with(Pattern.string, (str) => [str])
    .with(Pattern.array(Pattern.string), (arr) => arr)
    .otherwise(() => []);

  const reviewStatuses = match(reviewStatus)
    .with(undefined, () => [])
    .with(null, () => [])
    .with(Pattern.string, (str) => [str])
    .with(Pattern.array(Pattern.string), (arr) => arr)
    .otherwise(() => []);

  const disableClaimsFilters = roundTitle === 'NOT_SUBMITTED';
  const disableReviewFilters = roundTitle === 'NOT_SUBMITTED';

  const showClearFilterButton =
    [...stages, ...alternateRequests, ...reviewStatuses].length > 0 ||
    (applicationType && applicationType !== 'ALL');

  return (
    <div className={styles.container}>
      <div className={styles.roundAndType}>
        <Select
          id="round"
          selectedItemClassName={styles.bold}
          items={roundTitles}
          itemToKey={(item) => item}
          selectedItem={roundTitle}
          onChange={onRoundTitleChange}
          getItemText={(item) => {
            if (item === 'ALL') {
              return 'All Rounds';
            } else if (item === 'NOT_SUBMITTED') {
              return 'Not Submitted';
            } else {
              return `Round ${item}`;
            }
          }}
        />
        <Select
          id="applicationType"
          selectedItemClassName={styles.bold}
          items={['ALL', ApplicationType.Core, ApplicationType.Orion]}
          itemToKey={(item) => item}
          selectedItem={applicationType ?? 'ALL'}
          onChange={onApplicationTypeChange}
          getItemText={(item) => {
            if (!item) {
              return 'Core and Orion';
            }
            const texts = {
              [ApplicationType.Core]: 'Core',
              [ApplicationType.Orion]: 'Orion',
              ALL: 'Core and Orion',
            };

            return texts[item];
          }}
        />
      </div>
      <HorizontalRule />
      <div className={styles.applicationStatuses}>
        {!hideClaimsFilters && (
          <>
            <div className={styles.toggleButtons}>
              <ToggleButton
                disabled={disableClaimsFilters}
                className={styles.toggleButton}
                text="Approved"
                value={applicationStatus === ApplicationStatus.Approved}
                onClick={() =>
                  onApplicationStatusChange(ApplicationStatus.Approved)
                }
              />
              <ToggleButton
                disabled={disableClaimsFilters}
                className={styles.toggleButton}
                text="Denied"
                value={applicationStatus === ApplicationStatus.Denied}
                onClick={() =>
                  onApplicationStatusChange(ApplicationStatus.Denied)
                }
              />
            </div>
            <div className={styles.stages}>
              <CheckOption
                label="Contract Pending"
                disabled={disableClaimsFilters}
                checked={stages?.includes(
                  ApplicationStage.ContractAndDirectDepositPending
                )}
                onChange={() =>
                  onStageChange(
                    toggleItemInArray(
                      stages,
                      ApplicationStage.ContractAndDirectDepositPending
                    )
                  )
                }
              />
              <CheckOption
                label="Contract Uploaded"
                disabled={disableClaimsFilters}
                checked={stages?.includes(
                  ApplicationStage.ContractAndDirectDepositUploaded
                )}
                onChange={() =>
                  onStageChange(
                    toggleItemInArray(
                      stages,
                      ApplicationStage.ContractAndDirectDepositUploaded
                    )
                  )
                }
              />
              <CheckOption
                label="Initial Payment Paid"
                disabled={disableClaimsFilters}
                checked={initialPaymentPaid ?? false}
                onChange={() =>
                  onInitialPaymentPaidChange(initialPaymentPaid ? null : true)
                }
              />
              <CheckOption
                label="Claims Submitted"
                disabled={disableClaimsFilters}
                checked={
                  stages?.includes(ApplicationStage.ClaimsSubmitted) ||
                  stages?.includes(ApplicationStage.ClaimsResubmitted)
                }
                onChange={() => {
                  onStageChange(
                    toggleItemInArray(
                      toggleItemInArray(
                        stages,
                        ApplicationStage.ClaimsSubmitted
                      ) || [],
                      ApplicationStage.ClaimsResubmitted
                    )
                  );
                }}
              />
              <CheckOption
                label="Completed"
                disabled={disableClaimsFilters}
                checked={stages?.includes(ApplicationStage.Completed)}
                onChange={() =>
                  onStageChange(
                    toggleItemInArray(stages, ApplicationStage.Completed)
                  )
                }
              />
            </div>
            <HorizontalRule />
            <div className={styles.toggleButtons}>
              <ToggleButton
                className={styles.toggleButton}
                disabled={disableReviewFilters}
                text="Unsettled"
                value={alternateRequests.includes(
                  SearchApplicationsAlternateRequest.Unsettled
                )}
                onClick={() =>
                  onAlternateRequestChange(
                    toggleItemInArray(
                      alternateRequests,
                      SearchApplicationsAlternateRequest.Unsettled
                    )
                  )
                }
              />
              <ToggleButton
                className={styles.toggleButton}
                disabled={disableReviewFilters}
                text="Pending"
                value={alternateRequests.includes(
                  SearchApplicationsAlternateRequest.Pending
                )}
                onClick={() =>
                  onAlternateRequestChange(
                    toggleItemInArray(
                      alternateRequests,
                      SearchApplicationsAlternateRequest.Pending
                    )
                  )
                }
              />
            </div>
            <HorizontalRule />
          </>
        )}
        <div className={styles.reviewStatus}>
          <span className={styles.reviewStatusLabel}>Review Status:</span>
          <div className={styles.reviewStatuses}>
            <CheckOption
              label="No Review Status"
              disabled={disableReviewFilters}
              checked={reviewStatuses?.includes(ApplicationReviewStatus.None)}
              onChange={() =>
                onReviewStatusChange(
                  toggleItemInArray(
                    reviewStatuses,
                    ApplicationReviewStatus.None
                  )
                )
              }
            />
            <CheckOption
              label="Recommended"
              disabled={disableReviewFilters}
              checked={reviewStatuses?.includes(
                ApplicationReviewStatus.Recommended
              )}
              onChange={() =>
                onReviewStatusChange(
                  toggleItemInArray(
                    reviewStatuses,
                    ApplicationReviewStatus.Recommended
                  )
                )
              }
            />
            <CheckOption
              label="Board Call"
              disabled={disableReviewFilters}
              checked={reviewStatuses?.includes(
                ApplicationReviewStatus.BoardCall
              )}
              onChange={() =>
                onReviewStatusChange(
                  toggleItemInArray(
                    reviewStatuses,
                    ApplicationReviewStatus.BoardCall
                  )
                )
              }
            />
            <CheckOption
              label="Not Recommended"
              disabled={disableReviewFilters}
              checked={reviewStatuses?.includes(
                ApplicationReviewStatus.NotRecommended
              )}
              onChange={() =>
                onReviewStatusChange(
                  toggleItemInArray(
                    reviewStatuses,
                    ApplicationReviewStatus.NotRecommended
                  )
                )
              }
            />
            <CheckOption
              label="Not Qualified"
              disabled={disableReviewFilters}
              checked={reviewStatuses?.includes(
                ApplicationReviewStatus.NotQualified
              )}
              onChange={() =>
                onReviewStatusChange(
                  toggleItemInArray(
                    reviewStatuses,
                    ApplicationReviewStatus.NotQualified
                  )
                )
              }
            />
          </div>
        </div>
      </div>
      {showClearFilterButton && (
        <Button
          label="Clear all filters"
          className={styles.clear}
          onClick={onClear}
          variant="underline"
        />
      )}
    </div>
  );
}

function removeItem<T>(arr: Array<T>, value: T): Array<T> {
  const newArray = [...arr];
  const index = newArray.indexOf(value);
  if (index > -1) {
    newArray.splice(index, 1);
  }
  return newArray;
}

function toggleItemInArray<T>(arr: T[], val: T) {
  const newArray = arr.includes(val) ? removeItem(arr, val) : [...arr, val];
  if (newArray.length === 0) {
    return undefined;
  }
  return newArray;
}
