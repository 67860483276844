import { GraphQLClient } from 'graphql-request';
import {
  CancelFundingRequestDocument,
  CancelFundingRequestResponseCode,
} from '../../graphql/operations';
import { z } from 'zod';
import { CancelFundingRequest } from '../../events/CancelFundingRequest';
import { upsertFundingRequestResultSchema } from '../../schemas/fundingRequests/upsertFundingRequestResultSchema';

export const createCancelFundingRequestPromise =
  (client: GraphQLClient) =>
  async (_: unknown, { id }: CancelFundingRequest) => {
    const response = await client.request(CancelFundingRequestDocument, {
      id,
    });

    return z
      .object({
        code: z.nativeEnum(CancelFundingRequestResponseCode),
        fundingRequest: upsertFundingRequestResultSchema,
      })
      .parse(response.cancelFundingRequest?.cancelFundingRequestResponse);
  };

export type CreateCancelFundingRequestPromise = ReturnType<
  typeof createCancelFundingRequestPromise
>;

export type CreateCancelFundingRequestPromiseResult = Awaited<
  ReturnType<CreateCancelFundingRequestPromise>
>;

export const isInvalidCode = ({
  code,
}: CreateCancelFundingRequestPromiseResult) =>
  code === CancelFundingRequestResponseCode.Invalid;
