import { z } from 'zod';
import { tourDateTypeSchema } from './tourDateTypeSchema';
import { countrySchema } from '../countrySchema';
import { localDateSchema, serverDateSchema } from '../dateSchema';

export const partialTourDateSchema = z.object({
  applicationId: z.string().uuid(),
  tourDateType: tourDateTypeSchema.optional(),
  confirmed: z.boolean().nullable(),
  tourDateCountry: countrySchema.optional(),
  countrySubdivision: z.string(),
  venueName: z.string(),
  venueCapacity: z.string(),
  averageTicketPrice: z.string(),
  paymentGuarantee: z.string(),
  bookingAgency: z.string(),
  bookingAgent: z.string(),
  promoter: z.string(),
  reason: z.string(),
  date: localDateSchema,
});

export type PartialTourDate = z.infer<typeof partialTourDateSchema>;

export const completeTourDateSchema = partialTourDateSchema.required().extend({
  confirmed: partialTourDateSchema.shape.confirmed.unwrap(),
  date: partialTourDateSchema.shape.date.unwrap(),
});

export type CompleteTourDate = z.infer<typeof completeTourDateSchema>;

import { TourDateStatus } from '../../graphql/operations';

export const tourDateSchema = completeTourDateSchema.extend({
  id: z.string().uuid(),
  isTooNew: z.boolean(),
  isTooOld: z.boolean(),
  isTooMany: z.boolean(),
  isEligible: z.boolean(),
  isOwner: z.boolean(),
  isWritable: z.boolean(),
  isCancellable: z.boolean(),
  meetsTicketedVenueCapacity: z.boolean(),
  sameDateLimit: z.number(),
  status: z.nativeEnum(TourDateStatus),
  date: serverDateSchema,
  venueCapacity: z.number().transform(String),
  cancelled: z.boolean(),
  alternate: z.boolean(),
});

export type TourDate = z.infer<typeof tourDateSchema>;
