import { ReactNode } from 'react';
import cn from 'classnames';
import styles from './GrayBox.module.scss';

interface GrayBoxProps {
  className?: string;
  children?: ReactNode;
}

export function GrayBox({ className, children }: GrayBoxProps) {
  return <div className={cn(styles.container, className)}>{children}</div>;
}
