import { GraphQLClient } from 'graphql-request';
import {
  AddPaymentDocument,
  UpdatePaymentDocument,
} from '../graphql/operations';
import { Context } from '../machines/ClaimFunds/UpsertPaymentMachine';
import { directDepositAccountSchema } from '../schemas/directDepositAccount';
import { parsePositiveFloat } from '../utils/parsePositiveFloat';

export const createUpsertPayment =
  (client: GraphQLClient) =>
  async ({
    id,
    applicationId,
    amount,
    transactionNumber,
    invoiceDate,
    notes,
    directDepositAccountId,
  }: Context) => {
    if (id) {
      const { result } = await client.request(UpdatePaymentDocument, {
        input: {
          id: id,
          paymentPatch: {
            applicationId,
            amount: amount ? String(parsePositiveFloat(amount)) : undefined,
            transactionNumber,
            invoiceDate: invoiceDate ? invoiceDate.toString() : undefined,
            notes: notes,
          },
        },
        directDepositAccountId,
        applicationId,
      });

      return directDepositAccountSchema.parse(
        result?.query?.directDepositAccount
      );
    }

    if (!amount || !transactionNumber || !invoiceDate) {
      throw new Error('Missing required properties for payment creation');
    }

    const { result } = await client.request(AddPaymentDocument, {
      input: {
        payment: {
          applicationId,
          directDepositAccountId,
          amount: String(parsePositiveFloat(amount)),
          transactionNumber,
          invoiceDate: invoiceDate.toString(),
          notes,
        },
      },
      directDepositAccountId: directDepositAccountId,
      applicationId,
    });

    return directDepositAccountSchema.parse(
      result?.query?.directDepositAccount
    );
  };

export type CreateUpsertPaymentPromise = ReturnType<typeof createUpsertPayment>;
export type CreateUpsertPaymentPromiseResult = Awaited<
  ReturnType<CreateUpsertPaymentPromise>
>;
