import { useNavigate, useParams } from 'react-router-dom';
import { AllReleasesController } from './AllReleasesController';
import { useGraphQLClient } from '../../components/GraphQLClientProvider';

export const AllReleases = () => {
  const client = useGraphQLClient();
  const { artistId } = useParams();
  const navigate = useNavigate();

  if (!artistId) {
    navigate('/dashboard');
    return null;
  }

  return <AllReleasesController client={client} artistId={artistId} />;
};
