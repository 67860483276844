import { z } from 'zod';

export const signOutSuccessResponseSchema = z.object({
  message: z.literal('signed-out'),
});

export const signOutResponseSchema = z.union([
  signOutSuccessResponseSchema,
  z.object({
    message: z.literal('internal-error'),
  }),
]);
