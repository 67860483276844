import { useState } from 'react';
import cn from 'classnames';
import { match } from 'ts-pattern';

import { Icon, IconName } from './Icon';
import { Article } from './Article';

import styles from './Accordion.module.scss';

interface AccordionProps {
  id: string;
  className?: string;
  title: string | React.ReactNode;
  forceOpen?: boolean;
  children: React.ReactNode;
  headerClassName?: string;
  contentClassName?: string;
  isLoading?: boolean;
  onToggleOpen?: () => void;
}

export function Accordion({
  id,
  className,
  title,
  forceOpen = false,
  children,
  headerClassName,
  contentClassName,
  isLoading,
  onToggleOpen,
}: AccordionProps) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <details
      id={id}
      className={cn(styles.accordion, className, {
        [styles.disabled]: isLoading,
      })}
      onToggle={({ currentTarget, target }) => {
        // this target condition is needed to handle nested accordions
        if (currentTarget === target) {
          if (onToggleOpen && !isOpen) {
            onToggleOpen();
          }
          setIsOpen(!isOpen);
        }
      }}
      open={isLoading ? false : forceOpen}
    >
      <summary className={cn(styles.row, styles.summary, headerClassName)}>
        <Icon
          className={styles.icon}
          name={match<{ isOpen: boolean; isLoading?: boolean }, IconName>({
            isLoading,
            isOpen,
          })
            .with({ isLoading: true }, () => 'spinner')
            .with({ isOpen: true }, () => 'upChevron')
            .otherwise(() => 'downChevron')}
        />
        {typeof title === 'string' ? (
          <h4 className={styles.title}>{title}</h4>
        ) : (
          title
        )}
      </summary>
      <Article className={cn(styles.content, contentClassName)}>
        {children}
      </Article>
    </details>
  );
}
