import { CompleteFundingRound } from '../schemas/fundingRound/fundingRoundSchema';

export type AddFundingRound = {
  type: 'ADD_FUNDING_ROUND';
  round: CompleteFundingRound;
};

export const addFundingRound = (
  round: AddFundingRound['round']
): AddFundingRound => ({ type: 'ADD_FUNDING_ROUND', round });
