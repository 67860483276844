import { match } from 'ts-pattern';
import type {
  Sender,
  EventFrom,
  MachineOptionsFrom,
  ActorRefFrom,
} from 'xstate';
import { createMachine, assign, sendParent } from 'xstate';
import { CreateSinglePromiseResult } from '../../promises/createSinglePromise';
import { UpdateSinglePromiseResult } from '../../promises/updateSinglePromise';
import { logMachineError } from '../../utils/logError';
import { parsePositiveInteger } from '../../utils/parsePositiveInteger';
import { StateFrom } from '../../utils/StateFrom';
import {
  closeAddSingle,
  closeEditSingle,
  updateCachedRelease,
} from './ReleaseSinglesMachine';

type Events =
  | { type: 'ATTEMPT_SAVE' }
  | { type: 'ATTEMPT_UPDATE' }
  | { type: 'CANCEL_EDITING' }
  | { type: 'CANCEL_UPDATE_EDITING' }
  | { type: 'UPDATE_TITLE'; title: string }
  | { type: 'BLUR_TITLE' }
  | { type: 'UPDATE_SPINS'; spins: string }
  | { type: 'BLUR_SPINS' }
  | { type: 'UPDATE_REF_ID'; refId: number };

export type Context = {
  mode: 'add' | 'update';
  refId: number;
  id: string;
  releaseId: string;
  title: string;
  spins: string;
};

type Services = {
  addSingle: {
    data: CreateSinglePromiseResult;
  };
  updateSingle: {
    data: UpdateSinglePromiseResult;
  };
};

export const machine = createMachine(
  {
    id: 'releaseSingleMachine',
    predictableActionArguments: true,
    tsTypes: {} as import('./ReleaseSingleMachine.typegen').Typegen0,
    schema: {
      context: {} as Context,
      events: {} as Events,
      services: {} as Services,
    },
    type: 'parallel',
    states: {
      ui: {
        initial: 'edit',
        states: {
          edit: {
            on: {
              UPDATE_TITLE: {
                actions: 'updateTitle',
              },
              BLUR_TITLE: [
                {
                  cond: 'isEmpty',
                  target: '#releaseSingleMachine.title.error.empty',
                },
                {
                  target: '#releaseSingleMachine.title.noError',
                },
              ],
              UPDATE_SPINS: {
                actions: 'updateSpins',
              },
              BLUR_SPINS: [
                {
                  cond: 'isEmpty',
                  target: '#releaseSingleMachine.spins.error.empty',
                },
                {
                  cond: 'isNotNumeric',
                  target: '#releaseSingleMachine.spins.error.incorrectType',
                },
                {
                  target: '#releaseSingleMachine.spins.noError',
                },
              ],
              ATTEMPT_SAVE: [
                {
                  cond: 'isFormValid',
                  target: 'saving',
                },
                {
                  target:
                    '#releaseSingleMachine.wholeForm.error.invalid.highlight',
                },
              ],
              ATTEMPT_UPDATE: [
                {
                  cond: 'isFormValid',
                  target: 'updating',
                },
                {
                  target:
                    '#releaseSingleMachine.wholeForm.error.invalid.highlight',
                },
              ],
              CANCEL_EDITING: { actions: 'cancelEditing' },
              CANCEL_UPDATE_EDITING: { actions: 'cancelUpdateEditing' },
            },
          },
          saving: {
            invoke: {
              src: 'addSingle',
              onDone: [
                {
                  actions: ['updateParentCachedRelease', 'cancelEditing'],
                },
              ],
              onError: [
                {
                  cond: 'isNetworkError',
                  target: [
                    '#releaseSingleMachine.wholeForm.error.network',
                    'edit',
                  ],
                },
                {
                  target: [
                    '#releaseSingleMachine.wholeForm.error.internal',
                    'edit',
                  ],
                },
              ],
            },
          },
          updating: {
            invoke: {
              src: 'updateSingle',
              onDone: [
                {
                  actions: ['updateParentCachedRelease', 'cancelUpdateEditing'],
                },
              ],
              onError: [
                {
                  cond: 'isNetworkError',
                  target: [
                    '#releaseSingleMachine.wholeForm.error.network',
                    'edit',
                  ],
                },
                {
                  target: [
                    '#releaseSingleMachine.wholeForm.error.internal',
                    'edit',
                  ],
                },
              ],
            },
          },
        },
      },
      title: {
        initial: 'noError',
        states: {
          noError: {},
          error: {
            initial: 'empty',
            states: {
              empty: {},
            },
          },
        },
      },
      spins: {
        initial: 'noError',
        states: {
          noError: {},
          error: {
            initial: 'empty',
            states: {
              empty: {},
              incorrectType: {},
            },
          },
        },
      },
      wholeForm: {
        initial: 'noError',
        states: {
          noError: {},
          error: {
            initial: 'invalid',
            states: {
              invalid: {
                initial: 'default',
                states: {
                  default: {},
                  highlight: {
                    after: {
                      1000: { target: 'default' },
                    },
                  },
                },
              },
              network: {},
              internal: { entry: 'logMachineError' },
            },
          },
        },
      },
    },
    on: {
      UPDATE_REF_ID: {
        actions: 'updateRefId',
      },
    },
  },
  {
    guards: {
      isFormValid: (_, __, { state }) => {
        return ['title', 'spins'].every((field) =>
          state.matches(`${field}.noError`)
        );
      },
      isEmpty: ({ title, spins }, { type }) =>
        match(type)
          .with('BLUR_TITLE', () => title === '')
          .with('BLUR_SPINS', () => spins === '')
          .otherwise(() => false),
      isNotNumeric: ({ spins }, { type }) =>
        isNaN(
          parsePositiveInteger(
            match(type)
              .with('BLUR_SPINS', () => spins)
              .exhaustive()
              .trim()
          )
        ),
      isNetworkError: (_, event) => {
        if (event.data instanceof Error) {
          return /network/i.test(event.data.message);
        }
        return false;
      },
    },
    actions: {
      logMachineError,
      updateRefId: assign((_, { refId }) => ({ refId })),
      updateTitle: assign((_, { title }) => ({ title })),
      updateSpins: assign((_, { spins }) => ({ spins })),
      updateParentCachedRelease: sendParent((_, { data }) =>
        updateCachedRelease(data)
      ),
      cancelEditing: sendParent(({ refId }) => closeAddSingle(refId)),
      cancelUpdateEditing: sendParent(({ id }) => closeEditSingle(id)),
    },
  }
);

type Machine = typeof machine;

export type ReleaseSingleMachineState = StateFrom<Machine>;
export type ReleaseSingleMachineSender = Sender<EventFrom<Machine>>;
export type ReleaseSingleMachineOptions = MachineOptionsFrom<Machine, true> & {
  context: Context;
};
export type ReleaseSingleMachineEvent = EventFrom<Machine>;
export type ReleaseSingleMachineActor = ActorRefFrom<Machine>;
