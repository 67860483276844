import { useParams } from 'react-router-dom';
import { useGraphQLClient } from '../../components/GraphQLClientProvider';
import { SubscribeController } from './SubscribeController';
import { createVerifyNewsletterSubscriptionPromise } from '../../promises/createVerifyNewsletterSubscriptionPromise';

export const Subscribe = () => {
  const { tag } = useParams();
  const client = useGraphQLClient();

  if (!tag) {
    throw new Error('Tag not found.');
  }

  return (
    <SubscribeController
      options={{
        context: {
          tag,
        },
        services: {
          verifyNewsletterSubscriptionPromise:
            createVerifyNewsletterSubscriptionPromise(client),
        },
      }}
    />
  );
};
