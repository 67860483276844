import { useMachine } from '@xstate/react';
import {
  AccountsMachineOptions,
  machine,
} from '../../../machines/Admin/AccountsMachine';
import { AccountsView } from './AccountsView';

type AccountsControllerProps = {
  options: AccountsMachineOptions;
};

export function AccountsController({ options }: AccountsControllerProps) {
  const [state, send] = useMachine(machine, options);

  return <AccountsView state={state} send={send} />;
}
