import { GraphQLClient } from 'graphql-request';
import { GetReportsDocument } from '../graphql/operations';
import { annualReportsSchema } from '../schemas/reportsSchema';

export const createGetReportsPromise =
  (client: GraphQLClient, year?: number) => async () => {
    const result = await client.request(GetReportsDocument, {
      year,
    });

    return annualReportsSchema.parse(result);
  };

export type GetReportsPromise = ReturnType<typeof createGetReportsPromise>;
export type GetReportsPromiseResult = NonNullable<
  Awaited<ReturnType<GetReportsPromise>>
>;
