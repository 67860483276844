import { match } from 'ts-pattern';
import type {
  Sender,
  EventFrom,
  MachineOptionsFrom,
  ActorRefFrom,
} from 'xstate';
import { createMachine, assign, sendParent } from 'xstate';
import { UpdateReleaseResult } from '../../promises/createUpdateReleasePromise';
import { logMachineError } from '../../utils/logError';
import { StateFrom } from '../../utils/StateFrom';
import {
  cancelEditReleaseMasterOwnership,
  updateCachedRelease,
} from '../Release/ReleaseMachine';

type Events =
  | { type: 'ATTEMPT_SAVE' }
  | { type: 'EDIT' }
  | { type: 'CANCEL_EDITING' }
  | { type: 'UPDATE_COMPANY'; company: string }
  | { type: 'BLUR_COMPANY' }
  | { type: 'UPDATE_WEBSITE'; website: string }
  | { type: 'BLUR_WEBSITE' }
  | { type: 'UPDATE_CONTACT'; contact: string }
  | { type: 'BLUR_CONTACT' }
  | { type: 'UPDATE_ADDRESS'; address: string }
  | { type: 'BLUR_ADDRESS' }
  | { type: 'UPDATE_IN_CANADA'; inCanada: boolean }
  | { type: 'BLUR_IN_CANADA' }
  | { type: 'UPDATE_PHONE_NUMBER'; phoneNumber: string }
  | { type: 'BLUR_PHONE_NUMBER' }
  | { type: 'UPDATE_EMAIL'; email: string }
  | { type: 'BLUR_EMAIL' }
  | { type: 'UPDATE_NOTES'; notes: string }
  | { type: 'BLUR_NOTES' };

type MasterOwnership = {
  company: string;
  website?: string;
  contact: string;
  address: string;
  inCanada: boolean;
  phoneNumber: string;
  email: string;
  notes?: string;
};

export type Context = {
  releaseId: string;
  values: Partial<MasterOwnership>;
};

type Services = {
  updateRelease: {
    data: UpdateReleaseResult;
  };
};

export const createContext = (
  releaseId: string,
  values: Partial<MasterOwnership>
): Context => ({
  releaseId,
  values,
});

export const machine = createMachine(
  {
    id: 'releaseMasterOwnershipMachine',
    predictableActionArguments: true,
    tsTypes: {} as import('./ReleaseMasterOwnershipMachine.typegen').Typegen0,
    schema: {
      context: {} as Context,
      events: {} as Events,
      services: {} as Services,
    },
    type: 'parallel',
    states: {
      ui: {
        initial: 'edit',
        states: {
          edit: {
            on: {
              CANCEL_EDITING: {
                actions: 'cancelEdit',
              },
              UPDATE_COMPANY: {
                actions: 'updateCompany',
              },
              BLUR_COMPANY: [
                {
                  cond: 'isEmpty',
                  target: '#releaseMasterOwnershipMachine.company.error.empty',
                },
                {
                  target: '#releaseMasterOwnershipMachine.company.noError',
                },
              ],
              UPDATE_WEBSITE: {
                actions: 'updateWebsite',
              },
              BLUR_WEBSITE: [
                {
                  target: '#releaseMasterOwnershipMachine.website.noError',
                },
              ],
              UPDATE_CONTACT: {
                actions: 'updateContactPerson',
              },
              BLUR_CONTACT: [
                {
                  cond: 'isEmpty',
                  target: '#releaseMasterOwnershipMachine.contact.error.empty',
                },
                {
                  target: '#releaseMasterOwnershipMachine.contact.noError',
                },
              ],
              UPDATE_ADDRESS: {
                actions: 'updateAddress',
              },
              BLUR_ADDRESS: [
                {
                  cond: 'isEmpty',
                  target: '#releaseMasterOwnershipMachine.address.error.empty',
                },
                {
                  target: '#releaseMasterOwnershipMachine.address.noError',
                },
              ],
              UPDATE_IN_CANADA: {
                actions: 'updateInCanada',
              },
              BLUR_IN_CANADA: [
                {
                  target: '#releaseMasterOwnershipMachine.inCanada.noError',
                },
              ],
              UPDATE_PHONE_NUMBER: {
                actions: 'updatePhoneNumber',
              },
              BLUR_PHONE_NUMBER: [
                {
                  cond: 'isEmpty',
                  target:
                    '#releaseMasterOwnershipMachine.phoneNumber.error.empty',
                },
                {
                  target: '#releaseMasterOwnershipMachine.phoneNumber.noError',
                },
              ],
              UPDATE_EMAIL: {
                actions: 'updateEmail',
              },
              BLUR_EMAIL: [
                {
                  cond: 'isEmpty',
                  target: '#releaseMasterOwnershipMachine.email.error.empty',
                },
                {
                  target: '#releaseMasterOwnershipMachine.email.noError',
                },
              ],
              UPDATE_NOTES: {
                actions: 'updateNotes',
              },
              BLUR_NOTES: [
                {
                  target: '#releaseMasterOwnershipMachine.notes.noError',
                },
              ],
              ATTEMPT_SAVE: [
                {
                  cond: 'isFormValid',
                  target: 'saving',
                },
                {
                  target:
                    '#releaseMasterOwnershipMachine.wholeForm.error.invalid.highlight',
                },
              ],
            },
          },
          saving: {
            invoke: {
              src: 'updateRelease',
              onDone: [
                {
                  actions: ['updateParentCachedRelease', 'cancelEdit'],
                },
              ],
              onError: [
                {
                  cond: 'isNetworkError',
                  target: [
                    '#releaseMasterOwnershipMachine.wholeForm.error.network',
                    'edit',
                  ],
                },
                {
                  target: [
                    '#releaseMasterOwnershipMachine.wholeForm.error.internal',
                    'edit',
                  ],
                },
              ],
            },
          },
        },
      },
      company: {
        initial: 'noError',
        states: {
          noError: {},
          error: {
            initial: 'empty',
            states: {
              empty: {},
            },
          },
        },
      },
      website: {
        initial: 'noError',
        states: {
          noError: {},
        },
      },
      contact: {
        initial: 'noError',
        states: {
          noError: {},
          error: {
            initial: 'empty',
            states: {
              empty: {},
            },
          },
        },
      },
      address: {
        initial: 'noError',
        states: {
          noError: {},
          error: {
            initial: 'empty',
            states: {
              empty: {},
            },
          },
        },
      },
      inCanada: {
        initial: 'noError',
        states: {
          noError: {},
          error: {
            initial: 'empty',
            states: {
              empty: {},
            },
          },
        },
      },
      phoneNumber: {
        initial: 'noError',
        states: {
          noError: {},
          error: {
            initial: 'empty',
            states: {
              empty: {},
            },
          },
        },
      },
      email: {
        initial: 'noError',
        states: {
          noError: {},
          error: {
            initial: 'empty',
            states: {
              empty: {},
            },
          },
        },
      },
      notes: {
        initial: 'noError',
        states: {
          noError: {},
        },
      },
      wholeForm: {
        initial: 'noError',
        states: {
          noError: {},
          error: {
            initial: 'invalid',
            states: {
              invalid: {
                initial: 'default',
                states: {
                  default: {},
                  highlight: {
                    after: {
                      1000: { target: 'default' },
                    },
                  },
                },
              },
              network: {},
              internal: { entry: 'logMachineError' },
            },
          },
        },
      },
    },
  },
  {
    guards: {
      isFormValid: (_, __, { state }) => {
        return [
          'company',
          'website',
          'contact',
          'address',
          'inCanada',
          'phoneNumber',
          'email',
          'notes',
        ].every((field) => state.matches(`${field}.noError`));
      },
      isEmpty: ({ values }, { type }) =>
        match(type)
          .with('BLUR_COMPANY', () => Boolean(values?.company === ''))
          .with('BLUR_CONTACT', () => Boolean(values?.contact === ''))
          .with('BLUR_ADDRESS', () => Boolean(values?.address === ''))
          .with('BLUR_PHONE_NUMBER', () => Boolean(values?.phoneNumber === ''))
          .with('BLUR_EMAIL', () => Boolean(values?.email === ''))
          .otherwise(() => false),
      isNetworkError: (_, event) => {
        if (event.data instanceof Error) {
          return /network/i.test(event.data.message);
        }
        return false;
      },
    },
    actions: {
      logMachineError,
      updateCompany: assign((context, { company }) => ({
        values: {
          ...context.values,
          company,
        },
      })),
      updateWebsite: assign((context, { website }) => ({
        values: {
          ...context.values,
          website,
        },
      })),
      updateContactPerson: assign((context, { contact }) => ({
        values: {
          ...context.values,
          contact,
        },
      })),
      updateAddress: assign((context, { address }) => ({
        values: {
          ...context.values,
          address,
        },
      })),
      updateInCanada: assign((context, { inCanada }) => ({
        values: {
          ...context.values,
          inCanada,
        },
      })),
      updatePhoneNumber: assign((context, { phoneNumber }) => ({
        values: {
          ...context.values,
          phoneNumber,
        },
      })),
      updateEmail: assign((context, { email }) => ({
        values: {
          ...context.values,
          email,
        },
      })),
      updateNotes: assign((context, { notes }) => ({
        values: {
          ...context.values,
          notes,
        },
      })),
      updateParentCachedRelease: sendParent((_, { data }) =>
        updateCachedRelease(data)
      ),
      cancelEdit: sendParent(() => cancelEditReleaseMasterOwnership()),
    },
  }
);

type Machine = typeof machine;

export type ReleaseMasterOwnershipMachineState = StateFrom<Machine>;
export type ReleaseMasterOwnershipMachineSender = Sender<EventFrom<Machine>>;
export type ReleaseMasterOwnershipMachineOptions = MachineOptionsFrom<
  Machine,
  true
> & {
  context: Context;
};
export type ReleaseMasterOwnershipMachineEvent = EventFrom<Machine>;
export type ReleaseMasterOwnershipMachineActor = ActorRefFrom<Machine>;
