import { AllArtistsController } from './AllArtistsController';
import { useGraphQLClient } from '../../components/GraphQLClientProvider';
import { useAuth } from '../../components/AuthProvider';

export const AllArtists = () => {
  const client = useGraphQLClient();
  const { id } = useAuth();

  return <AllArtistsController client={client} accountId={id} />;
};
