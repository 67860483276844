import { GraphQLClient } from 'graphql-request';
import { GetAddArtistContextDocument } from '../graphql/operations';

export const createGetAddArtistContextPromise =
  (client: GraphQLClient) => async () => {
    const result = await client.request(GetAddArtistContextDocument);

    const countries = result.countries;
    if (!Array.isArray(countries) || countries.length === 0) {
      throw new Error('Unable to load any countries.');
    }

    const provinces = result.provinces;
    if (!Array.isArray(provinces) || provinces.length === 0) {
      throw new Error('Unable to load any provinces.');
    }

    const legalStatuses = result.legalStatuses;
    if (!Array.isArray(legalStatuses) || legalStatuses.length === 0) {
      throw new Error('Unable to load any legal statuses.');
    }

    return {
      countries,
      provinces,
      legalStatuses,
    };
  };

export type GetAddArtistContextPromise = ReturnType<
  typeof createGetAddArtistContextPromise
>;
export type GetAddArtistContextPromiseResult = Awaited<
  ReturnType<GetAddArtistContextPromise>
>;
