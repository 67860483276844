import z from 'zod';
import { boardMeetingBlockSchema } from './boardMeetingBlockSchema';
import { serverDateSchema } from './dateSchema';

export const boardMeetingContextQuerySchema = z.object({
  round: z
    .object({
      id: z.string(),
      title: z.string(),
      numberOfCoreApplications: z.number(),
      numberOfOrionApplications: z.number(),
    })
    .nullable(),
  nextMeetingDate: serverDateSchema.optional().nullable(),
  pageBlocks: boardMeetingBlockSchema.array(),
});

export type BoardMeetingContextQuery = z.infer<
  typeof boardMeetingContextQuerySchema
>;
