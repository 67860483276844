import { CompleteRedeemAccountInvitationState } from '../machines/RedeemAccountInvitation/state';
import { CompleteRedeemArtistInvitationState } from '../machines/RedeemArtistInvitation/state';
import { CompleteRedeemBoardInvitationState } from '../machines/RedeemBoardInvitation/state';
import { redeemInvitationResponseSchema } from '../schemas/redeemInvitationResponse';

const createRedeemInvitationPromise =
  <RedeemInvitationState>(
    url: string,
    mapper: (state: RedeemInvitationState) => Record<string, unknown>
  ) =>
  (tag: string) =>
  async (state: RedeemInvitationState) => {
    try {
      const response = await fetch(url, {
        method: 'POST',
        credentials: 'include',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          tag,
          ...mapper(state),
        }),
      });

      const result = redeemInvitationResponseSchema.parse(
        await response.json()
      );

      return result.message === 'signed-in'
        ? {
            success: response.ok,
            status: response.status,
            message: result.message,
            id: result.id,
            roles: result.roles,
            artists: result.artists,
          }
        : {
            success: response.ok,
            status: response.status,
            message: result.message,
          };
    } catch (e) {
      // Fetch API rejecting the promise means there was a network error.
      // Use `0` as a sentinel value to indicate this.
      return {
        success: false,
        status: 0,
        message: 'network-error' as const,
      };
    }
  };
export type RedeemInvitation = ReturnType<typeof createRedeemInvitationPromise>;

export type RedeemInvitationResult = Awaited<
  ReturnType<ReturnType<ReturnType<typeof createRedeemInvitationPromise>>>
>;
export const isSuccess = ({ message }: { message: string }) =>
  message === 'success';

export const isInvalidPassword = ({ message }: { message: string }) =>
  message === 'invalid-password';

export const redeemAccountInvitationPromise =
  createRedeemInvitationPromise<CompleteRedeemAccountInvitationState>(
    `${import.meta.env.VITE_API_BASE}/redeem-account-invitation`,
    ({ account, artist, company, management }) => ({
      account: {
        name: account.name,
        phoneNumber: account.phoneNumber,
        address: account.address,
        password: account.password,
      },
      artist: {
        name: artist.name,
        province: artist.province,
      },
      company,
      ...(artist.isSelfManaged ? {} : { management }),
    })
  );

export const redeemArtistInvitationPromise =
  createRedeemInvitationPromise<CompleteRedeemArtistInvitationState>(
    `${import.meta.env.VITE_API_BASE}/redeem-artist-invitation`,
    ({ account }) => ({
      account: {
        name: account.name,
        phoneNumber: account.phoneNumber,
        address: account.address,
        password: account.password,
      },
    })
  );

export const redeemBoardInvitationPromise =
  createRedeemInvitationPromise<CompleteRedeemBoardInvitationState>(
    `${import.meta.env.VITE_API_BASE}/redeem-board-member-invitation`,
    ({ account }) => ({
      account: {
        name: account.name,
        phoneNumber: account.phoneNumber,
        address: account.address,
        password: account.password,
      },
    })
  );
