import { z } from 'zod';

export const tourDateRegionSchema = z.object({
  id: z.string(),
  title: z.string(),
  totalTourDates: z.number(),
  maximumTourDates: z.number(),
  availableTourDates: z.number(),
});

export type TourDateRegion = z.infer<typeof tourDateRegionSchema>;
