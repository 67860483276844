import { GraphQLClient } from 'graphql-request';
import { Context } from '../../machines/Admin/ReopenClaimsMachine';
import { UpdateReopenClaimsNotesDocument } from '../../graphql/operations';

export const createUpdateReopenClaimsNotesPromise =
  (client: GraphQLClient) =>
  async ({ applicationId, notes }: Context) => {
    const request = await client.request(UpdateReopenClaimsNotesDocument, {
      id: applicationId,
      reopenClaimsNotes: notes ?? '',
    });

    return request.updateApplicationById?.application?.reopenClaimsNotes;
  };

export type CreateUpdateReopenClaimsNotesPromise = ReturnType<
  typeof createUpdateReopenClaimsNotesPromise
>;
export type CreateUpdateReopenClaimsNotesPromiseResult = Awaited<
  ReturnType<CreateUpdateReopenClaimsNotesPromise>
>;
