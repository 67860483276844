import { Icon } from '../Icon';
import { Button as ReactAriaButton } from 'react-aria-components';
import { AriaButtonProps } from 'react-aria';

import styles from './Chevron.module.scss';

interface ChevronProps {
  direction: 'left' | 'right';
  buttonProps?: AriaButtonProps<'button'>;
  onClick?: () => void;
}

export function Chevron({ direction, buttonProps, onClick }: ChevronProps) {
  const icon = direction === 'left' ? 'leftChevron' : 'rightChevron';

  return (
    <ReactAriaButton
      {...buttonProps}
      className={styles.chevron}
      {...(onClick && { onPress: onClick })}
    >
      <Icon name={icon} />
    </ReactAriaButton>
  );
}
