import { match } from 'ts-pattern';
import {
  ApplicationReviewMachineSender,
  ApplicationReviewMachineState,
} from '../../../machines/Admin/ApplicationReviewMachine';
import { Button } from '../../Button';
import { FormField } from '../../FormField';
import { TextInput } from '../../TextInput';
import { Form } from '../../Form';

import styles from './AddEmailRecipientForm.module.scss';

interface Props {
  state: ApplicationReviewMachineState;
  send: ApplicationReviewMachineSender;
}

export function AddEmailRecipientForm({ state, send }: Props) {
  return (
    <Form
      className={styles.addEmailRecipientForm}
      onSubmit={() => send({ type: 'SAVE_ADDITIONAL_EMAIL' })}
    >
      <div className={styles.addEmailRecipientFormTop}>
        <FormField
          label="Email Recipient"
          feedbackType="warning"
          formFieldStatus={
            state.matches(
              'status.none.approvalConfirmation.addEmailFormOpen.error'
            )
              ? 'error'
              : 'default'
          }
          infoLabel={match(state)
            .when(
              () =>
                state.matches(
                  'status.none.approvalConfirmation.addEmailFormOpen.error.empty'
                ),
              () => 'This field is required.'
            )
            .when(
              () =>
                state.matches(
                  'status.none.approvalConfirmation.addEmailFormOpen.error.value'
                ),
              () => `This email isn't valid.`
            )
            .otherwise(() => '')}
        >
          <TextInput
            id="email"
            onChange={(value) => {
              send({ type: 'UPDATE_ADD_EMAIL_INPUT_VALUE', value });
            }}
            onBlur={() => send({ type: 'BLUR_EMAIL_INPUT' })}
            value={state.context.addEmailInputValue ?? ''}
          />
        </FormField>
      </div>
      <div className={styles.addEmailRecipientFormBottom}>
        <Button type="submit" size="small" label="Add Email" />
        <Button
          size="small"
          variant="secondary"
          label="Cancel"
          onClick={() => send({ type: 'CLOSE_ADD_EMAIL_FORM' })}
        />
      </div>
    </Form>
  );
}
