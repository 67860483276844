import { CalendarDate } from '@internationalized/date';

const locale = 'en-CA';
const timeZone = 'UTC';

const options: Intl.DateTimeFormatOptions = {
  year: 'numeric',
  day: 'numeric',
  timeZone,
};

export const formatCalendarDate = (
  date: CalendarDate,
  month: Intl.DateTimeFormatOptions['month'] = 'long'
) => date.toDate(timeZone).toLocaleDateString(locale, { ...options, month });
