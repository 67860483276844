import cn from 'classnames';
import { ApplicationReviewMachineSender } from '../../../machines/Admin/ApplicationReviewMachine';
import { Button } from '../../Button';

import styles from './ResetStatusControl.module.scss';

interface ResetStatusControlProps {
  status: 'Approved' | 'Denied';
  send: ApplicationReviewMachineSender;
  disabled?: boolean;
}

export function ResetStatusControl({
  status,
  send,
  disabled,
}: ResetStatusControlProps) {
  return (
    <div
      className={cn(styles.controls, {
        [styles.denied]: status === 'Denied',
        [styles.approved]: status === 'Approved',
      })}
    >
      <span>Application {status}</span>
      <div>
        <Button
          size="small"
          disabled={disabled}
          variant="secondary"
          label="Reset Application Status"
          onClick={() => {
            send({
              type: 'RESET_STATUS',
            });
          }}
        />
      </div>
    </div>
  );
}
