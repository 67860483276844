import { ApplicationStage } from '../../graphql/operations';
import { GetDashboardContextPromiseResult } from '../../promises/getDashboardContextPromise';
import { InfoBox } from '../InfoBox';
import { Link } from '../Link';
import { ApplicationCard } from './ApplicationCard';

import styles from './ClosedApplicationsList.module.scss';

interface ClosedApplicationsListProps {
  applications: GetDashboardContextPromiseResult['closedApplications'];
  artistId: string;
}

export function ClosedApplicationsList({
  applications,
  artistId,
}: ClosedApplicationsListProps) {
  return (
    <div className={styles.container}>
      <div className={styles.heading}>
        <h3>Closed Applications</h3>
        <Link endIcon="rightChevron" href={`/artist/${artistId}/applications`}>
          View All
        </Link>
      </div>
      {applications.length ? (
        applications.map((application) => (
          <ApplicationCard
            key={application.id}
            type={application.type}
            round={application.fundingRound.title}
            submittedAt={application.submittedAt}
            createdAt={null}
            applicationLink={`/artist/${artistId}/application/${application.id}`}
            status={application.status}
            isComplete={application.stage === ApplicationStage.Completed}
          />
        ))
      ) : (
        <InfoBox>You don’t have any closed applications.</InfoBox>
      )}
    </div>
  );
}
