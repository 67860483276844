export const pluralize = (
  count: number,
  phrase: string,
  replaceNoun?: string
) => {
  if (count === 1) {
    return `1 ${phrase}`;
  }

  return (
    count.toString() +
    ' ' +
    (replaceNoun
      ? phrase.replace(replaceNoun, `${replaceNoun}s`)
      : `${phrase}s`)
  );
};
