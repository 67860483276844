import { ExpenseBlockView } from './ExpenseBlockView';
import { ExpenseBlockMachineActor } from '../../../machines/components/ExpenseBlockMachine';
import { useActor } from '@xstate/react';

interface ExpenseBlockControllerProps {
  actor: ExpenseBlockMachineActor;
  showEdit: boolean;
  showDelete: boolean;
}

export function ExpenseBlockController({
  actor,
  showEdit,
  showDelete,
}: ExpenseBlockControllerProps) {
  const [state, send] = useActor(actor);

  return (
    <ExpenseBlockView
      state={state}
      send={send}
      showEdit={showEdit}
      showDelete={showDelete}
    />
  );
}
