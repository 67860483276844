import { Button } from '../../../components/Button';
import { GrayBox } from '../../../components/GrayBox';

import styles from './MemberCard.module.scss';
interface MemberCardProps {
  title: string;
  isPending?: boolean;
  onRemove: () => void;
}

export function MemberCard({ title, isPending, onRemove }: MemberCardProps) {
  return (
    <GrayBox className={styles.box}>
      {isPending ? (
        <div className={styles.email}>
          <p>{title}</p>
          {isPending && <span className={styles.pending}>Pending</span>}
        </div>
      ) : (
        <p className={styles.name}>{title}</p>
      )}
      <Button
        onClick={onRemove}
        variant="neutral"
        label={isPending ? 'Cancel Invitation' : 'Remove'}
        length="auto"
        buttonClassName={styles.button}
      />
    </GrayBox>
  );
}
