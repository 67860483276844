import { Feedback } from '../Feedback';

interface ClaimSubmittedFeedbackProps {
  fundingType: string;
  roundTitle: string;
  isResubmission?: boolean;
}

export function ClaimSubmittedFeedback({
  fundingType,
  roundTitle,
  isResubmission,
}: ClaimSubmittedFeedbackProps) {
  return (
    <Feedback
      type="neutral"
      icon="clock"
      title={`Your funding claim for the ${fundingType} Application for round #${roundTitle} has been ${
        isResubmission ? 're' : ''
      }submitted.`}
    >
      You will be notified once the final funding is transferred to your
      account.
    </Feedback>
  );
}
