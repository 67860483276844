import { useLocation } from 'react-router-dom';
import { Helmet as ReactHelmet } from 'react-helmet';
import { getHelmetTitle } from '../utils/getHelmetTitle';

export function Helmet() {
  const location = useLocation();
  return (
    <ReactHelmet>
      <title>{getHelmetTitle(location.pathname)}</title>
    </ReactHelmet>
  );
}
