import { ReactNode } from 'react';
import cn from 'classnames';
import styles from './Form.module.scss';

interface FormProps {
  onSubmit: () => void;
  className?: string;
  children: ReactNode;
}

export function Form({ onSubmit, className, children }: FormProps) {
  return (
    <form
      className={cn(styles.formColumn, className)}
      onSubmit={(e) => {
        e.preventDefault();

        // blur active element to trigger validation before form submit.
        // This is a hack and validation should be triggered on submit.
        (document.activeElement as HTMLElement).blur();

        onSubmit();
      }}
    >
      {children}
    </form>
  );
}
