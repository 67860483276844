import { ReactComponent as FooterLogo } from '../assets/images/footer.svg';

import cn from 'classnames';

import styles from './Footer.module.scss';

export function Footer() {
  return (
    <footer className={cn(styles.footer, 'max-w-xl')}>
      <p>
        <span className={styles.line}>
          The Canadian Association of Broadcasters and Canada’s private radio
          broadcasters…
        </span>
        <span className={styles.line}>
          sole contributor and proud founding member of the Canadian Starmaker
          Fund
        </span>
      </p>
      <FooterLogo className={styles.footerLogo} />
    </footer>
  );
}
