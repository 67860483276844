import { ReactNode } from 'react';
import { useAuth } from './AuthProvider';
import { Loading } from './Loading';
import { match } from 'ts-pattern';
import { NotFound } from '../pages/NotFound';
import { Roles } from '../schemas/role';

interface Props {
  children: ReactNode;
  allowedRoles?: Roles;
}

export function OnlyWhenSignedIn({ children, allowedRoles }: Props) {
  const { isInitializing, isSignedIn, roles } = useAuth();

  return match({
    isInitializing,
    isSignedIn,
    isAllowed:
      !allowedRoles || roles.some((role) => allowedRoles.includes(role)),
  })
    .with({ isInitializing: true }, () => <Loading />)
    .with({ isSignedIn: true, isAllowed: true }, () => <>{children}</>)
    .otherwise(() => <NotFound />);
}
