import { GraphQLClient } from 'graphql-request';
import { Context, Events } from '../../machines/Admin/ApplicationReviewMachine';
import {
  UpdateApplicationReviewDocument,
  ApplicationStatus,
  UpdateApplicationReviewMutationVariables,
} from '../../graphql/operations';
import { adminApplicationFragmentSchema } from '../../schemas/admin/adminApplicationFragmentSchema';

type UpdateReviewStatusEvent = Extract<
  Events,
  { type: 'UPDATE_REVIEW_STATUS' }
>;

async function makeRequest(
  client: GraphQLClient,
  applicationId: string,
  patch: UpdateApplicationReviewMutationVariables['patch']
) {
  const request = await client.request(UpdateApplicationReviewDocument, {
    applicationId,
    patch,
  });

  const application = request.result?.query?.application;

  return adminApplicationFragmentSchema.parseAsync(application);
}

export const createUpdateApplicationReviewStatusPromise =
  (client: GraphQLClient) =>
  ({ applicationId }: Context, { reviewStatus }: UpdateReviewStatusEvent) => {
    return makeRequest(client, applicationId, { reviewStatus });
  };

export type CreateUpdateApplicationReviewStatusPromise = ReturnType<
  typeof createUpdateApplicationReviewStatusPromise
>;
export type CreateUpdateApplicationReviewStatusPromiseResult = Awaited<
  ReturnType<CreateUpdateApplicationReviewStatusPromise>
>;

export const createResetApplicationStatusPromise =
  (client: GraphQLClient) =>
  ({ applicationId }: Context) => {
    return makeRequest(client, applicationId, {
      status: ApplicationStatus.Submitted,
    });
  };

export type CreateResetApplicationStatusPromise = ReturnType<
  typeof createResetApplicationStatusPromise
>;
export type CreateResetApplicationStatusPromiseResult = Awaited<
  ReturnType<CreateResetApplicationStatusPromise>
>;

export const createDenyApplicationPromise =
  (client: GraphQLClient) =>
  ({ applicationId }: Context) => {
    return makeRequest(client, applicationId, {
      status: ApplicationStatus.Denied,
    });
  };

export type CreateDenyApplicationPromise = ReturnType<
  typeof createDenyApplicationPromise
>;
export type CreateDenyApplicationPromiseResult = Awaited<
  ReturnType<CreateDenyApplicationPromise>
>;
