import { GraphQLClient } from 'graphql-request';
import { z } from 'zod';
import {
  CreateBoardMemberInvitationDocument,
  CreateBoardMemberInvitationResponseCode,
} from '../../graphql/operations';
import { Context } from '../../machines/Admin/BoardMembersMachine';

const schema = z.discriminatedUnion('code', [
  z.object({
    code: z.literal(CreateBoardMemberInvitationResponseCode.AccountLinked),
    email: z.string(),
    accountId: z.string(),
    name: z.string(),
  }),
  z.object({
    code: z.literal(CreateBoardMemberInvitationResponseCode.InvitationCreated),
    email: z.string(),
  }),
  z.object({
    code: z.literal(CreateBoardMemberInvitationResponseCode.InvalidRequest),
  }),
]);

export const createAddBoardMemberPromise =
  (client: GraphQLClient) =>
  async ({ emailInput: email }: Context) => {
    const { result } = await client.request(
      CreateBoardMemberInvitationDocument,
      { email }
    );

    const response = schema.parse(result?.response);

    if (
      response.code === CreateBoardMemberInvitationResponseCode.InvalidRequest
    ) {
      throw new Error();
    }

    return response;
  };

export type AddBoardMemberPromise = ReturnType<
  typeof createAddBoardMemberPromise
>;

export type AddBoardMemberPromiseResult = Awaited<
  ReturnType<AddBoardMemberPromise>
>;
