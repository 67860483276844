import { signInResponseSchema } from '../schemas/signInResponse';

type Credentials = {
  username: string;
  password: string;
};

export const createSignInPromise = async ({
  username,
  password,
}: Credentials) => {
  try {
    const response = await fetch(`${import.meta.env.VITE_API_BASE}/sign-in`, {
      method: 'POST',
      credentials: 'include',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ username, password }),
    });

    const result = signInResponseSchema.parse(await response.json());

    return result.message === 'signed-in'
      ? {
          success: response.ok,
          status: response.status,
          message: result.message,
          id: result.id,
          roles: result.roles,
          artists: result.artists,
        }
      : {
          success: response.ok,
          status: response.status,
          message: result.message,
        };
  } catch (e) {
    // Fetch API rejecting the promise means there was a network error.
    // Use `0` as a sentinel value to indicate this.
    return {
      success: false,
      status: 0,
      message: 'network-error' as const,
    };
  }
};

export type CreateSignInPromise = typeof createSignInPromise;
export type CreateSignInPromiseResult = Awaited<
  ReturnType<CreateSignInPromise>
>;
