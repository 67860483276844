import { Card } from '../components/Card';
import { Footer } from '../components/Footer';
import { HeroSection } from '../components/HeroSection';
import { Icon } from '../components/Icon';
import { Link } from '../components/Link';
import cn from 'classnames';

import styles from './ContactUs.module.scss';

const RightPanel = () => (
  <div className={styles.rightPanel}>
    <Card title="Email" width="lg" display="row" icon="send">
      <Link className={styles.email} href="mailto:info@starmaker.ca">
        info@starmaker.ca
      </Link>
    </Card>
    <Card title="Mailing Address" width="lg" display="row" icon="mail">
      <p>Canadian Starmaker Fund</p>
      <p>PO Box 60037</p>
      <p>Toronto RPO Queen Beverly, ON M5V 0C5</p>
    </Card>
    <Card title="Phone" width="lg" display="row" icon="phone">
      <p>(416)&nbsp;597&#8209;6622</p>
    </Card>
  </div>
);

export function ContactUs() {
  return (
    <div className={styles.container}>
      <HeroSection
        title="Contact Us"
        breadcrumbs={[{ text: 'Home', to: '/' }]}
        rightPanel={<RightPanel />}
      >
        You can contact us with questions, comments, or suggestions by phone,
        email, or mail.
      </HeroSection>
      <div className={styles.contacts}>
        <div className={cn('max-w-xl', styles.cardRow)}>
          <Card
            icon="user"
            title="Chip Sutherland"
            subTitle="Executive Director"
            contentClassName={styles.card}
          >
            <div className={styles.row}>
              <Icon name="send" />
              <Link href="mailto:chipsutherland@starmaker.ca">
                chipsutherland@starmaker.ca
              </Link>
            </div>
          </Card>
          <Card
            icon="user"
            title="Jerry Leibowitz"
            subTitle="Vice President Programs & Applications"
            contentClassName={styles.card}
          >
            <div className={styles.row}>
              <Icon name="send" />
              <Link href="mailto:jerryleibowitz@starmaker.ca">
                jerryleibowitz@starmaker.ca
              </Link>
            </div>
          </Card>
          <Card
            icon="user"
            title="Michelle Farres"
            subTitle="Senior Manager, Finance & Programs"
            contentClassName={styles.card}
          >
            <div className={styles.row}>
              <Icon name="send" />
              <Link href="mailto:michellefarres@starmaker.ca">
                michellefarres@starmaker.ca
              </Link>
            </div>
          </Card>
        </div>
      </div>
      <Footer />
    </div>
  );
}
