import { useActor } from '@xstate/react';
import { UpsertPaymentMachineActor } from '../../machines/ClaimFunds/UpsertPaymentMachine';
import { UpsertPaymentView } from './UpsertPaymentView';

export function UpsertPaymentController({
  actor,
}: {
  actor: UpsertPaymentMachineActor;
}) {
  const [state, send] = useActor(actor);

  return <UpsertPaymentView state={state} send={send} />;
}
