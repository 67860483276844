import { useEffect } from 'react';
import classNames from 'classnames';
import { match } from 'ts-pattern';
import {
  ReleaseMasterOwnershipMachineSender,
  ReleaseMasterOwnershipMachineState,
} from '../../../machines/Release/ReleaseMasterOwnershipMachine';
import { Feedback } from '../../Feedback';
import { FormField } from '../../FormField';
import { InlineFormContainer } from '../../InlineFormContainer';
import { InlineFormHeading } from '../../InlineFormHeading';
import { Select } from '../../Select';
import { TextArea } from '../../TextArea';
import { TextInput } from '../../TextInput';
import { ErrorFeedback } from '../../ErrorFeedback';

interface ReleaseMasterOwnershipViewProps {
  state: ReleaseMasterOwnershipMachineState;
  send: ReleaseMasterOwnershipMachineSender;
}

export function ReleaseMasterOwnershipView({
  state,
  send,
}: ReleaseMasterOwnershipViewProps) {
  function blurAll() {
    send({
      type: 'BLUR_COMPANY',
    });
    send({
      type: 'BLUR_WEBSITE',
    });
    send({
      type: 'BLUR_CONTACT',
    });
    send({
      type: 'BLUR_ADDRESS',
    });
    send({
      type: 'BLUR_IN_CANADA',
    });
    send({
      type: 'BLUR_PHONE_NUMBER',
    });
    send({
      type: 'BLUR_EMAIL',
    });
    send({
      type: 'BLUR_NOTES',
    });
  }

  useEffect(() => blurAll(), []);
  const countryOptions = [
    { inCanada: true, label: 'Canada' },
    { inCanada: false, label: 'Outside of Canada' },
  ];

  const inCanada = state.context.values.inCanada;

  return (
    <>
      {match(state)
        .when(
          () => state.matches('ui.edit') || state.matches('ui.saving'),
          () => (
            <InlineFormContainer
              primaryButtonText={
                state.matches('ui.edit') ? 'Save Master Ownership' : 'Saving...'
              }
              primaryButtonDisabled={state.matches('ui.saving')}
              primaryButtonOnClick={() => {
                send({
                  type: 'ATTEMPT_SAVE',
                });
                blurAll();
              }}
              secondaryButtonText="Cancel"
              secondaryButtonOnClick={() => send({ type: 'CANCEL_EDITING' })}
            >
              <InlineFormHeading>Edit Master Ownership</InlineFormHeading>
              <FormField
                id="company"
                label="Company"
                feedbackType="warning"
                formFieldStatus={
                  state.matches('company.error') ? 'error' : 'default'
                }
                infoLabel={match(state)
                  .when(
                    ({ matches }) => matches('company.error.empty'),
                    () => 'This field is required.'
                  )
                  .otherwise(() => '')}
              >
                <TextInput
                  id="company"
                  label="company"
                  value={state.context.values.company ?? ''}
                  onChange={(company) =>
                    send({ type: 'UPDATE_COMPANY', company })
                  }
                  onBlur={() => send({ type: 'BLUR_COMPANY' })}
                />
              </FormField>
              <FormField
                id="website"
                label="Website"
                note="Optional"
                feedbackType="warning"
              >
                <TextInput
                  id="website"
                  label="website"
                  value={state.context.values.website ?? ''}
                  onChange={(website) =>
                    send({ type: 'UPDATE_WEBSITE', website })
                  }
                  onBlur={() => send({ type: 'BLUR_WEBSITE' })}
                />
              </FormField>
              <FormField
                id="contact"
                label="Contact person"
                feedbackType="warning"
                formFieldStatus={
                  state.matches('contact.error') ? 'error' : 'default'
                }
                infoLabel={match(state)
                  .when(
                    ({ matches }) => matches('contact.error.empty'),
                    () => 'This field is required.'
                  )
                  .otherwise(() => '')}
              >
                <TextInput
                  id="contact"
                  label="contact"
                  value={state.context.values.contact ?? ''}
                  onChange={(contact) =>
                    send({ type: 'UPDATE_CONTACT', contact })
                  }
                  onBlur={() => send({ type: 'BLUR_CONTACT' })}
                />
              </FormField>
              <FormField
                id="address"
                label="Address"
                description="Include street address, city, prov/state, country, and postal code."
                feedbackType="warning"
                formFieldStatus={
                  state.matches('address.error') ? 'error' : 'default'
                }
                infoLabel={match(state)
                  .when(
                    ({ matches }) => matches('address.error.empty'),
                    () => 'This field is required.'
                  )
                  .otherwise(() => '')}
              >
                <TextArea
                  id="address"
                  size="small"
                  label="address"
                  value={state.context.values.address ?? ''}
                  onChange={(address) =>
                    send({ type: 'UPDATE_ADDRESS', address })
                  }
                  onBlur={() => send({ type: 'BLUR_ADDRESS' })}
                />
              </FormField>
              <FormField
                id="country"
                label="Country"
                feedbackType="neutral"
                infoLabel={
                  inCanada
                    ? ''
                    : 'Masters must be owned by yourself or by a Canadian company.'
                }
              >
                <Select
                  id="country"
                  label="country"
                  items={countryOptions}
                  itemToKey={(item) => item?.label}
                  selectedItem={countryOptions.find(
                    (c) => c.inCanada === inCanada
                  )}
                  initialSelectedItem={countryOptions.find(
                    (c) => c.inCanada === inCanada
                  )}
                  onChange={(option) =>
                    option &&
                    send({
                      type: 'UPDATE_IN_CANADA',
                      inCanada: option.inCanada,
                    })
                  }
                  getItemText={(option) =>
                    option?.label ?? 'Please select a country'
                  }
                />
              </FormField>
              <FormField
                id="phoneNumber"
                label="Phone number"
                feedbackType="warning"
                formFieldStatus={
                  state.matches('phoneNumber.error') ? 'error' : 'default'
                }
                infoLabel={match(state)
                  .when(
                    ({ matches }) => matches('phoneNumber.error.empty'),
                    () => 'This field is required.'
                  )
                  .otherwise(() => '')}
              >
                <TextInput
                  id="phoneNumber"
                  label="phoneNumber"
                  value={state.context.values.phoneNumber ?? ''}
                  onChange={(phoneNumber) =>
                    send({ type: 'UPDATE_PHONE_NUMBER', phoneNumber })
                  }
                  onBlur={() => send({ type: 'BLUR_PHONE_NUMBER' })}
                />
              </FormField>
              <FormField
                id="email"
                label="Email"
                feedbackType="warning"
                formFieldStatus={
                  state.matches('email.error') ? 'error' : 'default'
                }
                infoLabel={match(state)
                  .when(
                    ({ matches }) => matches('email.error.empty'),
                    () => 'This field is required.'
                  )
                  .otherwise(() => '')}
              >
                <TextInput
                  id="email"
                  label="email"
                  value={state.context.values.email ?? ''}
                  onChange={(email) => send({ type: 'UPDATE_EMAIL', email })}
                  onBlur={() => send({ type: 'BLUR_EMAIL' })}
                />
              </FormField>
              <FormField
                id="notes"
                label="Additional notes"
                note="Optional"
                feedbackType="warning"
              >
                <TextArea
                  size="small"
                  id="notes"
                  label="notes"
                  value={state.context.values.notes ?? ''}
                  onChange={(notes) => send({ type: 'UPDATE_NOTES', notes })}
                  onBlur={() => send({ type: 'BLUR_NOTES' })}
                />
              </FormField>

              {state.matches('wholeForm.error.invalid') && (
                <Feedback
                  title="Please fill in all the fields above."
                  type="warning"
                  className={classNames({
                    'feedback-highlight': state.matches(
                      'wholeForm.error.invalid.highlight'
                    ),
                  })}
                />
              )}
              {state.matches('wholeForm.error.network') && (
                <ErrorFeedback type="network" />
              )}
              {state.matches('wholeForm.error.internal') && <ErrorFeedback />}
            </InlineFormContainer>
          )
        )
        .run()}
    </>
  );
}
