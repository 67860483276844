import { FormField, FormFieldProps } from './FormField';

interface RequiredFormFieldProps extends FormFieldProps {
  isEmpty: boolean;
  showWarning: boolean;
}

export function RequiredFormField(props: RequiredFormFieldProps) {
  const { isEmpty, showWarning, children, ...otherProps } = props;
  return (
    <FormField
      {...otherProps}
      formFieldStatus={isEmpty && showWarning ? 'error' : 'default'}
      feedbackType="warning"
      infoLabel={isEmpty && showWarning ? 'This field is required.' : ''}
    >
      {children}
    </FormField>
  );
}
