import { useActor } from '@xstate/react';
import { TourDateCardView } from './TourDateCardView';
import { TourDateCardMachineActor } from '../../../machines/components/TourDateCardMachine';

export function TourDateCardController({
  actor,
}: {
  actor: TourDateCardMachineActor;
}) {
  const [state, send] = useActor(actor);

  return <TourDateCardView state={state} send={send} />;
}
