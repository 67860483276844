import { ReactNode } from 'react';
import classNames from 'classnames';
import styles from './Article.module.scss';

interface ArticleProps {
  className?: string;
  children?: ReactNode;
}

export function Article({ className, children }: ArticleProps) {
  return (
    <div className={classNames(styles.article, className)}>{children}</div>
  );
}
