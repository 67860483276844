import type {
  Sender,
  EventFrom,
  MachineOptionsFrom,
  ActorRefFrom,
} from 'xstate';
import { createMachine, assign, sendParent, send } from 'xstate';
import { Context as ParentContext } from '../Application/ApplicationMachine';
import { createContext as directDepositMachineCreateContext } from '../ClaimFunds/DirectDepositMachine';
import { StateFrom } from '../../utils/StateFrom';
import { InsertDirectDepositAndLinkToApplicationType } from '../../graphql/operations';
import { logMachineError } from '../../utils/logError';
import { refreshApplication } from '../../events/RefreshApplication';
import {
  UpdateDirectDepositAccountsEvent,
  UpdateFundingRequestDirectDepositEvent,
  UpdateTouringDirectDepositEvent,
} from './events';

type Events =
  | { type: 'OPEN_DEPOSIT_INFO_CHOOSER' }
  | { type: 'CLOSE_DEPOSIT_INFO_CHOOSER' }
  | { type: 'CHOOSE_TOUR_DEPOSIT_INFO' }
  | { type: 'CHOOSE_FUNDING_REQUEST_DEPOSIT_INFO' }
  | UpdateTouringDirectDepositEvent
  | UpdateFundingRequestDirectDepositEvent
  | UpdateDirectDepositAccountsEvent;

export type Context = {
  applicationId: string;
  artistId: string;
  directDepositAccounts: ParentContext['directDepositAccounts'];
  touringDirectDepositAccount: ParentContext['touringDirectDepositAccount'];
  fundingRequestDirectDepositAccount: ParentContext['fundingRequestDirectDepositAccount'];
  applicationType: ParentContext['applicationType'];
  isAdmin: ParentContext['isAdmin'];
  stage: ParentContext['stage'];
  approvedTotalForTourDates: ParentContext['approvedTotalForTourDates'];
  approvedTotalForFundingRequests: ParentContext['approvedTotalForFundingRequests'];
  touringPaid: ParentContext['touringPaid'];
  fundingRequestPaid: ParentContext['fundingRequestPaid'];
};

export const createContext = (context: Context): Context => context;

export const machine = createMachine(
  {
    id: 'directDepositsMachine',
    predictableActionArguments: true,
    tsTypes: {} as import('./DirectDepositsMachine.typegen').Typegen0,
    schema: {
      context: {} as Context,
      events: {} as Events,
    },
    type: 'parallel',
    on: {
      UPDATE_TOURING_DIRECT_DEPOSIT_ACCOUNT: {
        actions: ['updateTouringDirectDepositAccount', 'refreshApplication'],
      },
      UPDATE_FUNDING_REQUEST_DIRECT_DEPOSIT_ACCOUNT: {
        actions: [
          'updateFundingRequestDirectDepositAccount',
          'refreshApplication',
        ],
      },
      UPDATE_DIRECT_DEPOSIT_ACCOUNTS: {
        actions: [
          'updateDirectDepositAccounts',
          'setTourAccounts',
          'setFundingRequestAccounts',
          'refreshApplication',
        ],
      },
    },
    states: {
      depositInfoChooser: {
        initial: 'closed',
        on: {
          CLOSE_DEPOSIT_INFO_CHOOSER: '.closed',
        },
        states: {
          closed: {
            on: {
              OPEN_DEPOSIT_INFO_CHOOSER: 'choosing',
            },
          },
          choosing: {
            on: {
              CHOOSE_TOUR_DEPOSIT_INFO: {
                target: 'closed',
                actions: 'setTourDepositInfo',
              },
              CHOOSE_FUNDING_REQUEST_DEPOSIT_INFO: {
                target: 'closed',
                actions: 'setFundingDepositInfo',
              },
            },
          },
        },
      },
      tourFundingDirectDepositInfo: {
        invoke: {
          id: 'tourFundingDirectDepositInfo',
          src: 'directDepositMachine',
          data: (context: Context) =>
            directDepositMachineCreateContext({
              ...context,
              directDepositAccount: context.touringDirectDepositAccount,
              depositInfoType:
                InsertDirectDepositAndLinkToApplicationType.Touring,
            }),
          onError: '#directDepositsMachine.ui.error',
        },
      },
      fundingRequestDirectDepositInfo: {
        invoke: {
          id: 'fundingRequestDirectDepositInfo',
          src: 'directDepositMachine',
          data: (context: Context) =>
            directDepositMachineCreateContext({
              ...context,
              directDepositAccount: context.fundingRequestDirectDepositAccount,
              depositInfoType:
                InsertDirectDepositAndLinkToApplicationType.FundingRequest,
            }),
          onError: '#directDepositsMachine.ui.error',
        },
      },
      ui: {
        states: {
          noError: {},
          error: {
            entry: 'logMachineError',
          },
        },
      },
    },
  },
  {
    actions: {
      logMachineError,
      refreshApplication: sendParent(refreshApplication()),
      updateTouringDirectDepositAccount: assign(
        ({ touringDirectDepositAccount }, event) => ({
          touringDirectDepositAccount:
            event.directDepositAccount ?? touringDirectDepositAccount,
        })
      ),
      updateFundingRequestDirectDepositAccount: assign(
        ({ fundingRequestDirectDepositAccount }, event) => ({
          fundingRequestDirectDepositAccount:
            event.directDepositAccount ?? fundingRequestDirectDepositAccount,
        })
      ),
      updateDirectDepositAccounts: assign((_, event) => ({
        directDepositAccounts: event.directDepositAccounts,
      })),
      setTourAccounts: send(
        (_, event) => ({
          type: 'SET_ACCOUNTS',
          accounts: event.directDepositAccounts,
        }),
        { to: 'tourFundingDirectDepositInfo' }
      ),
      setFundingRequestAccounts: send(
        (_, event) => ({
          type: 'SET_ACCOUNTS',
          accounts: event.directDepositAccounts,
        }),
        { to: 'fundingRequestDirectDepositInfo' }
      ),
      setTourDepositInfo: send(
        {
          type: 'SET_DEPOSIT_INFO',
        },
        { to: 'tourFundingDirectDepositInfo' }
      ),
      setFundingDepositInfo: send(
        {
          type: 'SET_DEPOSIT_INFO',
        },
        { to: 'fundingRequestDirectDepositInfo' }
      ),
    },
  }
);

type Machine = typeof machine;

export type DirectDepositsMachineState = StateFrom<Machine>;
export type DirectDepositsMachineSender = Sender<EventFrom<Machine>>;
export type DirectDepositsMachineOptions = MachineOptionsFrom<Machine, true> & {
  context: Context;
};
export type DirectDepositsMachineEvent = EventFrom<Machine>;
export type DirectDepositsMachineActor = ActorRefFrom<Machine>;
