import { match, P } from 'ts-pattern';
import {
  AdminBoardMeetingBlockMachineSender,
  AdminBoardMeetingBlockMachineState,
} from '../../../machines/BoardMeeting/AdminBoardMeetingBlockMachine';
import { Loading } from '../../Loading';
import { Button } from '../../Button';
import { MeetingBlock } from './MeetingBlock';
import { Block } from '../../Block';
import { MeetingBlockForm } from './MeetingBlockForm';

interface AdminBoardMeetingBlockViewProps {
  state: AdminBoardMeetingBlockMachineState;
  send: AdminBoardMeetingBlockMachineSender;
  isFirstBlock?: boolean;
  isLastBlock?: boolean;
}

export function AdminBoardMeetingBlockView({
  state,
  send,
  isFirstBlock,
  isLastBlock,
}: AdminBoardMeetingBlockViewProps) {
  return match({ state, block: state.context.block })
    .when(
      () => state.matches('loading'),
      () => (
        <Block>
          <Loading inline={true} />
        </Block>
      )
    )
    .when(
      () => state.matches('form'),
      () => (
        <MeetingBlockForm
          title={state.context.form.title}
          content={state.context.form.content}
          send={send}
        />
      )
    )
    .with({ block: P.not(undefined) }, ({ block }) => (
      <MeetingBlock
        block={block}
        send={send}
        isUploading={state.matches('block.uploadingFile')}
        hasAttachmentError={state.matches('block.fileError')}
        isFirstBlock={isFirstBlock}
        isLastBlock={isLastBlock}
      />
    ))
    .otherwise(() => (
      <Button
        label="Add a New Block"
        variant="addnew"
        onClick={() => {
          send('OPEN_FORM');
        }}
      />
    ));
}
