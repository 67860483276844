import { useActor } from '@xstate/react';
import { ApplicationStatementBlockMachineActor } from '../../machines/components/ApplicationStatementBlockMachine';
import { StatementType } from '../../utils/getApplicationStatementType';
import { ApplicationStatementBlockView } from './ApplicationStatementBlockView';

interface ApplicationStatementBlockControllerProps {
  actor: ApplicationStatementBlockMachineActor;
  statementType: StatementType;
}

export function ApplicationStatementBlockController({
  actor,
  statementType,
}: ApplicationStatementBlockControllerProps) {
  const [state, send] = useActor(actor);

  return (
    <ApplicationStatementBlockView
      statementType={statementType}
      state={state}
      send={send}
    />
  );
}
