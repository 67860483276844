import { GraphQLClient } from 'graphql-request';
import { RemoveBoardMemberDocument } from '../../graphql/operations';
import { Context } from '../../machines/Admin/BoardMembersMachine';
import { boardMemberSchema } from '../../schemas/boardMemberSchema';

export const createRemoveBoardMemberPromise =
  (client: GraphQLClient) =>
  async (_: Context, { id }: { id: string }) => {
    const { result } = await client.request(RemoveBoardMemberDocument, { id });

    return boardMemberSchema.pick({ accountId: true }).parse(result?.response);
  };

export type RemoveBoardMemberPromise = ReturnType<
  typeof createRemoveBoardMemberPromise
>;

export type RemoveBoardMemberPromiseResult = Awaited<
  ReturnType<RemoveBoardMemberPromise>
>;
