import { GraphQLClient } from 'graphql-request';
import {
  SearchSubscribersDocument,
  SearchSubscribersQueryVariables,
} from '../../graphql/operations';
import { z } from 'zod';

export const createSearchSubscribersPromise =
  (client: GraphQLClient) =>
  async (variables: SearchSubscribersQueryVariables) => {
    const result = await client.request(SearchSubscribersDocument, variables);

    return z
      .object({
        tag: z.string().uuid(),
        email: z.string(),
        count: z.number(),
      })
      .array()
      .parseAsync(result.subscribers);
  };

export type CreateSearchSubscribersPromise = ReturnType<
  typeof createSearchSubscribersPromise
>;

export type CreateSearchSubscribersPromiseResult = Awaited<
  ReturnType<CreateSearchSubscribersPromise>
>;
