import { GraphQLClient } from 'graphql-request';
import {
  UpsertManagementInput,
  UpsertManagementDetailsDocument,
} from '../graphql/operations';

export const createUpsertManagementDetails =
  <TContext>(
    client: GraphQLClient,
    contextToManagementDetailsPatch: (
      context: TContext
    ) => UpsertManagementInput
  ) =>
  (context: TContext) =>
    client.request(UpsertManagementDetailsDocument, {
      input: {
        ...contextToManagementDetailsPatch(context),
      },
    });

export type CreateUpsertManagementDetailsPromise = ReturnType<
  typeof createUpsertManagementDetails
>;
export type CreateUpsertManagementDetailsPromiseResult = Awaited<
  ReturnType<CreateUpsertManagementDetailsPromise>
>;
