import z from 'zod';
import { parseAbsolute } from '@internationalized/date';
import { serverDateTimeSchema } from '../dateTimeSchema';

const applicationLogMessageSchema = z.object({
  id: z.string().uuid(),
  title: z.string(),
  item: z.record(z.string()).optional(),
  changes: z
    .object({ column: z.string(), from: z.string(), to: z.string() })
    .array()
    .optional(),
});

export const applicationLogSchema = z.object({
  logAccountId: z.string().nullable(),
  logTransactionDate: serverDateTimeSchema.unwrap(),
  logMessage: z
    .string()
    .transform((text) => applicationLogMessageSchema.parse(JSON.parse(text))),
  name: z.string().nullable(),
});

export const transformApplicationLog =
  (timezone: string) =>
  ({ logTransactionDate, ...rest }: z.infer<typeof applicationLogSchema>) => ({
    ...rest,
    logTransactionDate: parseAbsolute(logTransactionDate, timezone),
  });

export type ApplicationLog = ReturnType<
  ReturnType<typeof transformApplicationLog>
>;

export type ApplicationLogMessage = z.infer<typeof applicationLogMessageSchema>;
