import z from 'zod';
import { serverDateSchema } from './dateSchema';

export const reportSchema = z.object({
  name: z.string(),
  params: z.record(z.string(), z.union([z.string(), z.number()])),
  headers: z.array(z.string()),
  rows: z.array(z.array(z.union([z.string(), z.number()]))),
});

export const annualReportsSchema = z.object({
  fiscalYearEnd: serverDateSchema,
  newArtists: reportSchema,
  submitted: reportSchema,
  genre: reportSchema,
  program: reportSchema,
  province: reportSchema,
});
