import cn from 'classnames';
import { EligibilityNoticeTypes } from '../../../graphql/operations';
import {
  EligibilityNoticeMachineSender,
  EligibilityNoticeMachineState,
} from '../../../machines/EligibilityNoticeMachine';
import { FieldSet } from '../../FieldSet';
import { FormField } from '../../FormField';
import { Icon } from '../../Icon';
import { InlineFormContainer } from '../../InlineFormContainer';
import { RadioOption } from '../../RadioOption';
import { TextInput } from '../../TextInput';

import styles from './EligibilityNoticeForm.module.scss';

interface EligibilityNoticeFormProps {
  state: EligibilityNoticeMachineState;
  send: EligibilityNoticeMachineSender;
}

export function EligibilityNoticeForm({
  state,
  send,
}: EligibilityNoticeFormProps) {
  const { notice, adminForm } = state.context;

  const hasInputError = state.matches('inputError');
  const radioError = hasInputError && !adminForm.type;
  const textError = hasInputError && !adminForm.message.trim();

  return (
    <InlineFormContainer
      primaryButtonText={notice ? 'Change Artist Note' : 'Add Artist Note'}
      primaryButtonOnClick={() => {
        send('SUBMIT_FORM');
      }}
      secondaryButtonText="Cancel"
      secondaryButtonOnClick={() => {
        send('CANCEL_FORM');
      }}
    >
      <FormField
        label="Select Note Type"
        formFieldStatus={radioError ? 'error' : 'default'}
      >
        <FieldSet>
          {[
            EligibilityNoticeTypes.Ineligible,
            EligibilityNoticeTypes.Warning,
          ].map((option) => (
            <RadioOption
              key={option}
              checked={adminForm.type === option}
              label={
                <div
                  className={cn([
                    styles.radioLabel,
                    styles[option.toLowerCase()],
                  ])}
                >
                  <Icon
                    height={16}
                    width={16}
                    name={
                      option === EligibilityNoticeTypes.Ineligible
                        ? 'alertCircle'
                        : 'alertTriangle'
                    }
                  />
                  {option.toLowerCase()}
                </div>
              }
              onChange={() => {
                send({
                  type: 'SET_FORM_NOTICE_TYPE',
                  noticeType: option,
                });
              }}
              error={radioError}
            />
          ))}
        </FieldSet>
      </FormField>
      <FormField
        label="Note For Artist"
        formFieldStatus={textError ? 'error' : 'default'}
        description="Please note that any comments you add here will be visible to the artist under a header of “Eligibility Notice”."
      >
        <TextInput
          value={adminForm.message}
          id="eligibility-form"
          onChange={(value) => {
            send({
              type: 'SET_FORM_MESSAGE',
              message: value,
            });
          }}
          error={textError}
        />
      </FormField>
    </InlineFormContainer>
  );
}
