import { useMachine } from '@xstate/react';
import { DashboardView } from './DashboardView';
import {
  machine,
  DashboardMachineOptions,
} from '../../machines/DashboardMachine';
import { useWindowSize } from '../../hooks/useWindowSize';
import { useIsAdmin } from '../../hooks/useIsAdmin';

type DashboardControllerProps = {
  options: DashboardMachineOptions;
};

export function DashboardController({ options }: DashboardControllerProps) {
  const { screen } = useWindowSize();
  const isAdmin = useIsAdmin();
  const [state, send] = useMachine(machine, options);

  return (
    <DashboardView
      state={state}
      send={send}
      isMobile={screen === 'mobile'}
      isAdmin={isAdmin}
    />
  );
}
