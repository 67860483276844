import cn from 'classnames';
import styles from './SummaryTable.module.scss';
import { pluralize } from '../../utils/pluralize';
import { formatMoney } from '../../utils/formatMoney';
import { ApplicationType } from '../../graphql/operations';
import { getFundingRequestLabels } from '../../utils/getFundingRequestLabels';
import {
  CalendarDate,
  toCalendarDate,
  ZonedDateTime,
} from '@internationalized/date';
import { Payment } from '../../schemas/paymentSchema';
import { formatCalendarDate } from '../../utils/formatCalendarDate';

interface SummaryTableProps {
  applicationType: ApplicationType;
  tourDateCount: number;
  fundingRequestCount: number;
  tourDateTotal: number;
  fundingRequestTotal: number;
  showApprovedRow: boolean;
  approvedTourDateCount: number;
  approvedFundingRequestCount: number;
  approvedTourDateTotal: number;
  approvedFundingRequestTotal: number;
  showClaimedRow: boolean;
  claimedTourDateCount: number;
  claimedFundingRequestCount: number;
  claimedTourDateTotal: number;
  claimedFundingRequestTotal: number;
  payments: Payment[];
  claimsSubmittedAt: ZonedDateTime | null;
}

type PaymentRowData = {
  id: string;
  type: 'payment';
  date: CalendarDate;
  amount: number;
};

type ClaimedRowData = {
  id: string;
  type: 'claims';
  date: CalendarDate;
  claimedTourDateCount: number;
  claimedFundingRequestCount: number;
  claimedTourDateTotal: number;
  claimedFundingRequestTotal: number;
};

type PaymentOrClaimedRowData = PaymentRowData | ClaimedRowData;

export function SummaryTable({
  applicationType,
  tourDateCount,
  fundingRequestCount,
  tourDateTotal,
  fundingRequestTotal,
  showApprovedRow,
  approvedTourDateCount,
  approvedFundingRequestCount,
  approvedTourDateTotal,
  approvedFundingRequestTotal,
  showClaimedRow,
  claimedTourDateCount,
  claimedFundingRequestCount,
  claimedTourDateTotal,
  claimedFundingRequestTotal,
  payments,
  claimsSubmittedAt,
}: SummaryTableProps) {
  const isCore = applicationType === ApplicationType.Core;
  const showFundingRequestColumn = fundingRequestCount > 0;

  const sortedPaymentAndClaimedData: PaymentOrClaimedRowData[] = [
    ...payments.map((payment) => ({
      id: payment.id,
      type: 'payment' as const,
      date: payment.invoiceDate,
      amount: payment.amount,
    })),
    ...(showClaimedRow && claimsSubmittedAt
      ? [
          {
            id: 'claims',
            type: 'claims' as const,
            date: toCalendarDate(claimsSubmittedAt),
            claimedTourDateCount,
            claimedFundingRequestCount,
            claimedTourDateTotal,
            claimedFundingRequestTotal,
          },
        ]
      : []),
  ].sort((a, b) => a.date.compare(b.date));

  return (
    <table
      className={cn(
        styles.table,
        isCore && showFundingRequestColumn ? styles.core : styles.orion
      )}
    >
      <tbody>
        <tr className={cn([styles.row, styles.heading])}>
          <td />
          {isCore && <th>Tour Dates</th>}
          {showFundingRequestColumn && (
            <th>{getFundingRequestLabels(applicationType).title}</th>
          )}
        </tr>
        <tr className={cn(styles.row)}>
          <td>Requested</td>
          {isCore && (
            <td className={styles.flex}>
              <span className={styles.bold}>{formatMoney(tourDateTotal)}</span>
              {pluralize(tourDateCount, 'date')}
            </td>
          )}
          {showFundingRequestColumn && (
            <td className={styles.flex}>
              <span className={styles.bold}>
                {formatMoney(fundingRequestTotal)}
              </span>
              {pluralize(fundingRequestCount, 'request')}
            </td>
          )}
        </tr>
        {showApprovedRow && (
          <tr className={cn(styles.row)}>
            <td>Approved</td>
            {isCore && (
              <td className={styles.flex}>
                <span className={styles.bold}>
                  {formatMoney(approvedTourDateTotal)}
                </span>
                {pluralize(approvedTourDateCount, 'date')}
              </td>
            )}
            {showFundingRequestColumn && (
              <td className={styles.flex}>
                <span className={styles.bold}>
                  {formatMoney(approvedFundingRequestTotal)}
                </span>
                {pluralize(approvedFundingRequestCount, 'request')}
              </td>
            )}
          </tr>
        )}
        {sortedPaymentAndClaimedData.map((data) => (
          <tr key={data.id} className={cn(styles.row)}>
            {data.type === 'claims' ? (
              <>
                <td>Claimed</td>
                {isCore && (
                  <td className={styles.flex}>
                    <span className={styles.bold}>
                      {formatMoney(claimedTourDateTotal)}
                    </span>
                    {pluralize(claimedTourDateCount, 'date')}
                  </td>
                )}
                {showFundingRequestColumn && (
                  <td className={styles.flex}>
                    <span className={styles.bold}>
                      {formatMoney(claimedFundingRequestTotal)}
                    </span>
                    {pluralize(claimedFundingRequestCount, 'request')}
                  </td>
                )}
              </>
            ) : (
              <>
                <td>Payment</td>
                <td className={cn(styles.flex, styles.payment)}>
                  <span className={styles.bold}>
                    {formatMoney(data.amount)}
                  </span>
                  &nbsp;
                  {formatCalendarDate(data.date)}
                </td>
              </>
            )}
          </tr>
        ))}{' '}
        {approvedTourDateTotal + approvedFundingRequestTotal > 0 && (
          <tr className={cn(styles.row)}>
            <td>Unused</td>
            {isCore && (
              <td className={styles.flex}>
                <span className={styles.bold}>
                  {formatMoney(approvedTourDateTotal - claimedTourDateTotal)}
                </span>
              </td>
            )}
            {showFundingRequestColumn && (
              <td className={styles.flex}>
                <span className={styles.bold}>
                  {formatMoney(
                    approvedFundingRequestTotal - claimedFundingRequestTotal
                  )}
                </span>
              </td>
            )}
          </tr>
        )}
      </tbody>
    </table>
  );
}
