import { match } from 'ts-pattern';
import { FormField } from '../../FormField';
import { AccountCard } from '../AccountCard';
import { InlineFormContainer } from '../../InlineFormContainer';
import { TextInput } from '../../TextInput';
import {
  AccountsBlockMachineSender,
  AccountsBlockMachineState,
} from '../../../machines/components/AccountsBlockMachine';
import { DashboardBlock } from '../DashboardBlock';
import { ErrorFeedback } from '../../ErrorFeedback';

import styles from './AccountsBlock.module.scss';

interface AccountsBlockViewProps {
  state: AccountsBlockMachineState;
  send: AccountsBlockMachineSender;
  isAdmin: boolean;
}

export function AccountsBlockView({
  state,
  send,
  isAdmin,
}: AccountsBlockViewProps) {
  const isOwner = state.context.isOwner || isAdmin;

  return (
    <DashboardBlock
      title={`${state.context.artistName} Accounts`}
      buttonIcon={state.context.isOpen ? undefined : 'plus'}
      buttonText={isOwner ? 'Invite Account' : undefined}
      buttonDisabled={state.context.isOpen}
      buttonOnClick={() => send('TOGGLE_OPEN')}
    >
      {state.matches('error') && <ErrorFeedback />}
      {state.context.isOpen && (
        <InlineFormContainer
          primaryButtonText="Send Invitation"
          primaryButtonOnClick={() => send('SEND_INVITATION')}
          secondaryButtonText="Cancel"
          secondaryButtonOnClick={() => send('TOGGLE_OPEN')}
          feedbackTitle={match(state)
            .when(
              ({ matches }) => matches('form.email.invalid.empty'),
              () => 'All fields are required.'
            )
            .when(
              ({ matches }) => matches('form.email.invalid.value'),
              () => 'Invalid email'
            )
            .when(
              ({ matches }) => matches('form.email.invalid.duplicate'),
              () => 'Already sent'
            )
            .otherwise(() => '')}
          feedbackBody={match(state)
            .when(
              ({ matches }) => matches('form.email.invalid.empty'),
              () => 'This field is required.'
            )
            .when(
              ({ matches }) => matches('form.email.invalid.value'),
              () => "This doesn't appear to be a valid email."
            )
            .when(
              ({ matches }) => matches('form.email.invalid.duplicate'),
              () =>
                'This email has already been invited to manage your account.'
            )
            .otherwise(() => '')}
          feedbackType="warning"
        >
          <FormField
            id="email"
            htmlFor="email"
            label="Email"
            formFieldStatus={
              state.matches('form.email.invalid') ? 'error' : 'default'
            }
          >
            <TextInput
              id="email"
              value={state.context.email}
              onChange={(email) => send({ type: 'SET_EMAIL', email })}
              onBlur={() => send({ type: 'BLUR_EMAIL' })}
            />
          </FormField>
        </InlineFormContainer>
      )}
      <div className={styles.accounts}>
        {state.context.accounts.map((account) => (
          <AccountCard
            key={account.email}
            {...account}
            isOwner={state.context.isOwner || isAdmin}
            onClick={() => {
              if (
                window.confirm(
                  'Do you really want to disassociate this account from this artist?'
                )
              ) {
                send({ type: 'DELETE_BINDING', accountId: account.accountId });
              }
            }}
            buttonDisabled={state.context.accounts.length === 1}
          />
        ))}
        {state.context.pendingAccountBindings.map((account) => (
          <AccountCard
            key={account.email}
            email={account.email}
            pending={true}
            isYou={false}
            isOwner={state.context.isOwner || isAdmin}
            onClick={() => {
              if (
                window.confirm('Do you really want to cancel this invitation?')
              ) {
                send({
                  type: 'DELETE_PENDING_BINDING',
                  email: account.email,
                  invitationId: account.invitationId,
                });
              }
            }}
          />
        ))}
      </div>
    </DashboardBlock>
  );
}
