import {
  History,
  createBrowserHistory,
  createMemoryHistory,
} from '../../utils/history';

import { AddArtistState, parseAddAccountState } from './state';

export type AddArtistHistory = History<AddArtistState>;

export const createBrowserAddArtistHistory = (): AddArtistHistory =>
  createBrowserHistory(parseAddAccountState);

export const createMemoryAddArtistHistory = (): AddArtistHistory =>
  createMemoryHistory<AddArtistState>();
