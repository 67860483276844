import { P, match } from 'ts-pattern';
import { Context } from '../../machines/DashboardMachine';
import { Feedback } from '../Feedback';
import { ArtistStatus } from '../../graphql/operations';
import { IneligibilityFeedback } from './IneligibilityFeedback';
import { Link } from '../Link';
import { formatNumber } from '../../utils/formatNumber';

interface StatusFeedbackProps {
  artistStatus: ArtistStatus;
  isCoreEligible: boolean;
  hasEligibleRelease: boolean;
  hasReachedOrionApprovalLimit: boolean;
  aboveSalesRequirement: boolean;
  aboveLifetimeFundingLimit: boolean;
  orionApprovalLimit: number;
  currentRound: Context['currentRound'];
  nextOrionRound: Context['nextOrionRound'];
}

export function StatusFeedback({
  artistStatus,
  currentRound,
  nextOrionRound,
  isCoreEligible,
  hasEligibleRelease,
  hasReachedOrionApprovalLimit,
  aboveSalesRequirement,
  aboveLifetimeFundingLimit,
  orionApprovalLimit,
}: StatusFeedbackProps) {
  return match(artistStatus)
    .with(ArtistStatus.Core, () => (
      <>
        {!isCoreEligible && currentRound && (
          <IneligibilityFeedback
            round={currentRound.title}
            aboveSalesRequirement={aboveSalesRequirement}
            aboveLifetimeFundingLimit={aboveLifetimeFundingLimit}
          />
        )}
      </>
    ))
    .with(ArtistStatus.PendingOrion, () => (
      <Feedback
        icon="alertTriangle"
        type="neutral"
        title="Email your eligibility documentation for full dashboard functionaity."
      >
        <div>
          <p>
            Please email documentation showing cumulative sales of releases from
            the past five years that total at least 1,500 units or one of the
            following: Juno, Grammy or Polaris Prize shortlist, and send it to{' '}
            <Link href={`mailto:${import.meta.env.VITE_ORION_EMAIL}`}>
              {import.meta.env.VITE_ORION_EMAIL}
            </Link>
          </p>
          <br />
          <p>
            After we review your documents and confirm your eligibility, you
            will gain access to create an application.
          </p>
        </div>
      </Feedback>
    ))
    .with(ArtistStatus.Orion, () => (
      <>
        {hasReachedOrionApprovalLimit && (
          <Feedback
            icon="xCircle"
            type="warning"
            title={`You are NO LONGER eligible to apply to the Orion Program.`}
          >
            <p>
              Artists can receive a maximum of{' '}
              {formatNumber(orionApprovalLimit)} approvals in the Orion Program
              before they are no longer allowed to apply to the Orion Program.
            </p>
            <p>
              Contact{' '}
              <Link href={`mailto:${import.meta.env.VITE_ORION_EMAIL}`}>
                {import.meta.env.VITE_ORION_EMAIL}
              </Link>{' '}
              if you have any questions or if you would like to graduate to the
              Core Program.
            </p>
          </Feedback>
        )}
        {(currentRound === null || currentRound.allowOrion === false) && (
          <Feedback
            icon="lock"
            type="neutral"
            title="Orion Program Applications Update"
          >
            {match([currentRound, nextOrionRound])
              .with([{ allowOrion: false }, P.not(null)], ([_, { title }]) => (
                <>
                  Applications for the Orion Program will not be accepted during
                  this round. Details regarding the the next Orion Program
                  round, Funding Round #{title}, will be announced soon.
                </>
              ))
              .with([{ allowOrion: false }, null], () => (
                <>
                  Applications for the Orion Program will not be accepted during
                  this round. Details regarding the the next Orion Program round
                  will be announced soon.
                </>
              ))
              .with([null, P.not(null)], ([_, { title }]) => (
                <>
                  Details regarding the the next Orion Program round, Funding
                  Round #{title}, will be announced soon.
                </>
              ))
              .with([null, null], () => (
                <>
                  Details regarding the the next Orion Program round will be
                  announced soon.
                </>
              ))
              .run()}
          </Feedback>
        )}
        {hasEligibleRelease && (
          <Feedback
            icon="alertCircle"
            type="positive"
            title="You have a release that may qualify for the Core Funding Program"
          >
            <div className="content">
              <p>
                Please reach out to the Starmaker team at{' '}
                <Link href={`mailto:${import.meta.env.VITE_ORION_EMAIL}`}>
                  {import.meta.env.VITE_ORION_EMAIL}
                </Link>{' '}
                to verify your Core Eligibility
              </p>
              <p>
                Once the admin reviews and confirms your eligibility,
                you&apos;ll have access to the Core Funding Program and will no
                longer be eligible to apply to Orion.
              </p>
            </div>
          </Feedback>
        )}
      </>
    ))
    .exhaustive();
}
