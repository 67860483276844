import { match } from 'ts-pattern';
import { Block } from '../Block';
import {
  AttachmentsBlockMachineSender,
  AttachmentsBlockMachineState,
} from '../../machines/components/AttachmentsBlockMachine';
import { AttachmentPreview } from './AttachmentPreview';
import { UploadButton } from '../UploadButton';
import { ErrorFeedback } from '../ErrorFeedback';

interface AttachmentsBlockViewProps {
  state: AttachmentsBlockMachineState;
  send: AttachmentsBlockMachineSender;
}

export function AttachmentsBlockView({
  state,
  send,
}: AttachmentsBlockViewProps) {
  return match(state)
    .when(
      () => state.matches('form'),
      () => (
        <div>
          <Block
            id="Attachments"
            headerText="File Attachments"
            iconName="folder"
          >
            {!state.context.submittedAt && (
              <p>
                You may optionally attach any files to support your application,
                including tour materials, schedules, or media files.
              </p>
            )}
            {state.matches('form.attachments.error') && <ErrorFeedback />}
            {!state.context.submittedAt && (
              <UploadButton
                buttonLabel="Upload a File"
                isUploading={state.matches('form.attachments.upload')}
                disabled={state.matches('form.attachments.upload')}
                onClick={(files) => send({ type: 'UPLOAD_FILES', files })}
              />
            )}
            {state.context.attachments.map((attachment) => (
              <AttachmentPreview
                key={attachment.name}
                attachment={attachment}
                onClick={(file) => send({ type: 'GET_FILE', file })}
                onDelete={(file) => {
                  if (
                    window.confirm(
                      'Are you sure you want to delete this attachment?'
                    )
                  ) {
                    send({ type: 'DELETE_FILE', file });
                  }
                }}
                isOwner={attachment.isOwner}
              />
            ))}
            {state.context.attachments.length === 0 && (
              <p>No attachments uploaded.</p>
            )}
          </Block>
        </div>
      )
    )
    .run();
}
