import type {
  ActorRefFrom,
  EventFrom,
  MachineOptionsFrom,
  Sender,
} from 'xstate';
import { assign, createMachine } from 'xstate';

import { sendParent } from 'xstate/lib/actions';
import { next } from '../navigation';
import { StateFrom } from '../../utils/StateFrom';
import { RedeemInvitationStorage } from './storage';
import { ReplayableEvent, createReplay } from '../../utils/replay';
import { Context as ParentContext } from './Context';

export type Context = {
  email: string;
  password: string;
  confirmPassword: string;
  showPassword: boolean;
  showConfirmPassword: boolean;
  minimumPasswordLength: number;
  isOrion: boolean;
};

type UpdatePasswordEvent = ReplayableEvent<{
  type: 'UPDATE_PASSWORD';
  value: string;
}>;

type UpdateConfirmPasswordEvent = ReplayableEvent<{
  type: 'UPDATE_CONFIRM_PASSWORD';
  value: string;
}>;

type ReplayableEvents = UpdatePasswordEvent | UpdateConfirmPasswordEvent;

type Events =
  | ReplayableEvents
  | { type: 'SUBMIT' }
  | { type: 'TOGGLE_PASSWORD_VISIBILITY' }
  | { type: 'TOGGLE_CONFIRM_PASSWORD_VISIBILITY' };

const passwordStates = {
  initial: 'noError',
  states: {
    noError: {},
    error: {
      initial: 'empty',
      states: {
        empty: {},
        invalid: {},
      },
    },
  },
};

const confirmPasswordStates = {
  initial: 'noError',
  states: {
    noError: {},
    error: {
      initial: 'empty',
      states: {
        empty: {},
        mismatch: {},
      },
    },
  },
};

export const createPasswordContext = ({
  email,
  minimumPasswordLength,
  isOrion,
}: Pick<
  ParentContext,
  'email' | 'minimumPasswordLength' | 'isOrion'
>): Context => ({
  email,
  password: '',
  confirmPassword: '',
  showPassword: false,
  showConfirmPassword: false,
  minimumPasswordLength,
  isOrion,
});

export const machine = createMachine(
  {
    predictableActionArguments: true,
    tsTypes: {} as import('./PasswordMachine.typegen').Typegen0,
    schema: {
      context: {} as Context,
      events: {} as Events,
    },
    type: 'parallel',
    invoke: {
      id: 'resume',
      src: 'resume',
    },
    states: {
      password: {
        ...passwordStates,
        on: {
          UPDATE_PASSWORD: {
            actions: ['updatePassword', 'savePassword'],
            target: '.noError',
          },
          SUBMIT: [
            {
              cond: 'isPasswordEmpty',
              target: '.error.empty',
            },
            {
              cond: 'isPasswordInvalid',
              target: '.error.invalid',
            },
          ],
        },
      },
      confirmPassword: {
        ...confirmPasswordStates,
        on: {
          UPDATE_CONFIRM_PASSWORD: {
            actions: ['updateConfirmPassword', 'saveConfirmPassword'],
            target: '.noError',
          },
          SUBMIT: [
            {
              cond: 'isConfirmPasswordEmpty',
              target: '.error.empty',
            },
            {
              cond: 'isConfirmPasswordMismatched',
              target: '.error.mismatch',
            },
          ],
        },
      },
    },
    on: {
      TOGGLE_PASSWORD_VISIBILITY: {
        actions: 'togglePasswordVisibility',
      },
      TOGGLE_CONFIRM_PASSWORD_VISIBILITY: {
        actions: 'toggleConfirmPasswordVisibility',
      },
      SUBMIT: {
        actions: 'next',
      },
    },
  },
  {
    guards: {
      isPasswordEmpty: ({ password }) => password.length === 0,
      isPasswordInvalid: ({ password, minimumPasswordLength }) =>
        password.length < minimumPasswordLength,
      isConfirmPasswordEmpty: ({ confirmPassword }) =>
        confirmPassword.length === 0,
      isConfirmPasswordMismatched: ({ password, confirmPassword }) =>
        password !== confirmPassword,
    },
    actions: {
      updatePassword: assign((_, { value: password }) => ({ password })),
      updateConfirmPassword: assign((_, { value: confirmPassword }) => ({
        confirmPassword,
      })),
      togglePasswordVisibility: assign(({ showPassword }) => ({
        showPassword: !showPassword,
      })),
      toggleConfirmPasswordVisibility: assign(({ showConfirmPassword }) => ({
        showConfirmPassword: !showConfirmPassword,
      })),
      next: sendParent(next()),
    },
  }
);

export const createPasswordResumeService =
  (storage: RedeemInvitationStorage) => () => (send: PasswordMachineSender) => {
    const replay = createReplay<ReplayableEvents>();
    const { account } = storage.get();

    (
      [
        [account?.password, 'UPDATE_PASSWORD'],
        [account?.confirmPassword, 'UPDATE_CONFIRM_PASSWORD'],
      ] satisfies Array<[string | undefined, ReplayableEvents['type']]>
    ).forEach(([value, type]) => {
      if (value !== undefined) {
        send(replay({ type, value }));
      }
    });
  };

export const createPasswordSaveActions = (
  storage: RedeemInvitationStorage
) => ({
  savePassword: (
    _: Context,
    { value: password, replay }: UpdatePasswordEvent
  ) => storage.add({ account: { password } }, replay),
  saveConfirmPassword: (
    _: Context,
    { value: confirmPassword, replay }: UpdateConfirmPasswordEvent
  ) => storage.add({ account: { confirmPassword } }, replay),
});

type Machine = typeof machine;

export type PasswordMachineState = StateFrom<Machine>;
export type PasswordMachineEvents = EventFrom<Machine>;
export type PasswordMachineSender = Sender<PasswordMachineEvents>;
export type PasswordMachineOptions = MachineOptionsFrom<Machine, true>;
export type PasswordMachineActor = ActorRefFrom<Machine>;
