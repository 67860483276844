import cn from 'classnames';

import styles from './ToggleButton.module.scss';

interface ToggleButtonProps {
  text: string;
  value: boolean;
  onClick: () => void;
  className?: string;
  disabled?: boolean;
}

export function ToggleButton({
  text,
  value,
  onClick,
  className,
  disabled,
}: ToggleButtonProps) {
  return (
    <button
      type="button"
      className={cn(
        styles.container,
        {
          [styles.on]: value,
        },
        className
      )}
      onClick={() => onClick()}
      disabled={disabled}
    >
      {text}
    </button>
  );
}
