import React from 'react';
import cn from 'classnames';
import { Link } from 'react-router-dom';
import { Icon } from './Icon';

import styles from './Breadcrumbs.module.scss';

export interface Breadcrumb {
  text: string;
  to: string;
}

interface Props {
  type?: 'normal' | 'hero';
  breadcrumbs: Breadcrumb[];
  currentPageName?: string;
  className?: string;
}

export function Breadcrumbs({
  type = 'normal',
  breadcrumbs,
  currentPageName,
  className,
}: Props) {
  return (
    <div className={cn(styles.breadcrumbs, className)}>
      {breadcrumbs.map((breadcrumb) => {
        return (
          <React.Fragment key={breadcrumb.text}>
            <Link
              className={cn(styles.link, {
                [styles.heroBreadcrumb]: type === 'hero',
                [styles.breadcrumb]: type !== 'hero',
              })}
              to={breadcrumb.to}
            >
              {breadcrumb.text}
            </Link>
            <Icon className={styles.icon} name="breadcrumbArrow" />
          </React.Fragment>
        );
      })}
      <span>{currentPageName}</span>
    </div>
  );
}
