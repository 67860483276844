import { useParams } from 'react-router-dom';
import { useGraphQLClient } from '../../components/GraphQLClientProvider';
import { useIsAdmin } from '../../hooks/useIsAdmin';
import { createContext } from '../../machines/BoardMeeting/BoardMeetingMachine';
import { createAddBoardMeetingBlockPromise } from '../../promises/Admin/createAddBoardMeetingBlockPromise';
import { createDeleteBoardMeetingBlockPromise } from '../../promises/Admin/createDeleteBoardMeetingBlockPromise';
import { createSwapBoardMeetingBlockPromise } from '../../promises/Admin/createSwapBoardMeetingBlocksPromise';
import { createUpdateBoardMeetingBlockPromise } from '../../promises/Admin/createUpdateBoardMeetingBlockPromise';
import { createDownloadBoardAttachmentPromise } from '../../promises/Attachments/createDownloadBoardAttachmentPromise';
import { createGetBoardMeetingContextPromise } from '../../promises/getBoardMeetingContextPromise';
import { withDelay } from '../../utils/withDelay';
import { BoardMeetingController } from './BoardMeetingController';

export function BoardMeeting() {
  const client = useGraphQLClient();
  const isAdmin = useIsAdmin();
  const { round } = useParams();

  return (
    <BoardMeetingController
      options={{
        context: createContext(isAdmin),
        services: {
          getBoardMeetingContext: createGetBoardMeetingContextPromise(
            client,
            isAdmin,
            round
          ),
          addBoardMeetingBlock: withDelay(
            createAddBoardMeetingBlockPromise(client)
          ),
          deleteBoardMeetingBlock: withDelay(
            createDeleteBoardMeetingBlockPromise(client)
          ),
          updateBoardMeetingBlock: withDelay(
            createUpdateBoardMeetingBlockPromise(client)
          ),
          moveBoardMeetingBlock: withDelay(
            createSwapBoardMeetingBlockPromise(client)
          ),
          downloadAttachment: createDownloadBoardAttachmentPromise(),
        },
      }}
    />
  );
}
