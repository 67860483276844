import cn from 'classnames';
import { ReactNode, useState } from 'react';
import { GenericPreview } from '../GenericPreview';
import { HorizontalRule } from '../HorizontalRule';
import { Icon } from '../Icon';
import { PaymentHistoryItem } from './PaymentHistoryItem';
import { formatMoney } from '../../utils/formatMoney';
import { Payment } from '../../schemas/paymentSchema';
import { PaymentRefs } from '../../machines/ClaimFunds/DirectDepositMachine';

import styles from './DirectDepositAccountPreview.module.scss';

interface Props {
  title: string;
  note: string;
  isAdmin: boolean;
  showChangeButton: boolean;
  onChange: () => void;
  onAddPaymentClick: () => void;
  totalPaid: number;
  showClaimed?: boolean;
  claimedTotal: number;
  payments: Payment[];
  paymentRefs: PaymentRefs;
  approvedAmount: number;
  children?: ReactNode;
}

export function DirectDepositAccountPreview({
  title,
  note,
  onChange,
  showChangeButton,
  isAdmin,
  onAddPaymentClick,
  totalPaid,
  showClaimed,
  claimedTotal,
  payments,
  paymentRefs,
  approvedAmount,
  children,
}: Props) {
  const [isPaymentHistoryOpen, setIsPaymentHistoryOpen] = useState(false);

  return (
    <GenericPreview
      iconName="creditCard"
      iconAriaLabel="Card icon"
      title={title}
      note={note}
      {...(showChangeButton && {
        buttonVariant: 'secondary',
        buttonLabel: 'Change',
        onButtonClick: onChange,
      })}
      {...(isAdmin && {
        secondaryButtonVariant: 'primary',
        secondaryButtonLabel: 'Add a Payment',
        onSecondaryButtonClick: onAddPaymentClick,
      })}
    >
      <HorizontalRule />
      {children}
      <div className={styles.totals}>
        <span className={styles.total}>
          <Icon className={styles.totalIcon} name="checkCircle" />
          <div>
            <span className={styles.totalAmount}>
              {formatMoney(approvedAmount)}
            </span>{' '}
            approved
          </div>
        </span>
        {showClaimed && (
          <>
            <span className={styles.total}>
              <Icon className={styles.totalIcon} name="document" />
              <div>
                <span className={styles.totalAmount}>
                  {formatMoney(claimedTotal)}
                </span>{' '}
                claimed
              </div>
            </span>
            <span className={styles.total}>
              <div>
                <span className={styles.totalAmount}>
                  {formatMoney(approvedAmount - claimedTotal)}
                </span>
                {'  '}
                unused
              </div>
            </span>
          </>
        )}
        <span className={cn(styles.total, styles.paid)}>
          <div>
            <span className={styles.totalAmount}>{formatMoney(totalPaid)}</span>{' '}
            paid
          </div>
        </span>
      </div>
      <details
        className={cn({
          [styles.detailsDisabled]: payments.length === 0,
        })}
        onToggle={() => setIsPaymentHistoryOpen((value) => !value)}
      >
        <summary className={styles.summary}>
          <span>{`${
            isPaymentHistoryOpen ? 'Hide' : 'Show'
          } Payment History`}</span>
          <Icon
            className={styles.icon}
            name={isPaymentHistoryOpen ? 'upChevron' : 'downChevron'}
          />
        </summary>
        <ul className={styles.payments}>
          {payments.map((payment, index) => (
            <div key={payment.id}>
              <PaymentHistoryItem
                isAdmin={isAdmin}
                payment={payment}
                onDeleteClick={() => {
                  if (
                    window.confirm('Do you really want to delete this payment?')
                  ) {
                    paymentRefs[payment.id]?.send({ type: 'DELETE' });
                  }
                }}
              />
              {index != payments.length - 1 && <HorizontalRule />}
            </div>
          ))}
        </ul>
      </details>
    </GenericPreview>
  );
}
