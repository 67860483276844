import { Button } from '../Button';

import styles from './ReleaseCard.module.scss';

export interface ReleaseCardProps {
  id: string;
  artistId: string;
  title: string;
  eligible: boolean;
  label: string;
  releaseDate: string;
}

export function ReleaseCard({
  id,
  artistId,
  title,
  eligible,
  label,
  releaseDate,
}: ReleaseCardProps) {
  return (
    <div className={styles.card}>
      <div className={styles.titleRow}>
        <div className={styles.col}>
          <div className={styles.title}>
            <h3 className={styles.heading}>{title}</h3>
            <p className={eligible ? styles.eligible : styles.ineligible}>
              {eligible ? 'Eligible' : 'Ineligible'}
            </p>
          </div>
          <div className={styles.col}>
            <dl className={styles.field}>
              <dt>Label:</dt>
              <dd className={styles.bold}>{label}</dd>
            </dl>
            <dl className={styles.field}>
              <dt>Release Date:</dt>
              <dd className={styles.bold}>{releaseDate}</dd>
            </dl>
          </div>
        </div>
        <div className={styles.col}>
          <Button
            variant="secondary"
            label="View"
            size="small"
            linkTo={`/artist/${artistId}/release/${id}`}
          />
        </div>
      </div>
    </div>
  );
}
