import { match, P } from 'ts-pattern';
import cn from 'classnames';
import { Icon, IconName } from './Icon';
import { ApplicationStatus } from '../graphql/operations';

import styles from './ApplicationStatusBadge.module.scss';

interface ApplicationStatusBadgeProps {
  status: ApplicationStatus;
  isComplete?: boolean;
}

export function ApplicationStatusBadge({
  status,
  isComplete,
}: ApplicationStatusBadgeProps) {
  const uiDetails = match<
    [ApplicationStatus, boolean],
    { icon: IconName; title: string; colour: string }
  >([status, Boolean(isComplete)])
    .with([P.any, true], () => ({
      icon: 'checkCircle',
      title: 'Completed',
      colour: 'darkBlue',
    }))
    .with([ApplicationStatus.InProgress, P.any], () => ({
      icon: 'clock',
      title: 'In progress',
      colour: 'gray',
    }))
    .with([ApplicationStatus.Submitted, P.any], () => ({
      icon: 'checkmark',
      title: 'Submitted',
      colour: 'blue',
    }))
    .with([ApplicationStatus.Approved, P.any], () => ({
      icon: 'star',
      title: 'Approved',
      colour: 'green',
    }))
    .with([ApplicationStatus.Denied, P.any], () => ({
      icon: 'xCircle',
      title: 'Denied',
      colour: 'red',
    }))
    .exhaustive();

  return (
    <div className={cn([styles.container, styles[uiDetails.colour]])}>
      <Icon name={uiDetails.icon} className={styles.icon} />
      <span className={styles.text}>{uiDetails.title}</span>
    </div>
  );
}
