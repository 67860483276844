import { useMachine } from '@xstate/react';
import {
  machine,
  ForgotPasswordMachineOptions,
} from '../../machines/ForgotPasswordMachine';
import { ForgotPasswordView } from './ForgotPasswordView';

type ForgotPasswordControllerProps = {
  options: ForgotPasswordMachineOptions;
};

export function ForgotPasswordController({
  options,
}: ForgotPasswordControllerProps) {
  const [state, send] = useMachine(machine, options);

  return <ForgotPasswordView state={state} send={send} />;
}
