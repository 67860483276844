import { ZonedDateTime } from '@internationalized/date';
import { ReopenDeadlineParagraph } from '../ClaimFunds/ReopenDeadlineParagraph';
import { Feedback } from '../Feedback';

import styles from './ClaimsReopenedFeedback.module.scss';

interface Props {
  notes: string;
  claimsResubmissionDeadline: ZonedDateTime;
}

export function ClaimsReopenedFeedback({
  notes,
  claimsResubmissionDeadline,
}: Props) {
  return (
    <Feedback type="warning" title="Claim Reopened" icon="alertTriangle">
      <p className={styles.notes}>{notes}</p>
      <ReopenDeadlineParagraph
        claimsResubmissionDeadline={claimsResubmissionDeadline}
      />
    </Feedback>
  );
}
