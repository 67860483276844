import { match } from 'ts-pattern';
import { IndexPage } from '../../../components/IndexPage';
import { Loading } from '../../../components/Loading';
import {
  BoardMembersMachineSender,
  BoardMembersMachineState,
} from '../../../machines/Admin/BoardMembersMachine';
import { ErrorPage } from '../../ErrorPage';
import { AddMemberForm } from './AddMemberForm';
import { MemberCard } from './MemberCard';
import { InfoBox } from '../../../components/InfoBox';

import styles from './BoardMembersView.module.scss';

interface BoardMembersViewProps {
  state: BoardMembersMachineState;
  send: BoardMembersMachineSender;
}

export function BoardMembersView({ state, send }: BoardMembersViewProps) {
  const { boardMembers, pendingMembers } = state.context;

  return match(state)
    .when(
      () => state.matches('error'),
      () => <ErrorPage />
    )
    .when(
      () => state.matches('ready'),
      () => (
        <IndexPage
          breadcrumbs={[{ text: 'Admin Dashboard', to: '/admin' }]}
          heading="Board Members"
          buttonText="Add New Board Member"
          buttonDisabled={!state.matches('ready.form.closedForm')}
          buttonOnClick={() => {
            send('OPEN_FORM');
          }}
        >
          <AddMemberForm state={state} send={send} />
          {pendingMembers.length > 0 || boardMembers.length > 0 ? (
            <ul className={styles.list}>
              {pendingMembers.map(({ email }) => (
                <li key={email} className={styles.listItem}>
                  <MemberCard
                    title={email}
                    isPending={true}
                    onRemove={() => {
                      send({ type: 'RESCIND_INVITATION', email });
                    }}
                  />
                </li>
              ))}
              {boardMembers.map(({ accountId, name }) => (
                <li key={accountId} className={styles.listItem}>
                  <MemberCard
                    title={name}
                    onRemove={() => {
                      send({ type: 'REMOVE_MEMBER', id: accountId });
                    }}
                  />
                </li>
              ))}
            </ul>
          ) : (
            <InfoBox>There are no board members.</InfoBox>
          )}
        </IndexPage>
      )
    )
    .otherwise(() => <Loading />);
}
