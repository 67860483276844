import { match } from 'ts-pattern';
import {
  AddArtistMachineSender,
  AddArtistMachineState,
} from '../../machines/AddArtist/Machine';
import { ArtistController } from '../../components/RedeemAccountInvitation/Artist/ArtistController';
import { ArtistMachineActor } from '../../machines/RedeemAccountInvitation/ArtistMachine';
import { ManagementController } from '../../components/RedeemAccountInvitation/Management/ManagementController';
import { ManagementMachineActor } from '../../machines/RedeemAccountInvitation/ManagementMachine';
import { Loading } from '../../components/Loading';
import { ErrorPage } from '../ErrorPage';

interface Props {
  state: AddArtistMachineState;
  send: AddArtistMachineSender;
}

export function AddArtistView({ state }: Props) {
  return match(state)
    .when(
      () => state.matches('init.pending'),
      () => <Loading />
    )
    .when(
      () => state.matches('init.rejected') || state.matches('adding.rejected'),
      () => <ErrorPage />
    )
    .when(
      () => state.matches('adding.pending'),
      () => <Loading />
    )
    .when(
      () => state.matches('artist'),
      () => (
        <ArtistController
          actor={state.children.artistMachine as ArtistMachineActor}
        />
      )
    )
    .when(
      () => state.matches('management'),
      () => (
        <ManagementController
          actor={state.children.managementMachine as ManagementMachineActor}
        />
      )
    )
    .run();
}
