import { useActor } from '@xstate/react';
import { RoundMachineActor } from '../../../machines/Admin/RoundMachine';
import { RoundListing } from './RoundListing';

interface RoundControllerProps {
  actor: RoundMachineActor;
}

export function RoundController({ actor }: RoundControllerProps) {
  const [state, send] = useActor(actor);

  return <RoundListing state={state} send={send} />;
}
