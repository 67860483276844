import { assign, createMachine } from 'xstate';
import { isEmailValid } from '../utils/isEmailValid';
import { CreateSignInPromiseResult } from '../promises/createSignInPromise';
import { logMachineError } from '../utils/logError';

type Context = { username: string; password: string };

type Events =
  | { type: 'SUBMIT' }
  | { type: 'UPDATE_USERNAME'; username: string }
  | { type: 'UPDATE_PASSWORD'; password: string };

type Services = {
  createSignInPromise: { data: CreateSignInPromiseResult };
};

const usernameStates = {
  initial: 'noError',
  states: {
    noError: {},
    error: {
      initial: 'empty',
      states: {
        empty: {},
        badFormat: {},
      },
    },
  },
};

const passwordStates = {
  initial: 'noError',
  states: {
    noError: {},
    error: {
      initial: 'empty',
      states: {
        empty: {},
      },
    },
  },
};

const authStates = {
  initial: 'noError',
  states: {
    noError: {},
    error: {
      initial: 'network',
      states: {
        network: {
          on: {
            SUBMIT: [
              {
                cond: 'isUsernameEmpty',
                target: '#signInForm.ready.username.error.empty',
              },
              {
                cond: 'isUsernameBadFormat',
                target: '#signInForm.ready.username.error.badFormat',
              },
              {
                cond: 'isPasswordEmpty',
                target: '#signInForm.ready.password.error.empty',
              },
              {
                target: '#signInForm.waitingResponse',
              },
            ],
          },
        },
        credentials: {},
        internal: { entry: 'logMachineError' },
      },
    },
  },
};

export const machine = createMachine(
  {
    id: 'signInForm',
    predictableActionArguments: true,
    tsTypes: {} as import('./SignInMachine.typegen').Typegen0,
    schema: {
      context: {} as Context,
      events: {} as Events,
      services: {} as Services,
    },
    context: {
      username: '',
      password: '',
    },
    initial: 'ready',
    states: {
      ready: {
        type: 'parallel',
        on: {
          UPDATE_USERNAME: {
            actions: 'updateUsername',
            target: 'ready.username.noError',
          },
          UPDATE_PASSWORD: {
            actions: 'updatePassword',
            target: 'ready.password.noError',
          },
          SUBMIT: [
            {
              cond: 'isUsernameEmpty',
              target: 'ready.username.error.empty',
            },
            {
              cond: 'isUsernameBadFormat',
              target: 'ready.username.error.badFormat',
            },
            {
              cond: 'isPasswordEmpty',
              target: 'ready.password.error.empty',
            },
            {
              target: 'waitingResponse',
            },
          ],
        },
        states: {
          username: { ...usernameStates },
          password: { ...passwordStates },
          auth: { ...authStates },
        },
      },
      waitingResponse: {
        invoke: {
          src: 'createSignInPromise',
          onDone: [
            {
              cond: 'isIncorrectCredentials',
              target: 'ready.auth.error.credentials',
            },
            {
              cond: 'isNetworkError',
              target: 'ready.auth.error.network',
            },
            {
              cond: 'isInternalServerError',
              target: 'ready.auth.error.internal',
            },
            {
              actions: 'signIn',
            },
          ],
        },
      },
    },
  },
  {
    guards: {
      isUsernameEmpty: ({ username }) => {
        return username.length === 0;
      },
      isUsernameBadFormat: ({ username }) => !isEmailValid(username),
      isPasswordEmpty: ({ password }) => password.length === 0,
      isIncorrectCredentials: (_, event) => {
        return event.data.message === 'incorrect-credentials';
      },
      isNetworkError: (_, event) => {
        return event.data.message === 'network-error';
      },
      isInternalServerError: (_, event) => {
        return event.data.message === 'internal-error';
      },
    },
    actions: {
      logMachineError,
      updateUsername: assign((_, { username }) => ({ username })),
      updatePassword: assign((_, { password }) => ({ password })),
    },
  }
);
