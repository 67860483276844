import type { ActorRefFrom, EventFrom, Sender } from 'xstate';
import { assign, createMachine } from 'xstate';
import type { MachineOptionsWithContextFrom } from '../../utils/MachineOptionsWithContextFrom';
import type { StateFrom } from '../../utils/StateFrom';
import { BoardMeetingBlock } from '../../schemas/boardMeetingBlockSchema';
import { sendParent } from 'xstate/lib/actions';
import { logMachineError } from '../../utils/logError';
import { Attachment } from '../../schemas/attachment';
import { UploadFilePromiseResult } from '../../promises/Attachments/createUploadFilePromise';
import { DeleteFilePromiseResult } from '../../promises/Attachments/createDeleteFilePromise';

type Events =
  | { type: 'OPEN_FORM' }
  | { type: 'CANCEL_FORM' }
  | { type: 'SET_TITLE'; title: string }
  | { type: 'SET_CONTENT'; content: string }
  | { type: 'SAVE_FORM' }
  | { type: 'MOVE_BLOCK'; direction: 'up' | 'down' }
  | { type: 'DELETE_BLOCK' }
  | { type: 'UPLOAD_FILES'; files: FileList }
  | { type: 'DELETE_FILE'; file: Attachment }
  | { type: 'DOWNLOAD_FILE'; file: Attachment };

type Services = {
  uploadFilePromise: {
    data: UploadFilePromiseResult;
  };
  deleteFilePromise: {
    data: DeleteFilePromiseResult;
  };
};

export type Context = {
  roundId: string;
  block?: BoardMeetingBlock;
  form: { title: string; content: string };
};

export function createContext(roundId: string, block?: BoardMeetingBlock) {
  return {
    roundId,
    block,
    form: {
      title: block?.title ?? '',
      content: block?.content ?? '',
    },
  };
}

export const machine = createMachine(
  {
    predictableActionArguments: true,
    tsTypes: {} as import('./AdminBoardMeetingBlockMachine.typegen').Typegen0,
    schema: {
      context: {} as Context,
      events: {} as Events,
      services: {} as Services,
    },
    initial: 'block',
    states: {
      block: {
        on: {
          OPEN_FORM: 'form',
          DELETE_BLOCK: { actions: 'deleteBlock', target: 'loading' },
          MOVE_BLOCK: { actions: 'moveBlock', target: 'loading' },
          UPLOAD_FILES: {
            target: '.uploadingFile',
          },
          DELETE_FILE: {
            target: '.deletingFile',
          },
          DOWNLOAD_FILE: { actions: 'openFile' },
        },
        initial: 'default',
        states: {
          default: {},
          fileError: { entry: 'logMachineError' },
          uploadingFile: {
            invoke: {
              id: 'uploadFilePromise',
              src: 'uploadFilePromise',
              onDone: {
                actions: 'updateAttachments',
                target: 'default',
              },
              onError: 'fileError',
            },
          },
          deletingFile: {
            invoke: {
              id: 'deleteFilePromise',
              src: 'deleteFilePromise',
              onDone: {
                actions: 'deleteAttachment',
                target: 'default',
              },
              onError: 'fileError',
            },
          },
        },
      },
      form: {
        on: {
          SET_TITLE: { actions: 'setTitle' },
          SET_CONTENT: { actions: 'setContent' },
          CANCEL_FORM: { actions: 'cancelForm', target: 'block' },
          SAVE_FORM: [
            { cond: 'isNewBlock', actions: 'addBlock', target: 'loading' },
            { actions: 'editBlock', target: 'loading' },
          ],
        },
      },
      loading: {},
    },
  },
  {
    guards: {
      isNewBlock: ({ block }) => !block,
    },
    actions: {
      logMachineError,
      setTitle: assign(({ form }, { title }) => ({
        form: {
          ...form,
          title,
        },
      })),
      setContent: assign(({ form }, { content }) => ({
        form: {
          ...form,
          content,
        },
      })),
      addBlock: sendParent(({ form, roundId }) => ({
        type: 'ADD_BLOCK',
        ...form,
        roundId,
      })),
      editBlock: sendParent(({ form, block }) => ({
        type: 'EDIT_BLOCK',
        ...form,
        id: block?.id,
      })),
      deleteBlock: sendParent(({ block }) => ({
        type: 'DELETE_BLOCK',
        block,
      })),
      moveBlock: sendParent(({ block }, { direction }) => ({
        type: 'MOVE_BLOCK',
        id: block?.id,
        direction: direction,
      })),
      cancelForm: assign(({ block, roundId }) => createContext(roundId, block)),
      updateAttachments: sendParent(({ block }, { data }) => ({
        type: 'UPDATE_BLOCK',
        data: {
          ...block,
          attachments: block?.attachments
            ? [
                ...block.attachments.filter(
                  (attachment) =>
                    !data.some(({ name }) => name === attachment.name)
                ),
                ...data,
              ]
            : data,
        },
      })),
      deleteAttachment: sendParent(({ block }, { data }) => ({
        type: 'UPDATE_BLOCK',
        data: {
          ...block,
          attachments: block?.attachments.filter(({ id }) => id !== data.id),
        },
      })),
      openFile: sendParent(({ block }, { file }) => ({
        type: 'DOWNLOAD_ATTACHMENT',
        blockId: block?.id,
        file,
      })),
    },
  }
);

type Machine = typeof machine;

export type AdminBoardMeetingBlockMachineState = StateFrom<Machine>;
export type AdminBoardMeetingBlockMachineSender = Sender<EventFrom<Machine>>;
export type AdminBoardMeetingBlockMachineOptions =
  MachineOptionsWithContextFrom<Machine>;
export type AdminBoardMeetingBlockMachineActor = ActorRefFrom<Machine>;
