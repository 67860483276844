import { GraphQLClient } from 'graphql-request';
import {
  ResetPasswordDocument,
  ResetPasswordMutationVariables,
  ResetPasswordResponseCode,
} from '../graphql/operations';

export const resetPasswordPromise =
  (client: GraphQLClient) => (variables: ResetPasswordMutationVariables) =>
    client.request(ResetPasswordDocument, variables);

export type ResetPasswordPromise = ReturnType<typeof resetPasswordPromise>;
export type ResetPasswordPromiseResult = Awaited<
  ReturnType<ResetPasswordPromise>
>;

export const isSuccess = ({ result }: ResetPasswordPromiseResult) =>
  result?.response?.code === ResetPasswordResponseCode.Success;
