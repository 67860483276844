import type { EventFrom, Sender } from 'xstate';
import { assign, createMachine } from 'xstate';
import type { StateFrom } from '../utils/StateFrom';
import type { MachineOptionsWithContextFrom } from '../utils/MachineOptionsWithContextFrom';
import { GetHomeContextPromiseResult } from '../promises/getHomeContextPromise';
import { logMachineError } from '../utils/logError';

type Services = {
  getHomeContextPromise: {
    data: GetHomeContextPromiseResult;
  };
};

export type Context = {
  rounds: GetHomeContextPromiseResult['rounds'];
};

export const machine = createMachine(
  {
    predictableActionArguments: true,
    tsTypes: {} as import('./HomeMachine.typegen').Typegen0,
    id: 'home',
    schema: {
      context: {} as Context,
      services: {} as Services,
    },
    initial: 'init',
    states: {
      init: {
        invoke: {
          id: 'getHomeContextPromise',
          src: 'getHomeContextPromise',
          onDone: {
            actions: 'setContext',
            target: 'ready',
          },
          onError: 'initError',
        },
      },
      initError: { entry: 'logMachineError' },
      ready: {},
    },
  },
  {
    actions: {
      logMachineError,
      setContext: assign((_, { data }) => data),
    },
  }
);

type Machine = typeof machine;

export type HomeMachineState = StateFrom<Machine>;
export type HomeMachineSender = Sender<EventFrom<Machine>>;
export type HomeMachineOptions = MachineOptionsWithContextFrom<Machine>;
