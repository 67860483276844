import { match } from 'ts-pattern';
import { Feedback } from '../Feedback';

interface ClaimsSubmittabilityFeedbackProps {
  isCore: boolean;
  isClaimsSubmittable: boolean;
  meetsClaimsTourSummaryRequirement: boolean;
  meetsClaimsExpenseRequirement: boolean;
  meetsClaimsTourDateRequirement: boolean;
  meetsClaimsFundingRequestRequirement: boolean;
  isResubmission: boolean;
}

export function ClaimsSubmittabilityFeedback({
  isCore,
  isClaimsSubmittable,
  meetsClaimsTourSummaryRequirement,
  meetsClaimsExpenseRequirement,
  meetsClaimsTourDateRequirement,
  meetsClaimsFundingRequestRequirement,
  isResubmission,
}: ClaimsSubmittabilityFeedbackProps) {
  const warnings = (
    [
      [
        meetsClaimsTourSummaryRequirement === false,
        'Please upload tour summary.',
      ],
      [
        meetsClaimsExpenseRequirement === false,
        isCore
          ? 'Please confirm marketing expenses.'
          : 'Please confirm expenses.',
      ],
      [
        meetsClaimsTourDateRequirement === false,
        'You have tour dates pending approval.',
      ],
      [
        meetsClaimsFundingRequestRequirement === false,
        isCore
          ? 'You have digital content requests pending approval.'
          : 'You have funding requests pending approval.',
      ],
    ] satisfies Array<[boolean, string]>
  )
    .filter(([show]) => show)
    .map(([, text]) => text);

  return isClaimsSubmittable ? (
    <Feedback
      icon="checkCircle"
      type="positive"
      title={`Your funding claim is ready to ${
        isResubmission ? 're' : ''
      }submit.`}
    >
      Please review the details and {isResubmission ? 're' : ''}submit when
      ready. Once your funding claim is {isResubmission ? 're' : ''}submitted,
      you will not be able to make changes.
    </Feedback>
  ) : (
    <Feedback
      icon="alertTriangle"
      type="warning"
      title={`Your funding claim is NOT yet ready to ${
        isResubmission ? 're' : ''
      }submit.`}
    >
      {match(warnings.length)
        .with(0, () => '')
        .with(1, () => warnings[0])
        .otherwise(() => (
          <ul>
            {warnings.map((warning) => (
              <li key={warning}>{warning}</li>
            ))}
          </ul>
        ))}
    </Feedback>
  );
}
