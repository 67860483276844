import { useActor } from '@xstate/react';
import { ReleaseSinglesMachineActor } from '../../../machines/Release/ReleaseSinglesMachine';
import { SinglesView } from './SinglesView';

export function SinglesController({
  actor,
}: {
  actor: ReleaseSinglesMachineActor;
}) {
  const [state, send] = useActor(actor);

  return <SinglesView state={state} send={send} />;
}
