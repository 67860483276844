import { useActor } from '@xstate/react';
import { TourDatesBlockMachineActor } from '../../machines/components/TourDatesBlockMachine';
import { TourDatesBlockView } from './TourDatesBlockView';

export function TourDatesBlockController({
  actor,
  isReopened,
}: {
  actor: TourDatesBlockMachineActor;
  isReopened: boolean;
}) {
  const [state, send] = useActor(actor);

  return (
    <TourDatesBlockView state={state} send={send} isReopened={isReopened} />
  );
}
