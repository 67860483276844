import z from 'zod';
import { tourDateRegionSchema } from './tourDateRegionSchema';

export const deleteTourDateResultSchema = z.object({
  id: z.string(),
  application: z.object({
    year: z.number(),
    tourDateRegions: tourDateRegionSchema.array(),
    alternateTourDatesAllowed: z.boolean(),
  }),
});
