import { useMachine } from '@xstate/react';
import {
  RedeemArtistInvitationMachineOptions,
  machine,
} from '../../machines/RedeemArtistInvitation/Machine';
import { RedeemArtistInvitaionView } from './RedeemArtistInvitationView';

type RedeemArtistInvitationControllerOptions = {
  options: RedeemArtistInvitationMachineOptions;
};

export function RedeemArtistInvitationController({
  options,
}: RedeemArtistInvitationControllerOptions) {
  const [state, send] = useMachine(machine, options);

  return <RedeemArtistInvitaionView state={state} send={send} />;
}
