import React from 'react';
import cn from 'classnames';
import { Icon, IconName } from './Icon';

import styles from './TextInput.module.scss';

interface TextInputProps {
  id: string;
  label?: string;
  buttonLabel?: string;
  value: string;
  disabled?: boolean;
  error?: boolean;
  placeholder?: string;
  type?: React.InputHTMLAttributes<HTMLInputElement>['type'];
  inputMode?: React.HTMLAttributes<HTMLInputElement>['inputMode'];
  maxLength?: number;
  icon?: IconName;
  iconClassName?: string;
  iconAriaLabel?: string;
  className?: string;
  formFieldStatus?: 'default' | 'text' | 'error';
  autoFocus?: boolean;
  onChange: (val: string) => void;
  onFocus?: () => void;
  onBlur?: () => void;
  onIconClick?: () => void;
}

export function TextInput({
  id,
  label,
  buttonLabel,
  value,
  disabled = false,
  error = false,
  placeholder,
  type,
  inputMode = 'text',
  maxLength,
  icon,
  iconClassName,
  className,
  formFieldStatus = 'default',
  autoFocus,
  onChange,
  onFocus,
  onBlur,
  onIconClick,
}: TextInputProps) {
  return (
    <div
      className={cn(
        styles.textInput,
        {
          [styles.error]: error || formFieldStatus === 'error',
          [styles.text]: formFieldStatus === 'text',
          [styles.disabled]: disabled,
        },
        className
      )}
    >
      <input
        id={id}
        className={styles.input}
        // See https://stackoverflow.com/a/71848275
        // eslint-disable-next-line jsx-a11y/no-autofocus
        autoFocus={autoFocus}
        type={type}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        onFocus={onFocus}
        onBlur={onBlur}
        disabled={disabled}
        aria-label={label}
        inputMode={inputMode}
        placeholder={placeholder}
        maxLength={maxLength}
      ></input>
      {icon && (
        <button
          type="button"
          className={styles.icon}
          aria-label={buttonLabel}
          onClick={onIconClick}
        >
          <Icon name={icon} className={iconClassName} />
        </button>
      )}
    </div>
  );
}
