import { useMachine } from '@xstate/react';
import {
  machine,
  SubscribeMachineOptions,
} from '../../machines/SubscribeMachine';
import { SubscribeView } from './SubscribeView';

type SubscribeControllerProps = {
  options: SubscribeMachineOptions;
};

export function SubscribeController({ options }: SubscribeControllerProps) {
  const [state, send] = useMachine(machine, options);

  return <SubscribeView state={state} send={send} />;
}
