import { useMachine } from '@xstate/react';
import { EligibilityView } from './EligibilityView';
import {
  machine,
  OrionEligibilityMachineOptions,
} from '../../../machines/Eligibility/Orion/Machine';

type EligibilityControllerProps = {
  options: OrionEligibilityMachineOptions;
};

export function EligibilityController({ options }: EligibilityControllerProps) {
  const [state, send] = useMachine(machine, options);

  return <EligibilityView state={state} send={send} />;
}
