import type { EventFrom, Sender } from 'xstate';
import { assign, createMachine } from 'xstate';
import { GetReleasePageResult } from '../../promises/createGetReleasePagePromise';
import { UpdateReleaseResult } from '../../promises/createUpdateReleasePromise';
import type { StateFrom } from '../../utils/StateFrom';
import type { MachineOptionsWithContextFrom } from '../../utils/MachineOptionsWithContextFrom';
import { createContext as createReleaseDetailsContext } from './ReleaseDetailsMachine';
import { createContext as createReleaseMasterOwnershipContext } from './ReleaseMasterOwnershipMachine';
import { createContext as createReleaseSalesContext } from './ReleaseSalesMachine';
import { createContext as createReleaseSinglesContext } from './ReleaseSinglesMachine';
import { UpdateSinglePromise } from '../../promises/updateSinglePromise';
import { CreateSinglePromise } from '../../promises/createSinglePromise';
import { isIdNotFound } from '../../errors/IdNotFoundError';
import { logMachineError } from '../../utils/logError';

export type Context = {
  createSinglePromise: CreateSinglePromise;
  updateSinglePromise: UpdateSinglePromise;
  release?: GetReleasePageResult['release'];
  artist?: GetReleasePageResult['artist'];
  genres?: GetReleasePageResult['genres'];
  labels?: GetReleasePageResult['labels'];
  distributors?: GetReleasePageResult['distributors'];
  releasesScannedDivisor?: number;
  tracksStreamedDivisor?: number;
  tracksDownloadedDivisor?: number;
  eligibilityCutoffDate?: string;
  isAdmin: boolean;
};

type Services = {
  getReleasePage: {
    data: GetReleasePageResult;
  };
};

type Events =
  | UpdateCachedRelease
  | EditReleaseDetails
  | CancelEditReleaseDetails
  | EditSales
  | CancelEditReleaseMasterOwnership
  | EditReleaseMasterOwnership
  | CancelEditSales
  | AttemptRemoveRelease;

export type UpdateCachedRelease = {
  type: 'UPDATE_CACHED_RELEASE';
  data: UpdateReleaseResult;
};
export type EditReleaseDetails = {
  type: 'EDIT_RELEASE_DETAILS';
};
export type CancelEditReleaseDetails = {
  type: 'CANCEL_EDIT_RELEASE_DETAILS';
};
export type EditSales = {
  type: 'EDIT_SALES';
};
export type CancelEditSales = {
  type: 'CANCEL_EDIT_SALES';
};
export type EditReleaseMasterOwnership = {
  type: 'EDIT_RELEASE_MASTER_OWNERSHIP';
};
export type CancelEditReleaseMasterOwnership = {
  type: 'CANCEL_EDIT_RELEASE_MASTER_OWNERSHIP';
};
export type AttemptRemoveRelease = {
  type: 'ATTEMPT_DELETE_RELEASE';
};
export const updateCachedRelease = (data: UpdateReleaseResult) => ({
  type: 'UPDATE_CACHED_RELEASE',
  data,
});
export const cancelEditReleaseDetails = () => ({
  type: 'CANCEL_EDIT_RELEASE_DETAILS',
});
export const cancelEditSales = () => ({
  type: 'CANCEL_EDIT_SALES',
});
export const cancelEditReleaseMasterOwnership = () => ({
  type: 'CANCEL_EDIT_RELEASE_MASTER_OWNERSHIP',
});

export const machine = createMachine(
  {
    predictableActionArguments: true,
    tsTypes: {} as import('./ReleaseMachine.typegen').Typegen0,
    schema: {
      context: {} as Context,
      events: {} as Events,
      services: {} as Services,
    },
    initial: 'loadingRelease',
    states: {
      loadingRelease: {
        invoke: {
          id: 'getReleasePage',
          src: 'getReleasePage',
          onDone: [
            {
              cond: 'releaseNotFound',
              target: 'notFound',
            },
            {
              cond: 'artistNotFound',
              target: 'notFound',
            },
            {
              cond: 'genresNotFound',
              target: 'loadingError',
            },
            {
              cond: 'labelsNotFound',
              target: 'loadingError',
            },
            {
              cond: 'distributorsNotFound',
              target: 'loadingError',
            },
            {
              cond: 'salesDivisorsNotFound',
              target: 'loadingError',
            },
            {
              target: 'ready',
              actions: [
                'updateCachedRelease',
                'updateCachedArtist',
                'updateGenres',
                'updateLabels',
                'updateDistributors',
                'updateDivisors',
              ],
            },
          ],
          onError: [
            { cond: 'isIdNotFound', target: 'notFound' },
            { target: 'loadingError' },
          ],
        },
      },
      loadingError: { entry: 'logMachineError' },
      notFound: {},
      deletingRelease: {
        invoke: {
          id: 'deleteRelease',
          src: 'deleteRelease',
          onDone: [
            { actions: 'redirectToDashboard', target: 'releaseDeleted' },
          ],
          onError: 'deleteError',
        },
      },
      releaseDeleted: {},
      deleteError: { entry: 'logMachineError' },
      ready: {
        type: 'parallel',
        on: {
          UPDATE_CACHED_RELEASE: [
            {
              actions: 'updateCachedRelease',
            },
          ],
          EDIT_RELEASE_DETAILS: '.releaseDetails.open',
          EDIT_SALES: '.releaseSales.open',
          EDIT_RELEASE_MASTER_OWNERSHIP: '.releaseMasterOwnership.open',
          ATTEMPT_DELETE_RELEASE: 'deletingRelease',
        },
        states: {
          releaseDetails: {
            initial: 'closed',
            states: {
              closed: {},
              open: {
                invoke: {
                  id: 'releaseDetailsMachine',
                  src: 'releaseDetailsMachine',
                  data: (context) => {
                    const cutOffDate = context.eligibilityCutoffDate;

                    if (
                      !context.release ||
                      !cutOffDate ||
                      !context.labels ||
                      !context.release.label ||
                      !context.distributors ||
                      !context.release.distributor
                    ) {
                      throw new Error('Required data not found');
                    }
                    return createReleaseDetailsContext({
                      releaseId: context.release.id,
                      eligibilityCutoffDate: cutOffDate,
                      labels: context.labels,
                      distributors: context.distributors,
                      title: context.release.title,
                      date: context.release.date,
                      numberOfMinutes: String(context.release.numberOfMinutes),
                      label: context.release.label,
                      distributor: context.release.distributor,
                      otherLabel: context.release.otherLabel ?? null,
                      otherDistributor:
                        context.release.otherDistributor ?? null,
                      numberOfTracks: String(context.release.numberOfTracks),
                      isMajorityEnglish: context.release.isMajorityEnglish,
                    });
                  },
                },
                on: {
                  CANCEL_EDIT_RELEASE_DETAILS: 'closed',
                },
              },
            },
          },
          releaseSales: {
            initial: 'closed',
            states: {
              closed: {},
              open: {
                invoke: {
                  id: 'releaseSalesMachine',
                  src: 'releaseSalesMachine',
                  data: ({
                    release,
                    genres,
                    releasesScannedDivisor,
                    tracksStreamedDivisor,
                    tracksDownloadedDivisor,
                    isAdmin,
                  }) => {
                    if (
                      !release ||
                      !release.genre ||
                      !genres ||
                      !releasesScannedDivisor ||
                      !tracksStreamedDivisor ||
                      !tracksDownloadedDivisor
                    ) {
                      throw new Error('Required data not found');
                    }

                    const sales = release.sales?.[0];
                    if (
                      !genres.find((genre) => genre.id === release.genre?.id)
                    ) {
                      genres.push(release.genre);
                    }
                    return createReleaseSalesContext(
                      release.id,
                      Boolean(release.isRestricted),
                      {
                        genreId: release.genre?.id,
                        releasesScanned: String(sales.releasesScanned),
                        tracksDownloaded: String(sales.tracksDownloaded),
                        tracksStreamed: String(sales.tracksStreamed),
                      },
                      genres,
                      releasesScannedDivisor,
                      tracksStreamedDivisor,
                      tracksDownloadedDivisor,
                      isAdmin
                    );
                  },
                },
                on: {
                  CANCEL_EDIT_SALES: 'closed',
                },
              },
            },
          },
          releaseMasterOwnership: {
            initial: 'closed',
            states: {
              closed: {},
              open: {
                invoke: {
                  id: 'releaseMasterOwnershipMachine',
                  src: 'releaseMasterOwnershipMachine',
                  data: ({ release }) => {
                    if (!release) {
                      throw new Error('Required data not found');
                    }
                    return createReleaseMasterOwnershipContext(release.id, {
                      company: release.company,
                      website: release.website,
                      contact: release.contact,
                      address: release.address,
                      inCanada: release.inCanada,
                      phoneNumber: release.phoneNumber,
                      email: release.email,
                      notes: release.notes,
                    });
                  },
                },
                on: {
                  CANCEL_EDIT_RELEASE_MASTER_OWNERSHIP: 'closed',
                },
              },
            },
          },
          releaseSingles: {
            initial: 'ready',
            states: {
              ready: {
                invoke: {
                  id: 'releaseSinglesMachine',
                  src: 'releaseSinglesMachine',
                  data: ({
                    release,
                    createSinglePromise,
                    updateSinglePromise,
                  }) => {
                    if (!release) {
                      throw new Error('Required data not found');
                    }
                    return createReleaseSinglesContext(
                      release.id,
                      release.singles,
                      release.isOwner ?? false,
                      createSinglePromise,
                      updateSinglePromise
                    );
                  },
                },
              },
            },
          },
        },
      },
    },
  },
  {
    guards: {
      releaseNotFound: (_, { data }) => !data.release,
      artistNotFound: (_, { data }) => !data.artist,
      genresNotFound: (_, { data }) => !data.genres,
      labelsNotFound: (_, { data }) => !data.labels,
      distributorsNotFound: (_, { data }) => !data.distributors,
      salesDivisorsNotFound: (_, { data }) =>
        !(
          data.eligibilityCutoffDate &&
          data.releasesScannedDivisor &&
          data.tracksStreamedDivisor &&
          data.tracksDownloadedDivisor
        ),
      isIdNotFound: (_, { data }) => isIdNotFound(data),
    },
    actions: {
      logMachineError,
      updateCachedRelease: assign((_, { data }) =>
        data.release ? { release: data.release } : {}
      ),
      updateCachedArtist: assign((_, { data }) =>
        data.artist ? { artist: data.artist } : {}
      ),
      updateGenres: assign((_, { data }) =>
        data.genres ? { genres: data.genres } : {}
      ),
      updateLabels: assign((_, { data }) =>
        data.labels ? { labels: data.labels } : {}
      ),
      updateDistributors: assign((_, { data }) =>
        data.distributors ? { distributors: data.distributors } : {}
      ),
      updateDivisors: assign((_, { data }) => ({
        eligibilityCutoffDate: data.eligibilityCutoffDate || undefined,
        releasesScannedDivisor: data.releasesScannedDivisor || undefined,
        tracksDownloadedDivisor: data.tracksDownloadedDivisor || undefined,
        tracksStreamedDivisor: data.tracksStreamedDivisor || undefined,
      })),
    },
  }
);

type Machine = typeof machine;

export type ReleaseMachineState = StateFrom<Machine>;
export type ReleaseMachineSender = Sender<EventFrom<Machine>>;
export type ReleaseUseMachineOptions = MachineOptionsWithContextFrom<Machine>;
