import type {
  ActorRefFrom,
  Sender,
  MachineOptionsFrom,
  EventFrom,
} from 'xstate';
import { assign, createMachine, sendParent } from 'xstate';

import { match } from 'ts-pattern';
import { Context as ParentContext } from './Context';
import { ReplayableEvent, createReplay } from '../../utils/replay';
import { back, next } from '../navigation';

import { StateFrom } from '../../utils/StateFrom';
import { parsePositiveInteger } from '../../utils/parsePositiveInteger';
import { formatNumber } from '../../utils/formatNumber';
import { totalUnits, Sales } from '../../utils/totalUnits';
import { EligibilityStorage } from './storage';
import { AddReleaseStorage } from '../AddRelease/storage';

type Genres = NonNullable<ParentContext['genres']>;
type Genre = Genres[0];

type SetGenreEvent = ReplayableEvent<{
  type: 'SET_GENRE';
  genre: Genre;
}>;

type SetReleasesScanned = ReplayableEvent<{
  type: 'SET_RELEASES_SCANNED';
  value: string;
}>;

type SetTracksDownloaded = ReplayableEvent<{
  type: 'SET_TRACKS_DOWNLOADED';
  value: string;
}>;

type SetTracksStreamed = ReplayableEvent<{
  type: 'SET_TRACKS_STREAMED';
  value: string;
}>;

type ReplayableEvents =
  | SetGenreEvent
  | ReplayableEvent<{ type: 'FOCUS_RELEASES_SCANNED' }>
  | SetReleasesScanned
  | ReplayableEvent<{ type: 'BLUR_RELEASES_SCANNED' }>
  | ReplayableEvent<{ type: 'FOCUS_TRACKS_DOWNLOADED' }>
  | SetTracksDownloaded
  | ReplayableEvent<{ type: 'BLUR_TRACKS_DOWNLOADED' }>
  | ReplayableEvent<{ type: 'FOCUS_TRACKS_STREAMED' }>
  | SetTracksStreamed
  | ReplayableEvent<{ type: 'BLUR_TRACKS_STREAMED' }>;

type Events = { type: 'NEXT' } | { type: 'BACK' } | ReplayableEvents;

type Context = {
  type: 'eligibility' | 'add-release';
  genre?: Genre;
  genres: Genres;
  releasesScanned: Sales;
  tracksDownloaded: Sales;
  tracksStreamed: Sales;
};

export const machine = createMachine(
  {
    predictableActionArguments: true,
    tsTypes: {} as import('./SalesMachine.typegen').Typegen0,
    schema: {
      events: {} as Events,
      context: {} as Context,
    },
    id: 'sales',
    initial: 'pending',
    invoke: {
      id: 'resume',
      src: 'resume',
    },
    states: {
      pending: {
        type: 'parallel',
        states: {
          genre: {
            initial: 'pristine',
            states: {
              pristine: {
                on: {
                  NEXT: 'error',
                },
              },
              error: {},
              valid: {
                type: 'final',
              },
            },
            on: {
              SET_GENRE: {
                target: '.valid',
                actions: ['setGenre', 'saveGenre'],
              },
            },
          },
          releasesScanned: {
            initial: 'pristine',
            states: {
              pristine: {
                on: {
                  NEXT: 'error.empty',
                  FOCUS_RELEASES_SCANNED: 'draft',
                },
              },
              draft: {
                on: {
                  SET_RELEASES_SCANNED: {
                    actions: ['setSalesValue', 'saveSalesValue'],
                  },
                  BLUR_RELEASES_SCANNED: [
                    { cond: 'isEmpty', target: 'error.empty' },
                    {
                      cond: 'isValid',
                      target: 'valid',
                      actions: 'formatSalesValue',
                    },
                    { target: 'error.invalid' },
                  ],
                },
              },
              error: {
                initial: 'invalid',
                states: {
                  invalid: {},
                  empty: {},
                },
                on: {
                  FOCUS_RELEASES_SCANNED: 'draft',
                },
              },
              valid: {
                type: 'final',
                on: {
                  FOCUS_RELEASES_SCANNED: 'draft',
                },
              },
            },
          },
          tracksDownloaded: {
            initial: 'pristine',
            states: {
              pristine: {
                on: {
                  NEXT: 'error.empty',
                  FOCUS_TRACKS_DOWNLOADED: 'draft',
                },
              },
              draft: {
                on: {
                  SET_TRACKS_DOWNLOADED: {
                    actions: ['setSalesValue', 'saveSalesValue'],
                  },
                  BLUR_TRACKS_DOWNLOADED: [
                    { cond: 'isEmpty', target: 'error.empty' },
                    {
                      cond: 'isValid',
                      target: 'valid',
                      actions: 'formatSalesValue',
                    },
                    { target: 'error.invalid' },
                  ],
                },
              },
              error: {
                initial: 'invalid',
                states: {
                  invalid: {},
                  empty: {},
                },
                on: {
                  FOCUS_TRACKS_DOWNLOADED: 'draft',
                },
              },
              valid: {
                type: 'final',
                on: {
                  FOCUS_TRACKS_DOWNLOADED: 'draft',
                },
              },
            },
          },
          tracksStreamed: {
            initial: 'pristine',
            states: {
              pristine: {
                on: {
                  NEXT: 'error.empty',
                  FOCUS_TRACKS_STREAMED: 'draft',
                },
              },
              draft: {
                on: {
                  SET_TRACKS_STREAMED: {
                    actions: ['setSalesValue', 'saveSalesValue'],
                  },
                  BLUR_TRACKS_STREAMED: [
                    { cond: 'isEmpty', target: 'error.empty' },
                    {
                      cond: 'isValid',
                      target: 'valid',
                      actions: 'formatSalesValue',
                    },
                    { target: 'error.invalid' },
                  ],
                },
              },
              error: {
                initial: 'invalid',
                states: {
                  invalid: {},
                  empty: {},
                },
                on: {
                  FOCUS_TRACKS_STREAMED: 'draft',
                },
              },
              valid: {
                type: 'final',
                on: {
                  FOCUS_TRACKS_STREAMED: 'draft',
                },
              },
            },
          },
        },
        onDone: [
          { cond: 'isAbove', target: 'complete.ineligible.status.above' },
          { cond: 'isBelow', target: 'complete.ineligible.status.below' },
          { target: 'complete.eligible' },
        ],
      },
      complete: {
        states: {
          ineligible: {
            type: 'parallel',
            states: {
              highlight: {
                initial: 'off',
                states: {
                  off: {
                    on: {
                      NEXT: 'on',
                    },
                  },
                  on: {
                    after: {
                      1000: { target: 'off' },
                    },
                  },
                },
              },
              status: {
                initial: 'below',
                states: {
                  below: {},
                  above: {},
                },
                on: {
                  NEXT: { cond: 'isAddReleaseVariant', actions: 'next' },
                },
              },
            },
          },
          eligible: {
            on: {
              NEXT: { actions: 'next' },
            },
          },
        },
        on: {
          SET_GENRE: {
            actions: ['setGenre', 'saveGenre'],
            target: [
              'pending.genre.valid',
              'pending.releasesScanned.valid',
              'pending.tracksDownloaded.valid',
              'pending.tracksStreamed.valid',
            ],
          },
          FOCUS_RELEASES_SCANNED: {
            target: [
              'pending.genre.valid',
              'pending.releasesScanned.draft',
              'pending.tracksDownloaded.valid',
              'pending.tracksStreamed.valid',
            ],
          },
          FOCUS_TRACKS_DOWNLOADED: {
            target: [
              'pending.genre.valid',
              'pending.releasesScanned.valid',
              'pending.tracksDownloaded.draft',
              'pending.tracksStreamed.valid',
            ],
          },
          FOCUS_TRACKS_STREAMED: {
            target: [
              'pending.genre.valid',
              'pending.releasesScanned.valid',
              'pending.tracksDownloaded.valid',
              'pending.tracksStreamed.draft',
            ],
          },
        },
      },
    },
    on: {
      BACK: { actions: 'back' },
    },
  },
  {
    guards: {
      isValid: (
        { releasesScanned, tracksDownloaded, tracksStreamed },
        { type }
      ) =>
        !Number.isNaN(
          parsePositiveInteger(
            match(type)
              .with('BLUR_RELEASES_SCANNED', () => releasesScanned.value)
              .with('BLUR_TRACKS_DOWNLOADED', () => tracksDownloaded.value)
              .with('BLUR_TRACKS_STREAMED', () => tracksStreamed.value)
              .exhaustive()
          )
        ),
      isEmpty: (
        { releasesScanned, tracksDownloaded, tracksStreamed },
        { type }
      ) =>
        match(type)
          .with('BLUR_RELEASES_SCANNED', () => releasesScanned.value)
          .with('BLUR_TRACKS_DOWNLOADED', () => tracksDownloaded.value)
          .with('BLUR_TRACKS_STREAMED', () => tracksStreamed.value)
          .exhaustive()
          .trim().length === 0,
      isAbove: ({
        genre,
        releasesScanned,
        tracksDownloaded,
        tracksStreamed,
      }: Context) =>
        genre !== undefined &&
        totalUnits([releasesScanned, tracksDownloaded, tracksStreamed]) >
          genre.maximumSales,
      isBelow: ({
        genre,
        releasesScanned,
        tracksDownloaded,
        tracksStreamed,
      }: Context) =>
        genre !== undefined &&
        totalUnits([releasesScanned, tracksDownloaded, tracksStreamed]) <
          genre.minimumSales,
      isAddReleaseVariant: ({ type }) => type === 'add-release',
    },
    actions: {
      next: sendParent(next()),
      back: sendParent(back()),
      setGenre: assign((_, { genre }) => ({ genre })),
      setSalesValue: assign(
        (
          { releasesScanned, tracksDownloaded, tracksStreamed },
          { type, value }
        ) =>
          match(type)
            .with('SET_RELEASES_SCANNED', () => ({
              releasesScanned: { ...releasesScanned, value },
            }))
            .with('SET_TRACKS_DOWNLOADED', () => ({
              tracksDownloaded: { ...tracksDownloaded, value },
            }))
            .with('SET_TRACKS_STREAMED', () => ({
              tracksStreamed: { ...tracksStreamed, value },
            }))
            .exhaustive()
      ),
      formatSalesValue: assign(
        ({ releasesScanned, tracksDownloaded, tracksStreamed }, { type }) =>
          match(type)
            .with('BLUR_RELEASES_SCANNED', () => ({
              releasesScanned: {
                ...releasesScanned,
                value: formatNumber(
                  parsePositiveInteger(releasesScanned.value)
                ),
              },
            }))
            .with('BLUR_TRACKS_DOWNLOADED', () => ({
              tracksDownloaded: {
                ...tracksDownloaded,
                value: formatNumber(
                  parsePositiveInteger(tracksDownloaded.value)
                ),
              },
            }))
            .with('BLUR_TRACKS_STREAMED', () => ({
              tracksStreamed: {
                ...tracksStreamed,
                value: formatNumber(parsePositiveInteger(tracksStreamed.value)),
              },
            }))
            .exhaustive()
      ),
    },
  }
);

type Machine = typeof machine;

export type SalesMachineState = StateFrom<Machine>;
export type SalesMachineSender = Sender<EventFrom<Machine>>;
export type SalesMachineOptions = MachineOptionsFrom<Machine, true>;
export type SalesMachineActor = ActorRefFrom<Machine>;

export const createCreateSalesContext =
  (type: 'eligibility' | 'add-release') =>
  ({
    genres,
    releasesScannedDivisor,
    tracksDownloadedDivisor,
    tracksStreamedDivisor,
  }: Pick<
    ParentContext,
    | 'genres'
    | 'releasesScannedDivisor'
    | 'tracksDownloadedDivisor'
    | 'tracksStreamedDivisor'
  >): Context => {
    return {
      type,
      genres,
      releasesScanned: { value: '', divisor: releasesScannedDivisor },
      tracksDownloaded: { value: '', divisor: tracksDownloadedDivisor },
      tracksStreamed: { value: '', divisor: tracksStreamedDivisor },
    };
  };

export const createSalesResume =
  (storage: EligibilityStorage | AddReleaseStorage) =>
  ({ genres }: Context) =>
  (send: SalesMachineSender) => {
    const replay = createReplay<ReplayableEvents>();
    const { sales } = storage.get();

    // Can we make it so that if you have left a field empty it remembers that?
    if (sales !== undefined) {
      if (sales.genreId !== undefined) {
        const genre = genres.find(({ id }) => id === sales.genreId);

        if (genre !== undefined) {
          send(replay({ type: 'SET_GENRE', genre }));
        }
      }

      (
        [
          [
            sales.releasesScanned,
            'FOCUS_RELEASES_SCANNED',
            'SET_RELEASES_SCANNED',
            'BLUR_RELEASES_SCANNED',
          ],
          [
            sales.tracksDownloaded,
            'FOCUS_TRACKS_DOWNLOADED',
            'SET_TRACKS_DOWNLOADED',
            'BLUR_TRACKS_DOWNLOADED',
          ],
          [
            sales.tracksStreamed,
            'FOCUS_TRACKS_STREAMED',
            'SET_TRACKS_STREAMED',
            'BLUR_TRACKS_STREAMED',
          ],
        ] satisfies [
          string | undefined,
          ReplayableEvents['type'],
          ReplayableEvents['type'],
          ReplayableEvents['type']
        ][]
      )
        .reduce<ReplayableEvents[]>(
          (acc, [value, focus, type, blur]) =>
            value !== undefined
              ? [...acc, { type: focus }, { type, value }, { type: blur }]
              : acc,
          []
        )
        .forEach((event) => {
          send(replay(event));
        });
    }
  };

export const createSalesSaveActions = (
  storage: EligibilityStorage | AddReleaseStorage
) => ({
  saveGenre: (_: Context, { genre: { id: genreId }, replay }: SetGenreEvent) =>
    storage.add({ sales: { genreId } }, replay),
  saveSalesValue: (
    _: Context,
    {
      type,
      value,
      replay,
    }: SetReleasesScanned | SetTracksDownloaded | SetTracksStreamed
  ) =>
    storage.add(
      match(type)
        .with('SET_RELEASES_SCANNED', () => ({
          sales: { releasesScanned: value },
        }))
        .with('SET_TRACKS_DOWNLOADED', () => ({
          sales: { tracksDownloaded: value },
        }))
        .with('SET_TRACKS_STREAMED', () => ({
          sales: { tracksStreamed: value },
        }))
        .exhaustive(),
      replay
    ),
});
