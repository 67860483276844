import { Storage, createMemoryStorage } from '../../utils/storage';

import { RedeemArtistInvitationState } from './state';

export type RedeemArtistInvitationStorage = Omit<
  Storage<RedeemArtistInvitationState>,
  'add'
> & {
  add: (state: RedeemArtistInvitationState, isReplay?: true) => void;
};

const mergeRedeemArtistInvitationStates = (
  newState: RedeemArtistInvitationState,
  oldState: RedeemArtistInvitationState
): RedeemArtistInvitationState => ({
  ...oldState,
  ...newState,
  account: {
    ...oldState.account,
    ...newState.account,
  },
});

const createRedeemArtistInvitationStorage = (
  storage: Storage<RedeemArtistInvitationState>
): RedeemArtistInvitationStorage => {
  const { add, ...rest } = storage;

  return {
    add: (state: RedeemArtistInvitationState, isReplay?: true) => {
      if (isReplay !== true) {
        add(state);
      }
    },
    ...rest,
  };
};

export const createMemoryRedeemArtistInvitationStorage = (
  initial?: RedeemArtistInvitationState
) =>
  createRedeemArtistInvitationStorage(
    createMemoryStorage<RedeemArtistInvitationState>(
      initial ?? {},
      mergeRedeemArtistInvitationStates
    )
  );
