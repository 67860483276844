import { GraphQLClient } from 'graphql-request';
import {
  SubmitApplicationClaimsDocument,
  SubmitApplicationClaimsMutationVariables,
} from '../graphql/operations';

export const createSubmitApplicationClaimsPromise =
  (client: GraphQLClient) =>
  ({ applicationId }: SubmitApplicationClaimsMutationVariables) =>
    client.request(SubmitApplicationClaimsDocument, { applicationId });

export type SubmitApplicationClaimsPromise = ReturnType<
  typeof createSubmitApplicationClaimsPromise
>;
export type SubmitApplicationClaimsPromiseResult = Awaited<
  ReturnType<SubmitApplicationClaimsPromise>
>;
