import type {
  Sender,
  EventFrom,
  MachineOptionsFrom,
  ActorRefFrom,
} from 'xstate';
import { assign, createMachine } from 'xstate';
import { StateFrom } from '../../utils/StateFrom';
import { sendParent } from 'xstate/lib/actions';
import { GetDashboardContextPromiseResult } from '../../promises/getDashboardContextPromise';
import { isPhoneNumberValid } from '../../utils/isPhoneNumberValid';
import { isEmailValid } from '../../utils/isEmailValid';
import { findById } from '../../utils/findById';
import { logMachineError } from '../../utils/logError';

type Management = GetDashboardContextPromiseResult['artist']['management'];
type ManagementForm = NonNullable<Management>;

type Events =
  | { type: 'OPEN_FORM' }
  | { type: 'CANCEL_FORM' }
  | { type: 'SUBMIT_FORM' }
  | { type: 'SET_FIELD'; field: keyof ManagementForm; value: string }
  | { type: 'UNSET_COMPANY_PROVINCE' }
  | { type: 'CLEAR_PHONE_WARNING' }
  | { type: 'CLEAR_EMAIL_WARNING' }
  | { type: 'DELETE_MANAGEMENT' };

export type Context = {
  management: Management;
  form: ManagementForm;
  countries: GetDashboardContextPromiseResult['countries'];
  provinces: GetDashboardContextPromiseResult['provinces'];
  legalStatuses: GetDashboardContextPromiseResult['legalStatuses'];
  isOwner: GetDashboardContextPromiseResult['artist']['isOwner'];
};

function getEmptyForm(): ManagementForm {
  return {
    name: '',
    countryId: '',
    contactPerson: '',
    address: '',
    phoneNumber: '',
    email: '',
    legalStatus: '',
    legalProvince: null,
  };
}

export const createContext = (
  { management, isOwner }: GetDashboardContextPromiseResult['artist'],
  countries: GetDashboardContextPromiseResult['countries'],
  provinces: GetDashboardContextPromiseResult['provinces'],
  legalStatuses: GetDashboardContextPromiseResult['legalStatuses']
): Context => ({
  management,
  form: management ?? getEmptyForm(),
  countries,
  provinces,
  legalStatuses,
  isOwner,
});

export const machine = createMachine(
  {
    predictableActionArguments: true,
    tsTypes: {} as import('./ArtistManagementMachine.typegen').Typegen0,
    schema: {
      context: {} as Context,
      events: {} as Events,
    },
    id: 'managementForm',
    initial: 'closed',
    states: {
      closed: {
        on: {
          OPEN_FORM: {
            target: 'form',
          },
          DELETE_MANAGEMENT: {
            target: 'deleting',
          },
        },
      },
      form: {
        type: 'parallel',
        states: {
          status: {
            initial: 'ready',
            states: {
              ready: {},
              invalid: {},
              error: { entry: 'logMachineError' },
            },
            on: {
              SUBMIT_FORM: [
                { cond: 'isSubmittable', target: '#managementForm.saving' },
                { target: '.invalid' },
              ],
            },
          },
          inputWarning: {
            type: 'parallel',
            states: {
              email: {
                initial: 'off',
                states: { off: {}, on: {} },
                on: {
                  SUBMIT_FORM: [
                    { cond: 'isEmailValid', target: '.off' },
                    { target: '.on' },
                  ],
                  CLEAR_EMAIL_WARNING: '.off',
                },
              },
              phone: {
                initial: 'off',
                states: { off: {}, on: {} },
                on: {
                  SUBMIT_FORM: [
                    { cond: 'isPhoneNumberValid', target: '.off' },
                    { target: '.on' },
                  ],
                  CLEAR_PHONE_WARNING: '.off',
                },
              },
            },
          },
        },
        on: {
          SET_FIELD: { actions: 'setField' },
          UNSET_COMPANY_PROVINCE: { actions: 'unsetCompanyProvince' },
          CANCEL_FORM: { actions: 'resetForm', target: 'closed' },
        },
      },
      saving: {
        invoke: {
          id: 'upsertManagement',
          src: 'upsertManagement',
          onDone: {
            actions: ['updateManagement', 'sendUpdatedManagementToParent'],
            target: 'closed',
          },
          onError: 'form.status.error',
        },
      },
      deleting: {
        invoke: {
          id: 'deleteManagement',
          src: 'deleteManagement',
          onDone: {
            actions: ['deleteManagement', 'sendUpdatedManagementToParent'],
            target: 'closed',
          },
          onError: 'error',
        },
      },
      error: { entry: 'logMachineError' },
    },
  },
  {
    guards: {
      isSubmittable: ({ form, legalStatuses }) => {
        const { legalProvince, legalStatus, email, phoneNumber } = form;
        return Boolean(
          (legalProvince ||
            findById(legalStatuses, legalStatus)?.requiresProvince === false) &&
            isEmailValid(email) &&
            isPhoneNumberValid(phoneNumber) &&
            Object.values(form).every((value) =>
              typeof value === 'string' ? !!value.trim() : true
            )
        );
      },
      isEmailValid: ({ form }) => isEmailValid(form.email),
      isPhoneNumberValid: ({ form }) => isPhoneNumberValid(form.phoneNumber),
    },
    actions: {
      logMachineError,
      resetForm: assign(({ management }) => ({
        form: management ?? getEmptyForm(),
      })),
      setField: assign(({ form }, { field, value }) => ({
        form: { ...form, [field]: value },
      })),
      unsetCompanyProvince: assign(({ form }) => ({
        form: { ...form, legalProvince: null },
      })),
      sendUpdatedManagementToParent: sendParent(({ form }, { type }) => ({
        type: 'UPDATE_MANAGEMENT',
        management: type === 'done.invoke.deleteManagement' ? null : form,
      })),
      updateManagement: assign(({ form }) => ({
        management: form,
      })),
      deleteManagement: assign((_) => ({
        management: null,
        form: getEmptyForm(),
      })),
    },
  }
);

type Machine = typeof machine;

export type ArtistManagementMachine = Machine;
export type ArtistManagementMachineState = StateFrom<Machine>;
export type ArtistManagementMachineSender = Sender<EventFrom<Machine>>;
export type ArtistManagementMachineEvent = EventFrom<Machine>;
export type ArtistManagementMachineOptions = MachineOptionsFrom<Machine, true>;
export type ArtistManagementMachineActor =
  ActorRefFrom<ArtistManagementMachine>;
