import styles from './Single.module.scss';
import { GrayBox } from '../../GrayBox';
import { Button } from '../../Button';
import { formatNumber } from '../../../utils/formatNumber';

interface SingleProps {
  id: string;
  title: string;
  spins: number;
  onRemoveClick: () => void;
  onEditClick: () => void;
}

export function Single({
  title,
  spins,
  onRemoveClick,
  onEditClick,
}: SingleProps) {
  return (
    <GrayBox>
      <div className={styles.container}>
        <dl className={styles.detailsGrid}>
          <div>
            <dt>Single title: </dt>
            <dd>{title}</dd>
          </div>
          <div>
            <dt>Spins at radio: </dt>
            <dd>{formatNumber(spins)}</dd>
          </div>
        </dl>
        <div className={styles.controls}>
          <Button
            size="small"
            variant="neutral"
            label="Remove"
            onClick={onRemoveClick}
          />
          <Button
            size="small"
            variant="secondary"
            label="Edit"
            onClick={onEditClick}
            startIcon="edit"
          />
        </div>
      </div>
    </GrayBox>
  );
}
