import { GraphQLClient } from 'graphql-request';
import { z } from 'zod';
import { IdNotFoundError } from '../errors/IdNotFoundError';
import {
  ApplicationStage,
  ApplicationStatus,
  ApplicationType,
  GetAllApplicationsPageDocument,
  GetAllApplicationsPageQueryVariables,
} from '../graphql/operations';
import { serverDateTimeSchema } from '../schemas/dateTimeSchema';
import { parseAbsolute } from '@internationalized/date';

export const createGetAllApplicationsPagePromise =
  (client: GraphQLClient) =>
  async (variables: GetAllApplicationsPageQueryVariables) => {
    const request = await client.request(
      GetAllApplicationsPageDocument,
      variables
    );

    if (!request.artist) {
      throw new IdNotFoundError();
    }

    return z
      .object({
        artistId: z.string().uuid(),
        artistName: z.string(),
        canStartApplication: z.boolean(),
        applications: z.array(
          z
            .object({
              id: z.string().uuid(),
              timezone: z.string(),
              createdAt: serverDateTimeSchema.unwrap(),
              submittedAt: serverDateTimeSchema,
              fundingRound: z
                .object({
                  title: z.string(),
                })
                .nullable(),
              type: z.nativeEnum(ApplicationType),
              status: z.nativeEnum(ApplicationStatus),
              stage: z.nativeEnum(ApplicationStage),
            })
            .transform(({ timezone, createdAt, submittedAt, ...rest }) => ({
              timezone,
              createdAt: parseAbsolute(createdAt, timezone),
              submittedAt:
                submittedAt !== null
                  ? parseAbsolute(submittedAt, timezone)
                  : null,
              ...rest,
            }))
        ),
      })
      .parse({
        artistId: request.artist.id,
        artistName: request.artist.name,
        canStartApplication: request.artist.canStartApplication,
        applications: request.artist.applications,
      });
  };

export type GetAllApplicationsPage = ReturnType<
  typeof createGetAllApplicationsPagePromise
>;
export type GetAllApplicationsPageResult = Awaited<
  ReturnType<GetAllApplicationsPage>
>;
