import { GraphQLClient } from 'graphql-request';
import { DeleteFundingRequestDocument } from '../../graphql/operations';
import { DeleteFundingRequest } from '../../events/DeleteFundingRequest';
import { deleteFundingRequestResultSchema } from '../../schemas/fundingRequests/deleteFundingRequestResultSchema';

export const createDeleteFundingRequestPromise =
  (client: GraphQLClient) =>
  async (_: unknown, { id }: DeleteFundingRequest) => {
    const response = await client.request(DeleteFundingRequestDocument, {
      deleteId: { id },
    });
    return deleteFundingRequestResultSchema.parse({
      id: response.deleteFundingRequestById?.fundingRequest?.id,
      ...response.deleteFundingRequestById?.fundingRequest?.application,
    });
  };

export type CreateDeleteFundingRequestPromise = ReturnType<
  typeof createDeleteFundingRequestPromise
>;

export type CreateDeleteFundingRequestPromiseResult = Awaited<
  ReturnType<CreateDeleteFundingRequestPromise>
>;
