import { match } from 'ts-pattern';
import {
  EligibilityNoticeMachineSender,
  EligibilityNoticeMachineState,
} from '../../../machines/EligibilityNoticeMachine';
import { Button } from '../../Button';
import { ErrorFeedback } from '../../ErrorFeedback';
import { Block } from '../../Block';
import { Loading } from '../../Loading';
import { EligibilityNoticeFeedback } from './EligibilityNoticeFeedback';
import { EligibilityNoticeForm } from './EligibilityNoticeForm';

import styles from './EligibilityNoticeView.module.scss';

interface EligibilityNoticeProps {
  state: EligibilityNoticeMachineState;
  send: EligibilityNoticeMachineSender;
}

export function EligibilityNoticeView({ state, send }: EligibilityNoticeProps) {
  return match({
    notice: state.context.notice,
    isFormOpen: state.context.adminForm.isOpen,
    hasError: state.matches('operationError'),
    isLoading:
      state.matches('deletingNotice') || state.matches('submittingForm'),
  })
    .with({ hasError: true }, () => <ErrorFeedback />)
    .with({ isLoading: true }, () => (
      <Block className={styles.loading}>
        <Loading inline={true} />
      </Block>
    ))
    .with({ isFormOpen: true }, () => (
      <EligibilityNoticeForm state={state} send={send} />
    ))
    .with({ notice: null }, () => (
      <Button
        className={styles.button}
        startIcon="settings"
        variant="secondary"
        size="small"
        label="Add Artist Note"
        onClick={() => {
          send('OPEN_FORM');
        }}
      />
    ))
    .otherwise(({ notice }) => (
      <EligibilityNoticeFeedback
        notice={notice}
        feedbackButtons={
          <div className={styles.feedbackButtons}>
            <Button
              variant="neutral"
              size="small"
              label="Remove Note"
              onClick={() => {
                send('DELETE_NOTICE');
              }}
            />
            <Button
              startIcon="settings"
              variant="secondary"
              size="small"
              label="Change Artist Note"
              onClick={() => {
                send('OPEN_FORM');
              }}
            />
          </div>
        }
      />
    ));
}
