import { ReactNode } from 'react';
import cn from 'classnames';
import { Breadcrumb, Breadcrumbs } from './Breadcrumbs';
import styles from './IndexPage.module.scss';
import { Block } from './Block';
import { Button } from './Button';

interface Props {
  heading?: ReactNode;
  breadcrumbs?: Breadcrumb[];
  buttonText?: string;
  buttonOnClick?: () => void;
  buttonLinkTo?: string;
  buttonDisabled?: boolean;
  buttonHidden?: boolean;
  children?: ReactNode;
}

export function IndexPage({
  heading,
  breadcrumbs,
  buttonText,
  buttonOnClick,
  buttonLinkTo,
  buttonDisabled,
  buttonHidden,
  children,
}: Props) {
  return (
    <div className={cn(styles.container, 'max-w-xl')}>
      {breadcrumbs && <Breadcrumbs breadcrumbs={breadcrumbs} />}
      <div className={styles.headingContainer}>
        <h1 className={styles.heading}>{heading}</h1>
        {buttonText &&
          !buttonHidden &&
          (buttonOnClick !== undefined || buttonLinkTo !== undefined) && (
            <Button
              label={buttonText}
              variant="secondary"
              size="small"
              startIcon="plus"
              onClick={buttonOnClick}
              linkTo={buttonLinkTo}
              disabled={buttonDisabled}
            />
          )}
      </div>
      <Block>
        <div className={styles.content}>{children}</div>
      </Block>
    </div>
  );
}
