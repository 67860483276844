import { EventFrom, Sender, StateFrom, assign, createMachine } from 'xstate';
import { ZonedDateTime } from '@internationalized/date';
import { GetFluxCapacitorPromiseResult } from '../promises/getFluxCapacitor';
import { SetFluxCapacitorPromiseResult } from '../promises/setFluxCapacitor';
import { ClearFluxCapacitorPromiseResult } from '../promises/clearFluxCapacitor';

type Context = {
  time: ZonedDateTime | null;
  serverTime: ZonedDateTime | null;
  timezone: string;
  hint: boolean;
  rounds: GetFluxCapacitorPromiseResult['rounds'];
};

type Events =
  | { type: 'SUBMIT' }
  | { type: 'SET_TIME'; data: ZonedDateTime }
  | { type: 'SET_FLUX_CAPACITOR' }
  | { type: 'CLEAR_FLUX_CAPACITOR' }
  | { type: 'SWITCH_TO_ROUND' }
  | { type: 'SWITCH_TO_TIME' };

type Services = {
  getFluxCapacitor: { data: GetFluxCapacitorPromiseResult };
  setFluxCapacitor: { data: SetFluxCapacitorPromiseResult };
  clearFluxCapacitor: { data: ClearFluxCapacitorPromiseResult };
};

export const machine = createMachine(
  {
    predictableActionArguments: true,
    tsTypes: {} as import('./TimeMachine.typegen').Typegen0,
    schema: {
      context: {} as Context,
      events: {} as Events,
      services: {} as Services,
    },
    context: {
      time: null,
      serverTime: null,
      timezone: 'UTC',
      hint: false,
      rounds: [],
    },
    initial: 'getting',
    states: {
      waiting: {
        initial: 'time',
        states: {
          time: {
            initial: 'pristine',
            states: {
              pristine: {},
              dirty: {},
            },
            on: {
              SET_TIME: { target: '.dirty', actions: 'setTimeFromLocal' },
            },
          },
          round: {
            initial: 'pristine',
            states: {
              pristine: {},
              dirty: {},
            },
            on: {
              SET_TIME: { target: '.dirty', actions: 'setTimeFromLocal' },
            },
          },
          hist: {
            type: 'history',
            history: 'shallow',
          },
        },
      },
      getting: {
        invoke: {
          id: 'getFluxCapacitor',
          src: 'getFluxCapacitor',
          onDone: [{ actions: 'setTimeFromServer', target: 'waiting.hist' }],
          onError: 'waiting.hist',
        },
      },
      setting: {
        invoke: {
          id: 'setFluxCapacitor',
          src: 'setFluxCapacitor',
          onDone: [
            {
              actions: ['setTimeFromServer', 'showHint'],
              target: 'waiting.hist',
            },
          ],
          onError: 'waiting.hist',
        },
      },
      clearing: {
        invoke: {
          id: 'clearFluxCapacitor',
          src: 'clearFluxCapacitor',
          onDone: [{ actions: 'setTimeFromServer', target: 'waiting.hist' }],
          onError: 'waiting.hist',
        },
      },
    },
    on: {
      SWITCH_TO_ROUND: 'waiting.round',
      SWITCH_TO_TIME: 'waiting.time',
      SET_FLUX_CAPACITOR: 'setting',
      CLEAR_FLUX_CAPACITOR: 'clearing',
    },
  },
  {
    actions: {
      setTimeFromServer: assign((_, { data: { time, ...rest } }) => ({
        time,
        serverTime: time,
        ...rest,
      })),
      setTimeFromLocal: assign((_, { data: time }) => ({ time })),
      showHint: assign((_, { data: { time } }) => ({ hint: time === null })),
    },
  }
);

type Machine = typeof machine;

export type TimeMachineState = StateFrom<Machine>;
export type TimeMachineSender = Sender<EventFrom<Machine>>;
