import { InlineFormContainer } from '../InlineFormContainer';
import { InlineFormHeading } from '../InlineFormHeading';
import { Button } from '../Button';
import { DirectDepositsMachineSender } from '../../machines/ClaimFunds/DirectDepositsMachine';

interface DepositInfoChooserProps {
  send: DirectDepositsMachineSender;
}

export function DepositInfoChooser({ send }: DepositInfoChooserProps) {
  return (
    <InlineFormContainer
      secondaryButtonText="Cancel"
      secondaryButtonOnClick={() =>
        send({ type: 'CLOSE_DEPOSIT_INFO_CHOOSER' })
      }
    >
      <InlineFormHeading>
        Which deposit info do you want to change?
      </InlineFormHeading>

      <Button
        size="small"
        length="auto"
        label="Change Tour Deposit Info"
        onClick={() => send('CHOOSE_TOUR_DEPOSIT_INFO')}
      />
      <Button
        size="small"
        length="auto"
        label="Change Digital Content Deposit Info"
        onClick={() => send('CHOOSE_FUNDING_REQUEST_DEPOSIT_INFO')}
      />
    </InlineFormContainer>
  );
}
