import { z } from 'zod';
import { rolesSchema } from './role';

export const redeemInvitationSuccessResponseSchema = z.object({
  id: z.string(),
  roles: rolesSchema,
  artists: z.array(z.object({ id: z.string(), name: z.string() })),
  message: z.literal('signed-in'),
});

export const redeemInvitationResponseSchema = z.union([
  redeemInvitationSuccessResponseSchema,
  z.object({
    message: z.union([
      z.literal('invalid-request'),
      z.literal('invalid-email'),
      z.literal('invalid-password'),
      z.literal('invalid-tag'),
    ]),
  }),
]);
