import { match } from 'ts-pattern';

import { ReleaseMachineActor } from '../../machines/Eligibility/ReleaseMachine';
import { SalesMachineActor } from '../../machines/Eligibility/SalesMachine';
import { MasterRecordingActor } from '../../machines/Eligibility/MasterRecordingMachine';

import { ReleaseController } from '../../components/Eligibility/Release/ReleaseController';
import { SalesController } from '../../components/Eligibility/Sales/SalesController';
import { MasterRecordingController } from '../../components/Eligibility/MasterRecording/MasterRecordingController';

import { ErrorPage } from '../../pages/ErrorPage';
import { Loading } from '../../components/Loading';
import { Breadcrumbs } from '../../components/Breadcrumbs';

import { AddReleaseMachineState } from '../../machines/AddRelease/Machine';

interface AddReleaseViewProps {
  artistId: string;
  state: AddReleaseMachineState;
}

export function AddReleaseView({ state, artistId }: AddReleaseViewProps) {
  return match(state)
    .when(
      () => state.matches('error'),
      () => <ErrorPage />
    )
    .when(
      () =>
        state.matches('init') ||
        state.matches('resume') ||
        state.matches('saving') ||
        state.matches('done'),
      () => <Loading />
    )
    .otherwise(() => (
      <>
        <Breadcrumbs
          breadcrumbs={[
            { text: 'Dashboard', to: `/artist/${artistId}` },
            { text: 'All Releases', to: `/artist/${artistId}/releases` },
          ]}
        />
        {match(state)
          .when(
            () => state.matches('release'),
            () => (
              <ReleaseController
                actor={state.children.releaseMachine as ReleaseMachineActor}
              />
            )
          )
          .when(
            () => state.matches('sales'),
            () => (
              <SalesController
                actor={state.children.salesMachine as SalesMachineActor}
              />
            )
          )
          .when(
            () => state.matches('masterRecording'),
            () => (
              <MasterRecordingController
                type="add-release"
                actor={
                  state.children.masterRecordingMachine as MasterRecordingActor
                }
              />
            )
          )
          .otherwise(() => null)}
      </>
    ));
}
