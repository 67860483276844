import { z } from 'zod';

import { artistInvitationStatusSchema as status } from './artistInvitationStatusSchema';
import { minimumPasswordLengthSchema as minimumPasswordLength } from './minimumPasswordLengthSchema';

export const redeemArtistInvitationContextSchema = z.object({
  status,
  minimumPasswordLength,
});

export type RedeemArtistInvitationContext = z.infer<
  typeof redeemArtistInvitationContextSchema
>;
