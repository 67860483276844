import styles from './VenueCapacityFeedback.module.scss';
import { Feedback, FeedbackSize } from '../Feedback';
import { match } from 'ts-pattern';
import { formatNumber } from '../../utils/formatNumber';

export function VenueCapacityFeedback({
  type,
  title,
  limit,
  size = 'default',
}: {
  type: 'above' | 'below';
  title: string;
  limit: number;
  size?: FeedbackSize;
}) {
  return (
    <Feedback
      title={
        size === 'default'
          ? 'Venue capacity requirements are not met'
          : undefined
      }
      type="warning"
      size={size}
    >
      {match(type)
        .with('above', () => (
          <p className={styles.feedback}>
            <strong>{title}</strong> appearances may not have a venue capacity
            larger than {formatNumber(limit)} for ticketed performances.
          </p>
        ))
        .with('below', () => (
          <p className={styles.feedback}>
            <strong>{title}</strong> appearances may not have a venue capacity
            smaller than {formatNumber(limit)} for ticketed performances.
          </p>
        ))
        .exhaustive()}
    </Feedback>
  );
}
