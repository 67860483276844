import { Button, ButtonVariant } from '../Button';
import cn from 'classnames';

import styles from './AccountCard.module.scss';

export interface AccountCardProps {
  name?: string;
  type?: 'compact' | 'full';
  email: string;
  pending?: boolean;
  isYou: boolean;
  isOwner: boolean;
  onClick?: () => void;
  buttonText?: string;
  buttonLinkTo?: string;
  buttonVariant?: ButtonVariant;
  buttonDisabled?: boolean;
}

export function AccountCard({
  name,
  type = 'compact',
  email,
  pending = false,
  isYou,
  isOwner,
  onClick,
  buttonLinkTo,
  buttonText = 'Remove',
  buttonVariant = 'neutral',
  buttonDisabled = false,
}: AccountCardProps) {
  return (
    <div className={styles.card}>
      <div className={styles.titleRow}>
        <div
          className={cn({
            [styles.col]: type === 'compact',
            [styles.collapseRow]: type === 'full',
          })}
        >
          <div className={styles.title}>
            {name && (
              <h3 className={styles.heading}>
                {name} {isYou ? '(you)' : ''}
              </h3>
            )}
            {pending && <div className={styles.pending}>Pending</div>}
          </div>
          <div className={styles.email}>{email}</div>
        </div>
        {!isYou && isOwner && (
          <div className={styles.col}>
            <Button
              variant={buttonVariant}
              label={buttonText}
              size="small"
              linkTo={buttonLinkTo}
              onClick={onClick}
              disabled={buttonDisabled}
            />
          </div>
        )}
      </div>
    </div>
  );
}
