import { useActor } from '@xstate/react';
import { EmailInvitationMachineActor } from '../../../machines/Eligibility/EmailInvitationMachine';
import { SummaryView } from './SummaryView';

interface Props {
  actor: EmailInvitationMachineActor;
}

export const SummaryController = ({ actor }: Props) => {
  const [state, send] = useActor(actor);

  return <SummaryView state={state} send={send} />;
};
