import { z } from 'zod';
import { GraphQLClient } from 'graphql-request';
import { GetAddReleaseContextDocument } from '../graphql/operations';
import { serverDateSchema } from '../schemas/dateSchema';
import { NoGenresFoundError } from '../errors/NoGenresFoundError';
import { NoLabelsFoundError } from '../errors/NoLabelsFoundError';
import { NoDistributorsFoundError } from '../errors/NoDistributorsFoundError';

export const createGetAddReleaseContextPromise =
  (client: GraphQLClient) => async () => {
    const result = await client.request(GetAddReleaseContextDocument);

    const genres = result.genres;
    if (!Array.isArray(genres) || genres.length === 0) {
      throw new NoGenresFoundError();
    }

    const labels = result.labels;
    if (!Array.isArray(labels) || labels.length === 0) {
      throw new NoLabelsFoundError();
    }

    const distributors = result.distributors;
    if (!Array.isArray(distributors) || distributors.length === 0) {
      throw new NoDistributorsFoundError();
    }

    return {
      genres,
      labels,
      distributors,
      ...z
        .object({
          eligibilityCutoffDate: serverDateSchema,
          releasesScannedDivisor: z.number(),
          tracksDownloadedDivisor: z.number(),
          tracksStreamedDivisor: z.number(),
        })
        .parse(result),
    };
  };

export type GetAddReleaseContextPromise = ReturnType<
  typeof createGetAddReleaseContextPromise
>;
export type GetAddReleaseContextPromiseResult = Awaited<
  ReturnType<GetAddReleaseContextPromise>
>;
