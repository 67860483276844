import { InlineFormContainer } from '../../InlineFormContainer';
import { TextInput } from '../../TextInput';
import {
  ArtistDetailsBlockMachineSender,
  ArtistDetailsBlockMachineState,
} from '../../../machines/components/ArtistDetailsBlockMachine';
import { InlineFormHeading } from '../../InlineFormHeading';
import { Select } from '../../Select';
import { RadioOption } from '../../RadioOption';
import { Feedback } from '../../Feedback';
import { findById } from '../../../utils/findById';
import { ErrorFeedback } from '../../ErrorFeedback';
import { RequiredFormField } from '../../RequiredFormField';

interface ArtistFormProps {
  state: ArtistDetailsBlockMachineState;
  send: ArtistDetailsBlockMachineSender;
}

export function ArtistForm({ state, send }: ArtistFormProps) {
  const { provinces, legalStatuses } = state.context;
  const {
    companyLegalProvince,
    companyName,
    legalStatus: legalStatusId,
    name,
    provinceId,
  } = state.context.form;
  const currentLegalStatus = findById(legalStatuses, legalStatusId);
  const showWarning = state.matches('artistForm.open.invalid');

  return (
    <InlineFormContainer
      primaryButtonText="Save Artist Details"
      secondaryButtonText="Cancel"
      primaryButtonOnClick={() => {
        send('SUBMIT_FORM');
      }}
      secondaryButtonOnClick={() => {
        send('CANCEL_FORM');
      }}
    >
      <InlineFormHeading>Edit Artist Details</InlineFormHeading>
      <RequiredFormField
        label="Your artist or group name"
        showWarning={showWarning}
        isEmpty={!name.trim()}
      >
        <TextInput
          id="group-name"
          value={name}
          onChange={(value) => {
            send({ type: 'SET_FIELD', field: 'name', value });
          }}
        />
      </RequiredFormField>
      <RequiredFormField
        label="Primary place of residence"
        description="Does not affect eligibility"
        showWarning={showWarning}
        isEmpty={!provinceId}
      >
        <Select
          id="primary-residence"
          items={provinces}
          itemToKey={(item) => item?.id}
          getItemText={(item) => item?.province ?? 'Choose an option'}
          selectedItem={findById(provinces, provinceId)}
          onChange={(province) => {
            if (province) {
              send({
                type: 'SET_FIELD',
                field: 'provinceId',
                value: province.id,
              });
            }
          }}
        />
      </RequiredFormField>
      <RequiredFormField
        showWarning={showWarning}
        isEmpty={!companyName.trim()}
        label="Artist’s company name"
      >
        <TextInput
          id="company-name"
          value={companyName}
          onChange={(value) =>
            send({
              type: 'SET_FIELD',
              field: 'companyName',
              value,
            })
          }
        />
      </RequiredFormField>
      <RequiredFormField
        showWarning={showWarning}
        isEmpty={!legalStatusId}
        label="Company’s legal status"
      >
        <>
          {legalStatuses.map((status) => (
            <RadioOption
              key={status.id}
              label={status.name}
              description={status.description ?? ''}
              checked={status.id === legalStatusId}
              onChange={() => {
                send({
                  type: 'SET_FIELD',
                  field: 'legalStatus',
                  value: status.id,
                });
                if (!status.requiresProvince) {
                  send({
                    type: 'UNSET_COMPANY_PROVINCE',
                  });
                }
              }}
            />
          ))}
        </>
      </RequiredFormField>
      {currentLegalStatus?.requiresProvince &&
        currentLegalStatus.provinceTitle && (
          <RequiredFormField
            showWarning={showWarning}
            isEmpty={!companyLegalProvince}
            label={currentLegalStatus.provinceTitle}
          >
            <Select
              id="artist-province"
              items={provinces}
              itemToKey={(item) => item?.id}
              getItemText={(item) => item?.province ?? 'Choose an option'}
              selectedItem={findById(provinces, companyLegalProvince || '')}
              onChange={(province) => {
                if (province) {
                  send({
                    type: 'SET_FIELD',
                    field: 'companyLegalProvince',
                    value: province.id,
                  });
                }
              }}
            />
          </RequiredFormField>
        )}
      {showWarning && (
        <Feedback type="warning">Please fill in all the fields above.</Feedback>
      )}
      {state.matches('artistForm.open.error') && <ErrorFeedback />}
    </InlineFormContainer>
  );
}
