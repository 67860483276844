import { ReactNode } from 'react';

import styles from './FormControls.module.scss';
import classNames from 'classnames';

interface FormControlsProps {
  children: ReactNode;
  className?: string;
}

export function FormControls({ children, className }: FormControlsProps) {
  return (
    <div className={classNames(styles.container, className)}>{children}</div>
  );
}
