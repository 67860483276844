import type {
  ActorRefFrom,
  EventFrom,
  MachineOptionsFrom,
  Sender,
} from 'xstate';
import { createMachine, assign } from 'xstate';
import { match } from 'ts-pattern';
import type { NextEvent, BackEvent } from '../navigation';
import { StateFrom } from '../../utils/StateFrom';
import { GetRedeemArtistInvitationContextPromiseResult } from '../../promises/getRedeemArtistInvitationContext';
import { createPasswordContext } from '../RedeemInvitation/PasswordMachine';
import { createTransferContext } from '../RedeemArtistInvitation/TransferMachine';
import { createAccountContext } from '../RedeemInvitation/AccountMachine';
import { isInvitationInvalidStatusError } from '../../errors/InvitationInvalidStatusError';
import { RedeemInvitationResult } from '../../promises/redeemInvitationPromise';
import { RedeemArtistInvitationStorage } from './storage';
import { RedeemArtistInvitationState } from './state';
import { RedeemArtistInvitationHistory } from './history';

import { Context } from './Context';
import { LinkInvitationResult } from '../../promises/linkInvitationPromise';
import { UpdateArtistInvitationPromiseResult } from '../../promises/updateArtistInvitationPromise';
import { logMachineError } from '../../utils/logError';

type Events =
  | NextEvent
  | BackEvent
  | { type: 'RESUME_PASSWORD' }
  | { type: 'RESUME_ACCOUNT' }
  | { type: 'RESUME_TRANSFER_LINK' }
  | { type: 'RESUME_TRANSFER_REDEEM' }
  | { type: 'RESTART' };

type Services = {
  getRedeemArtistInvitationContext: {
    data: GetRedeemArtistInvitationContextPromiseResult;
  };
  linkArtistInvitation: {
    data: LinkInvitationResult;
  };
  redeemArtistInvitation: {
    data: RedeemInvitationResult;
  };
  transferArtistInvitation: {
    data: UpdateArtistInvitationPromiseResult;
  };
};

export const machine = createMachine(
  {
    tsTypes: {} as import('./Machine.typegen').Typegen0,
    predictableActionArguments: true,
    schema: {
      context: {} as Context,
      events: {} as Events,
      services: {} as Services,
    },
    context: {
      tag: '',
      email: '',
      link: false,
      minimumPasswordLength: 0,
      isOrion: false,
    },
    initial: 'init',
    invoke: {
      id: 'restart',
      src: 'restart',
    },
    states: {
      init: {
        initial: 'pending',
        states: {
          pending: {
            invoke: {
              id: 'getRedeemArtistInvitationContext',
              src: 'getRedeemArtistInvitationContext',
              onDone: {
                actions: 'setContext',
                target: 'resolved',
              },
              onError: [
                { cond: 'isInvalidRequest', target: 'invalid-tag' },
                { target: 'rejected' },
              ],
            },
          },
          resolved: { type: 'final' },
          rejected: { entry: 'logMachineError' },
          'invalid-tag': {},
        },
        onDone: 'resume',
      },
      resume: {
        invoke: {
          id: 'resume',
          src: 'resume',
        },
        on: {
          RESUME_TRANSFER_LINK: 'transfer.link',
          RESUME_TRANSFER_REDEEM: 'transfer.redeem',
          RESUME_PASSWORD: 'password',
          RESUME_ACCOUNT: 'account',
        },
      },
      transfer: {
        entry: 'saveTransferStep',
        invoke: {
          id: 'transferMachine',
          src: 'transferMachine',
          data: createTransferContext,
          onDone: 'transferring',
        },
        initial: 'redeem',
        states: {
          link: { on: { NEXT: '#linking' } },
          redeem: { on: { NEXT: '#password' } },
        },
      },
      password: {
        id: 'password',
        entry: 'savePasswordStep',
        invoke: {
          id: 'passwordMachine',
          src: 'passwordMachine',
          data: createPasswordContext,
        },
        on: {
          NEXT: 'account',
        },
      },
      account: {
        entry: 'saveAccountStep',
        invoke: {
          id: 'accountMachine',
          src: 'accountMachine',
          data: createAccountContext,
        },
        on: {
          NEXT: 'redeeming',
          BACK: 'password',
        },
      },
      redeeming: {
        initial: 'pending',
        states: {
          pending: {
            invoke: {
              id: 'redeemArtistInvitation',
              src: 'redeemArtistInvitation',
              onDone: {
                actions: 'signIn',
              },
              onError: 'rejected',
            },
          },
          rejected: { entry: 'logMachineError' },
        },
      },
      linking: {
        id: 'linking',
        initial: 'pending',
        states: {
          pending: {
            invoke: {
              id: 'linkArtistInvitation',
              src: 'linkArtistInvitation',
              onDone: {
                actions: 'signIn',
              },
              onError: 'rejected',
            },
          },
          rejected: { entry: 'logMachineError' },
        },
      },
      transferring: {
        initial: 'pending',
        states: {
          pending: {
            invoke: {
              id: 'transferArtistInvitation',
              src: 'transferArtistInvitation',
              onDone: 'resolved',
              onError: 'rejected',
            },
          },
          resolved: {},
          rejected: { entry: 'logMachineError' },
        },
      },
    },
    on: { RESTART: 'init' },
  },
  {
    actions: { logMachineError, setContext: assign((_, { data }) => data) },
    guards: {
      isInvalidRequest: (_, { data }) => isInvitationInvalidStatusError(data),
    },
  }
);

type Machine = typeof machine;

export type RedeemArtistInvitationMachineState = StateFrom<Machine>;
export type RedeemArtistInvitationMachineSender = Sender<EventFrom<Machine>>;
export type RedeemArtistInvitationMachineOptions = MachineOptionsFrom<
  Machine,
  true
>;
export type RedeemArtistInvitationMachineActor = ActorRefFrom<Machine>;

export const createResumeService =
  (storage: RedeemArtistInvitationStorage) =>
  ({ link }: Context) =>
  (send: RedeemArtistInvitationMachineSender) => {
    const { step } = storage.get();
    const transfer = link ? 'RESUME_TRANSFER_LINK' : 'RESUME_TRANSFER_REDEEM';

    match(step)
      .with(undefined, () => send(transfer))
      .with('transfer', () => send(transfer))
      .with('password', () => send('RESUME_PASSWORD'))
      .with('account', () => send('RESUME_ACCOUNT'))
      .exhaustive();
  };

export const createRestartService =
  (
    storage: RedeemArtistInvitationStorage,
    history: RedeemArtistInvitationHistory
  ) =>
  () =>
  (send: RedeemArtistInvitationMachineSender) => {
    history.subscribe((state) => {
      storage.set(state);
      send('RESTART');
    });

    return history.unsubscribe;
  };

const createStepSaver =
  (
    storage: RedeemArtistInvitationStorage,
    history: RedeemArtistInvitationHistory,
    step: RedeemArtistInvitationState['step']
  ) =>
  // Every time the user navigates forwards or backwards in the flow we
  // push an entry onto the history stack. This gives us a snapshot to
  // restore to if the user every decides to use the back and forward
  // buttons on their browser.
  (_: Context, { type }: Events) => {
    // We only want to push history when the user has been the one
    // triggering movement. There are other events used by the resume
    // serivce to restore the state of the flow but those are not
    // triggered by humans in front of the screen and therefore we do
    // not want to push history onto the stack for those events.
    if (type === 'NEXT' || type === 'BACK') {
      // The reason we first replace the current state is because the user
      // will have changed the state on the page since they first entered this
      // state. This allows us to save all their work up till they navigated
      // to the next step.
      history.replace(storage.get());
      storage.add({ step });
      history.push(storage.get());
    }
  };

export const createSaveActions = (
  storage: RedeemArtistInvitationStorage,
  history: RedeemArtistInvitationHistory
) => ({
  saveTransferStep: createStepSaver(storage, history, 'transfer'),
  savePasswordStep: createStepSaver(storage, history, 'password'),
  saveAccountStep: createStepSaver(storage, history, 'account'),
});
