import { Helmet } from 'react-helmet';
import errorImage from '../assets/images/not-found.svg';

import styles from './ErrorPage.module.scss';

interface ErrorPageProps {
  title?: string;
  text?: string;
}

export function ErrorPage({
  title = 'Oops, something went wrong.',
  text = 'Please refresh the page, or if the problem continues, reach out to the Starmaker team for assistance.',
}: ErrorPageProps) {
  return (
    <div className={styles.container}>
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
      <img
        src={errorImage}
        alt="Cloud with a warning icon"
        className={styles.image}
      />
      <h1 className={styles.header}>{title}</h1>
      <p className={styles.text}>{text}</p>
    </div>
  );
}
