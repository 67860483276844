import { match } from 'ts-pattern';
import { Button } from '../../Button';
import { formatCalendarDate } from '../../../utils/formatCalendarDate';
import { formatNumber } from '../../../utils/formatNumber';
import { Feedback, FeedbackType } from '../../Feedback';
import { ApplicationStatus, TourDateStatus } from '../../../graphql/operations';
import { IconName } from '../../Icon';
import { InlineFormContainer } from '../../InlineFormContainer';
import {
  TourDateCardMachineSender,
  TourDateCardMachineState,
} from '../../../machines/components/TourDateCardMachine';
import { Select } from '../../Select';
import { DescriptionList } from '../../DescriptionList';
import { formatMoney } from '../../../utils/formatMoney';

import styles from './TourDateCard.module.scss';

interface TourDateCardProps {
  state: TourDateCardMachineState;
  send: TourDateCardMachineSender;
}

const tourDateStatuses = [
  { label: 'Approved', status: TourDateStatus.Approved },
  { label: 'Denied', status: TourDateStatus.Denied },
  { label: 'Pending', status: TourDateStatus.Pending },
  { label: 'Unsettled', status: TourDateStatus.Unsettled },
];

export function TourDateCardView({ state, send }: TourDateCardProps) {
  const { tourDate, applicationStatus, isAdmin, isBoard } = state.context;
  const hasAdminHandledApplicaton =
    applicationStatus === ApplicationStatus.Approved ||
    applicationStatus === ApplicationStatus.Denied;

  const feedbackProps = match<
    { status: TourDateStatus; cancelled: boolean },
    { icon: IconName; type: FeedbackType; status: string }
  >({ status: tourDate.status, cancelled: tourDate.cancelled })
    .with({ cancelled: true }, () => ({
      icon: 'alertTriangle',
      type: 'warning',
      status: 'Cancelled',
    }))
    .with({ status: TourDateStatus.Pending }, () => ({
      icon: 'clock',
      type: 'neutral',
      status: 'Pending',
    }))
    .with({ status: TourDateStatus.Unsettled }, () => ({
      icon: 'clock',
      type: 'neutral',
      status: 'Unsettled',
    }))
    .with({ status: TourDateStatus.Approved }, () => ({
      icon: 'checkCircle',
      type: 'positive',
      status: 'Approved',
    }))
    .with({ status: TourDateStatus.Denied }, () => ({
      icon: 'xCircle',
      type: 'warning',
      status: 'Denied',
    }))
    .run();

  return (
    <div className={styles.container} id={tourDate.id}>
      <div className={styles.content}>
        <div className={styles.tourInfo}>
          <div className={styles.header}>
            <h3 className={styles.date}>{formatCalendarDate(tourDate.date)}</h3>
            <div className={styles.type}>{tourDate.tourDateType.title}</div>
            {tourDate.confirmed && (
              <div className={styles.confirmed}>CONFIRMED</div>
            )}
          </div>
          <div>
            {tourDate.countrySubdivision}, {tourDate.tourDateCountry.name}
          </div>
          <div className={styles.location}>{tourDate.venueName}</div>
          <DescriptionList
            className={styles.list}
            fontSize="body"
            list={{
              Capacity: formatNumber(Number(tourDate.venueCapacity)),
              Guarantee: formatMoney(Number(tourDate.paymentGuarantee)),
              'Avg Ticket': formatMoney(Number(tourDate.averageTicketPrice)),
            }}
          />
        </div>
        <div className={styles.buttonsContainer}>
          {match(tourDate.isWritable)
            .with(true, () => (
              <>
                <Button
                  variant="neutral"
                  size="small"
                  label="Remove"
                  onClick={() => {
                    if (
                      window.confirm(
                        'Are you sure you want to remove this tour date?'
                      )
                    ) {
                      send({ type: 'DELETE_TOUR_DATE', id: tourDate.id });
                    }
                  }}
                />
                <Button
                  variant="secondary"
                  size="small"
                  label="Edit"
                  startIcon="edit"
                  onClick={() =>
                    send({ type: 'OPEN_TOUR_DATE', id: tourDate.id })
                  }
                />
              </>
            ))
            .with(false, () => (
              <>
                <Button
                  variant="secondary"
                  size="small"
                  label="View"
                  onClick={() =>
                    send({ type: 'OPEN_TOUR_DATE', id: tourDate.id })
                  }
                />
                {tourDate.isOwner && tourDate.isCancellable && (
                  <Button
                    variant="neutral"
                    size="small"
                    label="Cancel"
                    onClick={() => {
                      if (
                        window.confirm(
                          'Do you really want to cancel this tour date?'
                        )
                      ) {
                        send({ type: 'CANCEL_TOUR_DATE', id: tourDate.id });
                      }
                    }}
                  />
                )}
              </>
            ))
            .exhaustive()}
        </div>
      </div>

      {tourDate.isWritable && tourDate.isTooOld && (
        <Feedback size="compact" type="warning">
          <div>
            <span className={styles.label}>
              This tour date is not eligible.
            </span>{' '}
            <span>
              Date must be on or after{' '}
              {formatCalendarDate(state.context.presentCutoffDate)}.
            </span>
          </div>
        </Feedback>
      )}
      {tourDate.isWritable && tourDate.isTooNew && (
        <Feedback size="compact" type="warning">
          <div>
            <span className={styles.label}>
              This tour date is not eligible.
            </span>{' '}
            <span>
              {state.context.futureCutoffDate === undefined
                ? // The first string should never be displayed since you can't be
                  // too far ahead of a date that is undefined but if we somehow get
                  // into that state at least some error will be displayed to the user.
                  'Date is too far in the future.'
                : `Date must be on or before ${formatCalendarDate(
                    state.context.futureCutoffDate
                  )}.`}
            </span>
          </div>
        </Feedback>
      )}
      {tourDate.isWritable && !tourDate.meetsTicketedVenueCapacity && (
        <Feedback size="compact" type="warning">
          <div>
            <span className={styles.label}>
              This tour date is not eligible.
            </span>{' '}
            <span>Date does not meet venue capacity requirements.</span>
          </div>
        </Feedback>
      )}
      {tourDate.isWritable && tourDate.isTooMany && (
        <Feedback size="compact" type="warning">
          <div>
            <span className={styles.label}>
              This tour date is not eligible.
            </span>{' '}
            <span>
              {match(tourDate.sameDateLimit)
                .with(1, (limit) => (
                  <>
                    Only {formatNumber(limit)} tour date is allowed on the same
                    day across all applications.
                  </>
                ))
                .otherwise((limit) => (
                  <>
                    Only {formatNumber(limit)} tour dates are allowed on the
                    same day across all applications.
                  </>
                ))}
            </span>
          </div>
        </Feedback>
      )}
      {((!state.matches('status') && (isAdmin || isBoard)) ||
        hasAdminHandledApplicaton) && (
        <div>
          <div>
            <Feedback
              size="compact"
              icon={feedbackProps.icon}
              type={feedbackProps.type}
              title={feedbackProps.status}
              {...(isAdmin && {
                feedbackButtons: (
                  <Button
                    size="small"
                    variant="secondary"
                    startIcon="settings"
                    label="Change Approval"
                    onClick={() => send({ type: 'TOGGLE_FORM_VISIBILITY' })}
                  />
                ),
              })}
            />
          </div>
        </div>
      )}
      {state.matches('status') && isAdmin && (
        <InlineFormContainer
          primaryButtonText="Submit Review"
          primaryButtonOnClick={() => send({ type: 'UPDATE_TOUR_DATE' })}
          secondaryButtonText="Cancel"
          secondaryButtonOnClick={() =>
            send({ type: 'TOGGLE_FORM_VISIBILITY' })
          }
        >
          <p className="body-text-bold">Status</p>
          <Select
            id="status"
            items={tourDateStatuses}
            itemToKey={(item) => item?.status}
            getItemText={(item) => item?.label}
            selectedItem={
              tourDateStatuses.find(
                ({ status }) => status === state.context.status
              ) ?? null
            }
            onChange={({ status }) => send({ type: 'SET_STATUS', status })}
          />
        </InlineFormContainer>
      )}
    </div>
  );
}
