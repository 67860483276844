import { useEffect, useState } from 'react';
import { match } from 'ts-pattern';
import { ZonedDateTime } from '@internationalized/date';

import { useWindowSize } from '../hooks/useWindowSize';
import { formatMoney } from '../utils/formatMoney';
import { formatZonedDate, formatZonedTime } from '../utils/formatZonedDateTime';
import { Button } from './Button';
import { Icon } from './Icon';
import { Link } from './Link';

import cn from 'classnames';

import {
  ApplicationStage,
  ApplicationStatus,
  ApplicationType,
} from '../graphql/operations';

import styles from './ApplicationSidebar.module.scss';

interface ApplicationSidebarProps {
  title?: string;
  type?: 'application' | 'public';
  links: string[];
  fundingRequested?: number;
  fundingApproved?: number;
  fundingDeadline?: ZonedDateTime | null;
  status?: ApplicationStatus;
  stage?: ApplicationStage;
  submittedAt?: ZonedDateTime | null;
  claimsSubmittedAt?: ZonedDateTime | null;
  claimsReopenedAt?: ZonedDateTime | null;
  claimsResubmissionDeadline?: ZonedDateTime | null;
  claimsResubmittedAt?: ZonedDateTime | null;
  children?: React.ReactNode; //this is only used to inject an input for the showcase
  applicationType?: ApplicationType;
}

export function ApplicationSidebar({
  title,
  type = 'application',
  links,
  fundingRequested,
  fundingApproved,
  status,
  stage,
  submittedAt,
  claimsSubmittedAt,
  claimsResubmissionDeadline,
  claimsResubmittedAt,
  children,
  applicationType,
}: ApplicationSidebarProps) {
  const [activeLinks, setActiveLinks] = useState<string[]>([]);
  const window = useWindowSize();

  useEffect(() => {
    const callback = (e: IntersectionObserverEntry[]) => {
      e.forEach((entry) => {
        setActiveLinks((currentLinks) => {
          const otherLinks = currentLinks.filter(
            (link) => link !== entry.target.id
          );

          return entry.isIntersecting
            ? [...otherLinks, entry.target.id]
            : otherLinks;
        });
      });
    };
    const observer = new IntersectionObserver(callback, {
      rootMargin: '-50%',
    });
    for (const link of links) {
      const el = document.getElementById(link);
      if (el) {
        observer.observe(el);
      }
    }

    return () => observer.disconnect();
  }, []);
  return (
    <aside className={styles.sidebar}>
      <h2>{title}</h2>
      <div
        className={cn(styles.links, {
          [styles.publicLinks]: type === 'public',
        })}
      >
        {links.map((link) => (
          <div key={link}>
            <Link
              href={`#${link}`}
              className={cn(styles.link, {
                [styles.active]:
                  window.screen !== 'mobile' && activeLinks.at(-1) === link,
                [styles.publicLink]: type === 'public',
              })}
            >
              {link}
            </Link>
          </div>
        ))}
      </div>
      {type !== 'public' && (
        <div className={styles.summary}>
          {status === ApplicationStatus.Approved && (
            <div className={styles.iconContainer}>
              <Icon name="checkCircle" />
              <p>
                {stage === ApplicationStage.Completed
                  ? 'Completed'
                  : 'Approved'}
              </p>
            </div>
          )}
          <div className={styles.container}>
            {match(stage)
              .with(ApplicationStage.ClaimsReopened, () => (
                <div className={styles.valueContainer}>
                  <p>Resubmit Claim Deadline:</p>

                  <div className={styles.dateContainer}>
                    <p className={styles.date}>
                      {formatZonedDate(
                        claimsResubmissionDeadline ?? null,
                        'long'
                      )}
                    </p>
                    <p className={styles.time}>
                      {formatZonedTime(claimsResubmissionDeadline ?? null)}
                    </p>
                  </div>
                </div>
              ))
              .with(ApplicationStage.ClaimsResubmitted, () => (
                <div className={styles.valueContainer}>
                  <p>Funding Claim Resubmitted:</p>

                  <div className={styles.dateContainer}>
                    <p className={styles.date}>
                      {formatZonedDate(claimsResubmittedAt ?? null, 'long')}
                    </p>
                    <p className={styles.time}>
                      {formatZonedTime(claimsResubmittedAt ?? null)}
                    </p>
                  </div>
                </div>
              ))
              .with(
                ApplicationStage.ClaimsSubmitted,
                ApplicationStage.Completed,
                () => (
                  <div className={styles.valueContainer}>
                    <p>Funding Claim Submitted:</p>

                    <div className={styles.dateContainer}>
                      <p className={styles.date}>
                        {formatZonedDate(claimsSubmittedAt ?? null, 'long')}
                      </p>
                      <p className={styles.time}>
                        {formatZonedTime(claimsSubmittedAt ?? null)}
                      </p>
                    </div>
                  </div>
                )
              )
              .with(ApplicationStage.Submitted, () => (
                <div className={styles.valueContainer}>
                  <p>Submitted:</p>

                  <div className={styles.dateContainer}>
                    <p className={styles.date}>
                      {formatZonedDate(submittedAt ?? null, 'long')}
                    </p>
                    <p className={styles.time}>
                      {formatZonedTime(submittedAt ?? null)}
                    </p>
                  </div>
                </div>
              ))
              .otherwise(() => null)}

            {(fundingRequested || fundingRequested === 0) &&
              !claimsSubmittedAt && (
                <div className={styles.fundingContainer}>
                  Funding requested:
                  <span
                    className={cn(styles.row, {
                      [styles.noFunding]: fundingRequested === 0,
                      [styles.funding]: fundingRequested !== 0,
                    })}
                  >
                    {formatMoney(fundingRequested)}{' '}
                    {fundingRequested === 0 &&
                    applicationType === ApplicationType.Core
                      ? '(add tour dates)'
                      : ''}
                  </span>
                </div>
              )}
            {(fundingApproved || fundingApproved === 0) &&
              !claimsSubmittedAt && (
                <div className={styles.fundingContainer}>
                  <p>Funding approved:</p>
                  <p className={styles.funding}>
                    {formatMoney(fundingApproved)}
                  </p>
                </div>
              )}
          </div>
          {match(stage)
            .with(ApplicationStage.ClaimsReopened, () => (
              <Button
                className={styles.button}
                linkTo="#summary"
                label="Resubmit Claim"
                variant="secondary"
              />
            ))
            .with(ApplicationStage.Unsubmitted, () => (
              <Button
                className={styles.button}
                linkTo="#summary"
                label="Review & Submit"
                variant="secondary"
              />
            ))
            .with(ApplicationStage.ContractAndDirectDepositUploaded, () => (
              <Button
                className={styles.button}
                linkTo="#summary"
                label="Submit Claim"
                variant="secondary"
              />
            ))
            .otherwise(() => (
              <Button
                className={styles.button}
                linkTo="#summary"
                label="View Summary"
                variant="secondary"
              />
            ))}
        </div>
      )}
      {children}
    </aside>
  );
}
