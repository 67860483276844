import { useGraphQLClient } from '../../components/GraphQLClientProvider';
import { AccountController } from './AccountController';
import { useNavigate, useParams } from 'react-router-dom';
import { createContext } from '../../machines/AccountDetailsMachine';
import { createGetAccountDetailsPromise } from '../../promises/createGetAccountDetailsPromise';
import { createUpdateAccountDetailsPromise } from '../../promises/createUpdateAccountDetailsPromise';
import { createChangePasswordPromise } from '../../promises/createChangePasswordPromise';
import { useAuth } from '../../components/AuthProvider';
import { createSignOutPromise } from '../../promises/createSignOutPromise';
import {
  machine as ArtistSearchMachine,
  createContext as createSearchContext,
} from '../../machines/Admin/Artists/SearchArtistsMachine';
import { createSearchArtistsPromise } from '../../promises/Admin/createSearchArtistsPromise';
import { sendParent } from 'xstate/lib/actions';
import { createCreateAccountArtistBindingPromise } from '../../promises/Admin/createCreateAccountArtistBindingPromise';
import { createDeleteAccountArtistBindingAdminPromise } from '../../promises/Admin/createDeleteAccountArtistBindingAdminPromise';
import { useIsAdmin } from '../../hooks/useIsAdmin';
import { withDelay } from '../../utils/withDelay';

export const Account = () => {
  const { accountId } = useParams();
  const { id, signOut } = useAuth();
  const client = useGraphQLClient();
  const navigate = useNavigate();
  const isAdmin = useIsAdmin();

  if (!accountId) {
    navigate('/dashboard');
    return null;
  }

  return (
    <AccountController
      key={accountId}
      options={{
        context: createContext(accountId, isAdmin, accountId === id),
        actions: {
          signOut: () => {
            createSignOutPromise()
              .then(signOut)
              .then(() => navigate('/sign-in'))
              .catch((e) => {
                console.error(e);
              });
          },
        },
        services: {
          artistSearchMachine: ArtistSearchMachine.withContext(
            createSearchContext([], false, 0, false, 3)
          ).withConfig({
            services: {
              searchArtistsPromise: createSearchArtistsPromise(client),
            },
            actions: {
              sendArtistsToParent: sendParent(({ artists }) => ({
                type: 'RECEIVE_SEARCHED_ARTISTS',
                artists,
              })),
            },
            delays: {},
            guards: {},
          }),
          getAccountDetailsPromise: withDelay(
            createGetAccountDetailsPromise(client)
          ),
          updateAccountDetailsPromise:
            createUpdateAccountDetailsPromise(client),
          changePasswordPromise: createChangePasswordPromise(client),
          createAccountArtistBinding:
            createCreateAccountArtistBindingPromise(client),
          deleteAccountArtistBinding:
            createDeleteAccountArtistBindingAdminPromise(client),
        },
      }}
    />
  );
};
