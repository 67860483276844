import classNames from 'classnames';
import { match } from 'ts-pattern';
import {
  ReleaseDetailsMachineSender,
  ReleaseDetailsMachineState,
} from '../../../machines/Release/ReleaseDetailsMachine';
import { FormField, FormFieldProps } from '../../FormField';
import { TextInput } from '../../TextInput';
import { InlineFormContainer } from '../../InlineFormContainer';
import { InlineFormHeading } from '../../InlineFormHeading';
import { FieldSet } from '../../FieldSet';
import { RadioOption } from '../../RadioOption';
import { Feedback } from '../../Feedback';
import { DatePicker } from '../../DatePicker/DatePicker';
import { Select } from '../../Select';
import { FormFieldDuoInGrid } from '../../FormFieldDuoInGrid';
import { ErrorFeedback } from '../../ErrorFeedback';
import { formatDate } from '../../../utils/formatDate';
import { parseCalendarDate } from '../../../utils/parseCalendarDate';

import styles from './ReleaseDetailsView.module.scss';

interface ReleaseDetailsViewProps {
  state: ReleaseDetailsMachineState;
  send: ReleaseDetailsMachineSender;
}

export function ReleaseDetailsView({ state, send }: ReleaseDetailsViewProps) {
  const lengthInMinutesFormFieldProps = {
    label: 'Length in minutes',
    tooltipContent:
      'A full-length release must be at least 25 minutes in length. A bundle release must be at least 9 minutes in length.',
    formFieldStatus: state.matches('numberOfMinutes.error.empty')
      ? 'error'
      : ('default' as FormFieldProps['formFieldStatus']),
    infoLabel: match(state)
      .when(
        () => state.matches('numberOfMinutes.error.empty'),
        () => 'This field is required.'
      )
      .when(
        () => state.matches('numberOfMinutes.error.incorrectType'),
        () => 'This field must be numeric.'
      )
      .when(
        () => state.matches('numberOfMinutes.error.value'),
        () => 'Releases must be at least 9 minutes in length'
      )
      .otherwise(() => ''),
    feedbackType: state.matches('numberOfMinutes.error.value')
      ? 'neutral'
      : ('warning' as FormFieldProps['feedbackType']),
    children: (
      <TextInput
        id="length-in-minutes"
        label="Length in minutes"
        value={String(state.context.numberOfMinutes ?? '')}
        onChange={(numberOfMinutes) =>
          send({
            type: 'UPDATE_NUMBER_OF_MINUTES',
            numberOfMinutes,
          })
        }
        onBlur={() => send({ type: 'BLUR_NUMBER_OF_MINUTES' })}
      />
    ),
  };

  const numberOfTracksFormFieldProps = {
    label: 'Number of tracks',
    tooltipContent:
      'A full-length release must have at least 6 tracks. A bundle release must have at least 6 tracks.',
    formFieldStatus: state.matches('numberOfTracks.error.empty')
      ? 'error'
      : ('default' as FormFieldProps['formFieldStatus']),
    infoLabel: match(state)
      .when(
        () => state.matches('numberOfTracks.error.empty'),
        () => 'This field is required.'
      )
      .when(
        () => state.matches('numberOfTracks.error.incorrectType'),
        () => 'This field must be numeric.'
      )
      .when(
        () => state.matches('numberOfTracks.error.value'),
        () => 'Releases must have 3 or more tracks'
      )
      .otherwise(() => ''),
    feedbackType: state.matches('numberOfTracks.error.value')
      ? 'neutral'
      : ('warning' as FormFieldProps['feedbackType']),
    children: (
      <TextInput
        id="number-of-tracks"
        label="Number of tracks"
        value={String(state.context.numberOfTracks ?? '')}
        onChange={(numberOfTracks) =>
          send({
            type: 'UPDATE_NUMBER_OF_TRACKS',
            numberOfTracks,
          })
        }
        onBlur={() => send({ type: 'BLUR_NUMBER_OF_TRACKS' })}
      />
    ),
  };
  return (
    <>
      {match(state)
        .when(
          () => state.matches('ui.edit') || state.matches('ui.saving'),
          () => (
            <InlineFormContainer
              primaryButtonText={
                state.matches('ui.edit') ? 'Save Release Details' : 'Saving...'
              }
              primaryButtonDisabled={state.matches('ui.saving')}
              primaryButtonOnClick={() => {
                send({
                  type: 'ATTEMPT_SAVE',
                });
              }}
              secondaryButtonText="Cancel"
              secondaryButtonOnClick={() => send({ type: 'CANCEL_EDITING' })}
            >
              <InlineFormHeading>Edit Release Details</InlineFormHeading>
              <FormField
                id="title"
                label="Release title"
                feedbackType="warning"
                formFieldStatus={
                  state.matches('title.error') ? 'error' : 'default'
                }
                infoLabel={match(state)
                  .when(
                    ({ matches }) => matches('title.error.empty'),
                    () => 'This field is required.'
                  )
                  .otherwise(() => '')}
              >
                <TextInput
                  id="title"
                  label="title"
                  value={state.context.title ?? ''}
                  onChange={(title) => send({ type: 'UPDATE_TITLE', title })}
                  onBlur={() => send({ type: 'BLUR_TITLE' })}
                />
              </FormField>
              <FormField
                id="label"
                label="Label"
                description="If you released without a label, choose Self-Release. If your label is not in the list, please contact us."
                feedbackType="warning"
              >
                <Select
                  id="label"
                  label="Label"
                  items={state.context.labels}
                  itemToKey={(item) => item?.id}
                  selectedItem={state.context.label ?? null}
                  initialSelectedItem={state.context.label}
                  onChange={(label) => send({ type: 'UPDATE_LABEL', label })}
                  getItemText={({ name }) => name}
                />
              </FormField>
              {!state.matches('otherLabel.hidden') && (
                <FormField
                  label="Other Label"
                  infoLabel={match(state)
                    .when(
                      () => state.matches('otherLabel.error.empty'),
                      () => 'This field is required.'
                    )
                    .otherwise(() => '')}
                  formFieldStatus={
                    state.matches('otherLabel.error.empty')
                      ? 'error'
                      : 'default'
                  }
                  feedbackType="warning"
                >
                  <TextInput
                    id="other-label"
                    value={state.context.otherLabel ?? ''}
                    onChange={(otherLabel) =>
                      send({
                        type: 'UPDATE_OTHER_LABEL',
                        otherLabel,
                      })
                    }
                    onBlur={() => send({ type: 'BLUR_OTHER_LABEL' })}
                  />
                </FormField>
              )}
              <FormField
                id="distributor"
                label="Distributor"
                feedbackType="warning"
              >
                <Select
                  id="distributor"
                  label="Distributor"
                  items={state.context.distributors}
                  itemToKey={(item) => item?.id}
                  selectedItem={state.context.distributor ?? null}
                  initialSelectedItem={state.context.distributor}
                  onChange={(distributor) =>
                    send({ type: 'UPDATE_DISTRIBUTOR', distributor })
                  }
                  getItemText={({ name }) => name}
                />
              </FormField>
              {!state.matches('otherDistributor.hidden') && (
                <FormField
                  label="Other Distributor"
                  infoLabel={match(state)
                    .when(
                      () => state.matches('otherDistributor.error.empty'),
                      () => 'This field is required.'
                    )
                    .otherwise(() => '')}
                  formFieldStatus={
                    state.matches('otherDistributor.error.empty')
                      ? 'error'
                      : 'default'
                  }
                  feedbackType="warning"
                >
                  <TextInput
                    id="other-distributor"
                    value={state.context.otherDistributor ?? ''}
                    onChange={(otherDistributor) =>
                      send({
                        type: 'UPDATE_OTHER_DISTRIBUTOR',
                        otherDistributor,
                      })
                    }
                    onBlur={() => send({ type: 'BLUR_OTHER_DISTRIBUTOR' })}
                  />
                </FormField>
              )}
              <FormField
                label="Release date"
                tooltipContent="Release date must be from the previous 5 years before the round deadline."
                formFieldStatus={
                  state.matches('date.error.empty') ? 'error' : 'default'
                }
                infoLabel={match(state)
                  .when(
                    () => state.matches('date.error.empty'),
                    () => 'This field is required.'
                  )
                  .when(
                    (state) => state.matches('date.error.value'),
                    ({ context }) =>
                      `The release date must be on or after ${formatDate(
                        context.eligibilityCutoffDate
                      )}.`
                  )
                  .otherwise(() => '')}
                feedbackType={
                  state.matches('date.error.value') ? 'neutral' : 'warning'
                }
              >
                <DatePicker
                  aria-label="Release date"
                  value={parseCalendarDate(state.context.date ?? '')}
                  onChange={(date) =>
                    send({ type: 'UPDATE_DATE', date: date.toString() })
                  }
                />
              </FormField>

              <div className={styles.lengthAndTracksMobile}>
                <FormField {...lengthInMinutesFormFieldProps} />
                <FormField {...numberOfTracksFormFieldProps} />
              </div>

              <div className={styles.lengthAndTracksDesktop}>
                <FormFieldDuoInGrid
                  left={lengthInMinutesFormFieldProps}
                  right={numberOfTracksFormFieldProps}
                />
              </div>

              <FormField
                label="Are the majority of the songs on the release in French?"
                tooltipContent="For releases with the majority of songs in French you must contact le Fonds Radiostar."
                feedbackType={
                  state.matches('language.error.value') ? 'neutral' : 'warning'
                }
                formFieldStatus={
                  state.matches('language.error.empty') ? 'error' : 'default'
                }
                infoLabel={match(state)
                  .when(
                    () => state.matches('language.error.empty'),
                    () => 'This field is required.'
                  )
                  .when(
                    () => state.matches('language.error.value'),
                    () =>
                      'The majority of the tracks included on your album must not be in the French language. For releases with the majority of songs in French you must contact le Fonds Radiostar.'
                  )
                  .otherwise(() => '')}
              >
                <FieldSet direction="vertical">
                  <RadioOption
                    label="No, the majority of songs are NOT in French"
                    checked={Boolean(state.context.isMajorityEnglish)}
                    onChange={() =>
                      send({ type: 'UPDATE_LANGUAGE', isMajorityEnglish: true })
                    }
                  />
                  <RadioOption
                    label="Yes, the majority of songs are in French"
                    checked={!state.context.isMajorityEnglish}
                    onChange={() =>
                      send({
                        type: 'UPDATE_LANGUAGE',
                        isMajorityEnglish: false,
                      })
                    }
                  />
                </FieldSet>
              </FormField>
              {state.matches('wholeForm.error.invalid') && (
                <Feedback
                  title="Please fill in all the fields above."
                  type="warning"
                  className={classNames({
                    'feedback-highlight': state.matches(
                      'wholeForm.error.invalid.highlight'
                    ),
                  })}
                />
              )}
              {(state.matches('numberOfTracks.error.value') ||
                state.matches('language.error.value') ||
                state.matches('date.error.value') ||
                state.matches('numberOfMinutes.error.value')) && (
                <Feedback
                  title="Changes you make to release details will affect release eligibility."
                  type="neutral"
                />
              )}
              {state.matches('wholeForm.error.network') && (
                <ErrorFeedback type="network" />
              )}
              {state.matches('wholeForm.error.internal') && <ErrorFeedback />}
            </InlineFormContainer>
          )
        )
        .run()}
    </>
  );
}
