import cn from 'classnames';
import { FundingRoundStatusType } from '../../../graphql/operations';

import styles from './RoundTitle.module.scss';

interface RoundTitleProps {
  title: string;
  status: FundingRoundStatusType;
}

export function RoundTitle({ status, title }: RoundTitleProps) {
  return (
    <div
      className={cn(
        styles.container,
        status === FundingRoundStatusType.OpenForApplications
          ? styles.openForApplications
          : styles.closedForApplications
      )}
    >
      <p className={styles.roundHeading}>ROUND</p>
      <p className={styles.roundNumber}>{title}</p>
    </div>
  );
}
