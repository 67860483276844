import { GraphQLClient } from 'graphql-request';
import { ReleasePatch, UpdateReleaseDocument } from '../graphql/operations';

export const createUpdateReleasePromise =
  <TContext>(
    client: GraphQLClient,
    releaseId: string,
    contextToReleasePatch: (context: TContext) => ReleasePatch
  ) =>
  (context: TContext) =>
    client
      .request(UpdateReleaseDocument, {
        input: {
          id: releaseId,
          releasePatch: contextToReleasePatch(context),
        },
      })
      // Massage the response so it has the same shape as the GetRelease query.
      // That way we can use the same guards for both.
      .then((response) => ({ release: response.result?.release }));

export type UpdateRelease = ReturnType<typeof createUpdateReleasePromise>;
export type UpdateReleaseResult = Awaited<ReturnType<UpdateRelease>>;
