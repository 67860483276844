import { GraphQLClient } from 'graphql-request';
import {
  SubmitApplicationDocument,
  SubmitApplicationMutationVariables,
} from '../graphql/operations';

export const createSubmitApplicationPromise =
  (client: GraphQLClient) =>
  ({ applicationId }: SubmitApplicationMutationVariables) =>
    client.request(SubmitApplicationDocument, { applicationId });

export type SubmitApplicationPromise = ReturnType<
  typeof createSubmitApplicationPromise
>;
export type SubmitApplicationPromiseResult = Awaited<
  ReturnType<SubmitApplicationPromise>
>;
