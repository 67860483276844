import { ArtistEligibility } from '../../../graphql/operations';
import { Button } from '../../Button';
import { Icon } from '../../Icon';
import cn from 'classnames';

import styles from './ArtistCard.module.scss';

interface ArtistCardProps {
  name: string;
  type?: 'primary' | 'secondary';
  releases: { title: string }[];
  eligibility: ArtistEligibility;
  buttonText?: string;
  buttonDisabled?: boolean;
  buttonLinkTo?: string;
  buttonOnClick?: () => void;
  pendingOrion: boolean;
}

export function ArtistCard({
  name,
  type = 'primary',
  releases,
  eligibility,
  buttonText = 'View',
  buttonDisabled = false,
  buttonLinkTo,
  buttonOnClick,
  pendingOrion,
}: ArtistCardProps) {
  const statusLabel = (
    pendingOrion ? 'Pending Orion Request' : eligibility
  ).toLowerCase();

  return (
    <div className={styles[type]}>
      <div className={styles.container}>
        <div className={styles.col}>
          <div className={styles.header}>
            <h3 className={styles.name}>{name}</h3>
            <div
              className={cn({
                [styles.eligible]: eligibility === ArtistEligibility.Eligible,
                [styles.ineligible]:
                  eligibility === ArtistEligibility.Ineligible,
                [styles.slowing]: eligibility === ArtistEligibility.Slowing,
                [styles.orion]: pendingOrion,
              })}
            >
              {statusLabel}
            </div>
          </div>
          {/*todo: replace this key with release ID*/}
          <div className={styles.releases}>
            {releases.map((release) => (
              <div key={release.title} className={styles.release}>
                <Icon name="cd" className={styles.icon} />
                <p className={styles.releaseTitle}>{release.title}</p>
              </div>
            ))}
          </div>
        </div>

        <div className={styles.row}>
          <Button
            size="small"
            variant="secondary"
            label={buttonText}
            linkTo={buttonLinkTo}
            onClick={buttonOnClick}
            disabled={buttonDisabled}
          />
        </div>
      </div>
    </div>
  );
}
