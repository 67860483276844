import { GraphQLClient } from 'graphql-request';
import { GetApplicationDocument } from '../graphql/operations';
import { IdNotFoundError } from '../errors/IdNotFoundError';
import { applicationSchema } from '../schemas/application/applicationSchema';

export const createGetApplicationPromise =
  (
    client: GraphQLClient,
    applicationId: string,
    isAdmin: boolean,
    isBoard: boolean
  ) =>
  async () => {
    const request = await client.request(GetApplicationDocument, {
      applicationId,
    });
    const { application } = request;

    if (!application) {
      throw new IdNotFoundError();
    }

    return applicationSchema.parse({
      applicationId,
      isAdmin,
      isBoard,
      applicationRound: application.round,
      linkedAccounts: application.artist?.accounts,
      applicationType: application.type,
      primaryContact: application.artist?.accounts.find(
        ({ accountId }) => accountId === application?.primaryContactId
      ),
      directDepositAccounts: application.artist?.directDepositAccounts,
      ...application,
      ...request,
    });
  };

export type CreateGetApplicationPromise = ReturnType<
  typeof createGetApplicationPromise
>;

export type CreateGetApplicationPromiseResult = Awaited<
  ReturnType<CreateGetApplicationPromise>
>;
