import {
  History,
  createBrowserHistory,
  createMemoryHistory,
} from '../../utils/history';

import { EligibilityState, parseEligibilityState } from './state';

export type EligibilityHistory = History<EligibilityState>;

export const createBrowserEligibilityHistory = (): EligibilityHistory =>
  createBrowserHistory(parseEligibilityState);

export const createMemoryEligibilityHistory = (): EligibilityHistory =>
  createMemoryHistory<EligibilityState>();
