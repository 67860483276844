import { useNavigate, useParams } from 'react-router-dom';
import {
  machine as PasswordMachine,
  createPasswordResumeService,
  createPasswordSaveActions,
} from '../../machines/RedeemInvitation/PasswordMachine';
import {
  machine as AccountMachine,
  createAccountResumeService,
  createAccountSaveActions,
} from '../../machines/RedeemInvitation/AccountMachine';
import {
  machine as ArtistMachine,
  createArtistResumeService,
  createArtistSaveActions,
} from '../../machines/RedeemAccountInvitation/ArtistMachine';
import {
  machine as ManagementMachine,
  createManagementResumeService,
  createManagementSaveActions,
} from '../../machines/RedeemAccountInvitation/ManagementMachine';
import { RedeemAccountInvitationController } from './RedeemAccountInvitationController';
import { useGraphQLClient } from '../../components/GraphQLClientProvider';
import { createGetRedeemAccountInvitationContext } from '../../promises/getRedeemAccountInvitationContext';
import { createMemoryRedeemAccountInvitationStorage } from '../../machines/RedeemAccountInvitation/storage';
import { redeemAccountInvitationPromise } from '../../promises/redeemInvitationPromise';
import { parseCompleteRedeemAccountInvitationState } from '../../machines/RedeemAccountInvitation/state';
import {
  createGuards,
  createResumeService,
  createRestartService,
  createSaveActions,
} from '../../machines/RedeemAccountInvitation/Machine';
import { createBrowserRedeemAccountInvitationHistory } from '../../machines/RedeemAccountInvitation/history';
import { useAuth } from '../../components/AuthProvider';
import { withDelay } from '../../utils/withDelay';

const storage = createMemoryRedeemAccountInvitationStorage();
const history = createBrowserRedeemAccountInvitationHistory();

export const RedeemAccountInvitation = () => {
  const client = useGraphQLClient();
  const navigate = useNavigate();
  const tag = useParams().tag ?? '';
  const { signIn } = useAuth();

  return (
    <RedeemAccountInvitationController
      options={{
        guards: createGuards(storage),
        actions: {
          signIn: (_, { data }) => {
            if (data.message === 'signed-in') {
              signIn(data.id, data.roles, data.artists);
              navigate('/artists');
            }
          },
          ...createSaveActions(storage, history),
        },
        services: {
          resume: createResumeService(storage),
          restart: createRestartService(storage, history),
          getRedeemAccountInvitationContext: withDelay(
            createGetRedeemAccountInvitationContext(client, { tag })
          ),
          redeemAccountInvitation: withDelay(() =>
            parseCompleteRedeemAccountInvitationState(storage.get()).then(
              redeemAccountInvitationPromise(tag)
            )
          ),
          passwordMachine: PasswordMachine.withConfig({
            services: {
              resume: createPasswordResumeService(storage),
            },
            actions: createPasswordSaveActions(storage),
          }),
          accountMachine: AccountMachine.withConfig({
            services: { resume: createAccountResumeService(storage) },
            actions: createAccountSaveActions(storage),
          }),
          artistMachine: ArtistMachine.withConfig({
            services: { resume: createArtistResumeService(storage) },
            actions: createArtistSaveActions(storage),
          }),
          managementMachine: ManagementMachine.withConfig({
            services: { resume: createManagementResumeService(storage) },
            actions: createManagementSaveActions(storage),
          }),
        },
      }}
    />
  );
};
