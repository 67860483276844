import { z } from 'zod';
import { paymentSchema } from './paymentSchema';
import { moneySchema } from './moneySchema';

export const directDepositAccountSchema = z.object({
  id: z.string(),
  institution: z.string(),
  maskedAccount: z.string(),
  name: z.string(),
  transit: z.string(),
  paid: moneySchema,
  payments: paymentSchema.array(),
});

export type DirectDepositAccount = z.infer<typeof directDepositAccountSchema>;
