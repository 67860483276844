import { redeemInvitationResponseSchema } from '../schemas/redeemInvitationResponse';

const createLinkInvitationPromise =
  (url: string) => (tag: string) => async () => {
    try {
      const response = await fetch(url, {
        method: 'POST',
        credentials: 'include',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ tag }),
      });

      const result = redeemInvitationResponseSchema.parse(
        await response.json()
      );

      return result.message === 'signed-in'
        ? {
            success: response.ok,
            status: response.status,
            message: result.message,
            id: result.id,
            roles: result.roles,
            artists: result.artists,
          }
        : {
            success: response.ok,
            status: response.status,
            message: result.message,
          };
    } catch (e) {
      // Fetch API rejecting the promise means there was a network error.
      // Use `0` as a sentinel value to indicate this.
      return {
        success: false,
        status: 0,
        message: 'network-error' as const,
      };
    }
  };

export type LinkInvitationResult = Awaited<
  ReturnType<ReturnType<ReturnType<typeof createLinkInvitationPromise>>>
>;

export const linkArtistInvitationPromise = createLinkInvitationPromise(
  `${import.meta.env.VITE_API_BASE}/link-artist-invitation`
);

export const linkBoardMemberInvitationPromise = createLinkInvitationPromise(
  `${import.meta.env.VITE_API_BASE}/link-board-member-invitation`
);
