import { match } from 'ts-pattern';
import classNames from 'classnames';
import {
  ReleaseMachineSender,
  ReleaseMachineState,
} from '../../../machines/Eligibility/ReleaseMachine';
import { Button } from '../../Button';
import { RadioOption } from '../../RadioOption';
import { Feedback } from '../../Feedback';
import { FieldSet } from '../../FieldSet';
import { PageHeader } from '../../PageHeader';
import { TextInput } from '../../TextInput';
import { Select } from '../../Select';
import { FormField, FormFieldProps } from '../../FormField';
import { Link } from '../../Link';
import { DatePicker } from '../../DatePicker/DatePicker';
import { Form } from '../../Form';
import { FormFieldDuoInGrid } from '../../FormFieldDuoInGrid';
import { formatCalendarDate } from '../../../utils/formatCalendarDate';
import { FormControls } from '../../FormControls';

import styles from './ReleaseView.module.scss';

interface ReleaseViewProps {
  state: ReleaseMachineState;
  send: ReleaseMachineSender;
}

export function ReleaseView({ state, send }: ReleaseViewProps) {
  const { type } = state.context;
  const {
    heading,
    paginationSteps,
    paginationActive,
    includeBackButton,
    content,
  } =
    type === 'add-release'
      ? {
          heading: 'Add release details',
          paginationSteps: 3,
          paginationActive: 1,
          includeBackButton: false,
          content: null,
        }
      : {
          heading: 'Do you have an eligible release?',
          paginationSteps: 5,
          paginationActive: 2,
          includeBackButton: true,
          content: (
            <>
              Tell us about your{' '}
              <span className={styles.bold600}>most recent release</span> that
              has sales or streaming data.
            </>
          ),
        };

  return (
    <Form
      className={styles.form}
      onSubmit={() => {
        send('NEXT');
      }}
    >
      <PageHeader
        heading={heading}
        paginationSteps={paginationSteps}
        paginationActive={paginationActive}
        includeBackButton={includeBackButton}
        backButtonOnClick={() => send('BACK')}
      >
        {content}
      </PageHeader>
      <div className={classNames(styles.formFields, 'max-w-md')}>
        <FormField
          label="Release title"
          formFieldStatus={getFormFieldStatus(state, 'title')}
          infoLabel={match(state)
            .when(
              () => state.matches('title.empty'),
              () => 'This field is required.'
            )
            .otherwise(() => '')}
          feedbackType="warning"
        >
          <TextInput
            id="title"
            label="Release Title"
            value={state.context.title}
            onChange={(title) => send({ type: 'SET_TITLE', title })}
            onBlur={() => send({ type: 'BLUR_TITLE' })}
          />
        </FormField>
        <FormField
          label="Label"
          formFieldStatus={getFormFieldStatus(state, 'label')}
          description="If you released without a label, choose Self-Release. If your label is not in the list, please select Other."
          infoLabel={match(state)
            .when(
              () => state.matches('label.empty'),
              () => 'This field is required.'
            )
            .otherwise(() => '')}
          feedbackType="warning"
        >
          <Select
            id="label"
            label="Please select a label"
            items={state.context.labels}
            itemToKey={(item) => item?.id}
            selectedItem={state.context.label ?? null}
            initialSelectedItem={state.context.label}
            onChange={(label) => {
              send({ type: 'SET_LABEL', label });
            }}
            getItemText={({ name }) => name}
          />
        </FormField>
        {!state.matches('otherLabel.hidden') && (
          <FormField
            label="Other Label"
            formFieldStatus={getFormFieldStatus(state, 'otherLabel')}
            infoLabel={match(state)
              .when(
                () => state.matches('otherLabel.empty'),
                () => 'This field is required.'
              )
              .otherwise(() => '')}
            feedbackType="warning"
          >
            <TextInput
              id="other-label"
              value={state.context.otherLabel ?? ''}
              onChange={(otherLabel) =>
                send({ type: 'SET_OTHER_LABEL', otherLabel })
              }
              onBlur={() => send({ type: 'BLUR_OTHER_LABEL' })}
            />
          </FormField>
        )}
        <FormField
          label="Distributor"
          description="Please select Other if your distributor is not listed."
        >
          <Select
            id="distributor"
            label="Please select a distributor"
            items={state.context.distributors}
            itemToKey={(item) => item?.id}
            selectedItem={state.context.distributor ?? null}
            initialSelectedItem={state.context.distributor}
            onChange={(distributor) =>
              send({ type: 'SET_DISTRIBUTOR', distributor })
            }
            getItemText={({ name }) => name}
          />
        </FormField>
        {!state.matches('otherDistributor.hidden') && (
          <FormField
            label="Other Distributor"
            formFieldStatus={getFormFieldStatus(state, 'otherDistributor')}
            infoLabel={match(state)
              .when(
                () => state.matches('otherDistributor.empty'),
                () => 'This field is required.'
              )
              .otherwise(() => '')}
            feedbackType="warning"
          >
            <TextInput
              id="other-distributor"
              value={state.context.otherDistributor ?? ''}
              onChange={(otherDistributor) =>
                send({ type: 'SET_OTHER_DISTRIBUTOR', otherDistributor })
              }
              onBlur={() => send({ type: 'BLUR_OTHER_DISTRIBUTOR' })}
            />
          </FormField>
        )}
        <FormField
          id="Release date"
          htmlFor="Release date"
          label="Release date"
          tooltipContent="Release date must be from the previous 5 years before the round deadline."
          formFieldStatus={getFormFieldStatus(state, 'date')}
          infoLabel={match(state)
            .when(
              () => state.matches('date.empty'),
              () => 'This field is required.'
            )
            .when(
              (state) =>
                state.matches('date.invalid') ||
                state.matches('date.valid.warning'),
              ({ context }) =>
                `The release date must be on or after ${formatCalendarDate(
                  context.eligibilityCutoffDate
                )}.`
            )
            .otherwise(() => '')}
          feedbackType={
            state.matches('date.valid.warning') ? 'neutral' : 'warning'
          }
        >
          <DatePicker
            aria-label="Release date"
            value={state.context.date}
            onChange={(date) => send({ type: 'SET_DATE', date })}
          />
        </FormField>

        <FormFieldDuoInGrid
          left={{
            label: 'Length in minutes',
            tooltipContent:
              'A full-length release must be at least 25 minutes in length. A bundle release must be at least 9 minutes in length.',
            formFieldStatus: getFormFieldStatus(state, 'numberOfMinutes'),
            infoLabel: match(state)
              .when(
                () => state.matches('numberOfMinutes.empty'),
                () => 'This field is required.'
              )
              .when(
                () => state.matches('numberOfMinutes.invalid.type'),
                () => 'This field must be numeric.'
              )
              .when(
                () => state.matches('numberOfMinutes.invalid.value'),
                () => 'Releases must be at least 9 minutes in length'
              )
              .otherwise(() => ''),
            feedbackType:
              getFormFieldStatus(state, 'numberOfMinutes') === 'error'
                ? 'warning'
                : 'neutral',
            children: (
              <TextInput
                id="length-in-minutes"
                label="Length in minutes"
                value={state.context.numberOfMinutes}
                onChange={(numberOfMinutes) =>
                  send({ type: 'SET_NUMBER_OF_MINUTES', numberOfMinutes })
                }
                onBlur={() => send({ type: 'BLUR_NUMBER_OF_MINUTES' })}
              />
            ),
          }}
          right={{
            label: 'Number of tracks',
            tooltipContent:
              'A full-length release must have at least 6 tracks. A bundle release must have at least 6 tracks.',
            formFieldStatus: getFormFieldStatus(state, 'numberOfTracks'),
            infoLabel: match(state)
              .when(
                () => state.matches('numberOfTracks.empty'),
                () => 'This field is required.'
              )
              .when(
                () => state.matches('numberOfTracks.invalid.type'),
                () => 'This field must be numeric.'
              )
              .when(
                () => state.matches('numberOfTracks.invalid.value'),
                () => 'Releases must have 3 or more tracks'
              )
              .otherwise(() => ''),
            feedbackType:
              getFormFieldStatus(state, 'numberOfTracks') === 'error'
                ? 'warning'
                : 'neutral',
            children: (
              <TextInput
                id="number-of-tracks"
                label="Number of tracks"
                value={state.context.numberOfTracks}
                onChange={(numberOfTracks) =>
                  send({ type: 'SET_NUMBER_OF_TRACKS', numberOfTracks })
                }
                onBlur={() => send({ type: 'BLUR_NUMBER_OF_TRACKS' })}
              />
            ),
          }}
        />

        <FormField
          label="Are the majority of the songs on the release in French?"
          tooltipContent="For releases with the majority of songs in French you must contact le Fonds Radiostar."
          feedbackType={
            getFormFieldStatus(state, 'language') === 'error'
              ? 'warning'
              : 'neutral'
          }
          formFieldStatus={getFormFieldStatus(state, 'language')}
          infoLabel={match(state)
            .when(
              () => state.matches('language.empty'),
              () => 'This field is required.'
            )
            .when(
              () => state.matches('language.invalid'),
              () =>
                'The majority of the tracks included on your album must not be in the French language. For releases with the majority of songs in French you must contact le Fonds Radiostar.'
            )
            .otherwise(() => '')}
        >
          <FieldSet direction="vertical">
            <RadioOption
              label="No, the majority of songs are NOT in French"
              checked={state.context.isMajorityEnglish === true}
              onChange={() =>
                send({ type: 'SET_LANGUAGE', isMajorityEnglish: true })
              }
            />
            <RadioOption
              label="Yes, the majority of songs are in French"
              checked={state.context.isMajorityEnglish === false}
              onChange={() =>
                send({ type: 'SET_LANGUAGE', isMajorityEnglish: false })
              }
            />
          </FieldSet>
        </FormField>
      </div>
      <div className={classNames(['max-w-md', styles.container])}>
        {state.matches('submitted.empty') && (
          <Feedback
            type="warning"
            className={classNames({
              'feedback-highlight': state.matches('submitted.empty.highlight'),
            })}
          >
            Please fill in all the fields above.
          </Feedback>
        )}
        {state.matches('submitted.invalid') && (
          <Feedback
            title="This release is not eligible."
            type="warning"
            className={classNames({
              'feedback-highlight': state.matches(
                'submitted.invalid.highlight'
              ),
            })}
          >
            <div>
              Review the fields highlighted above. Learn more about the{' '}
              <Link href="/requirements">eligibility requirements</Link>.
            </div>
            <div>
              If you have another release that may be eligible, please update
              the form above.
            </div>
          </Feedback>
        )}
        {state.matches('submitted.empty-and-invalid') && (
          <Feedback
            title="This release is not eligible and required fields are missing."
            type="warning"
            className={classNames({
              'feedback-highlight': state.matches(
                'submitted.empty-and-invalid.highlight'
              ),
            })}
          >
            <div>
              Review the fields highlighted above. Learn more about the{' '}
              <Link href="/requirements">eligibility requirements</Link>.
            </div>
            <div>
              If you have another release that may be eligible, please update
              the form above.
            </div>
          </Feedback>
        )}
        <FormControls>
          <Button
            variant="primary"
            label="Next"
            endIcon="rightChevron"
            type="submit"
          />
        </FormControls>
      </div>
    </Form>
  );
}

function getFormFieldStatus(
  state: ReleaseMachineState,
  inputName: string
): NonNullable<FormFieldProps['formFieldStatus']> {
  if (state.matches(`${inputName}.empty`)) {
    return 'error';
  }

  if (state.matches(`${inputName}.invalid`)) {
    return 'error';
  }

  return 'default';
}
