import { GraphQLClient } from 'graphql-request';
import { CreateExpenseDocument } from '../../../graphql/operations';
import { AddExpense } from '../../../events/Expense/AddExpense';
import { z } from 'zod';
import { prepareExpense } from './prepareExpense';
import { fundingRequestSchema } from '../../../schemas/fundingRequests/fundingRequestSchema';

export const createAddExpensePromise =
  (client: GraphQLClient) =>
  async (_: unknown, { expense }: AddExpense) => {
    const response = await client.request(CreateExpenseDocument, {
      expense: prepareExpense(expense),
    });

    return z
      .object({
        fundingRequest: fundingRequestSchema,
      })
      .parse(response.request);
  };

export type CreateAddExpensePromise = ReturnType<
  typeof createAddExpensePromise
>;

export type CreateAddExpensePromiseResult = Awaited<
  ReturnType<CreateAddExpensePromise>
>;
