export function scrollElementIntoView(
  id: string,
  highlight?: boolean,
  conditionalAttribute?: string
) {
  setTimeout(() => {
    const element = document.getElementById(id);
    if (
      element &&
      (!conditionalAttribute || element.hasAttribute(conditionalAttribute))
    ) {
      element.scrollIntoView();
      if (highlight) {
        element.classList.remove('container-highlight');
        element.classList.add('container-highlight');
      }
    }
  }, 0);
}
