import cn from 'classnames';
import { useSelect, UseSelectProps } from 'downshift';
import { SelectIcon } from './SelectIcon';

import styles from './Select.module.scss';

type Props<Item> = {
  id: string;
  items: Item[];
  itemToKey: UseSelectProps<Item>['itemToKey'];
  selectedItem: Item | null;
  label?: string;
  error?: boolean;
  disabled?: boolean;
  initialSelectedItem?: Item;
  onChange: (option: Item) => void;
  onBlur?: () => void;
  onFocus?: () => void;
  getItemText: (option: Item) => string;
  className?: string;
  selectedItemClassName?: string;
  formFieldStatus?: 'default' | 'text' | 'error';
  isLoading?: boolean;
};

export function Select<Item>(props: Props<Item>) {
  const {
    label,
    id,
    items,
    itemToKey,
    error = false,
    disabled = false,
    initialSelectedItem,
    selectedItem,
    onChange,
    onBlur,
    onFocus,
    getItemText,
    className,
    selectedItemClassName,
    formFieldStatus = 'default',
    isLoading,
  } = props;

  const {
    isOpen,
    getToggleButtonProps,
    getMenuProps,
    highlightedIndex,
    getItemProps,
  } = useSelect({
    id,
    labelId: `${id}`,
    items,
    itemToString: (item) => (item ? getItemText(item) : ''),
    itemToKey,
    selectedItem,
    onSelectedItemChange: (changes) => {
      if (changes.selectedItem && changes.selectedItem !== selectedItem) {
        onChange(changes.selectedItem);
      }
    },
    onIsOpenChange: ({ isOpen }) => {
      if (isOpen) {
        onFocus?.();
      }

      if (!isOpen) {
        onBlur?.();
      }
    },
  });

  const isDisabled = disabled || isLoading;

  return (
    <div
      className={cn(
        styles.selectInput,
        {
          [styles.open]: isOpen === true,
          [styles.closed]: isOpen === false,
          [styles.default]: selectedItem === null,
          [styles.text]: formFieldStatus === 'text',
          [styles.error]: error || formFieldStatus === 'error',
        },
        className
      )}
    >
      <button
        id={id}
        type="button"
        {...getToggleButtonProps()}
        className={cn(styles.button, {
          [styles.buttonDefault]: !isDisabled && selectedItem === null,
          [styles.buttonDisabled]: isDisabled,
          [styles.buttonError]: error,
          [styles.buttonInitial]: initialSelectedItem,
          [styles.buttonOpen]: isOpen === true,
        })}
        disabled={isDisabled}
      >
        {selectedItem !== null ? (
          <span className={selectedItemClassName}>
            {getItemText(selectedItem)}
          </span>
        ) : (
          <span className={styles.placeholder}>{label}</span>
        )}
        <SelectIcon
          isOpen={isOpen}
          isLoading={isLoading}
          className={isDisabled ? styles.iconDisabled : styles.icon}
        />
      </button>
      <ul
        {...getMenuProps()}
        className={cn(styles.optionList, {
          [styles.open]: isOpen === true,
        })}
      >
        {items.map((item, index) => (
          <li className={styles.option} key={index}>
            <button
              type="button"
              className={cn(styles.optionButton, {
                [styles.highlighted]: index === highlightedIndex,
              })}
              {...getItemProps({ item, index })}
              value={getItemText(item)}
            >
              {getItemText(item)}
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
}
