import type { EventFrom, Sender } from 'xstate';
import { assign, createMachine } from 'xstate';
import { CreateSearchArtistsPromiseResult } from '../../../promises/Admin/createSearchArtistsPromise';
import type { StateFrom } from '../../../utils/StateFrom';
import type { MachineOptionsWithContextFrom } from '../../../utils/MachineOptionsWithContextFrom';
import { SearchAdminArtistsSortOrder } from '../../../graphql/operations';
import { logMachineError } from '../../../utils/logError';

type Events =
  | { type: 'SET_SEARCH_TERM'; searchTerm: string }
  | { type: 'SET_PAGE'; page: number }
  | { type: 'SET_SORT_MODE'; sortMode: SearchAdminArtistsSortOrder }
  | { type: 'SET_LIMIT'; limit: number }
  | { type: 'SET_IS_ELIGIBLE' }
  | { type: 'SET_IS_INELIGIBLE' }
  | { type: 'SET_ACTIVITY_SLOWING' }
  | { type: 'SET_PENDING_ORION' }
  | { type: 'SET_UNDER_400000' }
  | { type: 'SET_OVER_400000' }
  | { type: 'SET_NEARING_600000' }
  | { type: 'CLEAR_ALL_FILTERS' };

type Services = {
  searchArtistsPromise: {
    data: CreateSearchArtistsPromiseResult;
  };
};

export type Context = {
  artists: CreateSearchArtistsPromiseResult;
  offset: number;
  limit: number;
  searchTerm: string;
  totalNumberOfArtists: number;
  currentPage: number;
  sortMode: SearchAdminArtistsSortOrder;
  isEligible: boolean;
  isIneligible: boolean;
  isActivitySlowing: boolean;
  isOrionPending: boolean;
  under400kFunding: boolean;
  over400kFunding: boolean;
  nearing600kFunding: boolean;
  isMobile: boolean;
};

export const createContext = (
  artists: CreateSearchArtistsPromiseResult,
  isMobile: boolean,
  offset: number,
  isOrionPending = false,
  limit?: number
): Context => ({
  artists,
  offset,
  limit: limit ?? isMobile ? 10 : 20,
  searchTerm: '',
  totalNumberOfArtists: 0,
  currentPage: 1,
  sortMode: SearchAdminArtistsSortOrder.ArtistNameAsc,
  isEligible: false,
  isIneligible: false,
  isActivitySlowing: false,
  isOrionPending,
  under400kFunding: false,
  over400kFunding: false,
  nearing600kFunding: false,
  isMobile,
});

export const machine = createMachine(
  {
    predictableActionArguments: true,
    tsTypes: {} as import('./SearchArtistsMachine.typegen').Typegen0,
    schema: {
      context: {} as Context,
      events: {} as Events,
      services: {} as Services,
    },
    initial: 'init',
    id: 'form',
    states: {
      init: {
        invoke: {
          id: 'searchArtistsPromise',
          src: 'searchArtistsPromise',
          onDone: {
            actions: ['setContext', 'sendArtistsToParent'],
            target: 'ready',
          },
          onError: 'error',
        },
      },
      error: { entry: 'logMachineError' },
      ready: {
        on: {
          SET_SEARCH_TERM: {
            actions: 'setSearchTerm',
            target: 'typing',
          },
          SET_PAGE: {
            actions: 'setPage',
            target: 'init',
          },
          SET_SORT_MODE: {
            actions: 'setSortMode',
            target: 'search',
          },
        },
      },
      typing: {
        after: {
          500: {
            target: 'search',
          },
        },
        on: {
          SET_SEARCH_TERM: {
            actions: 'setSearchTerm',
            target: 'typing',
          },
        },
      },
      search: {
        entry: 'resetSearch',
        invoke: {
          id: 'searchArtistsPromise',
          src: 'searchArtistsPromise',
          onDone: {
            actions: ['setContext', 'sendArtistsToParent'],
            target: '#form.ready',
          },
          onError: 'error',
        },
      },
    },
    on: {
      SET_LIMIT: {
        actions: 'setLimit',
        target: 'search',
      },
      SET_IS_ELIGIBLE: {
        actions: 'setIsEligible',
        target: 'search',
      },
      SET_IS_INELIGIBLE: {
        actions: 'setIsIneligible',
        target: 'search',
      },
      SET_ACTIVITY_SLOWING: {
        actions: 'setActivitySlowing',
        target: 'search',
      },
      SET_PENDING_ORION: {
        actions: 'setOrionPending',
        target: 'search',
      },
      SET_UNDER_400000: {
        actions: 'setUnder400000',
        target: 'search',
      },
      SET_OVER_400000: {
        actions: 'setOver400000',
        target: 'search',
      },
      SET_NEARING_600000: {
        actions: 'setNearing600000',
        target: 'search',
      },
      CLEAR_ALL_FILTERS: {
        actions: 'clearFilters',
        target: 'search',
      },
    },
  },
  {
    actions: {
      logMachineError,
      setContext: assign((_, { data }) => ({
        artists: data,
        totalNumberOfArtists: data.at(0)?.total ?? 0,
      })),
      setSearchTerm: assign((_, { searchTerm }) => ({
        searchTerm,
      })),
      setPage: assign((context, event) => ({
        currentPage: event.page,
        offset: (event.page - 1) * context.limit,
      })),
      setSortMode: assign((_, event) => ({
        sortMode: event.sortMode,
      })),
      resetSearch: assign((_) => ({
        currentPage: 1,
        offset: 0,
      })),
      setLimit: assign((_, event) => ({
        limit: event.limit,
      })),
      setIsEligible: assign((context) => ({
        isEligible: !context.isEligible,
      })),
      setIsIneligible: assign((context) => ({
        isIneligible: !context.isIneligible,
      })),
      setActivitySlowing: assign((context) => ({
        isActivitySlowing: !context.isActivitySlowing,
      })),
      setOrionPending: assign((context) => ({
        isOrionPending: !context.isOrionPending,
      })),
      setUnder400000: assign((context) => ({
        under400kFunding: !context.under400kFunding,
      })),
      setOver400000: assign((context) => ({
        over400kFunding: !context.over400kFunding,
      })),
      setNearing600000: assign((context) => ({
        nearing600kFunding: !context.nearing600kFunding,
      })),
      clearFilters: assign((_) => ({
        searchTerm: '',
        sortMode: SearchAdminArtistsSortOrder.ArtistNameAsc,
        isEligible: false,
        isIneligible: false,
        isActivitySlowing: false,
        isOrionPending: false,
        under400kFunding: false,
        over400kFunding: false,
        nearing600kFunding: false,
      })),
    },
  }
);

type Machine = typeof machine;

export type SearchArtistsMachineState = StateFrom<Machine>;
export type SearchArtistsMachineSender = Sender<EventFrom<Machine>>;
export type SearchArtistsMachineOptions =
  MachineOptionsWithContextFrom<Machine>;
