import { Expense } from '../../schemas/fundingRequests/expenses/expenseSchema';

export type OpenExpense = {
  type: 'OPEN_EXPENSE';
  id?: Expense['id'];
};

export const openExpense = (id?: OpenExpense['id']): OpenExpense => ({
  type: 'OPEN_EXPENSE',
  id,
});
