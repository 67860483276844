import { createContext, useContext } from 'react';
import { GraphQLClient } from 'graphql-request';

const GraphQLClientContext = createContext(
  new GraphQLClient(`${import.meta.env.VITE_API_BASE}/graphql`, {
    credentials: 'include',
  })
);

export const useGraphQLClient = () => useContext(GraphQLClientContext);
