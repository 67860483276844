import cn from 'classnames';

import styles from './ReleaseStatusBadge.module.scss';

interface ReleaseStatusBadgeProps {
  type: 'Eligible' | 'Ineligible' | 'Restricted';
  className?: string;
}

export function ReleaseStatusBadge({
  type,
  className,
}: ReleaseStatusBadgeProps) {
  return (
    <div
      className={cn(className, styles.container, {
        [styles.eligible]: type === 'Eligible',
        [styles.ineligible]: type === 'Ineligible',
        [styles.restricted]: type === 'Restricted',
      })}
    >
      {type}
    </div>
  );
}
