import { GraphQLClient } from 'graphql-request';
import { z } from 'zod';
import {
  ArtistEligibility,
  ArtistStatus,
  SearchAdminArtistsDocument,
} from '../../graphql/operations';
import { Context } from '../../machines/Admin/Artists/SearchArtistsMachine';

export const createSearchArtistsPromise =
  (client: GraphQLClient) => async (context: Context) => {
    const result = await client.request(SearchAdminArtistsDocument, {
      searchTerm: context.searchTerm,
      sortMode: context.sortMode,
      isEligible: context.isEligible,
      isIneligible: context.isIneligible,
      isActivitySlowing: context.isActivitySlowing,
      isOrionPending: context.isOrionPending,
      under400kFunding: context.under400kFunding,
      over400kFunding: context.over400kFunding,
      nearing600kFunding: context.nearing600kFunding,
      offset: context.offset,
      limit: context.limit,
    });

    return z
      .object({
        name: z.string(),
        id: z.string().uuid(),
        eligibility: z.nativeEnum(ArtistEligibility),
        status: z.nativeEnum(ArtistStatus),
        total: z.string().transform((s) => parseInt(s)),
        releases: z
          .object({
            title: z.string(),
          })
          .array()
          .default([]),
      })
      .array()
      .parseAsync(result.artists);
  };

export type CreateSearchArtistsPromise = ReturnType<
  typeof createSearchArtistsPromise
>;
export type CreateSearchArtistsPromiseResult = Awaited<
  ReturnType<CreateSearchArtistsPromise>
>;
