import {
  CalendarDate,
  ZonedDateTime,
  toCalendarDate,
} from '@internationalized/date';
import { ApplicationType } from '../../graphql/operations';
import { FundingRound } from '../../schemas/fundingRound/fundingRoundSchema';
import { formatZonedDateTime } from '../../utils/formatZonedDateTime';
import { formatCalendarDate } from '../../utils/formatCalendarDate';
import { isAfterCutoff } from '../../utils/isAfterCutoff';

interface SubmissionDatesProps {
  submittedAt: ZonedDateTime | null;
  claimsSubmittedAt: ZonedDateTime | null;
  claimsResubmittedAt: ZonedDateTime | null;
  roundTitle?: string;
  applicationType: ApplicationType;
  currentRound: FundingRound | null;
  eligibilityDeadline: CalendarDate | null;
}

export function SubmissionDates({
  submittedAt,
  claimsSubmittedAt,
  claimsResubmittedAt,
  roundTitle = '',
  applicationType,
  currentRound,
  eligibilityDeadline,
}: SubmissionDatesProps) {
  const submittedParagraph = (
    <p>
      This application was submitted
      {roundTitle ? ` for Round ${roundTitle}` : ''} on{' '}
      <span className="body-text-bold">{formatZonedDateTime(submittedAt)}</span>
      .
    </p>
  );
  if (claimsSubmittedAt) {
    return (
      <>
        {submittedParagraph}
        <p>
          Funding claim was submitted on{' '}
          <span className="body-text-bold">
            {formatZonedDateTime(claimsSubmittedAt)}
          </span>
          .
        </p>
        {claimsResubmittedAt && (
          <p>
            Funding claim was resubmitted on{' '}
            <span className="body-text-bold">
              {formatZonedDateTime(claimsResubmittedAt)}
            </span>
            .
          </p>
        )}
      </>
    );
  }

  if (submittedAt) {
    return submittedParagraph;
  }

  if (currentRound) {
    return (
      <div>
        <p>
          Applications for Round {currentRound.title} must be submitted by{' '}
          <span className="body-text-bold">
            {formatZonedDateTime(currentRound.endDate)}.
          </span>
        </p>
        {eligibilityDeadline &&
          isAfterCutoff(
            eligibilityDeadline,
            toCalendarDate(currentRound.endDate)
          ) && (
            <p>
              If this application is not{' '}
              <span className="body-text-bold">
                submitted by midnight (Eastern) on{' '}
                {formatCalendarDate(eligibilityDeadline)},
              </span>
              {applicationType === ApplicationType.Core
                ? ' some tour dates or digital content requests will not be eligible for funding.'
                : ' some funding requests will not be eligible for funding.'}
            </p>
          )}
      </div>
    );
  }

  return null;
}
