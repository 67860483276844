import { CompleteFundingRequest } from '../../schemas/fundingRequests/fundingRequestSchema';
import { parsePositiveFloat } from '../../utils/parsePositiveFloat';

export function prepareFundingRequest({
  cost,
  date,
  ...rest
}: CompleteFundingRequest) {
  return {
    cost: parsePositiveFloat(cost).toString(),
    date: date.toString(),
    ...rest,
  };
}
