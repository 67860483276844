import { match } from 'ts-pattern';
import classNames from 'classnames';
import { TextInput } from '../../components/TextInput';
import { Button } from '../../components/Button';
import { PageHeader } from '../../components/PageHeader';
import {
  ForgotPasswordMachineState,
  ForgotPasswordMachineSender,
} from '../../machines/ForgotPasswordMachine';
import { Form } from '../../components/Form';
import { ErrorFeedback } from '../../components/ErrorFeedback';
import { Feedback } from '../../components/Feedback';
import { FormField } from '../../components/FormField';
import { FormControls } from '../../components/FormControls';

import styles from './ForgotPasswordView.module.scss';

type ForgotPasswordViewProps = {
  state: ForgotPasswordMachineState;
  send: ForgotPasswordMachineSender;
};

export function ForgotPasswordView({ state, send }: ForgotPasswordViewProps) {
  return match(state)
    .when(
      () => state.matches('ready') || state.matches('waitingResponse'),
      () => (
        <Form
          className={styles.container}
          onSubmit={() => {
            send('SUBMIT');
          }}
        >
          <PageHeader
            heading="Forgot Password"
            includeCloseButton={true}
            closeButtonOnClick={() => send('CLOSE')}
          >
            Enter the email associated with your account and we’ll send you an
            email with instructions to reset your password.
          </PageHeader>
          <div className={classNames(styles.formFields, 'max-w-md')}>
            {state.matches('ready.email.error.empty') && (
              <ErrorFeedback type="noEmail" />
            )}
            {state.matches('ready.email.error.badFormat') && (
              <ErrorFeedback type="badEmail" />
            )}
            {state.matches('ready.auth.error.network') && (
              <ErrorFeedback type="network" />
            )}
            {state.matches('ready.auth.error.internal') && <ErrorFeedback />}
            <FormField label="Email">
              <TextInput
                id="email"
                label="Email"
                disabled={state.matches('waitingResponse')}
                value={state.context.email}
                error={state.matches('ready.email.error')}
                onChange={(email) => send({ type: 'UPDATE_EMAIL', email })}
              />
            </FormField>
          </div>
          <FormControls className="max-w-md">
            <Button
              label="Send Instructions"
              disabled={state.matches('waitingResponse')}
              type="submit"
            />
          </FormControls>
        </Form>
      )
    )
    .when(
      () =>
        // To avoid user enumeration, display the same message regardless of whether
        // the email is in the system or not.
        state.matches('emailRequested'),
      () => (
        <div className={'max-w-lg'}>
          <PageHeader heading="Email Sent" />
          <Feedback
            type="positive"
            icon="mail"
            title="Password reset request sent"
          >
            <div className={styles.feedback}>
              <p>
                An email has been sent to{' '}
                <span className="body-text-bold">{state.context.email}</span>{' '}
                with instructions on how to reset your password.
              </p>
              <p>This request will expire in 1 hour.</p>
            </div>
          </Feedback>
        </div>
      )
    )
    .run();
}
