import { GraphQLClient } from 'graphql-request';
import { GetApplicationSearchPageDocument } from '../graphql/operations';

export const createGetApplicationSearchPagePromise =
  (client: GraphQLClient) => () =>
    client.request(GetApplicationSearchPageDocument);

export type GetApplicationSearchPagePromise = ReturnType<
  typeof createGetApplicationSearchPagePromise
>;
export type GetApplicationSearchPagePromiseResult =
  Awaited<GetApplicationSearchPagePromise>;
