import { z } from 'zod';
import { GraphQLClient } from 'graphql-request';
import { ZonedDateTime } from '@internationalized/date';
import { SetFluxCapacitorDocument } from '../graphql/operations';
import { serverDateTimeSchema } from '../schemas/dateTimeSchema';
import { parseAbsoluteOrNull } from '../utils/parseAbsoluteOrNull';

export const setFluxCapacitor =
  (client: GraphQLClient) =>
  async ({
    time,
    timezone,
  }: {
    time: ZonedDateTime | null;
    timezone: string;
  }) => {
    if (time === null) {
      return { time, timezone };
    }

    const result = await client.request(SetFluxCapacitorDocument, {
      time: time.toAbsoluteString(),
    });

    return z
      .object({ time: serverDateTimeSchema, timezone: z.string() })
      .transform(({ time, timezone }) => ({
        time: parseAbsoluteOrNull(time, timezone),
        timezone,
      }))
      .parse(result.result);
  };

export type SetFluxCapacitorPromise = ReturnType<typeof setFluxCapacitor>;
export type SetFluxCapacitorPromiseResult = Awaited<
  ReturnType<SetFluxCapacitorPromise>
>;
