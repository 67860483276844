import type { EventFrom, MachineOptionsFrom, Sender, StateFrom } from 'xstate';
import { assign, createMachine } from 'xstate';
import {
  ForgotPasswordPromiseResult,
  isSuccess,
} from '../promises/forgotPasswordPromise';
import { isEmailValid } from '../utils/isEmailValid';
import { logMachineError } from '../utils/logError';

type Context = {
  email: string;
};

type UpdateEmailEvent = { type: 'UPDATE_EMAIL'; email: string };

type Events = UpdateEmailEvent | { type: 'SUBMIT' } | { type: 'CLOSE' };

type Services = {
  forgotPasswordPromise: {
    data: ForgotPasswordPromiseResult;
  };
};

const emailStates = {
  initial: 'noError',
  states: {
    noError: {},
    error: {
      initial: 'empty',
      states: {
        empty: {},
        badFormat: {},
        invalidUser: {},
      },
    },
  },
};

const authStates = {
  initial: 'noError',
  states: {
    noError: {},
    error: {
      initial: 'network',
      states: {
        network: {
          on: {
            SUBMIT: [
              {
                cond: 'isEmailEmpty',
                target: '#forgotPasswordForm.ready.email.error.empty',
              },
              {
                cond: 'isEmailBadFormat',
                target: '#forgotPasswordForm.ready.email.error.badFormat',
              },
              {
                target: '#forgotPasswordForm.waitingResponse',
              },
            ],
          },
        },
        internal: { entry: 'logMachineError' },
      },
    },
  },
};

export const machine = createMachine(
  {
    predictableActionArguments: true,
    tsTypes: {} as import('./ForgotPasswordMachine.typegen').Typegen0,
    id: 'forgotPasswordForm',
    schema: {
      context: {} as Context,
      events: {} as Events,
      services: {} as Services,
    },
    context: {
      email: '',
    },
    initial: 'ready',
    states: {
      ready: {
        type: 'parallel',
        on: {
          CLOSE: { actions: 'close' },
          UPDATE_EMAIL: { actions: 'updateEmail' },
          SUBMIT: [
            { cond: 'isEmailEmpty', target: 'ready.email.error.empty' },
            { cond: 'isEmailBadFormat', target: 'ready.email.error.badFormat' },
            { target: 'waitingResponse' },
          ],
        },
        states: {
          email: { ...emailStates },
          auth: { ...authStates },
        },
      },
      waitingResponse: {
        invoke: {
          id: 'forgotPasswordPromise',
          src: 'forgotPasswordPromise',
          onDone: [
            {
              cond: 'isSuccess',
              target: 'emailRequested',
            },
            {
              target: 'ready.auth.error.internal',
            },
          ],
          onError: {
            target: 'ready.auth.error.network',
          },
        },
      },
      emailRequested: {},
    },
  },
  {
    actions: { logMachineError },
    guards: {
      isEmailEmpty: ({ email }) => email.length === 0,
      isEmailBadFormat: ({ email }) => !isEmailValid(email),
      isSuccess: (_, { data }) => isSuccess(data),
    },
  }
);

export const createActions = (close: () => void) => ({
  close,
  updateEmail: assign((_: Context, { email }: UpdateEmailEvent) => ({ email })),
});

export type ForgotPasswordMachine = typeof machine;
export type ForgotPasswordMachineState = StateFrom<ForgotPasswordMachine>;
export type ForgotPasswordMachineEvents = EventFrom<ForgotPasswordMachine>;
export type ForgotPasswordMachineSender = Sender<ForgotPasswordMachineEvents>;
export type ForgotPasswordMachineOptions = MachineOptionsFrom<
  ForgotPasswordMachine,
  true
>;
