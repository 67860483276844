import { useActor } from '@xstate/react';
import { ApplicationReviewView } from './ApplicationReviewView';
import { ApplicationReviewMachineActor } from '../../../machines/Admin/ApplicationReviewMachine';

interface ApplicationReviewProps {
  actor: ApplicationReviewMachineActor;
}

export function ApplicationReview({ actor }: ApplicationReviewProps) {
  const [state, send] = useActor(actor);

  return <ApplicationReviewView state={state} send={send} />;
}
