import { getInitalPaymentAmount } from '../../utils/getInitalPaymentAmount';
import { Feedback } from '../Feedback';
import { FeedbackPage } from './ApplicationStatusFeedback';
import { ApplicationType } from '../../graphql/operations';
import { Link } from '../Link';

interface ContractPendingFeedbackProps {
  applicationType: ApplicationType;
  fundingType: string;
  roundTitle: string;
  location: FeedbackPage;
  applicationLink?: string;
}

export function ContractPendingFeedback({
  applicationType,
  fundingType,
  roundTitle,
  location,
  applicationLink,
}: ContractPendingFeedbackProps) {
  return (
    <Feedback
      type="positive"
      title={`Congratulations, your ${fundingType} Application for round #${roundTitle} has been approved!`}
      icon="checkCircle"
    >
      <p>
        Please{' '}
        {location === 'dashboard' && applicationLink ? (
          <Link href={applicationLink}>upload the signed contract</Link>
        ) : (
          'upload the signed contract'
        )}{' '}
        and provide bank information to receive the first{' '}
        {getInitalPaymentAmount(applicationType)} of the funding.
      </p>
    </Feedback>
  );
}
