import { match } from 'ts-pattern';
import { Icon, IconName } from './Icon';

interface SelectIconProps {
  isOpen: boolean;
  isLoading?: boolean;
  className?: string;
}

export function SelectIcon({ isOpen, isLoading, className }: SelectIconProps) {
  return (
    <Icon
      name={match<{ isOpen: boolean; isLoading?: boolean }, IconName>({
        isOpen,
        isLoading,
      })
        .with({ isLoading: true }, () => 'spinner')
        .with({ isOpen: true }, () => 'upChevron')
        .otherwise(() => 'downChevron')}
      className={className}
    />
  );
}
