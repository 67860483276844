import { z } from 'zod';
import { GraphQLClient } from 'graphql-request';
import { CalendarDate } from '@internationalized/date';
import { GetEligibilityContextDocument } from '../graphql/operations';
import { serverDateSchema } from '../schemas/dateSchema';
import { NoGenresFoundError } from '../errors/NoGenresFoundError';
import { NoLabelsFoundError } from '../errors/NoLabelsFoundError';
import { NoDistributorsFoundError } from '../errors/NoDistributorsFoundError';

export const createGetEligibilityContextPromise =
  (client: GraphQLClient) => async () => {
    const result = await client.request(GetEligibilityContextDocument);

    const genres = result.genres;
    if (!Array.isArray(genres) || genres.length === 0) {
      throw new NoGenresFoundError();
    }

    const labels = result.labels;
    if (!Array.isArray(labels) || labels.length === 0) {
      throw new NoLabelsFoundError();
    }

    const distributors = result.distributors;
    if (!Array.isArray(distributors) || distributors.length === 0) {
      throw new NoDistributorsFoundError();
    }

    return {
      genres,
      labels,
      distributors,
      ...z
        .object({
          eligibilityCutoffDate: serverDateSchema,
          releasesScannedDivisor: z.number(),
          tracksDownloadedDivisor: z.number(),
          tracksStreamedDivisor: z.number(),
        })
        .parse(result),
    };
  };

export type GetEligibilityContextPromise = ReturnType<
  typeof createGetEligibilityContextPromise
>;
export type GetEligibilityContextPromiseResult = Awaited<
  ReturnType<GetEligibilityContextPromise>
>;

export const mockDoneData: GetEligibilityContextPromiseResult = {
  eligibilityCutoffDate: new CalendarDate(2018, 12, 31),
  releasesScannedDivisor: 1,
  tracksDownloadedDivisor: 1,
  tracksStreamedDivisor: 1,
  genres: [
    { id: '1', title: 'Pop', minimumSales: 1, maximumSales: 5 },
    { id: '2', title: 'Rock', minimumSales: 1, maximumSales: 5 },
  ],
  labels: [{ id: '1', name: 'Test Label' }],
  distributors: [{ id: '1', name: 'Test Distributor' }],
};

export const mockErrorData: unknown = new Error('This is a mock error.');
