import { createMachine, send, actions, assign } from 'xstate';
import { statusResponseSchema } from '../schemas/statusResponse';
import { Roles } from '../schemas/role';
import { MinimalArtist } from '../schemas/minimalArtistSchema';

const fetchStatus = async () => {
  const result = await fetch(`${import.meta.env.VITE_API_BASE}/status`, {
    method: 'POST',
    credentials: 'include',
  });

  return statusResponseSchema.parse(await result.json());
};

type Events =
  | { type: 'SIGNED_IN'; id: string; roles: Roles; artists: MinimalArtist[] }
  | { type: 'SIGNED_OUT' }
  | { type: 'NO_CONNECTION' };

type Services = {
  fetchStatus: { data: Awaited<ReturnType<typeof fetchStatus>> };
};

type Context = {
  id: string;
  roles: Roles;
  artists: MinimalArtist[];
};

const signIn = (
  id: string,
  roles: Roles,
  artists: MinimalArtist[]
): Events => ({
  type: 'SIGNED_IN',
  id,
  roles,
  artists,
});

const signOut = (): Events => ({ type: 'SIGNED_OUT' });

export const machine = createMachine(
  {
    predictableActionArguments: true,
    tsTypes: {} as import('./AuthStatusMachine.typegen').Typegen0,
    schema: {
      events: {} as Events,
      context: {} as Context,
      services: {} as Services,
    },
    context: {
      id: '',
      roles: [],
      artists: [],
    },
    initial: 'signed-out',
    type: 'parallel',
    states: {
      connection: {
        initial: 'ok',
        states: {
          ok: {
            on: {
              NO_CONNECTION: 'none',
            },
          },
          none: {
            on: {
              SIGNED_IN: 'ok',
              SIGNED_OUT: 'ok',
            },
          },
        },
      },
      status: {
        initial: 'initializing',
        states: {
          initializing: {},
          'signed-out': {},
          'signed-in': {
            entry: 'setContext',
            exit: 'resetContext',
          },
        },
        on: {
          // There are situations where a user may be signed in and
          // they are then signed into a different account by performing
          // some action. Redeeming invitations is one of those situations.
          // When that happens a SIGNED_IN event is sent to the machine and
          // we need to make sure that the signed-in state is exited then
          // entered so that resetContext and setContext are called. The
          // internal: false flag ensures that the exit/entry happens.
          SIGNED_IN: { target: '.signed-in', internal: false },
          SIGNED_OUT: '.signed-out',
        },
      },
      poll: {
        initial: 'fetching',
        states: {
          waiting: {
            after: {
              WAIT_TIME: 'fetching',
            },
          },
          fetching: {
            invoke: {
              id: 'fetchStatus',
              src: 'fetchStatus',
              onDone: {
                target: 'waiting',
                actions: 'sendUpdate',
              },
              onError: {
                target: 'waiting',
                actions: 'sendNoConnection',
              },
            },
          },
        },
      },
    },
  },
  {
    actions: {
      sendUpdate: actions.pure((_, { data }) =>
        data.message === 'signed-in'
          ? send(signIn(data.id, data.roles, data.artists))
          : send(signOut())
      ),
      sendNoConnection: send({ type: 'NO_CONNECTION' } satisfies Events),
      setContext: assign((_, { id, roles, artists }) => ({
        id,
        roles,
        artists,
      })),
      resetContext: assign((_) => ({ id: '', roles: [], artists: [] })),
    },
    delays: {
      WAIT_TIME: 10000,
    },
    services: {
      fetchStatus,
    },
  }
);
