import { match } from 'ts-pattern';
import { PasswordMachineActor } from '../../machines/RedeemInvitation/PasswordMachine';
import { PasswordController } from '../../components/RedeemInvitation/Password/PasswordController';
import {
  RedeemAccountInvitationMachineSender,
  RedeemAccountInvitationMachineState,
} from '../../machines/RedeemAccountInvitation/Machine';
import { AccountController } from '../../components/RedeemInvitation/Account/AccountController';
import { AccountMachineActor } from '../../machines/RedeemInvitation/AccountMachine';
import { ArtistController } from '../../components/RedeemAccountInvitation/Artist/ArtistController';
import { ArtistMachineActor } from '../../machines/RedeemAccountInvitation/ArtistMachine';
import { ManagementController } from '../../components/RedeemAccountInvitation/Management/ManagementController';
import { ManagementMachineActor } from '../../machines/RedeemAccountInvitation/ManagementMachine';
import { Loading } from '../../components/Loading';
import { ErrorPage } from '../ErrorPage';

interface AccountCreationViewProps {
  state: RedeemAccountInvitationMachineState;
  send: RedeemAccountInvitationMachineSender;
}

export function RedeemAccountInvitationView({
  state,
}: AccountCreationViewProps) {
  return match(state)
    .when(
      () => state.matches('init.pending'),
      () => <Loading />
    )
    .when(
      () =>
        state.matches('init.rejected') || state.matches('redeeming.rejected'),
      () => <ErrorPage />
    )
    .when(
      () => state.matches('init.invalid-tag'),
      () => (
        <ErrorPage
          title="Invalid invitation tag provided."
          text="Please reach out to the Starmaker team for assistance."
        />
      )
    )
    .when(
      () => state.matches('redeeming.pending'),
      () => <Loading />
    )
    .when(
      () => state.matches('password'),
      () => (
        <PasswordController
          actor={state.children.passwordMachine as PasswordMachineActor}
        />
      )
    )
    .when(
      () => state.matches('account'),
      () => (
        <AccountController
          actor={state.children.accountMachine as AccountMachineActor}
        />
      )
    )
    .when(
      () => state.matches('artist'),
      () => (
        <ArtistController
          actor={state.children.artistMachine as ArtistMachineActor}
        />
      )
    )
    .when(
      () => state.matches('management'),
      () => (
        <ManagementController
          actor={state.children.managementMachine as ManagementMachineActor}
        />
      )
    )
    .run();
}
