import { match } from 'ts-pattern';
import classNames from 'classnames';
import { Button } from '../../Button';
import { PageHeader } from '../../PageHeader';
import { FormField } from '../../FormField';
import { TextInput } from '../../TextInput';
import { Feedback } from '../../Feedback';
import { Link } from '../../Link';
import {
  EmailInvitationMachineState,
  EmailInvitationMachineSender,
} from '../../../machines/Eligibility/EmailInvitationMachine';
import { Form } from '../../Form';
import { Loading } from '../../Loading';
import { ErrorFeedback } from '../../ErrorFeedback';
import { FormControls } from '../../FormControls';

import styles from './SummaryView.module.scss';

interface SummaryViewProps {
  state: EmailInvitationMachineState;
  send: EmailInvitationMachineSender;
}

export const SummaryView = ({ state, send }: SummaryViewProps) => {
  return match(state)
    .when(
      () => state.matches('ready'),
      () => (
        <Form
          className={styles.container}
          onSubmit={() => {
            send('SUBMIT');
          }}
        >
          <PageHeader
            heading="You are eligible to apply for Starmaker funding"
            paginationSteps={5}
            paginationActive={5}
            includeBackButton={state.matches('ready.action.create')}
            backButtonOnClick={() => send('BACK')}
          />
          <Feedback
            type="positive"
            title="Enter your email address to continue."
          >
            <p>
              We’ll send an email to the address you provide with a link to
              confirm.
            </p>
            <p>
              Already have an account?{' '}
              <span>
                <Link href="/sign-in">Sign in</Link>.
              </span>
            </p>
          </Feedback>
          <div className={classNames(styles.formFields, 'max-w-md')}>
            <FormField
              id="email"
              label="Email Address"
              formFieldStatus={
                state.matches('ready.email.error') ? 'error' : 'default'
              }
            >
              <TextInput
                id="email"
                label="Email"
                value={state.context.email}
                onChange={(email) => send({ type: 'SET_EMAIL', email })}
                type="email"
                inputMode="email"
              />
            </FormField>
          </div>
          <FormControls>
            {state.matches('ready.email.error.empty') && (
              <ErrorFeedback type="noEmail" />
            )}
            {state.matches('ready.email.error.badFormat') && (
              <ErrorFeedback type="badEmail" />
            )}
            <Button
              className="max-w-md"
              label="Create an Account"
              onClick={() => send({ type: 'SUBMIT' })}
            />
          </FormControls>
        </Form>
      )
    )
    .when(
      () => state.matches('creatingInvitation'),
      () => <Loading />
    )
    .when(
      () => state.matches('updatingInvitation'),
      () => <Loading />
    )
    .when(
      () => state.matches('complete'),
      () => (
        <div className={styles.container}>
          <PageHeader heading="Check your email" />
          <div className="max-w-lg">
            <Feedback
              type="positive"
              title={`Follow the link sent to ${state.context.email}`}
              icon="mail"
            >
              <div className={styles.completeFeedback}>
                Didn’t get the link? Please check again.
                <Button
                  buttonClassName={styles.resendButton}
                  label="Resend Email"
                  variant="secondary"
                  onClick={() => send({ type: 'BACK' })}
                />
              </div>
            </Feedback>
          </div>
        </div>
      )
    )
    .when(
      () => state.matches('createError'),
      () => (
        <div className={styles.container}>
          <PageHeader heading="An unexpected error occured" />
          <div className="max-w-lg">
            <Feedback
              type="warning"
              title="An unexpected error occured when attempting to create your invitation."
              icon="x"
            >
              You may go back and try again.
              <Button
                label="Go Back"
                variant="secondary"
                onClick={() => send({ type: 'BACK' })}
              />
            </Feedback>
          </div>
        </div>
      )
    )
    .when(
      () => state.matches('updateError'),
      () => (
        <div className={styles.container}>
          <PageHeader heading="An unexpected error occured" />
          <div className="max-w-lg">
            <Feedback
              type="warning"
              title="An unexpected error occured when attempting to resend your invitation."
              icon="x"
            >
              You may go back and try again.
              <Button
                label="Go Back"
                variant="secondary"
                onClick={() => send({ type: 'BACK' })}
              />
            </Feedback>
          </div>
        </div>
      )
    )
    .run();
};
