import { GraphQLClient } from 'graphql-request';
import {
  CreateContactDocument,
  CreateContactMutationVariables,
} from '../../graphql/operations';
import { z } from 'zod';

export const createAddContactPromise =
  (client: GraphQLClient) =>
  async (variables: CreateContactMutationVariables) => {
    const contact = (await client.request(CreateContactDocument, variables))
      .createContact?.contact?.email;

    return z.string().parseAsync(contact);
  };

export type AddContactPromise = ReturnType<typeof createAddContactPromise>;

export type AddContactPromiseResult = Awaited<ReturnType<AddContactPromise>>;
