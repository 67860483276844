import { Sidebar } from '../../components/Sidebar';
import { Button } from '../../components/Button';
import { PageContainer } from '../../components/PageContainer';
import { Block } from '../../components/Block';
import { Breadcrumbs } from '../../components/Breadcrumbs';
import { AdminBoardMeetingBlockController } from '../../components/Admin/BoardMeetingBlock/AdminBoardMeetingBlockController';
import {
  BoardMeetingMachineSender,
  BoardMeetingMachineState,
  Context,
} from '../../machines/BoardMeeting/BoardMeetingMachine';
import { ReadOnlyBlock } from './ReadOnlyBlock';

import styles from './BoardMeetingDashboard.module.scss';

interface BoardMeetingDashboardProps {
  round: NonNullable<Context['round']>;
  state: BoardMeetingMachineState;
  send: BoardMeetingMachineSender;
}

export function BoardMeetingDashboard({
  round,
  state,
  send,
}: BoardMeetingDashboardProps) {
  const { title, numberOfCoreApplications, numberOfOrionApplications } = round;
  const { isAdmin, readOnlyBlocks, adminBlocks, addBlockRef } = state.context;
  const applicationsUrl = isAdmin
    ? `/admin/search-applications?roundTitle=${round.title}`
    : 'applications';

  return (
    <PageContainer>
      <div className={styles.titleContainer}>
        {isAdmin && (
          <Breadcrumbs
            breadcrumbs={[
              { text: 'Admin Dashboard', to: '/admin' },
              { text: 'Funding Rounds', to: '/admin/rounds' },
            ]}
          />
        )}
        <h1 className={styles.title}>{`Round ${title} Board Meeting`}</h1>
      </div>
      <div className={styles.page}>
        <Sidebar
          links={[...readOnlyBlocks, ...adminBlocks.map(({ block }) => block)]
            .filter(({ title }) => title)
            .map(({ id, title }) => ({ href: `#${id}`, title }))}
          linksClassName={styles.sidebarLinks}
          className={styles.sidebar}
        >
          <div className={styles.sidebarBlock}>
            <Button
              variant="secondary"
              linkTo={applicationsUrl}
              label="View Applications"
            />
            <p className={styles.count}>
              {numberOfCoreApplications} Core / {numberOfOrionApplications}{' '}
              Orion
            </p>
          </div>
        </Sidebar>
        <div className={styles.container}>
          <>
            {isAdmin ? (
              <>
                {adminBlocks.map(({ ref, block }, index, self) => (
                  <AdminBoardMeetingBlockController
                    actor={ref}
                    key={block.id}
                    isFirstBlock={index === 0}
                    isLastBlock={index === self.length - 1}
                  />
                ))}
                {addBlockRef && (
                  <AdminBoardMeetingBlockController actor={addBlockRef} />
                )}
              </>
            ) : (
              readOnlyBlocks.map((block) => (
                <ReadOnlyBlock
                  block={block}
                  key={block.id}
                  onDownload={(file) => {
                    send({
                      type: 'DOWNLOAD_ATTACHMENT',
                      file,
                      blockId: block.id,
                    });
                  }}
                />
              ))
            )}
          </>
          <Block
            headerText="Applications"
            iconName="file"
            footerClassName={styles.footer}
            footer={
              <Button linkTo={applicationsUrl} label="View Applications" />
            }
          >
            <div className={styles.applications}>
              <p>{numberOfCoreApplications} Core Applications</p>
              <p>{numberOfOrionApplications} Orion Applications</p>
            </div>
          </Block>
        </div>
      </div>
    </PageContainer>
  );
}
