import cn from 'classnames';
import styles from './TextArea.module.scss';

interface TextAreaProps {
  id: string;
  label: string;
  value: string;
  size: 'small' | 'large' | 'huge';
  onFocus?: () => void;
  onChange: (val: string) => void;
  onBlur?: () => void;
  disabled?: boolean;
  error?: boolean;
  className?: string;
  textAreaClassName?: string;
  formFieldStatus?: 'default' | 'text' | 'error';
}

export function TextArea({
  id,
  label,
  value,
  size,
  onFocus,
  onChange,
  onBlur,
  disabled = false,
  error,
  className,
  textAreaClassName,
  formFieldStatus = 'default',
}: TextAreaProps) {
  return (
    <div
      className={cn(
        {
          [styles.error]: error || formFieldStatus === 'error',
          [styles.disabled]: disabled,
        },
        className
      )}
    >
      <textarea
        id={id}
        className={cn(styles.textArea, textAreaClassName)}
        value={value}
        onFocus={onFocus}
        onChange={(e) => onChange(e.target.value)}
        onBlur={onBlur}
        disabled={disabled}
        aria-label={label}
        rows={size === 'small' ? 3 : size === 'large' ? 6 : 9}
      ></textarea>
    </div>
  );
}
