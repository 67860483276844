import { GraphQLClient } from 'graphql-request';
import {
  BoardMemberInvitationStatusResponseCode,
  GetRedeemBoardInvitationContextDocument,
  GetRedeemBoardInvitationContextQueryVariables,
} from '../graphql/operations';

import { InvitationInvalidStatusError } from '../errors/InvitationInvalidStatusError';
import { redeemBoardMemberInvitationContextSchema } from '../schemas/redeemBoardMemberInvitationContextSchema';

export const createGetRedeemBoardInvitationContext =
  (
    client: GraphQLClient,
    variables: GetRedeemBoardInvitationContextQueryVariables
  ) =>
  async () => {
    const response = await client.request(
      GetRedeemBoardInvitationContextDocument,
      variables
    );

    const {
      status: { code, email },
      minimumPasswordLength,
    } = redeemBoardMemberInvitationContextSchema.parse(response);

    if (
      code !== BoardMemberInvitationStatusResponseCode.Success &&
      code !== BoardMemberInvitationStatusResponseCode.Linkable
    ) {
      throw new InvitationInvalidStatusError();
    }

    return {
      tag: variables.tag,
      link: code === BoardMemberInvitationStatusResponseCode.Linkable,
      email,
      minimumPasswordLength,
    };
  };

export type GetRedeemBoardInvitationContextPromise = ReturnType<
  typeof createGetRedeemBoardInvitationContext
>;
export type GetRedeemBoardInvitationContextPromiseResult = Awaited<
  ReturnType<GetRedeemBoardInvitationContextPromise>
>;
