import { GraphQLClient } from 'graphql-request';
import {
  UpdateSingleDocument,
  UpdateSingleByIdInput,
} from '../graphql/operations';

export const createUpdateSinglePromise =
  (client: GraphQLClient) => (input: UpdateSingleByIdInput) =>
    client
      .request(UpdateSingleDocument, { input })
      .then((response) => ({ single: response.result?.single }));

export type UpdateSinglePromise = ReturnType<typeof createUpdateSinglePromise>;
export type UpdateSinglePromiseResult = Awaited<
  ReturnType<UpdateSinglePromise>
>;
