import { ReactNode } from 'react';
import { Icon, IconName } from './Icon';
import { Button, ButtonVariant } from './Button';
import styles from './GenericPreview.module.scss';

interface Props {
  iconName: IconName;
  iconAriaLabel: string;
  onIconClick?: () => void;
  title: string;
  onTitleClick?: () => void;
  note: string;
  buttonVariant?: ButtonVariant;
  buttonLabel?: string;
  buttonStartIcon?: IconName;
  onButtonClick?: () => void;
  secondaryButtonVariant?: ButtonVariant;
  secondaryButtonLabel?: string;
  secondaryButtonStartIcon?: IconName;
  onSecondaryButtonClick?: () => void;
  children?: ReactNode;
  isButtonDisabled?: boolean;
}

export function GenericPreview({
  iconName,
  iconAriaLabel,
  title,
  note,
  buttonVariant,
  buttonLabel,
  buttonStartIcon,
  onIconClick,
  onTitleClick,
  onButtonClick,
  secondaryButtonVariant,
  secondaryButtonLabel,
  secondaryButtonStartIcon,
  onSecondaryButtonClick,
  children,
  isButtonDisabled,
}: Props) {
  return (
    <div className={styles.outerContainer}>
      <div className={styles.innerContainer}>
        <div className={styles.content}>
          <Icon
            className={styles.icon}
            name={iconName}
            onClick={() => onIconClick && onIconClick()}
            ariaLabel={iconAriaLabel}
          />
          <div className={styles.info}>
            <button
              type="button"
              className={styles.title}
              onClick={() => onTitleClick && onTitleClick()}
            >
              {title}
            </button>
            <div className={styles.note}>{note}</div>
          </div>
        </div>
        <div className={styles.buttons}>
          {buttonLabel && onButtonClick && (
            <Button
              size="small"
              startIcon={buttonStartIcon}
              variant={buttonVariant}
              label={buttonLabel}
              onClick={() => onButtonClick()}
              disabled={isButtonDisabled}
            />
          )}
          {secondaryButtonLabel && onSecondaryButtonClick && (
            <Button
              size="small"
              startIcon={secondaryButtonStartIcon}
              variant={secondaryButtonVariant}
              label={secondaryButtonLabel}
              onClick={() => onSecondaryButtonClick()}
              disabled={isButtonDisabled}
            />
          )}
        </div>
      </div>
      {children}
    </div>
  );
}
