import { useActor } from '@xstate/react';
import { ReleaseSalesMachineActor } from '../../../machines/Release/ReleaseSalesMachine';
import { SalesView } from './SalesView';

export function SalesController({
  actor,
}: {
  actor: ReleaseSalesMachineActor;
}) {
  const [state, send] = useActor(actor);

  return <SalesView state={state} send={send} />;
}
