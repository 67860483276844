import z from 'zod';
import { GraphQLClient } from 'graphql-request';
import { DeleteFundingRoundDocument } from '../../graphql/operations';

export const createDeleteFundingRoundPromise =
  (client: GraphQLClient) =>
  async (_: unknown, { id }: { id: string }) => {
    const { result } = await client.request(DeleteFundingRoundDocument, {
      id,
    });
    return z.object({ id: z.string() }).parse(result?.round);
  };

export type DeleteFundingRoundPromise = ReturnType<
  typeof createDeleteFundingRoundPromise
>;

export type DeleteFundingRoundPromiseResult = Awaited<
  ReturnType<DeleteFundingRoundPromise>
>;
