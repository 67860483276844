import cn from 'classnames';

import { Button } from '../Button';
import { Link } from '../Link';

import styles from './CTA.module.scss';

export function HowToApply() {
  return (
    <div className={cn(styles.cta)}>
      <div className="max-w-xl">
        <div className={styles.content}>
          <div className={styles.header}>
            <h2>How to Apply?</h2>
            <p>
              All applications are to be completed online via the Canadian
              Starmaker Fund website. To see a sample application and learn more
              about the fund, visit the{' '}
              <Link href="/faq" className={styles.link}>
                FAQs
              </Link>{' '}
              or{' '}
              <Link href="/contact" className={styles.link}>
                Contact Us
              </Link>{' '}
              pages.
            </p>
          </div>
          <div className={styles.buttons}>
            <Button
              size="small"
              label="Start Core Funding Application"
              buttonClassName={styles.button}
              linkTo="/eligibility"
            />
            <Button
              size="small"
              label="Start Orion Application"
              buttonClassName={styles.button}
              linkTo="/eligibility/orion"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
