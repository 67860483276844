import { GraphQLClient } from 'graphql-request';
import { GetDashboardContextDocument } from '../graphql/operations';
import { IdNotFoundError } from '../errors/IdNotFoundError';
import { dashboardContextSchema } from '../schemas/dashboard/dashboardContextSchema';

export const createGetDashboardContextPromise =
  (client: GraphQLClient, artistId: string) => async () => {
    const result = await client.request(GetDashboardContextDocument, {
      artistId,
    });

    if (!result.artist) {
      throw new IdNotFoundError();
    }

    return dashboardContextSchema.parse({
      releases: result.artist.releases ?? [],
      accounts: result.artist.accountBindings ?? [],
      ...result,
    });
  };

export type GetDashboardContextPromise = ReturnType<
  typeof createGetDashboardContextPromise
>;
export type GetDashboardContextPromiseResult = NonNullable<
  Awaited<ReturnType<GetDashboardContextPromise>>
>;
