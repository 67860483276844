import cn from 'classnames';
import { match } from 'ts-pattern';
import { Feedback } from '../../../../components/Feedback';
import { FormField } from '../../../FormField';
import { TextInput } from '../../../TextInput';
import { Button } from '../../../Button';
import { Link } from '../../../Link';
import {
  EmailInvitationMachineSender,
  EmailInvitationMachineState,
} from '../../../../machines/Eligibility/EmailInvitationMachine';
import { PageHeader } from '../../../PageHeader';
import { ErrorFeedback } from '../../../ErrorFeedback';
import { Form } from '../../../Form';

import styles from './EmailView.module.scss';

interface EmailViewProps {
  state: EmailInvitationMachineState;
  send: EmailInvitationMachineSender;
}

export function EmailView({ state, send }: EmailViewProps) {
  return match(state)
    .when(
      (state) => state.matches('createError'),
      () => (
        <div className={styles.container}>
          <PageHeader
            includeBackButton={true}
            backButtonOnClick={() => send('BACK')}
            isOrion={true}
          />
          <Feedback
            icon="x"
            type="warning"
            title="An unexpected error occured when creating your invitation."
          >
            You may go back and try again.
            <Button
              variant="secondary"
              label="Go back"
              onClick={() => send('BACK')}
            />
          </Feedback>
        </div>
      )
    )
    .when(
      (state) => state.matches('complete'),
      () => (
        <div className={styles.container}>
          <PageHeader
            includeBackButton={false}
            backButtonOnClick={() => send('BACK')}
            isOrion={true}
          >
            <h1 className={styles.heading}>Check your email</h1>
          </PageHeader>
          <Feedback
            icon="mail"
            type="positive"
            title={`Follow the link sent to ${state.context.email}`}
          >
            <div className={styles.completeFeedback}>
              Didn’t get the link? Please check again.
              <Button
                buttonClassName={styles.resendButton}
                label="Resend Email"
                variant="secondary"
                onClick={() => send({ type: 'BACK' })}
              />
            </div>
          </Feedback>
        </div>
      )
    )
    .otherwise(() => (
      <Form className={styles.container} onSubmit={() => send('SUBMIT')}>
        <PageHeader
          includeBackButton={true}
          backButtonOnClick={() => send('BACK')}
          isOrion={true}
        >
          The Orion program is a funding stream dedicated to supporting music
          creation by members of the Canadian music industry who are Black,
          Indigenous, and People of Colour.
        </PageHeader>
        <div className={cn('max-w-md', styles.list)}>
          <p>In order to apply for funding, we ask that you:</p>
          <ol>
            <li>
              Submit the form below and follow the link sent to your email.
            </li>
            <li>
              Send documentation that you meet the{' '}
              <span className={styles.plainHref}>
                <Link href="/requirements">eligibility requirements</Link>
              </span>{' '}
              to the Starmaker team, including cumulative sales of at least
              1,500 units or one of the following: Juno, Grammy or Polaris Prize
              shortlist
            </li>
            <li>You will be contacted when your account has been reviewed.</li>
          </ol>
        </div>

        <Feedback type="positive" title="Enter your email address to continue.">
          <p>
            We’ll send an email to the address you provide with a link to
            confirm.
          </p>
          <p>
            Already have an account?{' '}
            <span>
              <Link href="/sign-in">Sign in</Link>.
            </span>
          </p>
        </Feedback>
        <div className="max-w-md">
          <div className={styles.list}>
            <FormField
              label="Email"
              formFieldStatus={
                state.matches('ready.email.error') ? 'error' : 'default'
              }
            >
              <TextInput
                id="email"
                label="Email"
                value={state.context.email}
                onChange={(email) => send({ type: 'SET_EMAIL', email })}
                type="email"
                inputMode="email"
              />
            </FormField>
          </div>
        </div>
        {state.matches('ready.email.error.empty') && (
          <ErrorFeedback type="noEmail" />
        )}
        {state.matches('ready.email.error.badFormat') && (
          <ErrorFeedback type="badEmail" />
        )}
        <div className="max-w-md">
          <Button label="Create an Account" type="submit" />
        </div>
      </Form>
    ));
}
