import { TourDate } from '../schemas/tourDates/tourDateSchema';

export type CloseTourDate = {
  type: 'CLOSE_TOUR_DATE';
  id?: TourDate['id'];
};

export const closeTourDate = (id?: CloseTourDate['id']): CloseTourDate => ({
  type: 'CLOSE_TOUR_DATE',
  id,
});
