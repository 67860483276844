import { match } from 'ts-pattern';
import { ReleaseSingleMachineState } from '../../../machines/Release/ReleaseSingleMachine';
import { FormField } from '../../FormField';
import { InlineFormContainer } from '../../InlineFormContainer';
import { InlineFormHeading } from '../../InlineFormHeading';
import { TextInput } from '../../TextInput';

interface SingleFormProps {
  type: 'add' | 'edit';
  state: ReleaseSingleMachineState;
  onTitleUpdate: (title: string) => void;
  onSpinsUpdate: (spins: string) => void;
  onTitleBlur: () => void;
  onSpinsBlur: () => void;
  onSaveClick: () => void;
  onCancelClick: () => void;
}

export function SingleForm({
  type,
  state,
  onTitleUpdate,
  onSpinsUpdate,
  onTitleBlur,
  onSpinsBlur,
  onSaveClick,
  onCancelClick,
}: SingleFormProps) {
  const { title = '', spins = '' } = state.context;

  return (
    <InlineFormContainer
      primaryButtonText={'Save'}
      primaryButtonOnClick={onSaveClick}
      primaryButtonDisabled={false}
      secondaryButtonText="Cancel"
      secondaryButtonOnClick={onCancelClick}
    >
      <InlineFormHeading>
        {type === 'add' ? 'Add Single' : 'Edit Single'}
      </InlineFormHeading>
      <FormField
        id="title"
        label="Single title"
        formFieldStatus={state.matches('title.error') ? 'error' : 'default'}
        feedbackType={state.matches('title.error') ? 'warning' : 'neutral'}
        infoLabel={match(state)
          .when(
            (state) => state.matches('title.error.empty'),
            () => 'This field is required.'
          )
          .otherwise(() => undefined)}
      >
        <TextInput
          id="company"
          label="company"
          value={title}
          onChange={onTitleUpdate}
          onBlur={onTitleBlur}
        />
      </FormField>
      <FormField
        id="spins"
        label="Spins at radio"
        formFieldStatus={state.matches('spins.error') ? 'error' : 'default'}
        feedbackType={state.matches('spins.error') ? 'warning' : 'neutral'}
        infoLabel={match(state)
          .when(
            (state) => state.matches('spins.error.empty'),
            () => 'This field is required.'
          )
          .when(
            (state) => state.matches('spins.error.incorrectType'),
            () => 'This field must be a number.'
          )
          .otherwise(() => undefined)}
      >
        <TextInput
          id="spins"
          label="spins"
          value={String(spins)}
          onChange={(spins) => onSpinsUpdate(spins)}
          onBlur={onSpinsBlur}
        />
      </FormField>
    </InlineFormContainer>
  );
}
