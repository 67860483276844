import { Icon, IconName } from '../Icon';
import cn from 'classnames';

import styles from './RequirementCheck.module.scss';

interface RequirementCheckProps {
  icon: IconName;
  checkedLabel: string;
  uncheckedLabel: string;
  isChecked: boolean;
  details?: string;
  isOptional?: boolean;
}

export function RequirementCheck({
  icon,
  checkedLabel,
  uncheckedLabel,
  isChecked,
  isOptional,
  details,
}: RequirementCheckProps) {
  return (
    <div className={styles.bubbleRow}>
      <Icon
        className={styles.icon}
        name={icon}
        bubble={true}
        height={16}
        width={16}
      />
      <p>
        <span className={cn({ 'body-text-bold': !isChecked && !isOptional })}>
          {isChecked ? checkedLabel : uncheckedLabel}
        </span>
        {details && <span> {details}</span>}
      </p>
    </div>
  );
}
