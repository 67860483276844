import z from 'zod';
import { GraphQLClient } from 'graphql-request';
import { DeleteBoardMeetingBlockDocument } from '../../graphql/operations';
import { Context } from '../../machines/BoardMeeting/BoardMeetingMachine';
import { BoardMeetingBlock } from '../../schemas/boardMeetingBlockSchema';
import { deleteFile } from '../Attachments/utils';

export const createDeleteBoardMeetingBlockPromise =
  (client: GraphQLClient) =>
  async (_: Context, event: { block: BoardMeetingBlock }) => {
    const { attachments, id } = event.block;

    await Promise.all(
      attachments.map(({ name }) =>
        deleteFile({
          referenceId: id,
          directory: 'board-meeting-attachments',
          fileName: name,
        })
      )
    );

    const { result } = await client.request(DeleteBoardMeetingBlockDocument, {
      id,
    });

    return z.object({ id: z.string() }).parse(result?.block);
  };

export type DeleteBoardMeetingBlockPromise = ReturnType<
  typeof createDeleteBoardMeetingBlockPromise
>;
export type DeleteBoardMeetingBlockPromiseResult = Awaited<
  ReturnType<DeleteBoardMeetingBlockPromise>
>;
