import type { EventFrom, Sender } from 'xstate';
import { createMachine } from 'xstate';
import type { StateFrom } from '../utils/StateFrom';
import type { MachineOptionsWithContextFrom } from '../utils/MachineOptionsWithContextFrom';
import { UnsubscribeFromNewsletterPromiseResult } from '../promises/createUnsubscribeFromNewsletterPromise';

type Services = {
  unsubscribeFromNewsletter: {
    data: UnsubscribeFromNewsletterPromiseResult;
  };
};

export type Context = {
  tag: string;
};

export const machine = createMachine({
  predictableActionArguments: true,
  tsTypes: {} as import('./UnsubscribeMachine.typegen').Typegen0,
  schema: {
    context: {} as Context,
    services: {} as Services,
  },
  initial: 'init',
  states: {
    init: {
      invoke: {
        id: 'unsubscribeFromNewsletter',
        src: 'unsubscribeFromNewsletter',
        onDone: 'done',
        onError: 'error',
      },
    },
    done: {},
    error: {},
  },
});

type Machine = typeof machine;

export type UnsubscribeMachineState = StateFrom<Machine>;
export type UnsubscribeMachineSender = Sender<EventFrom<Machine>>;
export type UnsubscribeMachineOptions = MachineOptionsWithContextFrom<Machine>;
