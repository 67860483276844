import { FundingRequest } from '../schemas/fundingRequests/fundingRequestSchema';

export type EditContext = {
  id: FundingRequest['id'];
  cost: FundingRequest['cost'];
};

export type RefreshFundingRequestTotals = {
  type: 'REFRESH_FUNDING_REQUEST_TOTALS';
  year: number;
  budgetForFundingRequests: number;
  editContext?: EditContext;
};

export const refreshFundingRequestTotals = (
  year: number,
  budgetForFundingRequests: number,
  editContext?: EditContext
): RefreshFundingRequestTotals => ({
  type: 'REFRESH_FUNDING_REQUEST_TOTALS',
  year,
  budgetForFundingRequests,
  editContext,
});

export const createEditContext = (
  request?: FundingRequest
): EditContext | undefined =>
  request !== undefined
    ? {
        id: request.id,
        cost: request.cost,
      }
    : undefined;
