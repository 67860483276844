import { z } from 'zod';
import { rolesSchema as roles } from './role';
import { minimalArtistSchema } from './minimalArtistSchema';

export const signInSuccessResponseSchema = z.object({
  id: z.string(),
  roles,
  artists: minimalArtistSchema.array(),
  message: z.literal('signed-in'),
});

export const signInResponseSchema = z.union([
  signInSuccessResponseSchema,
  z.object({
    message: z.union([
      z.literal('internal-error'),
      z.literal('incorrect-credentials'),
    ]),
  }),
]);
