import { CalendarDate } from '@internationalized/date';
import { Feedback } from '../Feedback';
import { formatCalendarDate } from '../../utils/formatCalendarDate';

interface SubmittedAplicationFeedbackProps {
  fundingType: string;
  roundTitle: string;
  boardMeetingDate: CalendarDate;
}

export function SubmittedAplicationFeedback({
  fundingType,
  roundTitle,
  boardMeetingDate,
}: SubmittedAplicationFeedbackProps) {
  return (
    <Feedback
      type="primary"
      title={`Your ${fundingType} Application has been submitted for round #${roundTitle}.`}
      icon="checkCircle"
    >
      {`You will be notified of the board's decision after the board meeting,
          which takes place on ${formatCalendarDate(boardMeetingDate)}.`}
    </Feedback>
  );
}
