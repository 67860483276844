import { GraphQLClient } from 'graphql-request';
import { CreateNewFundingRoundDocument } from '../../graphql/operations';
import { fundingRoundSchema } from '../../schemas/fundingRound/fundingRoundSchema';
import { AddFundingRound } from '../../events/AddFundingRound';
import { prepareFundingRound } from './prepareFundingRound';

export const createAddFundingRoundPromise =
  (client: GraphQLClient) =>
  async (_: unknown, { round }: AddFundingRound) => {
    const request = await client.request(CreateNewFundingRoundDocument, {
      round: prepareFundingRound(round),
    });

    return fundingRoundSchema.parse(request.createFundingRound?.round);
  };

export type AddFundingRoundPromise = ReturnType<
  typeof createAddFundingRoundPromise
>;

export type AddFundingRoundPromiseResult = Awaited<
  ReturnType<AddFundingRoundPromise>
>;
