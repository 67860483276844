import { useRef } from 'react';
import { useDateSegment } from 'react-aria';
import { DateFieldState, DateSegment as DateSegmentType } from 'react-stately';

import styles from './DateSegment.module.scss';

interface DateSegmentProps {
  segment: DateSegmentType;
  state: DateFieldState;
}

export function DateSegment({ segment, state }: DateSegmentProps) {
  const ref = useRef(null);
  const { segmentProps } = useDateSegment(segment, state, ref);

  return (
    <div {...segmentProps} ref={ref} className={styles.segment}>
      {segment.text}
    </div>
  );
}
