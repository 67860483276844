import { Icon, IconName } from './Icon';
import { Link } from './Link';

import styles from './LinkGroup.module.scss';

interface LinkGroupProps {
  title?: string;
  icon: IconName;
  links: { text: string; href: string }[];
  onLinkClicked: () => void;
}

export function LinkGroup({
  title,
  icon,
  links,
  onLinkClicked,
}: LinkGroupProps) {
  if (links.length === 1) {
    return (
      <div className={styles.row}>
        <Icon name={icon} />
        <Link href={links[0].href} onClick={onLinkClicked} plain={true}>
          {links[0].text}
        </Link>
      </div>
    );
  } else {
    return (
      <div className={styles.container}>
        {links.length > 1 && (
          <h3 className={styles.row}>
            <Icon name={icon} />
            <div className={styles.title}>{title}</div>
          </h3>
        )}
        <ul className={styles.rowList}>
          {links.map((link) => (
            <li key={link.text} className={styles.row}>
              <div className={styles.line} />
              <Link href={link.href} onClick={onLinkClicked} plain={true}>
                {link.text}
              </Link>
            </li>
          ))}
        </ul>
      </div>
    );
  }
}
