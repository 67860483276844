import { useRef } from 'react';
import { useCalendarCell } from 'react-aria';
import { CalendarDate, isSameMonth } from '@internationalized/date';
import { CalendarState } from 'react-stately';
import cn from 'classnames';

import styles from './CalendarCell.module.scss';

interface CalendarCellProps {
  state: CalendarState;
  date: CalendarDate;
  currentMonth: CalendarDate;
  active: boolean;
}

export function CalendarCell({
  state,
  date,
  currentMonth,
  active,
}: CalendarCellProps) {
  const ref = useRef(null);
  const { cellProps, buttonProps, isDisabled, formattedDate } = useCalendarCell(
    { date },
    state,
    ref
  );

  const isOutsideMonth = !isSameMonth(currentMonth, date);

  return (
    <td
      {...cellProps}
      className={cn({
        [styles.cell]: !isDisabled,
        [styles.active]: active && !isOutsideMonth,
      })}
      {...buttonProps}
    >
      <button className={styles.button} hidden={isOutsideMonth} type="button">
        {formattedDate}
      </button>
    </td>
  );
}
