import { z } from 'zod';
import { AccountInvitationStatusResponseCode } from '../graphql/operations';

export const accountInvitationStatusSchema = z.object({
  code: z.nativeEnum(AccountInvitationStatusResponseCode),
  // The email can be an empty string on failure so don't validate it as
  // an email. At this point it will have been validated anyway and we are
  // purely using this value for display purposes.
  email: z.string(),
  isOrion: z.boolean(),
});

export type AccountInvitationStatus = z.infer<
  typeof accountInvitationStatusSchema
>;
