import { FundingRequest } from '../schemas/fundingRequests/fundingRequestSchema';

export type CloseFundingRequest = {
  type: 'CLOSE_REQUEST';
  id?: FundingRequest['id'];
};

export const closeFundingRequest = (
  id?: CloseFundingRequest['id']
): CloseFundingRequest => ({
  type: 'CLOSE_REQUEST',
  id,
});
