import { approvedArtistsByRound } from '../static/approvedArtistsByRound';
import { Card } from '../components/Card';
import { Footer } from '../components/Footer';
import { HeroSection } from '../components/HeroSection';
import { PageContainer } from '../components/PageContainer';

import styles from './Finances.module.scss';

const years = [
  '2020-2021',
  '2019-2020',
  '2018-2019',
  '2017-2018',
  '2016-2017',
  '2015-2016',
  '2014-2015',
  '2013-2014',
  '2012-2013',
  '2011-2012',
  '2010-2011',
  '2009-2010',
  '2008-2009',
  '2007-2008',
  '2006-2007',
  '2005-2006',
  '2004-2005',
  '2003-2004',
  '2002-2003',
];

export function Finances() {
  return (
    <div className={styles.container}>
      <HeroSection
        title="Financial Statements"
        breadcrumbs={[{ text: 'Home', to: '/' }]}
      />
      <PageContainer>
        <h2>Annual Reports</h2>
        <div className={styles.grid}>
          {years.map((year) => (
            <Card
              key={year}
              title={year}
              buttonIcon="download"
              buttonLabel="Download PDF"
              buttonLinkTo={`https://files.starmaker.ca/reports/rsf-annual-report-${year}.pdf`}
            />
          ))}
        </div>
        <section className={styles.previouslyApproved}>
          <h2>Previously Approved Artists</h2>
          {Object.entries(approvedArtistsByRound).map(([round, artists]) => (
            <div className={styles.roundBlock} key={round}>
              <h3>{`Round ${round} Approved Artists`}</h3>
              <ul>
                {artists.map((artist) => (
                  <li key={round + artist}>{artist}</li>
                ))}
              </ul>
            </div>
          ))}
        </section>
      </PageContainer>
      <Footer />
    </div>
  );
}
