import cn from 'classnames';
import parse from 'url-parse';
import { Link as InternalLink } from 'react-router-dom';
import { isUrlExternal } from 'is-url-external';
import { Icon, IconName } from './Icon';
import { forwardRef } from 'react';

import styles from './Link.module.scss';

interface LinkProps {
  children: string;
  href?: string;
  type?: LinkType;
  className?: string;
  onClick?: () => void;
  disabled?: boolean;
  startIcon?: IconName;
  endIcon?: IconName;
  plain?: boolean;
  iconSize?: IconSize;
}

type LinkType = 'default' | 'feedback';
type IconSize = 'default' | 'small';

export const Link = forwardRef<HTMLAnchorElement, LinkProps>(
  (
    {
      children,
      type = 'default',
      href,
      className,
      onClick,
      disabled = false,
      startIcon,
      endIcon,
      iconSize = 'default',
      plain = false,
    }: LinkProps,
    ref
  ) => {
    const content = (
      <>
        {startIcon && (
          <Icon
            name={startIcon}
            className={cn(styles.icon, {
              [styles.iconSmall]: iconSize === 'small',
            })}
          />
        )}
        <span>{children}</span>
        {endIcon && (
          <Icon
            name={endIcon}
            className={cn(styles.icon, {
              [styles.iconSmall]: iconSize === 'small',
            })}
          />
        )}
      </>
    );

    const classes = cn(
      {
        [styles.link]: type === 'default',
        [styles.feedback]: type === 'feedback',
        [styles.disabled]: disabled,
        [styles.plain]: plain,
      },
      className
    );

    return !href ||
      isUrlExternal(href, window.location.hostname) ||
      /^mailto:/.test(href) ||
      parse(href).hash ? (
      <a
        href={href}
        className={classes}
        tabIndex={disabled ? -1 : 0}
        onClick={onClick}
        ref={ref}
      >
        {content}
      </a>
    ) : (
      <InternalLink
        to={href}
        className={classes}
        tabIndex={disabled ? -1 : 0}
        onClick={onClick}
        ref={ref}
      >
        {content}
      </InternalLink>
    );
  }
);

Link.displayName = 'Link';
