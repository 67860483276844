import { useActor } from '@xstate/react';
import { EmailView } from './EmailView';
import { EmailInvitationMachineActor } from '../../../../machines/Eligibility/EmailInvitationMachine';

export function EmailController({
  actor,
}: {
  actor: EmailInvitationMachineActor;
}) {
  const [state, send] = useActor(actor);

  return <EmailView state={state} send={send} />;
}
