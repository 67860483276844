import {
  Storage,
  createMemoryStorage,
  createPersistentStorage,
} from '../../utils/storage';

import { EligibilityState, parseEligibilityState } from './state';

export type EligibilityStorage = Omit<Storage<EligibilityState>, 'add'> & {
  add: (state: EligibilityState, isReplay?: true) => void;
};

const mergeEligibilityStates = (
  newState: EligibilityState,
  oldState: EligibilityState
): EligibilityState => ({
  ...oldState,
  ...newState,
  sales: {
    ...oldState.sales,
    ...newState.sales,
  },
  release: {
    ...oldState.release,
    ...newState.release,
  },
  master: {
    ...oldState.master,
    ...newState.master,
  },
});

const createEligibilityStorage = (
  storage: Storage<EligibilityState>
): EligibilityStorage => {
  const { add, ...rest } = storage;

  return {
    add: (state: EligibilityState, isReplay?: true) => {
      if (isReplay !== true) {
        add(state);
      }
    },
    ...rest,
  };
};

export const createPersistentEligibilityStorage = () =>
  createEligibilityStorage(
    createPersistentStorage<EligibilityState>(
      'starmaker.eligibility',
      {},
      mergeEligibilityStates,
      parseEligibilityState
    )
  );

export const createMemoryEligibilityStorage = (initial?: EligibilityState) =>
  createEligibilityStorage(
    createMemoryStorage<EligibilityState>(initial ?? {}, mergeEligibilityStates)
  );
