import { createSubscribeToNewsletterPromise } from '../../../promises/createSubscribeToNewsletterPromise';
import { withDelay } from '../../../utils/withDelay';
import { useGraphQLClient } from '../../GraphQLClientProvider';
import { NewsletterController } from './NewsletterController';

export const Newsletter = () => {
  const client = useGraphQLClient();

  return (
    <NewsletterController
      options={{
        services: {
          subscribeToNewsletter: withDelay(
            createSubscribeToNewsletterPromise(client)
          ),
        },
      }}
    />
  );
};
