import { GraphQLClient } from 'graphql-request';
import { Context } from '../../machines/Admin/ApplicationReviewMachine';
import { UpsertApplicationBoardNotesDocument } from '../../graphql/operations';
import { adminApplicationFragmentSchema } from '../../schemas/admin/adminApplicationFragmentSchema';

export const createUpsertBoardNotesPromise =
  (client: GraphQLClient) =>
  async ({ applicationId, boardNotesInputValue }: Context) => {
    const request = await client.request(UpsertApplicationBoardNotesDocument, {
      applicationId,
      notes: boardNotesInputValue,
    });

    const application = request.result?.query?.application;

    return adminApplicationFragmentSchema.parseAsync(application);
  };

export type CreateUpsertBoardNotesPromise = ReturnType<
  typeof createUpsertBoardNotesPromise
>;
export type CreateUpsertBoardNotesPromiseResult = Awaited<
  ReturnType<CreateUpsertBoardNotesPromise>
>;
