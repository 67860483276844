import { match } from 'ts-pattern';
import { PasswordMachineActor } from '../../machines/RedeemInvitation/PasswordMachine';
import { PasswordController } from '../../components/RedeemInvitation/Password/PasswordController';
import {
  RedeemBoardInvitationMachineSender,
  RedeemBoardInvitationMachineState,
} from '../../machines/RedeemBoardInvitation/Machine';
import { Loading } from '../../components/Loading';
import { ErrorPage } from '../ErrorPage';
import { AccountController } from '../../components/RedeemInvitation/Account/AccountController';
import { AccountMachineActor } from '../../machines/RedeemInvitation/AccountMachine';
import { Button } from '../../components/Button';
import { Form } from '../../components/Form';
import { PageHeader } from '../../components/PageHeader';
import { FormControls } from '../../components/FormControls';

interface BoardCreationViewProps {
  state: RedeemBoardInvitationMachineState;
  send: RedeemBoardInvitationMachineSender;
}

export function RedeemBoardInvitationView({
  state,
  send,
}: BoardCreationViewProps) {
  return match(state)
    .when(
      () =>
        state.matches('init.rejected') ||
        state.matches('redeeming.rejected') ||
        state.matches('linking.rejected'),
      () => <ErrorPage />
    )
    .when(
      () => state.matches('init.invalid-tag'),
      () => (
        <ErrorPage
          title="Invalid invitation tag provided."
          text="Please reach out to the Starmaker team for assistance."
        />
      )
    )
    .when(
      () => state.matches('password'),
      () => (
        <PasswordController
          actor={state.children.passwordMachine as PasswordMachineActor}
        />
      )
    )
    .when(
      () => state.matches('account'),
      () => (
        <AccountController
          actor={state.children.accountMachine as AccountMachineActor}
        />
      )
    )
    .when(
      () => state.matches('link'),
      () => (
        <Form onSubmit={() => send('NEXT')}>
          <PageHeader heading="Accept Board Member Invitation">
            You’ve been invited to access the Canadian Starmaker Fund Board of
            Directors tools.
          </PageHeader>
          <FormControls className="max-w-md">
            <Button label="Accept Invitation" type="submit" />
          </FormControls>
        </Form>
      )
    )
    .otherwise(() => <Loading />);
}
