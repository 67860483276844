import { useGraphQLClient } from '../../../components/GraphQLClientProvider';
import { BoardMembersController } from './BoardMembersController';
import { createGetBoardMembersPromise } from '../../../promises/Admin/createGetBoardMembersPromise';
import { createContext } from '../../../machines/Admin/BoardMembersMachine';
import { createRemoveBoardMemberPromise } from '../../../promises/Admin/createRemoveBoardMemberPromise';
import { createAddBoardMemberPromise } from '../../../promises/Admin/createAddBoardMemberPromise';
import { createDeleteBoardInvitationPromise } from '../../../promises/Admin/createDeleteBoardInvitationPromise';

export function BoardMembers() {
  const client = useGraphQLClient();

  return (
    <BoardMembersController
      options={{
        context: createContext(),
        services: {
          getBoardMembersPromise: createGetBoardMembersPromise(client),
          removeBoardMember: createRemoveBoardMemberPromise(client),
          addBoardMember: createAddBoardMemberPromise(client),
          deleteBoardInvitation: createDeleteBoardInvitationPromise(client),
        },
      }}
    />
  );
}
