import { useActor } from '@xstate/react';
import { ExpenseCardView } from './ExpenseCardView';
import { ExpenseMachineActor } from '../../../machines/components/ExpenseMachine';

interface ExpenseCardControllerProps {
  actor: ExpenseMachineActor;
  showEdit: boolean;
  showDelete: boolean;
}

export function ExpenseCardController({
  actor,
  showEdit,
  showDelete,
}: ExpenseCardControllerProps) {
  const [state, send] = useActor(actor);

  return (
    <ExpenseCardView
      state={state}
      send={send}
      showEdit={showEdit}
      showDelete={showDelete}
    />
  );
}
