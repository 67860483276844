import { useActor } from '@xstate/react';
import { ReleaseDetailsMachineActor } from '../../../machines/Release/ReleaseDetailsMachine';
import { ReleaseDetailsView } from './ReleaseDetailsView';

export function ReleaseDetailsController({
  actor,
}: {
  actor: ReleaseDetailsMachineActor;
}) {
  const [state, send] = useActor(actor);

  return <ReleaseDetailsView state={state} send={send} />;
}
