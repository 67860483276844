import { useActor } from '@xstate/react';
import { AttachmentsBlockMachineActor } from '../../machines/components/AttachmentsBlockMachine';
import { AttachmentsBlockView } from './AttachmentsBlockView';

export function AttachmentsBlockController({
  actor,
}: {
  actor: AttachmentsBlockMachineActor;
}) {
  const [state, send] = useActor(actor);

  return <AttachmentsBlockView state={state} send={send} />;
}
