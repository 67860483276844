import z from 'zod';
import { attachmentSchema } from './attachment';

export const boardMeetingBlockSchema = z.object({
  id: z.string(),
  title: z.string(),
  content: z.string(),
  displayOrder: z.number(),
  fundingRoundId: z.string(),
  attachments: z.array(attachmentSchema),
});

export type BoardMeetingBlock = z.infer<typeof boardMeetingBlockSchema>;
