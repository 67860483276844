import { GraphQLClient } from 'graphql-request';
import { DeleteManagementDetailsDocument } from '../graphql/operations';

export const createDeleteManagementDetailsPromise =
  (client: GraphQLClient, artistId: string) => () =>
    client.request(DeleteManagementDetailsDocument, {
      input: {
        artistId,
      },
    });

export type DeleteManagementDetailsPromise = ReturnType<
  typeof createDeleteManagementDetailsPromise
>;
export type DeleteManagementDetailsPromiseResult = Awaited<
  ReturnType<DeleteManagementDetailsPromise>
>;
