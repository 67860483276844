import classNames from 'classnames';
import { TextInput } from '../../TextInput';
import { PageHeader } from '../../PageHeader';
import {
  ManagementMachineState,
  ManagementMachineSender,
} from '../../../machines/RedeemAccountInvitation/ManagementMachine';
import { FormField } from '../../FormField';
import { Select } from '../../Select';
import { RadioOption } from '../../RadioOption';
import { Button } from '../../Button';
import { TextArea } from '../../TextArea';
import { Feedback } from '../../Feedback';
import { match } from 'ts-pattern';
import { Form } from '../../Form';
import { FormControls } from '../../FormControls';

import styles from './ManagementView.module.scss';

interface ManagementViewProps {
  state: ManagementMachineState;
  send: ManagementMachineSender;
}

export function ManagementView({ state, send }: ManagementViewProps) {
  return (
    <Form className={styles.container} onSubmit={() => send('NEXT')}>
      <PageHeader
        heading={'Management company'}
        paginationActive={state.context.isAddArtist ? 2 : 4}
        paginationSteps={state.context.isAddArtist ? 2 : 4}
        includeBackButton={true}
        backButtonOnClick={() => send('BACK')}
        isOrion={state.context.isOrion}
      >
        {state.context.isAddArtist
          ? 'Tell us about the management company.'
          : 'Tell us about your management company.'}
      </PageHeader>
      <div className={classNames(styles.formFields, 'max-w-md')}>
        <FormField
          htmlFor="management-company-name"
          label="Management company name"
          formFieldStatus={
            state.matches('form.pending.name.empty') ? 'error' : 'default'
          }
          feedbackType="warning"
          infoLabel={
            state.matches('form.pending.name.empty')
              ? 'This field is required.'
              : ''
          }
        >
          <TextInput
            id="management-company-name"
            label="Management company name"
            value={state.context.name}
            onFocus={() => send('FOCUS_NAME')}
            onChange={(value) => send({ type: 'UPDATE_NAME', value })}
            onBlur={() => send('BLUR_NAME')}
          />
        </FormField>
        <FormField
          id="management-company-nationality"
          htmlFor="management-company-nationality-toggle-button"
          label="Management company nationality"
          formFieldStatus={
            state.matches('form.pending.country.empty') ? 'error' : 'default'
          }
          feedbackType="warning"
          infoLabel={
            state.matches('form.pending.country.empty')
              ? 'This field is required.'
              : ''
          }
        >
          <Select
            id="management-company-nationality"
            label="Choose an option"
            items={state.context.countries}
            itemToKey={(item) => item?.id}
            getItemText={({ name }) => name}
            selectedItem={
              state.context.country !== undefined ? state.context.country : null
            }
            onChange={(value) =>
              send({
                type: 'UPDATE_COUNTRY',
                value,
              })
            }
          />
        </FormField>
        <FormField
          htmlFor="contact-person"
          label="Contact person"
          formFieldStatus={
            state.matches('form.pending.contact-person.empty')
              ? 'error'
              : 'default'
          }
          feedbackType="warning"
          infoLabel={
            state.matches('form.pending.contact-person.empty')
              ? 'This field is required.'
              : ''
          }
        >
          <TextInput
            id="contact-person"
            label="Contact person"
            value={state.context.contactPerson}
            onFocus={() => send('FOCUS_CONTACT_PERSON')}
            onChange={(value) => send({ type: 'UPDATE_CONTACT_PERSON', value })}
            onBlur={() => send('BLUR_CONTACT_PERSON')}
          />
        </FormField>
        <FormField
          htmlFor="address"
          label="Address"
          description="Include street address, city, prov/state, country, and postal code."
          formFieldStatus={
            state.matches('form.pending.address.empty') ? 'error' : 'default'
          }
          feedbackType="warning"
          infoLabel={
            state.matches('form.pending.address.empty')
              ? 'This field is required.'
              : ''
          }
        >
          <TextArea
            id="address"
            size={'small'}
            label="Address"
            value={state.context.address}
            onFocus={() => send('FOCUS_ADDRESS')}
            onChange={(value) => send({ type: 'UPDATE_ADDRESS', value })}
            onBlur={() => send('BLUR_ADDRESS')}
          />
        </FormField>
        <FormField
          htmlFor="phone-number"
          label="Phone number"
          formFieldStatus={
            state.matches('form.pending.phone-number.invalid')
              ? 'error'
              : 'default'
          }
          feedbackType="warning"
          infoLabel={match(state)
            .when(
              ({ matches }) =>
                matches('form.pending.phone-number.invalid.empty'),
              () => 'This field is required.'
            )
            .when(
              ({ matches }) =>
                matches('form.pending.phone-number.invalid.value'),
              () => "This phone number isn't valid."
            )
            .otherwise(() => '')}
        >
          <TextInput
            id="phone-number"
            label="Phone number"
            value={state.context.phoneNumber}
            onFocus={() => send('FOCUS_PHONE_NUMBER')}
            onChange={(value) => send({ type: 'UPDATE_PHONE_NUMBER', value })}
            onBlur={() => send('BLUR_PHONE_NUMBER')}
          />
        </FormField>
        <FormField
          htmlFor="email"
          label="Email"
          formFieldStatus={
            state.matches('form.pending.email.invalid') ? 'error' : 'default'
          }
          feedbackType="warning"
          infoLabel={match(state)
            .when(
              ({ matches }) => matches('form.pending.email.invalid.empty'),
              () => 'This field is required.'
            )
            .when(
              ({ matches }) => matches('form.pending.email.invalid.value'),
              () => "This email isn't valid."
            )
            .otherwise(() => '')}
        >
          <TextInput
            id="email"
            label="Email"
            value={state.context.email}
            onFocus={() => send('FOCUS_EMAIL')}
            onChange={(value) => send({ type: 'UPDATE_EMAIL', value })}
            onBlur={() => send('BLUR_EMAIL')}
          />
        </FormField>
        <FormField
          label="Company's legal status"
          formFieldStatus={
            state.matches('form.pending.legal-status.empty')
              ? 'error'
              : 'default'
          }
          feedbackType="warning"
          infoLabel={
            state.matches('form.pending.legal-status.empty')
              ? 'This field is required.'
              : ''
          }
        >
          <>
            {state.context.legalStatuses.map((value) => (
              <RadioOption
                key={value.id}
                label={value.name}
                description={value.description ?? undefined}
                checked={state.context.legalStatus?.id === value.id}
                onChange={() => send({ type: 'UPDATE_LEGAL_STATUS', value })}
              />
            ))}
          </>
        </FormField>
        {state.context.legalStatus?.requiresProvince && (
          <FormField
            htmlFor="province-of-incorporation-toggle-button"
            id="company-legal-province"
            label={state.context.legalStatus.provinceTitle ?? undefined}
            formFieldStatus={
              state.matches('form.pending.legal-status.partial.empty')
                ? 'error'
                : 'default'
            }
            feedbackType="warning"
            infoLabel={
              state.matches('form.pending.legal-status.partial.empty')
                ? 'This field is required.'
                : ''
            }
          >
            <Select
              id="company-legal-province"
              label="Choose an option"
              items={state.context.provinces}
              itemToKey={(item) => item?.id}
              getItemText={({ province }) => province}
              selectedItem={
                state.context.legalProvince !== undefined
                  ? state.context.legalProvince
                  : null
              }
              onChange={(value) =>
                send({ type: 'UPDATE_LEGAL_PROVINCE', value })
              }
            />
          </FormField>
        )}
      </div>
      <FormControls className="max-w-lg">
        {(state.matches('form.pending.warning.on') ||
          state.matches('form.pending.warning.pulse')) && (
          <Feedback
            type="warning"
            pulse={state.matches('form.pending.warning.pulse')}
          >
            Please fill in all the fields above.
          </Feedback>
        )}
        <div className="max-w-md">
          <Button label="Proceed to Artist Dashboard" type="submit" />
        </div>
      </FormControls>
    </Form>
  );
}
