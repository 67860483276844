import { Expense } from '../../schemas/fundingRequests/expenses/expenseSchema';

export type EditContext = {
  id: Expense['id'];
};

export type RefreshExpenses = {
  type: 'REFRESH_EXPENSES';
  editContext?: EditContext;
};

export const refreshExpenses = (
  editContext?: EditContext
): RefreshExpenses => ({
  type: 'REFRESH_EXPENSES',
  editContext,
});

export const createEditContext = (expense?: Expense): EditContext | undefined =>
  expense !== undefined
    ? {
        id: expense.id,
      }
    : undefined;
