import { CalendarDate, getLocalTimeZone, today } from '@internationalized/date';
import { useState } from 'react';
import cn from 'classnames';
import { Chevron } from './Chevron';

import styles from './TimePeriodPanel.module.scss';

const MONTHS = new Array(12).fill(0).map((_, i) => {
  return new Date(2024, i).toLocaleString('en', { month: 'short' });
});

interface TimePeriodPanelProps {
  value: CalendarDate | null;
  onDone: (date: CalendarDate) => void;
}

export function TimePeriodPanel({ value, onDone }: TimePeriodPanelProps) {
  const [showYearSelector, setShowYearSelector] = useState(false);
  const [selectedDate, setSelectedDate] = useState(
    value ?? today(getLocalTimeZone())
  );

  const yearRange = [];
  for (let i = selectedDate.year - 4; i < selectedDate.year + 8; i++) {
    yearRange.push(i);
  }

  return (
    <>
      {!showYearSelector && (
        <>
          <div className={styles.heading}>
            <p />
            <h3>Month</h3>
            <p />
          </div>
          <div className={styles.grid}>
            {MONTHS.map((month) => (
              <button
                type="button"
                key={month}
                className={cn(styles.item, {
                  [styles.active]:
                    MONTHS.indexOf(month) === selectedDate.month - 1,
                })}
                onClick={() => {
                  setShowYearSelector(true);
                  setSelectedDate(
                    selectedDate.set({
                      month: MONTHS.indexOf(month) + 1,
                    })
                  );
                }}
              >
                {month}
              </button>
            ))}
          </div>
        </>
      )}
      {showYearSelector && (
        <>
          <div className={styles.heading}>
            <Chevron
              direction="left"
              onClick={() =>
                setSelectedDate((date) => date.subtract({ years: 12 }))
              }
            />
            <h3>Year</h3>
            <Chevron
              direction="right"
              onClick={() => setSelectedDate((date) => date.add({ years: 12 }))}
            />
          </div>
          <div className={styles.grid}>
            {yearRange.map((year) => (
              <button
                type="button"
                key={year}
                className={cn(styles.item, {
                  [styles.active]: value?.year === year,
                })}
                onClick={() => onDone(selectedDate.set({ year }))}
              >
                {year}
              </button>
            ))}
          </div>
        </>
      )}
    </>
  );
}
