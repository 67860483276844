import { P, match } from 'ts-pattern';
import {
  ApplicationStatementBlockMachineSender,
  ApplicationStatementBlockMachineState,
} from '../../machines/components/ApplicationStatementBlockMachine';
import { StatementType } from '../../utils/getApplicationStatementType';
import { Block } from '../Block';
import { FormField } from '../FormField';
import { SaveableTextArea } from '../SaveableTextArea';
import { StatementInstructions } from './StatementInstructions';

import styles from './ApplicationStatementBlockView.module.scss';

interface ApplicationStatementBlockViewProps {
  state: ApplicationStatementBlockMachineState;
  send: ApplicationStatementBlockMachineSender;
  statementType: StatementType;
}

export function ApplicationStatementBlockView({
  state,
  send,
  statementType,
}: ApplicationStatementBlockViewProps) {
  const [formLabel, formNote, buttonText] =
    statementType === 'Proposal'
      ? ['One-page business proposal', '', 'Save Proposal']
      : ['Describe your tour strategy', 'optional', 'Save Strategy'];

  return (
    <Block id={statementType} headerText={statementType} iconName="file">
      {match(state.context)
        .with({ submittedAt: null }, () => (
          <>
            <StatementInstructions type={statementType} />
            <FormField
              id="statement"
              htmlFor="statementText"
              label={formLabel}
              note={formNote}
            >
              <SaveableTextArea
                id="statementText"
                label="statement"
                savedValue={state.context.statement}
                saveButtonLabel={buttonText}
                onSubmit={(value) => {
                  send({
                    type: 'SAVE_STATEMENT',
                    value,
                  });
                }}
                onCancel={() => {
                  send({ type: 'CLEAR_ERROR' });
                }}
                isSubmittingAsync={state.matches('save')}
                hasSubmissionError={state.matches('error')}
              />
            </FormField>
          </>
        ))
        .with({ statement: '' }, () => (
          <p className={styles.text}>No tour strategy added.</p>
        ))
        .with({ statement: P.string }, () => (
          <p className={styles.text}>{state.context.statement}</p>
        ))
        .exhaustive()}
    </Block>
  );
}
