import type {
  ActorRefFrom,
  EventFrom,
  MachineOptionsFrom,
  Sender,
} from 'xstate';
import { createMachine, assign } from 'xstate';
import type { NextEvent, BackEvent } from '../navigation';
import { StateFrom } from '../../utils/StateFrom';
import { GetRedeemBoardInvitationContextPromiseResult } from '../../promises/getRedeemBoardInvitationContext';
import { createPasswordContext } from '../RedeemInvitation/PasswordMachine';
import { isInvitationInvalidStatusError } from '../../errors/InvitationInvalidStatusError';
import { RedeemInvitationResult } from '../../promises/redeemInvitationPromise';
import { logMachineError } from '../../utils/logError';
import { createAccountContext } from '../RedeemInvitation/AccountMachine';
import { RedeemBoardInvitationStorage } from './storage';
import { RedeemBoardInvitationHistory } from './history';
import { RedeemBoardInvitationState } from './state';
import { match } from 'ts-pattern';
import { LinkInvitationResult } from '../../promises/linkInvitationPromise';
import { Context } from './Context';

type Events =
  | NextEvent
  | BackEvent
  | { type: 'RESUME_PASSWORD' }
  | { type: 'RESUME_ACCOUNT' }
  | { type: 'RESUME_LINK' }
  | { type: 'RESTART' };

type Services = {
  getRedeemBoardInvitationContext: {
    data: GetRedeemBoardInvitationContextPromiseResult;
  };
  linkBoardMemberInvitation: {
    data: LinkInvitationResult;
  };
  redeemBoardInvitation: {
    data: RedeemInvitationResult;
  };
};

export const machine = createMachine(
  {
    id: 'redeemBoardMemberInvitation',
    tsTypes: {} as import('./Machine.typegen').Typegen0,
    predictableActionArguments: true,
    schema: {
      context: {} as Context,
      events: {} as Events,
      services: {} as Services,
    },
    initial: 'init',
    invoke: {
      id: 'restart',
      src: 'restart',
    },
    context: {
      tag: '',
      email: '',
      minimumPasswordLength: 0,
      link: false,
    },
    states: {
      init: {
        initial: 'pending',
        states: {
          pending: {
            invoke: {
              id: 'getRedeemBoardInvitationContext',
              src: 'getRedeemBoardInvitationContext',
              onDone: {
                actions: 'setContext',
                target: 'resolved',
              },
              onError: [
                { cond: 'isInvalidRequest', target: 'invalid-tag' },
                { target: 'rejected' },
              ],
            },
          },
          resolved: { type: 'final' },
          rejected: { entry: 'logMachineError' },
          'invalid-tag': {},
        },
        onDone: 'resume',
      },
      resume: {
        invoke: {
          id: 'resume',
          src: 'resume',
        },
        on: {
          RESUME_LINK: 'link',
          RESUME_PASSWORD: 'password',
          RESUME_ACCOUNT: 'account',
        },
      },
      password: {
        entry: 'savePasswordStep',
        invoke: {
          id: 'passwordMachine',
          src: 'passwordMachine',
          data: createPasswordContext,
        },
        on: {
          NEXT: 'account',
        },
      },
      account: {
        entry: 'saveAccountStep',
        invoke: {
          id: 'accountMachine',
          src: 'accountMachine',
          data: createAccountContext,
        },
        on: {
          NEXT: 'redeeming',
          BACK: 'password',
        },
      },
      redeeming: {
        initial: 'pending',
        states: {
          pending: {
            invoke: {
              id: 'redeemBoardInvitation',
              src: 'redeemBoardInvitation',
              onDone: {
                actions: 'signIn',
              },
              onError: 'rejected',
            },
          },
          rejected: { entry: 'logMachineError' },
        },
      },
      link: {
        on: { NEXT: 'linking' },
      },
      linking: {
        id: 'linking',
        initial: 'pending',
        states: {
          pending: {
            invoke: {
              id: 'linkBoardMemberInvitation',
              src: 'linkBoardMemberInvitation',
              onDone: {
                actions: 'signIn',
              },
              onError: 'rejected',
            },
          },
          rejected: { entry: 'logMachineError' },
        },
      },
    },
    on: { RESTART: 'init' },
  },
  {
    actions: { logMachineError, setContext: assign((_, { data }) => data) },
    guards: {
      isInvalidRequest: (_, { data }) => isInvitationInvalidStatusError(data),
    },
  }
);

type Machine = typeof machine;

export type RedeemBoardInvitationMachineState = StateFrom<Machine>;
export type RedeemBoardInvitationMachineSender = Sender<EventFrom<Machine>>;
export type RedeemBoardInvitationMachineOptions = MachineOptionsFrom<
  Machine,
  true
>;
export type RedeemBoardInvitationMachineActor = ActorRefFrom<Machine>;

const createStepSaver =
  (
    storage: RedeemBoardInvitationStorage,
    history: RedeemBoardInvitationHistory,
    step: RedeemBoardInvitationState['step']
  ) =>
  // Every time the user navigates forwards or backwards in the flow we
  // push an entry onto the history stack. This gives us a snapshot to
  // restore to if the user every decides to use the back and forward
  // buttons on their browser.
  (_: Context, { type }: Events) => {
    // We only want to push history when the user has been the one
    // triggering movement. There are other events used by the resume
    // serivce to restore the state of the flow but those are not
    // triggered by humans in front of the screen and therefore we do
    // not want to push history onto the stack for those events.
    if (type === 'NEXT' || type === 'BACK') {
      // The reason we first replace the current state is because the user
      // will have changed the state on the page since they first entered this
      // state. This allows us to save all their work up till they navigated
      // to the next step.
      history.replace(storage.get());
      storage.add({ step });
      history.push(storage.get());
    }
  };

export const createResumeService =
  (storage: RedeemBoardInvitationStorage) =>
  ({ link }: Context) =>
  (send: RedeemBoardInvitationMachineSender) => {
    const { step } = storage.get();

    match(step)
      .with(undefined, () => send(link ? 'RESUME_LINK' : 'RESUME_PASSWORD'))
      .with('password', () => send('RESUME_PASSWORD'))
      .with('account', () => send('RESUME_ACCOUNT'))
      .exhaustive();
  };

export const createRestartService =
  (
    storage: RedeemBoardInvitationStorage,
    history: RedeemBoardInvitationHistory
  ) =>
  () =>
  (send: RedeemBoardInvitationMachineSender) => {
    history.subscribe((state) => {
      storage.set(state);
      send('RESTART');
    });

    return history.unsubscribe;
  };

export const createSaveActions = (
  storage: RedeemBoardInvitationStorage,
  history: RedeemBoardInvitationHistory
) => ({
  savePasswordStep: createStepSaver(storage, history, 'password'),
  saveAccountStep: createStepSaver(storage, history, 'account'),
});
