import { z } from 'zod';

const completeRedeemArtistInvitationStateSchema = z.object({
  account: z.object({
    name: z.string(),
    phoneNumber: z.string(),
    address: z.string(),
    password: z.string(),
    confirmPassword: z.string(),
  }),
});

const completeTransferArtistInvitationStateSchema = z.object({
  email: z.string(),
});

const redeemArtistInvitationStateSchema = z
  .object({
    step: z
      .union([
        z.literal('transfer'),
        z.literal('password'),
        z.literal('account'),
      ])
      .optional(),
  })
  .merge(completeRedeemArtistInvitationStateSchema.deepPartial())
  .merge(completeTransferArtistInvitationStateSchema.deepPartial());

export type RedeemArtistInvitationState = z.infer<
  typeof redeemArtistInvitationStateSchema
>;

export type CompleteRedeemArtistInvitationState = z.infer<
  typeof completeRedeemArtistInvitationStateSchema
>;

export type CompleteTransferArtistInvitationState = z.infer<
  typeof completeTransferArtistInvitationStateSchema
>;

export const parseRedeemArtistInvitationState = (data: unknown) =>
  redeemArtistInvitationStateSchema.parse(data);

export const parseCompleteRedeemArtistInvitationState = (data: unknown) =>
  completeRedeemArtistInvitationStateSchema.parseAsync(data);

export const parseCompleteTransferArtistInvitationState = (data: unknown) =>
  completeTransferArtistInvitationStateSchema.parseAsync(data);
