import { FundingRequest } from '../graphql/operations';

export type OpenFundingRequest = {
  type: 'OPEN_REQUEST';
  id?: FundingRequest['id'];
};

export const openFundingRequest = (
  id?: OpenFundingRequest['id']
): OpenFundingRequest => ({
  type: 'OPEN_REQUEST',
  id,
});
