import React from 'react';
import { Feedback } from './Feedback';
import { FormFieldProps } from './FormField';
import { FormFieldLabel } from './FormFieldLabel';
import cn from 'classnames';

import formFieldStyles from './FormField.module.scss';
import styles from './FormFieldDuoInGrid.module.scss';

interface Props {
  left: FormFieldProps;
  right: FormFieldProps;
}

export function FormFieldDuoInGrid({ left, right }: Props) {
  return (
    <div className={styles.container}>
      <div className={formFieldStyles.labelContainer}>
        <div className={formFieldStyles.labelRow}>
          <FormFieldLabel {...left} />
        </div>
      </div>
      <div className={formFieldStyles.labelContainer}>
        <div className={formFieldStyles.labelRow}>
          <FormFieldLabel {...right} />
        </div>
      </div>

      <div className={styles.fieldAndFeedback}>
        <div
          className={cn(
            formFieldStyles.options,
            formFieldStyles[left.direction ?? 'vertical']
          )}
        >
          {React.cloneElement(left.children, {
            formFieldStatus: left.formFieldStatus,
          })}
        </div>

        {left.infoLabel && (
          <Feedback
            title={left.feedbackTitle}
            type={left.feedbackType ?? 'neutral'}
            size={left.feedbackSize ?? 'compact'}
          >
            {left.infoLabel}
          </Feedback>
        )}
      </div>

      <div className={styles.fieldAndFeedback}>
        <div
          className={cn(
            formFieldStyles.options,
            formFieldStyles[right.direction ?? 'vertical']
          )}
        >
          {React.cloneElement(right.children, {
            formFieldStatus: right.formFieldStatus,
          })}
        </div>

        {right.infoLabel && (
          <Feedback
            title={right.feedbackTitle}
            type={right.feedbackType ?? 'neutral'}
            size={right.feedbackSize ?? 'compact'}
          >
            {right.infoLabel}
          </Feedback>
        )}
      </div>
    </div>
  );
}
