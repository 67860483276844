import { BoardMeetingBlock } from '../../schemas/boardMeetingBlockSchema';
import { Block } from '../../components/Block';
import { AttachmentPreview } from '../../components/Attachments/AttachmentPreview';
import { Attachment } from '../../schemas/attachment';

import styles from './ReadOnlyBlock.module.scss';

interface ReadOnlyBlockProps {
  block: BoardMeetingBlock;
  onDownload: (file: Attachment) => void;
}

export function ReadOnlyBlock({ block, onDownload }: ReadOnlyBlockProps) {
  return (
    <Block headerText={block.title} id={block.id}>
      {block.content && <p className={styles.content}>{block.content}</p>}
      {block.attachments.map((file) => (
        <AttachmentPreview
          key={file.id}
          attachment={file}
          onClick={onDownload}
        />
      ))}
    </Block>
  );
}
