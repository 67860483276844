import { z } from 'zod';
import { EligibilityNoticeTypes } from '../graphql/operations';

export const eligibilityNoticeSchema = z.object({
  type: z.nativeEnum(EligibilityNoticeTypes),
  message: z.string(),
});

export const artistEligibilityNoticeSchema = eligibilityNoticeSchema.nullable();

export type ArtistEligibilityNotice = z.infer<
  typeof artistEligibilityNoticeSchema
>;
