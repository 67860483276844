import { GraphQLClient } from 'graphql-request';
import { DeleteBoardInvitationDocument } from '../../graphql/operations';
import { Context } from '../../machines/Admin/BoardMembersMachine';
import { contactSchema } from '../../schemas/contactSchema';

export const createDeleteBoardInvitationPromise =
  (client: GraphQLClient) =>
  async (_: Context, { email }: { email: string }) => {
    const { result } = await client.request(DeleteBoardInvitationDocument, {
      input: { email },
    });
    return contactSchema.parse(result?.response);
  };

export type DeleteBoardInvitationPromise = ReturnType<
  typeof createDeleteBoardInvitationPromise
>;

export type DeleteBoardInvitationPromiseResult = Awaited<
  ReturnType<DeleteBoardInvitationPromise>
>;
