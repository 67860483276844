import { useMachine } from '@xstate/react';
import { machine } from '../machines/SaveableTextAreaMachine';
import { TextArea } from './TextArea';
import { Button } from './Button';
import styles from './SaveableTextArea.module.scss';
import { Feedback } from './Feedback';

interface Props {
  id: string;
  label: string;
  savedValue: string;
  saveButtonLabel?: string;
  onSubmit: (value: string) => void;
  onCancel?: () => void;
  isSubmittingAsync?: boolean;
  hasSubmissionError?: boolean;
}

export function SaveableTextArea({
  id,
  label,
  savedValue,
  saveButtonLabel = 'Save',
  onSubmit,
  onCancel,
  isSubmittingAsync,
  hasSubmissionError,
}: Props) {
  const [state, send] = useMachine(machine, {
    context: {
      formValue: savedValue,
    },
  });
  const { formValue } = state.context;

  const isSubmitting = isSubmittingAsync || state.matches('submitting');

  const isButtonDisabled = isSubmitting || formValue === savedValue;

  return (
    <div className={styles.container}>
      <TextArea
        id={id}
        textAreaClassName={styles.textArea}
        size="small"
        disabled={isSubmitting}
        label={label}
        value={formValue}
        onChange={(value) => send('UPDATE_FORM_VALUE', { value })}
      />
      <div className={styles.buttons}>
        <Button
          size="small"
          disabled={isButtonDisabled}
          label={isSubmitting ? 'Saving…' : saveButtonLabel}
          onClick={() => {
            onSubmit(formValue);
            send('SUBMIT');
          }}
        />
        {!isButtonDisabled && (
          <Button
            size="small"
            variant="secondary"
            label="Cancel"
            onClick={() => {
              if (onCancel) {
                onCancel();
              }
              send('UPDATE_FORM_VALUE', { value: savedValue });
            }}
          />
        )}
      </div>
      {hasSubmissionError && !isSubmitting && (
        <Feedback type="warning">Failed to submit. Please try again.</Feedback>
      )}
    </div>
  );
}
