import { match } from 'ts-pattern';
import { ApplicationReviewStatus } from '../graphql/operations';

export function formatReviewStatus(
  reviewStatus: ApplicationReviewStatus
): string {
  return match(reviewStatus)
    .with(ApplicationReviewStatus.None, () => 'None')
    .with(ApplicationReviewStatus.BoardCall, () => 'Board Call')
    .with(ApplicationReviewStatus.NotQualified, () => 'Not Qualified')
    .with(ApplicationReviewStatus.NotRecommended, () => 'Not Recommended')
    .with(ApplicationReviewStatus.Recommended, () => 'Recommended')
    .exhaustive();
}
