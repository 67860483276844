import {
  AdminFundingRequest,
  CompleteFundingRequest,
  FundingRequest,
} from '../schemas/fundingRequests/fundingRequestSchema';

export type UpdateFundingRequest = {
  type: 'UPDATE_REQUEST';
  id: FundingRequest['id'];
  request: CompleteFundingRequest;
};

export const updateFundingRequest = (
  id: UpdateFundingRequest['id'],
  request: UpdateFundingRequest['request']
): UpdateFundingRequest => ({
  type: 'UPDATE_REQUEST',
  id,
  request,
});

export type UpdateAdminFundingRequest = {
  type: 'UPDATE_ADMIN_REQUEST';
  id: FundingRequest['id'];
  request: AdminFundingRequest;
};

export const updateAdminFundingRequest = (
  id: UpdateAdminFundingRequest['id'],
  request: UpdateAdminFundingRequest['request']
): UpdateAdminFundingRequest => ({
  type: 'UPDATE_ADMIN_REQUEST',
  id,
  request,
});
