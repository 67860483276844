import {
  History,
  createBrowserHistory,
  createMemoryHistory,
} from '../../utils/history';

import { AddReleaseState, parseAddReleaseState } from './state';

export type AddReleaseHistory = History<AddReleaseState>;

export const createBrowserAddReleaseHistory = (): AddReleaseHistory =>
  createBrowserHistory(parseAddReleaseState);

export const createMemoryAddReleaseHistory = (): AddReleaseHistory =>
  createMemoryHistory<AddReleaseState>();
