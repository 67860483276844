import React from 'react';
import { Button } from './Button';
import styles from './InlineFormContainer.module.scss';
import { Feedback, FeedbackType } from './Feedback';
import { Form } from './Form';
import { InlineBox } from './InlineBox';

interface InlineFormContainerProps {
  primaryButtonText?: string;
  primaryButtonOnClick?: () => void;
  primaryButtonDisabled?: boolean;
  secondaryButtonText?: string;
  secondaryButtonOnClick?: () => void;
  rawFeedback?: React.ReactNode;
  feedbackTitle?: string;
  feedbackType?: FeedbackType;
  feedbackBody?: string;
  feedbackPulse?: boolean;
  children: React.ReactNode;
}

export function InlineFormContainer({
  primaryButtonText,
  primaryButtonOnClick,
  primaryButtonDisabled = false,
  secondaryButtonText,
  secondaryButtonOnClick,
  rawFeedback,
  feedbackTitle,
  feedbackType = 'neutral',
  feedbackBody,
  feedbackPulse,
  children,
}: InlineFormContainerProps) {
  return (
    <InlineBox>
      <Form
        className={styles.form}
        onSubmit={() => {
          primaryButtonOnClick?.();
        }}
      >
        <div className={styles.content}>{children}</div>
        {feedbackBody && (
          <div className={styles.footer}>
            <Feedback
              type={feedbackType}
              title={feedbackTitle}
              pulse={feedbackPulse}
            >
              {feedbackBody}
            </Feedback>
          </div>
        )}
        {rawFeedback && <div className={styles.footer}>{rawFeedback}</div>}
        <div className={styles.controls}>
          {primaryButtonText && (
            <Button
              size="small"
              label={primaryButtonText}
              disabled={primaryButtonDisabled}
              type="submit"
            />
          )}
          {secondaryButtonText && (
            <Button
              size="small"
              variant="secondary"
              label={secondaryButtonText}
              onClick={secondaryButtonOnClick}
            />
          )}
        </div>
      </Form>
    </InlineBox>
  );
}
