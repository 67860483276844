import { useNavigate } from 'react-router-dom';
import { useGraphQLClient } from '../../components/GraphQLClientProvider';
import { ForgotPasswordController } from './ForgotPasswordController';
import { createForgotPasswordPromise } from '../../promises/forgotPasswordPromise';
import { createActions } from '../../machines/ForgotPasswordMachine';

export const ForgotPassword = () => {
  const client = useGraphQLClient();
  const navigate = useNavigate();

  return (
    <ForgotPasswordController
      options={{
        actions: createActions(() => navigate('/sign-in')),
        services: {
          forgotPasswordPromise: createForgotPasswordPromise(client),
        },
      }}
    />
  );
};
