import { match } from 'ts-pattern';
import {
  ApplicationType,
  InsertDirectDepositAndLinkToApplicationType,
} from '../../graphql/operations';
import {
  SetDepositInfoMachineSender,
  SetDepositInfoMachineState,
} from '../../machines/ClaimFunds/SetDepositInfoMachine';
import { ExampleCheque } from '../ExampleCheque';
import { Feedback } from '../Feedback';
import { FormField } from '../FormField';
import { InlineFormContainer } from '../InlineFormContainer';
import { InlineFormHeading } from '../InlineFormHeading';
import { RadioOption } from '../RadioOption';
import { TextInput } from '../TextInput';
import { formatDirectDepositNumbers } from './utils';
import styles from './SetDepositInfoView.module.scss';

interface SetDepositInfoViewProps {
  state: SetDepositInfoMachineState;
  send: SetDepositInfoMachineSender;
}

export function SetDepositInfoView({ state, send }: SetDepositInfoViewProps) {
  const formName = match({
    applicationType: state.context.applicationType,
    depositType: state.context.depositInfoType,
  })
    .with({ applicationType: ApplicationType.Orion }, () => 'Direct Deposit')
    .with(
      {
        applicationType: ApplicationType.Core,
        depositType: InsertDirectDepositAndLinkToApplicationType.Touring,
      },
      () => 'Tour Deposit'
    )
    .with(
      {
        applicationType: ApplicationType.Core,
        depositType: InsertDirectDepositAndLinkToApplicationType.FundingRequest,
      },
      () => 'Digital Content Deposit'
    )
    .exhaustive();

  return (
    <InlineFormContainer
      primaryButtonText={`Set ${formName} Info`}
      primaryButtonOnClick={() => send({ type: 'SAVE' })}
      secondaryButtonText="Cancel"
      secondaryButtonOnClick={() => send({ type: 'CANCEL' })}
      feedbackTitle="A network error occurred."
      feedbackBody={
        state.matches('inputtingNewAccount.network.error') ||
        state.matches('selectingExistingAccount.error')
          ? 'Please check your internet connection and try again.'
          : undefined
      }
      feedbackType="warning"
    >
      <InlineFormHeading>
        {state.context.isChange ? `Change ${formName}` : formName} Information
      </InlineFormHeading>
      <Feedback type="neutral" size="compact" icon="lock">
        We take stringent measures to safeguard the security of your banking
        information. Every account number is encrypted before being stored in
        our records and can only be decrypted by authorized Starmaker staff.
      </Feedback>
      <FormField>
        <>
          {state.context.existingAccounts.map((account, index) => (
            <RadioOption
              // See https://stackoverflow.com/a/71848275
              // eslint-disable-next-line jsx-a11y/no-autofocus
              autoFocus={index === 0}
              key={account.id}
              label={formatDirectDepositNumbers(account)}
              description={account.name}
              checked={
                account.id === state.context.selectedExistingAccountId &&
                !state.matches('inputtingNewAccount')
              }
              onChange={() =>
                send({
                  type: 'SELECT_EXISTING_ACCOUNT',
                  directDepositAccountId: account.id,
                })
              }
            />
          ))}
          {state.context.existingAccounts.length > 0 && (
            <RadioOption
              label="New Account"
              checked={state.matches('inputtingNewAccount')}
              onChange={() =>
                send({
                  type: 'SELECT_NEW_ACCOUNT',
                })
              }
            />
          )}
        </>
      </FormField>
      {state.matches('inputtingNewAccount') && (
        <>
          <FormField
            id="accountName"
            label="Account Name"
            feedbackType="warning"
            formFieldStatus={
              state.matches('inputtingNewAccount.name.empty')
                ? 'error'
                : 'default'
            }
            infoLabel={match(state)
              .when(
                ({ matches }) => matches('inputtingNewAccount.name.empty'),
                () => 'This field is required.'
              )
              .otherwise(() => '')}
          >
            <TextInput
              id="accountName"
              label="Account Name"
              // See https://stackoverflow.com/a/71848275
              // eslint-disable-next-line jsx-a11y/no-autofocus
              autoFocus={true}
              value={state.context.name ?? ''}
              onChange={(name) => send({ type: 'UPDATE_NAME', name })}
              onBlur={() => send({ type: 'BLUR_NAME' })}
            />
          </FormField>
          <FormField
            id="accountNumber"
            label="Account Number"
            feedbackType="warning"
            formFieldStatus={
              state.matches('inputtingNewAccount.account.empty') ||
              state.matches('inputtingNewAccount.account.invalid')
                ? 'error'
                : 'default'
            }
            infoLabel={match(state)
              .when(
                ({ matches }) => matches('inputtingNewAccount.account.empty'),
                () => 'This field is required.'
              )
              .when(
                ({ matches }) => matches('inputtingNewAccount.account.invalid'),
                () => 'Account numbers must be 7 to 12 digits.'
              )
              .otherwise(() => '')}
          >
            <TextInput
              id="accountNumber"
              label="Account Number"
              value={state.context.account ?? ''}
              onChange={(account) => send({ type: 'UPDATE_ACCOUNT', account })}
              onBlur={() => send({ type: 'BLUR_ACCOUNT' })}
            />
          </FormField>
          <div className={styles.transitAndInstitution}>
            <FormField
              id="transitNumber"
              className={styles.field}
              label="Transit Number"
              feedbackType="warning"
              formFieldStatus={
                state.matches('inputtingNewAccount.transit.empty') ||
                state.matches('inputtingNewAccount.transit.invalid')
                  ? 'error'
                  : 'default'
              }
              infoLabel={match(state)
                .when(
                  ({ matches }) => matches('inputtingNewAccount.transit.empty'),
                  () => 'This field is required.'
                )
                .when(
                  ({ matches }) =>
                    matches('inputtingNewAccount.transit.invalid'),
                  () => 'Transit numbers must be 4 or 5 digits.'
                )
                .otherwise(() => '')}
            >
              <TextInput
                id="transitNumber"
                label="Bank Transit Number"
                value={state.context.transit ?? ''}
                onChange={(transit) =>
                  send({ type: 'UPDATE_TRANSIT', transit })
                }
                onBlur={() => send({ type: 'BLUR_TRANSIT' })}
              />
            </FormField>
            <FormField
              id="institutionNumber"
              className={styles.field}
              label="Institution Number"
              feedbackType="warning"
              formFieldStatus={
                state.matches('inputtingNewAccount.institution.empty') ||
                state.matches('inputtingNewAccount.institution.invalid')
                  ? 'error'
                  : 'default'
              }
              infoLabel={match(state)
                .when(
                  ({ matches }) =>
                    matches('inputtingNewAccount.institution.empty'),
                  () => 'This field is required.'
                )
                .when(
                  ({ matches }) =>
                    matches('inputtingNewAccount.institution.invalid'),
                  () => 'Institution numbers must be 3 digits.'
                )
                .otherwise(() => '')}
            >
              <TextInput
                id="institutionNumber"
                label="Institution Number"
                value={state.context.institution ?? ''}
                onChange={(institution) =>
                  send({ type: 'UPDATE_INSTITUTION', institution })
                }
                onBlur={() => send({ type: 'BLUR_INSTITUTION' })}
              />
            </FormField>
          </div>
          <ExampleCheque />
        </>
      )}
    </InlineFormContainer>
  );
}
