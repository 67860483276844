import { Storage, createMemoryStorage } from '../../utils/storage';

import { RedeemBoardInvitationState } from './state';

export type RedeemBoardInvitationStorage = Omit<
  Storage<RedeemBoardInvitationState>,
  'add'
> & {
  add: (state: RedeemBoardInvitationState, isReplay?: true) => void;
};

const mergeRedeemBoardInvitationStates = (
  newState: RedeemBoardInvitationState,
  oldState: RedeemBoardInvitationState
): RedeemBoardInvitationState => ({
  ...oldState,
  ...newState,
  account: {
    ...oldState.account,
    ...newState.account,
  },
});

const createRedeemBoardInvitationStorage = (
  storage: Storage<RedeemBoardInvitationState>
): RedeemBoardInvitationStorage => {
  const { add, ...rest } = storage;

  return {
    add: (state: RedeemBoardInvitationState, isReplay?: true) => {
      if (isReplay !== true) {
        add(state);
      }
    },
    ...rest,
  };
};

export const createMemoryRedeemBoardInvitationStorage = (
  initial?: RedeemBoardInvitationState
) =>
  createRedeemBoardInvitationStorage(
    createMemoryStorage<RedeemBoardInvitationState>(
      initial ?? {},
      mergeRedeemBoardInvitationStates
    )
  );
