import { z } from 'zod';

export const attachmentSchema = z.object({
  id: z.string(),
  name: z.string(),
  size: z.number(),
  isOwner: z.boolean().optional(),
});

export type Attachment = z.infer<typeof attachmentSchema>;
