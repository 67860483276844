import { GraphQLClient } from 'graphql-request';
import { UpdateFundingRoundDocument } from '../../graphql/operations';
import { fundingRoundSchema } from '../../schemas/fundingRound/fundingRoundSchema';
import { UpdateFundingRound } from '../../events/UpdateFundingRound';
import { prepareFundingRound } from './prepareFundingRound';

export const createUpdateFundingRoundPromise =
  (client: GraphQLClient) =>
  async (_: unknown, { id, round }: UpdateFundingRound) => {
    const result = (
      await client.request(UpdateFundingRoundDocument, {
        round: prepareFundingRound(round),
        id,
      })
    ).result?.round;

    return fundingRoundSchema.parse(result);
  };

export type UpdateFundingRoundPromise = ReturnType<
  typeof createUpdateFundingRoundPromise
>;

export type UpdateFundingRoundPromiseResult = Awaited<
  ReturnType<UpdateFundingRoundPromise>
>;
