import { TourDate } from '../schemas/tourDates/tourDateSchema';
import { TourDateRegion } from '../schemas/tourDates/tourDateRegionSchema';

export type EditContext = {
  id: TourDate['id'];
  regionId: TourDate['tourDateCountry']['regionId'];
};

export type RefreshTourDateRegions = {
  type: 'REFRESH_TOUR_DATE_REGIONS';
  year: number;
  tourDateRegions: TourDateRegion[];
  editContext?: EditContext;
};

export const refreshTourDateRegions = (
  year: number,
  tourDateRegions: TourDateRegion[],

  editContext?: EditContext
): RefreshTourDateRegions => ({
  type: 'REFRESH_TOUR_DATE_REGIONS',
  year,
  tourDateRegions,
  editContext,
});

export const createEditContext = (
  tourDate?: TourDate
): EditContext | undefined =>
  tourDate !== undefined
    ? {
        id: tourDate.id,
        regionId: tourDate.tourDateCountry.regionId,
      }
    : undefined;
