import { GraphQLClient } from 'graphql-request';
import { z } from 'zod';
import { CreateAccountArtistBindingDocument } from '../../graphql/operations';
import { CreateAccountArtistBinding } from '../../events/CreateAccountArtistBinding';
import { Context } from '../../machines/AccountDetailsMachine';

export const createCreateAccountArtistBindingPromise =
  (client: GraphQLClient) =>
  async (variables: Context, { artistId }: CreateAccountArtistBinding) => {
    const request = await client.request(CreateAccountArtistBindingDocument, {
      accountId: variables.accountId,
      artistId,
    });

    return z
      .object({
        id: z.string(),
        name: z.string(),
      })
      .parse(request.result?.artistByArtistId);
  };

export type CreateAccountArtistBindingPromise = ReturnType<
  typeof createCreateAccountArtistBindingPromise
>;
export type CreateAccountArtistBindingPromiseResult = Awaited<
  ReturnType<CreateAccountArtistBindingPromise>
>;
