import { useMachine } from '@xstate/react';
import { EligibilityView } from './EligibilityView';
import {
  machine,
  EligibilityMachineOptions,
} from '../../machines/Eligibility/Machine';

type EligibilityControllerProps = {
  options: EligibilityMachineOptions;
};

export function EligibilityController({ options }: EligibilityControllerProps) {
  const [state, send] = useMachine(machine, options);

  return <EligibilityView state={state} send={send} />;
}
