import { GraphQLClient } from 'graphql-request';
import {
  GetAllArtistsPageDocument,
  GetAllArtistsPageQueryVariables,
} from '../graphql/operations';
import { z } from 'zod';
import { roundDetailsSchema } from '../schemas/roundDetailsSchema';

export const createGetAllArtistsPagePromise =
  (client: GraphQLClient, variables: GetAllArtistsPageQueryVariables) =>
  async () => {
    const request = await client.request(GetAllArtistsPageDocument, variables);

    return z
      .object({
        accountName: z.string(),
        artists: z.array(
          z.object({
            id: z.string().uuid(),
            name: z.string(),
          })
        ),
      })
      .merge(roundDetailsSchema)
      .parse({
        accountName: request.account?.name,
        artists: request.account?.artists,
        ...request,
      });
  };

export type GetAllArtistsPromise = ReturnType<
  typeof createGetAllArtistsPagePromise
>;
export type GetAllArtistsPromiseResult = Awaited<
  ReturnType<GetAllArtistsPromise>
>;
