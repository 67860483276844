import { GraphQLClient } from 'graphql-request';
import { UpdateBoardMeetingBlockDocument } from '../../graphql/operations';
import { Context } from '../../machines/BoardMeeting/BoardMeetingMachine';
import { boardMeetingBlockSchema } from '../../schemas/boardMeetingBlockSchema';

export const createUpdateBoardMeetingBlockPromise =
  (client: GraphQLClient) =>
  async (_: Context, input: { id: string; title: string; content: string }) => {
    const { result } = await client.request(UpdateBoardMeetingBlockDocument, {
      id: input.id,
      patch: { title: input.title, content: input.content },
    });

    return boardMeetingBlockSchema.parse(result?.block);
  };

export type UpdateBoardMeetingBlockPromise = ReturnType<
  typeof createUpdateBoardMeetingBlockPromise
>;
export type UpdateBoardMeetingBlockPromiseResult = Awaited<
  ReturnType<UpdateBoardMeetingBlockPromise>
>;
