import { Footer } from '../components/Footer';
import { HeroSection } from '../components/HeroSection';
import { ScheduleTable } from '../components/Tables/ScheduleTable';
import cn from 'classnames';
import { createGetHomeContextPromise } from '../promises/getHomeContextPromise';
import usePromise from 'react-use-promise';
import { useGraphQLClient } from '../components/GraphQLClientProvider';
import { Loading } from '../components/Loading';
import { withDelay } from '../utils/withDelay';
import { withErrorLogging } from '../utils/withErrorLogging';
import { ErrorPage } from './ErrorPage';

import styles from './Deadlines.module.scss';

export function Deadlines() {
  const client = useGraphQLClient();
  const [result, , state] = usePromise(
    withDelay(withErrorLogging(createGetHomeContextPromise(client))),
    []
  );

  if (state === 'pending') {
    return <Loading />;
  }

  if (state === 'rejected') {
    return <ErrorPage />;
  }

  return (
    <div className={styles.container}>
      <HeroSection
        title="Application Deadline & Board Meeting Dates"
        breadcrumbs={[{ text: 'Home', to: '/' }]}
      />
      <div className={cn('max-w-xl', styles.content)}>
        <ScheduleTable rounds={result.rounds} />
      </div>
      <Footer />
    </div>
  );
}
