import { useNavigate } from 'react-router-dom';

import {
  machine as ArtistMachine,
  createArtistResumeService,
  createArtistSaveActions,
} from '../../machines/RedeemAccountInvitation/ArtistMachine';
import {
  machine as ManagementMachine,
  createManagementResumeService,
  createManagementSaveActions,
} from '../../machines/RedeemAccountInvitation/ManagementMachine';
import { AddArtistController } from './AddArtistController';
import { useGraphQLClient } from '../../components/GraphQLClientProvider';

import { createGetAddArtistContextPromise } from '../../promises/getAddArtistContextPromise';
import { parseCompleteAddAccountState } from '../../machines/AddArtist/state';
import {
  createGuards,
  createResumeService,
  createRestartService,
  createSaveActions,
} from '../../machines/AddArtist/Machine';
import { createMemoryAddArtistStorage } from '../../machines/AddArtist/storage';
import { createBrowserAddArtistHistory } from '../../machines/AddArtist/history';

import { withDelay } from '../../utils/withDelay';
import { addArtistPromise } from '../../promises/addArtistPromise';

const storage = createMemoryAddArtistStorage();
const history = createBrowserAddArtistHistory();

export const AddArtist = () => {
  const client = useGraphQLClient();
  const navigate = useNavigate();

  return (
    <AddArtistController
      options={{
        guards: createGuards(storage),
        actions: {
          relocateToAllArtists: () => navigate(`/artists`),
          relocateToArtist: (_, { data: { artistId } }) =>
            navigate(`/artist/${artistId}`),
          ...createSaveActions(storage, history),
        },
        services: {
          resume: createResumeService(storage),
          restart: createRestartService(storage, history),
          getAddArtistContext: withDelay(
            createGetAddArtistContextPromise(client)
          ),
          addArtist: withDelay(() =>
            parseCompleteAddAccountState(storage.get()).then(
              addArtistPromise(client)
            )
          ),
          artistMachine: ArtistMachine.withConfig({
            services: { resume: createArtistResumeService(storage) },
            actions: createArtistSaveActions(storage),
          }),
          managementMachine: ManagementMachine.withConfig({
            services: { resume: createManagementResumeService(storage) },
            actions: createManagementSaveActions(storage),
          }),
        },
      }}
    />
  );
};
