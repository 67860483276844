import { GraphQLClient } from 'graphql-request';
import {
  GetRedeemAccountInvitationContextDocument,
  GetRedeemAccountInvitationContextQueryVariables,
  AccountInvitationStatusResponseCode,
} from '../graphql/operations';

import { InvitationInvalidStatusError } from '../errors/InvitationInvalidStatusError';
import { redeemAccountInvitationContextSchema } from '../schemas/redeemAccountInvitationContextSchema';

export const createGetRedeemAccountInvitationContext =
  (
    client: GraphQLClient,
    variables: GetRedeemAccountInvitationContextQueryVariables
  ) =>
  async () => {
    const result = await client.request(
      GetRedeemAccountInvitationContextDocument,
      variables
    );

    const {
      countries,
      provinces,
      legalStatuses,
      status: { code, email, isOrion },
      minimumPasswordLength,
    } = redeemAccountInvitationContextSchema.parse(result);

    if (code !== AccountInvitationStatusResponseCode.Success) {
      throw new InvitationInvalidStatusError();
    }

    return {
      tag: variables.tag,
      email,
      minimumPasswordLength,
      countries,
      provinces,
      legalStatuses,
      isOrion,
    };
  };

export type GetRedeemAccountInvitationContextPromise = ReturnType<
  typeof createGetRedeemAccountInvitationContext
>;
export type GetRedeemAccountInvitationContextPromiseResult = Awaited<
  ReturnType<GetRedeemAccountInvitationContextPromise>
>;

export const mockDoneData: GetRedeemAccountInvitationContextPromiseResult = {
  tag: 'tag',
  email: 'foo@example.com',
  minimumPasswordLength: 10,
  countries: [
    { id: 'CA', name: 'Canada', regionId: 'canada' },
    { id: 'US', name: 'USA', regionId: 'usa' },
  ],
  provinces: [
    { id: 'AB', province: 'Alberta' },
    { id: 'ON', province: 'Ontario' },
  ],
  legalStatuses: [
    {
      id: 'federal',
      name: 'Federal',
      requiresProvince: false,
      description: null,
      provinceTitle: null,
    },
    {
      id: 'provincial',
      name: 'Provincial',
      requiresProvince: true,
      description: null,
      provinceTitle: null,
    },
  ],
  isOrion: false,
};

export const mockErrorData: unknown = new Error('This is a mock error.');
