import { logError } from './logError';

export const withErrorLogging =
  <U extends Array<unknown>, T>(fn: (...args: U) => Promise<T>) =>
  (...args: U): Promise<T> =>
    fn(...args).catch((x) => {
      if (x instanceof Error) {
        logError(x);
      }
      throw x;
    });
