import { GraphQLClient } from 'graphql-request';
import { UpdateExpense } from '../../../events/Expense/UpdateExpense';
import { UpdateExpenseDocument } from '../../../graphql/operations';
import { prepareExpense } from './prepareExpense';
import { z } from 'zod';
import { fundingRequestSchema } from '../../../schemas/fundingRequests/fundingRequestSchema';

export const createUpdateExpensePromise =
  (client: GraphQLClient) =>
  async (_: unknown, { id, expense }: UpdateExpense) => {
    const response = await client.request(UpdateExpenseDocument, {
      id,
      expense: prepareExpense(expense),
    });

    return z
      .object({
        fundingRequest: fundingRequestSchema,
      })
      .parse(response.request);
  };

export type CreateUpdateExpensePromise = ReturnType<
  typeof createUpdateExpensePromise
>;

export type CreateUpdateExpensePromiseResult = Awaited<
  ReturnType<CreateUpdateExpensePromise>
>;
