import type { EventFrom, Sender } from 'xstate';
import { assign, createMachine } from 'xstate';
import type { StateFrom } from '../../utils/StateFrom';
import type { MachineOptionsWithContextFrom } from '../../utils/MachineOptionsWithContextFrom';
import { CreateApplicationPromiseResult } from '../../promises/createApplicationPromise';
import { GetAllApplicationsPageResult } from '../../promises/createGetAllApplicationsPagePromise';
import { isIdNotFound } from '../../errors/IdNotFoundError';
import { logMachineError } from '../../utils/logError';

export type Context = GetAllApplicationsPageResult;

type Events = { type: 'START_APPLICATION' };

type Services = {
  createApplicationPromise: {
    data: CreateApplicationPromiseResult;
  };
  getAllApplicationsPagePromise: {
    data: GetAllApplicationsPageResult;
  };
};

export function createContext(artistId: string): Context {
  return {
    artistId,
    artistName: '',
    canStartApplication: false,
    applications: [],
  };
}

export const machine = createMachine(
  {
    predictableActionArguments: true,
    tsTypes: {} as import('./AllApplicationsMachine.typegen').Typegen0,
    id: 'allApplicationsPage',
    schema: {
      context: {} as Context,
      events: {} as Events,
      services: {} as Services,
    },
    initial: 'init',
    states: {
      init: {
        invoke: {
          id: 'getAllApplicationsPagePromise',
          src: 'getAllApplicationsPagePromise',
          onDone: {
            actions: 'setContext',
            target: 'ready',
          },
          onError: [
            { cond: 'isIdNotFound', target: 'notFound' },
            { target: 'initError' },
          ],
        },
      },
      initError: { entry: 'logMachineError' },
      notFound: {},
      ready: {
        type: 'parallel',
        states: {
          application: {
            initial: 'ready',
            states: {
              ready: {
                on: {
                  START_APPLICATION: 'creating',
                },
              },
              creating: {
                invoke: {
                  id: 'createApplicationPromise',
                  src: 'createApplicationPromise',
                  onDone: { actions: 'navigateToApplication' },
                  onError: 'createApplicationError',
                },
              },
              createApplicationError: { entry: 'logMachineError' },
            },
          },
        },
      },
    },
  },
  {
    guards: {
      isIdNotFound: (_, { data }) => isIdNotFound(data),
    },
    actions: { logMachineError, setContext: assign((_, { data }) => data) },
  }
);

type Machine = typeof machine;

export type AllApplicationsMachineState = StateFrom<Machine>;
export type AllApplicationsMachineSender = Sender<EventFrom<Machine>>;
export type AllApplicationsMachineOptions =
  MachineOptionsWithContextFrom<Machine>;
