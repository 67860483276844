import { ZonedDateTime } from '@internationalized/date';
import { useActor } from '@xstate/react';
import { ApplicationStage } from '../../graphql/operations';
import {
  ApplicationMachineSender,
  ApplicationMachineState,
} from '../../machines/Application/ApplicationMachine';
import { DirectDepositsMachineActor } from '../../machines/ClaimFunds/DirectDepositsMachine';
import { SignedContractMachineActor } from '../../machines/ClaimFunds/SignedContractMachine';
import { TourSummaryMachineActor } from '../../machines/ClaimFunds/TourSummaryMachine';
import { ClaimFundsView } from './ClaimFundsView';

export function ClaimFundsController({
  stage,
  isOwner,
  isClaimsReopenable,
  reopenClaimsNotes,
  claimsReopenedAt,
  claimsResubmissionDeadline,
  claimsResubmittedAt,
  hasApprovedFundingRequests,
  directDepositsActor,
  signedContractActor,
  tourSummaryActor,
  claimedTotalForTourDates,
  claimedTotalForFundingRequests,
  claimedTotal,
  applicationState,
  applicationSend,
}: {
  stage: ApplicationStage;
  claimedTotalForTourDates: number;
  claimedTotalForFundingRequests: number;
  claimedTotal: number;
  claimsReopenedAt: ZonedDateTime | null;
  claimsResubmissionDeadline: ZonedDateTime | null;
  claimsResubmittedAt: ZonedDateTime | null;
  hasApprovedFundingRequests: boolean;
  isOwner: boolean;
  isClaimsReopenable: boolean;
  reopenClaimsNotes: string | null;
  directDepositsActor: DirectDepositsMachineActor;
  signedContractActor: SignedContractMachineActor;
  tourSummaryActor: TourSummaryMachineActor;
  applicationState: ApplicationMachineState;
  applicationSend: ApplicationMachineSender;
}) {
  const [directDepositsState, directDepositsSend] =
    useActor(directDepositsActor);
  const [signedContractState, signedContractSend] =
    useActor(signedContractActor);
  const [tourSummaryState, tourSummarySend] = useActor(tourSummaryActor);

  return (
    <ClaimFundsView
      stage={stage}
      claimedTotalForTourDates={claimedTotalForTourDates}
      claimedTotalForFundingRequests={claimedTotalForFundingRequests}
      claimedTotal={claimedTotal}
      isOwner={isOwner}
      isClaimsReopenable={isClaimsReopenable}
      claimsReopenedAt={claimsReopenedAt}
      claimsResubmissionDeadline={claimsResubmissionDeadline}
      claimsResubmittedAt={claimsResubmittedAt}
      hasApprovedFundingRequests={hasApprovedFundingRequests}
      reopenClaimsNotes={reopenClaimsNotes}
      directDepositsState={directDepositsState}
      directDepositsSend={directDepositsSend}
      signedContractState={signedContractState}
      signedContractSend={signedContractSend}
      tourSummaryState={tourSummaryState}
      tourSummarySend={tourSummarySend}
      applicationState={applicationState}
      applicationSend={applicationSend}
    />
  );
}
