import { ReactNode } from 'react';
import { Icon } from './Icon';

import styles from './InfoBox.module.scss';

interface InfoBoxProps {
  children: ReactNode;
}

export function InfoBox({ children }: InfoBoxProps) {
  return (
    <div className={styles.container}>
      <Icon name="infoCircle" />
      <p className={styles.text}>{children}</p>
    </div>
  );
}
