import { z } from 'zod';

export const legalStatusSchema = z.object({
  id: z.string(),
  name: z.string(),
  description: z.string().nullable(),
  requiresProvince: z.boolean(),
  provinceTitle: z.string().nullable(),
});

export type LegalStatus = z.infer<typeof legalStatusSchema>;
