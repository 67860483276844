import { P, match } from 'ts-pattern';
import classNames from 'classnames';
import { TextInput } from '../../TextInput';
import { PageHeader } from '../../PageHeader';
import {
  ArtistMachineSender,
  ArtistMachineState,
} from '../../../machines/RedeemAccountInvitation/ArtistMachine';
import { FormField } from '../../FormField';
import { Select } from '../../Select';
import { RadioOption } from '../../RadioOption';
import { Button } from '../../Button';
import { Feedback } from '../../Feedback';
import { Form } from '../../Form';
import { FormControls } from '../../FormControls';

import styles from './ArtistView.module.scss';

interface ArtistViewProps {
  state: ArtistMachineState;
  send: ArtistMachineSender;
}

export function ArtistView({ state, send }: ArtistViewProps) {
  return (
    <Form className={styles.container} onSubmit={() => send('NEXT')}>
      <PageHeader
        heading={
          state.context.isAddArtist
            ? 'Add a new artist or group'
            : 'Artist or group'
        }
        paginationActive={state.context.isAddArtist ? 1 : 3}
        paginationSteps={match([
          state.context.isAddArtist,
          state.context.artistIsSelfManaged,
        ])
          .with([true, false], () => 2)
          // Hide the pagination until we know you are not self managed.
          .with([true, P._], () => undefined)
          .with([false, false], () => 4)
          .with([false, P._], () => 3)
          .exhaustive()}
        includeBackButton={true}
        backButtonOnClick={() => send('BACK')}
        isOrion={state.context.isOrion}
      >
        {state.context.isAddArtist
          ? 'Tell us about the artist or group.'
          : 'Tell us about yourself as an artist/group.'}
      </PageHeader>
      <div className={classNames(styles.formFields, 'max-w-md')}>
        <FormField
          htmlFor="artist-name"
          label="Artist or group name"
          formFieldStatus={
            state.matches('form.pending.artist-name.empty')
              ? 'error'
              : 'default'
          }
          feedbackType="warning"
          infoLabel={
            state.matches('form.pending.artist-name.empty')
              ? 'This field is required.'
              : ''
          }
        >
          <TextInput
            id="artist-name"
            label="Artist or group name"
            value={state.context.artistName}
            onFocus={() => send('FOCUS_ARTIST_NAME')}
            onChange={(value) => send({ type: 'UPDATE_ARTIST_NAME', value })}
            onBlur={() => send('BLUR_ARTIST_NAME')}
          />
        </FormField>
        <FormField
          htmlFor="place-of-residence-toggle-button"
          label="Primary place of residence"
          description="Does not affect eligibility"
          formFieldStatus={
            state.matches('form.pending.artist-province.empty')
              ? 'error'
              : 'default'
          }
          feedbackType="warning"
          infoLabel={
            state.matches('form.pending.artist-province.empty')
              ? 'This field is required.'
              : ''
          }
        >
          <Select
            id="place-of-residence"
            label="Choose an option"
            items={state.context.provinces}
            itemToKey={(item) => item?.id}
            getItemText={({ province }) => province}
            selectedItem={
              state.context.artistProvince !== undefined
                ? state.context.artistProvince
                : null
            }
            onChange={(value) =>
              send({
                type: 'UPDATE_ARTIST_PROVINCE',
                value,
              })
            }
          />
        </FormField>
        <FormField
          htmlFor="artist-company-name"
          label="Artist's company name"
          formFieldStatus={
            state.matches('form.pending.company-name.empty')
              ? 'error'
              : 'default'
          }
          feedbackType="warning"
          infoLabel={
            state.matches('form.pending.company-name.empty')
              ? 'This field is required.'
              : ''
          }
        >
          <TextInput
            id="artist-company-name"
            label="Artist's company name"
            value={state.context.companyName}
            onFocus={() => send('FOCUS_COMPANY_NAME')}
            onChange={(value) => send({ type: 'UPDATE_COMPANY_NAME', value })}
            onBlur={() => send('BLUR_COMPANY_NAME')}
          />
        </FormField>
        <FormField
          htmlFor="legal"
          label="Company's legal status"
          formFieldStatus={
            state.matches('form.pending.company-legal-status.empty')
              ? 'error'
              : 'default'
          }
          feedbackType="warning"
          infoLabel={
            state.matches('form.pending.company-legal-status.empty')
              ? 'This field is required.'
              : ''
          }
        >
          <>
            {state.context.legalStatuses.map((value) => (
              <RadioOption
                key={value.id}
                label={value.name}
                description={value.description ?? undefined}
                checked={state.context.companyLegalStatus?.id === value.id}
                onChange={() =>
                  send({
                    type: 'UPDATE_COMPANY_LEGAL_STATUS',
                    value,
                  })
                }
              />
            ))}
          </>
        </FormField>
        {state.context.companyLegalStatus?.requiresProvince && (
          <FormField
            htmlFor="province-of-incorporation-toggle-button"
            id="company-legal-province"
            label={state.context.companyLegalStatus.provinceTitle ?? undefined}
            formFieldStatus={
              state.matches('form.pending.company-legal-status.partial.empty')
                ? 'error'
                : 'default'
            }
            feedbackType="warning"
            infoLabel={
              state.matches('form.pending.company-legal-status.partial.empty')
                ? 'This field is required.'
                : ''
            }
          >
            <Select
              id="company-legal-province"
              label="Choose an option"
              items={state.context.provinces}
              itemToKey={(item) => item?.id}
              getItemText={({ province }) => province}
              selectedItem={
                state.context.companyLegalProvince !== undefined
                  ? state.context.companyLegalProvince
                  : null
              }
              onChange={(value) =>
                send({
                  type: 'UPDATE_COMPANY_LEGAL_PROVINCE',
                  value,
                })
              }
            />
          </FormField>
        )}
        <FormField
          label="Artist/group management"
          formFieldStatus={
            state.matches('form.pending.is-self-managed.empty')
              ? 'error'
              : 'default'
          }
          feedbackType="warning"
          infoLabel={
            state.matches('form.pending.is-self-managed.empty')
              ? 'This field is required.'
              : ''
          }
        >
          <>
            <RadioOption
              label="Management Company"
              checked={state.context.artistIsSelfManaged === false}
              onChange={() =>
                send({
                  type: 'UPDATE_ARTIST_IS_SELF_MANAGED',
                  value: false,
                })
              }
            />
            <RadioOption
              label="Self-Managed"
              checked={state.context.artistIsSelfManaged === true}
              onChange={() =>
                send({
                  type: 'UPDATE_ARTIST_IS_SELF_MANAGED',
                  value: true,
                })
              }
            />
          </>
        </FormField>
      </div>
      <FormControls className="max-w-lg">
        {(state.matches('form.pending.warning.on') ||
          state.matches('form.pending.warning.pulse')) && (
          <Feedback
            type="warning"
            pulse={state.matches('form.pending.warning.pulse')}
          >
            Please fill in all the fields above.
          </Feedback>
        )}
        <div className="max-w-md">
          <Button
            {...(state.context.artistIsSelfManaged === false && {
              endIcon: 'rightChevron',
            })}
            label={
              state.context.artistIsSelfManaged === false
                ? 'Next'
                : 'Proceed to Artist Dashboard'
            }
            type="submit"
          />
        </div>
      </FormControls>
    </Form>
  );
}
