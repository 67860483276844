// Almost all of the promises we're using in xstate aren't
// promises but functions that return a promise. This will
// allow us to wrap those functions in the delay.
export function withDelay<U extends Array<unknown>, T>(
  fn: (...args: U) => Promise<T>,
  delay = 500
) {
  return (...args: U): Promise<T> => {
    let done = false;
    let error = false;
    let thrown: unknown = null;
    let result: T;

    fn(...args)
      .then((r) => {
        done = true;
        result = r;
      })
      .catch((e) => {
        error = true;
        thrown = e;
      });

    return new Promise<T>((resolve, reject) => {
      const interval = setInterval(() => {
        if (done) {
          clearInterval(interval);
          resolve(result);
        }

        if (error) {
          clearInterval(interval);
          reject(thrown);
        }
      }, delay);
    });
  };
}
