import cn from 'classnames';
import { ChangeEvent, useId } from 'react';

import styles from './RadioOption.module.scss';

interface RadioOptionProps {
  description?: string;
  checked: boolean;
  label: string | React.ReactElement;
  error?: boolean;
  disabled?: boolean;
  value?: string;
  autoFocus?: boolean;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  onFocus?: () => void;
}

export function RadioOption({
  description,
  checked,
  label,
  error,
  disabled = false,
  value,
  autoFocus,
  onChange,
  onFocus,
}: RadioOptionProps) {
  const id = useId();
  return (
    <div
      className={cn(styles.radioOption, {
        [styles.withoutDescription]: !description,
      })}
    >
      <input
        type="radio"
        // See https://stackoverflow.com/a/71848275
        // eslint-disable-next-line jsx-a11y/no-autofocus
        autoFocus={autoFocus}
        className={cn(styles.radioOption, {
          [styles.disabled]: disabled,
          [styles.error]: error,
        })}
        id={id}
        onChange={onChange}
        onFocus={onFocus}
        disabled={disabled}
        value={value}
        checked={checked}
      />
      <label htmlFor={id} className={styles.label}>
        {label}
        {description && (
          <span className={styles.description}>{description}</span>
        )}
      </label>
    </div>
  );
}
