import { NewsletterView } from './NewsletterView';
import {
  machine,
  NewsletterMachineOptions,
} from '../../../machines/components/NewsletterMachine';
import { useMachine } from '@xstate/react';

interface NewsletterMachineControllerProps {
  options: NewsletterMachineOptions;
}

export function NewsletterController({
  options,
}: NewsletterMachineControllerProps) {
  const [state, send] = useMachine(machine, options);

  return <NewsletterView state={state} send={send} />;
}
