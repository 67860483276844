import { useGraphQLClient } from '../../components/GraphQLClientProvider';

import { createPersistentAddReleaseStorage } from '../../machines/AddRelease/storage';
import { createBrowserAddReleaseHistory } from '../../machines/AddRelease/history';

import { AddReleaseController } from './AddReleaseController';

import {
  createResumeService,
  createRestartService,
  createSaveActions,
} from '../../machines/AddRelease/Machine';

import {
  machine as ReleaseMachine,
  createReleaseResume,
  createReleaseSaveActions,
} from '../../machines/Eligibility/ReleaseMachine';

import {
  machine as SalesMachine,
  createSalesResume,
  createSalesSaveActions,
} from '../../machines/Eligibility/SalesMachine';

import {
  machine as MasterRecordingMachine,
  createMasterRecordingResume,
  createMasterRecordingSaveActions,
} from '../../machines/Eligibility/MasterRecordingMachine';

import { createGetAddReleaseContextPromise } from '../../promises/getAddReleaseContextPromise';
import { parseCompleteAddReleaseState } from '../../machines/AddRelease/state';
import {
  createCreateReleasePromise,
  loadCreateReleaseMutationVariablesFromAddReleaseStorage,
} from '../../promises/createReleasePromise';
import { useNavigate, useParams } from 'react-router-dom';
import { withDelay } from '../../utils/withDelay';

const storage = createPersistentAddReleaseStorage();
const history = createBrowserAddReleaseHistory();

export const AddRelease = () => {
  const client = useGraphQLClient();
  const { artistId } = useParams();
  const navigate = useNavigate();

  if (!artistId) {
    navigate('/dashboard');
    return null;
  }

  return (
    <AddReleaseController
      artistId={artistId}
      options={{
        actions: {
          ...createSaveActions(storage, history),
          navigateToReleases: () => {
            navigate(`/artist/${artistId}/releases`);
          },
        },
        services: {
          resume: createResumeService(storage),
          restart: createRestartService(storage, history),
          reset: () => Promise.resolve(storage.reset()),
          getAddReleaseContextPromise: withDelay(
            createGetAddReleaseContextPromise(client)
          ),
          addReleasePromise: withDelay(() =>
            parseCompleteAddReleaseState(storage.get()).then((state) =>
              createCreateReleasePromise(client)(
                loadCreateReleaseMutationVariablesFromAddReleaseStorage(
                  artistId,
                  state
                )
              )
            )
          ),
          releaseMachine: ReleaseMachine.withConfig({
            actions: createReleaseSaveActions(storage),
            services: {
              resume: createReleaseResume(storage),
            },
          }),
          salesMachine: SalesMachine.withConfig({
            actions: createSalesSaveActions(storage),
            services: {
              resume: createSalesResume(storage),
            },
          }),
          masterRecordingMachine: MasterRecordingMachine.withConfig({
            actions: createMasterRecordingSaveActions(storage),
            services: {
              resume: createMasterRecordingResume(storage),
            },
          }),
        },
      }}
    />
  );
};
