import { z } from 'zod';

export const accountDetailsSchema = z.object({
  name: z.string(),
  phoneNumber: z.string(),
  email: z.string(),
  address: z.string(),
  createdAt: z.string(),
  artists: z
    .object({
      artist: z.object({
        id: z.string().uuid(),
        name: z.string(),
      }),
    })
    .array(),
});

export type AccountDetails = z.infer<typeof accountDetailsSchema>;
