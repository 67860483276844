import { ReactNode } from 'react';
import { Icon, IconName } from './Icon';
import classNames from 'classnames';
import Tippy from '@tippyjs/react/headless';
import styles from './Tooltip.module.scss';

export type Status = 'default' | 'hover' | 'focus' | 'active';

interface TooltipProps {
  label?: string;
  status: Status;
  icon: IconName;
  children: ReactNode;
}

export function Tooltip({ label, status, icon, children }: TooltipProps) {
  const circleClassName = classNames(styles.circle, styles[status]);

  return (
    <span className={styles.container}>
      <span className={styles.label}>{label}</span>
      <Tippy
        render={(attrs) => (
          <div className={styles.tooltipContent} tabIndex={-1} {...attrs}>
            {children}
            <div id="arrow" data-popper-arrow className={styles.arrow}></div>
          </div>
        )}
        hideOnClick={false}
      >
        <button className={styles[status]} type="button">
          <div className={circleClassName}>
            <Icon name={icon} className={styles.icon} />
          </div>
        </button>
      </Tippy>
    </span>
  );
}
