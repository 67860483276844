import { z } from 'zod';
import { GraphQLClient } from 'graphql-request';
import { CreateNewApplicationDocument } from '../graphql/operations';

export const createApplicationPromise =
  (client: GraphQLClient, artistId: string) => async () => {
    return client
      .request(CreateNewApplicationDocument, { artistId })
      .then((result) =>
        z
          .string()
          .parseAsync(
            result.createNewApplication?.createApplicationResponse?.id
          )
      );
  };

export type CreateApplicationPromise = ReturnType<
  typeof createApplicationPromise
>;
export type CreateApplicationPromiseResult = Awaited<
  ReturnType<CreateApplicationPromise>
>;
