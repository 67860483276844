import { ApplicationSearchController } from './ApplicationSearchController';
import { useGraphQLClient } from '../../components/GraphQLClientProvider';
import { createSearchApplicationsPromise } from '../../promises/createSearchApplicationsPromise';
import { createGetApplicationSearchPagePromise } from '../../promises/createGetApplicationSearchPageProimse';
import { Loading } from '../../components/Loading';
import { withDelay } from '../../utils/withDelay';
import usePromise from 'react-use-promise';
import { ErrorPage } from '../ErrorPage';
import { useNavigate } from 'react-router-dom';
import { withErrorLogging } from '../../utils/withErrorLogging';

interface ApplicationSearchProps {
  isBoardView?: boolean;
}

export function ApplicationSearch({ isBoardView }: ApplicationSearchProps) {
  const client = useGraphQLClient();
  const navigate = useNavigate();
  const [data, , promiseState] = usePromise(
    withDelay(withErrorLogging(createGetApplicationSearchPagePromise(client))),
    []
  );

  if (promiseState === 'pending') {
    return <Loading />;
  }

  if (promiseState === 'rejected' || !data.fundingRounds) {
    return <ErrorPage />;
  }

  if (isBoardView && !data.currentBoardRound) {
    navigate('/board-meeting');
  }

  return (
    <ApplicationSearchController
      options={{
        services: {
          searchApplicationsPromise: createSearchApplicationsPromise(client),
        },
      }}
      fundingRoundTitles={(isBoardView ? [] : ['ALL', 'NOT_SUBMITTED']).concat(
        data.fundingRounds.map((f) => f.title)
      )}
      isBoardView={isBoardView}
      boardRound={data.currentBoardRound?.title}
    />
  );
}
