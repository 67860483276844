import { ArtistStatus } from '../../graphql/operations';
import { GetDashboardContextPromiseResult } from '../../promises/getDashboardContextPromise';
import { ActiveApplicationCard } from '../ActiveApplicationCard';
import { Button } from '../Button';
import { Feedback } from '../Feedback';

import styles from './ActiveApplicationsBlock.module.scss';

interface ActiveApplicationsProps {
  artist: GetDashboardContextPromiseResult['artist'];
  applications: GetDashboardContextPromiseResult['activeApplications'];
  canStartApplication: boolean;
  isOwner: boolean;
  startApplication: () => void;
}

export function ActiveApplicationsBlock({
  artist,
  applications,
  canStartApplication,
  isOwner,
  startApplication,
}: ActiveApplicationsProps) {
  return (
    <>
      <div className={styles.header}>
        <h3>Active Applications</h3>
        {applications.length > 0 && isOwner && (
          <Button
            disabled={!canStartApplication}
            startIcon={canStartApplication ? 'plus' : undefined}
            variant="secondary"
            label="Start an Application"
            onClick={startApplication}
            size="small"
          />
        )}
      </div>
      {!applications.length && (
        <Feedback
          title="You don't have any active applications."
          type="primary"
          icon="star"
        >
          <Button
            className={styles.button}
            disabled={!canStartApplication}
            label={
              artist.status === ArtistStatus.Core
                ? 'Start an Application'
                : 'Start an Orion Application'
            }
            length="auto"
            onClick={startApplication}
          />
        </Feedback>
      )}
      {applications.map((application) => (
        <ActiveApplicationCard
          key={application.id}
          application={application}
          applicationLink={`/artist/${artist.id}/application/${application.id}`}
        />
      ))}
    </>
  );
}
