import {
  ExpenseMachineSender,
  ExpenseMachineState,
} from '../../../machines/components/ExpenseMachine';
import { formatDate } from '../../../utils/formatDate';
import { formatNumber } from '../../../utils/formatNumber';
import { parsePositiveFloat } from '../../../utils/parsePositiveFloat';
import { Button } from '../../Button';
import { DescriptionList } from '../../DescriptionList';

import styles from './ExpenseCard.module.scss';

interface ExpenseCardProps {
  state: ExpenseMachineState;
  send: ExpenseMachineSender;
  showEdit: boolean;
  showDelete: boolean;
}

export function ExpenseCardView({
  state,
  send,
  showEdit,
  showDelete,
}: ExpenseCardProps) {
  const { expense } = state.context;

  return (
    <div className={styles.content}>
      <div className={styles.row}>
        <DescriptionList
          list={{ 'Vendor Name': expense.vendor }}
          fontSize="body"
        />
        <DescriptionList
          list={{
            'Spent Amount':
              '$' + formatNumber(parsePositiveFloat(expense.cost)),
          }}
          fontSize="body"
        />
      </div>
      <div className={styles.row}>
        <DescriptionList
          list={{ 'Invoice Number': expense.invoiceNumber }}
          fontSize="body"
        />
        <DescriptionList
          list={{ 'Invoice Date': formatDate(expense.date?.toString() ?? '') }}
          fontSize="body"
        />
      </div>
      <div>
        <div className={styles.expenseLabel}>Expense description:</div>
        <p className={styles.expenseDescription}>{expense.description}</p>
      </div>
      {(showEdit || showDelete) && (
        <div className={styles.buttonRow}>
          {showEdit && (
            <Button
              label="Edit"
              startIcon="edit"
              size="small"
              variant="secondary"
              onClick={() => send({ type: 'OPEN_EXPENSE' })}
            />
          )}
          {showDelete && (
            <Button
              label="Delete"
              size="small"
              variant="neutral"
              onClick={() => {
                if (
                  window.confirm(
                    'Are you sure you want to delete this expense?'
                  ) &&
                  state.context.editContext?.id
                ) {
                  send({
                    type: 'DELETE_EXPENSE',
                    id: state.context.editContext?.id,
                  });
                }
              }}
            />
          )}
        </div>
      )}
    </div>
  );
}
