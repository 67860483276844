import { GraphQLClient } from 'graphql-request';
import {
  CreateArtistInvitationDocument,
  CreateArtistInvitationMutationVariables,
  CreateArtistInvitationResponseCode,
} from '../graphql/operations';
import { z } from 'zod';

export const isInvalidRequest = ({
  code,
}: CreateArtistInvitationPromiseResult) =>
  code === CreateArtistInvitationResponseCode.InvalidRequest;

export const isSuccess = ({ code }: CreateArtistInvitationPromiseResult) =>
  code === CreateArtistInvitationResponseCode.Success;

export const createArtistInvitationPromise =
  (client: GraphQLClient) =>
  async (variables: CreateArtistInvitationMutationVariables) => {
    const response = await client.request(
      CreateArtistInvitationDocument,
      variables
    );

    return z
      .object({
        code: z.string(),
        email: z.string(),
        invitationId: z.string().uuid(),
      })
      .parseAsync(response.result?.response);
  };

export type CreateArtistInvitationPromise = ReturnType<
  typeof createArtistInvitationPromise
>;

export type CreateArtistInvitationPromiseResult = Awaited<
  ReturnType<CreateArtistInvitationPromise>
>;
