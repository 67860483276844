import { useActor } from '@xstate/react';
import { AdminBoardMeetingBlockMachineActor } from '../../../machines/BoardMeeting/AdminBoardMeetingBlockMachine';
import { AdminBoardMeetingBlockView } from './AdminBoardMeetingBlockView';

type AdminBoardMeetingBlockControllerProps = {
  actor: AdminBoardMeetingBlockMachineActor;
  isFirstBlock?: boolean;
  isLastBlock?: boolean;
};

export function AdminBoardMeetingBlockController({
  actor,
  isFirstBlock,
  isLastBlock,
}: AdminBoardMeetingBlockControllerProps) {
  const [state, send] = useActor(actor);

  return (
    <AdminBoardMeetingBlockView
      state={state}
      send={send}
      isFirstBlock={isFirstBlock}
      isLastBlock={isLastBlock}
    />
  );
}
