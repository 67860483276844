import { match } from 'ts-pattern';
import { ApplicationCard } from '../../components/Dashboard/ApplicationCard';
import { IndexPage } from '../../components/IndexPage';
import { Loading } from '../../components/Loading';
import { InfoBox } from '../../components/InfoBox';
import {
  AllApplicationsMachineSender,
  AllApplicationsMachineState,
} from '../../machines/Application/AllApplicationsMachine';
import { ErrorPage } from '../ErrorPage';
import { NotFound } from '../NotFound';
import { ErrorFeedback } from '../../components/ErrorFeedback';
import { ApplicationStage } from '../../graphql/operations';

import styles from './AllApplicationsView.module.scss';

interface AllApplicationsViewProps {
  state: AllApplicationsMachineState;
  send: AllApplicationsMachineSender;
  showBoardVersion: boolean;
}

export function AllApplicationsView({
  state,
  send,
  showBoardVersion,
}: AllApplicationsViewProps) {
  if (state.matches('init')) {
    return <Loading />;
  }

  if (state.matches('initError')) {
    return <ErrorPage />;
  }

  if (state.matches('notFound')) {
    return <NotFound />;
  }

  const { artistId, artistName, applications, canStartApplication } =
    state.context;

  return (
    <IndexPage
      breadcrumbs={match({ showBoardVersion })
        .with({ showBoardVersion: true }, () => [
          { text: 'Board Meeting', to: '/board-meeting' },
          {
            text: 'Search Applications',
            to: '/board-meeting/applications',
          },
          {
            text: artistName,
            to: `/artist/${artistId}`,
          },
        ])
        .otherwise(() => [{ text: 'Dashboard', to: `/artist/${artistId}` }])}
      heading={
        <>
          <span className="text-normal">All Applications for </span>
          {artistName}
        </>
      }
      buttonText="Start an Application"
      buttonDisabled={!canStartApplication}
      buttonHidden={showBoardVersion}
      buttonOnClick={() => {
        send('START_APPLICATION');
      }}
    >
      {state.matches('ready.application.createApplicationError') && (
        <ErrorFeedback className={styles.error} />
      )}
      {applications.length ? (
        applications.map((application) => (
          <ApplicationCard
            key={application.id}
            type={application.type}
            round={application.fundingRound?.title}
            status={application.status}
            submittedAt={application.submittedAt}
            createdAt={application.createdAt}
            applicationLink={`/artist/${artistId}/application/${application.id}`}
            isComplete={application.stage === ApplicationStage.Completed}
          />
        ))
      ) : (
        <InfoBox>You don’t have any applications.</InfoBox>
      )}
    </IndexPage>
  );
}
