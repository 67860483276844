import { GraphQLClient } from 'graphql-request';
import {
  UpdateArtistInvitationDocument,
  UpdateArtistInvitationMutationVariables,
  UpdateArtistInvitationMutation,
  UpdateArtistInvitationResponseCode,
} from '../graphql/operations';
import { CompleteTransferArtistInvitationState } from '../machines/RedeemArtistInvitation/state';

export const isInvalidRequest = ({ result }: UpdateArtistInvitationMutation) =>
  result?.response?.code === UpdateArtistInvitationResponseCode.InvalidRequest;

export const isSuccess = ({ result }: UpdateArtistInvitationMutation) =>
  result?.response?.code === UpdateArtistInvitationResponseCode.Success;

export const updateArtistInvitationPromise =
  (client: GraphQLClient) =>
  (tag: Pick<UpdateArtistInvitationMutationVariables, 'tag'>['tag']) =>
  ({ email }: CompleteTransferArtistInvitationState) =>
    client.request(UpdateArtistInvitationDocument, { tag, email });

export type UpdateArtistInvitationPromiseResult = Awaited<
  ReturnType<ReturnType<ReturnType<typeof updateArtistInvitationPromise>>>
>;
