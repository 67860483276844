import classNames from 'classnames';
import {
  PasswordMachineSender,
  PasswordMachineState,
} from '../../../machines/RedeemInvitation/PasswordMachine';

import { Button } from '../../../components/Button';
import { TextInput } from '../../../components/TextInput';
import { PageHeader } from '../../../components/PageHeader';
import { Feedback } from '../../../components/Feedback';
import { FormField } from '../../../components/FormField';
import { Form } from '../../Form';

import styles from './PasswordView.module.scss';

interface RedeemInvitationProps {
  state: PasswordMachineState;
  send: PasswordMachineSender;
}

export function PasswordView({ state, send }: RedeemInvitationProps) {
  return (
    <Form
      className={classNames(styles.container, 'max-w-lg')}
      onSubmit={() => {
        send('SUBMIT');
      }}
    >
      <PageHeader
        heading="Choose a password"
        paginationActive={1}
        paginationSteps={3}
        isOrion={state.context.isOrion}
      />
      <Feedback type="positive" icon="checkCircle">
        <span className="body-text-bold">
          Your {state.context.email} address has been confirmed
        </span>
      </Feedback>
      <div className={classNames(styles.formFields, 'max-w-md')}>
        <FormField
          label="Choose a password"
          description="Minimum of 12 characters"
          formFieldStatus={
            state.matches('password.error.empty') ||
            state.matches('password.error.invalid')
              ? 'error'
              : 'default'
          }
        >
          <TextInput
            id="password"
            type={state.context.showPassword ? 'text' : 'password'}
            icon={state.context.showPassword ? 'eyeOn' : 'eyeOff'}
            onIconClick={() => send({ type: 'TOGGLE_PASSWORD_VISIBILITY' })}
            label="Password"
            buttonLabel="Show password"
            value={state.context.password}
            onChange={(value) => send({ type: 'UPDATE_PASSWORD', value })}
          />
        </FormField>
        <FormField
          label="Confirm your new password"
          formFieldStatus={
            state.matches('confirmPassword.error') ? 'error' : 'default'
          }
        >
          <TextInput
            id="confirmPassword"
            type={state.context.showConfirmPassword ? 'text' : 'password'}
            icon={state.context.showConfirmPassword ? 'eyeOn' : 'eyeOff'}
            onIconClick={() =>
              send({ type: 'TOGGLE_CONFIRM_PASSWORD_VISIBILITY' })
            }
            label="Confirm Password"
            buttonLabel="Show confirm password"
            value={state.context.confirmPassword}
            error={state.matches('confirmPassword.error')}
            onChange={(value) =>
              send({ type: 'UPDATE_CONFIRM_PASSWORD', value })
            }
          />
        </FormField>
      </div>
      {(state.matches('password.error.empty') ||
        state.matches('confirmPassword.error.empty')) && (
        <Feedback type="warning" className="max-w-lg">
          Passwords can’t be empty.
        </Feedback>
      )}
      {state.matches('confirmPassword.error.mismatch') && (
        <Feedback type="warning" className="max-w-lg">
          Your passwords do not match.
        </Feedback>
      )}
      {state.matches('password.error.invalid') && (
        <Feedback type="warning" className="max-w-lg">
          Passwords must be at least {state.context.minimumPasswordLength}{' '}
          characters long.
        </Feedback>
      )}
      <div className="controls max-w-md">
        <Button type="submit" label="Next" endIcon="rightChevron" />
      </div>
    </Form>
  );
}
