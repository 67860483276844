import styles from './ExampleCheque.module.scss';
import cn from 'classnames';

export function ExampleCheque() {
  return (
    <div className={styles.container}>
      <div className={styles.cheque}>
        <div className={styles.chequeTop}>
          <div className={styles.bank}>
            <div className={styles.bankNameText}>First Starmaker Bank</div>
            <div className={styles.bankAddressText}>
              1234 Main Street
              <br />
              Anywhere, ON K1A 0B1
            </div>
          </div>
          <div className={styles.memo}>
            <span className={styles.memoText}>Memo</span>
            <div className={styles.memoLine} />
          </div>
        </div>
        <div className={cn(styles.chequeBottom, styles.numberText)}>
          <span>123</span>
          <div className={styles.transitAndInstitutionNumber}>
            <span className={styles.transitNumber}>
              12345
              <div className={styles.downLine} />
            </span>
            <span className={styles.institutionNumber}>
              098
              <div className={styles.downLine} />
            </span>
          </div>
          <span className={styles.accountNumber}>
            1234567
            <div className={styles.downLine} />
          </span>
        </div>
      </div>
      <div className={styles.legend}>
        <div className={styles.legendItem}>
          <span
            className={cn(
              styles.legendLabelText,
              styles.bankTransitLegendLabel
            )}
          >
            Transit
            <br className={styles.mobileBreak} /> Number
          </span>
          <span className={styles.legendNote}>4 or 5 digits</span>
        </div>
        <div className={styles.legendItem}>
          <span
            className={cn(
              styles.legendLabelText,
              styles.institutionNumberLegendLabel
            )}
          >
            Institution
            <br className={styles.mobileBreak} /> Number
          </span>
          <span className={styles.legendNote}>3 digits</span>
        </div>
        <div className={styles.legendItem}>
          <span
            className={cn(
              styles.legendLabelText,
              styles.accountNumberLegendLabel
            )}
          >
            Account
            <br className={styles.mobileBreak} /> Number
          </span>
          <span className={styles.legendNote}>7 to 12 digits</span>
        </div>
      </div>
    </div>
  );
}
