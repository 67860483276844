import { GraphQLClient } from 'graphql-request';
import { z } from 'zod';
import { DeleteAccountArtistBindingDocument } from '../../graphql/operations';
import { DeleteAccountArtistBinding } from '../../events/DeleteAccountArtistBinding';
import { Context } from '../../machines/AccountDetailsMachine';

export const createDeleteAccountArtistBindingAdminPromise =
  (client: GraphQLClient) =>
  async (variables: Context, { artistId }: DeleteAccountArtistBinding) => {
    const request = await client.request(DeleteAccountArtistBindingDocument, {
      accountId: variables.accountId,
      artistId,
    });

    return z
      .object({
        accountId: z.string(),
        artistId: z.string(),
      })
      .parse(request.result?.accountArtistBinding);
  };

export type DeleteAccountArtistBindingAdminPromise = ReturnType<
  typeof createDeleteAccountArtistBindingAdminPromise
>;
export type DeleteAccountArtistBindingAdminPromiseResult = Awaited<
  ReturnType<DeleteAccountArtistBindingAdminPromise>
>;
