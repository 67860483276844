import { ApplicationSidebar } from '../components/ApplicationSidebar';
import { Block } from '../components/Block';
import { Footer } from '../components/Footer';
import { HeroSection } from '../components/HeroSection';
import { PageContainerWithSidebar } from '../components/PageContainerWithSidebar';
import { Link } from '../components/Link';
import cn from 'classnames';

import { Article } from '../components/Article';
import styles from './Requirements.module.scss';

export function Requirements() {
  return (
    <div>
      <HeroSection
        title="Requirements & Rules"
        breadcrumbs={[{ text: 'Home', to: '/' }]}
      />
      <div className={styles.spacer} />
      <PageContainerWithSidebar>
        <ApplicationSidebar
          type="public"
          links={[
            'Artist Eligibility Criteria',
            'Label Eligibility Criteria',
            'Funding Rules',
            'Still Have Questions?',
          ]}
        />
        <main className={cn(styles.content)}>
          <Block
            id="Artist Eligibility Criteria"
            headerText="Artist Eligibility Criteria"
            iconName="list"
            shadow={true}
            reduceFontOnMobile={true}
          >
            <Article>
              <p>
                To qualify for funding you must be a Canadian who has attained a
                track record in your existing career. This means you must meet
                the following criteria:
              </p>
              <ol>
                <li>
                  You are a Canadian citizen or landed immigrant/permanent
                  resident.
                </li>
                <li>
                  You have attained a sales level on a release in Canada that
                  satisfies the minimum “Track Record” requirement for your
                  genre of music but has never sold more than 150,000 equivalent
                  units (see the “Frequently Asked Questions” section for more
                  information). You may establish this track record from the
                  sales of any previous record (bundle or full-length) released
                  within the last 5 years or from the sales of a current record
                  (bundle or full-length).
                </li>
                <li>
                  You are signed to a Canadian major label or a Canadian-owned
                  independent record label, or you own your record, and you
                  either release it on your own or license it to a Canadian
                  company. All details regarding your recording contract must be
                  in place by the application deadline for the Board of
                  Directors to consider an application.
                </li>
              </ol>
              <p className={styles.note}>
                Please Note: You must receive your royalties from Canadian sales
                directly without mixing the royalties and/or expenses from a
                foreign territory. You may engage the services of a US or other
                foreign-based distributor, provided your Canadian royalties are
                isolated and accounted to you separately.
              </p>
              <p>
                You may wish to have your record label, manager, or other
                representative fill out this application on your behalf.
                However, you will be required to sign any funding agreement and
                warrant the accuracy of all the material contained in it. You
                will also be held responsible for all grant money received and
                spent on your behalf. Therefore, we encourage you to actively
                participate in this process to ensure the money is properly
                spent in your best interests.
              </p>
            </Article>
          </Block>
          <Block
            id="Label Eligibility Criteria"
            headerText="Label Eligibility Criteria"
            iconName="list"
            shadow={true}
            reduceFontOnMobile={true}
          >
            <Article>
              <p>
                The domestic and international touring applications should be
                filled out by the artists and/or their managers or
                representatives. However, it can be done by labels on their
                behalf with their permission. Marketing requests can be added by
                either the label or by the artists and/or their managers or
                representatives.
              </p>
              <p>
                Marketing requests must be non-recoupable and occur within 8
                months of the deadline for the round in which you apply. Any
                requests to change approved funding made in the last 2 months of
                the spending period will not be considered outside of
                extraordinary circumstances.
              </p>
              <p className={styles.bold}>
                Marketing requests will be for Digital Content only and may only
                include the following:{' '}
              </p>
              <ol>
                <li>Videographer</li>
                <li>
                  Media for online marketing (not the cost of placing any ads or
                  photoshoots)
                </li>
                <li>Cost of a music video</li>
              </ol>
              <p className={styles.bold}>
                Unit Level requirements per record according to genre:
              </p>
              <ul>
                <li>5,000 units (pop/rock/hip-hop/r&b/country)</li>
                <li>3,500 units (folk/electronica)</li>
                <li>2,000 units (global/jazz/classical/blues)</li>
                <li>Maximum units per record for all genres: 150,000</li>
              </ul>
              <p>
                Unit-level requirements must be met by 1 eligible release from
                the artist within the last 5 years. Sales levels are determined
                on records sold in Canada only - you cannot include sales from
                outside Canada. Sales levels will be determined by “net sales”
                in Canada, excluding exports. This can include records sold
                offstage and reported to Luminate/Soundscan Canada. Please note:
                “Net sales” is defined as the number of records shipped and the
                net of returns.
              </p>
              <p>
                The CSF allows the sale of digital downloads of full-length
                releases or bundles and physical releases to meet our net sales
                thresholds. A full-length release contains at least 6 unique
                studio recordings – not including acoustic re-recordings,
                remixes, versions, etc. – and at least 25 minutes of music. A
                bundle contains at least 3 unique, previously unreleased studio
                recordings – not including acoustic re-recordings, remixes,
                versions, etc. – and at least 9 minutes of music that were made
                available no earlier than 1 year prior to the release of the
                bundle. 5 single-track downloads from a full-length release or
                bundle will constitute 1 sale toward achieving the minimum sales
                threshold under the eligibility requirements. Canadian streams
                of single tracks from titles released within the last 5 years
                will also be allowed to meet the net sales thresholds. 750
                single-track streams from a full-length release or bundle will
                constitute 1 sale toward achieving the minimum sales threshold
                under the eligibility requirements. The tracks must appear on
                the actual release – not remixes or versions of songs which
                don’t appear on the actual release. Only downloads and streams
                shown on Luminate/Soundscan Canada will be counted.
              </p>
              <p>
                We will allow the inclusion of deluxe editions of a release in
                the count towards sales eligibility as long as there are at
                least 3 additional songs added to the original version of the
                release (remixes or versions of existing tracks are acceptable).
                If the applicant has already applied for the regular version of
                the release, they should not add the deluxe version into the
                system, but you can include any sales or streams from the deluxe
                version should you re-apply under the original title. You will
                need to discuss the deluxe edition in the text of any new
                applications. In that case, the release date will stay the same
                as the original version. If no applications were submitted for
                the regular version of the release, then the release date for
                the deluxe version is what applicants should use, as it will be
                the first release under which you apply for the title. You can
                include sales/streams from the previously released regular
                version when adding the deluxe edition into the system. All
                other rules around applications will remain the same, with the
                inclusion of a deluxe edition.
              </p>
              <p>
                Should Luminate/Soundscan Canada indicate the applicant has
                reached but not exceeded the eligibility requirement for net
                sales, it is unnecessary to forward net sales reports to us.
                Should Luminate/Soundscan Canada not indicate that the net sales
                requirement has been met, a Canadian net sales report showing
                the title’s total Canadian shipments minus any returns must be
                submitted to us, proving sales eligibility by the application
                deadline for an application to be considered. The information
                can be sent via email/jpeg. It can take the form of a concise
                print-out from the distributor’s computer system on the
                distributor’s letterhead or via a letter from the distributor on
                their letterhead. It must show the artist’s name, title, and
                release date and use the words “Canadian net sales” and the
                corresponding figure. Excel spreadsheets cannot be accepted.
                Manufacturing sheets will be accepted in specific situations,
                provided you receive our prior approval. This information should
                be provided well ahead of the deadline to ensure it can be
                reviewed.
              </p>
              <p>
                The Board of Directors of the Canadian Starmaker Fund shall make
                the final determination on all funding requests, and their
                decision is final and binding and not subject to review or
                appeal. The criteria used by the Board shall be comprised of
                several factors, including the artist’s track record, whether
                you can show momentum and growth in your career and the
                resources available to the Fund in that period. There will be no
                jury process that considers the artistic or commercial merit of
                the music. However, we ask that you submit a one-song sample of
                music from the relevant release (MP3 or WAV file) as part of
                your application for context if it is not otherwise available on
                a streaming platform.
              </p>
              <p>The Board will meet 3 times a year to allocate funding.</p>
            </Article>
          </Block>
          <Block
            id="Funding Rules"
            headerText="Funding Rules"
            iconName="list"
            shadow={true}
            reduceFontOnMobile={true}
          >
            <Article>
              <p>
                The policies, by-laws and eligibility requirements are
                continually under review. Therefore, we welcome any thoughts or
                suggestions you may have on this aspect of the fund. Please
                email your comments or questions to{' '}
                <Link href="mailto:michellefarres@starmaker.ca">
                  michellefarres@starmaker.ca
                </Link>
                . The following funding rules will apply:
              </p>
              <ol>
                <li>
                  Sales eligibility must be established from 1 release (either a
                  bundle or full-length) from the previous 5 years before the
                  round deadline. Releases cannot be added together to reach
                  sales eligibility. Only Canadian sales/downloads/streams can
                  be used to establish sales eligibility.
                </li>
                <li>
                  You can apply for tour dates/marketing requests before the
                  round deadline, provided they occur after you submit your
                  application. Nothing can be applied for after it has taken
                  place.
                </li>
                <li>
                  Tour dates and marketing requests must occur within 8 months
                  of the deadline for the round. Change requests to approved
                  requests must be received within 6 months of the deadline for
                  the round in which the approval took place. Change requests in
                  the last 2 months will only be considered under extraordinary
                  circumstances.
                </li>
                <li>
                  In conjunction with at least 3 tour dates in either Canada,
                  the US or International (non-US), you may also apply for
                  marketing for those dates. The marketing requests can be for
                  Digital Content only and may only include any combination of a
                  videographer, media for online marketing (not the cost of
                  placing any ads or photoshoots) and/or the cost of a music
                  video.
                </li>
                <li>
                  Artists may only submit 1 application per round. No changes
                  can be made once an application has been submitted.
                </li>
                <li>
                  Digital Content will not be tied to a specific territory and
                  will be capped at a cumulative $30,000 in approvals per
                  calendar year. If you are approved, for example, $20,000 in
                  one round, you may apply for up to an additional $10,000 in
                  the other rounds that calendar year until the approval cap is
                  hit.
                </li>
                <li>
                  These amounts include taxes. Only Digital Content initiatives
                  that have yet to occur will be considered for funding. Artists
                  signed to major labels or whose release is licensed to major
                  labels in a specific territory will not be eligible to receive
                  marketing funds in that territory.
                </li>
                <li>
                  All applications can be submitted under any bundle or
                  full-length that has sales eligibility from the last 5 years –
                  you no longer need to apply under the most recent release.
                  Remember that as long as there is no change in genre or type
                  of release, sales eligibility lasts 5 years from the eligible
                  title’s release date. As long as new recordings are within
                  that 5-year window, there should be no issue with the upcoming
                  title being eligible for funding consideration. If there is a
                  change in genre or type of release for the upcoming title,
                  then you must still show sales eligibility with the new
                  release to apply.
                </li>
              </ol>
            </Article>
          </Block>
          <Block
            id="Still Have Questions?"
            headerText="Still Have Questions?"
            iconName="infoCircle"
            shadow={true}
            reduceFontOnMobile={true}
          >
            <Article>
              <p>
                Please visit our{' '}
                <Link href="/faq">Frequently Asked Questions</Link> page for
                additional information, especially regarding eligibility
                requirements.
              </p>
            </Article>
          </Block>
        </main>
      </PageContainerWithSidebar>
      <Footer />
    </div>
  );
}
