import { assign, createMachine } from 'xstate';
import type {
  EventFrom,
  MachineOptionsFrom,
  Sender,
  ActorRefFrom,
} from 'xstate';
import { BackEvent, NextEvent, back, next } from '../navigation';
import { match } from 'ts-pattern';
import { sendParent } from 'xstate/lib/actions';
import { StateFrom } from '../../utils/StateFrom';
import { ReplayableEvent, createReplay } from '../../utils/replay';
import { RedeemAccountInvitationStorage } from './storage';
import { GetRedeemAccountInvitationContextPromiseResult } from '../../promises/getRedeemAccountInvitationContext';
import { Context as ParentContext } from './Context';
import { AddArtistStorage } from '../AddArtist/storage';

type Provinces = GetRedeemAccountInvitationContextPromiseResult['provinces'];
type Province = Provinces[0];

type LegalStatuses =
  GetRedeemAccountInvitationContextPromiseResult['legalStatuses'];
type LegalStatus = LegalStatuses[0];

type FocusArtistNameEvent = ReplayableEvent<{ type: 'FOCUS_ARTIST_NAME' }>;
type FocusCompanyNameEvent = ReplayableEvent<{ type: 'FOCUS_COMPANY_NAME' }>;

type UpdateArtistNameEvent = ReplayableEvent<{
  type: 'UPDATE_ARTIST_NAME';
  value: string;
}>;

type UpdateArtistProvinceEvent = ReplayableEvent<{
  type: 'UPDATE_ARTIST_PROVINCE';
  value: Province;
}>;

type UpdateArtistIsSelfManagedEvent = ReplayableEvent<{
  type: 'UPDATE_ARTIST_IS_SELF_MANAGED';
  value: boolean;
}>;

type UpdateCompanyNameEvent = ReplayableEvent<{
  type: 'UPDATE_COMPANY_NAME';
  value: string;
}>;

type UpdateCompanyLegalStatusEvent = ReplayableEvent<{
  type: 'UPDATE_COMPANY_LEGAL_STATUS';
  value: LegalStatus;
}>;

type UpdateCompanyLegalProvinceEvent = ReplayableEvent<{
  type: 'UPDATE_COMPANY_LEGAL_PROVINCE';
  value: Province;
}>;

type BlurArtistNameEvent = ReplayableEvent<{ type: 'BLUR_ARTIST_NAME' }>;
type BlurCompanyNameEvent = ReplayableEvent<{ type: 'BLUR_COMPANY_NAME' }>;

type ReplayableEvents =
  | FocusArtistNameEvent
  | FocusCompanyNameEvent
  | UpdateArtistNameEvent
  | UpdateArtistProvinceEvent
  | UpdateArtistIsSelfManagedEvent
  | UpdateCompanyNameEvent
  | UpdateCompanyLegalStatusEvent
  | UpdateCompanyLegalProvinceEvent
  | BlurArtistNameEvent
  | BlurCompanyNameEvent;

type Events = NextEvent | BackEvent | ReplayableEvents;

type Context = {
  artistName: string;
  artistProvince?: Province;
  artistIsSelfManaged?: boolean;
  companyName: string;
  companyLegalStatus?: LegalStatus;
  companyLegalProvince?: Province;
  provinces: Provinces;
  legalStatuses: LegalStatuses;
  isOrion: boolean;
  isAddArtist: boolean;
};

type Storage = RedeemAccountInvitationStorage | AddArtistStorage;

const createContext =
  (isAddArtist: boolean) =>
  ({
    provinces,
    legalStatuses,
    isOrion,
  }: Pick<
    ParentContext,
    'provinces' | 'legalStatuses' | 'isOrion'
  >): Context => {
    return {
      artistName: '',
      companyName: '',
      provinces,
      legalStatuses,
      isOrion,
      isAddArtist,
    };
  };

export const createArtistContextForAddArtist = (
  args: Pick<ParentContext, 'provinces' | 'legalStatuses'>
) => createContext(true)({ ...args, isOrion: false });

export const createArtistContext = createContext(false);

export const machine = createMachine(
  {
    predictableActionArguments: true,
    tsTypes: {} as import('./ArtistMachine.typegen').Typegen0,
    schema: {
      events: {} as Events,
      context: {} as Context,
    },
    type: 'parallel',
    invoke: {
      id: 'resume',
      src: 'resume',
    },
    states: {
      form: {
        initial: 'pending',
        states: {
          pending: {
            type: 'parallel',
            states: {
              'artist-name': {
                initial: 'pristine',
                states: {
                  pristine: { on: { NEXT: 'empty' } },
                  empty: {},
                  valid: { type: 'final' },
                },
                on: {
                  UPDATE_ARTIST_NAME: {
                    actions: ['setArtistName', 'saveArtistName'],
                  },
                  BLUR_ARTIST_NAME: [
                    { cond: 'isEmpty', target: '.empty' },
                    { target: '.valid' },
                  ],
                },
              },
              'artist-province': {
                initial: 'pristine',
                states: {
                  pristine: { on: { NEXT: 'empty' } },
                  empty: {},
                  valid: { type: 'final' },
                },
                on: {
                  UPDATE_ARTIST_PROVINCE: {
                    actions: ['setArtistProvince', 'saveArtistProvince'],
                    target: '.valid',
                  },
                },
              },
              'company-name': {
                initial: 'pristine',
                states: {
                  pristine: { on: { NEXT: 'empty' } },
                  empty: {},
                  valid: { type: 'final' },
                },
                on: {
                  UPDATE_COMPANY_NAME: {
                    actions: ['setCompanyName', 'saveCompanyName'],
                  },
                  BLUR_COMPANY_NAME: [
                    { cond: 'isEmpty', target: '.empty' },
                    { target: '.valid' },
                  ],
                },
              },
              'company-legal-status': {
                initial: 'pristine',
                states: {
                  pristine: { on: { NEXT: 'empty' } },
                  empty: {},
                  partial: {
                    initial: 'default',
                    states: {
                      default: { on: { NEXT: 'empty' } },
                      empty: {},
                    },
                  },
                  valid: { type: 'final' },
                },
                on: {
                  UPDATE_COMPANY_LEGAL_STATUS: [
                    {
                      cond: 'requiresProvince',
                      actions: [
                        'setCompanyLegalStatus',
                        'saveCompanyLegalStatus',
                      ],
                      target: '.partial',
                    },
                    {
                      actions: [
                        'setCompanyLegalStatus',
                        'saveCompanyLegalStatus',
                      ],
                      target: '.valid',
                    },
                  ],
                  UPDATE_COMPANY_LEGAL_PROVINCE: {
                    actions: [
                      'setCompanyLegalProvince',
                      'saveCompanyLegalProvince',
                    ],
                    target: '.valid',
                  },
                },
              },
              'is-self-managed': {
                initial: 'pristine',
                states: {
                  pristine: { on: { NEXT: 'empty' } },
                  empty: {},
                  valid: { type: 'final' },
                },
                on: {
                  UPDATE_ARTIST_IS_SELF_MANAGED: {
                    actions: [
                      'setArtistIsSelfManaged',
                      'saveArtistIsSelfManaged',
                    ],
                    target: '.valid',
                  },
                },
              },
              // We have to make 'pulse' and 'on' sibling nodes because final
              // states can not have any children. We need them to be final
              // states so that we can transition to the complete state when
              // the form is valid.
              warning: {
                initial: 'off',
                states: {
                  on: {
                    type: 'final',
                    on: { NEXT: 'pulse' },
                  },
                  off: {
                    type: 'final',
                    on: { NEXT: 'on' },
                  },
                  pulse: {
                    type: 'final',
                    after: { 1000: 'on' },
                  },
                },
              },
            },
            onDone: 'complete',
          },
          complete: {
            on: {
              FOCUS_ARTIST_NAME: {
                target: [
                  'pending.artist-name.pristine',
                  'pending.artist-province.valid',
                  'pending.company-name.valid',
                  'pending.company-legal-status.valid',
                  'pending.is-self-managed.valid',
                ],
              },
              UPDATE_ARTIST_PROVINCE: {
                actions: ['setArtistProvince', 'saveArtistProvince'],
              },
              FOCUS_COMPANY_NAME: {
                target: [
                  'pending.artist-name.valid',
                  'pending.artist-province.valid',
                  'pending.company-name.pristine',
                  'pending.company-legal-status.valid',
                  'pending.is-self-managed.valid',
                ],
              },
              UPDATE_ARTIST_IS_SELF_MANAGED: {
                actions: ['setArtistIsSelfManaged', 'saveArtistIsSelfManaged'],
              },
              UPDATE_COMPANY_LEGAL_STATUS: [
                {
                  cond: 'requiresProvince',
                  actions: ['setCompanyLegalStatus', 'saveCompanyLegalStatus'],
                  target: [
                    'pending.artist-name.valid',
                    'pending.artist-province.valid',
                    'pending.company-name.valid',
                    'pending.company-legal-status.partial',
                    'pending.is-self-managed.valid',
                  ],
                },
                {
                  actions: ['setCompanyLegalStatus', 'saveCompanyLegalStatus'],
                },
              ],
              UPDATE_COMPANY_LEGAL_PROVINCE: {
                actions: [
                  'setCompanyLegalProvince',
                  'saveCompanyLegalProvince',
                ],
              },
              NEXT: { actions: 'next' },
            },
          },
        },
      },
      province: {
        initial: 'hidden',
        states: {
          hidden: {},
          shown: {},
        },
        on: {
          UPDATE_COMPANY_LEGAL_STATUS: [
            {
              cond: 'requiresProvince',
              target: '.shown',
            },
            { target: '.hidden' },
          ],
        },
      },
    },
    on: { BACK: { actions: 'back' } },
  },
  {
    guards: {
      isEmpty: ({ artistName, companyName }, { type }) =>
        match(type)
          .with('BLUR_ARTIST_NAME', () => artistName)
          .with('BLUR_COMPANY_NAME', () => companyName)
          .exhaustive()
          .trim() === '',
      requiresProvince: (_, { value: { requiresProvince } }) =>
        requiresProvince,
    },
    actions: {
      next: sendParent(next()),
      back: sendParent(back()),
      setArtistName: assign((_, { value: artistName }) => ({
        artistName,
      })),
      setArtistProvince: assign((_, { value: artistProvince }) => ({
        artistProvince,
      })),
      setArtistIsSelfManaged: assign((_, { value: artistIsSelfManaged }) => ({
        artistIsSelfManaged,
      })),
      setCompanyName: assign((_, { value: companyName }) => ({
        companyName,
      })),
      setCompanyLegalStatus: assign((_, { value: companyLegalStatus }) => ({
        companyLegalStatus,
        companyLegalProvince: undefined,
      })),
      setCompanyLegalProvince: assign((_, { value: companyLegalProvince }) => ({
        companyLegalProvince,
      })),
    },
  }
);

export const createArtistResumeService =
  (storage: Storage) =>
  ({ provinces, legalStatuses }: Context) =>
  (send: ArtistMachineSender) => {
    const replay = createReplay<ReplayableEvents>();
    const { artist, company } = storage.get();

    [
      ...(
        [
          [
            artist?.name,
            'FOCUS_ARTIST_NAME',
            'UPDATE_ARTIST_NAME',
            'BLUR_ARTIST_NAME',
          ],
          [
            company?.name,
            'FOCUS_COMPANY_NAME',
            'UPDATE_COMPANY_NAME',
            'BLUR_COMPANY_NAME',
          ],
        ] satisfies Array<
          [
            string | undefined,
            ReplayableEvents['type'],
            ReplayableEvents['type'],
            ReplayableEvents['type']
          ]
        >
      ).reduce<ReplayableEvents[]>(
        (acc, [value, focus, type, blur]) =>
          value !== undefined
            ? [...acc, { type: focus }, { type, value }, { type: blur }]
            : acc,
        []
      ),
      ...(
        [
          [artist?.province, 'UPDATE_ARTIST_PROVINCE'],
          // This event needs to be sent before the legal province since this
          // event always resets the province.
          [company?.legalStatus, 'UPDATE_COMPANY_LEGAL_STATUS'],
          [company?.legalProvince, 'UPDATE_COMPANY_LEGAL_PROVINCE'],
        ] satisfies Array<[string | undefined, ReplayableEvents['type']]>
      ).reduce<ReplayableEvents[]>((acc, [findId, type]) => {
        let event: ReplayableEvents | undefined;

        // Kind of annoying we can't do this better.
        if (type === 'UPDATE_COMPANY_LEGAL_STATUS') {
          const value = legalStatuses.find(({ id }) => id === findId);
          event = value !== undefined ? { type, value } : undefined;
        } else {
          const value = provinces.find(({ id }) => id === findId);
          event = value !== undefined ? { type, value } : undefined;
        }

        return event !== undefined ? [...acc, event] : acc;
      }, []),
      ...(artist?.isSelfManaged !== undefined
        ? [
            {
              type: 'UPDATE_ARTIST_IS_SELF_MANAGED',
              value: artist.isSelfManaged,
            } satisfies UpdateArtistIsSelfManagedEvent,
          ]
        : []),
    ].forEach((event) => send(replay(event)));
  };

export const createArtistSaveActions = (storage: Storage) => ({
  saveArtistName: (
    _: Context,
    { value: name, replay }: UpdateArtistNameEvent
  ) => storage.add({ artist: { name } }, replay),
  saveArtistProvince: (
    _: Context,
    { value: { id: province }, replay }: UpdateArtistProvinceEvent
  ) => storage.add({ artist: { province } }, replay),
  saveArtistIsSelfManaged: (
    _: Context,
    { value: isSelfManaged, replay }: UpdateArtistIsSelfManagedEvent
  ) => storage.add({ artist: { isSelfManaged } }, replay),
  saveCompanyName: (
    _: Context,
    { value: name, replay }: UpdateCompanyNameEvent
  ) => storage.add({ company: { name } }, replay),
  saveCompanyLegalStatus: (
    _: Context,
    { value: { id: legalStatus }, replay }: UpdateCompanyLegalStatusEvent
  ) =>
    storage.add({ company: { legalStatus, legalProvince: undefined } }, replay),
  saveCompanyLegalProvince: (
    _: Context,
    { value: { id: legalProvince }, replay }: UpdateCompanyLegalProvinceEvent
  ) => storage.add({ company: { legalProvince } }, replay),
});

export type ArtistMachineActor = ActorRefFrom<typeof machine>;
export type ArtistMachine = typeof machine;
export type ArtistMachineState = StateFrom<ArtistMachine>;
export type ArtistMachineSender = Sender<EventFrom<ArtistMachine>>;
export type ArtistMachineOptions = MachineOptionsFrom<ArtistMachine, true>;
