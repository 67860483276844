import { z } from 'zod';

const completeEligibilityStateSchema = z.object({
  release: z.object({
    title: z.string(),
    labelId: z.string(),
    otherLabel: z.string().optional(),
    distributorId: z.string(),
    otherDistributor: z.string().optional(),
    date: z.string(),
    numberOfTracks: z.string(),
    numberOfMinutes: z.string(),
    isMajorityEnglish: z.boolean(),
  }),
  sales: z.object({
    genreId: z.string(),
    releasesScanned: z.string(),
    tracksDownloaded: z.string(),
    tracksStreamed: z.string(),
  }),
  master: z.object({
    company: z.string(),
    website: z.string().optional(),
    contact: z.string(),
    address: z.string(),
    inCanada: z.boolean(),
    phoneNumber: z.string(),
    email: z.string(),
    notes: z.string().optional(),
  }),
});

const eligibilityStateSchema = completeEligibilityStateSchema
  .deepPartial()
  .extend({
    step: z
      .union([
        z.literal('resident'),
        z.literal('release'),
        z.literal('sales'),
        z.literal('masterRecording'),
        z.literal('summary'),
      ])
      .optional(),
    resident: z.boolean().optional(),
  });

export type EligibilityState = z.infer<typeof eligibilityStateSchema>;
export type CompleteEligibilityState = z.infer<
  typeof completeEligibilityStateSchema
>;

export const parseEligibilityState = (data: unknown) =>
  eligibilityStateSchema.parse(data);

export const parseCompleteEligibilityState = (data: unknown) =>
  completeEligibilityStateSchema.parseAsync(data);
