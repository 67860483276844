import type {
  Sender,
  EventFrom,
  MachineOptionsFrom,
  ActorRefFrom,
} from 'xstate';
import { assign, sendParent, createMachine } from 'xstate';
import { CalendarDate } from '@internationalized/date';
import { StateFrom } from '../../utils/StateFrom';
import { TourDate } from '../../schemas/tourDates/tourDateSchema';
import { ApplicationStatus, TourDateStatus } from '../../graphql/operations';
import { updateTourDate } from '../../events/UpdateTourDate';
import { OpenTourDate, openTourDate } from '../../events/OpenTourDate';
import { DeleteTourDate, deleteTourDate } from '../../events/DeleteTourDate';
import { cancelTourDate, CancelTourDate } from '../../events/CancelTourDate';

export type Events =
  | { type: 'TOGGLE_FORM_VISIBILITY' }
  | { type: 'SET_STATUS'; status: TourDateStatus }
  | { type: 'UPDATE_TOUR_DATE' }
  | { type: 'UPDATE_CONTEXT'; context: Partial<Context> }
  | OpenTourDate
  | DeleteTourDate
  | CancelTourDate;

export type Context = {
  status: TourDate['status'];
  tourDate: TourDate;
  isAdmin: boolean;
  isBoard: boolean;
  presentCutoffDate: CalendarDate;
  futureCutoffDate: CalendarDate | undefined;
  applicationStatus: ApplicationStatus;
};

export const createContext = (
  tourDate: TourDate,
  isAdmin: boolean,
  isBoard: boolean,
  presentCutoffDate: CalendarDate,
  futureCutoffDate: CalendarDate | undefined,
  applicationStatus: ApplicationStatus
): Context => ({
  status: tourDate.status,
  tourDate,
  isAdmin,
  isBoard,
  presentCutoffDate,
  futureCutoffDate,
  applicationStatus,
});

export const machine = createMachine(
  {
    predictableActionArguments: true,
    tsTypes: {} as import('./TourDateCardMachine.typegen').Typegen0,
    schema: {
      context: {} as Context,
      events: {} as Events,
    },
    id: 'form',
    initial: 'ready',
    states: {
      ready: {
        on: {
          TOGGLE_FORM_VISIBILITY: 'status',
          OPEN_TOUR_DATE: {
            actions: 'openTourDate',
          },
          DELETE_TOUR_DATE: {
            actions: 'deleteTourDate',
          },
          CANCEL_TOUR_DATE: {
            actions: 'cancelTourDate',
          },
        },
      },
      status: {
        entry: 'resetStatus',
        on: {
          TOGGLE_FORM_VISIBILITY: 'ready',
          UPDATE_TOUR_DATE: {
            target: 'ready',
            actions: 'updateTourDate',
          },
        },
      },
    },
    on: {
      SET_STATUS: {
        actions: 'setStatus',
      },
      UPDATE_CONTEXT: {
        actions: 'updateContext',
      },
    },
  },
  {
    guards: {},
    actions: {
      resetStatus: assign(({ tourDate: { status } }) => ({
        status,
      })),
      setStatus: assign((_, { status }) => ({
        status,
      })),
      updateTourDate: sendParent(({ status, tourDate: { id } }) =>
        updateTourDate(id, { status })
      ),
      openTourDate: sendParent(({ tourDate }) => openTourDate(tourDate.id)),
      deleteTourDate: sendParent(({ tourDate }) => deleteTourDate(tourDate.id)),
      cancelTourDate: sendParent(({ tourDate }) => cancelTourDate(tourDate.id)),
      updateContext: assign((_, { context }) => ({
        ...context,
      })),
    },
  }
);

type Machine = typeof machine;

export type TourDateCardMachine = Machine;
export type TourDateCardMachineState = StateFrom<Machine>;
export type TourDateCardMachineSender = Sender<EventFrom<Machine>>;
export type TourDateCardMachineEvent = EventFrom<Machine>;
export type TourDateCardMachineOptions = MachineOptionsFrom<Machine, true>;
export type TourDateCardMachineActor = ActorRefFrom<TourDateCardMachine>;
