import { CalendarStateOptions, useCalendarState } from 'react-stately';
import { useCalendar } from 'react-aria';
import { CalendarGrid } from './CalendarGrid';
import { Icon } from '../Icon';
import { Chevron } from './Chevron';

import styles from './Calendar.module.scss';

type CalendarProps = CalendarStateOptions & {
  onShowMonthView: () => void;
};

export function Calendar(props: CalendarProps) {
  const state = useCalendarState(props);

  const { calendarProps, prevButtonProps, nextButtonProps, title } =
    useCalendar(props, state);

  return (
    <div {...calendarProps}>
      <div className={styles.heading}>
        <Chevron direction="left" buttonProps={prevButtonProps} />
        <button
          type="button"
          className={styles.openButton}
          onClick={props.onShowMonthView}
        >
          {title}
          <Icon name="downChevron" />
        </button>
        <Chevron direction="right" buttonProps={nextButtonProps} />
      </div>

      <CalendarGrid state={state} />
    </div>
  );
}
