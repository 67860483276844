import type {
  Sender,
  EventFrom,
  MachineOptionsFrom,
  ActorRefFrom,
} from 'xstate';
import { createMachine, assign, sendParent, spawn } from 'xstate';
import { send, stop } from 'xstate/lib/actions';
import { Context as ParentContext } from '../Application/ApplicationMachine';
import { StateFrom } from '../../utils/StateFrom';
import { CreateSaveApplicationDirectDepositInfoPromiseResult } from '../../promises/saveApplicationDirectDepositInfo';
import { createContext as setDepositInfoMachineCreateContext } from '../ClaimFunds/SetDepositInfoMachine';
import { InsertDirectDepositAndLinkToApplicationType } from '../../graphql/operations';
import { CreateUpsertPaymentPromiseResult } from '../../promises/upsertPaymentPromise';
import { createContext as upsertPaymentMachineCreateContext } from '../ClaimFunds/UpsertPaymentMachine';
import { DirectDepositAccount } from '../../schemas/directDepositAccount';
import { logMachineError } from '../../utils/logError';
import {
  updateDirectDepositAccounts,
  updateFundingRequestDirectDeposit,
  updateTouringDirectDeposit,
} from './events';
import {
  PaymentMachineActor,
  machine as PaymentMachine,
} from './PaymentMachine';
import { DeletePaymentPromiseResult } from '../../promises/deletePaymentPromise';

type Events =
  | { type: 'SET_DEPOSIT_INFO' }
  | { type: 'SET_ACCOUNTS'; accounts: DirectDepositAccount[] }
  | { type: 'ADD_PAYMENT' }
  | { type: 'DELETE_PAYMENT'; id: string };

export type CreateContextArg = {
  applicationType: ParentContext['applicationType'];
  artistId: string;
  applicationId: string;
  depositInfoType: InsertDirectDepositAndLinkToApplicationType;
  directDepositAccounts: DirectDepositAccount[];
  directDepositAccount: DirectDepositAccount | null;
  isAdmin: ParentContext['isAdmin'];
};

export type PaymentRefs = { [paymentId: string]: PaymentMachineActor };

export interface Context extends CreateContextArg {
  paymentRefs: PaymentRefs;
  deletionChildRef: PaymentMachineActor | null;
}

type Services = {
  setDepositInfoMachine: {
    data: CreateSaveApplicationDirectDepositInfoPromiseResult;
  };
  upsertPaymentMachine: {
    data: CreateUpsertPaymentPromiseResult;
  };
  deletePayment: {
    data: DeletePaymentPromiseResult;
  };
};

export const createContext = (arg: CreateContextArg): Context => ({
  ...arg,
  deletionChildRef: null,
  paymentRefs: {},
});

export const machine = createMachine(
  {
    id: 'directDepositMachine',
    predictableActionArguments: true,
    tsTypes: {} as import('./DirectDepositMachine.typegen').Typegen0,
    schema: {
      context: {} as Context,
      events: {} as Events,
      services: {} as Services,
    },
    entry: 'spawnPaymentMachines',
    type: 'parallel',
    states: {
      paymentMutations: {
        initial: 'idle',
        states: {
          idle: {
            on: {
              DELETE_PAYMENT: { target: 'deleting' },
            },
          },
          deleting: {
            entry: 'saveDeletionChildRef',
            invoke: {
              id: 'deletePayment',
              src: 'deletePayment',
              onDone: {
                target: 'idle',
                actions: [
                  'removePayment',
                  'sendParentDirectDepositAccount',
                  'sendParentDirectDepositAccounts',
                  'stopChild',
                ],
              },
              onError: {
                target: 'idle',
                actions: ['sendChildDeletePaymentError'],
              },
            },
            exit: 'clearDeletionChildRef',
          },
          error: {},
        },
      },
      default: {
        initial: 'idle',
        on: {
          SET_ACCOUNTS: { actions: ['setAccounts'] },
        },
        states: {
          idle: {
            on: {
              SET_DEPOSIT_INFO: 'settingDepositInfo',
              ADD_PAYMENT: 'addingPayment',
            },
          },
          settingDepositInfo: {
            invoke: {
              id: 'setDepositMachine',
              src: 'setDepositInfoMachine',
              data: (context: Context) =>
                setDepositInfoMachineCreateContext({
                  depositInfoType: context.depositInfoType,
                  applicationId: context.applicationId,
                  artistId: context.artistId,
                  existingAccounts: context.directDepositAccounts,
                  selectedExistingAccountId:
                    context.directDepositAccount?.id ??
                    context.directDepositAccounts[0]?.id,
                  isChange: Boolean(context.directDepositAccount),
                  applicationType: context.applicationType,
                }),
              onDone: {
                actions: [
                  'updateDirectDepositAccount',
                  'updateDirectDepositAccounts',
                  'sendParentDirectDepositAccount',
                  'sendParentDirectDepositAccounts',
                ],
                target: 'idle',
              },
              onError: '#directDepositMachine.default.error',
            },
          },
          addingPayment: {
            invoke: {
              id: 'upsertPaymentMachine',
              src: 'upsertPaymentMachine',
              data: (context: Context) => {
                if (!context.directDepositAccount?.id) {
                  throw new Error(
                    'Cannot add payment to null directDepositAccount'
                  );
                }
                return upsertPaymentMachineCreateContext({
                  ...context,
                  directDepositAccountId: context.directDepositAccount.id,
                  amount: '',
                  transactionNumber: '',
                });
              },
              onDone: {
                actions: [
                  'updateDirectDepositAccount',
                  'updateDirectDepositAccounts',
                  'sendParentDirectDepositAccount',
                  'sendParentDirectDepositAccounts',
                ],
                target: 'idle',
              },
              onError: '#directDepositMachine.default.error',
            },
          },
          error: { entry: 'logMachineError' },
        },
      },
    },
  },
  {
    actions: {
      logMachineError,
      spawnPaymentMachines: assign((context) => ({
        paymentRefs: context.directDepositAccount
          ? context.directDepositAccount.payments.reduce<PaymentRefs>(
              (acc, payment) => {
                acc[payment.id] = spawn(
                  PaymentMachine.withContext({ payment })
                );
                return acc;
              },
              {}
            )
          : {},
      })),
      sendParentDirectDepositAccount: sendParent(({ depositInfoType }, event) =>
        depositInfoType === InsertDirectDepositAndLinkToApplicationType.Touring
          ? updateTouringDirectDeposit(event.data)
          : updateFundingRequestDirectDeposit(event.data)
      ),
      sendParentDirectDepositAccounts: sendParent(
        ({ directDepositAccounts }, event) =>
          updateDirectDepositAccounts(
            !directDepositAccounts.find(
              (account) => event.data?.id === account.id
            ) && event.data
              ? [...directDepositAccounts, event.data]
              : directDepositAccounts
          )
      ),
      updateDirectDepositAccount: assign((context, event) => ({
        directDepositAccount: event.data ?? context.directDepositAccount,
        paymentRefs: event.data
          ? event.data.payments.reduce<PaymentRefs>((acc, payment) => {
              if (!acc[payment.id]) {
                acc[payment.id] = spawn(
                  PaymentMachine.withContext({ payment })
                );
              }
              return acc;
            }, context.paymentRefs)
          : context.paymentRefs,
      })),
      updateDirectDepositAccounts: assign({
        directDepositAccounts: ({ directDepositAccounts }, event) =>
          !directDepositAccounts.find(
            (account) => event.data?.id === account.id
          ) && event.data
            ? [...directDepositAccounts, event.data]
            : directDepositAccounts,
      }),
      setAccounts: assign({
        directDepositAccounts: (_, event) => event.accounts,
      }),
      stopChild: stop((context) => {
        return context.deletionChildRef || '';
      }),
      removePayment: assign({
        directDepositAccount: (_, event) => event.data,
      }),
      saveDeletionChildRef: assign((context, event) => {
        return {
          deletionChildRef: context.paymentRefs[event.id],
        };
      }),
      clearDeletionChildRef: assign((_) => ({
        deletionChildRef: null,
      })),
      sendChildDeletePaymentError: send(
        {
          type: 'DELETE_PAYMENT_ERROR',
        },
        { to: (context) => context.deletionChildRef ?? '' }
      ),
    },
  }
);

type Machine = typeof machine;

export type DirectDepositMachineState = StateFrom<Machine>;
export type DirectDepositMachineSender = Sender<EventFrom<Machine>>;
export type DirectDepositMachineOptions = MachineOptionsFrom<Machine, true> & {
  context: Context;
};
export type DirectDepositMachineEvent = EventFrom<Machine>;
export type DirectDepositMachineActor = ActorRefFrom<Machine>;
