export type Storage<State> = {
  get: () => State;
  set: (state: State) => void;
  add: (state: State) => void;
  reset: () => void;
};

// TODO: Do we need to add exception handling here?
export const createPersistentStorage = <State>(
  key: string,
  initial: State,
  merge: (s1: State, s2: State) => State,
  parse: (data: unknown) => State
): Storage<State> => {
  const get = () => {
    const value = window.localStorage.getItem(key);

    return value === null ? initial : parse(JSON.parse(value));
  };

  const set = (value: State) =>
    window.localStorage.setItem(key, JSON.stringify(value));

  const reset = () => set(initial);

  if (window.localStorage.getItem(key) === null) {
    reset();
  }

  return {
    get,
    set,
    add: (value) => set(merge(value, get())),
    reset,
  };
};

export const createMemoryStorage = <State>(
  initial: State,
  merge: (s1: State, s2: State) => State
): Storage<State> => {
  let state = initial;

  return {
    get: () => state,
    set: (next) => {
      state = next;
    },
    add: (next) => {
      state = merge(next, state);
    },
    reset: () => {
      state = initial;
    },
  };
};
