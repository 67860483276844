import { Link } from 'react-router-dom';
import { Icon, IconName } from './Icon';
import { Button } from './Button';
import cn from 'classnames';

import styles from './Card.module.scss';

interface BaseCardProps {
  title?: string;
  children?: React.ReactNode;
  contentClassName?: string;
  shadow?: boolean;
  width?: 'sm' | 'md' | 'lg';
  display?: 'row' | 'col';
  subTitle?: string;
  icon?: IconName;
  onClick?: () => void;
}

interface LinkCardProps extends BaseCardProps {
  linkTo: string;
  buttonLabel?: undefined;
  buttonIcon?: undefined;
  buttonLinkTo?: undefined;
}

interface NonLinkCardProps extends BaseCardProps {
  linkTo?: undefined;
  buttonLabel?: string;
  buttonIcon?: IconName;
  buttonLinkTo?: string;
}

type CardProps = LinkCardProps | NonLinkCardProps;

export function Card({
  title,
  contentClassName,
  shadow = true,
  width = 'sm',
  display = 'col',
  subTitle,
  icon,
  children,
  onClick,
  ...rest
}: CardProps) {
  const cardContents = (
    <>
      {icon && (
        <div
          className={cn({
            [styles.row]: width !== 'lg',
          })}
        >
          <Icon name={icon} className={styles.icon} />
        </div>
      )}
      <div className={cn(styles.content, contentClassName)}>
        {subTitle ? (
          <div className={styles.heading}>
            <h3 className={styles.bold}>{title}</h3>
            <h4 className={styles.subTitle}>{subTitle}</h4>
          </div>
        ) : (
          <h4 className={styles.bold}>{title}</h4>
        )}
        {children}
      </div>
    </>
  );

  return (
    <div
      className={cn(styles.card, styles.borderOuterRing, {
        [styles.cardLink]: rest.linkTo || onClick,
        [styles.shadow]: shadow,
        [styles.pointer]: onClick,
      })}
      tabIndex={onClick ? 0 : undefined}
      role={onClick ? 'button' : undefined}
      onClick={onClick}
      onKeyDown={onClick}
    >
      <div className={styles.borderMiddleRing}>
        {rest.linkTo ? (
          <Link
            to={rest.linkTo}
            className={cn(
              styles.innerContainer,
              styles.borderInnerRing,
              styles[width],
              styles[display]
            )}
          >
            {cardContents}
          </Link>
        ) : (
          <div
            className={cn(
              styles.innerContainer,
              styles.borderInnerRing,
              styles[width],
              styles[display]
            )}
          >
            {cardContents}
            {rest.buttonLabel && (
              <Button
                startIcon={rest.buttonIcon}
                variant="secondary"
                size="small"
                label={rest.buttonLabel}
                linkTo={rest.buttonLinkTo}
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
}
