import { match } from 'ts-pattern';
import { Icon, IconName } from './Icon';
import { Button } from './Button';
import cn from 'classnames';
import { ApplicationStage, ApplicationType } from '../graphql/operations';

import styles from './ApplicationProgressTimeline.module.scss';

export interface ApplicationProgressTimelineProps {
  stage: ApplicationStage;
  applicationType?: ApplicationType;
  hideButtons?: boolean;
  linkTo?: string;
}

export function ApplicationProgressTimeline({
  stage,
  hideButtons = false,
  linkTo,
}: ApplicationProgressTimelineProps) {
  const step = match(stage)
    .with(
      ApplicationStage.Unsubmitted,
      ApplicationStage.Submitted,
      ApplicationStage.ContractAndDirectDepositPending,
      () => 1
    )
    .with(ApplicationStage.ContractAndDirectDepositUploaded, () => 2)
    .with(ApplicationStage.ClaimsReopened, () => 2)
    .with(ApplicationStage.ClaimsSubmitted, () => 3)
    .with(ApplicationStage.ClaimsResubmitted, () => 3)
    .with(ApplicationStage.Completed, () => 4)
    .exhaustive();

  return (
    <div className={styles.progressContainer}>
      <div className={styles.collapseColumn}>
        <div className={styles.block}>
          <div className={cn(styles.line, styles.green)} />
          <Icon
            name={step === 1 ? 'upload' : 'checkCircle'}
            className={cn(styles.icon, {
              [styles.activeIcon]: step === 1,
              [styles.finished]: step > 1,
            })}
          />
          <div
            className={cn(styles.line, {
              [styles.active]: step === 1,
              [styles.green]: step > 1,
            })}
          />
        </div>
        <div className={cn(styles.buttonContainer, styles.bold)}>
          <div>
            Contract
            {step > 1 && <span className={styles.micro}>Uploaded</span>}
          </div>
          {step === 1 && !hideButtons && (
            <Button
              size="small"
              length="auto"
              label="Upload Contract & Bank Info"
              linkTo={linkTo}
            />
          )}
        </div>
      </div>
      <div className={styles.collapseColumn}>
        <div className={styles.block}>
          <div
            className={cn(styles.line, {
              [styles.green]: step > 1,
            })}
          />
          <Icon
            name={match<number, IconName>(step)
              .with(2, () => 'document')
              .when(
                (step) => step > 2,
                () => 'checkCircle'
              )
              .otherwise(() => 'circle')}
            className={cn(styles.icon, {
              [styles.activeIcon]: step === 2,
              [styles.finished]: step > 2,
            })}
          />
          <div
            className={cn(styles.line, {
              [styles.green]: step > 2,
              [styles.active]: step === 2,
            })}
          />
        </div>
        <div
          className={cn(styles.buttonContainer, styles.bold, {
            [styles.light]: step < 2,
          })}
        >
          <div>
            Reporting
            <span className={styles.micro}>{step > 2 && 'Submitted'}</span>
          </div>
          {step === 2 && !hideButtons && (
            <Button
              size="small"
              length="auto"
              label={
                stage === ApplicationStage.ClaimsReopened
                  ? 'Resubmit'
                  : 'Submit'
              }
              linkTo={linkTo}
            />
          )}
        </div>
      </div>
      <div className={styles.collapseColumn}>
        <div className={styles.block}>
          <div
            className={cn(styles.line, {
              [styles.green]: step > 2,
            })}
          />
          <Icon
            name={match<number, IconName>(step)
              .with(3, () => 'circle')
              .when(
                (step) => step > 3,
                () => 'checkCircle'
              )
              .otherwise(() => 'circle')}
            className={cn(styles.icon, {
              [styles.activeIcon]: step === 3,
              [styles.finished]: step > 3,
            })}
          />
        </div>
        <div
          className={cn(styles.buttonContainer, styles.bold, {
            [styles.light]: step < 3,
          })}
        >
          <div>Completed</div>
        </div>
      </div>
    </div>
  );
}
