import { z } from 'zod';

import { countrySchema } from './countrySchema';
import { provinceSchema } from './provinceSchema';
import { legalStatusSchema } from './legalStatusSchema';
import { accountInvitationStatusSchema as status } from './accountInvitationStatusSchema';
import { minimumPasswordLengthSchema as minimumPasswordLength } from './minimumPasswordLengthSchema';

export const redeemAccountInvitationContextSchema = z.object({
  countries: countrySchema.array(),
  provinces: provinceSchema.array(),
  legalStatuses: legalStatusSchema.array(),
  status,
  minimumPasswordLength,
});

export type RedeemAccountInvitationContext = z.infer<
  typeof redeemAccountInvitationContextSchema
>;
