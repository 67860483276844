import { useMachine } from '@xstate/react';
import {
  machine,
  BoardMembersMachineOptions,
} from '../../../machines/Admin/BoardMembersMachine';
import { BoardMembersView } from './BoardMembersView';

type BoardMembersControllerProps = {
  options: BoardMembersMachineOptions;
};

export function BoardMembersController({
  options,
}: BoardMembersControllerProps) {
  const [state, send] = useMachine(machine, options);

  return <BoardMembersView state={state} send={send} />;
}
