import type {
  ActorRefFrom,
  EventFrom,
  MachineOptionsFrom,
  Sender,
} from 'xstate';
import { assign, createMachine, sendParent } from 'xstate';

import { StateFrom } from '../../utils/StateFrom';
import { ReplayableEvent, createReplay } from '../../utils/replay';
import { back, next } from '../navigation';
import { match } from 'ts-pattern';
import { isPhoneNumberValid } from '../../utils/isPhoneNumberValid';
import { EligibilityStorage } from './storage';
import { isEmailValid } from '../../utils/isEmailValid';
import { AddReleaseStorage } from '../AddRelease/storage';

type SetCompanyEvent = ReplayableEvent<{
  type: 'SET_COMPANY';
  company: string;
}>;

type SetWebsiteEvent = ReplayableEvent<{
  type: 'SET_WEBSITE';
  website: string;
}>;

type SetContactEvent = ReplayableEvent<{
  type: 'SET_CONTACT';
  contact: string;
}>;

type SetAddressEvent = ReplayableEvent<{
  type: 'SET_ADDRESS';
  address: string;
}>;

type SetCountryEvent = ReplayableEvent<{
  type: 'SET_COUNTRY';
  inCanada: boolean;
}>;

type SetPhoneNumberEvent = ReplayableEvent<{
  type: 'SET_PHONE_NUMBER';
  phoneNumber: string;
}>;

type SetEmailEvent = ReplayableEvent<{
  type: 'SET_EMAIL';
  email: string;
}>;

type SetNotesEvent = ReplayableEvent<{
  type: 'SET_NOTES';
  notes: string;
}>;

type ReplayableEvents =
  | SetCompanyEvent
  | SetWebsiteEvent
  | SetContactEvent
  | SetAddressEvent
  | SetCountryEvent
  | SetPhoneNumberEvent
  | SetEmailEvent
  | SetNotesEvent;

type Events =
  | { type: 'NEXT' }
  | { type: 'BACK' }
  | { type: 'BLUR_COMPANY' }
  | { type: 'BLUR_CONTACT' }
  | { type: 'BLUR_ADDRESS' }
  | { type: 'BLUR_PHONE_NUMBER' }
  | { type: 'BLUR_EMAIL' }
  | ReplayableEvents;

type Context = {
  company: string;
  website: string;
  contact: string;
  address: string;
  inCanada: boolean;
  phoneNumber: string;
  email: string;
  notes: string;
};

const inputStates = {
  pristine: {
    on: {
      NEXT: 'empty',
    },
  },
  empty: {},
  valid: {},
  invalid: {},
};

export const machine = createMachine(
  {
    predictableActionArguments: true,
    tsTypes: {} as import('./MasterRecordingMachine.typegen').Typegen0,
    schema: {
      events: {} as Events,
      context: {} as Context,
    },
    id: 'masterRelease',
    context: {
      company: '',
      website: '',
      contact: '',
      address: '',
      inCanada: true,
      phoneNumber: '',
      email: '',
      notes: '',
    },
    invoke: {
      id: 'resume',
      src: 'resume',
    },
    type: 'parallel',
    states: {
      company: {
        initial: 'pristine',
        states: {
          ...inputStates,
        },
        on: {
          SET_COMPANY: {
            actions: ['setCompany', 'saveCompany'],
            target: '.valid',
          },
          BLUR_COMPANY: [
            { cond: 'isEmpty', target: '.empty' },
            { target: '.valid' },
          ],
        },
      },
      website: {
        initial: 'pristine',
        states: {
          ...inputStates,
        },
        on: {
          SET_WEBSITE: { actions: ['setWebsite', 'saveWebsite'] },
        },
      },
      contact: {
        initial: 'pristine',
        states: {
          ...inputStates,
        },
        on: {
          SET_CONTACT: {
            actions: ['setContact', 'saveContact'],
            target: '.valid',
          },
          BLUR_CONTACT: [{ cond: 'isEmpty', target: '.empty' }, '.valid'],
        },
      },
      address: {
        initial: 'pristine',
        states: {
          ...inputStates,
        },
        on: {
          SET_ADDRESS: {
            actions: ['setAddress', 'saveAddress'],
            target: '.valid',
          },
          BLUR_ADDRESS: [{ cond: 'isEmpty', target: '.empty' }, '.valid'],
        },
      },
      country: {
        initial: 'valid',
        states: {
          ...inputStates,
          invalid: {
            initial: 'default',
            states: {
              default: {
                on: {
                  NEXT: 'pulse',
                },
              },
              pulse: {
                after: {
                  1000: 'default',
                },
              },
            },
          },
        },
        on: {
          SET_COUNTRY: [
            {
              cond: 'isCountryInvalid',
              actions: ['setCountry', 'saveCountry'],
              target: '.invalid',
            },
            {
              actions: ['setCountry', 'saveCountry'],
              target: ['.valid', 'submitted.no'],
            },
          ],
        },
      },
      phoneNumber: {
        initial: 'pristine',
        states: {
          ...inputStates,
        },
        on: {
          SET_PHONE_NUMBER: {
            actions: ['setPhoneNumber', 'savePhoneNumber'],
            target: '.valid',
          },
          BLUR_PHONE_NUMBER: [
            { cond: 'isEmpty', target: '.empty' },
            {
              cond: 'isPhoneNumberInvalid',
              target: '.invalid',
            },
            '.valid',
          ],
        },
      },
      email: {
        initial: 'pristine',
        states: {
          ...inputStates,
        },
        on: {
          SET_EMAIL: {
            target: '.valid',
            actions: ['setEmail', 'saveEmail'],
          },
          BLUR_EMAIL: [
            { cond: 'isEmpty', target: '.empty' },
            {
              cond: 'isEmailBadFormat',
              target: '.invalid',
            },
            '.valid',
          ],
        },
      },
      notes: {
        initial: 'pristine',
        states: {
          ...inputStates,
        },
        on: {
          SET_NOTES: { actions: ['setNotes', 'saveNotes'] },
        },
      },
      submitted: {
        initial: 'no',
        states: {
          no: {
            on: {
              NEXT: [
                { cond: 'hasEmptyFields', target: 'yes.missing' },
                {
                  cond: 'isFormValid',
                  actions: 'next',
                },
                'yes.invalid',
              ],
            },
          },
          yes: {
            initial: 'invalid',
            states: {
              invalid: {
                initial: 'default',
                states: {
                  default: {},
                  pulse: {
                    after: {
                      1000: 'default',
                    },
                  },
                },
                on: {
                  NEXT: [
                    { cond: 'hasEmptyFields', target: 'missing' },
                    {
                      cond: 'isFormValid',
                      actions: 'next',
                    },
                    '.pulse',
                  ],
                },
              },
              missing: {
                initial: 'default',
                states: {
                  default: {},
                  pulse: {
                    after: {
                      1000: 'default',
                    },
                  },
                },
                on: {
                  NEXT: [
                    { cond: 'hasEmptyFields', target: '.pulse' },
                    {
                      cond: 'isFormValid',
                      actions: 'next',
                    },
                    'invalid',
                  ],
                },
              },
            },
          },
        },
        on: {
          BACK: { actions: 'back' },
        },
      },
    },
  },
  {
    guards: {
      isEmpty: ({ company, contact, address, phoneNumber, email }, { type }) =>
        match(type)
          .with('BLUR_COMPANY', () => company)
          .with('BLUR_CONTACT', () => contact)
          .with('BLUR_ADDRESS', () => address)
          .with('BLUR_PHONE_NUMBER', () => phoneNumber)
          .with('BLUR_EMAIL', () => email)
          .run()
          .trim() === '',
      isCountryInvalid: (_, { inCanada }) => !inCanada,
      isPhoneNumberInvalid: ({ phoneNumber }) =>
        !isPhoneNumberValid(phoneNumber),
      isEmailBadFormat: ({ email }) => !isEmailValid(email),
      isFormValid: (context, event, { state }) =>
        [
          'company.valid',
          'contact.valid',
          'address.valid',
          'country.valid',
          'phoneNumber.valid',
          'email.valid',
        ].every((match) => state.matches(match)),
      hasEmptyFields: (context, event, { state }) =>
        [
          'company.pristine',
          'contact.pristine',
          'address.pristine',
          'country.pristine',
          'phoneNumber.pristine',
          'company.empty',
          'contact.empty',
          'address.empty',
          'country.empty',
          'phoneNumber.empty',
        ].some((match) => state.matches(match)),
    },
    actions: {
      setCompany: assign((_, { company }) => ({ company })),
      setWebsite: assign((_, { website }) => ({ website })),
      setContact: assign((_, { contact }) => ({ contact })),
      setAddress: assign((_, { address }) => ({ address })),
      setCountry: assign((_, { inCanada }) => ({ inCanada })),
      setPhoneNumber: assign((_, { phoneNumber }) => ({ phoneNumber })),
      setEmail: assign((_, { email }) => ({ email })),
      setNotes: assign((_, { notes }) => ({ notes })),
      next: sendParent(next()),
      back: sendParent(back()),
    },
  }
);

export type MasterRecordingActor = ActorRefFrom<typeof machine>;

export type MasterRecordingMachine = typeof machine;
export type MasterRecordingMachineState = StateFrom<MasterRecordingMachine>;
export type MasterRecordingMachineSender = Sender<
  EventFrom<MasterRecordingMachine>
>;
export type MasterRecordingMachineOptions = MachineOptionsFrom<
  MasterRecordingMachine,
  true
>;

export const createMasterRecordingResume =
  (storage: EligibilityStorage | AddReleaseStorage) =>
  ({ inCanada }: Context) =>
  (send: MasterRecordingMachineSender) => {
    const replay = createReplay<ReplayableEvents>();
    const { master } = storage.get();

    (
      [
        ...(master?.company !== undefined
          ? [
              replay({
                type: 'SET_COMPANY',
                company: master.company,
              }),
              { type: 'BLUR_COMPANY' as const },
            ]
          : []),
        ...(master?.website !== undefined
          ? [replay({ type: 'SET_WEBSITE', website: master.website })]
          : []),
        ...(master?.contact !== undefined
          ? [
              replay({
                type: 'SET_CONTACT',
                contact: master.contact,
              }),
              { type: 'BLUR_CONTACT' as const },
            ]
          : []),
        ...(master?.address !== undefined
          ? [
              replay({
                type: 'SET_ADDRESS',
                address: master.address,
              }),
              { type: 'BLUR_ADDRESS' as const },
            ]
          : []),
        ...(master?.inCanada !== undefined
          ? [
              replay({
                type: 'SET_COUNTRY',
                inCanada: master.inCanada,
              }),
            ]
          : [
              {
                type: 'SET_COUNTRY' as const,
                inCanada,
              },
            ]),
        ...(master?.phoneNumber !== undefined
          ? [
              replay({
                type: 'SET_PHONE_NUMBER',
                phoneNumber: master.phoneNumber,
              }),
              { type: 'BLUR_PHONE_NUMBER' as const },
            ]
          : []),
        ...(master?.email !== undefined
          ? [
              replay({
                type: 'SET_EMAIL',
                email: master.email,
              }),
              { type: 'BLUR_EMAIL' as const },
            ]
          : []),
        ...(master?.notes !== undefined
          ? [replay({ type: 'SET_NOTES', notes: master.notes })]
          : []),
      ] satisfies Events[]
    ).forEach((event) => send(event));
  };

export const createMasterRecordingSaveActions = (
  storage: EligibilityStorage | AddReleaseStorage
) => ({
  saveCompany: (_: Context, { company, replay }: SetCompanyEvent) =>
    storage.add({ master: { company } }, replay),
  saveWebsite: (_: Context, { website, replay }: SetWebsiteEvent) =>
    storage.add({ master: { website } }, replay),
  saveContact: (_: Context, { contact, replay }: SetContactEvent) =>
    storage.add({ master: { contact } }, replay),
  saveAddress: (_: Context, { address, replay }: SetAddressEvent) =>
    storage.add({ master: { address } }, replay),
  saveCountry: (_: Context, { inCanada, replay }: SetCountryEvent) =>
    storage.add({ master: { inCanada } }, replay),
  savePhoneNumber: (_: Context, { phoneNumber, replay }: SetPhoneNumberEvent) =>
    storage.add({ master: { phoneNumber } }, replay),
  saveEmail: (_: Context, { email, replay }: SetEmailEvent) =>
    storage.add({ master: { email } }, replay),
  saveNotes: (_: Context, { notes, replay }: SetNotesEvent) =>
    storage.add({ master: { notes } }, replay),
});
