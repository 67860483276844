import { match, P } from 'ts-pattern';
import { Feedback } from '../Feedback';
import { CheckOption } from '../CheckOption';
import { Button } from '../Button';
import { Context } from '../../machines/Application/ApplicationMachine';
import { FundingRoundStatusType } from '../../graphql/operations';

interface ApplicationReviewBlockFooterProps {
  context: Context;
  isConfirmed: boolean;
  toggleConfirmation: () => void;
  submitApplication: () => void;
  submitClaims: () => void;
  resubmitClaims: () => void;
}

export function ApplicationReviewBlockFooter({
  context,
  isConfirmed,
  toggleConfirmation,
  submitApplication,
  submitClaims,
  resubmitClaims,
}: ApplicationReviewBlockFooterProps) {
  const {
    currentOrNextRound,
    previousRound,
    isSubmittable,
    isClaimsSubmittable,
    submittedAt,
    claimsSubmittedAt,
    claimsResubmittedAt,
  } = context;

  const isUpcoming =
    currentOrNextRound === null ||
    currentOrNextRound.status === FundingRoundStatusType.Upcoming;

  return (
    <>
      {!submittedAt && isUpcoming && (
        <Feedback type="neutral" icon="alertTriangle" size="compact">
          <p>
            The previous Funding Round
            {previousRound && `, #${previousRound.title},`} is closed for
            applications. Details for the next Funding Round
            {currentOrNextRound && `, #${currentOrNextRound.title},`} will be
            announced soon.
          </p>
        </Feedback>
      )}
      {match({ submittedAt, claimsSubmittedAt, claimsResubmittedAt })
        .with({ submittedAt: P.nullish }, () => (
          <>
            <CheckOption
              label="I have finished my application and I am ready to submit."
              checked={isConfirmed}
              disabled={!isSubmittable}
              onChange={toggleConfirmation}
            />
            {isConfirmed && (
              <Feedback icon="alertTriangle" type="neutral" size="compact">
                No changes can be made to an application once it’s been
                submitted and only one application per band/artist is allowed
                per round.
              </Feedback>
            )}
            <Button
              variant="primary"
              label="Submit Application"
              disabled={!isSubmittable || !isConfirmed}
              onClick={submitApplication}
            />
          </>
        ))
        .with({ claimsSubmittedAt: P.nullish }, () => (
          <>
            <CheckOption
              label="I have finished my funding claim and I am ready to submit."
              checked={isConfirmed}
              disabled={!isClaimsSubmittable}
              onChange={toggleConfirmation}
            />
            <Button
              variant="primary"
              label="Submit Claim"
              disabled={!isClaimsSubmittable}
              onClick={submitClaims}
            />
          </>
        ))
        .with({ claimsResubmittedAt: P.nullish }, () => (
          <>
            <CheckOption
              label="I have finished my funding claim and I am ready to resubmit."
              checked={isConfirmed}
              disabled={!isClaimsSubmittable}
              onChange={toggleConfirmation}
            />
            <Button
              variant="primary"
              label="Resubmit Claim"
              disabled={!isClaimsSubmittable}
              onClick={resubmitClaims}
            />
          </>
        ))
        .run()}
    </>
  );
}
