import { GraphQLClient } from 'graphql-request';
import { CreateSingleDocument, CreateSingleInput } from '../graphql/operations';

export const createCreateSinglePromise =
  (client: GraphQLClient) => (input: CreateSingleInput) =>
    client
      .request(CreateSingleDocument, { input })
      .then((response) => ({ single: response.result?.single }));

export type CreateSinglePromise = ReturnType<typeof createCreateSinglePromise>;
export type CreateSinglePromiseResult = Awaited<
  ReturnType<CreateSinglePromise>
>;
