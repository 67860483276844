import { GraphQLClient } from 'graphql-request';
import { UpdateTourDateDocument } from '../../graphql/operations';
import { UpdateTourDate } from '../../events/UpdateTourDate';
import { upsertTourDateResultSchema } from '../../schemas/tourDates/upsertTourDateResultSchema';

export const createUpdateTourDatePromise =
  (client: GraphQLClient) =>
  async (_: unknown, { id, tourDate }: UpdateTourDate) => {
    const response = await client.request(UpdateTourDateDocument, {
      id,
      tourDate,
    });

    return upsertTourDateResultSchema.parse(response.request?.tourDate);
  };

export type CreateUpdateTourDatePromise = ReturnType<
  typeof createUpdateTourDatePromise
>;

export type CreateUpdateTourDatePromiseResult = Awaited<
  ReturnType<CreateUpdateTourDatePromise>
>;
