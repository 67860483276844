import type {
  Sender,
  EventFrom,
  RaiseAction,
  MachineOptionsFrom,
} from 'xstate';
import { assign, createMachine, raise, send } from 'xstate';
import { toCalendarDate } from '@internationalized/date';
import { StateFrom } from '../../utils/StateFrom';
import { createContext as createFundingRequestsBlockMachineContext } from '../components/FundingRequestsBlockMachine';
import { createContext as createTourDatesBlockMachineContext } from '../components/TourDatesBlockMachine';
import { CreateGetApplicationPromiseResult } from '../../promises/createGetApplicationPromise';
import { SubmitApplicationPromiseResult } from '../../promises/createSubmitApplicationPromise';
import { SubmitApplicationClaimsPromiseResult } from '../../promises/createSubmitApplicationClaimsPromise';
import { createContext as createAttachmentsBlockContext } from '../components/AttachmentsBlockMachine';
import { createContext as createApplicationStatementBlockContext } from '../components/ApplicationStatementBlockMachine';
import { createContext as createAdminReviewContext } from '../Admin/ApplicationReviewMachine';
import { createContext as createDirectDepositsContext } from '../ClaimFunds/DirectDepositsMachine';
import { createContext as createSignedContractContext } from '../ClaimFunds/SignedContractMachine';
import { createContext as createTourSummaryContext } from '../ClaimFunds/TourSummaryMachine';
import { createContext as createContactsBlockMachineContext } from '../components/ContactsBlockMachine';
import { isIdNotFound } from '../../errors/IdNotFoundError';
import {
  refreshApplication,
  RefreshApplicationEvent,
} from '../../events/RefreshApplication';
import { updateTourDates } from '../../events/UpdateTourDates';
import { updateFundingRequests } from '../../events/UpdateFundingRequests';
import { logMachineError } from '../../utils/logError';
import { ExpenseBlockMachineWithImplementations } from '../components/ExpenseBlockMachine';
import { ResubmitApplicationClaimsPromiseResult } from '../../promises/createResubmitApplicationClaimsPromise';
import { ApplicationStatus } from '../../graphql/operations';
import { CompleteApplicationPromiseResult } from '../../promises/createCompleteApplicationPromise';

export type Context = CreateGetApplicationPromiseResult & {
  expenseBlockMachine: ExpenseBlockMachineWithImplementations;
};

type Events =
  | RefreshApplicationEvent
  | { type: 'APPLICATION_REFRESHED' }
  | { type: 'TOGGLE_CONFIRMATION' }
  | { type: 'OPEN_REOPEN_CLAIMS_FORM' }
  | { type: 'CLOSE_REOPEN_CLAIMS_FORM' }
  | { type: 'SUBMIT_APPLICATION' }
  | { type: 'SUBMIT_CLAIMS' }
  | { type: 'RESUBMIT_CLAIMS' }
  | { type: 'MARK_COMPLETE' };

type Services = {
  getApplication: {
    data: CreateGetApplicationPromiseResult;
  };
  submitApplication: {
    data: SubmitApplicationPromiseResult;
  };
  createExpenseBlockMachine: {
    data: ExpenseBlockMachineWithImplementations;
  };
  submitClaims: {
    data: SubmitApplicationClaimsPromiseResult;
  };
  resubmitClaims: {
    data: ResubmitApplicationClaimsPromiseResult;
  };
  markComplete: {
    data: CompleteApplicationPromiseResult;
  };
};

export const machine = createMachine(
  {
    id: 'application',
    predictableActionArguments: true,
    tsTypes: {} as import('./ApplicationMachine.typegen').Typegen0,
    schema: {
      context: {} as Context,
      events: {} as Events,
      services: {} as Services,
    },
    initial: 'load',
    states: {
      load: {
        initial: 'application',
        states: {
          application: {
            invoke: {
              id: 'getApplication',
              src: 'getApplication',
              onDone: {
                actions: 'saveApplication',
                target: 'refs',
              },
              onError: [
                { cond: 'isIdNotFound', target: 'notFound' },
                { target: 'error' },
              ],
            },
          },
          refs: {
            invoke: {
              id: 'createExpenseBlockMachine',
              src: 'createExpenseBlockMachine',
              onDone: {
                actions: 'setExpenseBlockRef',
                target: '#application.form',
              },
            },
          },
          error: { entry: 'logMachineError' },
          notFound: {},
        },
      },
      form: {
        id: 'form',
        type: 'parallel',
        states: {
          'signed-contract': {
            invoke: {
              id: 'signedContractMachine',
              src: 'signedContractMachine',
              data: (context) =>
                createSignedContractContext({
                  applicationId: context.applicationId,
                  artistName: context.artist.name,
                  signedContract: context.signedContract,
                }),
            },
          },
          'tour-summary': {
            invoke: {
              id: 'tourSummaryMachine',
              src: 'tourSummaryMachine',
              data: (context) =>
                createTourSummaryContext({
                  applicationId: context.applicationId,
                  tourSummary: context.tourSummary,
                  applicationType: context.applicationType,
                }),
            },
          },
          'claim-funds': {
            invoke: {
              id: 'directDepositsMachine',
              src: 'directDepositsMachine',
              data: (context) =>
                createDirectDepositsContext({
                  applicationId: context.applicationId,
                  artistId: context.artist.id,
                  directDepositAccounts: context.directDepositAccounts,
                  touringDirectDepositAccount:
                    context.touringDirectDepositAccount,
                  fundingRequestDirectDepositAccount:
                    context.fundingRequestDirectDepositAccount,
                  applicationType: context.applicationType,
                  isAdmin: context.isAdmin,
                  stage: context.stage,
                  approvedTotalForTourDates: context.approvedTotalForTourDates,
                  approvedTotalForFundingRequests:
                    context.approvedTotalForFundingRequests,
                  touringPaid: context.touringPaid,
                  fundingRequestPaid: context.fundingRequestPaid,
                }),
            },
          },
          'admin-review': {
            invoke: {
              id: 'adminApplicationReviewMachine',
              src: 'adminApplicationReview',
              data: (context) => createAdminReviewContext(context),
            },
          },
          'reopen-claims': {
            initial: 'closed',
            states: {
              closed: {
                on: {
                  OPEN_REOPEN_CLAIMS_FORM: 'open',
                },
              },
              open: {
                on: {
                  CLOSE_REOPEN_CLAIMS_FORM: 'closed',
                },
                invoke: {
                  id: 'reopenClaimsMachine',
                  src: 'reopenClaimsMachine',
                  data: (context) => ({
                    applicationId: context.applicationId,
                    notes: context.reopenClaimsNotes,
                  }),
                  onDone: { target: 'closed', actions: ['refreshApplication'] },
                },
              },
            },
          },
          'funding-requests': {
            invoke: {
              id: 'fundingRequestsMachine',
              src: 'fundingRequestsMachine',
              data: (context) =>
                createFundingRequestsBlockMachineContext(
                  context.applicationId,
                  context.applicationType,
                  context.status,
                  context.stage,
                  context.fundingRequests,
                  context.year,
                  context.maximumYearlyTotalForFundingRequests,
                  context.remainingYearlyTotalForFundingRequests,
                  context.budgetForFundingRequests,
                  context.submittedAt,
                  context.claimsSubmittedAt,
                  context.status === ApplicationStatus.Approved &&
                    context.submittedAt
                    ? toCalendarDate(context.submittedAt)
                    : context.presentCutoffDate,
                  context.applicationRound?.futureCutoffDate ??
                    context.currentOrNextRound?.futureCutoffDate,
                  context.isAdmin,
                  context.isBoard,
                  context.expenseBlockMachine
                ),
            },
          },
          'tour-dates': {
            invoke: {
              id: 'tourDatesMachine',
              src: 'tourDatesMachine',
              data: (context) =>
                createTourDatesBlockMachineContext(
                  context.applicationId,
                  context.tourDates,
                  context.tourDateTypes,
                  context.tourDateRegions,
                  context.year,
                  context.countries,
                  context.submittedAt,
                  context.claimsSubmittedAt,
                  context.status === ApplicationStatus.Approved &&
                    context.submittedAt
                    ? toCalendarDate(context.submittedAt)
                    : context.presentCutoffDate,
                  context.applicationRound?.futureCutoffDate ??
                    context.currentOrNextRound?.futureCutoffDate,
                  context.isAdmin,
                  context.isBoard,
                  context.status,
                  context.alternateTourDatesAllowed
                ),
            },
          },
          attachments: {
            invoke: {
              id: 'attachmentsMachine',
              src: 'attachmentsMachine',
              data: (context) =>
                createAttachmentsBlockContext(
                  context.applicationId,
                  context.attachments,
                  context.submittedAt
                ),
            },
          },
          applicationStatement: {
            invoke: {
              id: 'applicationStatementMachine',
              src: 'applicationStatementMachine',
              data: ({ applicationId, statement, submittedAt }) =>
                createApplicationStatementBlockContext(
                  applicationId,
                  statement,
                  submittedAt
                ),
            },
          },
          contacts: {
            invoke: {
              id: 'contactsBlockMachine',
              src: 'contactsBlockMachine',
              data: (context) =>
                createContactsBlockMachineContext(
                  context.applicationId,
                  context.contacts,
                  context.linkedAccounts,
                  context.primaryContact,
                  context.submittedAt
                ),
            },
          },
          review: {
            initial: 'unconfirmed',
            on: {
              MARK_COMPLETE: {
                cond: 'isCompletable',
                target: '.completingApplication',
              },
            },
            states: {
              unconfirmed: {
                on: {
                  REFRESH_APPLICATION: 'refreshing',
                  TOGGLE_CONFIRMATION: 'confirmed',
                },
              },
              confirmed: {
                initial: 'default',
                states: { default: {}, error: { entry: 'logMachineError' } },
                on: {
                  REFRESH_APPLICATION: 'refreshing',
                  TOGGLE_CONFIRMATION: 'unconfirmed',
                  SUBMIT_APPLICATION: {
                    cond: 'isSubmittable',
                    target: 'saving',
                  },
                  SUBMIT_CLAIMS: {
                    cond: 'isClaimsSubmittable',
                    target: 'submittingClaims',
                  },
                  RESUBMIT_CLAIMS: {
                    cond: 'isClaimsSubmittable',
                    target: 'resubmittingClaims',
                  },
                },
              },
              refreshing: {
                invoke: {
                  id: 'getApplication',
                  src: 'getApplication',
                  onDone: {
                    actions: [
                      'saveApplication',
                      'updateTourDates',
                      'updateFundingRequests',
                      // xstate type inference fails here
                      // See https://github.com/statelyai/xstate/issues/1414#issuecomment-699972485
                      raise({
                        type: 'APPLICATION_REFRESHED',
                      }) as RaiseAction<Event>,
                    ],
                    target: 'unconfirmed',
                  },
                  onError: '#application.load.error',
                },
              },
              saving: {
                invoke: {
                  id: 'submitApplication',
                  src: 'submitApplication',
                  onError: 'confirmed.error',
                  onDone: {
                    actions: 'navigateToDashboard',
                  },
                },
              },
              submittingClaims: {
                invoke: {
                  id: 'submitClaims',
                  src: 'submitClaims',
                  onError: 'confirmed.error',
                  onDone: { target: 'refreshing', actions: 'scrollToTop' },
                },
              },
              completingApplication: {
                invoke: {
                  id: 'completeApplication',
                  src: 'completeApplication',
                  onError: 'confirmed.error',
                  onDone: { target: 'refreshing', actions: 'scrollToTop' },
                },
              },
              resubmittingClaims: {
                invoke: {
                  id: 'resubmitClaims',
                  src: 'resubmitClaims',
                  onError: 'confirmed.error',
                  onDone: { target: 'refreshing', actions: 'scrollToTop' },
                },
              },
            },
          },
        },
      },
    },
  },
  {
    guards: {
      isSubmittable: ({ isSubmittable }) => isSubmittable,
      isClaimsSubmittable: ({ isClaimsSubmittable }) => isClaimsSubmittable,
      isCompletable: ({ isCompletable }) => isCompletable,
      isIdNotFound: (_, { data }) => isIdNotFound(data),
    },
    actions: {
      logMachineError,
      saveApplication: assign((_, { data }) => data),
      updateTourDates: send(({ tourDates }) => updateTourDates(tourDates), {
        to: 'tourDatesMachine',
      }),
      refreshApplication: send(() => refreshApplication()),
      updateFundingRequests: send(
        ({
          fundingRequests,
          maximumYearlyTotalForFundingRequests,
          remainingYearlyTotalForFundingRequests,
          stage,
        }) =>
          updateFundingRequests(
            fundingRequests,
            maximumYearlyTotalForFundingRequests,
            remainingYearlyTotalForFundingRequests,
            stage
          ),
        {
          to: 'fundingRequestsMachine',
        }
      ),
      setExpenseBlockRef: assign((_, event) => ({
        expenseBlockMachine: event.data,
      })),
    },
  }
);

type Machine = typeof machine;

export type ApplicationMachineState = StateFrom<Machine>;
export type ApplicationMachineSender = Sender<EventFrom<Machine>>;
export type ApplicationUseMachineOptions = MachineOptionsFrom<Machine, true>;
