import { useMachine } from '@xstate/react';
import {
  machine,
  SubscribersMachineOptions,
} from '../../../machines/Admin/SubscribersMachine';
import { SubscribersView } from './SubscribersView';
import { useWindowSize } from '../../../hooks/useWindowSize';

type SubscribersControllerProps = {
  options: SubscribersMachineOptions;
};

export function SubscribersController({ options }: SubscribersControllerProps) {
  const [state, send] = useMachine(machine, options);
  useWindowSize((size) => {
    if (size === 'mobile') {
      send({ type: 'SET_LIMIT', limit: 10 });
    } else {
      send({ type: 'SET_LIMIT', limit: 20 });
    }
  });

  return <SubscribersView state={state} send={send} />;
}
