import { GraphQLClient } from 'graphql-request';
import {
  InsertDirectDepositAndLinkToApplicationDocument,
  InsertDirectDepositAndLinkToApplicationType,
  LinkDirectDepositAccountToApplicationDocument,
  LinkDirectDepositAccountToApplicationType,
} from '../graphql/operations';
import { Context } from '../machines/ClaimFunds/SetDepositInfoMachine';
import { directDepositAccountSchema } from '../schemas/directDepositAccount';

export const createSaveApplicationDirectDepositInfo =
  (client: GraphQLClient) =>
  async ({
    selectedExistingAccountId,
    depositInfoType,
    artistId,
    applicationId,
    name,
    transit,
    institution,
    account,
  }: Context) => {
    if (!selectedExistingAccountId) {
      if (!name || !transit || !institution || !account) {
        throw new Error('Missing required values for direct deposit account');
      }

      const { result } = await client.request(
        InsertDirectDepositAndLinkToApplicationDocument,
        {
          input: {
            type: depositInfoType,
            artistId: artistId,
            applicationId: applicationId,
            name,
            transit,
            institution,
            account,
          },
          applicationId,
        }
      );
      return directDepositAccountSchema.parseAsync(
        result?.response?.directDepositAccount
      );
    } else if (
      depositInfoType === InsertDirectDepositAndLinkToApplicationType.Touring
    ) {
      const { result } = await client.request(
        LinkDirectDepositAccountToApplicationDocument,
        {
          input: {
            type: LinkDirectDepositAccountToApplicationType.Touring,
            directDepositId: selectedExistingAccountId,
            applicationId,
          },
          applicationId,
        }
      );
      return directDepositAccountSchema.parseAsync(
        result?.response?.directDepositAccount
      );
    } else if (
      depositInfoType ===
      InsertDirectDepositAndLinkToApplicationType.FundingRequest
    ) {
      const { result } = await client.request(
        LinkDirectDepositAccountToApplicationDocument,
        {
          input: {
            type: LinkDirectDepositAccountToApplicationType.FundingRequest,
            directDepositId: selectedExistingAccountId,
            applicationId,
          },
          applicationId,
        }
      );
      return directDepositAccountSchema.parseAsync(
        result?.response?.directDepositAccount
      );
    }

    return null;
  };

export type CreateSaveApplicationDirectDepositInfoPromise = ReturnType<
  typeof createSaveApplicationDirectDepositInfo
>;
export type CreateSaveApplicationDirectDepositInfoPromiseResult = Awaited<
  ReturnType<CreateSaveApplicationDirectDepositInfoPromise>
>;
