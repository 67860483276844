import type { EventFrom, Sender } from 'xstate';
import { assign, createMachine } from 'xstate';
import type { StateFrom } from '../../utils/StateFrom';
import type { MachineOptionsWithContextFrom } from '../../utils/MachineOptionsWithContextFrom';
import { CreateSearchAccountsPromiseResult } from '../../promises/Admin/createSearchAccountsPromise';
import { logMachineError } from '../../utils/logError';

export type SortMode = 'a-z' | 'z-a' | 'submitted' | 'modified';

type Events =
  | { type: 'SET_SEARCH_TERM'; searchTerm: string }
  | { type: 'SET_PAGE'; page: number }
  | { type: 'CHANGE_SORT'; sortMode: SortMode }
  | { type: 'SET_LIMIT'; limit: number };

type Services = {
  searchAccountsPromise: {
    data: CreateSearchAccountsPromiseResult;
  };
};

type Context = {
  accounts: CreateSearchAccountsPromiseResult;
  offset: number;
  limit: number;
  searchTerm: string;
  totalNumberOfAccounts: number;
  currentPage: number;
  sortMode: SortMode;
};

export const createContext = (
  accounts: CreateSearchAccountsPromiseResult,
  offset: number,
  limit: number
): Context => ({
  accounts,
  offset,
  limit,
  searchTerm: '',
  totalNumberOfAccounts: 0,
  currentPage: 1,
  sortMode: 'a-z',
});

export const machine = createMachine(
  {
    predictableActionArguments: true,
    tsTypes: {} as import('./AccountsMachine.typegen').Typegen0,
    schema: {
      context: {} as Context,
      events: {} as Events,
      services: {} as Services,
    },
    initial: 'init',
    id: 'form',
    states: {
      init: {
        invoke: {
          id: 'searchAccountsPromise',
          src: 'searchAccountsPromise',
          onDone: {
            actions: 'setContext',
            target: 'ready',
          },
          onError: 'error',
        },
      },
      error: { entry: 'logMachineError' },
      ready: {
        on: {
          SET_SEARCH_TERM: {
            actions: 'setSearchTerm',
            target: 'typing',
          },
          SET_PAGE: {
            actions: 'setPage',
            target: 'init',
          },
        },
      },
      typing: {
        after: {
          500: {
            target: 'search',
          },
        },
        on: {
          SET_SEARCH_TERM: {
            actions: 'setSearchTerm',
            target: 'typing',
          },
        },
      },
      search: {
        initial: 'which',
        states: {
          which: {
            always: [{ cond: 'isEmpty', target: 'all' }, 'some'],
          },
          all: {
            entry: 'resetSearch',
            invoke: {
              id: 'searchAccountsPromise',
              src: 'searchAccountsPromise',
              onDone: {
                actions: 'setContext',
                target: '#form.ready',
              },
              onError: '#form.error',
            },
          },
          some: {
            entry: 'resetSearch',
            invoke: {
              id: 'searchAccountsPromise',
              src: 'searchAccountsPromise',
              onDone: {
                actions: 'setAccounts',
                target: '#form.ready',
              },
              onError: '#form.error',
            },
          },
        },
      },
    },
    on: {
      CHANGE_SORT: {
        actions: 'changeSort',
        target: 'search.all',
      },
      SET_LIMIT: {
        actions: 'setLimit',
        target: 'search',
      },
    },
  },
  {
    guards: {
      isEmpty: ({ searchTerm }) => searchTerm === '',
    },
    actions: {
      logMachineError,
      setContext: assign((_, { data }) => ({
        accounts: data,
        totalNumberOfAccounts: data.length ? data[0].count : 0,
      })),
      setSearchTerm: assign((_, { searchTerm }) => ({
        searchTerm,
      })),
      setAccounts: assign((_, { data }) => ({
        accounts: data,
        totalNumberOfAccounts: data.length ? data[0].count : 0,
      })),
      setPage: assign((context, event) => ({
        currentPage: event.page,
        offset: (event.page - 1) * context.limit,
      })),
      changeSort: assign((_, event) => ({
        sortMode: event.sortMode,
      })),
      // eslint-disable-next-line
      resetSearch: assign((_) => ({
        currentPage: 1,
        offset: 0,
      })),
      setLimit: assign((context, event) => ({
        limit: event.limit,
      })),
    },
  }
);

type Machine = typeof machine;

export type AccountsMachineState = StateFrom<Machine>;
export type AccountsMachineSender = Sender<EventFrom<Machine>>;
export type AccountsMachineOptions = MachineOptionsWithContextFrom<Machine>;
