import { useActor } from '@xstate/react';
import { SubmittedFundingRequestCardMachineActor } from '../../../machines/components/SubmittedFundingRequestCardMachine';
import { SubmittedFundingRequestCardView } from './SubmittedFundingRequestCardView';

export function SubmittedFundingRequestCardController({
  actor,
  showExpenseEdit,
  showExpenseDelete,
}: {
  actor: SubmittedFundingRequestCardMachineActor;
  showExpenseEdit: boolean;
  showExpenseDelete: boolean;
}) {
  const [state, send] = useActor(actor);

  return (
    <SubmittedFundingRequestCardView
      state={state}
      send={send}
      showExpenseEdit={showExpenseEdit}
      showExpenseDelete={showExpenseDelete}
    />
  );
}
