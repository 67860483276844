import { InfoBox } from '../InfoBox';
import { DashboardBlock } from './DashboardBlock';
import { ReleaseCard, ReleaseCardProps } from './ReleaseCard';

interface ReleasesBlockProps {
  artistId: string;
  releases: ReleaseCardProps[];
  isOwner: boolean;
}

export function ReleasesBlock({
  artistId,
  releases,
  isOwner,
}: ReleasesBlockProps) {
  return (
    <DashboardBlock
      title="Releases"
      buttonIcon="plus"
      buttonText={isOwner ? 'Add Release' : undefined}
      buttonLinkTo={`/artist/${artistId}/releases/add`}
      linkText="View All"
      linkHref="releases"
    >
      {releases.length ? (
        releases.map((release) => (
          <ReleaseCard key={release.title} {...release} />
        ))
      ) : (
        <InfoBox>You don’t have any releases.</InfoBox>
      )}
    </DashboardBlock>
  );
}
