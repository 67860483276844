import z from 'zod';

import { tourDateSchema } from './tourDateSchema';
import { tourDateRegionSchema } from './tourDateRegionSchema';

export const upsertTourDateResultSchema = tourDateSchema.extend({
  application: z.object({
    year: z.number(),
    tourDateRegions: tourDateRegionSchema.array(),
    alternateTourDatesAllowed: z.boolean(),
  }),
});
