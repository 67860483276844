import { TourDateInput } from '../graphql/operations';

export type AddTourDate = {
  type: 'ADD_TOUR_DATE';
  tourDate: TourDateInput;
};

export const addTourDate = (
  tourDate: AddTourDate['tourDate']
): AddTourDate => ({ type: 'ADD_TOUR_DATE', tourDate });
