import { ReactComponent as StartLoadingStar } from '../assets/images/loading.svg';
import cn from 'classnames';

import styles from './Loading.module.scss';

interface LoadingProps {
  inline?: boolean;
}

export function Loading({ inline }: LoadingProps) {
  return (
    <div className={cn([styles.loading, inline ? styles.inline : styles.page])}>
      <StartLoadingStar />
    </div>
  );
}
