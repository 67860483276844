import { useMachine } from '@xstate/react';
import { HomeMachineOptions, machine } from '../../machines/HomeMachine';
import { HomeView } from './HomeView';

type HomeControllerProps = {
  options: HomeMachineOptions;
  isSignedIn: boolean;
};

export function HomeController({ options, isSignedIn }: HomeControllerProps) {
  const [state] = useMachine(machine, options);

  return <HomeView state={state} isSignedIn={isSignedIn} />;
}
