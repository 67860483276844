import { match } from 'ts-pattern';
import type {
  Sender,
  EventFrom,
  MachineOptionsFrom,
  ActorRefFrom,
} from 'xstate';
import { createMachine, assign, sendParent, send } from 'xstate';
import { UpdateReleaseResult } from '../../promises/createUpdateReleasePromise';
import { logMachineError } from '../../utils/logError';
import { parsePositiveInteger } from '../../utils/parsePositiveInteger';
import { StateFrom } from '../../utils/StateFrom';
import {
  cancelEditReleaseDetails,
  updateCachedRelease,
} from '../Release/ReleaseMachine';

type Label = { id: string; name: string };
type Distributor = { id: string; name: string };

type Events =
  | { type: 'ATTEMPT_SAVE' }
  | { type: 'EDIT' }
  | { type: 'CANCEL_EDITING' }
  | { type: 'UPDATE_TITLE'; title: string }
  | { type: 'BLUR_TITLE' }
  | { type: 'UPDATE_LABEL'; label: Label }
  | { type: 'UPDATE_OTHER_LABEL'; otherLabel: string }
  | { type: 'UPDATE_DISTRIBUTOR'; distributor: Distributor }
  | { type: 'BLUR_LABEL' }
  | { type: 'BLUR_OTHER_LABEL' }
  | { type: 'BLUR_DISTRIBUTOR' }
  | { type: 'UPDATE_OTHER_DISTRIBUTOR'; otherDistributor: string }
  | { type: 'BLUR_OTHER_DISTRIBUTOR' }
  | { type: 'UPDATE_DATE'; date: string }
  | { type: 'UPDATE_NUMBER_OF_MINUTES'; numberOfMinutes: string }
  | { type: 'BLUR_NUMBER_OF_MINUTES' }
  | { type: 'UPDATE_NUMBER_OF_TRACKS'; numberOfTracks: string }
  | { type: 'BLUR_NUMBER_OF_TRACKS' }
  | { type: 'UPDATE_LANGUAGE'; isMajorityEnglish: boolean }
  | { type: 'BLUR_LANGUAGE' };

export type Context = {
  // Data required to render/validate/save form.
  releaseId: string;
  eligibilityCutoffDate: string;
  labels: Label[];
  distributors: Distributor[];
  // Input fields
  title: string;
  label: Label;
  otherLabel: string | null;
  distributor: Distributor;
  otherDistributor: string | null;
  date: string;
  numberOfMinutes: string;
  numberOfTracks: string;
  isMajorityEnglish: boolean;
};

type Services = {
  updateRelease: {
    data: UpdateReleaseResult;
  };
};

export const createContext = (context: Context): Context => context;

export const machine = createMachine(
  {
    id: 'releaseDetailsMachine',
    predictableActionArguments: true,
    tsTypes: {} as import('./ReleaseDetailsMachine.typegen').Typegen0,
    schema: {
      context: {} as Context,
      events: {} as Events,
      services: {} as Services,
    },
    type: 'parallel',
    entry: [
      'blurTitle',
      'blurLabel',
      'blurOtherLabel',
      'blurDistributor',
      'blurOtherDistributor',
      'blurDate',
      'blurNumberOfMinutes',
      'blurNumberOfTracks',
      'blurLanguage',
    ],
    states: {
      ui: {
        initial: 'edit',
        states: {
          edit: {
            on: {
              CANCEL_EDITING: {
                actions: 'cancelEdit',
              },
              UPDATE_TITLE: {
                actions: 'updateTitle',
              },
              BLUR_TITLE: [
                {
                  cond: 'isEmpty',
                  target: '#releaseDetailsMachine.title.error.empty',
                },
                {
                  target: '#releaseDetailsMachine.title.noError',
                },
              ],
              UPDATE_LABEL: {
                actions: 'updateLabel',
              },
              BLUR_LABEL: [
                {
                  cond: 'isEmpty',
                  target: '#releaseDetailsMachine.label.error.empty',
                },
                {
                  target: '#releaseDetailsMachine.label.noError',
                },
              ],
              UPDATE_OTHER_LABEL: {
                actions: 'updateOtherLabel',
              },
              BLUR_OTHER_LABEL: [
                {
                  cond: 'isNonOtherLabelSelected',
                  target: '#releaseDetailsMachine.otherLabel.hidden',
                },
                {
                  cond: 'isEmpty',
                  target: '#releaseDetailsMachine.otherLabel.error.empty',
                },
                {
                  target: '#releaseDetailsMachine.otherLabel.noError',
                },
              ],
              UPDATE_DISTRIBUTOR: {
                actions: 'updateDistributor',
              },
              BLUR_DISTRIBUTOR: [
                {
                  cond: 'isEmpty',
                  target: '#releaseDetailsMachine.distributor.error.empty',
                },
                {
                  target: '#releaseDetailsMachine.distributor.noError',
                },
              ],
              UPDATE_OTHER_DISTRIBUTOR: {
                actions: 'updateOtherDistributor',
              },
              BLUR_OTHER_DISTRIBUTOR: [
                {
                  cond: 'isNonOtherDistributorSelected',
                  target: '#releaseDetailsMachine.otherDistributor.hidden',
                },
                {
                  cond: 'isEmpty',
                  target: '#releaseDetailsMachine.otherDistributor.error.empty',
                },
                {
                  target: '#releaseDetailsMachine.otherDistributor.noError',
                },
              ],
              UPDATE_DATE: {
                actions: 'updateDate',
                target: '#releaseDetailsMachine.date.validating',
              },
              UPDATE_NUMBER_OF_MINUTES: {
                actions: 'updateNumberOfMinutes',
              },
              BLUR_NUMBER_OF_MINUTES: [
                {
                  cond: 'isEmpty',
                  target: '#releaseDetailsMachine.numberOfMinutes.error.empty',
                },
                {
                  cond: 'isNotNumeric',
                  target:
                    '#releaseDetailsMachine.numberOfMinutes.error.incorrectType',
                },
                {
                  cond: 'isNumberOfMinutesInvalid',
                  target: '#releaseDetailsMachine.numberOfMinutes.error.value',
                },
                {
                  target: '#releaseDetailsMachine.numberOfMinutes.noError',
                },
              ],
              UPDATE_NUMBER_OF_TRACKS: {
                actions: 'updateNumberOfTracks',
              },
              BLUR_NUMBER_OF_TRACKS: [
                {
                  cond: 'isEmpty',
                  target: '#releaseDetailsMachine.numberOfTracks.error.empty',
                },
                {
                  cond: 'isNotNumeric',
                  target:
                    '#releaseDetailsMachine.numberOfTracks.error.incorrectType',
                },
                {
                  cond: 'isNumberOfTracksInvalid',
                  target: '#releaseDetailsMachine.numberOfTracks.error.value',
                },
                {
                  target: '#releaseDetailsMachine.numberOfTracks.noError',
                },
              ],
              UPDATE_LANGUAGE: [
                {
                  actions: 'updateLanguage',
                  cond: 'isMajorityFrench',
                  target: '#releaseDetailsMachine.language.error.value',
                },
                {
                  actions: 'updateLanguage',
                  cond: 'isMajorityEnglish',
                  target: '#releaseDetailsMachine.language.noError',
                },
              ],
              ATTEMPT_SAVE: [
                {
                  cond: 'isFormValid',
                  target: 'saving',
                },
                {
                  target:
                    '#releaseDetailsMachine.wholeForm.error.invalid.highlight',
                  actions: [
                    'blurTitle',
                    'blurDistributor',
                    'blurOtherDistributor',
                    'blurDate',
                    'blurNumberOfMinutes',
                    'blurNumberOfTracks',
                    'blurLanguage',
                  ],
                },
              ],
            },
          },
          saving: {
            invoke: {
              src: 'updateRelease',
              onDone: [
                {
                  actions: ['updateParentCachedRelease', 'cancelEdit'],
                },
              ],
              onError: [
                {
                  cond: 'isNetworkError',
                  target: [
                    '#releaseDetailsMachine.wholeForm.error.network',
                    'edit',
                  ],
                },
                {
                  target: [
                    '#releaseDetailsMachine.wholeForm.error.internal',
                    'edit',
                  ],
                },
              ],
            },
          },
        },
      },
      title: {
        initial: 'noError',
        states: {
          noError: {},
          error: {
            initial: 'empty',
            states: {
              empty: {},
            },
          },
        },
      },
      label: {
        initial: 'noError',
        states: {
          noError: {},
          error: {
            initial: 'empty',
            states: {
              empty: {},
            },
          },
        },
      },
      otherLabel: {
        initial: 'init',
        states: {
          init: {
            always: [
              {
                cond: 'isOtherLabelSelected',
                target: 'noError',
              },
              { target: 'hidden' },
            ],
          },
          hidden: {
            on: {
              UPDATE_LABEL: {
                cond: 'isOtherLabel',
                actions: ['clearOtherLabel'],
                target: 'noError',
              },
            },
          },
          noError: {},
          error: {
            initial: 'empty',
            states: {
              empty: {},
            },
          },
        },
        on: {
          UPDATE_LABEL: {
            cond: 'isNotOtherLabel',
            actions: ['clearOtherLabel'],
            target: '.hidden',
          },
        },
      },
      distributor: {
        initial: 'noError',
        states: {
          noError: {},
          error: {
            initial: 'empty',
            states: {
              empty: {},
            },
          },
        },
      },
      otherDistributor: {
        initial: 'init',
        states: {
          init: {
            always: [
              {
                cond: 'isOtherDistributorSelected',
                target: 'noError',
              },
              { target: 'hidden' },
            ],
          },
          hidden: {
            on: {
              UPDATE_DISTRIBUTOR: {
                cond: 'isOtherDistributor',
                actions: ['clearOtherDistributor'],
                target: 'noError',
              },
            },
          },
          noError: {},
          error: {
            initial: 'empty',
            states: {
              empty: {},
            },
          },
        },
        on: {
          UPDATE_DISTRIBUTOR: {
            cond: 'isNotOtherDistributor',
            actions: ['clearOtherDistributor'],
            target: '.hidden',
          },
        },
      },
      date: {
        initial: 'noError',
        states: {
          noError: {},
          validating: {
            always: [
              { cond: 'isEmpty', target: 'error.empty' },
              { cond: 'isInvalidDate', target: 'error.value' },
              { target: 'noError' },
            ],
          },
          error: {
            initial: 'empty',
            states: {
              empty: {},
              value: {},
            },
          },
        },
      },
      numberOfMinutes: {
        initial: 'noError',
        states: {
          noError: {},
          error: {
            initial: 'empty',
            states: {
              empty: {},
              incorrectType: {},
              value: {},
            },
          },
        },
      },
      numberOfTracks: {
        initial: 'noError',
        states: {
          noError: {},
          error: {
            initial: 'empty',
            states: {
              empty: {},
              incorrectType: {},
              value: {},
            },
          },
        },
      },
      language: {
        initial: 'noError',
        states: {
          noError: {},
          error: {
            initial: 'empty',
            states: {
              empty: {},
              value: {},
            },
          },
        },
      },
      wholeForm: {
        initial: 'noError',
        states: {
          noError: {},
          error: {
            initial: 'invalid',
            states: {
              invalid: {
                initial: 'default',
                states: {
                  default: {},
                  highlight: {
                    after: {
                      1000: { target: 'default' },
                    },
                  },
                },
              },
              network: {},
              internal: { entry: 'logMachineError' },
            },
          },
        },
      },
    },
  },
  {
    guards: {
      isFormValid: (context, __, { state }) => {
        if (
          context.distributor?.name === 'Other' &&
          !context.otherDistributor &&
          context.label?.name === 'Other' &&
          !context.otherLabel
        ) {
          return false;
        }

        return [
          'title',
          'otherLabel',
          'distributor',
          'otherDistributor',
          'date',
          'numberOfMinutes',
          'numberOfTracks',
          'language',
        ].every(
          (field) =>
            state.matches(`${field}.hidden`) ||
            state.matches(`${field}.noError`) ||
            // We still consider the form valid with certain errors.
            state.matches(`${field}.error.value`)
        );
      },
      isEmpty: (context, { type }) =>
        match(type)
          .with('BLUR_TITLE', () => Boolean(context.title === ''))
          .with('BLUR_LABEL', () => !context.label)
          .with('BLUR_OTHER_LABEL', () => !context.otherLabel)
          .with('BLUR_DISTRIBUTOR', () => !context.distributor)
          .with('BLUR_OTHER_DISTRIBUTOR', () => !context.otherDistributor)
          .with('BLUR_NUMBER_OF_MINUTES', () =>
            Boolean(context.numberOfMinutes === '')
          )
          .with('BLUR_NUMBER_OF_TRACKS', () =>
            Boolean(context.numberOfTracks === '')
          )
          .with('', () => Boolean(context.date === ''))
          .otherwise(() => false),
      isNotNumeric: (context, { type }) =>
        isNaN(
          parsePositiveInteger(
            match(type)
              .with(
                'BLUR_NUMBER_OF_MINUTES',
                () => context.numberOfMinutes ?? ''
              )
              .with('BLUR_NUMBER_OF_TRACKS', () => context.numberOfTracks ?? '')
              .exhaustive()
              .trim()
          )
        ),
      isNumberOfMinutesInvalid: ({ numberOfMinutes }) =>
        parsePositiveInteger(numberOfMinutes ?? '') < 9,
      isNumberOfTracksInvalid: ({ numberOfTracks }) =>
        parsePositiveInteger(numberOfTracks ?? '') < 3,
      isInvalidDate: ({ eligibilityCutoffDate, date }) => {
        if (!date) {
          return false;
        }

        return new Date(date) < new Date(eligibilityCutoffDate);
      },
      isMajorityEnglish: (_, { isMajorityEnglish }) =>
        isMajorityEnglish === true,
      isMajorityFrench: (_, { isMajorityEnglish }) =>
        isMajorityEnglish === false,
      isNetworkError: (_, event) => {
        if (event.data instanceof Error) {
          return /network/i.test(event.data.message);
        }
        return false;
      },
      isOtherLabel: (_, event) => {
        return event.label.name === 'Other';
      },
      isNotOtherLabel: (_, event) => {
        return event.label.name !== 'Other';
      },
      isOtherLabelSelected: (context) => {
        return context.label?.name === 'Other';
      },
      isNonOtherLabelSelected: (context) => {
        return context.label?.name !== 'Other';
      },
      isOtherDistributor: (_, event) => {
        return event.distributor.name === 'Other';
      },
      isNotOtherDistributor: (_, event) => {
        return event.distributor.name !== 'Other';
      },
      isOtherDistributorSelected: (context) => {
        return context.distributor?.name === 'Other';
      },
      isNonOtherDistributorSelected: (context) => {
        return context.distributor?.name !== 'Other';
      },
    },
    actions: {
      logMachineError,
      updateTitle: assign((_, { title }) => ({
        title,
      })),
      updateLabel: assign((_, { label }) => ({
        label,
      })),
      updateDistributor: assign((_, { distributor }) => ({
        distributor,
      })),
      updateDate: assign((_, { date }) => ({
        date,
      })),
      updateNumberOfMinutes: assign((_, { numberOfMinutes }) => ({
        numberOfMinutes,
      })),
      updateNumberOfTracks: assign((_, { numberOfTracks }) => ({
        numberOfTracks,
      })),
      updateLanguage: assign((_, { isMajorityEnglish }) => ({
        isMajorityEnglish,
      })),
      updateParentCachedRelease: sendParent((_, { data }) =>
        updateCachedRelease(data)
      ),
      updateOtherLabel: assign((_, { otherLabel }) => ({
        otherLabel,
      })),
      clearOtherLabel: assign((_) => ({
        otherLabel: null,
      })),
      updateOtherDistributor: assign((_, { otherDistributor }) => ({
        otherDistributor,
      })),
      clearOtherDistributor: assign((_) => ({
        otherDistributor: null,
      })),
      cancelEdit: sendParent(() => cancelEditReleaseDetails()),
      blurTitle: send('BLUR_TITLE'),
      blurLabel: send('BLUR_LABEL'),
      blurOtherLabel: send('BLUR_OTHER_LABEL'),
      blurDistributor: send('BLUR_DISTRIBUTOR'),
      blurOtherDistributor: send('BLUR_OTHER_DISTRIBUTOR'),
      blurDate: send(({ date }) => ({ type: 'UPDATE_DATE', date })),
      blurNumberOfMinutes: send('BLUR_NUMBER_OF_MINUTES'),
      blurNumberOfTracks: send('BLUR_NUMBER_OF_TRACKS'),
      blurLanguage: send('BLUR_LANGUAGE'),
    },
  }
);

type Machine = typeof machine;

export type ReleaseDetailsMachineState = StateFrom<Machine>;
export type ReleaseDetailsMachineSender = Sender<EventFrom<Machine>>;
export type ReleaseDetailsMachineOptions = MachineOptionsFrom<Machine, true> & {
  context: Context;
};
export type ReleaseDetailsMachineEvent = EventFrom<Machine>;
export type ReleaseDetailsMachineActor = ActorRefFrom<Machine>;
