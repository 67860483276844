import { useMachine } from '@xstate/react';
import {
  RedeemAccountInvitationMachineOptions,
  machine,
} from '../../machines/RedeemAccountInvitation/Machine';
import { RedeemAccountInvitationView } from './RedeemAccountInvitationView';

type AccountCreationControllerOptions = {
  options: RedeemAccountInvitationMachineOptions;
};

export function RedeemAccountInvitationController({
  options,
}: AccountCreationControllerOptions) {
  const [state, send] = useMachine(machine, options);

  return <RedeemAccountInvitationView state={state} send={send} />;
}
