import {
  History,
  createBrowserHistory,
  createMemoryHistory,
} from '../../utils/history';

import {
  RedeemAccountInvitationState,
  parseRedeemAccountInvitationState,
} from './state';

export type RedeemAccountInvitationHistory =
  History<RedeemAccountInvitationState>;

export const createBrowserRedeemAccountInvitationHistory =
  (): RedeemAccountInvitationHistory =>
    createBrowserHistory(parseRedeemAccountInvitationState);

export const createMemoryRedeemAccountInvitationHistory =
  (): RedeemAccountInvitationHistory =>
    createMemoryHistory<RedeemAccountInvitationState>();
