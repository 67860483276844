import type {
  Sender,
  EventFrom,
  MachineOptionsFrom,
  ActorRefFrom,
} from 'xstate';
import { createMachine, assign, sendParent } from 'xstate';
import { DeleteFilePromiseResult } from '../../promises/Attachments/createDeleteFilePromise';
import { GetFilePromiseResult } from '../../promises/Attachments/createGetFilePromise';
import { UploadFilePromiseResult } from '../../promises/Attachments/createUploadFilePromise';
import { Context as ParentContext } from '../Application/ApplicationMachine';
import { StateFrom } from '../../utils/StateFrom';
import { Attachment } from '../../schemas/attachment';
import { logMachineError } from '../../utils/logError';
import { refreshApplication } from '../../events/RefreshApplication';

type Events =
  | { type: 'UPLOAD_CONTRACT'; files: FileList }
  | { type: 'GET_CONTRACT'; file: Attachment }
  | { type: 'DELETE_CONTRACT'; file: Attachment };

export type Context = {
  applicationId: string;
  artistName: ParentContext['artist']['name'];
  signedContract: ParentContext['signedContract'];
};

type Services = {
  getFilePromise: {
    data: GetFilePromiseResult;
  };
  uploadFilePromise: {
    data: UploadFilePromiseResult;
  };
  deleteFilePromise: {
    data: DeleteFilePromiseResult;
  };
};

export const createContext = (context: Context): Context => context;

export const machine = createMachine(
  {
    id: 'signedContractMachine',
    predictableActionArguments: true,
    tsTypes: {} as import('./SignedContractMachine.typegen').Typegen0,
    schema: {
      context: {} as Context,
      events: {} as Events,
      services: {} as Services,
    },
    initial: 'default',
    on: {
      UPLOAD_CONTRACT: 'uploading',
      DELETE_CONTRACT: 'deleting',
      GET_CONTRACT: 'fetchingUrl',
    },
    states: {
      default: {},
      uploading: {
        invoke: {
          id: 'uploadFilePromise',
          src: 'uploadFilePromise',
          onDone: {
            actions: ['updateSignedContract', 'refreshApplication'],
            target: 'default',
          },
          onError: '#signedContractMachine.error',
        },
      },
      deleting: {
        invoke: {
          id: 'deleteFilePromise',
          src: 'deleteFilePromise',
          onDone: {
            actions: ['deleteSignedContract', 'refreshApplication'],
            target: 'default',
          },
          onError: '#signedContractMachine.error',
        },
      },
      fetchingUrl: {
        invoke: {
          id: 'getFilePromise',
          src: 'getFilePromise',
          onDone: { actions: 'openAttachment', target: 'default' },
          onError: '#signedContractMachine.error',
        },
      },
      error: { entry: 'logMachineError' },
    },
  },
  {
    actions: {
      logMachineError,
      refreshApplication: sendParent(refreshApplication()),
      updateSignedContract: assign({
        signedContract: (_, { data }) => data[0],
      }),
      deleteSignedContract: assign({
        signedContract: (_) => null,
      }),
    },
  }
);

type Machine = typeof machine;

export type SignedContractMachineState = StateFrom<Machine>;
export type SignedContractMachineSender = Sender<EventFrom<Machine>>;
export type SignedContractMachineOptions = MachineOptionsFrom<Machine, true> & {
  context: Context;
};
export type SignedContractMachineEvent = EventFrom<Machine>;
export type SignedContractMachineActor = ActorRefFrom<Machine>;
