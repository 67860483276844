import { Attachment } from '../../schemas/attachment';
import { getSignedUrl } from './utils';

export const createDownloadBoardAttachmentPromise =
  () =>
  async (
    _: unknown,
    { file, blockId }: { file: Attachment; blockId: string }
  ) => {
    return getSignedUrl({
      referenceId: blockId,
      directory: 'board-meeting-attachments',
      fileName: file.name,
    });
  };

export type DownloadBoardAttachmentPromise = ReturnType<
  typeof createDownloadBoardAttachmentPromise
>;
export type DownloadBoardAttachmentPromiseResult = Awaited<
  ReturnType<DownloadBoardAttachmentPromise>
>;
