import { GraphQLClient } from 'graphql-request';
import {
  UnsubscribeFromNewsletterDocument,
  UnsubscribeFromNewsletterMutationVariables,
} from '../graphql/operations';

export const createUnsubscribeFromNewsletterPromise =
  (client: GraphQLClient) =>
  (variables: UnsubscribeFromNewsletterMutationVariables) =>
    client.request(UnsubscribeFromNewsletterDocument, variables);

export type UnsubscribeFromNewsletterPromise = ReturnType<
  typeof createUnsubscribeFromNewsletterPromise
>;
export type UnsubscribeFromNewsletterPromiseResult = Awaited<
  ReturnType<UnsubscribeFromNewsletterPromise>
>;
