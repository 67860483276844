import z from 'zod';
import { parseAbsolute } from '@internationalized/date';
import { ArtistStatus } from '../../graphql/operations';
import { artistEligibilityNoticeSchema } from '../artistEligibilityNoticeSchema';
import { serverDateTimeSchema } from '../dateTimeSchema';

export const basicDashboardArtistSchema = z.object({
  id: z.string(),
  name: z.string(),
  legalStatus: z
    .object({
      id: z.string(),
    })
    .transform((status) => status.id),
  companyName: z.string(),
  companyLegalProvince: z.string().nullable(),
  createdAt: serverDateTimeSchema.unwrap(),
  provinceId: z
    .object({
      id: z.string(),
    })
    .transform((binding) => binding.id),
  totalFunded: z.string().transform((value) => parseFloat(value)),
  management: z
    .object({
      name: z.string(),
      countryId: z
        .object({
          id: z.string(),
        })
        .transform((country) => country.id),
      contactPerson: z.string(),
      address: z.string(),
      phoneNumber: z.string(),
      email: z.string(),
      legalStatus: z
        .object({
          id: z.string(),
        })
        .transform((legalStatus) => legalStatus.id),
      legalProvince: z.string().nullable(),
    })
    .nullable(),
  isCoreEligible: z.boolean(),
  hasEligibleRelease: z.boolean(),
  aboveSalesRequirement: z.boolean(),
  aboveLifetimeFundingLimit: z.boolean(),
  canStartApplication: z.boolean(),
  eligibilityNotice: artistEligibilityNoticeSchema,
  status: z.nativeEnum(ArtistStatus),
  orionApprovalCount: z.number(),
  orionApprovalLimit: z.number(),
  hasReachedOrionApprovalLimit: z.boolean(),
  showLegacyClaimsMessage: z.boolean(),
  isOwner: z.boolean(),
});

type BasicDashboardArtist = z.infer<typeof basicDashboardArtistSchema>;

export const transformBasicDashboardArtist = (
  timezone: string,
  { createdAt, ...rest }: BasicDashboardArtist
) => ({
  createdAt: parseAbsolute(createdAt, timezone),
  ...rest,
});

export type DashboardArtist = ReturnType<typeof transformBasicDashboardArtist>;
