import { useMachine } from '@xstate/react';
import {
  RedeemBoardInvitationMachineOptions,
  machine,
} from '../../machines/RedeemBoardInvitation/Machine';
import { RedeemBoardInvitationView } from './RedeemBoardInvitationView';

type BoardCreationControllerOptions = {
  options: RedeemBoardInvitationMachineOptions;
};

export function RedeemBoardInvitationController({
  options,
}: BoardCreationControllerOptions) {
  const [state, send] = useMachine(machine, options);

  return <RedeemBoardInvitationView state={state} send={send} />;
}
