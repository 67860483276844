import { GraphQLClient } from 'graphql-request';
import {
  CancelTourDateDocument,
  CancelTourDateResponseCode,
} from '../../graphql/operations';
import { z } from 'zod';
import { CancelTourDate } from '../../events/CancelTourDate';
import { upsertTourDateResultSchema } from '../../schemas/tourDates/upsertTourDateResultSchema';

export const createCancelTourDatePromise =
  (client: GraphQLClient) =>
  async (_: unknown, { id }: CancelTourDate) => {
    const response = await client.request(CancelTourDateDocument, {
      id,
    });

    return z
      .object({
        code: z.nativeEnum(CancelTourDateResponseCode),
        tourDate: upsertTourDateResultSchema,
      })
      .parse(response.cancelTourDate?.cancelTourDateResponse);
  };

export type CreateCancelTourDatePromise = ReturnType<
  typeof createCancelTourDatePromise
>;

export type CreateCancelTourDatePromiseResult = Awaited<
  ReturnType<CreateCancelTourDatePromise>
>;

export const isInvalidCode = ({ code }: CreateCancelTourDatePromiseResult) =>
  code === CancelTourDateResponseCode.Invalid;
