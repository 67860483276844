import { ArtistCard } from '../../../components/Admin/Artists/ArtistCard';
import { Block } from '../../../components/Block';
import { CheckOption } from '../../../components/CheckOption';
import { Loading } from '../../../components/Loading';
import { NoSearchResults } from '../../../components/NoSearchResults';
import { PageContainer } from '../../../components/PageContainer';
import { PageContainerWithSidebar } from '../../../components/PageContainerWithSidebar';
import { PagePagination } from '../../../components/PagePagination';
import { SortBy } from '../../../components/SortBy';
import { TextInput } from '../../../components/TextInput';
import {
  ArtistStatus,
  SearchAdminArtistsSortOrder,
} from '../../../graphql/operations';
import {
  SearchArtistsMachineSender,
  SearchArtistsMachineState,
} from '../../../machines/Admin/Artists/SearchArtistsMachine';
import { ErrorPage } from '../../ErrorPage';

import styles from './SearchArtistsView.module.scss';

interface ArtistsViewProps {
  state: SearchArtistsMachineState;
  send: SearchArtistsMachineSender;
}

const sortByOptions: Array<{
  label: string;
  value: SearchAdminArtistsSortOrder;
}> = [
  {
    label: 'Alphabetical (A-Z)',
    value: SearchAdminArtistsSortOrder.ArtistNameAsc,
  },
  {
    label: 'Alphabetical (Z-A)',
    value: SearchAdminArtistsSortOrder.ArtistNameDesc,
  },
  {
    label: 'Latest Modified Release',
    value: SearchAdminArtistsSortOrder.LatestModified,
  },
  {
    label: 'Recently Added',
    value: SearchAdminArtistsSortOrder.RecentlyAdded,
  },
];

export function SearchArtistsView({ state, send }: ArtistsViewProps) {
  if (state.matches('init')) {
    return <Loading />;
  }

  if (state.matches('error')) {
    return <ErrorPage />;
  }

  const numberOfPages = Math.ceil(
    state.context.totalNumberOfArtists / state.context.limit
  );

  return (
    <PageContainer innerClassName={styles.container}>
      <div className={styles.controls}>
        <div></div>
        <TextInput
          id="searchTerm"
          placeholder="Search by artist or release"
          icon="search"
          onChange={(searchTerm) =>
            send({ type: 'SET_SEARCH_TERM', searchTerm })
          }
          value={state.context.searchTerm}
        />
        <h2>Artists</h2>
        <SortBy
          options={sortByOptions}
          value={state.context.sortMode}
          onSelect={(sortMode) => send({ type: 'SET_SORT_MODE', sortMode })}
        />
      </div>
      <PageContainerWithSidebar
        useMaxWidthXl={false}
        innerClassName={styles.content}
      >
        <div className={styles.left}>
          <Block>
            <p className={styles.sidebarHeading}>Artist Status:</p>

            <div className={styles.fields}>
              <CheckOption
                label="Eligible"
                checked={state.context.isEligible}
                onChange={() => send('SET_IS_ELIGIBLE')}
              />
              <CheckOption
                label="Ineligible"
                checked={state.context.isIneligible}
                onChange={() => send('SET_IS_INELIGIBLE')}
              />
              <CheckOption
                label="Activity Slowdown"
                checked={state.context.isActivitySlowing}
                onChange={() => send('SET_ACTIVITY_SLOWING')}
              />
              <CheckOption
                label="Pending Orion Request"
                checked={state.context.isOrionPending}
                onChange={() => send('SET_PENDING_ORION')}
              />
            </div>
            <p className={styles.sidebarHeading}>Lifetime Funding Total:</p>
            <div className={styles.fields}>
              <CheckOption
                label="Less than $400,000"
                checked={state.context.under400kFunding}
                onChange={() => send('SET_UNDER_400000')}
              />
              <CheckOption
                label="More than $400,000"
                checked={state.context.over400kFunding}
                onChange={() => send('SET_OVER_400000')}
              />
              <CheckOption
                label="Close to $600,000"
                checked={state.context.nearing600kFunding}
                onChange={() => send('SET_NEARING_600000')}
              />
            </div>
          </Block>
        </div>
        <div className={styles.right}>
          <Block>
            {state.context.artists.length === 0 && (
              <NoSearchResults onClear={() => send('CLEAR_ALL_FILTERS')} />
            )}
            {state.context.artists.map((artist) => (
              <ArtistCard
                key={artist.id}
                name={artist.name}
                releases={artist.releases}
                eligibility={artist.eligibility}
                buttonLinkTo={`/artist/${artist.id}`}
                pendingOrion={artist.status === ArtistStatus.PendingOrion}
              />
            ))}
            {numberOfPages > 1 && (
              <PagePagination
                active={state.context.currentPage}
                numberOfPages={numberOfPages}
                onPageClick={(page) => send({ type: 'SET_PAGE', page })}
                shrink={true}
              />
            )}
          </Block>
        </div>
      </PageContainerWithSidebar>
    </PageContainer>
  );
}
