import { z } from 'zod';
import { GraphQLClient } from 'graphql-request';
import {
  AddArtistDocument,
  AddArtistMutationVariables,
  AddArtistResponseCode,
} from '../graphql/operations';

import { CompleteAddArtistState } from '../machines/AddArtist/state';

const loadAddArtistMutationVariablesFromAddArtistStorage = (
  state: CompleteAddArtistState
): AddArtistMutationVariables => ({
  artistName: state.artist.name,
  artistProvince: state.artist.province,
  companyName: state.company.name,
  companyLegalStatus: state.company.legalStatus,
  companyLegalProvince: state.company.legalProvince ?? '',
  managementName: state.management?.name ?? '',
  managementCountryId: state.management?.country ?? '',
  managementContactPerson: state.management?.contactPerson ?? '',
  managementAddress: state.management?.address ?? '',
  managementPhoneNumber: state.management?.phoneNumber ?? '',
  managementEmail: state.management?.email ?? '',
  managementLegalStatus: state.management?.legalStatus ?? '',
  managementLegalProvince: state.management?.legalProvince ?? '',
});

export const addArtistPromise =
  (client: GraphQLClient) => async (state: CompleteAddArtistState) => {
    const variables = loadAddArtistMutationVariablesFromAddArtistStorage(state);
    const response = await client.request(AddArtistDocument, variables);

    return z
      .object({
        code: z.nativeEnum(AddArtistResponseCode),
        artistId: z.string().uuid(),
      })
      .parse(response.result?.response);
  };

export type AddArtistPromise = ReturnType<typeof addArtistPromise>;

export type AddArtistPromiseResult = Awaited<ReturnType<AddArtistPromise>>;
