import { Link } from 'react-router-dom';
import parse from 'url-parse';
import { isUrlExternal } from 'is-url-external';

interface ButtonLinkWrapperProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  href?: string;
}

export function ButtonLinkWrapper(props: ButtonLinkWrapperProps) {
  const { href, disabled, children, className } = props;

  if (!href || disabled) {
    return <button {...props} />;
  }

  return isUrlExternal(href, window.location.hostname) || parse(href).hash ? (
    <a className={className} href={href}>
      {children}
    </a>
  ) : (
    <Link to={href} className={className}>
      {children}
    </Link>
  );
}
