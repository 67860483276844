import { Block } from '../../components/Block';
import { Button } from '../../components/Button';
import {
  AccountMachineSender,
  AccountMachineState,
} from '../../machines/AccountDetailsMachine';
import { InlineFormContainer } from '../../components/InlineFormContainer';
import { InlineFormHeading } from '../../components/InlineFormHeading';
import { TextInput } from '../../components/TextInput';
import { FormField } from '../../components/FormField';
import { TextArea } from '../../components/TextArea';
import { match } from 'ts-pattern';
import { Feedback } from '../../components/Feedback';
import { HorizontalRule } from '../../components/HorizontalRule';
import { PageContainer } from '../../components/PageContainer';
import { GrayBox } from '../../components/GrayBox';
import { ArtistCard } from '../../components/Admin/Artists/ArtistCard';
import { ArtistStatus } from '../../graphql/operations';
import { Loading } from '../../components/Loading';
import { NotFound } from '../NotFound';
import { ErrorPage } from '../ErrorPage';

import styles from './AccountView.module.scss';

interface AccountViewProps {
  state: AccountMachineState;
  send: AccountMachineSender;
}

export function AccountView({ state, send }: AccountViewProps) {
  if (state.matches('init')) {
    return <Loading />;
  }

  if (state.matches('notFound')) {
    return <NotFound />;
  }

  if (state.matches('initError')) {
    return <ErrorPage />;
  }

  return (
    <PageContainer>
      <div className={styles.header}>
        <h1>{state.context.initialDetails.name}</h1>
        {state.context.isYou && (
          <Button
            variant="neutral"
            size="small"
            startIcon="logout"
            label="Sign Out"
            onClick={() => send({ type: 'SIGN_OUT' })}
          />
        )}
      </div>
      <div className={styles.blockContainer}>
        {state.matches('ready.updated-details') && (
          <Feedback size="compact" type="positive" icon="alertCircle">
            Your account details have been updated.
          </Feedback>
        )}
        {state.matches('ready.update-error') && (
          <Feedback size="compact" type="warning" icon="alertCircle">
            We were unable to update your details. Please try again later.
          </Feedback>
        )}
        <Block type="secondary">
          <div>
            <div className={styles.blockRow}>
              <h3>Account Details</h3>
              <div className={styles.buttonContainer}>
                <Button
                  variant="neutral"
                  size="small"
                  label="Change Password"
                  disabled={state.matches('password')}
                  onClick={() => send('TOGGLE_EDIT_PASSWORD')}
                />
                <Button
                  variant="secondary"
                  size="small"
                  startIcon="editPencil"
                  label="Edit"
                  disabled={state.matches('details')}
                  onClick={() => send('TOGGLE_EDIT_DETAILS')}
                />
              </div>
            </div>
            <HorizontalRule />
          </div>

          {!state.matches('details') && (
            <div className={styles.content}>
              <div>
                <dt>Name: </dt>
                <dd>{state.context.initialDetails.name}</dd>
              </div>
              <div>
                <dt>Email: </dt>
                <dd>{state.context.initialDetails.email}</dd>
              </div>
              <div>
                <dt>Phone Number: </dt>
                <dd>{state.context.initialDetails.phoneNumber}</dd>
              </div>
              <div>
                <dt>Address: </dt>
                <dd>{state.context.initialDetails.address}</dd>
              </div>
              <div>
                <dt>Created on: </dt>
                <dd>{state.context.initialDetails.createdAt}</dd>
              </div>
            </div>
          )}
          {state.matches('details') && (
            <InlineFormContainer
              primaryButtonText="Save"
              primaryButtonOnClick={() => send('SAVE_DETAILS')}
              secondaryButtonText="Cancel"
              secondaryButtonOnClick={() => send('TOGGLE_EDIT_DETAILS')}
            >
              <InlineFormHeading>Edit Account</InlineFormHeading>
              <FormField
                htmlFor="name"
                label="Name"
                formFieldStatus={
                  state.matches('details.in-progress.name.invalid')
                    ? 'error'
                    : 'default'
                }
                feedbackType="warning"
                infoLabel={
                  state.matches('details.in-progress.name.invalid')
                    ? 'This field is required.'
                    : ''
                }
              >
                <TextInput
                  id="name"
                  value={state.context.editedDetails.name}
                  onChange={(name) => send({ type: 'SET_NAME', name })}
                  onBlur={() => send('BLUR_NAME')}
                  onFocus={() => send('FOCUS_NAME')}
                />
              </FormField>
              <div className={styles.formRow}>
                <FormField htmlFor="email" label="Email">
                  <TextInput
                    id="email"
                    disabled={true}
                    value={state.context.editedDetails.email}
                    onChange={() => null}
                  />
                </FormField>
                <FormField
                  htmlFor="phone"
                  label="Phone number"
                  formFieldStatus={
                    state.matches('details.in-progress.phone.invalid')
                      ? 'error'
                      : 'default'
                  }
                  feedbackType="warning"
                  infoLabel={match(state)
                    .when(
                      (state) =>
                        state.matches(
                          'details.in-progress.phone.invalid.empty'
                        ),
                      () => 'This field is required.'
                    )
                    .when(
                      (state) =>
                        state.matches(
                          'details.in-progress.phone.invalid.value'
                        ),
                      () => "This doesn't appear to be a valid phone number."
                    )
                    .otherwise(() => '')}
                >
                  <TextInput
                    id="phone"
                    value={state.context.editedDetails.phoneNumber}
                    onChange={(phoneNumber) =>
                      send({ type: 'SET_PHONE_NUMBER', phoneNumber })
                    }
                    onBlur={() => send('BLUR_PHONE_NUMBER')}
                    onFocus={() => send('FOCUS_PHONE_NUMBER')}
                  />
                </FormField>
              </div>
              <FormField
                htmlFor="address"
                label="Address"
                formFieldStatus={
                  state.matches('details.in-progress.address.invalid')
                    ? 'error'
                    : 'default'
                }
                feedbackType="warning"
                infoLabel={
                  state.matches('details.in-progress.address.invalid')
                    ? 'This field is required.'
                    : ''
                }
              >
                <TextArea
                  id="address"
                  label="address"
                  size="small"
                  value={state.context.editedDetails.address}
                  onChange={(address) => send({ type: 'SET_ADDRESS', address })}
                  onBlur={() => send('BLUR_ADDRESS')}
                  onFocus={() => send('FOCUS_ADDRESS')}
                />
              </FormField>
              {(state.matches('details.in-progress.warning.on') ||
                state.matches('details.in-progress.warning.pulse')) && (
                <Feedback
                  type="warning"
                  pulse={state.matches('details.in-progress.warning.pulse')}
                >
                  Please fix the above issues.
                </Feedback>
              )}
            </InlineFormContainer>
          )}
          {state.matches('password') && (
            <InlineFormContainer
              primaryButtonText="Save"
              primaryButtonOnClick={() => send('SAVE_PASSWORD')}
              secondaryButtonText="Cancel"
              secondaryButtonOnClick={() => send('TOGGLE_EDIT_PASSWORD')}
            >
              <InlineFormHeading>Change Password</InlineFormHeading>
              <FormField
                htmlFor="currentPassword"
                label="Current Password"
                formFieldStatus={
                  state.matches('password.in-progress.current.invalid')
                    ? 'error'
                    : 'default'
                }
                feedbackType="warning"
                infoLabel={match(state)
                  .when(
                    (state) =>
                      state.matches(
                        'password.in-progress.current.invalid.empty'
                      ),
                    () => 'This field is required.'
                  )
                  .when(
                    (state) =>
                      state.matches(
                        'password.in-progress.current.invalid.incorrect'
                      ),
                    () => 'This password is incorrect.'
                  )
                  .otherwise(() => '')}
              >
                <TextInput
                  id="currentPassword"
                  type={state.context.showCurrentPassword ? 'text' : 'password'}
                  value={state.context.currentPassword}
                  icon={state.context.showCurrentPassword ? 'eyeOn' : 'eyeOff'}
                  onIconClick={() => send('TOGGLE_CURRENT_PASSWORD_VISIBILITY')}
                  onChange={(currentPassword) =>
                    send({ type: 'SET_CURRENT_PASSWORD', currentPassword })
                  }
                  onBlur={() => send('BLUR_CURRENT_PASSWORD')}
                  onFocus={() => send('FOCUS_CURRENT_PASSWORD')}
                />
              </FormField>
              <FormField
                htmlFor="newPassword"
                label="New Password"
                description="Minimum of 12 characters"
                formFieldStatus={
                  state.matches('password.in-progress.new.invalid')
                    ? 'error'
                    : 'default'
                }
                feedbackType="warning"
                infoLabel={match(state)
                  .when(
                    (state) =>
                      state.matches('password.in-progress.new.invalid.empty'),
                    () => 'This field is required.'
                  )
                  .when(
                    (state) =>
                      state.matches('password.in-progress.new.invalid.length'),
                    () =>
                      `Passwords must be at least ${state.context.minimumPasswordLength} characters long.`
                  )
                  .otherwise(() => '')}
              >
                <TextInput
                  id="newPassword"
                  type={state.context.showNewPassword ? 'text' : 'password'}
                  value={state.context.newPassword}
                  icon={state.context.showNewPassword ? 'eyeOn' : 'eyeOff'}
                  onIconClick={() => send('TOGGLE_NEW_PASSWORD_VISIBILITY')}
                  onChange={(newPassword) =>
                    send({ type: 'SET_NEW_PASSWORD', newPassword })
                  }
                  onBlur={() => send('BLUR_NEW_PASSWORD')}
                  onFocus={() => send('FOCUS_NEW_PASSWORD')}
                />
              </FormField>
              <FormField
                htmlFor="confirmPassword"
                label="Confirm Password"
                formFieldStatus={
                  state.matches('password.in-progress.confirm.invalid')
                    ? 'error'
                    : 'default'
                }
                feedbackType="warning"
                infoLabel={match(state)
                  .when(
                    (state) =>
                      state.matches(
                        'password.in-progress.confirm.invalid.empty'
                      ),
                    () => 'This field is required.'
                  )
                  .when(
                    (state) =>
                      state.matches(
                        'password.in-progress.confirm.invalid.different'
                      ),
                    () => "Your passwords don't match."
                  )
                  .otherwise(() => '')}
              >
                <TextInput
                  id="confirmPassword"
                  type={state.context.showConfirmPassword ? 'text' : 'password'}
                  value={state.context.confirmPassword}
                  icon={state.context.showConfirmPassword ? 'eyeOn' : 'eyeOff'}
                  onIconClick={() => send('TOGGLE_CONFIRM_PASSWORD_VISIBILITY')}
                  onChange={(confirmPassword) =>
                    send({ type: 'SET_CONFIRM_PASSWORD', confirmPassword })
                  }
                  onBlur={() => send('BLUR_CONFIRM_PASSWORD')}
                  onFocus={() => send('FOCUS_CONFIRM_PASSWORD')}
                />
              </FormField>
              {(state.matches('password.in-progress.warning.on') ||
                state.matches('password.in-progress.warning.pulse')) && (
                <Feedback
                  type="warning"
                  pulse={state.matches('password.in-progress.warning.pulse')}
                >
                  Please fix the above issues.
                </Feedback>
              )}
            </InlineFormContainer>
          )}
        </Block>
      </div>
      {state.matches('ready.updated-password') && (
        <Feedback size="compact" type="positive" icon="alertCircle">
          Your password has been updated.
        </Feedback>
      )}
      {state.matches('ready.update-error') && (
        <Feedback size="compact" type="warning" icon="alertCircle">
          We were unable to update your account. Please try again later.
        </Feedback>
      )}
      {state.matches('ready.artist-added') && (
        <Feedback size="compact" type="positive" icon="alertCircle">
          1 artist was added to this account.
        </Feedback>
      )}
      {state.matches('ready.artist-removed') && (
        <Feedback size="compact" type="positive" icon="alertCircle">
          Artist removed successsfully.
        </Feedback>
      )}
      {state.matches('ready.artist-error') && (
        <Feedback size="compact" type="warning" icon="alertCircle">
          We were unable to perform this request. Please try again later.
        </Feedback>
      )}
      {state.context.isAdmin && (
        <Block type="secondary">
          <div className={styles.headerRow}>
            <h3>Artists</h3>
            <Button
              disabled={state.matches('search')}
              label="Add Artist to Account"
              startIcon="plus"
              variant="secondary"
              size="small"
              onClick={() => send('TOGGLE_SHOW_ADD_ARTIST')}
            />
          </div>
          {state.matches('search') && (
            <InlineFormContainer
              secondaryButtonText="Close"
              secondaryButtonOnClick={() => send('TOGGLE_SHOW_ADD_ARTIST')}
            >
              <TextInput
                id="search"
                placeholder="Search for Artists to add"
                icon="search"
                value={state.context.searchTerm}
                onChange={(searchTerm) =>
                  send({ type: 'SET_SEARCH_TERM', searchTerm })
                }
              />
              <div className={styles.search}>
                {state.context.searchedArtists.map((artist) => {
                  const alreadyAdded = state.context.artists.find(
                    ({ artist: boundArtist }) => boundArtist.id === artist.id
                  );

                  return (
                    <ArtistCard
                      key={artist.id}
                      type="secondary"
                      name={artist.name}
                      releases={artist.releases}
                      eligibility={artist.eligibility}
                      buttonOnClick={() =>
                        send({
                          type: 'CREATE_ACCOUNT_ARTIST_BINDING',
                          artistId: artist.id,
                        })
                      }
                      buttonText={alreadyAdded ? 'Added' : 'Add Artist'}
                      buttonDisabled={alreadyAdded !== undefined}
                      pendingOrion={artist.status === ArtistStatus.PendingOrion}
                    />
                  );
                })}
              </div>
            </InlineFormContainer>
          )}
          <div className={styles.artistsGrid}>
            {state.context.artists.map(({ artist }) => (
              <GrayBox key={artist.id} className={styles.artistCard}>
                <h3 className={styles.title}>{artist.name}</h3>
                <div className={styles.buttonContainer}>
                  <Button
                    label="Remove"
                    size="small"
                    variant="neutral"
                    onClick={() =>
                      send({
                        type: 'DELETE_ACCOUNT_ARTIST_BINDING',
                        artistId: artist.id,
                      })
                    }
                  />
                  <Button
                    label="View"
                    size="small"
                    variant="secondary"
                    linkTo={`/artist/${artist.id}`}
                  />
                </div>
              </GrayBox>
            ))}
          </div>
        </Block>
      )}
    </PageContainer>
  );
}
