import type { EventFrom, MachineOptionsFrom, Sender } from 'xstate';
import { assign, createMachine } from 'xstate';
import { CreateApplicationPromiseResult } from '../promises/createApplicationPromise';
import type { StateFrom } from '../utils/StateFrom';
import { GetDashboardContextPromiseResult } from '../promises/getDashboardContextPromise';
import { DeleteAccountArtistBindingPromiseResult } from '../promises/createDeleteAccountArtistBindingPromise';
import { createContext as createAccountsBlockContext } from './components/AccountsBlockMachine';
import { createContext as createArtistDetailsBlockContext } from './components/ArtistDetailsBlockMachine';
import { createContext as createArtistManagementContext } from './components/ArtistManagementMachine';
import { createContext as createEligibilityNoticeContext } from './EligibilityNoticeMachine';
import { CreateUpdateArtistStatusPromiseResult } from '../promises/Admin/createUpdateArtistStatusPromise';
import { isIdNotFound } from '../errors/IdNotFoundError';
import { logMachineError } from '../utils/logError';
import { EligibilityNotice } from '../graphql/operations';

type Events =
  | { type: 'START_APPLICATION' }
  | { type: 'REMOVE_ACCOUNT'; accountId: string }
  | { type: 'SET_PENDING_INVITATIONS' }
  | {
      type: 'UPDATE_ARTIST';
      artist: GetDashboardContextPromiseResult['artist'];
    }
  | {
      type: 'UPDATE_MANAGEMENT';
      management: GetDashboardContextPromiseResult['artist']['management'];
    }
  | {
      type: 'UPDATE_ELIGIBILITY_NOTICE';
      notice: EligibilityNotice | null;
    }
  | {
      type: 'REFRESH_DASHBOARD';
    };

type Services = {
  createApplicationPromise: {
    data: CreateApplicationPromiseResult;
  };
  deleteAccountArtistBindingPromise: {
    data: DeleteAccountArtistBindingPromiseResult;
  };
  getDashboardContextPromise: {
    data: GetDashboardContextPromiseResult;
  };
  updateArtistStatusPromise: {
    data: CreateUpdateArtistStatusPromiseResult;
  };
};

export type Context = GetDashboardContextPromiseResult;

export const machine = createMachine(
  {
    predictableActionArguments: true,
    tsTypes: {} as import('./DashboardMachine.typegen').Typegen0,
    id: 'dashboard',
    schema: {
      context: {} as Context,
      events: {} as Events,
      services: {} as Services,
    },
    initial: 'init',
    states: {
      init: {
        invoke: {
          id: 'getDashboardContextPromise',
          src: 'getDashboardContextPromise',
          onDone: {
            actions: 'setContext',
            target: 'ready',
          },
          onError: [
            { cond: 'isIdNotFound', target: 'notFound' },
            { target: 'initError' },
          ],
        },
      },
      initError: { entry: 'logMachineError' },
      notFound: {},
      ready: {
        type: 'parallel',
        states: {
          application: {
            initial: 'ready',
            states: {
              ready: {
                on: {
                  START_APPLICATION: 'creating',
                },
              },
              creating: {
                invoke: {
                  id: 'createApplicationPromise',
                  src: 'createApplicationPromise',
                  onDone: { actions: 'navigateToApplication' },
                  onError: { target: 'error' },
                },
              },
              error: { entry: 'logMachineError' },
            },
          },
          account: {
            invoke: {
              id: 'accountsBlockMachine',
              src: 'accountsBlockMachine',
              data: (context) =>
                createAccountsBlockContext(
                  context.artist.id,
                  context.artist.name,
                  context.artist.isOwner,
                  false,
                  '',
                  context.accounts,
                  context.pendingAccountBindings
                ),
            },
          },
          details: {
            invoke: {
              id: 'artistDetailsBlockMachine',
              src: 'artistDetailsBlockMachine',
              data: (context) =>
                createArtistDetailsBlockContext(
                  context.artist,
                  context.provinces,
                  context.legalStatuses
                ),
            },
          },
          eligibilityNotice: {
            invoke: {
              id: 'eligibilityNoticeMachine',
              src: 'eligibilityNoticeMachine',
              data: (context) =>
                createEligibilityNoticeContext(
                  context.artist.id,
                  context.artist.eligibilityNotice
                ),
            },
          },
          management: {
            invoke: {
              id: 'artistManagementMachine',
              src: 'artistManagementMachine',
              data: (context) =>
                createArtistManagementContext(
                  context.artist,
                  context.countries,
                  context.provinces,
                  context.legalStatuses
                ),
            },
          },
        },
        on: {
          UPDATE_ARTIST: { actions: 'updateArtist', target: 'ready' },
          UPDATE_MANAGEMENT: { actions: 'updateManagement', target: 'ready' },
          UPDATE_ELIGIBILITY_NOTICE: {
            actions: 'updateEligibilityNotice',
            target: 'ready',
          },
          REFRESH_DASHBOARD: { target: 'init' },
        },
      },
    },
  },
  {
    guards: { isIdNotFound: (_, { data }) => isIdNotFound(data) },
    actions: {
      logMachineError,
      setContext: assign((_, { data }) => data),
      updateArtist: assign((_, { artist }) => ({
        artist,
      })),
      updateManagement: assign(({ artist }, { management }) => ({
        artist: { ...artist, management },
      })),
      updateEligibilityNotice: assign(({ artist }, { notice }) => ({
        artist: { ...artist, eligibilityNotice: notice },
      })),
    },
  }
);

type Machine = typeof machine;

export type DashboardMachineState = StateFrom<Machine>;
export type DashboardMachineSender = Sender<EventFrom<Machine>>;
export type DashboardMachineOptions = MachineOptionsFrom<Machine, true>;
