import { GraphQLClient } from 'graphql-request';
import { Context, Events } from '../../machines/Admin/ApplicationReviewMachine';
import { AddBoardBlockDocument } from '../../graphql/operations';
import { adminApplicationFragmentSchema } from '../../schemas/admin/adminApplicationFragmentSchema';

type Event = Extract<Events, { type: 'ADD_BOARD_BLOCK' }>;

export const createAddBoardBlockPromise =
  (client: GraphQLClient) =>
  async ({ applicationId }: Context, { accountId }: Event) => {
    const request = await client.request(AddBoardBlockDocument, {
      accountId,
      applicationId,
    });

    const application = request.result?.query?.application;

    return adminApplicationFragmentSchema.parseAsync(application);
  };

export type CreateAddBoardBlockPromise = ReturnType<
  typeof createAddBoardBlockPromise
>;
export type CreateAddBoardBlockPromiseResult = Awaited<
  ReturnType<CreateAddBoardBlockPromise>
>;
