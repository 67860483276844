import { ReactNode } from 'react';
import { IconName } from '../Icon';
import { Button } from '../Button';
import { Link } from '../Link';

import styles from './DashboardBlock.module.scss';

interface DashboardBlockProps {
  title: string;
  linkText?: string;
  linkHref?: string;
  buttonText?: string;
  buttonIcon?: IconName;
  buttonDisabled?: boolean;
  children: ReactNode;
  buttonOnClick?: () => void;
  buttonLinkTo?: string;
}

export function DashboardBlock({
  title,
  linkText,
  linkHref,
  buttonText,
  buttonIcon,
  buttonOnClick,
  buttonLinkTo,
  buttonDisabled = false,
  children,
}: DashboardBlockProps) {
  return (
    <div className={styles.block}>
      <div className={styles.header}>
        <div className={styles.row}>
          <h3>{title}</h3>
          {linkText && linkHref && (
            <Link endIcon="rightChevron" href={linkHref}>
              {linkText}
            </Link>
          )}
        </div>
        {buttonText && (
          <Button
            variant="secondary"
            size="small"
            label={buttonText}
            startIcon={buttonIcon}
            onClick={buttonOnClick}
            linkTo={buttonLinkTo}
            disabled={buttonDisabled}
          />
        )}
      </div>
      {children}
    </div>
  );
}
