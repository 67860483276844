import { z } from 'zod';
import {
  ApplicationReviewStatus,
  ApplicationStatus,
} from '../../graphql/operations';

export const adminApplicationFragmentSchema = z.object({
  status: z.nativeEnum(ApplicationStatus),
  reviewStatus: z.nativeEnum(ApplicationReviewStatus),
  boardNotes: z.object({ notes: z.string() }).nullable(),
  boardBlocks: z.array(
    z.object({ accountId: z.string(), applicationId: z.string() })
  ),
});
