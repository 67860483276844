import { CompleteExpense } from '../../schemas/fundingRequests/expenses/expenseSchema';

export type AddExpense = {
  type: 'ADD_EXPENSE';
  expense: CompleteExpense;
};

export const addExpense = (expense: AddExpense['expense']): AddExpense => ({
  type: 'ADD_EXPENSE',
  expense,
});
