import type { EventFrom, Sender } from 'xstate';
import { assign, createMachine } from 'xstate';
import type { StateFrom } from '../../utils/StateFrom';
import type { MachineOptionsWithContextFrom } from '../../utils/MachineOptionsWithContextFrom';
import { logMachineError } from '../../utils/logError';
import { CreateSearchSubscribersPromiseResult } from '../../promises/Admin/createSearchSubscribersPromise';
import { UnsubscribeFromNewsletterPromiseResult } from '../../promises/createUnsubscribeFromNewsletterPromise';

type Events =
  | { type: 'SET_SEARCH_TERM'; searchTerm: string }
  | { type: 'SET_PAGE'; page: number }
  | { type: 'SET_LIMIT'; limit: number }
  | { type: 'REMOVE'; tag: string };

type Services = {
  searchSubscribersPromise: {
    data: CreateSearchSubscribersPromiseResult;
  };
  unsubscribeFromNewsletterPromise: {
    data: UnsubscribeFromNewsletterPromiseResult;
  };
};

type Context = {
  subscribers: CreateSearchSubscribersPromiseResult;
  offset: number;
  limit: number;
  searchTerm: string;
  totalNumberOfAccounts: number;
  currentPage: number;
  tag: string;
  numberOfPages: number;
};

export const createContext = (
  subscribers: CreateSearchSubscribersPromiseResult,
  offset: number,
  limit: number
): Context => ({
  subscribers,
  offset,
  limit,
  searchTerm: '',
  totalNumberOfAccounts: 0,
  currentPage: 1,
  tag: '',
  numberOfPages: 0,
});

export const machine = createMachine(
  {
    predictableActionArguments: true,
    tsTypes: {} as import('./SubscribersMachine.typegen').Typegen0,
    schema: {
      context: {} as Context,
      events: {} as Events,
      services: {} as Services,
    },
    initial: 'init',
    id: 'form',
    states: {
      init: {
        invoke: {
          id: 'searchSubscribersPromise',
          src: 'searchSubscribersPromise',
          onDone: {
            actions: ['setContext', 'setNumberOfPages'],
            target: 'ready',
          },
          onError: 'error',
        },
      },
      error: { entry: 'logMachineError' },
      ready: {
        on: {
          SET_PAGE: {
            actions: 'setPage',
            target: 'init',
          },
        },
      },
      typing: {
        after: {
          500: {
            target: 'search',
          },
        },
      },
      search: {
        entry: 'resetSearch',
        invoke: {
          id: 'searchSubscribersPromise',
          src: 'searchSubscribersPromise',
          onDone: {
            actions: ['setContext', 'setNumberOfPages'],
            target: '#form.ready',
          },
          onError: '#form.error',
        },
      },
      remove: {
        invoke: {
          id: 'unsubscribeFromNewsletterPromise',
          src: 'unsubscribeFromNewsletterPromise',
          onDone: '#form.search',
          onError: '#form.error',
        },
      },
    },
    on: {
      SET_LIMIT: {
        actions: 'setLimit',
        target: 'search',
      },
      REMOVE: {
        actions: 'setTagToDelete',
        target: 'remove',
      },
      SET_SEARCH_TERM: {
        actions: 'setSearchTerm',
        target: 'typing',
      },
    },
  },
  {
    actions: {
      logMachineError,
      setContext: assign((_, { data }) => ({
        subscribers: data,
        totalNumberOfAccounts: data.length ? data[0].count : 0,
      })),
      setSearchTerm: assign((_, { searchTerm }) => ({
        searchTerm,
      })),
      setPage: assign((context, event) => ({
        currentPage: event.page,
        offset: (event.page - 1) * context.limit,
      })),
      resetSearch: assign((_) => ({
        currentPage: 1,
        offset: 0,
      })),
      setLimit: assign((_, event) => ({
        limit: event.limit,
      })),
      setTagToDelete: assign((_, { tag }) => ({
        tag,
      })),
      setNumberOfPages: assign(({ limit }, { data }) => ({
        numberOfPages: Math.ceil((data?.[0]?.count ?? 0) / limit),
      })),
    },
  }
);

type Machine = typeof machine;

export type SubscribersMachineState = StateFrom<Machine>;
export type SubscribersMachineSender = Sender<EventFrom<Machine>>;
export type SubscribersMachineOptions = MachineOptionsWithContextFrom<Machine>;
