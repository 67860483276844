import { useNavigate } from 'react-router-dom';
import { useGraphQLClient } from '../../../components/GraphQLClientProvider';

import { machine as EmailInvitationMachine } from '../../../machines/Eligibility/EmailInvitationMachine';

import { createGetEligibilityContextPromise } from '../../../promises/getEligibilityContextPromise';
import { EligibilityController } from './EligibilityController';
import { createCreateOrionAccountInvitationPromise } from '../../../promises/createAccountInvitationPromise';
import { createUpdateAccountInvitationPromise } from '../../../promises/updateAccountInvitationPromise';
import { withDelay } from '../../../utils/withDelay';

const noop = () => {
  // We don't need to do anything when the close or resetEligibilityState
  // action is run in this flow.
};

export const Eligibility = () => {
  const client = useGraphQLClient();
  const navigate = useNavigate();

  return (
    <EligibilityController
      options={{
        actions: { close: () => navigate('/') },
        services: {
          getEligibilityContextPromise: withDelay(
            createGetEligibilityContextPromise(client)
          ),
          emailInvitationMachine: EmailInvitationMachine.withConfig({
            actions: {
              resetEligibilityState: noop,
            },
            services: {
              createAccountInvitationPromise:
                createCreateOrionAccountInvitationPromise(client),
              updateAccountInvitationPromise:
                createUpdateAccountInvitationPromise(client),
            },
          }),
        },
      }}
    />
  );
};
