import type { ActorRefFrom, EventFrom, Sender } from 'xstate';
import { assign, createMachine, sendParent } from 'xstate';
import { CalendarDate, ZonedDateTime } from '@internationalized/date';
import { match } from 'ts-pattern';
import type { StateFrom } from '../../utils/StateFrom';
import type { StatesConfig } from '../../utils/StateConfig';
import type { MachineOptionsWithContextFrom } from '../../utils/MachineOptionsWithContextFrom';
import {
  FundingRound,
  PartialFundingRound,
  CompleteFundingRound,
} from '../../schemas/fundingRound/fundingRoundSchema';
import { addFundingRound } from '../../events/AddFundingRound';
import { updateFundingRound } from '../../events/UpdateFundingRound';
import { logMachineError } from '../../utils/logError';
import { closeFundingRound } from '../../events/CloseFundingRound';

type Events =
  | { type: 'SET_ROUND_TITLE'; title: string }
  | { type: 'SET_ROUND_START_DATE'; startDate: ZonedDateTime }
  | { type: 'SET_ROUND_END_DATE'; endDate: ZonedDateTime }
  | { type: 'SET_BOARD_MEETING_START_DATE'; boardStartDate: ZonedDateTime }
  | { type: 'SET_BOARD_MEETING_END_DATE'; boardEndDate: ZonedDateTime }
  | { type: 'SET_BOARD_MEETING_DATE'; boardMeetingDate: CalendarDate }
  | { type: 'SET_ALLOW_ORION'; allowOrion: boolean }
  | { type: 'BLUR_ROUND_TITLE' }
  | { type: 'BLUR_ROUND_START_DATE' }
  | { type: 'BLUR_ROUND_END_DATE' }
  | { type: 'BLUR_BOARD_MEETING_START_DATE' }
  | { type: 'BLUR_BOARD_MEETING_END_DATE' }
  | { type: 'BLUR_BOARD_MEETING_DATE' }
  | { type: 'BLUR_ALLOW_ORION' }
  | { type: 'FOCUS_ROUND_TITLE' }
  | { type: 'FOCUS_ROUND_START_DATE' }
  | { type: 'FOCUS_ROUND_END_DATE' }
  | { type: 'FOCUS_BOARD_MEETING_START_DATE' }
  | { type: 'FOCUS_BOARD_MEETING_END_DATE' }
  | { type: 'FOCUS_BOARD_MEETING_DATE' }
  | { type: 'CANCEL' }
  | { type: 'SAVE_ROUND' };

type Context = {
  id?: FundingRound['id'];
  round: PartialFundingRound;
};

type Services = {
  validateCompleteFundingRound: {
    data: CompleteFundingRound;
  };
};

export const createContext = (
  id: string | undefined,
  round: PartialFundingRound
): Context => ({
  id,
  round,
});

const inputStates: StatesConfig<Context, Events> = {
  pristine: { on: { SAVE_ROUND: 'invalid' } },
  valid: { type: 'final' },
  invalid: {},
};

export const machine = createMachine(
  {
    predictableActionArguments: true,
    tsTypes: {} as import('./RoundMachine.typegen').Typegen0,
    id: 'rounds',
    schema: {
      context: {} as Context,
      events: {} as Events,
      services: {} as Services,
    },
    initial: 'form',
    states: {
      form: {
        id: 'form',
        type: 'parallel',
        states: {
          title: {
            initial: 'transfer',
            states: {
              ...inputStates,
              transfer: {
                always: [
                  {
                    cond: (context) => !context.round.title,
                    target: 'pristine',
                  },
                  'valid',
                ],
              },
            },
            on: {
              SET_ROUND_TITLE: {
                actions: 'setRoundTitle',
              },
              BLUR_ROUND_TITLE: [
                { cond: 'isEmpty', target: '.invalid' },
                '.valid',
              ],
            },
          },
          'start-date': {
            initial: 'valid',
            states: {
              ...inputStates,
            },
            on: {
              BLUR_ROUND_END_DATE: {
                cond: 'isRoundEndBeforeStart',
                target: '#form.end-date.invalid.before-start',
              },
              SET_ROUND_START_DATE: {
                actions: 'setRoundStartDate',
              },
              BLUR_ROUND_START_DATE: '.valid',
            },
          },
          'end-date': {
            id: 'end-date',
            initial: 'transfer',
            states: {
              ...inputStates,
              transfer: {
                always: [
                  {
                    cond: (context) => !context.round.endDate,
                    target: 'pristine',
                  },
                  'valid',
                ],
              },
              invalid: {
                initial: 'before-start',
                states: {
                  'before-start': {},
                },
              },
            },
            on: {
              BLUR_ROUND_START_DATE: [
                {
                  cond: 'isRoundEndBeforeStart',
                  target: '.invalid.before-start',
                },
                {
                  cond: 'isEndSet',
                  target: '.valid',
                },
              ],
              SET_ROUND_END_DATE: {
                actions: 'setRoundEndDate',
              },
              BLUR_ROUND_END_DATE: [
                { cond: 'isEmpty', target: '.invalid' },
                {
                  cond: 'isRoundEndBeforeStart',
                  target: '.invalid.before-start',
                },
                '.valid',
              ],
            },
          },
          'board-meeting-start-date': {
            initial: 'valid',
            states: {
              ...inputStates,
            },
            on: {
              SET_BOARD_MEETING_START_DATE: {
                actions: 'setBoardStartDate',
              },
            },
          },
          'board-meeting-end-date': {
            initial: 'valid',
            states: {
              ...inputStates,
              invalid: {
                initial: 'before-end',
                states: {
                  'before-end': {},
                },
              },
            },
            on: {
              SET_BOARD_MEETING_END_DATE: {
                actions: 'setBoardEndDate',
              },
            },
          },
          'board-meeting-date': {
            id: 'date',
            initial: 'transfer',
            states: {
              ...inputStates,
              transfer: {
                always: [
                  {
                    cond: (context) => !context.round.boardMeetingDate,
                    target: 'pristine',
                  },
                  'valid',
                ],
              },
            },
            on: {
              SET_BOARD_MEETING_DATE: {
                actions: 'setBoardMeetingDate',
              },
              BLUR_BOARD_MEETING_DATE: [
                { cond: 'isEmpty', target: '.invalid' },
                '.valid',
              ],
            },
          },
          'allow-orion': {
            initial: 'transfer',
            states: {
              ...inputStates,
              transfer: {
                always: [
                  {
                    cond: (context) => context.round.allowOrion === null,
                    target: 'pristine',
                  },
                  'valid',
                ],
              },
            },
            on: {
              SET_ALLOW_ORION: {
                actions: 'setAllowOrion',
                target: '.valid',
              },
              BLUR_ALLOW_ORION: [
                { cond: 'isEmpty', target: '.invalid' },
                '.valid',
              ],
            },
          },
          warning: {
            initial: 'off',
            states: {
              on: {
                type: 'final',
                on: { SAVE_ROUND: 'pulse' },
              },
              off: {
                type: 'final',
                on: { SAVE_ROUND: 'on' },
              },
              pulse: {
                type: 'final',
                after: { 1000: 'on' },
              },
              error: { entry: 'logMachineError' },
            },
          },
        },
        on: {
          BLUR_BOARD_MEETING_START_DATE: [
            {
              cond: 'isBoardStartBeforeBoardEnd',
              target: '#form.board-meeting-end-date.invalid.before-end',
            },
            {
              target: [
                'form.board-meeting-start-date.valid',
                'form.board-meeting-end-date.valid',
              ],
            },
          ],
          BLUR_BOARD_MEETING_END_DATE: [
            {
              cond: 'isBoardStartBeforeBoardEnd',
              target: '#form.board-meeting-end-date.invalid.before-end',
            },
            {
              target: [
                'form.board-meeting-start-date.valid',
                'form.board-meeting-end-date.valid',
              ],
            },
          ],
        },
        onDone: {
          target: 'complete',
        },
      },
      complete: {
        on: {
          SAVE_ROUND: 'saving',
          FOCUS_ROUND_TITLE: {
            target: [
              'form.title.pristine',
              'form.start-date.valid',
              'form.end-date.valid',
              'form.board-meeting-start-date.valid',
              'form.board-meeting-end-date.valid',
              'form.board-meeting-date.valid',
              'form.allow-orion.valid',
            ],
          },
          FOCUS_ROUND_START_DATE: {
            target: [
              'form.title.valid',
              'form.start-date.pristine',
              'form.end-date.valid',
              'form.board-meeting-start-date.valid',
              'form.board-meeting-end-date.valid',
              'form.board-meeting-date.valid',
              'form.allow-orion.valid',
            ],
          },
          FOCUS_ROUND_END_DATE: {
            target: [
              'form.title.valid',
              'form.start-date.valid',
              'form.end-date.pristine',
              'form.board-meeting-start-date.valid',
              'form.board-meeting-end-date.valid',
              'form.board-meeting-date.valid',
              'form.allow-orion.valid',
            ],
          },
          FOCUS_BOARD_MEETING_START_DATE: {
            target: [
              'form.title.valid',
              'form.start-date.valid',
              'form.end-date.valid',
              'form.board-meeting-start-date.pristine',
              'form.board-meeting-end-date.valid',
              'form.board-meeting-date.valid',
              'form.allow-orion.valid',
            ],
          },
          FOCUS_BOARD_MEETING_END_DATE: {
            target: [
              'form.title.valid',
              'form.start-date.valid',
              'form.end-date.valid',
              'form.board-meeting-start-date.valid',
              'form.board-meeting-end-date.pristine',
              'form.board-meeting-date.valid',
              'form.allow-orion.valid',
            ],
          },
          FOCUS_BOARD_MEETING_DATE: {
            target: [
              'form.title.valid',
              'form.start-date.valid',
              'form.end-date.valid',
              'form.board-meeting-start-date.valid',
              'form.board-meeting-end-date.valid',
              'form.board-meeting-date.pristine',
              'form.allow-orion.valid',
            ],
          },
        },
      },
      saving: {
        invoke: {
          id: 'validateCompleteFundingRound',
          src: 'validateCompleteFundingRound',
          onDone: { target: 'complete', actions: 'addFundingRound' },
          onError: 'form.warning.error',
        },
      },
    },
    on: {
      CANCEL: {
        actions: 'closeFundingRound',
      },
      SET_ALLOW_ORION: {
        actions: 'setAllowOrion',
      },
    },
  },
  {
    guards: {
      isEmpty: ({ round }, { type }) => {
        const value = match(type)
          .with('BLUR_ROUND_TITLE', () => round.title)
          .with('BLUR_ROUND_END_DATE', () => round.endDate)
          .with('BLUR_BOARD_MEETING_DATE', () => round.boardMeetingDate)
          .with('BLUR_ALLOW_ORION', () => round.allowOrion)
          .exhaustive();

        return value === '' || value === null;
      },
      isEndSet: ({ round }) => {
        return round.endDate !== null;
      },
      isRoundEndBeforeStart: ({ round }) => {
        if (!round.startDate || !round.endDate) {
          return false;
        }
        return round.startDate > round.endDate;
      },
      isBoardStartBeforeBoardEnd: ({ round }) => {
        if (!round.boardStartDate || !round.boardEndDate) {
          return false;
        }
        return round.boardStartDate > round.boardEndDate;
      },
    },
    actions: {
      logMachineError,
      setRoundTitle: assign((context, { title }) => ({
        round: {
          ...context.round,
          title,
        },
      })),
      setRoundStartDate: assign((context, { startDate }) => ({
        round: {
          ...context.round,
          startDate,
        },
      })),
      setRoundEndDate: assign((context, { endDate }) => ({
        round: {
          ...context.round,
          endDate,
        },
      })),
      setBoardStartDate: assign((context, { boardStartDate }) => ({
        round: {
          ...context.round,
          boardStartDate,
        },
      })),
      setBoardEndDate: assign((context, { boardEndDate }) => ({
        round: {
          ...context.round,
          boardEndDate,
        },
      })),
      setBoardMeetingDate: assign((context, { boardMeetingDate }) => ({
        round: {
          ...context.round,
          boardMeetingDate,
        },
      })),
      setAllowOrion: assign((context, { allowOrion }) => ({
        round: {
          ...context.round,
          allowOrion,
        },
      })),
      closeFundingRound: sendParent(({ id }) => closeFundingRound(id)),
      addFundingRound: sendParent(({ id }, { data: round }) =>
        id === undefined
          ? addFundingRound(round)
          : updateFundingRound(id, round)
      ),
    },
  }
);

type Machine = typeof machine;

export type RoundMachineState = StateFrom<Machine>;
export type RoundMachineSender = Sender<EventFrom<Machine>>;
export type RoundMachineOptions = MachineOptionsWithContextFrom<Machine>;
export type RoundMachineEvent = EventFrom<Machine>;
export type RoundMachineActor = ActorRefFrom<Machine>;
