import { TourDate } from '../schemas/tourDates/tourDateSchema';

export type OpenTourDate = {
  type: 'OPEN_TOUR_DATE';
  id?: TourDate['id'];
};

export const openTourDate = (id?: OpenTourDate['id']): OpenTourDate => ({
  type: 'OPEN_TOUR_DATE',
  id,
});
