import React from 'react';
import { Icon, IconName } from './Icon';
import { Button } from './Button';
import cn from 'classnames';

import styles from './Block.module.scss';

interface BlockProps {
  id?: string;
  type?: 'primary' | 'secondary';
  headerText?: string;
  headerButtonOnClick?: () => void;
  headerButtonText?: string;
  headerButtonDisabled?: boolean;
  hideHeaderButton?: boolean;
  shadow?: boolean;
  iconName?: IconName;
  children: React.ReactNode;
  footer?: React.ReactNode;
  footerClassName?: string;
  className?: string;
  reduceFontOnMobile?: boolean;
}

export function Block({
  id,
  type = 'primary',
  headerText,
  headerButtonOnClick,
  headerButtonText,
  headerButtonDisabled,
  hideHeaderButton,
  shadow = false,
  iconName,
  children,
  footer,
  footerClassName,
  className,
  reduceFontOnMobile,
}: BlockProps) {
  return (
    <div className={className}>
      <div
        id={id}
        className={cn(styles.block, styles[type], {
          [styles.shadow]: shadow,
        })}
      >
        <div className={styles.mainContent}>
          {headerText && (
            <div className={styles.header}>
              <div className={styles.headerGroup}>
                {iconName && <Icon name={iconName} className={styles.icon} />}
                <h2 className={styles.headerText}>{headerText}</h2>
              </div>
              {headerButtonText && (
                <Button
                  buttonClassName={cn(hideHeaderButton && styles.hidden)}
                  variant="secondary"
                  size="small"
                  label={headerButtonText}
                  onClick={headerButtonOnClick}
                  disabled={headerButtonDisabled}
                />
              )}
            </div>
          )}
          <div
            className={cn(styles.content, {
              [styles.small]: reduceFontOnMobile,
            })}
          >
            {children}
          </div>
        </div>
        {footer && (
          <div className={cn(styles.footer, footerClassName)}>{footer}</div>
        )}
      </div>
    </div>
  );
}
