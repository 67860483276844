import { ReactNode } from 'react';
import cn from 'classnames';

import styles from './PageContainer.module.scss';

interface Props {
  children: ReactNode;
  className?: string;
  innerClassName?: string;
}

export function PageContainer({ children, className, innerClassName }: Props) {
  return (
    <main className={className}>
      <div className={cn(styles.pageContainer, innerClassName)}>{children}</div>
    </main>
  );
}
