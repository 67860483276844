import { useState } from 'react';
import { useMachine } from '@xstate/react';
import { match } from 'ts-pattern';
import cn from 'classnames';
import {
  TimeMachineSender,
  TimeMachineState,
  machine,
} from '../machines/TimeMachine';

import { useGraphQLClient } from './GraphQLClientProvider';

import { getFluxCapacitor } from '../promises/getFluxCapacitor';
import { setFluxCapacitor } from '../promises/setFluxCapacitor';
import { clearFluxCapacitor } from '../promises/clearFluxCapacitor';

import { DatePickerWithTime } from './DatePicker/DatePickerWithTime';
import { Button } from './Button';
import { Icon } from './Icon';
import { Select } from './Select';

import styles from './ConnectionStatus.module.scss';

const DatePickerPanel = ({
  state,
  send,
}: {
  state: TimeMachineState;
  send: TimeMachineSender;
}) => {
  return (
    <div className={styles.controls}>
      <div>
        {state.context.hint && (
          <p>
            The database does not appear to have debugging functions enabled.
            Please run <code>pnpm db:debug</code> to enable the database
            debugging functions.
          </p>
        )}
        <p>Current Flux Capacitor Time</p>

        <DatePickerWithTime
          aria-label="Current Flux Capacitor Time"
          value={state.context.serverTime}
          timezone={state.context.timezone}
          onChange={() => {
            // Do nothing here. This is a read only date picker.
          }}
        />
      </div>
      <div>
        <p>Destination Flux Capacitor Time</p>
        <DatePickerWithTime
          aria-label="Destination Flux Capacitor Time"
          value={state.context.time}
          timezone={state.context.timezone}
          onChange={(data) => send({ type: 'SET_TIME', data })}
        />
      </div>
      <Button
        disabled={!state.matches('waiting.time.dirty')}
        label="Set Flux Capacitor"
        onClick={() => send({ type: 'SET_FLUX_CAPACITOR' })}
        size="small"
      />
      <Button
        label="Clear Flux Capacitor"
        onClick={() => send({ type: 'CLEAR_FLUX_CAPACITOR' })}
        size="small"
      />
    </div>
  );
};

const RoundPickerPanel = ({
  state,
  send,
}: {
  state: TimeMachineState;
  send: TimeMachineSender;
}) => {
  return (
    <div className={styles.controls}>
      <div>
        {state.context.hint && (
          <p>
            The database does not appear to have debugging functions enabled.
            Please run <code>pnpm db:debug</code> to enable the database
            debugging functions.
          </p>
        )}
        <p>Rounds</p>
        <Select
          id="round"
          items={state.context.rounds}
          itemToKey={(item) => item?.title}
          getItemText={(round) =>
            round.title + (round.startDate === null ? ' (No Start Date)' : '')
          }
          selectedItem={
            state.context.rounds.find(
              (round) =>
                round.startDate !== null &&
                state.context.time !== null &&
                round.startDate.compare(state.context.time) === 0
            ) ?? null
          }
          onChange={(round) => {
            if (round.startDate !== null) {
              send({
                type: 'SET_TIME',
                data: round.startDate,
              });
            }
          }}
        />
      </div>
      <Button
        disabled={!state.matches('waiting.round.dirty')}
        label="Set Flux Capacitor"
        onClick={() => send({ type: 'SET_FLUX_CAPACITOR' })}
        size="small"
      />
      <Button
        label="Clear Flux Capacitor"
        onClick={() => send({ type: 'CLEAR_FLUX_CAPACITOR' })}
        size="small"
      />
    </div>
  );
};

export function TimeTravelPanel() {
  const client = useGraphQLClient();
  const [isOpen, setIsOpen] = useState(false);

  const [state, send] = useMachine(machine, {
    services: {
      getFluxCapacitor: getFluxCapacitor(client),
      setFluxCapacitor: setFluxCapacitor(client),
      clearFluxCapacitor: clearFluxCapacitor(client),
    },
  });

  return match(isOpen)
    .with(false, () => (
      <button
        type="button"
        className={styles.icon}
        onClick={() => setIsOpen(true)}
      >
        <p className={styles.label}>+</p>
      </button>
    ))
    .with(true, () => (
      <div className={styles.connectionStatus}>
        <div className={styles.container}>
          <div className={styles.col}>
            <Icon
              name="zap"
              onClick={() => send('SWITCH_TO_TIME')}
              className={cn({
                [styles.active]: state.matches('waiting.time'),
              })}
            />
            <Icon
              name="clock"
              onClick={() => send('SWITCH_TO_ROUND')}
              className={cn({
                [styles.active]: state.matches('waiting.round'),
              })}
            />
          </div>
          <div className={styles.col}>
            <button
              type="button"
              className={styles.closeIcon}
              onClick={() => setIsOpen(false)}
            >
              <p className={styles.label}>-</p>
            </button>
          </div>
          {state.matches('waiting.time') && (
            <DatePickerPanel state={state} send={send} />
          )}
          {state.matches('waiting.round') && (
            <RoundPickerPanel state={state} send={send} />
          )}
        </div>
      </div>
    ))
    .otherwise(() => null);
}
