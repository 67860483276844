import { ReactNode } from 'react';
import cn from 'classnames';

import styles from './PageContainerWithSidebar.module.scss';
import { Breadcrumb, Breadcrumbs } from './Breadcrumbs';

interface Props {
  breadcrumbs?: Breadcrumb[];
  heading?: ReactNode;
  currentPageName?: string;
  innerClassName?: string;
  children: ReactNode;
  useMaxWidthXl?: boolean;
}

export function PageContainerWithSidebar({
  breadcrumbs,
  heading,
  currentPageName,
  innerClassName,
  useMaxWidthXl = true,
  children,
}: Props) {
  return (
    <div
      className={cn({
        'max-w-xl': useMaxWidthXl,
      })}
    >
      {breadcrumbs && (
        <Breadcrumbs
          className={styles.breadcrumbs}
          breadcrumbs={breadcrumbs}
          currentPageName={currentPageName}
        />
      )}
      {heading && <h1 className={styles.heading}>{heading}</h1>}
      <div className={cn(styles.pageContainer, innerClassName)}>{children}</div>
    </div>
  );
}
