import { FundingRequestFormView } from './FundingRequestFormView';
import { FundingRequestFormMachineActor } from '../../machines/components/FundingRequestFormMachine';
import { useActor } from '@xstate/react';

interface FundingRequestFormControllerProps {
  actor: FundingRequestFormMachineActor;
}

export function FundingRequestFormController({
  actor,
}: FundingRequestFormControllerProps) {
  const [state, send] = useActor(actor);

  return <FundingRequestFormView state={state} send={send} />;
}
