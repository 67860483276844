import type {
  Sender,
  EventFrom,
  MachineOptionsFrom,
  ActorRefFrom,
} from 'xstate';
import { createMachine, assign, spawn, actions, send } from 'xstate';

import {
  CreateSinglePromise,
  CreateSinglePromiseResult,
} from '../../promises/createSinglePromise';
import { DeleteSinglePromiseResult } from '../../promises/deleteSinglePromise';
import { UpdateSinglePromise } from '../../promises/updateSinglePromise';
import { StateFrom } from '../../utils/StateFrom';
import {
  machine as ReleaseSingleMachine,
  ReleaseSingleMachineActor,
} from './ReleaseSingleMachine';
import { parsePositiveInteger } from '../../utils/parsePositiveInteger';
import { logMachineError } from '../../utils/logError';

type Events =
  | { type: 'ADD_SINGLE' }
  | { type: 'EDIT_SINGLE'; id: string }
  | { type: 'CLOSE_ADD_SINGLE'; id: number }
  | { type: 'CLOSE_EDIT_SINGLE'; id: string }
  | { type: 'DELETE_SINGLE'; id: string }
  | { type: 'UPDATE_CACHED_RELEASE'; data: CreateSinglePromiseResult };

export const updateCachedRelease = (data: CreateSinglePromiseResult) => ({
  type: 'UPDATE_CACHED_RELEASE',
  data,
});

export const closeAddSingle = (id: number) => ({
  type: 'CLOSE_ADD_SINGLE',
  id,
});

export const closeEditSingle = (id: string) => ({
  type: 'CLOSE_EDIT_SINGLE',
  id,
});

type Single = {
  id: string;
  title: string;
  spins: number;
  editRef?: ReleaseSingleMachineActor;
};

export type Context = {
  createSinglePromise: CreateSinglePromise;
  updateSinglePromise: UpdateSinglePromise;
  releaseId: string;
  singles: Single[];
  isOwner: boolean;
  singleAddRefs: ReleaseSingleMachineActor[];
};

type Services = {
  deleteSingle: {
    data: DeleteSinglePromiseResult;
  };
};

export const createContext = (
  releaseId: string,
  singles: Single[],
  isOwner: boolean,
  createSinglePromise: CreateSinglePromise,
  updateSinglePromise: UpdateSinglePromise
): Context => ({
  releaseId,
  singles,
  isOwner,
  createSinglePromise,
  updateSinglePromise,
  singleAddRefs: [],
});

export const machine = createMachine(
  {
    id: 'releaseSinglesMachine',
    predictableActionArguments: true,
    tsTypes: {} as import('./ReleaseSinglesMachine.typegen').Typegen0,
    schema: {
      context: {} as Context,
      events: {} as Events,
      services: {} as Services,
    },
    initial: 'ready',
    states: {
      ready: {
        on: {
          ADD_SINGLE: { actions: 'createSingleAddMachine' },
          EDIT_SINGLE: { actions: 'createSingleEditMachine' },
          CLOSE_ADD_SINGLE: {
            actions: ['closeAddSingle', 'updateAddSingleIds'],
          },
          CLOSE_EDIT_SINGLE: { actions: 'closeEditSingle' },
          UPDATE_CACHED_RELEASE: { actions: 'updateCachedRelease' },
          DELETE_SINGLE: 'deletingSingle',
        },
        initial: 'noError',
        states: {
          noError: {},
          error: {
            states: {
              network: {},
              internal: { entry: 'logMachineError' },
            },
          },
        },
      },
      deletingSingle: {
        invoke: {
          src: 'deleteSingle',
          onDone: [
            {
              actions: ['deleteSingle'],
              target: 'ready',
            },
          ],
          onError: [
            {
              cond: 'isNetworkError',
              target: ['#releaseSinglesMachine.ready.error.network'],
            },
            {
              target: ['#releaseSinglesMachine.ready.error.internal'],
            },
          ],
        },
      },
    },
  },
  {
    guards: {
      isNetworkError: (_, event) => {
        if (event.data instanceof Error) {
          return /network/i.test(event.data.message);
        }
        return false;
      },
    },
    actions: {
      logMachineError,
      createSingleAddMachine: assign((context) => {
        return {
          singleAddRefs: [
            ...context.singleAddRefs,
            spawn(
              ReleaseSingleMachine.withConfig(
                {
                  services: {
                    addSingle: ({ releaseId, spins, title }) =>
                      context.createSinglePromise({
                        single: {
                          releaseId,
                          spins: parsePositiveInteger(spins),
                          title,
                        },
                      }),
                    updateSingle: ({ id, releaseId, spins, title }) =>
                      context.updateSinglePromise({
                        id,
                        singlePatch: {
                          releaseId,
                          spins: parsePositiveInteger(spins),
                          title,
                        },
                      }),
                  },
                },
                {
                  mode: 'add',
                  refId: context.singleAddRefs.length,
                  id: '',
                  releaseId: context.releaseId,
                  title: '',
                  spins: '',
                }
              )
            ),
          ],
        };
      }),
      createSingleEditMachine: assign((context, { id }) => {
        return {
          singles: context.singles.map((single) =>
            single.id !== id
              ? single
              : {
                  ...single,
                  editRef: spawn(
                    ReleaseSingleMachine.withConfig(
                      {
                        services: {
                          addSingle: ({ releaseId, spins, title }) =>
                            context.createSinglePromise({
                              single: {
                                releaseId,
                                spins: parsePositiveInteger(spins),
                                title,
                              },
                            }),
                          updateSingle: ({ id, releaseId, spins, title }) =>
                            context.updateSinglePromise({
                              id,
                              singlePatch: {
                                releaseId,
                                spins: parsePositiveInteger(spins),
                                title,
                              },
                            }),
                        },
                      },
                      {
                        mode: 'update',
                        refId: 0, // Existing singles use the refId to tell which reference to remove.
                        id,
                        releaseId: context.releaseId,
                        title: single.title,
                        spins: String(single.spins),
                      }
                    )
                  ),
                }
          ),
        };
      }),
      closeAddSingle: assign((context, { id }) => ({
        singleAddRefs: context.singleAddRefs.filter((_, index) => index !== id),
      })),
      updateAddSingleIds: actions.pure(({ singleAddRefs }) =>
        singleAddRefs.map((to, refId) =>
          send({ type: 'UPDATE_REF_ID', refId }, { to })
        )
      ),
      closeEditSingle: assign((context, { id }) => ({
        singles: context.singles.map((single) =>
          single.id !== id
            ? single
            : {
                id: single.id,
                title: single.title,
                spins: single.spins,
              }
        ),
      })),
      updateCachedRelease: assign((context, { data }) => {
        const isUpdate = Boolean(
          context.singles.find((single) => single.id === data.single?.id)
        );

        if (isUpdate && data.single) {
          return {
            singles: context.singles.map((single) =>
              single.id !== data.single?.id ? single : data.single
            ),
          };
        }

        return {
          singles: data.single
            ? [...context.singles, data.single]
            : context.singles,
        };
      }),
      deleteSingle: assign((context, { data }) => ({
        singles: context.singles.filter(
          (single) => single.id !== data.result?.single?.id
        ),
      })),
    },
  }
);

type Machine = typeof machine;

export type ReleaseSinglesMachineState = StateFrom<Machine>;
export type ReleaseSinglesMachineSender = Sender<EventFrom<Machine>>;
export type ReleaseSinglesMachineOptions = MachineOptionsFrom<Machine, true> & {
  context: Context;
};
export type ReleaseSinglesMachineEvent = EventFrom<Machine>;
export type ReleaseSinglesMachineActor = ActorRefFrom<Machine>;
