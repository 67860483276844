import { useEffect, useState } from 'react';
import { match } from 'ts-pattern';

const DESKTOP_BREAKPOINT = 768;
const MOBILE_BREAKPOINT = 480;

type ScreenVariant = 'mobile' | 'tablet' | 'desktop';

export function useWindowSize(onChange?: (screen: ScreenVariant) => void) {
  const [screen, setScreen] = useState<ScreenVariant>('desktop');
  const [windowWidth, setWindowWidth] = useState<number>(0);

  useEffect(() => {
    function updateSize() {
      if (window.innerWidth >= DESKTOP_BREAKPOINT) {
        if (screen !== 'desktop') {
          setScreen('desktop');
          onChange && onChange('desktop');
        }
      } else if (window.innerWidth < MOBILE_BREAKPOINT) {
        if (screen !== 'mobile') {
          setScreen('mobile');
          onChange && onChange('mobile');
        }
      } else if (screen !== 'tablet') {
        setScreen('tablet');
        onChange && onChange('tablet');
      }
      setWindowWidth(window.innerWidth);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, [screen]);
  return { screen, windowWidth };
}

export function useInitialWindowSize() {
  const screen = match(window.innerWidth)
    .when(
      (width) => width >= DESKTOP_BREAKPOINT,
      () => 'desktop'
    )
    .when(
      (width) => width < MOBILE_BREAKPOINT,
      () => 'mobile'
    )
    .otherwise(() => 'tablet');

  return {
    screen,
    windowWidth: window.innerWidth,
  };
}
