import { Expense } from '../../schemas/fundingRequests/expenses/expenseSchema';

export type CloseExpense = {
  type: 'CLOSE_EXPENSE';
  id?: Expense['id'];
};

export const closeExpense = (id?: CloseExpense['id']): CloseExpense => ({
  type: 'CLOSE_EXPENSE',
  id,
});
