import { useMachine } from '@xstate/react';
import {
  machine,
  ResetPasswordMachineOptions,
} from '../../machines/ResetPasswordMachine';
import { ResetPasswordView } from './ResetPasswordView';

type ResetPasswordControllerProps = {
  options: ResetPasswordMachineOptions;
};

export function ResetPasswordController({
  options,
}: ResetPasswordControllerProps) {
  const [state, send] = useMachine(machine, options);

  return <ResetPasswordView state={state} send={send} />;
}
