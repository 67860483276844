import { useActor } from '@xstate/react';
import { SetDepositInfoMachineActor } from '../../machines/ClaimFunds/SetDepositInfoMachine';
import { SetDepositInfoView } from './SetDepositInfoView';

export function SetDepositInfoController({
  actor,
}: {
  actor: SetDepositInfoMachineActor;
}) {
  const [state, send] = useActor(actor);

  return <SetDepositInfoView state={state} send={send} />;
}
