import { match } from 'ts-pattern';

import { ReleaseMachineActor } from '../../machines/Eligibility/ReleaseMachine';
import { ResidentMachineActor } from '../../machines/Eligibility/ResidentMachine';
import { EmailInvitationMachineActor } from '../../machines/Eligibility/EmailInvitationMachine';
import { SalesMachineActor } from '../../machines/Eligibility/SalesMachine';
import { MasterRecordingActor } from '../../machines/Eligibility/MasterRecordingMachine';

import { ResidentController } from '../../components/Eligibility/Resident/ResidentController';
import { MasterRecordingController } from '../../components/Eligibility/MasterRecording/MasterRecordingController';
import { SummaryController } from '../../components/Eligibility/Summary/SummaryController';
import { ReleaseController } from '../../components/Eligibility/Release/ReleaseController';
import { SalesController } from '../../components/Eligibility/Sales/SalesController';

import { ErrorPage } from '../ErrorPage';
import { Loading } from '../../components/Loading';

import {
  EligibilityMachineSender,
  EligibilityMachineState,
} from '../../machines/Eligibility/Machine';

interface EligibilityViewProps {
  state: EligibilityMachineState;
  send: EligibilityMachineSender;
}

export function EligibilityView({ state }: EligibilityViewProps) {
  return (
    <div className="max-w-lg">
      {match(state)
        .when(
          () => state.matches('error'),
          () => <ErrorPage />
        )
        .when(
          () => state.matches('init'),
          () => <Loading />
        )
        .when(
          () => state.matches('resume'),
          () => <p>Resuming session</p>
        )
        .when(
          () => state.matches('resident'),
          () => (
            <ResidentController
              actor={state.children.residentMachine as ResidentMachineActor}
            />
          )
        )
        .when(
          () => state.matches('release'),
          () => (
            <ReleaseController
              actor={state.children.releaseMachine as ReleaseMachineActor}
            />
          )
        )
        .when(
          () => state.matches('sales'),
          () => (
            <SalesController
              actor={state.children.salesMachine as SalesMachineActor}
            />
          )
        )
        .when(
          () => state.matches('masterRecording'),
          () => (
            <MasterRecordingController
              type="eligibility"
              actor={
                state.children.masterRecordingMachine as MasterRecordingActor
              }
            />
          )
        )
        .when(
          () => state.matches('summary'),
          () => (
            <SummaryController
              actor={
                state.children
                  .emailInvitationMachine as EmailInvitationMachineActor
              }
            />
          )
        )
        .run()}
    </div>
  );
}
