import { GraphQLClient } from 'graphql-request';
import { GetFundingRoundsDocument } from '../../graphql/operations';
import { fundingRoundsSchema } from '../../schemas/admin/fundingRoundsSchema';
import { z } from 'zod';

export const createGetFundingRoundsPromise =
  (client: GraphQLClient) => async () => {
    const result = await client.request(GetFundingRoundsDocument);

    return fundingRoundsSchema
      .merge(z.object({ timezone: z.string() }))
      .parse(result);
  };

export type CreateGetFundingRoundsPromise = ReturnType<
  typeof createGetFundingRoundsPromise
>;
export type CreateGetFundingRoundsPromiseResult = Awaited<
  ReturnType<CreateGetFundingRoundsPromise>
>;
