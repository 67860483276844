import { Icon } from './Icon';
import cn from 'classnames';

import styles from './SortBy.module.scss';
import { useOutsideClick } from '../hooks/useOutsideClick';
import { useRef, useState } from 'react';

interface Props<T> {
  options: Array<{
    label: string;
    value: T;
  }>;
  value: T;
  onSelect: (value: T) => void;
  className?: string;
}

export function SortBy<T>(props: Props<T>) {
  const [open, setOpen] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  const label = props.options.find(
    (option) => option.value === props.value
  )?.label;

  const selectOption = (option: T) => {
    props.onSelect(option);
    setOpen(false);
  };

  useOutsideClick(ref, () => {
    setOpen(false);
  });

  return (
    <div className={cn(styles.container, props.className)} ref={ref}>
      <button
        type="button"
        className={cn(styles.button, {
          [styles.open]: open,
        })}
        onClick={() => (open ? setOpen(false) : setOpen(true))}
      >
        <Icon name="sort" className={styles.icon} />
        <p>Sort by: {label}</p>
      </button>
      {open && (
        <div className={styles.options}>
          {props.options.map((option) => (
            <button
              type="button"
              key={String(option.value)}
              className={cn(styles.item, {
                [styles.active]: props.value === option.value,
              })}
              onClick={() => selectOption(option.value)}
            >
              {option.label}
            </button>
          ))}
        </div>
      )}
    </div>
  );
}
