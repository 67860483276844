import { GraphQLClient } from 'graphql-request';
import { Context } from '../machines/ClaimFunds/DirectDepositMachine';
import { DeletePaymentDocument } from '../graphql/operations';
import { directDepositAccountSchema } from '../schemas/directDepositAccount';

export const createDeletePaymentPromise =
  (client: GraphQLClient) =>
  async (
    { directDepositAccount, applicationId }: Context,
    { id }: { id: string }
  ) => {
    if (!directDepositAccount) {
      throw new Error('No directDepositAccount given');
    }

    const { result } = await client.request(DeletePaymentDocument, {
      input: {
        id,
      },
      directDepositAccountId: directDepositAccount.id,
      applicationId,
    });

    return directDepositAccountSchema.parse(
      result?.query?.directDepositAccount
    );
  };

export type DeletePaymentPromise = ReturnType<
  typeof createDeletePaymentPromise
>;
export type DeletePaymentPromiseResult = Awaited<
  ReturnType<DeletePaymentPromise>
>;
