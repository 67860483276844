import { GenericPreview } from '../GenericPreview';
import { Attachment } from '../../schemas/attachment';
import { formatFileSize } from '../../utils/formatFileSize';

interface Props {
  attachment: Attachment;
  isOwner?: boolean;
  onClick: (attachment: Attachment) => void;
  onDelete?: (attachment: Attachment) => void;
  isButtonDisabled?: boolean;
}

export function AttachmentPreview({
  attachment,
  isOwner = false,
  onClick,
  onDelete,
  isButtonDisabled,
}: Props) {
  return (
    <GenericPreview
      iconName="file"
      iconAriaLabel="File icon"
      title={attachment.name}
      note={formatFileSize(attachment.size)}
      buttonVariant="neutral"
      buttonLabel={isOwner && onDelete ? 'Delete' : undefined}
      onIconClick={() => onClick(attachment)}
      onTitleClick={() => onClick(attachment)}
      onButtonClick={
        isOwner && onDelete ? () => onDelete(attachment) : undefined
      }
      isButtonDisabled={isButtonDisabled}
    />
  );
}
