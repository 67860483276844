import { FundingRound } from '../schemas/fundingRound/fundingRoundSchema';

export type CloseFundingRound = {
  type: 'CLOSE_FUNDING_ROUND';
  id?: FundingRound['id'];
};

export const closeFundingRound = (
  id?: CloseFundingRound['id']
): CloseFundingRound => ({
  type: 'CLOSE_FUNDING_ROUND',
  id,
});
