import { match } from 'ts-pattern';
import {
  ReopenClaimsMachineSender,
  ReopenClaimsMachineState,
} from '../../machines/Admin/ReopenClaimsMachine';
import { ApplicationMachineSender } from '../../machines/Application/ApplicationMachine';
import { FormField } from '../FormField';
import { InlineFormContainer } from '../InlineFormContainer';
import { InlineFormHeading } from '../InlineFormHeading';
import { TextArea } from '../TextArea';
import { ErrorFeedback } from '../ErrorFeedback';
import { Loading } from '../Loading';

import styles from './ReopenClaimsForm.module.scss';

interface ReopenClaimsFormProps {
  reopenClaimsState: ReopenClaimsMachineState;
  reopenClaimsSend: ReopenClaimsMachineSender;
  applicationSend: ApplicationMachineSender;
}

export function ReopenClaimsForm({
  reopenClaimsState,
  reopenClaimsSend,
  applicationSend,
}: ReopenClaimsFormProps) {
  return match(reopenClaimsState)
    .when(
      () => reopenClaimsState.matches('savingNotes'),
      () => <Loading inline={true} />
    )
    .when(
      () => reopenClaimsState.matches('error'),
      () => <ErrorFeedback />
    )
    .otherwise(() => {
      const isEdit = reopenClaimsState.matches('editNotesFormOpen');
      return (
        <InlineFormContainer
          primaryButtonText={isEdit ? 'Save Review Notes' : 'Reopen Claim'}
          primaryButtonOnClick={() =>
            reopenClaimsSend({
              type: isEdit ? 'SAVE_REOPEN_CLAIMS_NOTES' : 'REOPEN_CLAIMS',
            })
          }
          secondaryButtonText="Cancel"
          secondaryButtonOnClick={() =>
            applicationSend({ type: 'CLOSE_REOPEN_CLAIMS_FORM' })
          }
        >
          <InlineFormHeading>
            {isEdit ? 'Edit Review Notes' : 'Add Review Notes & Reopen Claim'}
          </InlineFormHeading>
          <FormField
            label="Notes:"
            description="The RSF’s 30-day/one-fix policy text will be automatically appended to your message."
          >
            <TextArea
              id="reopen-claim-notes"
              label="Notes"
              size="large"
              value={reopenClaimsState.context.notes ?? ''}
              onChange={(notes) =>
                reopenClaimsSend({ type: 'UPDATE_NOTES', notes })
              }
            />
          </FormField>
          {!isEdit && (
            <p className={styles.note}>
              After adding review notes the claim will be re-opened for 30 days
              for the artist to review and apply changes.
            </p>
          )}
        </InlineFormContainer>
      );
    });
}
