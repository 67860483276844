import { GraphQLClient } from 'graphql-request';
import {
  ForgotPasswordDocument,
  ForgotPasswordMutationVariables,
  ForgotPasswordMutation,
  ForgotPasswordResponseCode,
} from '../graphql/operations';
import {
  createPromiseDoneEvent,
  createPromiseErrorEvent,
} from '../utils/createPromiseEvent';

export const isInvalidRequest = ({ result }: ForgotPasswordMutation) =>
  result?.response?.code === ForgotPasswordResponseCode.InvalidRequest;

export const isSuccess = ({ result }: ForgotPasswordMutation) =>
  result?.response?.code === ForgotPasswordResponseCode.Success;

export const createForgotPasswordPromise =
  (client: GraphQLClient) => (variables: ForgotPasswordMutationVariables) =>
    client.request(ForgotPasswordDocument, variables);

export type ForgotPasswordPromise = ReturnType<
  typeof createForgotPasswordPromise
>;
export type ForgotPasswordPromiseResult = Awaited<
  ReturnType<ForgotPasswordPromise>
>;

export const mockDoneData: ForgotPasswordPromiseResult = {
  result: {
    response: {
      code: ForgotPasswordResponseCode.Success,
    },
  },
};

export const mockErrorData: unknown = new Error('This is a mock error.');

export const createMockDoneEvent = (promiseName: string) =>
  createPromiseDoneEvent(promiseName, mockDoneData);

export const createMockErrorEvent = (promiseName: string) =>
  createPromiseErrorEvent(promiseName, mockErrorData);
