import {
  Storage,
  createMemoryStorage,
  createPersistentStorage,
} from '../../utils/storage';

import { AddReleaseState, parseAddReleaseState } from './state';

export type AddReleaseStorage = Omit<Storage<AddReleaseState>, 'add'> & {
  add: (state: AddReleaseState, isReplay?: true) => void;
};

const mergeAddReleaseStates = (
  newState: AddReleaseState,
  oldState: AddReleaseState
): AddReleaseState => ({
  ...oldState,
  ...newState,
  sales: {
    ...oldState.sales,
    ...newState.sales,
  },
  release: {
    ...oldState.release,
    ...newState.release,
  },
  master: {
    ...oldState.master,
    ...newState.master,
  },
});

const createAddReleaseStorage = (
  storage: Storage<AddReleaseState>
): AddReleaseStorage => {
  const { add, ...rest } = storage;

  return {
    add: (state: AddReleaseState, isReplay?: true) => {
      if (isReplay !== true) {
        add(state);
      }
    },
    ...rest,
  };
};

export const createPersistentAddReleaseStorage = () =>
  createAddReleaseStorage(
    createPersistentStorage<AddReleaseState>(
      // Should this be limited to the artist? Can we even do that?
      'starmaker.add-release',
      {},
      mergeAddReleaseStates,
      parseAddReleaseState
    )
  );

export const createMemoryAddReleaseStorage = (initial?: AddReleaseState) =>
  createAddReleaseStorage(
    createMemoryStorage<AddReleaseState>(initial ?? {}, mergeAddReleaseStates)
  );
