import { GraphQLClient } from 'graphql-request';
import {
  ApplicationReviewStatus,
  ApplicationStage,
  ApplicationStatus,
  ApplicationType,
  SearchApplicationsDocument,
  SearchApplicationsQueryVariables,
} from '../graphql/operations';
import { z } from 'zod';
import { parsePositiveFloat } from '../utils/parsePositiveFloat';
import { serverDateTimeSchema } from '../schemas/dateTimeSchema';
import { parseAbsolute } from '@internationalized/date';

export const createSearchApplicationsPromise =
  (client: GraphQLClient) =>
  async (data: { searchVariables: SearchApplicationsQueryVariables }) => {
    const response = await client.request(
      SearchApplicationsDocument,
      data.searchVariables
    );

    return await z
      .object({
        applications: z
          .object({
            id: z.string().uuid(),
            timezone: z.string(),
            createdAt: serverDateTimeSchema.unwrap(),
            artistModifiedAt: serverDateTimeSchema.unwrap().nullable(),
            submittedAt: serverDateTimeSchema,
            status: z.nativeEnum(ApplicationStatus),
            stage: z.nativeEnum(ApplicationStage),
            type: z.nativeEnum(ApplicationType),
            artist: z.object({
              id: z.string().uuid(),
              name: z.string(),
            }),
            fundingRound: z
              .object({
                title: z.string(),
              })
              .nullable(),
            numberOfPendingRequests: z.number(),
            numberOfUnsettledRequests: z.number(),
            reviewStatus: z.nativeEnum(ApplicationReviewStatus),
            totalRequested: z
              .string()
              .transform((el) => parsePositiveFloat(el)),
            approvedTotal: z.string().transform((el) => parsePositiveFloat(el)),
            tentativeTotal: z
              .string()
              .transform((el) => parsePositiveFloat(el)),
            claimedTotal: z.string().transform((el) => parsePositiveFloat(el)),
            totalPaid: z.string().transform((el) => parsePositiveFloat(el)),
            claimsResubmissionDeadline: serverDateTimeSchema,
          })
          .transform(
            ({
              timezone,
              createdAt,
              artistModifiedAt,
              submittedAt,
              claimsResubmissionDeadline,
              ...rest
            }) => ({
              timezone,
              createdAt: parseAbsolute(createdAt, timezone),
              artistModifiedAt: artistModifiedAt
                ? parseAbsolute(artistModifiedAt, timezone)
                : null,
              submittedAt:
                submittedAt !== null
                  ? parseAbsolute(submittedAt, timezone)
                  : null,
              claimsResubmissionDeadline:
                claimsResubmissionDeadline !== null
                  ? parseAbsolute(claimsResubmissionDeadline, timezone)
                  : null,
              ...rest,
            })
          )
          .array(),
        total: z.number(),
      })
      .parseAsync(response.result?.at(0));
  };

export type CreateSearchApplicationsPromise = ReturnType<
  typeof createSearchApplicationsPromise
>;

export type CreateSearchApplicationsPromiseResult = Awaited<
  ReturnType<CreateSearchApplicationsPromise>
>;
