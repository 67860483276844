export type History<State> = {
  back: () => void;
  forward: () => void;
  push: (state: State) => void;
  replace: (state: State) => void;
  subscribe: (onPop: (state: State) => void) => void;
  unsubscribe: () => void;
};

export const createBrowserHistory = <State>(
  parse: (data: unknown) => State
): History<State> => {
  let listener: ((event: PopStateEvent) => void) | undefined = undefined;

  return {
    back: () => {
      window.history.back();
    },
    forward: () => {
      window.history.forward();
    },
    push: (state) => window.history.pushState(state, ''),
    replace: (state) => window.history.replaceState(state, ''),
    subscribe: (onPop) => {
      listener = ({ state }: PopStateEvent) => onPop(parse(state));
      window.addEventListener('popstate', listener);
    },
    unsubscribe: () => {
      if (listener !== undefined) {
        window.removeEventListener('popstate', listener);
        listener = undefined;
      }
    },
  };
};

export const createMemoryHistory = <State>(): History<State> => {
  let listener: ((state: State) => void) | undefined = undefined;

  const stack: State[] = [];
  let index = 0;

  return {
    back: () => {
      if (index > 0 && listener !== undefined) {
        index = index - 1;
        listener(stack[index]);
      }
    },
    forward: () => {
      if (index < stack.length - 1 && listener !== undefined) {
        index = index + 1;
        listener(stack[index]);
      }
    },
    push: (state) => {
      stack.splice(index + 1);
      stack.push(state);
      index++;
    },
    replace: (state) => {
      stack[index] = state;
    },
    subscribe: (onPop) => {
      listener = onPop;
    },
    unsubscribe: () => {
      listener = undefined;
    },
  };
};
