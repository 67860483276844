import { Icon } from '../components/Icon';
import cn from 'classnames';
import { DragEvent, useRef, useState } from 'react';
import { match } from 'ts-pattern';

import styles from './UploadButton.module.scss';

export interface ButtonProps {
  className?: string;
  buttonLabel: string;
  multiple?: boolean;
  disabled?: boolean;
  isUploading: boolean;
  onClick: (files: FileList) => void;
  variant?: 'primary' | 'secondary';
}

export function UploadButton({
  className,
  buttonLabel,
  multiple = true,
  isUploading,
  disabled = false,
  onClick,
  variant = 'primary',
}: ButtonProps) {
  const [isDragOver, setIsDragOver] = useState(false);
  const inputRef = useRef<HTMLInputElement | null>(null);

  const handleFileSelect = () => {
    inputRef.current?.click();
  };

  const handleFileSelected = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files?.length) {
      return;
    }

    onClick(e.target.files);
  };

  const onDrop = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    if (!e.dataTransfer.files.length) {
      return;
    }
    onClick(e.dataTransfer.files);
    setIsDragOver(false);
  };

  return (
    <div
      className={cn(styles.container, {
        [styles.drag]: isDragOver,
      })}
      onDragOver={(e) => {
        e.preventDefault();
        setIsDragOver(true);
      }}
      onDragLeave={() => setIsDragOver(false)}
      onDrop={onDrop}
    >
      <button
        type="button"
        disabled={disabled}
        className={cn(styles.button, className, {
          [styles.disabled]: disabled,
          [styles.isUploading]: isUploading,
        })}
        onClick={handleFileSelect}
      >
        <div className={cn(styles.addnewContainer)}>
          {match(isUploading)
            .with(false, () => (
              <>
                {variant === 'primary' && (
                  <div className={styles.addnewIconContainer}>
                    <Icon className={styles.addIcon} name="plus" label="" />
                  </div>
                )}
                <div
                  className={cn(
                    {
                      [styles.primaryLabel]: variant === 'primary',
                      [styles.secondaryLabel]: variant === 'secondary',
                    },
                    styles.label
                  )}
                >
                  {buttonLabel}
                </div>
                {!isUploading && (
                  <div className={cn(styles.small, styles.label)}>
                    … or drag & drop a file.
                  </div>
                )}
              </>
            ))
            .with(true, () => (
              <>
                <div>
                  <Icon className={styles.addIcon} name="spinner" label="" />
                </div>
                <div>
                  <p className={styles.primaryLabel}>Uploading…</p>
                </div>
              </>
            ))
            .run()}
        </div>
      </button>
      <input
        className={styles.input}
        type="file"
        ref={inputRef}
        onChange={handleFileSelected}
        multiple={multiple}
        data-testid="fileUpload"
      />
    </div>
  );
}
