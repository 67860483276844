import { useNavigate, useParams } from 'react-router-dom';
import {
  createAccountResumeService,
  createAccountSaveActions,
  machine as AccountMachine,
} from '../../machines/RedeemInvitation/AccountMachine';
import {
  machine as PasswordMachine,
  createPasswordResumeService,
  createPasswordSaveActions,
} from '../../machines/RedeemInvitation/PasswordMachine';
import { RedeemBoardInvitationController } from './RedeemBoardInvitationController';
import { useGraphQLClient } from '../../components/GraphQLClientProvider';
import { createGetRedeemBoardInvitationContext } from '../../promises/getRedeemBoardInvitationContext';
import { createMemoryRedeemBoardInvitationStorage } from '../../machines/RedeemBoardInvitation/storage';
import { createBrowserRedeemBoardInvitationHistory } from '../../machines/RedeemBoardInvitation/history';
import { parseCompleteRedeemBoardInvitationState } from '../../machines/RedeemBoardInvitation/state';
import {
  createSaveActions,
  createResumeService,
  createRestartService,
} from '../../machines/RedeemBoardInvitation/Machine';
import { useAuth } from '../../components/AuthProvider';
import { withDelay } from '../../utils/withDelay';
import { redeemBoardInvitationPromise } from '../../promises/redeemInvitationPromise';
import { linkBoardMemberInvitationPromise } from '../../promises/linkInvitationPromise';

const storage = createMemoryRedeemBoardInvitationStorage();
const history = createBrowserRedeemBoardInvitationHistory();

export const RedeemBoardInvitation = () => {
  const client = useGraphQLClient();
  const navigate = useNavigate();
  const tag = useParams().tag ?? '';
  const { signIn } = useAuth();

  return (
    <RedeemBoardInvitationController
      options={{
        actions: {
          signIn: (_, { data }) => {
            if (data.message === 'signed-in') {
              signIn(data.id, data.roles, data.artists);
              navigate('/');
            }
          },
          ...createSaveActions(storage, history),
        },
        services: {
          getRedeemBoardInvitationContext: withDelay(
            createGetRedeemBoardInvitationContext(client, { tag })
          ),
          redeemBoardInvitation: withDelay(() =>
            parseCompleteRedeemBoardInvitationState(storage.get()).then(
              redeemBoardInvitationPromise(tag)
            )
          ),
          linkBoardMemberInvitation: withDelay(
            linkBoardMemberInvitationPromise(tag)
          ),
          passwordMachine: PasswordMachine.withConfig({
            services: {
              resume: createPasswordResumeService(storage),
            },
            actions: createPasswordSaveActions(storage),
          }),
          accountMachine: AccountMachine.withConfig({
            services: { resume: createAccountResumeService(storage) },
            actions: createAccountSaveActions(storage),
          }),
          resume: createResumeService(storage),
          restart: createRestartService(storage, history),
        },
      }}
    />
  );
};
