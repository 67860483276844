import { TourDateInput } from '../graphql/operations';
import { CompleteTourDate } from '../schemas/tourDates/tourDateSchema';
import { parsePositiveFloat } from './parsePositiveFloat';
import { parsePositiveInteger } from './parsePositiveInteger';

export function prepareTourDate({
  venueCapacity,
  averageTicketPrice,
  paymentGuarantee,
  date,
  tourDateCountry: { id: tourDateCountryId },
  tourDateType: { id: tourDateTypeId },
  ...rest
}: CompleteTourDate): TourDateInput {
  return {
    venueCapacity: parsePositiveInteger(venueCapacity),
    averageTicketPrice: parsePositiveFloat(averageTicketPrice).toString(),
    paymentGuarantee: parsePositiveFloat(paymentGuarantee).toString(),
    date: date.toString(),
    tourDateCountryId,
    tourDateTypeId,
    ...rest,
  };
}
