import { useMachine } from '@xstate/react';
import {
  AddArtistMachineOptions,
  machine,
} from '../../machines/AddArtist/Machine';
import { AddArtistView } from './AddArtistView';

type Props = {
  options: AddArtistMachineOptions;
};

export function AddArtistController({ options }: Props) {
  const [state, send] = useMachine(machine, options);

  return <AddArtistView state={state} send={send} />;
}
