import { uploadFile } from './utils';
import { BoardMeetingBlock } from '../../schemas/boardMeetingBlockSchema';

interface Props {
  applicationId?: string;
  block?: BoardMeetingBlock;
}

export const createUploadFilePromise =
  ({ directory }: { directory: string }) =>
  async ({ applicationId, block }: Props, { files }: { files: FileList }) => {
    const referenceId = applicationId || block?.id;

    if (!referenceId) {
      throw Error('No reference id provided');
    }

    return Promise.all(
      Array.from(files).map(async (file) => {
        return uploadFile({
          referenceId,
          directory,
          fileName: file.name,
          file,
        });
      })
    );
  };

export type UploadFilePromise = ReturnType<typeof createUploadFilePromise>;
export type UploadFilePromiseResult = Awaited<ReturnType<UploadFilePromise>>;
