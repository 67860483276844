import { GraphQLClient } from 'graphql-request';
import { Context } from '../../machines/Admin/ReopenClaimsMachine';
import { ReopenApplicationClaimsDocument } from '../../graphql/operations';

export const createReopenClaimsPromise =
  (client: GraphQLClient) =>
  async ({ applicationId, notes }: Context) => {
    const request = await client.request(ReopenApplicationClaimsDocument, {
      applicationId,
      notes: notes ?? '',
    });

    return request.reopenApplicationClaims?.reopenApplicationClaimsResponse
      ?.code;
  };

export type CreateReopenClaimsPromise = ReturnType<
  typeof createReopenClaimsPromise
>;
export type CreateReopenClaimsPromiseResult = Awaited<
  ReturnType<CreateReopenClaimsPromise>
>;
