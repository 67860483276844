import { Icon, IconName } from '../components/Icon';
import cn from 'classnames';
import { match, P } from 'ts-pattern';
import { ButtonLinkWrapper } from './ButtonLinkWrapper';

import styles from './Button.module.scss';

export type ButtonVariant =
  | 'primary'
  | 'secondary'
  | 'neutral'
  | 'warning'
  | 'addnew'
  | 'underline';

type Size = 'large' | 'small';
type Length = 'auto' | 'full';

export interface ButtonProps {
  variant?: ButtonVariant;
  size?: Size;
  length?: Length;
  label?: string;
  onClick?: () => void;
  linkTo?: string;
  disabled?: boolean;
  startIcon?: IconName;
  endIcon?: IconName;
  className?: string;
  buttonClassName?: string;
  disabledClassName?: string;
  type?: React.ButtonHTMLAttributes<HTMLButtonElement>['type'];
  id?: string;
}

export function Button({
  variant = 'primary',
  size = 'large',
  length = 'full',
  label,
  onClick,
  disabled = false,
  startIcon,
  endIcon,
  className,
  buttonClassName,
  disabledClassName,
  type = 'button',
  linkTo,
  id,
}: ButtonProps) {
  return (
    <div className={cn(styles.container, className)} id={id}>
      <ButtonLinkWrapper
        href={linkTo}
        type={type}
        disabled={disabled}
        className={cn(
          styles[size],
          styles[variant],
          {
            [styles.button]: variant !== 'underline',
            [styles.buttonLink]: linkTo,
            [styles.disabled]: disabled,
            [styles.full]: length === 'full',
          },
          buttonClassName,
          disabled && disabledClassName
        )}
        onClick={onClick}
      >
        {match(variant)
          .with(P.union('primary', 'secondary', 'neutral', 'warning'), () => (
            <>
              {startIcon && <Icon name={startIcon} />}
              {label}
              {endIcon && <Icon name={endIcon} />}
            </>
          ))
          .with('addnew', () => (
            <div className={cn(styles.addnewContainer)}>
              <div className={cn(styles.addnewIconContainer)}>
                <Icon className={cn(styles.addIcon)} name="plus" label="" />
              </div>
              <div
                className={cn(styles.addnewLabel, {
                  [styles.addnewDisabledText]: disabled,
                })}
              >
                {label}
              </div>
            </div>
          ))
          .with('underline', () => label)
          .exhaustive()}
      </ButtonLinkWrapper>
    </div>
  );
}
