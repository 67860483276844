import {
  ReleaseSinglesMachineSender,
  ReleaseSinglesMachineState,
} from '../../../machines/Release/ReleaseSinglesMachine';
import { Button } from '../../Button';
import { Single } from './Single';
import { SingleAddFormController } from './SingleAddFormController';
import { SingleUpdateFormController } from './SingleUpdateFormController';
import styles from './SinglesView.module.scss';
import { ErrorFeedback } from '../../ErrorFeedback';
import { useIsAdmin } from '../../../hooks/useIsAdmin';

interface SinglesViewProps {
  state: ReleaseSinglesMachineState;
  send: ReleaseSinglesMachineSender;
}

export function SinglesView({ state, send }: SinglesViewProps) {
  const isAdmin = useIsAdmin();
  const canAddSingle = state.context.isOwner || isAdmin;

  if (state.matches('ready.error.network')) {
    return <ErrorFeedback type="network" />;
  }

  if (state.matches('ready.error')) {
    return <ErrorFeedback />;
  }

  return (
    <>
      {state.context.singles.map((single) =>
        single.editRef ? (
          <SingleUpdateFormController key={single.id} actor={single.editRef} />
        ) : (
          <Single
            id={single.id}
            key={single.id}
            title={single.title}
            spins={single.spins}
            onEditClick={() => send({ type: 'EDIT_SINGLE', id: single.id })}
            onRemoveClick={() => send({ type: 'DELETE_SINGLE', id: single.id })}
          />
        )
      )}
      {state.context.singleAddRefs.map((singleAddRef) => {
        return (
          <SingleAddFormController key={singleAddRef.id} actor={singleAddRef} />
        );
      })}
      {canAddSingle && (
        <Button
          buttonClassName={styles.button}
          variant="addnew"
          label="Add Single"
          onClick={() => send('ADD_SINGLE')}
        />
      )}
    </>
  );
}
