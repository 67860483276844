import { match } from 'ts-pattern';
import { Loading } from '../../../components/Loading';
import { EmailController } from '../../../components/Eligibility/Orion/Email/EmailController';
import {
  OrionEligibilityMachineSender,
  OrionEligibilityMachineState,
} from '../../../machines/Eligibility/Orion/Machine';
import { EmailInvitationMachineActor } from '../../../machines/Eligibility/EmailInvitationMachine';
import { ErrorPage } from '../../ErrorPage';

interface EligibilityViewProps {
  state: OrionEligibilityMachineState;
  send: OrionEligibilityMachineSender;
}

export function EligibilityView({ state }: EligibilityViewProps) {
  return (
    <div className="max-w-lg">
      {match(state)
        .when(
          () => state.matches('error'),
          () => <ErrorPage />
        )
        .when(
          () => state.matches('init'),
          () => <Loading />
        )
        .when(
          () => state.matches('email'),
          () => (
            <EmailController
              actor={
                state.children
                  .emailInvitationMachine as EmailInvitationMachineActor
              }
            />
          )
        )
        .run()}
    </div>
  );
}
