import { TourDatePatch } from '../graphql/operations';
import { TourDate } from '../schemas/tourDates/tourDateSchema';

export type UpdateTourDate = {
  type: 'UPDATE_TOUR_DATE';
  id: TourDate['id'];
  tourDate: TourDatePatch;
};

export const updateTourDate = (
  id: UpdateTourDate['id'],
  tourDate: UpdateTourDate['tourDate']
): UpdateTourDate => ({ type: 'UPDATE_TOUR_DATE', id, tourDate });
