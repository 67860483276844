import { useGraphQLClient } from '../../../components/GraphQLClientProvider';
import { useWindowSize } from '../../../hooks/useWindowSize';
import { createContext } from '../../../machines/Admin/AccountsMachine';
import { createSearchAccountsPromise } from '../../../promises/Admin/createSearchAccountsPromise';
import { withDelay } from '../../../utils/withDelay';
import { AccountsController } from './AccountsController';

export const Accounts = () => {
  const client = useGraphQLClient();
  const { screen } = useWindowSize();

  return (
    <AccountsController
      options={{
        context: createContext([], 0, screen === 'mobile' ? 10 : 20),
        services: {
          searchAccountsPromise: withDelay(createSearchAccountsPromise(client)),
        },
      }}
    />
  );
};
