import { GraphQLClient } from 'graphql-request';
import {
  ResubmitApplicationClaimsDocument,
  ResubmitApplicationClaimsMutationVariables,
} from '../graphql/operations';

export const createResubmitApplicationClaimsPromise =
  (client: GraphQLClient) =>
  ({ applicationId }: ResubmitApplicationClaimsMutationVariables) =>
    client.request(ResubmitApplicationClaimsDocument, { applicationId });

export type ResubmitApplicationClaimsPromise = ReturnType<
  typeof createResubmitApplicationClaimsPromise
>;
export type ResubmitApplicationClaimsPromiseResult = Awaited<
  ReturnType<ResubmitApplicationClaimsPromise>
>;
