import { assign, createMachine } from 'xstate';
import type {
  EventFrom,
  MachineOptionsFrom,
  Sender,
  ActorRefFrom,
} from 'xstate';
import { BackEvent, NextEvent, back, next } from '../navigation';
import { match } from 'ts-pattern';
import { sendParent } from 'xstate/lib/actions';
import { isPhoneNumberValid } from '../../utils/isPhoneNumberValid';
import { StateFrom } from '../../utils/StateFrom';
import { RedeemInvitationStorage } from './storage';
import { createReplay, ReplayableEvent } from '../../utils/replay';
import { Context as ParentContext } from './Context';

type FocusNameEvent = ReplayableEvent<{ type: 'FOCUS_NAME' }>;
type FocusPhoneNumberEvent = ReplayableEvent<{ type: 'FOCUS_PHONE_NUMBER' }>;
type FocusAddressEvent = ReplayableEvent<{ type: 'FOCUS_ADDRESS' }>;

type UpdateNameEvent = ReplayableEvent<{ type: 'UPDATE_NAME'; value: string }>;

type UpdatePhoneNumberEvent = ReplayableEvent<{
  type: 'UPDATE_PHONE_NUMBER';
  value: string;
}>;

type UpdateAddressEvent = ReplayableEvent<{
  type: 'UPDATE_ADDRESS';
  value: string;
}>;

type BlurNameEvent = ReplayableEvent<{ type: 'BLUR_NAME' }>;
type BlurPhoneNumberEvent = ReplayableEvent<{ type: 'BLUR_PHONE_NUMBER' }>;
type BlurAddressEvent = ReplayableEvent<{ type: 'BLUR_ADDRESS' }>;

type ReplayableEvents =
  | FocusNameEvent
  | FocusPhoneNumberEvent
  | FocusAddressEvent
  | UpdateNameEvent
  | UpdatePhoneNumberEvent
  | UpdateAddressEvent
  | BlurNameEvent
  | BlurPhoneNumberEvent
  | BlurAddressEvent;

type Events = NextEvent | BackEvent | ReplayableEvents;

type Context = {
  name: string;
  phoneNumber: string;
  address: string;
  isOrion: boolean;
};

export const createAccountContext = ({
  isOrion,
}: Pick<ParentContext, 'isOrion'>): Context => ({
  name: '',
  phoneNumber: '',
  address: '',
  isOrion,
});

export const machine = createMachine(
  {
    predictableActionArguments: true,
    tsTypes: {} as import('./AccountMachine.typegen').Typegen0,
    schema: {
      events: {} as Events,
      context: {} as Context,
    },
    initial: 'form',
    invoke: {
      id: 'resume',
      src: 'resume',
    },
    states: {
      form: {
        type: 'parallel',
        states: {
          name: {
            initial: 'pristine',
            states: {
              pristine: { on: { NEXT: 'invalid' } },
              valid: { type: 'final' },
              invalid: {},
            },
            on: {
              UPDATE_NAME: { actions: ['setName', 'saveName'] },
              BLUR_NAME: [
                { cond: 'isEmpty', target: '.invalid' },
                { target: '.valid' },
              ],
            },
          },
          'phone-number': {
            initial: 'pristine',
            states: {
              pristine: { on: { NEXT: 'invalid' } },
              valid: { type: 'final' },
              invalid: {
                initial: 'empty',
                states: {
                  empty: {},
                  value: {},
                },
              },
            },
            on: {
              UPDATE_PHONE_NUMBER: {
                actions: ['setPhoneNumber', 'savePhoneNumber'],
              },
              BLUR_PHONE_NUMBER: [
                { cond: 'isEmpty', target: '.invalid.empty' },
                { cond: 'isPhoneNumberValid', target: '.valid' },
                { target: '.invalid.value' },
              ],
            },
          },
          address: {
            initial: 'pristine',
            states: {
              pristine: {
                on: {
                  NEXT: 'invalid',
                },
              },
              valid: {
                type: 'final',
              },
              invalid: {},
            },
            on: {
              UPDATE_ADDRESS: { actions: ['setAddress', 'saveAddress'] },
              BLUR_ADDRESS: [
                { cond: 'isEmpty', target: '.invalid' },
                { target: '.valid' },
              ],
            },
          },
          // We have to make 'pulse' and 'on' sibling nodes because final
          // states can not have any children. We need them to be final
          // states so that we can transition to the complete state when
          // the form is valid.
          warning: {
            initial: 'off',
            states: {
              on: {
                type: 'final',
                on: { NEXT: 'pulse' },
              },
              off: {
                type: 'final',
                on: { NEXT: 'on' },
              },
              pulse: {
                type: 'final',
                after: { 1000: 'on' },
              },
            },
          },
        },
        onDone: 'complete',
      },
      complete: {
        on: {
          FOCUS_NAME: {
            target: [
              'form.name.pristine',
              'form.phone-number.valid',
              'form.address.valid',
            ],
          },
          FOCUS_PHONE_NUMBER: {
            target: [
              'form.name.valid',
              'form.phone-number.pristine',
              'form.address.valid',
            ],
          },
          FOCUS_ADDRESS: {
            target: [
              'form.name.valid',
              'form.phone-number.valid',
              'form.address.pristine',
            ],
          },
          NEXT: { actions: 'next' },
        },
      },
    },
    on: { BACK: { actions: 'back' } },
  },
  {
    guards: {
      isEmpty: ({ name, phoneNumber, address }, { type }) =>
        match(type)
          .with('BLUR_NAME', () => name)
          .with('BLUR_PHONE_NUMBER', () => phoneNumber)
          .with('BLUR_ADDRESS', () => address)
          .exhaustive()
          .trim() === '',
      isPhoneNumberValid: ({ phoneNumber }) => isPhoneNumberValid(phoneNumber),
    },
    actions: {
      next: sendParent(next()),
      back: sendParent(back()),
      setName: assign((_, { value: name }) => ({
        name,
      })),
      setPhoneNumber: assign((_, { value: phoneNumber }) => ({
        phoneNumber,
      })),
      setAddress: assign((_, { value: address }) => ({
        address,
      })),
    },
    services: {},
  }
);

export const createAccountResumeService =
  (storage: RedeemInvitationStorage) => () => (send: AccountMachineSender) => {
    const replay = createReplay<ReplayableEvents>();
    const { account } = storage.get();

    (
      [
        [account?.name, 'FOCUS_NAME', 'UPDATE_NAME', 'BLUR_NAME'],
        [
          account?.phoneNumber,
          'FOCUS_PHONE_NUMBER',
          'UPDATE_PHONE_NUMBER',
          'BLUR_PHONE_NUMBER',
        ],
        [account?.address, 'FOCUS_ADDRESS', 'UPDATE_ADDRESS', 'BLUR_ADDRESS'],
      ] satisfies Array<
        [
          string | undefined,
          ReplayableEvents['type'],
          ReplayableEvents['type'],
          ReplayableEvents['type']
        ]
      >
    ).forEach(([value, focus, type, blur]) => {
      if (value !== undefined) {
        send(replay({ type: focus }));
        send(replay({ type, value }));
        send(replay({ type: blur }));
      }
    });
  };

export const createAccountSaveActions = (storage: RedeemInvitationStorage) => ({
  saveName: (_: Context, { value: name, replay }: UpdateNameEvent) =>
    storage.add({ account: { name } }, replay),
  savePhoneNumber: (
    _: Context,
    { value: phoneNumber, replay }: UpdatePhoneNumberEvent
  ) => storage.add({ account: { phoneNumber } }, replay),
  saveAddress: (_: Context, { value: address, replay }: UpdateAddressEvent) =>
    storage.add({ account: { address } }, replay),
});

type Machine = typeof machine;

export type AccountMachineState = StateFrom<Machine>;
export type AccountMachineSender = Sender<EventFrom<Machine>>;
export type AccountMachineOptions = MachineOptionsFrom<Machine, true>;
export type AccountMachineActor = ActorRefFrom<Machine>;
