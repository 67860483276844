import { useActor } from '@xstate/react';
import { ReleaseMasterOwnershipMachineActor } from '../../../machines/Release/ReleaseMasterOwnershipMachine';
import { ReleaseMasterOwnershipView } from './ReleaseMasterOwnershipView';

export function ReleaseMasterOwnershipController({
  actor,
}: {
  actor: ReleaseMasterOwnershipMachineActor;
}) {
  const [state, send] = useActor(actor);

  return <ReleaseMasterOwnershipView state={state} send={send} />;
}
