import { match } from 'ts-pattern';
import classNames from 'classnames';

import {
  SalesMachineState,
  SalesMachineSender,
} from '../../../machines/Eligibility/SalesMachine';

import { Button } from '../../Button';
import { Feedback } from '../../Feedback';
import { FormField } from '../../FormField';
import { PageHeader } from '../../PageHeader';
import { RangeSlider } from '../../RangeSlider';
import { Select } from '../../Select';
import { TextInput } from '../../TextInput';
import { Link } from '../../Link';
import { Form } from '../../Form';
import { FormControls } from '../../FormControls';

import { formatNumber } from '../../../utils/formatNumber';
import { totalUnits } from '../../../utils/totalUnits';

import styles from './SalesView.module.scss';

type EligibilitySalesViewProps = {
  state: SalesMachineState;
  send: SalesMachineSender;
};

export function SalesView({ state, send }: EligibilitySalesViewProps) {
  const { type } = state.context;
  const { heading, paginationSteps, paginationActive } =
    type === 'add-release'
      ? {
          heading: 'Add release details',
          paginationSteps: 3,
          paginationActive: 2,
        }
      : {
          heading: 'Do your sales meet requirements?',
          paginationSteps: 5,
          paginationActive: 3,
        };

  const { genre, genres, releasesScanned, tracksDownloaded, tracksStreamed } =
    state.context;

  const totalReleasesScanned = formatNumber(totalUnits([releasesScanned]));
  const totalTracksDownloaded = formatNumber(totalUnits([tracksDownloaded]));
  const totalTracksStreamed = formatNumber(totalUnits([tracksStreamed]));
  const total = totalUnits([releasesScanned, tracksDownloaded, tracksStreamed]);

  return (
    <Form
      className={styles.form}
      onSubmit={() => {
        send('NEXT');
      }}
    >
      <PageHeader
        heading={heading}
        paginationSteps={paginationSteps}
        paginationActive={paginationActive}
        includeBackButton={true}
        backButtonOnClick={() => send('BACK')}
      >
        Eligible amounts of scanned, downloaded, and streamed releases depends
        on the genre of your release.
      </PageHeader>
      <div className={styles.formFields}>
        <div className={classNames(styles.innerForm, 'max-w-md')}>
          <FormField
            label="What genre best describes this release?"
            formFieldStatus={
              state.matches('pending.genre.error') ? 'error' : 'default'
            }
            feedbackType={
              state.matches('pending.genre.error') ? 'warning' : 'neutral'
            }
            infoLabel={
              state.matches('pending.genre.error')
                ? 'This field is required.'
                : undefined
            }
          >
            <Select
              id="genres"
              label="Please select a genre"
              items={genres}
              itemToKey={(item) => item?.id}
              selectedItem={genre ?? null}
              initialSelectedItem={genre}
              onChange={(genre) => send({ type: 'SET_GENRE', genre })}
              getItemText={({ title }) => title}
            />
          </FormField>
          <FormField
            label="Scanned releases"
            description="Include both physical and digital."
            formFieldStatus={
              state.matches('pending.releasesScanned.error')
                ? 'error'
                : 'default'
            }
            feedbackType={
              state.matches('pending.releasesScanned.error')
                ? 'warning'
                : 'neutral'
            }
            infoLabel={match(state)
              .when(
                (state) => state.matches('pending.releasesScanned.error.empty'),
                () => 'This field is required.'
              )
              .when(
                (state) =>
                  state.matches('pending.releasesScanned.error.invalid'),
                () => 'Please enter a valid number of units scanned.'
              )
              .otherwise(() => undefined)}
          >
            <div className={styles.row}>
              <TextInput
                id="units_scanned"
                inputMode="numeric"
                value={releasesScanned.value}
                maxLength={12}
                onChange={(value) =>
                  send({ type: 'SET_RELEASES_SCANNED', value })
                }
                onFocus={() => send('FOCUS_RELEASES_SCANNED')}
                onBlur={() => send('BLUR_RELEASES_SCANNED')}
              />
              <p className={styles.cellDivision}></p>
              <p className={styles.cellDivisor}></p>
              <p className={styles.cellEquals}>=</p>
              <p className={styles.cellResult}>{totalReleasesScanned}</p>
            </div>
          </FormField>

          <FormField
            label="Downloaded Tracks"
            formFieldStatus={
              state.matches('pending.tracksDownloaded.error')
                ? 'error'
                : 'default'
            }
            feedbackType={
              state.matches('pending.tracksDownloaded.error')
                ? 'warning'
                : 'neutral'
            }
            infoLabel={match(state)
              .when(
                (state) =>
                  state.matches('pending.tracksDownloaded.error.empty'),
                () => 'This field is required.'
              )
              .when(
                (state) =>
                  state.matches('pending.tracksDownloaded.error.invalid'),
                () => 'Please enter a valid number of tracks downloaded.'
              )
              .otherwise(() => undefined)}
          >
            <div className={styles.row}>
              <TextInput
                id="tracks_downloaded"
                inputMode="numeric"
                value={tracksDownloaded.value}
                maxLength={12}
                onChange={(value) =>
                  send({ type: 'SET_TRACKS_DOWNLOADED', value })
                }
                onFocus={() => send('FOCUS_TRACKS_DOWNLOADED')}
                onBlur={() => send('BLUR_TRACKS_DOWNLOADED')}
              />

              <p className={styles.cellDivision}>÷</p>
              <p className={styles.cellDivisor}>
                {formatNumber(tracksDownloaded.divisor)}
              </p>
              <p className={styles.cellEquals}>=</p>
              <p className={styles.cellResult}>{totalTracksDownloaded}</p>
            </div>
          </FormField>

          <FormField
            label="Streamed Tracks"
            formFieldStatus={
              state.matches('pending.tracksStreamed.error')
                ? 'error'
                : 'default'
            }
            feedbackType={
              state.matches('pending.tracksStreamed.error')
                ? 'warning'
                : 'neutral'
            }
            infoLabel={match(state)
              .when(
                (state) => state.matches('pending.tracksStreamed.error.empty'),
                () => 'This field is required.'
              )
              .when(
                (state) =>
                  state.matches('pending.tracksStreamed.error.invalid'),
                () => 'Please enter a valid number of tracks streamed.'
              )
              .otherwise(() => undefined)}
          >
            <div className={styles.row}>
              <TextInput
                id="tracks_streamed"
                inputMode="numeric"
                value={tracksStreamed.value}
                maxLength={12}
                onChange={(value) =>
                  send({ type: 'SET_TRACKS_STREAMED', value })
                }
                onFocus={() => send('FOCUS_TRACKS_STREAMED')}
                onBlur={() => send('BLUR_TRACKS_STREAMED')}
              />

              <p className={styles.cellDivision}>÷</p>
              <p className={styles.cellDivisor}>
                {formatNumber(tracksStreamed.divisor)}
              </p>
              <p className={styles.cellEquals}>=</p>
              <p className={styles.cellResult}>{totalTracksStreamed}</p>
            </div>
          </FormField>
          <hr />
          <div className={styles.total}>
            <p className={styles.cellTitle}>Total units for eligibility</p>
            <p>=</p>
            <p className={styles.cellResult}>{formatNumber(total)}</p>
          </div>
          <>
            {genre !== undefined &&
              state.matches('complete.ineligible.status.below') && (
                <Feedback
                  type={type === 'eligibility' ? 'warning' : 'neutral'}
                  size="compact"
                >
                  Releases in the {genre.title} genre must have at least{' '}
                  {formatNumber(genre.minimumSales)} units of sales to be
                  eligible.
                </Feedback>
              )}
            {genre !== undefined &&
              state.matches('complete.ineligible.status.above') && (
                <Feedback
                  type={type === 'eligibility' ? 'warning' : 'neutral'}
                  size="compact"
                >
                  Releases in the {genre.title} genre must have at most{' '}
                  {formatNumber(genre.maximumSales)} units of sales to be
                  eligible.
                </Feedback>
              )}
          </>
        </div>
        <div className="max-w-lg">
          <RangeSlider
            value={total}
            error={state.matches('complete.ineligible.status')}
            range={
              genre !== undefined
                ? {
                    min: genre.minimumSales,
                    max: genre.maximumSales,
                  }
                : undefined
            }
          />
        </div>
      </div>
      {state.matches('complete.ineligible') && (
        <Feedback
          pulse={state.matches('complete.ineligible.highlight.on')}
          type={type === 'eligibility' ? 'warning' : 'neutral'}
          title="This release is not eligible"
        >
          <p>
            Learn more about the{' '}
            <Link href="/requirements">eligibility requirements</Link>.
          </p>
          <p>
            If you have another release that may be eligible, please update the
            form above.
          </p>
        </Feedback>
      )}
      <FormControls className="max-w-md">
        <Button
          type="submit"
          variant="primary"
          endIcon="rightChevron"
          label="Next"
        />
      </FormControls>
    </Form>
  );
}
