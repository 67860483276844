import { ReactNode } from 'react';

import styles from './InlineBox.module.scss';

interface InlineBoxProps {
  children: ReactNode;
}

export function InlineBox({ children }: InlineBoxProps) {
  return (
    <div className={styles.container}>
      <div className={styles.innerContainer}>{children}</div>
    </div>
  );
}
