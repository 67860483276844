import { Footer } from '../components/Footer';
import { HeroSection } from '../components/HeroSection';
import cn from 'classnames';

import styles from './BoardOfDirectors.module.scss';

export function BoardOfDirectors() {
  return (
    <div className={styles.container}>
      <HeroSection
        title="Board of Directors"
        breadcrumbs={[{ text: 'Home', to: '/' }]}
      />
      <div className={cn('max-w-xl', styles.grid)}>
        <div className={styles.card}>
          <h3>Lenore Gibson (Chair)</h3>
          <p>Assistant General Counsel, Bell</p>
          <p>Ottawa, Ontario</p>
        </div>
        <div className={styles.card}>
          <h3>Shauna De Cartier (Vice Chair)</h3>
          <p>Founder Six Shooter Records Inc.</p>
          <p>Toronto, Ontario</p>
        </div>
        <div className={styles.card}>
          <h3>Julie Penner (Treasurer)</h3>
          <p>CBC Radio Producer and Musician</p>
          <p>Winnipeg, Manitoba</p>
        </div>
        <div className={styles.card}>
          <h3>Jill Barber (Artist Representative)</h3>
          <p>Singer, Songwriter</p>
          <p>Vancouver, British Columbia</p>
        </div>
        <div className={styles.card}>
          <h3>Dan Barton</h3>
          <p>Stingray Radio</p>
          <p>Director of Programming – Maritimes</p>
          <p>Halifax, Nova Scotia</p>
        </div>
        <div className={styles.card}>
          <h3>Tammy Cole</h3>
          <p>FM Program Director Corus Entertainment Inc.</p>
          <p>Toronto, Ontario</p>
        </div>
        <div className={styles.card}>
          <h3>Al Ford</h3>
          <p>Rogers Media Inc. Program Director SONiC 102.9</p>
          <p>Edmonton, Alberta</p>
        </div>
        <div className={styles.card}>
          <h3>Simon Mortimer-Lamb</h3>
          <p>President & COO Nettwerk Music Group</p>
          <p>Vancouver, British Columbia</p>
        </div>
        <div className={styles.card}>
          <h3>Jeffrey Remedios</h3>
          <p>Chairman & CEO Universal Music Canada</p>
          <p>Toronto, Ontario</p>
        </div>
        <div className={styles.card}>
          <h3>Kesi Smyth</h3>
          <p>General Manager/Human Resources 604 Records</p>
          <p>Vancouver, British Columbia</p>
        </div>
        <div className={styles.card}>
          <h3>Alanna Stuart</h3>
          <p>Music Artist</p>
          <p>Toronto, Ontario</p>
        </div>
      </div>
      <Footer />
    </div>
  );
}
