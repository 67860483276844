import { match } from 'ts-pattern';
import cn from 'classnames';
import { Feedback } from './Feedback';
import styles from './ErrorFeedback.module.scss';

interface ErrorFeedbackProps {
  type?:
    | 'network'
    | 'unknown'
    | 'badEmail'
    | 'noEmail'
    | 'noPassword'
    | 'credentials';
  className?: string;
  pulse?: boolean;
}

export function ErrorFeedback({
  type = 'unknown',
  className,
  pulse,
}: ErrorFeedbackProps) {
  return (
    <Feedback
      type="warning"
      title={match(type)
        .with('unknown', () => 'Something went wrong.')
        .with('network', () => 'A network error has occurred.')
        .otherwise(() => '')}
      className={cn([styles.text, className])}
      pulse={pulse}
    >
      {match(type)
        .with(
          'unknown',
          () =>
            'Please refresh the page and try again. If the problem continues, please contact us with a description of what happened.'
        )
        .with(
          'network',
          () => 'Please check your internet connection and try again.'
        )
        .with('noEmail', () => 'An email address is required.')
        .with(
          'badEmail',
          () => 'This is not a valid format for an email address.'
        )
        .with('noPassword', () => 'A password is required.')
        .with('credentials', () => 'Invalid email or password.')
        .exhaustive()}
    </Feedback>
  );
}
