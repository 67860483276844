import { match } from 'ts-pattern';
import classNames from 'classnames';

import { ReactComponent as Home } from '../assets/icons/home.svg';
import { ReactComponent as User } from '../assets/icons/user.svg';
import { ReactComponent as CheckCircle } from '../assets/icons/check-circle.svg';
import { ReactComponent as InfoCircle } from '../assets/icons/info-circle.svg';
import { ReactComponent as HelpCircle } from '../assets/icons/help-circle.svg';
import { ReactComponent as Help } from '../assets/icons/help.svg';
import { ReactComponent as LeftChevron } from '../assets/icons/left_chevron.svg';
import { ReactComponent as RightChevron } from '../assets/icons/right_chevron.svg';
import { ReactComponent as DownChevron } from '../assets/icons/down_chevron.svg';
import { ReactComponent as UpChevron } from '../assets/icons/up_chevron.svg';
import { ReactComponent as Plus } from '../assets/icons/plus.svg';
import { ReactComponent as WarningCircle } from '../assets/icons/warning-circle.svg';
import { ReactComponent as AlertTriangle } from '../assets/icons/alert-triangle.svg';
import { ReactComponent as File } from '../assets/icons/file.svg';
import { ReactComponent as X } from '../assets/icons/x.svg';
import { ReactComponent as Checkmark } from '../assets/icons/checkmark.svg';
import { ReactComponent as Calendar } from '../assets/icons/calendar.svg';
import { ReactComponent as Document } from '../assets/icons/document.svg';
import { ReactComponent as Music } from '../assets/icons/music.svg';
import { ReactComponent as Dots } from '../assets/icons/dots.svg';
import { ReactComponent as Trash } from '../assets/icons/trash.svg';
import { ReactComponent as EyeOff } from '../assets/icons/eye-off.svg';
import { ReactComponent as EyeOn } from '../assets/icons/eye-open.svg';
import { ReactComponent as Send } from '../assets/icons/send.svg';
import { ReactComponent as UserCheck } from '../assets/icons/user-check.svg';
import { ReactComponent as Star } from '../assets/icons/star.svg';
import { ReactComponent as Users } from '../assets/icons/users.svg';
import { ReactComponent as Upload } from '../assets/icons/upload.svg';
import { ReactComponent as Mail } from '../assets/icons/mail.svg';
import { ReactComponent as Attachment } from '../assets/icons/attachment.svg';
import { ReactComponent as AlertCircle } from '../assets/icons/alert-circle.svg';
import { ReactComponent as Edit } from '../assets/icons/edit.svg';
import { ReactComponent as Clock } from '../assets/icons/clock.svg';
import { ReactComponent as Radio } from '../assets/icons/radio.svg';
import { ReactComponent as ArrowRight } from '../assets/icons/arrow-right.svg';
import { ReactComponent as Download } from '../assets/icons/download.svg';
import { ReactComponent as List } from '../assets/icons/list.svg';
import { ReactComponent as Dashboard } from '../assets/icons/dashboard.svg';
import { ReactComponent as Hamburger } from '../assets/icons/hamburger.svg';
import { ReactComponent as Requirements } from '../assets/icons/requirements.svg';
import { ReactComponent as Phone } from '../assets/icons/phone.svg';
import { ReactComponent as Folder } from '../assets/icons/folder.svg';
import { ReactComponent as Circle } from '../assets/icons/circle.svg';
import { ReactComponent as Spinner } from '../assets/icons/spinner.svg';
import { ReactComponent as EditPencil } from '../assets/icons/edit-pencil.svg';
import { ReactComponent as Award } from '../assets/icons/award.svg';
import { ReactComponent as FilePlus } from '../assets/icons/file-plus.svg';
import { ReactComponent as Divide } from '../assets/icons/divide.svg';
import { ReactComponent as Briefcase } from '../assets/icons/briefcase.svg';
import { ReactComponent as Shield } from '../assets/icons/shield.svg';
import { ReactComponent as Target } from '../assets/icons/target.svg';
import { ReactComponent as DollarSign } from '../assets/icons/dollar-sign.svg';
import { ReactComponent as Lock } from '../assets/icons/lock.svg';
import { ReactComponent as XCircle } from '../assets/icons/x-circle.svg';
import { ReactComponent as Sort } from '../assets/icons/sort.svg';
import { ReactComponent as Search } from '../assets/icons/search.svg';
import { ReactComponent as Bell } from '../assets/icons/bell.svg';
import { ReactComponent as MenuItems } from '../assets/icons/menu-items.svg';
import { ReactComponent as ExternalLink } from '../assets/icons/external-link.svg';
import { ReactComponent as CD } from '../assets/icons/cd.svg';
import { ReactComponent as Settings } from '../assets/icons/settings.svg';
import { ReactComponent as Logout } from '../assets/icons/log-out.svg';
import { ReactComponent as Celebration } from '../assets/icons/celebration.svg';
import { ReactComponent as CreditCard } from '../assets/icons/credit-card.svg';
import { ReactComponent as Zap } from '../assets/icons/zap.svg';
import { ReactComponent as Unsubscribe } from '../assets/icons/unsubscribe.svg';
import { ReactComponent as BreadcrumbArrow } from '../assets/icons/breadcrumb-arrow.svg';

import styles from './Icon.module.scss';

export type IconName =
  | 'home'
  | 'user'
  | 'checkCircle'
  | 'infoCircle'
  | 'helpCircle'
  | 'help'
  | 'leftChevron'
  | 'rightChevron'
  | 'downChevron'
  | 'upChevron'
  | 'plus'
  | 'warningCircle'
  | 'alertTriangle'
  | 'x'
  | 'checkmark'
  | 'file'
  | 'calendar'
  | 'list'
  | 'dollarSign'
  | 'document'
  | 'music'
  | 'dots'
  | 'trash'
  | 'eyeOff'
  | 'eyeOn'
  | 'send'
  | 'userCheck'
  | 'star'
  | 'users'
  | 'upload'
  | 'attachment'
  | 'mail'
  | 'alertCircle'
  | 'edit'
  | 'clock'
  | 'radio'
  | 'arrowRight'
  | 'dashboard'
  | 'hamburger'
  | 'requirements'
  | 'phone'
  | 'folder'
  | 'circle'
  | 'spinner'
  | 'editPencil'
  | 'award'
  | 'download'
  | 'list'
  | 'filePlus'
  | 'divide'
  | 'briefcase'
  | 'shield'
  | 'target'
  | 'dollarSign'
  | 'lock'
  | 'xCircle'
  | 'bell'
  | 'sort'
  | 'search'
  | 'bell'
  | 'menuItems'
  | 'externalLink'
  | 'cd'
  | 'settings'
  | 'logout'
  | 'creditCard'
  | 'zap'
  | 'celebration'
  | 'unsubscribe'
  | 'breadcrumbArrow';

interface IconProps {
  name: IconName;
  className?: string;
  labelClassName?: string;
  label?: string;
  labelIsAlt?: boolean;
  width?: number;
  height?: number;
  ariaLabel?: string;
  bubble?: boolean;
  onClick?: () => void;
}

export const Icon = ({
  name,
  className,
  labelClassName,
  label,
  labelIsAlt = true,
  width = 24,
  height = 24,
  ariaLabel,
  bubble,
  onClick,
}: IconProps) => {
  const Wrapper = onClick === undefined ? 'div' : 'button';

  const props = {
    title: labelIsAlt ? label : undefined,
    ...(width && { width }),
    ...(height && { height }),
    ...(onClick !== undefined && { type: 'button' }),
  };

  return (
    <Wrapper
      className={classNames(
        {
          [styles.button]: onClick,
          [styles.icon]: label,
          [styles.bubble]: bubble,
        },
        className
      )}
      {...(ariaLabel && { 'aria-label': ariaLabel })}
      onClick={onClick}
    >
      {match(name)
        .with('home', () => <Home {...props} />)
        .with('user', () => <User {...props} />)
        .with('checkCircle', () => <CheckCircle {...props} />)
        .with('infoCircle', () => <InfoCircle {...props} />)
        .with('helpCircle', () => <HelpCircle {...props} />)
        .with('help', () => <Help {...props} />)
        .with('leftChevron', () => <LeftChevron {...props} />)
        .with('rightChevron', () => <RightChevron {...props} />)
        .with('plus', () => <Plus {...props} />)
        .with('warningCircle', () => <WarningCircle {...props} />)
        .with('alertTriangle', () => <AlertTriangle {...props} />)
        .with('x', () => <X {...props} />)
        .with('file', () => <File {...props} />)
        .with('checkmark', () => <Checkmark {...props} />)
        .with('downChevron', () => <DownChevron {...props} />)
        .with('upChevron', () => <UpChevron {...props} />)
        .with('calendar', () => <Calendar {...props} />)
        .with('dots', () => <Dots {...props} />)
        .with('trash', () => <Trash {...props} />)
        .with('eyeOff', () => <EyeOff {...props} />)
        .with('eyeOn', () => <EyeOn {...props} />)
        .with('send', () => <Send {...props} />)
        .with('userCheck', () => <UserCheck {...props} />)
        .with('star', () => <Star {...props} />)
        .with('users', () => <Users {...props} />)
        .with('upload', () => <Upload {...props} />)
        .with('mail', () => <Mail {...props} />)
        .with('attachment', () => <Attachment {...props} />)
        .with('alertCircle', () => <AlertCircle {...props} />)
        .with('edit', () => <Edit {...props} />)
        .with('clock', () => <Clock {...props} />)
        .with('radio', () => <Radio {...props} />)
        .with('folder', () => <Folder {...props} />)
        .with('arrowRight', () => <ArrowRight {...props} />)
        .with('download', () => <Download {...props} />)
        .with('dashboard', () => <Dashboard {...props} />)
        .with('hamburger', () => <Hamburger {...props} />)
        .with('requirements', () => <Requirements {...props} />)
        .with('phone', () => <Phone {...props} />)
        .with('list', () => <List {...props} />)
        .with('dollarSign', () => <DollarSign {...props} />)
        .with('document', () => <Document {...props} />)
        .with('music', () => <Music {...props} />)
        .with('circle', () => <Circle {...props} />)
        .with('spinner', () => <Spinner {...props} />)
        .with('editPencil', () => <EditPencil {...props} />)
        .with('award', () => <Award {...props} />)
        .with('filePlus', () => <FilePlus {...props} />)
        .with('divide', () => <Divide {...props} />)
        .with('briefcase', () => <Briefcase {...props} />)
        .with('shield', () => <Shield {...props} />)
        .with('target', () => <Target {...props} />)
        .with('lock', () => <Lock {...props} />)
        .with('xCircle', () => <XCircle {...props} />)
        .with('sort', () => <Sort {...props} />)
        .with('search', () => <Search {...props} />)
        .with('bell', () => <Bell {...props} />)
        .with('menuItems', () => <MenuItems {...props} />)
        .with('externalLink', () => <ExternalLink {...props} />)
        .with('logout', () => <Logout {...props} />)
        .with('cd', () => <CD {...props} />)
        .with('settings', () => <Settings {...props} />)
        .with('celebration', () => <Celebration {...props} />)
        .with('creditCard', () => <CreditCard {...props} />)
        .with('zap', () => <Zap {...props} />)
        .with('unsubscribe', () => <Unsubscribe {...props} />)
        .with('breadcrumbArrow', () => <BreadcrumbArrow {...props} />)
        .exhaustive()}
      {label && labelIsAlt && (
        <div className={classNames(styles.label, labelClassName)}>{label}</div>
      )}
    </Wrapper>
  );
};
