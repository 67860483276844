import { useMachine } from '@xstate/react';
import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
} from 'react';

import { machine } from '../machines/AuthStatusMachine';
import { Roles } from '../schemas/role';
import { MinimalArtist } from '../schemas/minimalArtistSchema';

type SignInFunction = (
  id: string,
  roles: Roles,
  artists: MinimalArtist[]
) => void;
type SignOutFunction = () => void;

interface AuthContext {
  id: string;
  roles: Roles;
  artists: MinimalArtist[];
  isInitializing: boolean;
  isSignedIn: boolean;
  isSignedOut: boolean;
  isConnectionOk: boolean;
  signIn: SignInFunction;
  signOut: SignOutFunction;
}

const authContext = createContext({} as AuthContext);

export function AuthProvider({ children }: { children: ReactNode }) {
  const auth = useAuthProvider();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

export const useAuth = () => {
  return useContext(authContext);
};

function useAuthProvider(): AuthContext {
  const [state, send] = useMachine(machine);

  const signIn = useCallback(
    ((id, roles, artists) =>
      send({ type: 'SIGNED_IN', id, roles, artists })) satisfies SignInFunction,
    []
  );

  const signOut = useCallback(
    (() => send({ type: 'SIGNED_OUT' })) satisfies SignOutFunction,
    []
  );

  const result = useMemo(
    (): AuthContext => ({
      id: state.context.id,
      roles: state.context.roles,
      artists: state.context.artists,
      isInitializing: state.matches('status.initializing'),
      isSignedIn: state.matches('status.signed-in'),
      isSignedOut: state.matches('status.signed-out'),
      isConnectionOk: state.matches('connection.ok'),
      signIn,
      signOut,
    }),
    [
      state.context.id,
      state.context.roles,
      state.context.artists,
      state.matches('status.initializing'),
      state.matches('status.signed-in'),
      state.matches('status.signed-out'),
      state.matches('connection.ok'),
      signIn,
      signOut,
    ]
  );

  return result;
}
