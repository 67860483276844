import { assign, createMachine } from 'xstate';
import type { EventFrom, MachineOptionsFrom, Sender } from 'xstate';
import { match } from 'ts-pattern';
import type { BackEvent, NextEvent } from '../navigation';
import { createCreateSalesContext } from '../Eligibility/SalesMachine';
import { createCreateReleaseContext } from '../Eligibility/ReleaseMachine';
import { StateFrom } from '../../utils/StateFrom';
import { GetAddReleaseContextPromiseResult } from '../../promises/getAddReleaseContextPromise';

import { Context } from './Context';

import { AddReleaseState } from './state';
import { AddReleaseStorage } from './storage';
import { AddReleaseHistory } from './history';
import { logMachineError } from '../../utils/logError';

type ReplayableEvents = NextEvent | BackEvent;

type Events =
  | ReplayableEvents
  | { type: 'RESUME_RELEASE' }
  | { type: 'RESUME_SALES' }
  | { type: 'RESUME_MASTER_RECORDING' }
  | { type: 'RESTART' };

type Services = {
  getAddReleaseContextPromise: {
    data: GetAddReleaseContextPromiseResult;
  };
};

export const machine = createMachine(
  {
    predictableActionArguments: true,
    tsTypes: {} as import('./Machine.typegen').Typegen0,
    schema: {
      events: {} as Events,
      context: {} as Context,
      services: {} as Services,
    },
    id: 'add-release',
    initial: 'init',
    invoke: {
      id: 'restart',
      src: 'restart',
    },
    states: {
      init: {
        invoke: {
          id: 'getAddReleaseContextPromise',
          src: 'getAddReleaseContextPromise',
          onDone: 'resume',
          onError: 'error',
        },
      },
      error: { entry: 'logMachineError' },
      resume: {
        entry: 'setContext',
        invoke: {
          id: 'resume',
          src: 'resume',
        },
        on: {
          RESUME_RELEASE: 'release',
          RESUME_SALES: 'sales',
          RESUME_MASTER_RECORDING: 'masterRecording',
        },
      },
      release: {
        entry: 'saveReleaseStep',
        invoke: {
          id: 'releaseMachine',
          src: 'releaseMachine',
          data: createCreateReleaseContext('add-release'),
        },
        on: {
          NEXT: 'sales',
        },
      },
      sales: {
        entry: 'saveSalesStep',
        invoke: {
          id: 'salesMachine',
          src: 'salesMachine',
          data: createCreateSalesContext('add-release'),
        },
        on: {
          NEXT: 'masterRecording',
          BACK: 'release',
        },
      },
      masterRecording: {
        entry: 'saveMasterRecordingStep',
        invoke: {
          id: 'masterRecordingMachine',
          src: 'masterRecordingMachine',
        },
        on: {
          NEXT: 'saving',
          BACK: 'sales',
        },
      },
      saving: {
        invoke: {
          id: 'addReleasePromise',
          src: 'addReleasePromise',
          onDone: 'done',
          onError: 'error',
        },
      },
      done: {
        invoke: {
          id: 'reset',
          src: 'reset',
          onDone: { actions: 'navigateToReleases' },
        },
      },
    },
    on: { RESTART: 'init' },
  },
  {
    actions: { logMachineError, setContext: assign((_, { data }) => data) },
  }
);

type Machine = typeof machine;

export type AddReleaseMachineState = StateFrom<Machine>;
export type AddReleaseMachineSender = Sender<EventFrom<Machine>>;
export type AddReleaseMachineOptions = MachineOptionsFrom<Machine, true>;

export const createResumeService =
  (storage: AddReleaseStorage) => () => (send: AddReleaseMachineSender) => {
    const { step } = storage.get();

    match(step)
      .with(undefined, () => send('RESUME_RELEASE'))
      .with('release', () => send('RESUME_RELEASE'))
      .with('sales', () => send('RESUME_SALES'))
      .with('masterRecording', () => send('RESUME_MASTER_RECORDING'))
      .exhaustive();
  };

export const createRestartService =
  (storage: AddReleaseStorage, history: AddReleaseHistory) =>
  () =>
  (send: AddReleaseMachineSender) => {
    history.subscribe((state) => {
      storage.set(state);
      send('RESTART');
    });

    return history.unsubscribe;
  };

const createStepSaver =
  (
    storage: AddReleaseStorage,
    history: AddReleaseHistory,
    step: AddReleaseState['step']
  ) =>
  // Every time the user navigates forwards or backwards in the flow we
  // push an entry onto the history stack. This gives us a snapshot to
  // restore to if the user every decides to use the back and forward
  // buttons on their browser.
  (_: Context, { type }: Events) => {
    // We only want to push history when the user has been the one
    // triggering movement. There are other events used by the resume
    // serivce to restore the state of the flow but those are not
    // triggered by humans in front of the screen and therefore we do
    // not want to push history onto the stack for those events.
    if (type === 'NEXT' || type === 'BACK') {
      // The reason we first replace the current state is because the user
      // will have changed the state on the page since they first entered this
      // state. This allows us to save all their work up till they navigated
      // to the next step.
      history.replace(storage.get());
      storage.add({ step });
      history.push(storage.get());
    }
  };

export const createSaveActions = (
  storage: AddReleaseStorage,
  history: AddReleaseHistory
) => ({
  saveReleaseStep: createStepSaver(storage, history, 'release'),
  saveSalesStep: createStepSaver(storage, history, 'sales'),
  saveMasterRecordingStep: createStepSaver(storage, history, 'masterRecording'),
});
