import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import * as Sentry from '@sentry/react';
import { ErrorPage } from './pages/ErrorPage';
import { PageContainer } from './components/PageContainer';
import { filterSentryErrors } from './utils/filterSentryErrors';

import './index.scss';

Sentry.init({
  dsn: import.meta.env.VITE_SENTRY_DSN,
  environment: import.meta.env.VITE_SENTRY_ENV,
  integrations: [new Sentry.BrowserTracing()],
  tracesSampleRate: 1.0,
  // The above setting ensures that 100% of transactions will be sent to Sentry.
  // Sentry recommends lowering this number in production.
  beforeSend: filterSentryErrors,
  beforeSendTransaction: filterSentryErrors,
});

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <Sentry.ErrorBoundary
      fallback={
        <PageContainer>
          <ErrorPage />
        </PageContainer>
      }
    >
      <App />
    </Sentry.ErrorBoundary>
  </React.StrictMode>
);
