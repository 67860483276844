import { useGraphQLClient } from '../../../components/GraphQLClientProvider';
import { useWindowSize } from '../../../hooks/useWindowSize';
import { createContext } from '../../../machines/Admin/SubscribersMachine';
import { createSearchSubscribersPromise } from '../../../promises/Admin/createSearchSubscribersPromise';
import { createUnsubscribeFromNewsletterPromise } from '../../../promises/createUnsubscribeFromNewsletterPromise';
import { withDelay } from '../../../utils/withDelay';
import { SubscribersController } from './SubscribersController';

export const Subscribers = () => {
  const client = useGraphQLClient();
  const { screen } = useWindowSize();

  return (
    <SubscribersController
      options={{
        context: createContext([], 0, screen === 'mobile' ? 10 : 20),
        services: {
          searchSubscribersPromise: withDelay(
            createSearchSubscribersPromise(client)
          ),
          unsubscribeFromNewsletterPromise:
            createUnsubscribeFromNewsletterPromise(client),
        },
      }}
    />
  );
};
