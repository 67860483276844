import { z } from 'zod';

const completeRedeemAccountInvitationStateSchema = z.object({
  account: z.object({
    name: z.string(),
    phoneNumber: z.string(),
    address: z.string(),
    password: z.string(),
    confirmPassword: z.string(),
  }),
  artist: z.object({
    name: z.string(),
    province: z.string(),
    isSelfManaged: z.boolean(),
  }),
  company: z.object({
    name: z.string(),
    legalStatus: z.string(),
    legalProvince: z.string().optional(),
  }),
  management: z
    .object({
      name: z.string(),
      country: z.string(),
      contactPerson: z.string(),
      address: z.string(),
      phoneNumber: z.string(),
      email: z.string(),
      legalStatus: z.string(),
      legalProvince: z.string().optional(),
    })
    .optional(),
});

const redeemAccountInvitationStateSchema =
  completeRedeemAccountInvitationStateSchema.deepPartial().extend({
    step: z
      .union([
        z.literal('password'),
        z.literal('account'),
        z.literal('artist'),
        z.literal('management'),
      ])
      .optional(),
  });

export type RedeemAccountInvitationState = z.infer<
  typeof redeemAccountInvitationStateSchema
>;
export type CompleteRedeemAccountInvitationState = z.infer<
  typeof completeRedeemAccountInvitationStateSchema
>;

export const parseRedeemAccountInvitationState = (data: unknown) =>
  redeemAccountInvitationStateSchema.parse(data);

export const parseCompleteRedeemAccountInvitationState = (data: unknown) =>
  completeRedeemAccountInvitationStateSchema.parseAsync(data);
