import { useActor } from '@xstate/react';
import { ReopenClaimsMachineActor } from '../../machines/Admin/ReopenClaimsMachine';
import { ApplicationMachineSender } from '../../machines/Application/ApplicationMachine';
import { ReopenClaimsForm } from './ReopenClaimsForm';

interface Props {
  applicationSend: ApplicationMachineSender;
  reopenClaimsActor: ReopenClaimsMachineActor;
}

export function ReopenClaimsFormController({
  applicationSend,
  reopenClaimsActor,
}: Props) {
  const [state, send] = useActor(reopenClaimsActor);

  return (
    <ReopenClaimsForm
      reopenClaimsState={state}
      reopenClaimsSend={send}
      applicationSend={applicationSend}
    />
  );
}
