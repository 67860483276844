import { match } from 'ts-pattern';
import { useAuth } from './AuthProvider';
import { Feedback } from './Feedback';
import styles from './ConnectionStatus.module.scss';

export function ConnectionStatus() {
  const { isConnectionOk, isInitializing, isSignedIn, isSignedOut, id, roles } =
    useAuth();

  return (
    <>
      {isConnectionOk ? (
        <Feedback className={styles.connectionStatus} type="positive">
          <div>
            Status:{' '}
            {match({ isInitializing, isSignedIn, isSignedOut })
              .with({ isInitializing: true }, () => 'Initializing')
              .with({ isSignedIn: true }, () => 'Signed In')
              .with({ isSignedOut: true }, () => 'Signed Out')
              .otherwise(() => 'Unknown Status')}
          </div>
          {isSignedIn && (
            <>
              <div>Roles: {roles.join(', ')}</div>
              <div>ID: {id}</div>
            </>
          )}
        </Feedback>
      ) : (
        <Feedback className={styles.connectionStatus} type="warning">
          Connection Issue...
        </Feedback>
      )}
    </>
  );
}
