import classNames from 'classnames';
import { Button } from '../../Button';
import { PageHeader } from '../../PageHeader';
import {
  AccountMachineSender,
  AccountMachineState,
} from '../../../machines/RedeemInvitation/AccountMachine';
import { FormField } from '../../FormField';
import { TextInput } from '../../TextInput';
import { TextArea } from '../../TextArea';
import { Feedback } from '../../Feedback';
import { match } from 'ts-pattern';
import { Form } from '../../Form';
import { FormControls } from '../../FormControls';

import styles from './AccountView.module.scss';

interface AccountInfoViewProps {
  state: AccountMachineState;
  send: AccountMachineSender;
}

export function AccountView({ state, send }: AccountInfoViewProps) {
  return (
    <Form className={styles.container} onSubmit={() => send('NEXT')}>
      <PageHeader
        heading="Set up your account"
        paginationActive={2}
        paginationSteps={3}
        includeBackButton={true}
        backButtonOnClick={() => send('BACK')}
        isOrion={state.context.isOrion}
      >
        Tell us a bit about yourself.
      </PageHeader>
      <div className={classNames(styles.formFields, 'max-w-md')}>
        <FormField
          id="name"
          label="Your name"
          description="Please provide your personal name, NOT your artist/group name"
          formFieldStatus={
            state.matches('form.name.invalid') ? 'error' : 'default'
          }
          feedbackType="warning"
          infoLabel={
            state.matches('form.name.invalid') ? 'This field is required.' : ''
          }
        >
          <TextInput
            id="name"
            label="name"
            value={state.context.name}
            onFocus={() => send('FOCUS_NAME')}
            onChange={(value) => send({ type: 'UPDATE_NAME', value })}
            onBlur={() => send('BLUR_NAME')}
          />
        </FormField>
        <FormField
          id="phone-number"
          label="Phone number"
          feedbackType="warning"
          formFieldStatus={
            state.matches('form.phone-number.invalid') ? 'error' : 'default'
          }
          infoLabel={match(state)
            .when(
              () => state.matches('form.phone-number.invalid.empty'),
              () => 'This field is required.'
            )
            .when(
              () => state.matches('form.phone-number.invalid.value'),
              () => "This phone number isn't valid."
            )
            .otherwise(() => '')}
        >
          <TextInput
            id="phone-number"
            label="phone-number"
            value={state.context.phoneNumber}
            onFocus={() => send('FOCUS_PHONE_NUMBER')}
            onChange={(value) => send({ type: 'UPDATE_PHONE_NUMBER', value })}
            onBlur={() => send('BLUR_PHONE_NUMBER')}
          />
        </FormField>
        <FormField
          id="address"
          label="Address"
          description="Include street address, city, prov/state, country, and postal code."
          feedbackType="warning"
          formFieldStatus={
            state.matches('form.address.invalid') ? 'error' : 'default'
          }
          infoLabel={
            state.matches('form.address.invalid')
              ? 'This field is required.'
              : ''
          }
        >
          <TextArea
            id="address"
            size="large"
            label="address"
            value={state.context.address}
            onFocus={() => send('FOCUS_ADDRESS')}
            onChange={(value) => send({ type: 'UPDATE_ADDRESS', value })}
            onBlur={() => send('BLUR_ADDRESS')}
          />
        </FormField>
      </div>
      <FormControls className="max-w-lg">
        {(state.matches('form.warning.on') ||
          state.matches('form.warning.pulse')) && (
          <Feedback type="warning" pulse={state.matches('form.warning.pulse')}>
            Please fill in all the fields above.
          </Feedback>
        )}
        <div className="max-w-md">
          <Button
            variant="primary"
            endIcon="rightChevron"
            label="Next"
            type="submit"
          />
        </div>
      </FormControls>
    </Form>
  );
}
