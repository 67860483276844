import type {
  ActorRefFrom,
  EventFrom,
  MachineOptionsFrom,
  Sender,
} from 'xstate';
import { createMachine, assign } from 'xstate';
import { match } from 'ts-pattern';
import type { NextEvent, BackEvent } from '../navigation';
import { StateFrom } from '../../utils/StateFrom';
import { createArtistContext as createArtistContext } from './ArtistMachine';
import { createAccountContext } from '../RedeemInvitation/AccountMachine';
import { createManagementContext } from './ManagementMachine';
import { GetRedeemAccountInvitationContextPromiseResult } from '../../promises/getRedeemAccountInvitationContext';
import { createPasswordContext } from '../RedeemInvitation/PasswordMachine';
import { isInvitationInvalidStatusError } from '../../errors/InvitationInvalidStatusError';
import { RedeemInvitationResult } from '../../promises/redeemInvitationPromise';
import { RedeemAccountInvitationStorage } from './storage';
import { RedeemAccountInvitationState } from './state';
import { RedeemAccountInvitationHistory } from './history';

import { Context } from './Context';
import { logMachineError } from '../../utils/logError';

type Events =
  | NextEvent
  | BackEvent
  | { type: 'RESUME_PASSWORD' }
  | { type: 'RESUME_ACCOUNT' }
  | { type: 'RESUME_ARTIST' }
  | { type: 'RESUME_MANGEMENT' }
  | { type: 'RESTART' };

type Services = {
  getRedeemAccountInvitationContext: {
    data: GetRedeemAccountInvitationContextPromiseResult;
  };
  redeemAccountInvitation: {
    data: RedeemInvitationResult;
  };
};

export const machine = createMachine(
  {
    tsTypes: {} as import('./Machine.typegen').Typegen0,
    predictableActionArguments: true,
    schema: {
      context: {} as Context,
      events: {} as Events,
      services: {} as Services,
    },
    initial: 'init',
    invoke: {
      id: 'restart',
      src: 'restart',
    },
    context: {
      tag: '',
      email: '',
      minimumPasswordLength: 0,
      countries: [],
      provinces: [],
      legalStatuses: [],
      isOrion: false,
    },
    states: {
      init: {
        initial: 'pending',
        states: {
          pending: {
            invoke: {
              id: 'getRedeemAccountInvitationContext',
              src: 'getRedeemAccountInvitationContext',
              onDone: {
                actions: 'setContext',
                target: 'resolved',
              },
              onError: [
                { cond: 'isInvalidRequest', target: 'invalid-tag' },
                { target: 'rejected' },
              ],
            },
          },
          resolved: { type: 'final' },
          rejected: { entry: 'logMachineError' },
          'invalid-tag': {},
        },
        onDone: 'resume',
      },
      resume: {
        invoke: {
          id: 'resume',
          src: 'resume',
        },
        on: {
          RESUME_PASSWORD: 'password',
          RESUME_ACCOUNT: 'account',
          RESUME_ARTIST: 'artist',
          RESUME_MANGEMENT: 'management',
        },
      },
      password: {
        entry: 'savePasswordStep',
        invoke: {
          id: 'passwordMachine',
          src: 'passwordMachine',
          data: createPasswordContext,
        },
        on: {
          NEXT: 'account',
        },
      },
      account: {
        entry: 'saveAccountStep',
        invoke: {
          id: 'accountMachine',
          src: 'accountMachine',
          data: createAccountContext,
        },
        on: {
          NEXT: 'artist',
          BACK: 'password',
        },
      },
      artist: {
        entry: 'saveArtistStep',
        invoke: {
          id: 'artistMachine',
          src: 'artistMachine',
          data: createArtistContext,
        },
        on: {
          NEXT: [
            { cond: 'requiresManagement', target: 'management' },
            { target: 'redeeming' },
          ],
          BACK: 'account',
        },
      },
      management: {
        entry: 'saveManagementStep',
        invoke: {
          id: 'managementMachine',
          src: 'managementMachine',
          data: createManagementContext,
        },
        on: {
          NEXT: 'redeeming',
          BACK: 'artist',
        },
      },
      redeeming: {
        initial: 'pending',
        states: {
          pending: {
            invoke: {
              id: 'redeemAccountInvitation',
              src: 'redeemAccountInvitation',
              onDone: {
                actions: 'signIn',
              },
              onError: 'rejected',
            },
          },
          rejected: { entry: 'logMachineError' },
        },
      },
    },
    on: { RESTART: 'init' },
  },
  {
    actions: { logMachineError, setContext: assign((_, { data }) => data) },
    guards: {
      isInvalidRequest: (_, { data }) => isInvitationInvalidStatusError(data),
    },
  }
);

type Machine = typeof machine;

export type RedeemAccountInvitationMachineState = StateFrom<Machine>;
export type RedeemAccountInvitationMachineSender = Sender<EventFrom<Machine>>;
export type RedeemAccountInvitationMachineOptions = MachineOptionsFrom<
  Machine,
  true
>;
export type RedeemAccountInvitationMachineActor = ActorRefFrom<Machine>;

export const createGuards = (storage: RedeemAccountInvitationStorage) => ({
  requiresManagement: () => storage.get().artist?.isSelfManaged === false,
});

export const createResumeService =
  (storage: RedeemAccountInvitationStorage) =>
  () =>
  (send: RedeemAccountInvitationMachineSender) => {
    const { step } = storage.get();

    match(step)
      .with(undefined, () => send('RESUME_PASSWORD'))
      .with('password', () => send('RESUME_PASSWORD'))
      .with('account', () => send('RESUME_ACCOUNT'))
      .with('artist', () => send('RESUME_ARTIST'))
      .with('management', () => send('RESUME_MANGEMENT'))
      .exhaustive();
  };

export const createRestartService =
  (
    storage: RedeemAccountInvitationStorage,
    history: RedeemAccountInvitationHistory
  ) =>
  () =>
  (send: RedeemAccountInvitationMachineSender) => {
    history.subscribe((state) => {
      storage.set(state);
      send('RESTART');
    });

    return history.unsubscribe;
  };

const createStepSaver =
  (
    storage: RedeemAccountInvitationStorage,
    history: RedeemAccountInvitationHistory,
    step: RedeemAccountInvitationState['step']
  ) =>
  // Every time the user navigates forwards or backwards in the flow we
  // push an entry onto the history stack. This gives us a snapshot to
  // restore to if the user every decides to use the back and forward
  // buttons on their browser.
  (_: Context, { type }: Events) => {
    // We only want to push history when the user has been the one
    // triggering movement. There are other events used by the resume
    // serivce to restore the state of the flow but those are not
    // triggered by humans in front of the screen and therefore we do
    // not want to push history onto the stack for those events.
    if (type === 'NEXT' || type === 'BACK') {
      // The reason we first replace the current state is because the user
      // will have changed the state on the page since they first entered this
      // state. This allows us to save all their work up till they navigated
      // to the next step.
      history.replace(storage.get());
      storage.add({ step });
      history.push(storage.get());
    }
  };

export const createSaveActions = (
  storage: RedeemAccountInvitationStorage,
  history: RedeemAccountInvitationHistory
) => ({
  savePasswordStep: createStepSaver(storage, history, 'password'),
  saveAccountStep: createStepSaver(storage, history, 'account'),
  saveArtistStep: createStepSaver(storage, history, 'artist'),
  saveManagementStep: createStepSaver(storage, history, 'management'),
});
