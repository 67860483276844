import { z } from 'zod';

const completeAddArtistStateSchema = z.object({
  artist: z.object({
    name: z.string(),
    province: z.string(),
    isSelfManaged: z.boolean(),
  }),
  company: z.object({
    name: z.string(),
    legalStatus: z.string(),
    legalProvince: z.string().optional(),
  }),
  management: z
    .object({
      name: z.string(),
      country: z.string(),
      contactPerson: z.string(),
      address: z.string(),
      phoneNumber: z.string(),
      email: z.string(),
      legalStatus: z.string(),
      legalProvince: z.string().optional(),
    })
    .optional(),
});

const addArtistStateSchema = completeAddArtistStateSchema.deepPartial().extend({
  step: z.union([z.literal('artist'), z.literal('management')]).optional(),
});

export type AddArtistState = z.infer<typeof addArtistStateSchema>;
export type CompleteAddArtistState = z.infer<
  typeof completeAddArtistStateSchema
>;

export const parseAddAccountState = (data: unknown) =>
  addArtistStateSchema.parse(data);

export const parseCompleteAddAccountState = (data: unknown) =>
  completeAddArtistStateSchema.parseAsync(data);
