import { CompleteFundingRequest } from '../schemas/fundingRequests/fundingRequestSchema';

export type AddFundingRequest = {
  type: 'ADD_REQUEST';
  request: CompleteFundingRequest;
};

export const addFundingRequest = (
  request: AddFundingRequest['request']
): AddFundingRequest => ({
  type: 'ADD_REQUEST',
  request,
});
