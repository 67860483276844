import { GraphQLClient } from 'graphql-request';
import { DeleteExpenseDocument } from '../../../graphql/operations';
import { DeleteExpense } from '../../../events/Expense/DeleteExpense';
import { z } from 'zod';
import { fundingRequestSchema } from '../../../schemas/fundingRequests/fundingRequestSchema';

export const createDeleteExpensePromise =
  (client: GraphQLClient) =>
  async (_: unknown, { id }: DeleteExpense) => {
    const response = await client.request(DeleteExpenseDocument, {
      deleteId: { id },
    });

    return z
      .object({
        fundingRequest: fundingRequestSchema,
      })
      .parse(response.request);
  };

export type CreateDeleteExpensePromise = ReturnType<
  typeof createDeleteExpensePromise
>;

export type CreateDeleteExpensePromiseResult = Awaited<
  ReturnType<CreateDeleteExpensePromise>
>;
