import { match } from 'ts-pattern';
import { ApplicationType } from '../../graphql/operations';
import { Feedback } from '../Feedback';
import { FeedbackPage } from './ApplicationStatusFeedback';

interface InProgressApplicationFeedbackProps {
  applicationType: ApplicationType;
  location: FeedbackPage;
  isFinalOrionApplication: boolean;
}

export function InProgressApplicationFeedback({
  applicationType,
  location,
  isFinalOrionApplication,
}: InProgressApplicationFeedbackProps) {
  const isOrion = applicationType === ApplicationType.Orion;
  const finalWarning = (
    <>
      You have almost reached the limit of Orion application approvals.{' '}
      <span className="body-text-bold">
        If this application is approved it will be your final application in the
        Orion program.
      </span>
    </>
  );

  return match(location)
    .with('dashboard', () => (
      <Feedback
        type="neutral"
        title={`You have ${
          isOrion ? 'an Orion' : 'a Core'
        } funding application in progress.`}
        icon="infoCircle"
      >
        Please finish and submit your application.{' '}
        {isOrion && isFinalOrionApplication && finalWarning}
      </Feedback>
    ))
    .with('application', () => (
      <Feedback
        type="neutral"
        title="Your application is in progress."
        icon="infoCircle"
      >
        Complete your application by following the instructions below.{' '}
        {isOrion && isFinalOrionApplication && finalWarning}
      </Feedback>
    ))
    .exhaustive();
}
