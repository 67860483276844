import { GenericPreview } from '../GenericPreview';
import { Attachment } from '../../schemas/attachment';
import { formatFileSize } from '../../utils/formatFileSize';

interface Props {
  attachment: Attachment;
  onClick: (attachment: Attachment) => void;
  showDelete: boolean;
  onDelete: (attachment: Attachment) => void;
}

export function TourSummaryPreview({
  attachment,
  onClick,
  onDelete,
  showDelete,
}: Props) {
  return (
    <GenericPreview
      iconName="file"
      iconAriaLabel="Document icon"
      title="Tour Summary"
      note={`${attachment.name} ${formatFileSize(attachment.size)}`}
      buttonVariant="neutral"
      buttonLabel={showDelete ? 'Delete' : undefined}
      onIconClick={() => onClick(attachment)}
      onTitleClick={() => onClick(attachment)}
      onButtonClick={() => onDelete(attachment)}
    />
  );
}
