import { GraphQLClient } from 'graphql-request';
import { DeleteReleaseDocument } from '../graphql/operations';

export const createDeleteReleasePromise =
  (client: GraphQLClient, releaseId: string) => () => {
    return client.request(DeleteReleaseDocument, {
      input: {
        id: releaseId,
      },
    });
  };

export type DeleteReleasePromise = ReturnType<
  typeof createDeleteReleasePromise
>;
export type DeleteReleasePromiseResult = Awaited<
  ReturnType<DeleteReleasePromise>
>;
