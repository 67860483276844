import { CompleteExpense } from '../../../schemas/fundingRequests/expenses/expenseSchema';
import { parsePositiveFloat } from '../../../utils/parsePositiveFloat';

export function prepareExpense({
  date,
  description,
  cost,
  ...rest
}: CompleteExpense) {
  return {
    date: date.toString(),
    description: description ?? '',
    cost: parsePositiveFloat(cost).toString(),
    ...rest,
  };
}
