import { ReactNode } from 'react';
import { EligibilityNoticeTypes } from '../../../graphql/operations';
import { ArtistEligibilityNotice } from '../../../schemas/artistEligibilityNoticeSchema';
import { Feedback } from '../../Feedback';

import styles from './EligibilityNoticeFeedback.module.scss';

interface EligibilityNoticeFeedbackProps {
  notice: ArtistEligibilityNotice;
  feedbackButtons?: ReactNode;
}

export function EligibilityNoticeFeedback({
  notice,
  feedbackButtons,
}: EligibilityNoticeFeedbackProps) {
  if (!notice) {
    return null;
  }

  return (
    <Feedback
      type={
        notice.type === EligibilityNoticeTypes.Ineligible
          ? 'warning'
          : 'neutral'
      }
      size="compact"
      title="Eligibility Notice"
      feedbackButtons={feedbackButtons}
    >
      <p className={styles.noticeText}>{notice.message}</p>
    </Feedback>
  );
}
