import { AdminBoardMeetingBlockMachineSender } from '../../../machines/BoardMeeting/AdminBoardMeetingBlockMachine';
import { FormField } from '../../FormField';
import { InlineFormContainer } from '../../InlineFormContainer';
import { TextArea } from '../../TextArea';
import { TextInput } from '../../TextInput';

interface MeetingBlockFormProps {
  title: string;
  content: string;
  send: AdminBoardMeetingBlockMachineSender;
}

export function MeetingBlockForm({
  title,
  content,
  send,
}: MeetingBlockFormProps) {
  return (
    <InlineFormContainer
      primaryButtonOnClick={() => {
        send('SAVE_FORM');
      }}
      primaryButtonText="Save"
      secondaryButtonOnClick={() => {
        send('CANCEL_FORM');
      }}
      secondaryButtonText="Cancel"
    >
      <FormField label="Block title">
        <TextInput
          id="title"
          value={title}
          onChange={(title) => {
            send({ type: 'SET_TITLE', title });
          }}
        />
      </FormField>
      <FormField label="Block text content">
        <TextArea
          id="content"
          value={content}
          label="Block text content"
          onChange={(content) => {
            send({ type: 'SET_CONTENT', content });
          }}
          size="small"
        />
      </FormField>
    </InlineFormContainer>
  );
}
