import classNames from 'classnames';

import styles from './Pagination.module.scss';

interface PaginationProps {
  steps: number;
  active: number;
}

export function Pagination({ steps, active }: PaginationProps) {
  const middleCircleCount = (steps - 2) * 1.25;
  const gaps = steps - 1;
  const lineWidth = middleCircleCount + gaps + 0.5;
  // circle width is 1.25rem and the line is 1rem
  const activeWidth = (1.25 + 1) * (active - 1);
  const fillPercent = (activeWidth / lineWidth) * 100;

  return (
    <div className={styles.pagination}>
      {Array.from({ length: steps })
        .map((_, index) => index + 1)
        .map((step, index) => (
          <span
            key={index}
            className={classNames(styles.circle, {
              [styles.active]: step === active,
              [styles.complete]: step < active,
              [styles.incomplete]: step > active,
            })}
          >
            {step}
          </span>
        ))}
      <div
        className={styles.line}
        style={{
          width: `${lineWidth}rem`,
          background: `-webkit-linear-gradient(left, #BFDBF7 ${fillPercent}%, #CCCCCC ${fillPercent}%)`,
        }}
      ></div>
    </div>
  );
}
