import { GraphQLClient } from 'graphql-request';
import { z } from 'zod';
import {
  UpdateAccountDetailsDocument,
  UpdateAccountDetailsMutationVariables,
} from '../graphql/operations';
import { accountDetailsSchema } from '../schemas/accountDetailsSchema';

export const createUpdateAccountDetailsPromise =
  (client: GraphQLClient) =>
  async (variables: UpdateAccountDetailsMutationVariables) => {
    const request = await client.request(UpdateAccountDetailsDocument, {
      accountId: variables.accountId,
      editedDetails: {
        address: variables.editedDetails.address,
        name: variables.editedDetails.name,
        phoneNumber: variables.editedDetails.phoneNumber,
      },
    });
    return z
      .object({
        account: accountDetailsSchema,
        query: z.object({
          minimumPasswordLength: z.number(),
        }),
      })
      .parse(request.updateAccountById);
  };

export type UpdateAccountDetailsPromise = ReturnType<
  typeof createUpdateAccountDetailsPromise
>;
export type UpdateAccountDetailsPromiseResult = Awaited<
  ReturnType<UpdateAccountDetailsPromise>
>;
