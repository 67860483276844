import { DirectDepositAccount } from '../../schemas/directDepositAccount';

export type UpdateTouringDirectDepositEvent = {
  type: 'UPDATE_TOURING_DIRECT_DEPOSIT_ACCOUNT';
  directDepositAccount: DirectDepositAccount | null;
};

export const updateTouringDirectDeposit = (
  directDepositAccount: DirectDepositAccount | null
): UpdateTouringDirectDepositEvent => ({
  type: 'UPDATE_TOURING_DIRECT_DEPOSIT_ACCOUNT',
  directDepositAccount,
});

export type UpdateFundingRequestDirectDepositEvent = {
  type: 'UPDATE_FUNDING_REQUEST_DIRECT_DEPOSIT_ACCOUNT';
  directDepositAccount: DirectDepositAccount | null;
};

export const updateFundingRequestDirectDeposit = (
  directDepositAccount: DirectDepositAccount | null
): UpdateFundingRequestDirectDepositEvent => ({
  type: 'UPDATE_FUNDING_REQUEST_DIRECT_DEPOSIT_ACCOUNT',
  directDepositAccount,
});

export type UpdateDirectDepositAccountsEvent = {
  type: 'UPDATE_DIRECT_DEPOSIT_ACCOUNTS';
  directDepositAccounts: DirectDepositAccount[];
};

export const updateDirectDepositAccounts = (
  directDepositAccounts: DirectDepositAccount[]
): UpdateDirectDepositAccountsEvent => ({
  type: 'UPDATE_DIRECT_DEPOSIT_ACCOUNTS',
  directDepositAccounts,
});

export type DeletePaymentEvent = {
  type: 'DELETE_PAYMENT';
  id: string;
};

export const deletePayment = (id: string): DeletePaymentEvent => ({
  type: 'DELETE_PAYMENT',
  id,
});

export type DeletePaymentErrorEvent = {
  type: 'DELETE_PAYMENT_ERROR';
};

export const deletePaymentError = (): DeletePaymentErrorEvent => ({
  type: 'DELETE_PAYMENT_ERROR',
});
