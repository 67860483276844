import cn from 'classnames';
import { P, match } from 'ts-pattern';
import { Block } from '../Block';
import { Button } from '../Button';
import { Feedback } from '../Feedback';
import {
  TourDatesBlockMachineSender,
  TourDatesBlockMachineState,
} from '../../machines/components/TourDatesBlockMachine';
import { TourDateController } from './TourDateController';
import { formatCalendarDate } from '../../utils/formatCalendarDate';
import { TourDateRegion } from '../../schemas/tourDates/tourDateRegionSchema';
import { ErrorFeedback } from '../ErrorFeedback';
import { SubmittedTourDate } from './SubmittedTourDate';
import { TourDateCardController } from './TourDateCard/TourDateCardController';
import { ApplicationStatus } from '../../graphql/operations';
import { FormFieldLabel } from '../FormFieldLabel';

import styles from './TourDatesBlockView.module.scss';

interface TourDatesBlockViewProps {
  state: TourDatesBlockMachineState;
  send: TourDatesBlockMachineSender;
  isReopened: boolean;
}

function TourLimits({
  year,
  regions,
}: {
  year: number;
  regions: TourDateRegion[];
}) {
  return (
    <p className={styles.limitFeedback}>
      Remaining for {year}{' '}
      {regions.map(({ id, title, availableTourDates, maximumTourDates }) => (
        <span key={id}>
          <span
            className={cn(styles.bold, {
              [styles.red]: availableTourDates <= 0,
            })}
          >
            {availableTourDates} / {maximumTourDates}
          </span>{' '}
          {title}{' '}
        </span>
      ))}
    </p>
  );
}

function ExtendedTourDateView({
  extendedTourDate: { open, tourDate, ref, cardRef },
  send,
}: {
  extendedTourDate: TourDatesBlockMachineState['context']['extendedTourDates'][0];
  send: TourDatesBlockMachineSender;
}) {
  return match([open, tourDate.isWritable])
    .with([true, true], () => (
      <TourDateController key={tourDate.id} actor={ref} />
    ))
    .with([true, P._], () => (
      <SubmittedTourDate
        key={tourDate.id}
        tourDate={tourDate}
        onClose={() => send({ type: 'CLOSE_TOUR_DATE', id: tourDate.id })}
      />
    ))
    .with([false, P._], () => (
      <TourDateCardController key={tourDate.id} actor={cardRef} />
    ))
    .exhaustive();
}

export function TourDatesBlockView({
  state,
  send,
  isReopened,
}: TourDatesBlockViewProps) {
  const isSubmitted = state.context.submittedAt !== null;
  const isClaimsSubmitted = state.context.claimsSubmittedAt !== null;
  const isApproved =
    state.context.applicationStatus === ApplicationStatus.Approved;

  const alternateButton = (
    <Button
      id="addAlternateTourDateButton"
      variant="addnew"
      label="Add an Alternate Tour Date"
      onClick={() => send({ type: 'OPEN_TOUR_DATE' })}
      disabled={state.context.alternateTourDatesAllowed === false}
    />
  );

  return (
    <Block id="Tour Dates" headerText="Tour Dates" iconName="calendar">
      <Feedback type="neutral" size="compact">
        <div>
          <TourLimits
            year={state.context.year}
            regions={state.context.tourDateRegions}
          />
        </div>
      </Feedback>
      {!isSubmitted && (
        <>
          <p>
            List the tour dates for which you are applying below. Keep in mind
            the following limitations:
          </p>
          <ul className={styles.list}>
            <li>
              You must list at least 3 confirmed dates in one of the following
              territories: Canada, the US, or International (non-US)
              territories.
            </li>
            <li>
              Any performance outside of a regular hard-ticketed concert must be
              explained in the Strategy section below.
            </li>
            <li>
              Headlining appearances in venues with capacity above 1,000 or
              below 100 are not eligible unless the performance is non-ticketed.
            </li>
            <li>
              Support appearances for ticketed appearances are eligible in
              venues over 1,000 but not under 100.
            </li>
            <li>
              Tour dates must occur within 8 months of the application deadline.
              {state.context.futureCutoffDate !== undefined &&
                ` Tour dates after ${formatCalendarDate(
                  state.context.futureCutoffDate
                )} will not be eligible for this round.`}
            </li>
            <li>
              You can be approved for up to 35 Canadian, 35 US, and 35
              International (non-US) dates per calendar year.
            </li>
            <li>You can only apply once per round per artist.</li>
            <li>
              Changes to tour dates after approval require review from the CSF.
            </li>
          </ul>
        </>
      )}

      <div className={styles.tourDates}>
        {isSubmitted && isApproved && (
          <FormFieldLabel label="Original Tour Dates" />
        )}
        {state.context.extendedTourDates
          .filter(({ tourDate: { alternate } }) => alternate === false)
          .map((extendedTourDate) => (
            <ExtendedTourDateView
              key={extendedTourDate.tourDate.id}
              extendedTourDate={extendedTourDate}
              send={send}
            />
          ))}
        {isSubmitted && isApproved && (
          <FormFieldLabel
            label="Alternate Tour Dates"
            tooltipContent="Alternate tour date(s) are subject to approval by the Starmaker staff who will review it for eligibility. You will be notified about the status of your alternate date."
          />
        )}
        {isSubmitted &&
          isApproved &&
          state.context.extendedTourDates
            .filter(({ tourDate: { alternate } }) => alternate)
            .map((extendedTourDate) => (
              <ExtendedTourDateView
                key={extendedTourDate.tourDate.id}
                extendedTourDate={extendedTourDate}
                send={send}
              />
            ))}
        {state.context.openTourDate && (
          <TourDateController actor={state.context.openTourDate} />
        )}
      </div>
      {match([
        state.context.openTourDate,
        isSubmitted,
        isApproved,
        isClaimsSubmitted,
        isReopened,
      ])
        .with([null, false, false, false, false], () => (
          <Button
            id="addTourDateButton"
            variant="addnew"
            label="Add a Tour Date"
            onClick={() => send({ type: 'OPEN_TOUR_DATE' })}
          />
        ))
        .with([null, true, true, false, false], () => alternateButton)
        .with([null, true, true, true, true], () => alternateButton)
        .otherwise(() => null)}
      {state.matches('ready.error') && <ErrorFeedback />}
    </Block>
  );
}
