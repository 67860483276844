import type {
  Sender,
  EventFrom,
  MachineOptionsFrom,
  ActorRefFrom,
} from 'xstate';
import { createMachine, sendParent } from 'xstate';
import { Payment } from '../../schemas/paymentSchema';
import { StateFrom } from '../../utils/StateFrom';
import { DeletePaymentErrorEvent, DeletePaymentEvent } from './events';

type Events = { type: 'DELETE' } | DeletePaymentEvent | DeletePaymentErrorEvent;

export type Context = {
  payment: Payment;
};

export const machine = createMachine(
  {
    id: 'paymentMachine',
    predictableActionArguments: true,
    tsTypes: {} as import('./PaymentMachine.typegen').Typegen0,
    schema: {
      context: {} as Context,
      events: {} as Events,
    },
    initial: 'idle',
    states: {
      idle: {
        on: {
          DELETE: { actions: 'requestDelete', target: 'deleting' },
        },
      },
      deleting: {
        on: {
          DELETE_PAYMENT_ERROR: 'error',
        },
      },
      error: {},
    },
  },
  {
    actions: {
      requestDelete: sendParent((context) => {
        return {
          type: 'DELETE_PAYMENT',
          id: context.payment.id,
        };
      }),
    },
  }
);

type Machine = typeof machine;

export type PaymentMachineState = StateFrom<Machine>;
export type PaymentMachineSender = Sender<EventFrom<Machine>>;
export type PaymentMachineOptions = MachineOptionsFrom<Machine, true> & {
  context: Context;
};
export type PaymentMachineEvent = EventFrom<Machine>;
export type PaymentMachineActor = ActorRefFrom<Machine>;
