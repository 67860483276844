import cn from 'classnames';
import { Icon } from '../../components/Icon';
import { Link } from '../../components/Link';
import {
  DashboardMachineSender,
  DashboardMachineState,
} from '../../machines/DashboardMachine';
import { RoundStatus } from '../../components/RoundStatus';
import { DashboardTitle } from '../../components/Dashboard/DashboardTitle';
import { ClosedApplicationsList } from '../../components/Dashboard/ClosedApplicationsList';
import { ReleasesBlock } from '../../components/Dashboard/ReleasesBlock';
import { AccountsBlockController } from '../../components/Dashboard/AccountsBlock/AccountsBlockController';
import { AccountsBlockMachineActor } from '../../machines/components/AccountsBlockMachine';
import { ArtistDetailsBlockController } from '../../components/Dashboard/ArtistDetailsBlock/ArtistDetailsBlockController';
import { ArtistDetailsBlockMachineActor } from '../../machines/components/ArtistDetailsBlockMachine';
import { ArtistManagementController } from '../../components/Dashboard/ArtistManagement/ArtistManagementController';
import { ArtistManagementMachineActor } from '../../machines/components/ArtistManagementMachine';
import { ArtistStatus } from '../../graphql/operations';
import { Breadcrumbs } from '../../components/Breadcrumbs';
import { EligibilityNoticeController } from '../../components/Dashboard/EligibilityNotice/EligibilityNoticeController';
import { EligibilityNoticeMachineActor } from '../../machines/EligibilityNoticeMachine';
import { StatusFeedback } from '../../components/Dashboard/StatusFeedback';
import { ApplicationStatusFeedback } from '../../components/ApplicationStatusFeedback/ApplicationStatusFeedback';
import { ActiveApplicationsBlock } from '../../components/Dashboard/ActiveApplicationsBlock';
import { Loading } from '../../components/Loading';
import { ErrorPage } from '../ErrorPage';
import { NotFound } from '../NotFound';
import { ErrorFeedback } from '../../components/ErrorFeedback';
import { EligibilityNoticeFeedback } from '../../components/Dashboard/EligibilityNotice/EligibilityNoticeFeedback';

import styles from './DashboardView.module.scss';

interface DashboardViewProps {
  state: DashboardMachineState;
  send: DashboardMachineSender;
  isMobile: boolean;
  isAdmin: boolean;
}

export function DashboardView({ state, send, isAdmin }: DashboardViewProps) {
  if (state.matches('init')) {
    return <Loading />;
  }

  if (state.matches('initError')) {
    return <ErrorPage />;
  }

  if (state.matches('notFound')) {
    return <NotFound />;
  }

  const {
    artist,
    currentRound,
    nextRound,
    previousRound,
    activeApplications,
    closedApplications,
    nextOrionRound,
  } = state.context;

  return (
    <div className={styles.container}>
      <RoundStatus
        currentRound={currentRound}
        nextRound={nextRound}
        previousRound={previousRound}
      />
      {artist.showLegacyClaimsMessage && (
        <div className={styles.legacyClaimsNote}>
          <div className={cn('max-w-xl', styles.legacyClaimsNoteContent)}>
            <Icon name="infoCircle" className={styles.legacyClaimsIcon} />
            <p>
              For claims on applications from Rounds 87, 88, 89, or 90 please
              visit:{' '}
              <Link type="feedback" href="http://legacy.starmaker.ca">
                legacy.starmaker.ca
              </Link>
            </p>
          </div>
        </div>
      )}
      <div className={cn('max-w-xl', styles.content)}>
        <div className={styles.feedback}>
          {isAdmin && (
            <Breadcrumbs
              breadcrumbs={[{ text: 'All Artists', to: '/admin/artists' }]}
            />
          )}
          <DashboardTitle
            artistName={artist.name}
            fundingGiven={artist.totalFunded}
            showOrion={artist.status === ArtistStatus.Orion}
            orionApprovalCount={artist.orionApprovalCount}
            orionApprovalLimit={artist.orionApprovalLimit}
          />
          {isAdmin ? (
            <EligibilityNoticeController
              actor={
                state.children
                  .eligibilityNoticeMachine as EligibilityNoticeMachineActor
              }
            />
          ) : (
            <EligibilityNoticeFeedback notice={artist.eligibilityNotice} />
          )}
          <StatusFeedback
            artistStatus={artist.status}
            currentRound={currentRound}
            nextOrionRound={nextOrionRound}
            isCoreEligible={artist.isCoreEligible}
            hasEligibleRelease={artist.hasEligibleRelease}
            hasReachedOrionApprovalLimit={artist.hasReachedOrionApprovalLimit}
            aboveSalesRequirement={artist.aboveSalesRequirement}
            aboveLifetimeFundingLimit={artist.aboveLifetimeFundingLimit}
            orionApprovalLimit={artist.orionApprovalLimit}
          />
          {activeApplications.map((application) => (
            <ApplicationStatusFeedback
              application={{
                ...application,
                hasPayment: application.totalPaid > 0,
                hasFundingRequest: application.totalNumberOfFundingRequests > 0,
              }}
              location="dashboard"
              key={application.id}
              applicationLink={`/artist/${artist.id}/application/${application.id}`}
              isFinalOrionApplication={
                artist.orionApprovalLimit - artist.orionApprovalCount === 1
              }
            />
          ))}
          {state.matches('ready.application.error') && (
            <ErrorFeedback pulse={true} className={styles.error} />
          )}
          <ActiveApplicationsBlock
            applications={activeApplications}
            artist={artist}
            canStartApplication={artist.canStartApplication}
            isOwner={artist.isOwner || isAdmin}
            startApplication={() => {
              send({ type: 'START_APPLICATION' });
            }}
          />
        </div>
        <div className={styles.collapseRow}>
          <ClosedApplicationsList
            artistId={artist.id}
            applications={closedApplications}
          />
          <ReleasesBlock
            artistId={artist.id}
            releases={state.context.releases.map((el) => ({
              ...el,
              artistId: artist.id,
              label: el.otherLabel ?? el.label.name,
            }))}
            isOwner={artist.isOwner || isAdmin}
          />
        </div>
        <div>
          <AccountsBlockController
            actor={
              state.children.accountsBlockMachine as AccountsBlockMachineActor
            }
          />
        </div>
        <div className={styles.artistDetails}>
          <ArtistDetailsBlockController
            actor={
              state.children
                .artistDetailsBlockMachine as ArtistDetailsBlockMachineActor
            }
          />
          <ArtistManagementController
            actor={
              state.children
                .artistManagementMachine as ArtistManagementMachineActor
            }
          />
        </div>
      </div>
    </div>
  );
}
