import { Storage, createMemoryStorage } from '../../utils/storage';

import { RedeemAccountInvitationState } from './state';

export type RedeemAccountInvitationStorage = Omit<
  Storage<RedeemAccountInvitationState>,
  'add'
> & {
  add: (state: RedeemAccountInvitationState, isReplay?: true) => void;
};

const mergeRedeemAccountInvitationStates = (
  newState: RedeemAccountInvitationState,
  oldState: RedeemAccountInvitationState
): RedeemAccountInvitationState => ({
  ...oldState,
  ...newState,
  account: {
    ...oldState.account,
    ...newState.account,
  },
  artist: {
    ...oldState.artist,
    ...newState.artist,
  },
  company: {
    ...oldState.company,
    ...newState.company,
  },
  // Only create the partial object if there is management information otherwise
  // leave the property set to null. The schema that validates the overall state
  // expects this to undefined or fully defined. It would fail when it was just
  // an empty object.
  management:
    oldState.management !== undefined || newState.management !== undefined
      ? {
          ...oldState.management,
          ...newState.management,
        }
      : undefined,
});

const createRedeemAccountInvitationStorage = (
  storage: Storage<RedeemAccountInvitationState>
): RedeemAccountInvitationStorage => {
  const { add, ...rest } = storage;

  return {
    add: (state: RedeemAccountInvitationState, isReplay?: true) => {
      if (isReplay !== true) {
        add(state);
      }
    },
    ...rest,
  };
};

export const createMemoryRedeemAccountInvitationStorage = (
  initial?: RedeemAccountInvitationState
) =>
  createRedeemAccountInvitationStorage(
    createMemoryStorage<RedeemAccountInvitationState>(
      initial ?? {},
      mergeRedeemAccountInvitationStates
    )
  );
