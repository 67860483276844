import { match } from 'ts-pattern';
import {
  ApplicationReviewMachineSender,
  ApplicationReviewMachineState,
} from '../../../machines/Admin/ApplicationReviewMachine';
import { Button } from '../../Button';
import { CheckOption } from '../../CheckOption';
import { ErrorFeedback } from '../../ErrorFeedback';
import { AddEmailRecipientForm } from './AddEmailRecipientForm';

import styles from './ApprovalConfirmation.module.scss';

interface Props {
  state: ApplicationReviewMachineState;
  send: ApplicationReviewMachineSender;
}

export function ApprovalConfirmation({ state, send }: Props) {
  return (
    <div className={styles.approvalConfirmation}>
      <div className={styles.approvalConfirmationTop}>
        <span>
          <span className="body-text-bold">From: </span>
          info@starmaker.ca
        </span>
        <div className={styles.emails}>
          <span className="body-text-bold">Send to: </span>{' '}
          {state.context.contacts.map(({ email, name, checked, isPrimary }) => (
            <CheckOption
              key={email}
              label={
                name ? (
                  <div>
                    <span className="body-text-bold">
                      {name}
                      {isPrimary && ` (Primary Contact)`}
                    </span>
                    {` <${email}>`}
                  </div>
                ) : (
                  email
                )
              }
              onChange={() => send({ type: 'TOGGLE_EMAIL', email })}
              checked={checked}
            />
          ))}
          {state.context.additionalEmails &&
            state.context.additionalEmails.map((email) => (
              <div className={styles.additionalEmail} key={email}>
                <span>{email}</span>
                <Button
                  size="small"
                  variant="neutral"
                  label="Remove"
                  onClick={() =>
                    send({ type: 'REMOVE_ADDITIONAL_EMAIL', email })
                  }
                />
              </div>
            ))}
          {match(state)
            .when(
              () => state.matches('status.none.approvalConfirmation.default'),
              () => (
                <div className={styles.addEmailButton}>
                  <Button
                    size="small"
                    variant="secondary"
                    label="Add Email Recipient"
                    startIcon="plus"
                    onClick={() => send({ type: 'OPEN_ADD_EMAIL_FORM' })}
                  />
                </div>
              )
            )
            .when(
              () =>
                state.matches(
                  'status.none.approvalConfirmation.addEmailFormOpen'
                ),
              () => <AddEmailRecipientForm state={state} send={send} />
            )
            .otherwise(() => null)}
        </div>
      </div>
      <div className={styles.approvalConfirmationBottom}>
        {state.matches('status.none.approvalConfirmation.approvingError') && (
          <ErrorFeedback />
        )}
        <div className={styles.approvalConfirmationControls}>
          <Button
            size="small"
            label="Approve & Send Contract Email"
            disabled={state.matches(
              'status.none.approvalConfirmation.approvingAndNotifying'
            )}
            onClick={() =>
              send({
                type: 'APPROVE_AND_NOTIFY',
              })
            }
          />
          <Button
            size="small"
            variant="secondary"
            label="Cancel"
            disabled={state.matches(
              'status.none.approvalConfirmation.approvingAndNotifying'
            )}
            onClick={() => send({ type: 'CLOSE_APPROVAL_FORM' })}
          />
        </div>
      </div>
    </div>
  );
}
