import { Link } from 'react-router-dom';
import { useAuth } from '../components/AuthProvider';
import { PageHeader } from '../components/PageHeader';

export function DevHome() {
  const { isSignedIn } = useAuth();

  return (
    <>
      <PageHeader heading="Home" />
      <ul>
        <li>
          <Link to="/artists">Artists</Link>
        </li>
        <li>
          <Link to="/showcase">Showcase</Link>
        </li>
        <li>
          <Link to="/forgot-password">Forgot Password</Link>
        </li>
        <li>
          <Link to="/eligibility">Eligibility</Link>
        </li>
        <li>
          <Link to="/create-profile">Create Profile</Link>
        </li>
        <li>
          <Link to="/search-prototype">Search Prototype</Link>
        </li>
        {!isSignedIn && (
          <li>
            <Link to="/sign-in">Sign In</Link>
          </li>
        )}
      </ul>
    </>
  );
}
