import { useMachine } from '@xstate/react';
import {
  machine,
  RoundsBlockMachineOptions,
} from '../../../machines/Admin/RoundsBlockMachine';
import { RoundsView } from './RoundsView';

type RoundsControllerProps = {
  options: RoundsBlockMachineOptions;
};

export function RoundsController({ options }: RoundsControllerProps) {
  const [state, send] = useMachine(machine, options);

  return <RoundsView state={state} send={send} />;
}
