import { z } from 'zod';
import { serverDateSchema } from '../dateSchema';
import { serverDateTimeSchema } from '../dateTimeSchema';
import { fundingRoundSchema } from '../fundingRound/fundingRoundSchema';
import { applicationArtistSchema } from './applicationArtistSchema';
import { linkedAccountSchema } from '../linkedAccountSchema';
import { fundingRequestSchema } from '../fundingRequests/fundingRequestSchema';
import { applicationTypeSchema } from './applicationTypeSchema';
import { contactSchema } from '../contactSchema';
import { tourDateSchema } from '../tourDates/tourDateSchema';
import { tourDateTypeSchema } from '../tourDates/tourDateTypeSchema';
import { countrySchema } from '../countrySchema';
import { attachmentSchema } from '../attachment';
import { applicationStatusSchema } from './applicationStatusSchema';
import { applicationReviewStatusSchema } from './applicationReviewStatusSchema';
import { directDepositAccountSchema } from '../directDepositAccount';
import { tourDateRegionSchema } from '../tourDates/tourDateRegionSchema';
import { applicationTotalsSchema } from './applicationTotalsSchema';
import { moneySchema } from '../moneySchema';
import { fundingRequestTotalsSchema } from '../fundingRequests/fundingRequestTotalsSchema';
import { parseAbsoluteOrNull } from '../../utils/parseAbsoluteOrNull';
import { applicationStageSchema } from './applicationStageSchema';
import { applicationLogSchema } from './applicationLogSchema';
import { parseAbsolute } from '@internationalized/date';

export const applicationSchema = z
  .object({
    applicationId: z.string(),
    isAdmin: z.boolean(),
    isBoard: z.boolean(),
    isSubmittable: z.boolean(),
    isClaimsSubmittable: z.boolean(),
    isClaimsReopenable: z.boolean(),
    isCompletable: z.boolean(),
    meetsTourDateRequirement: z.boolean(),
    meetsFundingRequestRequirement: z.boolean(),
    meetsStatementRequirement: z.boolean(),
    meetsOrionRoundRequirement: z.boolean(),
    meetsClaimsTourDateRequirement: z.boolean(),
    meetsClaimsFundingRequestRequirement: z.boolean(),
    meetsClaimsTourSummaryRequirement: z.boolean(),
    meetsClaimsExpenseRequirement: z.boolean(),
    exceedsYearlyTotalForFundingRequests: z.boolean(),
    exceedsYearlyNumberOfTourDatesByRegion: z.string().array(),
    alternateTourDatesAllowed: z.boolean(),
    timezone: z.string(),
    submittedAt: serverDateTimeSchema,
    claimsSubmittedAt: serverDateTimeSchema,
    claimsReopenedAt: serverDateTimeSchema,
    claimsResubmittedAt: serverDateTimeSchema,
    claimsResubmissionDeadline: serverDateTimeSchema,
    reopenClaimsNotes: z.string().nullable(),
    eligibilityDeadline: serverDateSchema.nullable(),
    presentCutoffDate: serverDateSchema,
    currentOrNextRound: fundingRoundSchema.nullable(),
    previousRound: z.object({ title: z.string() }).nullable(),
    applicationRound: fundingRoundSchema.nullable(),
    artist: applicationArtistSchema,
    linkedAccounts: z.array(linkedAccountSchema),
    fundingRequests: fundingRequestSchema.array(),
    statement: z.string(),
    applicationType: applicationTypeSchema,
    contacts: contactSchema.array(),
    primaryContact: linkedAccountSchema,
    tourDates: tourDateSchema.array(),
    tourDateTypes: tourDateTypeSchema.array(),
    tourDateRegions: tourDateRegionSchema.array(),
    countries: countrySchema.array(),
    signedContract: attachmentSchema.nullable(),
    tourSummary: attachmentSchema.nullable(),
    attachments: attachmentSchema.array(),
    status: applicationStatusSchema,
    reviewStatus: applicationReviewStatusSchema,
    stage: applicationStageSchema,
    isOwner: z.boolean(),
    boardMembers: z.array(
      z.object({
        accountId: z.string().uuid(),
        name: z.string(),
      })
    ),
    boardNotes: z
      .object({
        notes: z.string(),
      })
      .nullable(),
    boardBlocks: z
      .array(
        z.object({
          applicationId: z.string().uuid(),
          accountId: z.string().uuid(),
        })
      )
      .nullable(),
    directDepositAccounts: directDepositAccountSchema.array(),
    fundingRequestDirectDepositAccount: directDepositAccountSchema.nullable(),
    touringDirectDepositAccount: directDepositAccountSchema.nullable(),
    latestLog: applicationLogSchema
      .pick({ logTransactionDate: true, name: true })
      .array(),
    maximumYearlyTotalForFundingRequests: moneySchema,
    remainingYearlyTotalForFundingRequests: moneySchema,
  })
  .merge(applicationTotalsSchema)
  .merge(fundingRequestTotalsSchema)
  .transform(
    ({
      timezone,
      latestLog,
      submittedAt,
      claimsSubmittedAt,
      claimsReopenedAt,
      claimsResubmissionDeadline,
      claimsResubmittedAt,
      ...rest
    }) => ({
      timezone,
      latestLog: latestLog[0]
        ? {
            ...latestLog[0],
            logTransactionDate: parseAbsolute(
              latestLog[0].logTransactionDate,
              timezone
            ),
          }
        : null,
      submittedAt: parseAbsoluteOrNull(submittedAt, timezone),
      claimsSubmittedAt: parseAbsoluteOrNull(claimsSubmittedAt, timezone),
      claimsReopenedAt: parseAbsoluteOrNull(claimsReopenedAt, timezone),
      claimsResubmissionDeadline: parseAbsoluteOrNull(
        claimsResubmissionDeadline,
        timezone
      ),
      claimsResubmittedAt: parseAbsoluteOrNull(claimsResubmittedAt, timezone),
      ...rest,
    })
  );
