import {
  ApplicationReviewMachineSender,
  ApplicationReviewMachineState,
} from '../../../machines/Admin/ApplicationReviewMachine';
import { Button } from '../../Button';

import styles from './NoStatusControls.module.scss';

interface Props {
  state: ApplicationReviewMachineState;
  send: ApplicationReviewMachineSender;
}

export function NoStatusControls({ send }: Props) {
  return (
    <div className={styles.noStatusControls}>
      <Button
        size="small"
        label="Approve"
        className={styles.button}
        startIcon="checkCircle"
        onClick={() =>
          send({
            type: 'OPEN_APPROVAL_FORM',
          })
        }
      />
      <Button
        size="small"
        label="Deny"
        className={styles.button}
        startIcon="xCircle"
        onClick={() =>
          send({
            type: 'DENY',
          })
        }
      />
    </div>
  );
}
