import type { EventFrom, Sender } from 'xstate';
import { assign, createMachine } from 'xstate';
import {
  ResetPasswordPromiseResult,
  isSuccess as isResetSuccess,
} from '../promises/resetPasswordPromise';
import {
  ResetPasswordStatusPromiseResult,
  isSuccess as isStatusSuccess,
  getMinimumPasswordLength,
} from '../promises/resetPasswordStatusPromise';
import { StateFrom } from '../utils/StateFrom';
import { MachineOptionsWithContextFrom } from '../utils/MachineOptionsWithContextFrom';
import { logMachineError } from '../utils/logError';

type Context = {
  tag: string;
  password: string;
  confirmPassword: string;
  showPassword: boolean;
  showConfirmPassword: boolean;
  minimumPasswordLength: number;
};

const empty = (a: string, b: string) => a.length === 0 && b.length === 0;

const is =
  (predicate: (a: string, b: string) => boolean) =>
  ({
    password,
    confirmPassword,
  }: Pick<Context, 'password' | 'confirmPassword'>) =>
    predicate(password, confirmPassword);

type Events =
  | { type: 'UPDATE_PASSWORD'; password: string }
  | { type: 'BLUR_PASSWORD' }
  | { type: 'FOCUS_PASSWORD' }
  | { type: 'UPDATE_CONFIRM_PASSWORD'; confirmPassword: string }
  | { type: 'BLUR_CONFIRM_PASSWORD' }
  | { type: 'FOCUS_CONFIRM_PASSWORD' }
  | { type: 'SUBMIT' }
  | { type: 'TOGGLE_PASSWORD_VISIBILITY' }
  | { type: 'TOGGLE_CONFIRM_PASSWORD_VISIBILITY' };

type Services = {
  resetPasswordPromise: {
    data: ResetPasswordPromiseResult;
  };
  resetPasswordStatusPromise: {
    data: ResetPasswordStatusPromiseResult;
  };
};

export const createContext = (tag: string): Context => ({
  tag,
  password: '',
  confirmPassword: '',
  showPassword: false,
  showConfirmPassword: false,
  minimumPasswordLength: 0,
});

export const machine = createMachine(
  {
    predictableActionArguments: true,
    tsTypes: {} as import('./ResetPasswordMachine.typegen').Typegen0,
    schema: {
      context: {} as Context,
      events: {} as Events,
      services: {} as Services,
    },
    id: 'reset-password',
    initial: 'status',
    states: {
      status: {
        initial: 'pending',
        states: {
          pending: {
            invoke: {
              id: 'resetPasswordStatusPromise',
              src: 'resetPasswordStatusPromise',
              onDone: [
                {
                  cond: 'isStatusSuccess',
                  actions: 'updateMinimumLength',
                  target: '#reset-password.form',
                },
                { target: 'failure' },
              ],
              onError: 'error',
            },
          },
          failure: {},
          error: {
            on: {
              SUBMIT: 'pending',
            },
          },
        },
      },
      form: {
        initial: 'in-progress',
        states: {
          'in-progress': {
            type: 'parallel',
            states: {
              password: {
                initial: 'pristine',
                states: {
                  pristine: {},
                  invalid: {
                    initial: 'empty',
                    states: {
                      empty: {},
                      length: {},
                    },
                  },
                  valid: {
                    type: 'final',
                  },
                },
                on: {
                  UPDATE_PASSWORD: {
                    actions: 'updatePassword',
                  },
                  BLUR_PASSWORD: [
                    {
                      cond: 'isEmpty',
                      target: '.invalid.empty',
                    },
                    { cond: 'isPasswordMinimumLength', target: '.valid' },
                    '.invalid.length',
                  ],
                },
              },
              confirmPassword: {
                initial: 'pristine',
                states: {
                  pristine: {},
                  invalid: {
                    initial: 'empty',
                    states: {
                      empty: {},
                      mismatch: {},
                    },
                    on: {
                      BLUR_PASSWORD: [
                        { cond: 'doPasswordsMatch', target: 'valid' },
                        'invalid.mismatch',
                      ],
                    },
                  },
                  valid: {
                    type: 'final',
                    on: {
                      BLUR_PASSWORD: [
                        { cond: 'doPasswordsMatch' },
                        'invalid.mismatch',
                      ],
                    },
                  },
                },
                on: {
                  UPDATE_CONFIRM_PASSWORD: {
                    actions: 'updateConfirmPassword',
                  },

                  BLUR_CONFIRM_PASSWORD: [
                    {
                      cond: 'isEmpty',
                      target: '.invalid.empty',
                    },
                    {
                      cond: 'doPasswordsMatch',
                      target: '.valid',
                    },
                    '.invalid.mismatch',
                  ],
                },
              },
            },
            onDone: 'done',
          },
          done: {
            on: {
              SUBMIT: 'reset',
              FOCUS_PASSWORD: {
                target: [
                  '#reset-password.form.in-progress.password.pristine',
                  '#reset-password.form.in-progress.confirmPassword.valid',
                ],
              },
              FOCUS_CONFIRM_PASSWORD: {
                target: [
                  '#reset-password.form.in-progress.password.valid',
                  '#reset-password.form.in-progress.confirmPassword.pristine',
                ],
              },
            },
          },
          reset: {
            initial: 'pending',
            states: {
              pending: {
                invoke: {
                  id: 'resetPasswordPromise',
                  src: 'resetPasswordPromise',
                  onDone: [
                    { cond: 'isResetSuccess', target: 'success' },
                    { target: 'failure' },
                  ],
                  onError: 'error',
                },
              },
              success: {},
              failure: {},
              error: {
                entry: 'logMachineError',
                on: {
                  SUBMIT: 'pending',
                },
              },
            },
          },
        },
        on: {
          TOGGLE_PASSWORD_VISIBILITY: {
            actions: 'togglePasswordVisibility',
          },
          TOGGLE_CONFIRM_PASSWORD_VISIBILITY: {
            actions: 'toggleConfirmPasswordVisibility',
          },
        },
      },
    },
  },
  {
    guards: {
      isStatusSuccess: (_, { data }) => isStatusSuccess(data),
      isResetSuccess: (_, { data }) => isResetSuccess(data),
      isEmpty: (context, event) => is(empty)({ ...context, ...event }),
      isPasswordMinimumLength: ({ minimumPasswordLength, password }) =>
        password.length >= minimumPasswordLength,
      doPasswordsMatch: ({ password, confirmPassword }) =>
        password === confirmPassword,
    },
    actions: {
      logMachineError,
      updateMinimumLength: assign((_, { data }) => ({
        minimumPasswordLength: getMinimumPasswordLength(data),
      })),
      updatePassword: assign((_, { password }) => ({ password })),
      updateConfirmPassword: assign((_, { confirmPassword }) => ({
        confirmPassword,
      })),
      togglePasswordVisibility: assign(({ showPassword }) => ({
        showPassword: !showPassword,
      })),
      toggleConfirmPasswordVisibility: assign(({ showConfirmPassword }) => ({
        showConfirmPassword: !showConfirmPassword,
      })),
    },
  }
);

export type ResetPasswordMachine = typeof machine;
export type ResetPasswordMachineState = StateFrom<ResetPasswordMachine>;
export type ResetPasswordMachineEvents = EventFrom<ResetPasswordMachine>;
export type ResetPasswordMachineSender = Sender<ResetPasswordMachineEvents>;
export type ResetPasswordMachineOptions =
  MachineOptionsWithContextFrom<ResetPasswordMachine>;
