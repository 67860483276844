import classNames from 'classnames';
import { useEffect } from 'react';
import { match } from 'ts-pattern';
import {
  ReleaseSalesMachineSender,
  ReleaseSalesMachineState,
} from '../../../machines/Release/ReleaseSalesMachine';
import { formatNumber } from '../../../utils/formatNumber';
import { totalUnits } from '../../../utils/totalUnits';
import { ErrorFeedback } from '../../ErrorFeedback';
import { Feedback } from '../../Feedback';
import { FormField } from '../../FormField';
import { InlineFormContainer } from '../../InlineFormContainer';
import { InlineFormHeading } from '../../InlineFormHeading';
import { RangeSlider } from '../../RangeSlider';
import { Select } from '../../Select';
import { TextInput } from '../../TextInput';
import { Tooltip } from '../../Tooltip';
import styles from './SalesView.module.scss';

interface SalesViewProps {
  state: ReleaseSalesMachineState;
  send: ReleaseSalesMachineSender;
}

export function SalesView({ state, send }: SalesViewProps) {
  function blurAll() {
    send({
      type: 'BLUR_GENRE',
    });
    send({
      type: 'BLUR_RELEASES_SCANNED',
    });
    send({
      type: 'BLUR_TRACKS_DOWNLOADED',
    });
    send({
      type: 'BLUR_TRACKS_STREAMED',
    });
  }

  useEffect(() => blurAll(), []);

  const genreId = state.context.values.genreId ?? '';

  const genre = state.context.genres.find((g) => g.id === genreId);

  const releasesScanned = state.context.values.releasesScanned ?? '';
  const tracksDownloaded = state.context.values.tracksDownloaded ?? '';
  const tracksStreamed = state.context.values.tracksStreamed ?? '';

  const totalReleasesScanned = formatNumber(
    totalUnits([
      { value: releasesScanned, divisor: state.context.releasesScannedDivisor },
    ])
  );
  const totalTracksDownloaded = formatNumber(
    totalUnits([
      {
        value: tracksDownloaded,
        divisor: state.context.tracksDownloadedDivisor,
      },
    ])
  );
  const totalTracksStreamed = formatNumber(
    totalUnits([
      { value: tracksStreamed, divisor: state.context.tracksStreamedDivisor },
    ])
  );

  const total = totalUnits([
    { value: releasesScanned, divisor: state.context.releasesScannedDivisor },
    {
      value: tracksDownloaded,
      divisor: state.context.tracksDownloadedDivisor,
    },
    { value: tracksStreamed, divisor: state.context.tracksStreamedDivisor },
  ]);

  const totalTooLow = genre?.minimumSales ? total < genre.minimumSales : false;
  const totalTooHigh = genre?.maximumSales ? total > genre.maximumSales : false;

  return (
    <>
      {match(state)
        .when(
          () => state.matches('ui.edit') || state.matches('ui.saving'),
          () => (
            <InlineFormContainer
              primaryButtonText={
                state.matches('ui.edit') ? 'Save Sales' : 'Saving...'
              }
              primaryButtonDisabled={
                state.matches('ui.saving') ||
                (state.context.releaseIsRestricted && totalTooLow)
              }
              primaryButtonOnClick={() => {
                send({
                  type: 'ATTEMPT_SAVE',
                });
                blurAll();
              }}
              secondaryButtonText="Cancel"
              secondaryButtonOnClick={() => send({ type: 'CANCEL_EDITING' })}
            >
              <InlineFormHeading>Edit Sales</InlineFormHeading>

              <FormField
                label="What genre best describes this release?"
                formFieldStatus={
                  state.matches('genre.error') ? 'error' : 'default'
                }
                feedbackType={
                  state.matches('genre.error') ? 'warning' : 'neutral'
                }
                infoLabel={match(state)
                  .when(
                    (state) => state.matches('genre.error.empty'),
                    () => 'This field is required.'
                  )
                  .otherwise(() => undefined)}
              >
                <Select
                  id="genres"
                  label="Genre"
                  items={state.context.genres}
                  itemToKey={(item) => item?.id}
                  selectedItem={genre}
                  initialSelectedItem={genre}
                  onChange={(genre) =>
                    genre && send({ type: 'UPDATE_GENRE', genreId: genre.id })
                  }
                  getItemText={(genre) =>
                    genre?.title ?? 'Please select a genre'
                  }
                  disabled={!state.context.isAdmin}
                />
              </FormField>

              <FormField
                label="Scanned releases"
                description="Include both physical and digital."
                formFieldStatus={
                  state.matches('releasesScanned.error') ? 'error' : 'default'
                }
                feedbackType={
                  state.matches('releasesScanned.error') ? 'warning' : 'neutral'
                }
                infoLabel={match(state)
                  .when(
                    (state) => state.matches('releasesScanned.error.empty'),
                    () => 'This field is required.'
                  )
                  .when(
                    (state) =>
                      state.matches('releasesScanned.error.incorrectType'),
                    () => 'Please enter a valid number of releases scanned.'
                  )
                  .otherwise(() => undefined)}
              >
                <div className={styles.row}>
                  <div>
                    <TextInput
                      id="releases_scanned"
                      inputMode="numeric"
                      maxLength={12}
                      value={state.context.values.releasesScanned ?? ''}
                      onChange={(releasesScanned) =>
                        send({
                          type: 'UPDATE_RELEASES_SCANNED',
                          releasesScanned,
                        })
                      }
                      onBlur={() => send('BLUR_RELEASES_SCANNED')}
                    />
                  </div>
                  <p className={styles.cellDivision}></p>
                  <p className={styles.cellDivisor}></p>
                  <p className={styles.cellEquals}>=</p>
                  <p className={styles.cellResult}>{totalReleasesScanned}</p>
                </div>
              </FormField>

              <FormField
                label="Downloaded Tracks"
                formFieldStatus={
                  state.matches('tracksDownloaded.error') ? 'error' : 'default'
                }
                feedbackType={
                  state.matches('tracksDownloaded.error')
                    ? 'warning'
                    : 'neutral'
                }
                infoLabel={match(state)
                  .when(
                    (state) => state.matches('tracksDownloaded.error.empty'),
                    () => 'This field is required.'
                  )
                  .when(
                    (state) =>
                      state.matches('tracksDownloaded.error.incorrectType'),
                    () => 'Please enter a valid number of tracks downloaded.'
                  )
                  .otherwise(() => undefined)}
              >
                <div className={styles.row}>
                  <div>
                    <TextInput
                      id="tracks_downloaded"
                      inputMode="numeric"
                      maxLength={12}
                      value={state.context.values.tracksDownloaded ?? ''}
                      onChange={(tracksDownloaded) =>
                        send({
                          type: 'UPDATE_TRACKS_DOWNLOADED',
                          tracksDownloaded,
                        })
                      }
                      onBlur={() => send('BLUR_TRACKS_DOWNLOADED')}
                    />
                  </div>

                  <p className={styles.cellDivision}>÷</p>
                  <p className={styles.cellDivisor}>
                    {formatNumber(state.context.tracksDownloadedDivisor)}
                  </p>
                  <p className={styles.cellEquals}>=</p>
                  <p className={styles.cellResult}>{totalTracksDownloaded}</p>
                </div>
              </FormField>

              <FormField
                label="Streamed Tracks"
                formFieldStatus={
                  state.matches('tracksStreamed.error') ? 'error' : 'default'
                }
                feedbackType={
                  state.matches('tracksStreamed.error') ? 'warning' : 'neutral'
                }
                infoLabel={match(state)
                  .when(
                    (state) => state.matches('tracksStreamed.error.empty'),
                    () => 'This field is required.'
                  )
                  .when(
                    (state) =>
                      state.matches('tracksStreamed.error.incorrectType'),
                    () => 'Please enter a valid number of streamed tracks.'
                  )
                  .otherwise(() => undefined)}
              >
                <div className={styles.row}>
                  <div>
                    <TextInput
                      id="tracks_streamed"
                      inputMode="numeric"
                      maxLength={12}
                      value={tracksStreamed}
                      onChange={(tracksStreamed) =>
                        send({ type: 'UPDATE_TRACKS_STREAMED', tracksStreamed })
                      }
                      onBlur={() => send('BLUR_TRACKS_STREAMED')}
                    />
                  </div>
                  <p className={styles.cellDivision}>÷</p>
                  <p className={styles.cellDivisor}>
                    {formatNumber(state.context.tracksStreamedDivisor)}
                  </p>
                  <p className={styles.cellEquals}>=</p>
                  <p className={styles.cellResult}>{totalTracksStreamed}</p>
                </div>
              </FormField>
              <hr />

              <div className={styles.total}>
                <p className={styles.cellTitle}>Total units for eligibility</p>
                <p>=</p>
                <p className={styles.totalUnits}>{formatNumber(total)}</p>
              </div>

              {totalTooLow && genre && (
                <Feedback type="warning" size="compact">
                  Releases in the {genre.title} genre must have at least{' '}
                  {formatNumber(genre.minimumSales)} units of sales to be
                  eligible.
                </Feedback>
              )}
              {totalTooHigh && genre && (
                <Feedback type="warning">
                  Releases in the {genre.title} genre must have at most{' '}
                  {formatNumber(genre.maximumSales)} units of sales to be
                  eligible.
                </Feedback>
              )}
              <div>
                <RangeSlider
                  value={total}
                  error={totalTooLow || totalTooHigh}
                  range={
                    genre !== undefined
                      ? {
                          min: genre.minimumSales,
                          max: genre.maximumSales,
                        }
                      : undefined
                  }
                />
              </div>

              <div className={styles.center}>
                <Tooltip
                  status="default"
                  icon="help"
                  label="Why are we asking?"
                >
                  To qualify for funding you must have sold a minimum number of
                  units. This minimum varies by the genre of the release.
                </Tooltip>
              </div>
              {state.matches('wholeForm.error.invalid') && (
                <Feedback
                  title="Please fill in all the fields above."
                  type="warning"
                  className={classNames({
                    'feedback-highlight': state.matches(
                      'wholeForm.error.invalid.highlight'
                    ),
                  })}
                />
              )}
              {state.matches('wholeForm.error.network') && (
                <ErrorFeedback type="network" />
              )}
              {state.matches('wholeForm.error.internal') && <ErrorFeedback />}
            </InlineFormContainer>
          )
        )
        .run()}
    </>
  );
}
