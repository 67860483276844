import { useActor } from '@xstate/react';
import { EligibilityNoticeView } from './EligibilityNoticeView';
import {
  EligibilityNoticeMachineActor,
  EligibilityNoticeMachineState,
} from '../../../machines/EligibilityNoticeMachine';

type EligibilityNoticeControllerProps = {
  actor: EligibilityNoticeMachineActor;
};

export function EligibilityNoticeController({
  actor,
}: EligibilityNoticeControllerProps) {
  const [state, send] = useActor(actor);

  return (
    <EligibilityNoticeView
      state={state as EligibilityNoticeMachineState}
      send={send}
    />
  );
}
