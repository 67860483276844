import { GraphQLClient } from 'graphql-request';
import {
  AddReleaseDocument,
  AddReleaseMutationVariables,
} from '../graphql/operations';

import { CompleteAddReleaseState } from '../machines/AddRelease/state';
import { parsePositiveInteger } from '../utils/parsePositiveInteger';

export const createCreateReleasePromise =
  (client: GraphQLClient) => (variables: AddReleaseMutationVariables) =>
    client.request(AddReleaseDocument, variables);

export type CreateReleasePromise = ReturnType<
  typeof createCreateReleasePromise
>;

export type CreateReleasePromiseResult = Awaited<
  ReturnType<CreateReleasePromise>
>;

export const loadCreateReleaseMutationVariablesFromAddReleaseStorage = (
  artistId: string,
  state: CompleteAddReleaseState
): AddReleaseMutationVariables => ({
  artistId,
  title: state.release.title,
  date: state.release.date,
  numberOfTracks: parsePositiveInteger(state.release.numberOfTracks),
  numberOfMinutes: parsePositiveInteger(state.release.numberOfMinutes),
  isMajorityEnglish: state.release.isMajorityEnglish,
  genreId: state.sales.genreId,
  labelId: state.release.labelId,
  otherLabel: state.release.otherLabel,
  distributorId: state.release.distributorId,
  otherDistributor: state.release.otherDistributor,
  releasesScanned: parsePositiveInteger(state.sales.releasesScanned),
  tracksDownloaded: parsePositiveInteger(state.sales.tracksDownloaded),
  tracksStreamed: parsePositiveInteger(state.sales.tracksStreamed),
  company: state.master.company,
  website: state.master.website ?? '',
  contact: state.master.contact,
  address: state.master.address,
  inCanada: state.master.inCanada,
  phoneNumber: state.master.phoneNumber,
  email: state.master.email,
  notes: state.master.notes ?? '',
});
