import { z } from 'zod';
import { parseAbsolute } from '@internationalized/date';
import { FundingRoundStatusType } from '../../graphql/operations';
import { localDateTimeSchema, serverDateTimeSchema } from '../dateTimeSchema';
import { localDateSchema, serverDateSchema } from '../dateSchema';
import { parseAbsoluteOrNull } from '../../utils/parseAbsoluteOrNull';

export const partialFundingRoundSchema = z.object({
  title: z.string(),
  timezone: z.string(),
  startDate: localDateTimeSchema,
  endDate: localDateTimeSchema,
  boardStartDate: localDateTimeSchema,
  boardEndDate: localDateTimeSchema,
  boardMeetingDate: localDateSchema,
  allowOrion: z.boolean().nullable(),
});

export type PartialFundingRound = z.infer<typeof partialFundingRoundSchema>;

export const completeFundingRoundSchema = partialFundingRoundSchema.extend({
  endDate: partialFundingRoundSchema.shape.endDate.unwrap(),
  boardMeetingDate: partialFundingRoundSchema.shape.boardMeetingDate.unwrap(),
  allowOrion: partialFundingRoundSchema.shape.allowOrion.unwrap(),
});

export type CompleteFundingRound = z.infer<typeof completeFundingRoundSchema>;

export const fundingRoundSchema = completeFundingRoundSchema
  .extend({
    id: z.string().uuid(),

    startDate: serverDateTimeSchema,
    endDate: serverDateTimeSchema.unwrap(),
    boardStartDate: serverDateTimeSchema,
    boardEndDate: serverDateTimeSchema,

    contractDeadline: serverDateTimeSchema.unwrap(),
    claimDeadline: serverDateTimeSchema.unwrap(),

    boardMeetingDate: serverDateSchema,
    futureCutoffDate: serverDateSchema,

    active: z.boolean(),
    status: z.nativeEnum(FundingRoundStatusType),

    numberOfApplications: z.number(),
  })
  .transform(
    ({
      timezone,
      startDate,
      endDate,
      boardStartDate,
      boardEndDate,
      contractDeadline,
      claimDeadline,
      ...rest
    }) => ({
      ...rest,
      timezone,
      startDate: parseAbsoluteOrNull(startDate, timezone),
      endDate: parseAbsolute(endDate, timezone),
      boardStartDate: parseAbsoluteOrNull(boardStartDate, timezone),
      boardEndDate: parseAbsoluteOrNull(boardEndDate, timezone),
      contractDeadline: parseAbsolute(contractDeadline, timezone),
      claimDeadline: parseAbsolute(claimDeadline, timezone),
    })
  );

export type FundingRound = z.infer<typeof fundingRoundSchema>;
