import { match } from 'ts-pattern';

export const list = (items: string[], single: string, plural: string) => {
  return match(items.length)
    .with(0, () => '')
    .with(1, () => `${items[0]} ${single}`)
    .with(2, () => `${items[0]} and ${items[1]} ${plural}`)
    .otherwise(() =>
      items.reduce(
        (acc, cur, index) =>
          index === items.length - 1
            ? `${acc}and ${cur} ${plural}`
            : `${acc}${cur}, `,
        ''
      )
    );
};
