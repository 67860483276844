import { HowToApply } from '../components/CTA/HowToApply';
import { Card } from '../components/Card';
import { Footer } from '../components/Footer';
import { HeroSection } from '../components/HeroSection';
import cn from 'classnames';
import { Link } from '../components/Link';

import { Article } from '../components/Article';
import styles from './About.module.scss';

export function About() {
  return (
    <div className={styles.container}>
      <HeroSection
        title="About the Canadian Starmaker Fund"
        breadcrumbs={[{ text: 'Home', to: '/' }]}
      />
      <div className={cn('max-w-lg', styles.content)}>
        <Article>
          <p>
            The Canadian Starmaker Fund (previously known as the Radio Starmaker
            Fund) was created by Canada’s private radio broadcasters in
            partnership with the{' '}
            <Link href="https://cimamusic.ca/">
              Canadian Independent Music Association (CIMA)
            </Link>{' '}
            and <Link href="https://musiccanada.com/">Music Canada</Link>.
          </p>
          <p>
            Private Broadcasters in Canada have injected over $175 million into
            the Canadian Starmaker Fund (the fund) since 2001 as the sole
            funding source. This funding is used to provide touring and
            promotional support to emerging Canadian artists, as well as provide
            seed funding to artists from diverse cultural backgrounds through
            the Orion program. The fund is a not-for-profit corporation with a
            Board of Directors comprised of music industry experts and
            broadcasters, including{' '}
            <Link href="https://cimamusic.ca/">CIMA</Link> and{' '}
            <Link href="https://musiccanada.com/">Music Canada</Link>{' '}
            appointees. It has become a foundational element in the Canadian
            music industry to help advance the careers of rising stars and boost
            sales of Canadian music.
          </p>
          <h2>Background</h2>
          <p>
            The Canadian Starmaker Fund flows from the{' '}
            <Link href="https://www.cab-acr.ca/">
              Canadian Association of Broadcaster’s
            </Link>{' '}
            (CAB) suggestion during the 1997 Commercial Radio Policy Hearing
            that the existing benefits test be replaced with a minimum
            contribution to FACTOR/MUSICACTION and a new Canadian music
            marketing and promotion fund. The CRTC, in December 2006, following
            its review of radio, endorsed the fund’s operation and further
            extended its mandate for the coming years.
          </p>
        </Article>
      </div>

      <div className={styles.overview}>
        <div className="max-w-xl">
          <h2 className={styles.heading}>
            Overview of the Canadian Starmaker Fund
          </h2>
          <div className={styles.cards}>
            <Card title="Structure and Allocation of Funding" icon="divide">
              The fund is dedicated solely to the English-language music
              industry. An equivalent program, Fonds RadioStar, has been created
              uniquely for French-language Canadian artists.
            </Card>
            <Card title="How is it Funded?" icon="briefcase">
              Radio broadcasters are currently the sole contributors to the fund
              through 3% of benefits payable for licence transfer transactions.
              There is the potential for future contributions through
              alternative sources.
            </Card>
            <Card
              title="Governing Principles and Objectives of the Fund"
              icon="shield"
            >
              <p>
                The Starmaker Fund is an artist-centred vehicle intended to make
                a substantial and discernible difference to the careers of
                Canadian artists who benefit from it.
              </p>
            </Card>
            <Card icon="user" title="Eligibility">
              <p>
                The fund supports emerging artists with star potential from
                every region of Canada. The aim is to allocate funding in such a
                way that it directly impacts artists and supports their touring
                initiatives both in Canada and abroad in a way that will make a
                difference.
              </p>
              <p>
                For complete details regarding eligibility, see{' '}
                <Link href="/requirements">
                  Eligibility Requirements and Funding Rules
                </Link>
                .
              </p>
            </Card>
          </div>
        </div>
        <HowToApply />
      </div>
      <Footer />
    </div>
  );
}
