import { useActor } from '@xstate/react';
import { FundingRequestCardMachineActor } from '../../../machines/components/FundingRequestCardMachine';
import { FundingRequestCardView } from './FundingRequestCardView';

export function FundingRequestCardController({
  actor,
  showExpenseEdit,
  showExpenseDelete,
}: {
  actor: FundingRequestCardMachineActor;
  showExpenseEdit: boolean;
  showExpenseDelete: boolean;
}) {
  const [state, send] = useActor(actor);

  return (
    <FundingRequestCardView
      state={state}
      send={send}
      showExpenseEdit={showExpenseEdit}
      showExpenseDelete={showExpenseDelete}
    />
  );
}
