import { useMachine } from '@xstate/react';
import {
  SearchArtistsMachineOptions,
  machine,
} from '../../../machines/Admin/Artists/SearchArtistsMachine';
import { SearchArtistsView } from './SearchArtistsView';

type SearchArtistsControllerProps = {
  options: SearchArtistsMachineOptions;
};

export function SearchArtistsController({
  options,
}: SearchArtistsControllerProps) {
  const [state, send] = useMachine(machine, options);

  return <SearchArtistsView state={state} send={send} />;
}
