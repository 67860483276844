import type {
  Sender,
  EventFrom,
  MachineOptionsFrom,
  ActorRefFrom,
} from 'xstate';
import { assign, createMachine } from 'xstate';
import { StateFrom } from '../../utils/StateFrom';
import { StatesConfig } from '../../utils/StateConfig';
import { match } from 'ts-pattern';
import { DeleteAccountArtistBindingPromiseResult } from '../../promises/createDeleteAccountArtistBindingPromise';
import { GetDashboardContextPromiseResult } from '../../promises/getDashboardContextPromise';
import { CreateArtistInvitationPromiseResult } from '../../promises/createArtistInvitationPromise';
import { DeleteArtistInvitationPromiseResult } from '../../promises/deleteArtistInvitationPromise';
import { isEmailValid } from '../../utils/isEmailValid';
import { logMachineError } from '../../utils/logError';

type Events =
  | { type: 'TOGGLE_OPEN' }
  | { type: 'SET_EMAIL'; email: string }
  | { type: 'BLUR_EMAIL' }
  | { type: 'SEND_INVITATION' }
  | { type: 'DELETE_BINDING'; accountId: string }
  | { type: 'DELETE_PENDING_BINDING'; email: string; invitationId: string };

type Context = {
  artistId: string;
  artistName: string;
  isOwner: boolean;
  isOpen: boolean;
  email: string;
  accounts: GetDashboardContextPromiseResult['accounts'];
  pendingAccountBindings: GetDashboardContextPromiseResult['pendingAccountBindings'];
};

type Services = {
  createArtistInvitationPromise: {
    data: CreateArtistInvitationPromiseResult;
  };
  deleteAccountArtistBindingPromise: {
    data: DeleteAccountArtistBindingPromiseResult;
  };
  deleteArtistInvitationPromise: {
    data: DeleteArtistInvitationPromiseResult;
  };
};

export const createContext = (
  artistId: string,
  artistName: string,
  isOwner: boolean,
  isOpen: boolean,
  email: string,
  accounts: GetDashboardContextPromiseResult['accounts'],
  pendingAccountBindings: GetDashboardContextPromiseResult['pendingAccountBindings']
): Context => ({
  artistId,
  artistName,
  isOwner,
  isOpen,
  email,
  accounts,
  pendingAccountBindings,
});

const inputStates: StatesConfig<Context, Events> = {
  pristine: { on: { SEND_INVITATION: 'invalid' } },
  valid: { type: 'final' },
  invalid: {},
};

export const machine = createMachine(
  {
    predictableActionArguments: true,
    tsTypes: {} as import('./AccountsBlockMachine.typegen').Typegen0,
    schema: {
      context: {} as Context,
      events: {} as Events,
      services: {} as Services,
    },
    initial: 'form',
    states: {
      form: {
        type: 'parallel',
        states: {
          email: {
            initial: 'pristine',
            states: {
              ...inputStates,
              invalid: {
                initial: 'empty',
                states: {
                  empty: {},
                  value: {},
                  duplicate: {},
                },
              },
            },
            on: {
              SET_EMAIL: { actions: 'setEmail' },
              BLUR_EMAIL: [
                { cond: 'isEmpty', target: '.invalid.empty' },
                { cond: 'isEmailInvalid', target: '.invalid.value' },
                { cond: 'isEmailDuplicate', target: '.invalid.duplicate' },
                '.valid',
              ],
            },
          },
        },
        on: {
          SET_EMAIL: { actions: 'setEmail' },
          TOGGLE_OPEN: { actions: 'toggleOpen', target: 'form' },
          DELETE_BINDING: { target: 'delete' },
          DELETE_PENDING_BINDING: { target: 'delete-pending' },
        },
        onDone: {
          target: 'complete',
        },
      },
      delete: {
        invoke: {
          id: 'deleteAccountArtistBindingPromise',
          src: 'deleteAccountArtistBindingPromise',
          onDone: { actions: 'removeAccount', target: 'form' },
          onError: { target: 'error' },
        },
      },
      'delete-pending': {
        invoke: {
          id: 'deleteArtistInvitationPromise',
          src: 'deleteArtistInvitationPromise',
          onDone: { actions: 'removePendingAccount', target: 'form' },
          onError: { target: 'error' },
        },
      },
      send: {
        invoke: {
          id: 'createArtistInvitationPromise',
          src: 'createArtistInvitationPromise',
          onDone: {
            actions: ['addEmail', 'toggleOpen'],
            target: 'form',
          },
          onError: { actions: 'toggleOpen', target: 'error' },
        },
      },
      complete: {
        on: {
          SET_EMAIL: { actions: 'setEmail', target: 'form' },
          SEND_INVITATION: { target: 'send' },
        },
      },
      error: { entry: 'logMachineError' },
    },
  },
  {
    guards: {
      isEmpty: ({ email }, { type }) => {
        const value = match(type)
          .with('BLUR_EMAIL', () => email)
          .exhaustive();

        return value === '' || value === null;
      },
      isEmailInvalid: ({ email }) => !isEmailValid(email),
      isEmailDuplicate: (context) =>
        !!context.pendingAccountBindings.find(
          (binding) => binding.email === context.email
        ),
    },
    actions: {
      logMachineError,
      addEmail: assign((context, event) => ({
        pendingAccountBindings: [
          ...context.pendingAccountBindings,
          {
            email: event.data.email,
            invitationId: event.data.invitationId,
          },
        ],
      })),
      setEmail: assign((context, event) => ({
        email: event.email,
      })),
      toggleOpen: assign((context) => ({
        isOpen: !context.isOpen,
        email: '',
      })),
      removeAccount: assign(({ accounts }, { data: deletedAccountId }) => ({
        accounts: accounts.filter(
          ({ accountId }) => accountId !== deletedAccountId
        ),
      })),
      removePendingAccount: assign((context, event) => ({
        pendingAccountBindings: context.pendingAccountBindings.filter(
          (binding) =>
            binding.invitationId !== event.data.result?.response?.invitationId
        ),
      })),
    },
  }
);

type Machine = typeof machine;

export type AccountsBlockMachine = Machine;
export type AccountsBlockMachineState = StateFrom<Machine>;
export type AccountsBlockMachineSender = Sender<EventFrom<Machine>>;
export type AccountsBlockMachineEvent = EventFrom<Machine>;
export type AccountsBlockMachineOptions = MachineOptionsFrom<Machine, true>;
export type AccountsBlockMachineActor = ActorRefFrom<AccountsBlockMachine>;
