import { match } from 'ts-pattern';
import { FundingRoundStatusType } from '../graphql/operations';

export function getRoundStatusLabel(status: FundingRoundStatusType) {
  return match(status)
    .with(FundingRoundStatusType.ClaimsInProgress, () => 'claims in progress')
    .with(FundingRoundStatusType.Closed, () => 'closed')
    .with(
      FundingRoundStatusType.ClosedForApplications,
      () => 'closed for applications'
    )
    .with(
      FundingRoundStatusType.OpenForApplications,
      () => 'open for applications'
    )
    .with(FundingRoundStatusType.OpenToBoard, () => 'open to board')
    .with(FundingRoundStatusType.Upcoming, () => 'upcoming')
    .exhaustive();
}
