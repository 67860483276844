import { z } from 'zod';
import { GraphQLClient } from 'graphql-request';
import { GetFluxCapacitorDocument } from '../graphql/operations';
import { serverDateTimeSchema } from '../schemas/dateTimeSchema';
import { parseAbsoluteOrNull } from '../utils/parseAbsoluteOrNull';

export const getFluxCapacitor = (client: GraphQLClient) => async () => {
  const result = await client.request(GetFluxCapacitorDocument);

  return z
    .object({
      time: serverDateTimeSchema,
      timezone: z.string(),
      rounds: z
        .object({
          title: z.string(),
          timezone: z.string(),
          startDate: serverDateTimeSchema,
        })
        .transform(({ title, timezone, startDate }) => ({
          title,
          timezone,
          startDate: parseAbsoluteOrNull(startDate, timezone),
        }))
        .array(),
    })
    .transform(({ time, timezone, ...rest }) => ({
      time: parseAbsoluteOrNull(time, timezone),
      timezone,
      ...rest,
    }))
    .parse(result);
};

export type GetFluxCapacitorPromise = ReturnType<typeof getFluxCapacitor>;
export type GetFluxCapacitorPromiseResult = Awaited<
  ReturnType<GetFluxCapacitorPromise>
>;
