import { GraphQLClient } from 'graphql-request';
import { z } from 'zod';
import {
  ChangePasswordDocument,
  ChangePasswordMutationVariables,
  ChangePasswordResponseCode,
} from '../graphql/operations';

export const createChangePasswordPromise =
  (client: GraphQLClient) =>
  async (variables: ChangePasswordMutationVariables) => {
    const request = await client.request(ChangePasswordDocument, variables);

    return z
      .object({
        response: z.object({
          code: z.nativeEnum(ChangePasswordResponseCode),
        }),
      })
      .parse(request.changePassword);
  };

export type ChangePasswordPromise = ReturnType<
  typeof createChangePasswordPromise
>;
export type ChangePasswordPromiseResult = Awaited<
  ReturnType<ChangePasswordPromise>
>;
