import { GraphQLClient } from 'graphql-request';
import {
  DeleteArtistInvitationDocument,
  DeleteArtistInvitationMutation,
  DeleteArtistInvitationMutationVariables,
  DeleteArtistInvitationResponseCode,
} from '../graphql/operations';

export const isInvalidRequest = ({ result }: DeleteArtistInvitationMutation) =>
  result?.response?.code === DeleteArtistInvitationResponseCode.InvalidRequest;

export const isSuccess = ({ result }: DeleteArtistInvitationMutation) =>
  result?.response?.code === DeleteArtistInvitationResponseCode.Success;

export const deleteArtistInvitationPromise =
  (client: GraphQLClient) =>
  (_: unknown, event: DeleteArtistInvitationMutationVariables) =>
    client.request(DeleteArtistInvitationDocument, {
      ...event,
    });

export type DeleteArtistInvitationPromise = ReturnType<
  typeof deleteArtistInvitationPromise
>;

export type DeleteArtistInvitationPromiseResult = Awaited<
  ReturnType<DeleteArtistInvitationPromise>
>;
