import { CalendarDate } from '@internationalized/date';
import { match } from 'ts-pattern';
import type {
  Sender,
  EventFrom,
  MachineOptionsFrom,
  ActorRefFrom,
  RaiseAction,
} from 'xstate';
import { createMachine, assign, raise } from 'xstate';
import { CreateUpsertPaymentPromiseResult } from '../../promises/upsertPaymentPromise';
import { Payment } from '../../schemas/paymentSchema';
import { StateFrom } from '../../utils/StateFrom';
import { logMachineError } from '../../utils/logError';
import { parsePositiveFloat } from '../../utils/parsePositiveFloat';

type Events =
  | { type: 'SAVE' }
  | { type: 'CANCEL' }
  | { type: 'BLUR_TRANSACTION_NUMBER' }
  | { type: 'BLUR_AMOUNT' }
  | { type: 'BLUR_INVOICE_DATE' }
  | { type: 'BLUR_NOTES' }
  | { type: 'SAVE_CHECK' }
  | { type: 'BLUR_CHECK' }
  | { type: 'UPDATE_TRANSACTION_NUMBER'; transactionNumber: string }
  | { type: 'UPDATE_AMOUNT'; amount: string }
  | { type: 'UPDATE_INVOICE_DATE'; invoiceDate: CalendarDate }
  | { type: 'UPDATE_NOTES'; notes: string };

export type Context = Partial<Omit<Payment, 'amount'>> &
  Pick<Payment, 'applicationId' | 'directDepositAccountId'> & {
    data?: CreateUpsertPaymentPromiseResult;
    amount: string;
  };

type Services = {
  upsertPayment: {
    data: CreateUpsertPaymentPromiseResult;
  };
};

export const createContext = (context: Context): Context => ({
  ...context,
});

const fieldConfig = {
  initial: 'pristine',
  states: {
    pristine: {},
    noError: {},
    empty: {},
    invalid: {},
  },
};

export const machine = createMachine(
  {
    id: 'upsertPaymentMachine',
    predictableActionArguments: true,
    tsTypes: {} as import('./UpsertPaymentMachine.typegen').Typegen0,
    schema: {
      context: {} as Context,
      events: {} as Events,
      services: {} as Services,
    },
    initial: 'inputting',
    on: {
      SAVE: [
        { cond: 'isFormValid', target: 'inputting.network.saving' },
        {
          actions: [
            'saveCheck',
            'blurTransactionNumber',
            'blurAmount',
            'blurInvoiceDate',
            'blurNotes',
          ],
        },
      ],
      CANCEL: 'done',
      UPDATE_TRANSACTION_NUMBER: { actions: 'updateTransactionNumber' },
      UPDATE_AMOUNT: { actions: 'updateAmount' },
      UPDATE_INVOICE_DATE: { actions: 'updateInvoiceDate' },
      UPDATE_NOTES: { actions: 'updateNotes' },
      BLUR_TRANSACTION_NUMBER: [
        {
          cond: 'isEmpty',
          target: 'inputting.transactionNumber.empty',
        },
        {
          target: 'inputting.transactionNumber.noError',
          actions: 'blurCheck',
        },
      ],
      BLUR_AMOUNT: [
        {
          cond: 'isEmpty',
          target: 'inputting.amount.empty',
        },
        {
          cond: 'isAmountAlphabetic',
          target: 'inputting.amount.invalid',
        },
        {
          target: 'inputting.amount.noError',
          actions: 'blurCheck',
        },
      ],
      BLUR_INVOICE_DATE: [
        {
          cond: 'isEmpty',
          target: 'inputting.invoiceDate.empty',
        },
        {
          target: 'inputting.invoiceDate.noError',
          actions: 'blurCheck',
        },
      ],
      BLUR_NOTES: [
        {
          cond: 'isEmpty',
          target: 'inputting.notes.empty',
        },
        {
          target: 'inputting.notes.noError',
        },
      ],
    },
    states: {
      inputting: {
        type: 'parallel',
        states: {
          transactionNumber: { ...fieldConfig },
          amount: { ...fieldConfig },
          invoiceDate: { ...fieldConfig },
          notes: { ...fieldConfig },
          network: {
            initial: 'idle',
            states: {
              idle: {},
              saving: {
                invoke: {
                  src: 'upsertPayment',
                  onDone: {
                    target: '#upsertPaymentMachine.done',
                    actions: 'updateData',
                  },
                  onError: 'error',
                },
              },
              error: { entry: 'logMachineError' },
            },
          },
          warning: {
            initial: 'off',
            states: {
              on: {
                on: {
                  SAVE_CHECK: [
                    { target: 'pulse', cond: 'isFormInvalid' },
                    { target: 'off' },
                  ],
                  BLUR_CHECK: [{ target: 'off', cond: 'isFormValid' }],
                },
              },
              off: {
                on: {
                  SAVE_CHECK: {
                    target: 'on',
                    cond: 'isFormInvalid',
                  },
                },
              },
              pulse: {
                after: { 1000: 'on' },
                on: {
                  BLUR_CHECK: [{ target: 'off', cond: 'isFormValid' }],
                },
              },
              error: { entry: 'logMachineError' },
            },
          },
        },
      },
      done: {
        type: 'final',
        data: (context) => context.data,
      },
    },
  },
  {
    guards: {
      isAmountAlphabetic: (context) =>
        Number.isNaN(parsePositiveFloat(context.amount)),
      isEmpty: (context, { type }) =>
        match(type)
          .with('BLUR_TRANSACTION_NUMBER', () =>
            Boolean(context.transactionNumber === '')
          )
          .with('BLUR_AMOUNT', () => !context.amount)
          .with('BLUR_INVOICE_DATE', () => !context.invoiceDate)
          .otherwise(() => false),
      isFormValid: (_, __, { state }) =>
        ['transactionNumber', 'amount', 'invoiceDate'].every((field) =>
          state.matches(`inputting.${field}.noError`)
        ),
      isFormInvalid: (_, __, { state }) =>
        ['transactionNumber', 'amount', 'invoiceDate'].some(
          (field) =>
            state.matches(`inputting.${field}.pristine`) ||
            state.matches(`inputting.${field}.empty`) ||
            state.matches(`inputting.${field}.invalid`)
        ),
    },
    actions: {
      updateTransactionNumber: assign((_, event) => ({
        transactionNumber: event.transactionNumber,
      })),
      updateAmount: assign((_, event) => ({
        amount: event.amount,
      })),
      updateInvoiceDate: assign((_, event) => ({
        invoiceDate: event.invoiceDate,
      })),
      updateNotes: assign((_, event) => ({
        notes: event.notes,
      })),
      blurTransactionNumber: raise({
        type: 'BLUR_TRANSACTION_NUMBER',
      }) as RaiseAction<{
        type: 'BLUR_TRANSACTION_NUMBER';
      }>,
      blurAmount: raise({ type: 'BLUR_AMOUNT' }) as RaiseAction<{
        type: 'BLUR_AMOUNT';
      }>,
      blurInvoiceDate: raise({ type: 'BLUR_INVOICE_DATE' }) as RaiseAction<{
        type: 'BLUR_INVOICE_DATE';
      }>,
      blurNotes: raise({ type: 'BLUR_NOTES' }) as RaiseAction<{
        type: 'BLUR_NOTES';
      }>,
      saveCheck: raise({ type: 'SAVE_CHECK' }) as RaiseAction<{
        type: 'SAVE_CHECK';
      }>,
      blurCheck: raise({ type: 'BLUR_CHECK' }) as RaiseAction<{
        type: 'BLUR_CHECK';
      }>,
      updateData: assign((_, event) => ({
        data: event.data,
      })),
      logMachineError,
    },
  }
);

type Machine = typeof machine;

export type UpsertPaymentMachineState = StateFrom<Machine>;
export type UpsertPaymentMachineSender = Sender<EventFrom<Machine>>;
export type UpsertPaymentMachineOptions = MachineOptionsFrom<Machine, true> & {
  context: Context;
};
export type UpsertPaymentMachineEvent = EventFrom<Machine>;
export type UpsertPaymentMachineActor = ActorRefFrom<Machine>;
