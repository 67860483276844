import { z } from 'zod';
import { GraphQLClient } from 'graphql-request';
import {
  DeleteAccountArtistBindingDocument,
  DeleteAccountArtistBindingMutationVariables,
} from '../graphql/operations';

export const deleteAccountArtistBindingPromise =
  (client: GraphQLClient) =>
  (
    contextVars: Pick<DeleteAccountArtistBindingMutationVariables, 'artistId'>,
    eventVars: Pick<DeleteAccountArtistBindingMutationVariables, 'accountId'>
  ) =>
    client
      .request(DeleteAccountArtistBindingDocument, {
        ...contextVars,
        ...eventVars,
      })
      .then((mutation) =>
        z.string().parseAsync(mutation.result?.accountArtistBinding?.accountId)
      );

export type DeleteAccountArtistBindingPromise = ReturnType<
  typeof deleteAccountArtistBindingPromise
>;
export type DeleteAccountArtistBindingPromiseResult = Awaited<
  ReturnType<DeleteAccountArtistBindingPromise>
>;
