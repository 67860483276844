import {
  History,
  createBrowserHistory,
  createMemoryHistory,
} from '../../utils/history';

import {
  RedeemBoardInvitationState,
  parseRedeemBoardInvitationState,
} from './state';

export type RedeemBoardInvitationHistory = History<RedeemBoardInvitationState>;

export const createBrowserRedeemBoardInvitationHistory =
  (): RedeemBoardInvitationHistory =>
    createBrowserHistory(parseRedeemBoardInvitationState);

export const createMemoryRedeemBoardInvitationHistory =
  (): RedeemBoardInvitationHistory =>
    createMemoryHistory<RedeemBoardInvitationState>();
