import { z } from 'zod';

export const tourDateTypeSchema = z.object({
  id: z.string(),
  title: z.string(),
  minimumTicketedVenueCapacity: z.number().nullable(),
  maximumTicketedVenueCapacity: z.number().nullable(),
});

export type TourDateType = z.infer<typeof tourDateTypeSchema>;
