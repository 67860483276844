import { match, P } from 'ts-pattern';
import { ZonedDateTime } from '@internationalized/date';
import { ApplicationType } from '../../graphql/operations';
import { formatZonedDateTime } from '../../utils/formatZonedDateTime';
import { getInitalPaymentAmount } from '../../utils/getInitalPaymentAmount';
import { Feedback } from '../Feedback';
import { Link } from '../Link';
import { FeedbackPage } from './ApplicationStatusFeedback';

import styles from './ContractUploadedFeedback.module.scss';

interface ContractUploadedFeedbackProps {
  applicationLink?: string;
  applicationType: ApplicationType;
  fundingType: string;
  roundTitle: string;
  claimDeadline: ZonedDateTime;
  location: FeedbackPage;
  hasPayment: boolean;
  hasFundingRequest: boolean;
}

export function ContractUploadedFeedback({
  applicationLink,
  applicationType,
  fundingType,
  roundTitle,
  claimDeadline,
  location,
  hasPayment,
  hasFundingRequest,
}: ContractUploadedFeedbackProps) {
  const feedbackTitle = match(hasPayment)
    .with(
      false,
      () =>
        `Your signed contract and bank details for the ${fundingType} Application for round #${roundTitle} have been uploaded.`
    )
    .with(
      true,
      () =>
        `You've received the first ${getInitalPaymentAmount(
          applicationType
        )} of the approved funding for the ${fundingType} Application for round #${roundTitle}.`
    )
    .exhaustive();

  const contentForPleaseReview = match([
    location,
    applicationType,
    hasFundingRequest,
  ])
    .with(['dashboard', P.any, P.any], () => null)
    .with(['application', ApplicationType.Orion, P.any], () => (
      <p>
        Please review your{' '}
        <Link href="#Funding%20Requests" type="feedback">
          funding requests
        </Link>{' '}
        to ensure they match your provided documentation. You can cancel
        original requests and add alternate requests if needed.
      </p>
    ))
    .with(['application', ApplicationType.Core, false], () => (
      <p>
        Please review your{' '}
        <Link href="#Tour%20Dates" type="feedback">
          tour dates
        </Link>{' '}
        information to ensure it matches your final itinerary and provided
        documentation. You can cancel original dates and add alternate dates if
        needed.
      </p>
    ))
    .with(['application', ApplicationType.Core, true], () => (
      <p>
        Please review your{' '}
        <Link href="#Tour%20Dates" type="feedback">
          tour dates
        </Link>{' '}
        and{' '}
        <Link href="#Digital%20Content" type="feedback">
          digital content request
        </Link>{' '}
        information to ensure it matches your final itinerary and provided
        documentation. You can cancel original dates/requests and add alternate
        dates/requests if needed.
      </p>
    ))
    .exhaustive();

  const contentForPleaseSubmit = match([
    location,
    applicationType,
    hasFundingRequest,
  ])
    .with(['dashboard', ApplicationType.Orion, P.any], () => (
      <p>
        Please{' '}
        <Link href={applicationLink} type="feedback">
          submit your funding claim
        </Link>{' '}
        to receive the remaining funds.
      </p>
    ))
    .with(['dashboard', ApplicationType.Core, false], () => (
      <p>
        Once your tour is completed, please{' '}
        <Link href={applicationLink} type="feedback">
          submit your funding claim
        </Link>{' '}
        to receive the remaining funds.
      </p>
    ))
    .with(['dashboard', ApplicationType.Core, true], () => (
      <p>
        Once your tour and digital content campaign is completed, please{' '}
        <Link href={applicationLink} type="feedback">
          submit your funding claim
        </Link>{' '}
        to receive the remaining funds.
      </p>
    ))
    .with(['application', ApplicationType.Orion, P.any], () => null)
    .with(['application', ApplicationType.Core, false], () => (
      <p>
        Once your tour is completed, please upload your tour summary to receive
        the remaining funds.
      </p>
    ))
    .with(['application', ApplicationType.Core, true], () => (
      <p>
        Once your tour and digital content campaign are completed, please upload
        your tour summary and confirm your digital content expenses to receive
        the remaining funds.
      </p>
    ))
    .exhaustive();

  return (
    <Feedback
      type={hasPayment ? 'positive' : 'neutral'}
      icon="dollarSign"
      title={feedbackTitle}
    >
      <div className={styles.content}>
        {!hasPayment && (
          <p>
            You will be notified once the funds are transferred to your account.
          </p>
        )}

        {contentForPleaseReview}
        {contentForPleaseSubmit}

        <p>
          The deadline to submit your funding claim is{' '}
          {formatZonedDateTime(claimDeadline)}.
        </p>
      </div>
    </Feedback>
  );
}
