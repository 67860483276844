import { Payment } from '../../schemas/paymentSchema';
import { formatCalendarDate } from '../../utils/formatCalendarDate';
import { formatDate } from '../../utils/formatDate';
import { formatMoney } from '../../utils/formatMoney';
import { Button } from '../Button';
import styles from './PaymentHistoryItem.module.scss';

export function PaymentHistoryItem({
  payment,
  onDeleteClick,
  isAdmin,
}: {
  payment: Payment;
  onDeleteClick: (id: string) => void;
  isAdmin: boolean;
}) {
  return (
    <li className={styles.container}>
      <div className={styles.info}>
        <span className={styles.amount}>{formatMoney(payment.amount)}</span>
        <div>
          <dt>Created on: </dt>
          <dd>{formatDate(payment.createdAt, 'numeric')}</dd>
        </div>
        <div>
          <dt>Transaction #: </dt>
          <dd>{payment.transactionNumber}</dd>
        </div>
        <div>
          <dt>Invoice date: </dt>
          <dd>{formatCalendarDate(payment.invoiceDate, 'numeric')}</dd>
        </div>
        {payment.notes && (
          <div className={styles.notes}>
            <dt>Notes: </dt>
            <dd>{payment.notes}</dd>
          </div>
        )}
      </div>
      {isAdmin && (
        <Button
          size="small"
          variant="neutral"
          label="Delete"
          onClick={() => onDeleteClick(payment.id)}
        />
      )}
    </li>
  );
}
