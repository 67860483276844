import { useMachine } from '@xstate/react';
import { GraphQLClient } from 'graphql-request';
import {
  machine,
  ReleaseUseMachineOptions,
} from '../../machines/Release/ReleaseMachine';
import { ReleaseView } from './ReleaseView';

type ReleaseControllerProps = {
  client: GraphQLClient;
  options: ReleaseUseMachineOptions;
  artistId: string;
};

export function ReleaseController({
  options,
  artistId,
}: ReleaseControllerProps) {
  const [state, send] = useMachine(machine, options);

  if (state.matches('releaseDeleted')) {
    return null;
  }

  return <ReleaseView state={state} send={send} artistId={artistId} />;
}
