import { match, P } from 'ts-pattern';
import { ZonedDateTime } from '@internationalized/date';
import {
  ApplicationStatus,
  ApplicationType,
  ApplicationStage,
} from '../../graphql/operations';
import { ClaimSubmittedFeedback } from './ClaimSubmittedFeedback';
import { ContractPendingFeedback } from './ContractPendingFeedback';
import { ContractUploadedFeedback } from './ContractUploadedFeedback';
import { InProgressApplicationFeedback } from './InProgressApplicationFeedback';
import { SubmittedAplicationFeedback } from './SubmittedApplicationFeedback';
import { FundingRound } from '../../schemas/fundingRound/fundingRoundSchema';
import { ClaimsReopenedFeedback } from './ClaimsReopenedFeedback';
import { ApplicationCompletedFeedback } from './ApplicationCompletedFeedback';

export type FeedbackPage = 'dashboard' | 'application';

interface ApplicationStatusFeedbackProps {
  application: {
    status: ApplicationStatus;
    stage: ApplicationStage;
    type: ApplicationType;
    fundingRound: Pick<
      FundingRound,
      'title' | 'boardMeetingDate' | 'claimDeadline'
    > | null;
    hasPayment: boolean;
    hasFundingRequest: boolean;
    claimsResubmissionDeadline: ZonedDateTime | null;
    reopenClaimsNotes: string | null;
  };
  location: FeedbackPage;
  applicationLink?: string;
  isFinalOrionApplication: boolean;
}

export function ApplicationStatusFeedback({
  application,
  location,
  applicationLink,
  isFinalOrionApplication,
}: ApplicationStatusFeedbackProps) {
  const { type: applicationType } = application;
  const fundingType =
    applicationType === ApplicationType.Core ? 'Core Funding' : 'Orion';

  return match(application)
    .with({ status: ApplicationStatus.InProgress }, () => (
      <InProgressApplicationFeedback
        location={location}
        applicationType={applicationType}
        isFinalOrionApplication={isFinalOrionApplication}
      />
    ))
    .with(
      { status: ApplicationStatus.Submitted, fundingRound: P.not(null) },
      ({ fundingRound }) => (
        <SubmittedAplicationFeedback
          fundingType={fundingType}
          boardMeetingDate={fundingRound.boardMeetingDate}
          roundTitle={fundingRound.title}
        />
      )
    )
    .with(
      {
        status: ApplicationStatus.Approved,
        fundingRound: P.not(null),
      },
      (props) =>
        match(props)
          .with(
            {
              stage: ApplicationStage.ClaimsReopened,
              reopenClaimsNotes: P.not(P.nullish),
              claimsResubmissionDeadline: P.not(P.nullish),
            },
            ({ claimsResubmissionDeadline, reopenClaimsNotes }) => (
              <ClaimsReopenedFeedback
                notes={reopenClaimsNotes}
                claimsResubmissionDeadline={claimsResubmissionDeadline}
              />
            )
          )
          .with(
            {
              stage: ApplicationStage.ContractAndDirectDepositPending,
            },
            ({ fundingRound }) => (
              <ContractPendingFeedback
                applicationType={applicationType}
                fundingType={fundingType}
                roundTitle={fundingRound.title}
                applicationLink={applicationLink}
                location={location}
              />
            )
          )
          .with(
            {
              stage: ApplicationStage.ContractAndDirectDepositUploaded,
            },
            ({ fundingRound, hasPayment, hasFundingRequest }) => (
              <ContractUploadedFeedback
                applicationLink={applicationLink}
                applicationType={applicationType}
                claimDeadline={fundingRound.claimDeadline}
                fundingType={fundingType}
                location={location}
                roundTitle={fundingRound.title}
                hasPayment={hasPayment}
                hasFundingRequest={hasFundingRequest}
              />
            )
          )
          .with(
            { stage: ApplicationStage.ClaimsSubmitted },
            ({ fundingRound }) => (
              <ClaimSubmittedFeedback
                fundingType={fundingType}
                roundTitle={fundingRound.title}
              />
            )
          )
          .with(
            { stage: ApplicationStage.ClaimsResubmitted },
            ({ fundingRound }) => (
              <ClaimSubmittedFeedback
                fundingType={fundingType}
                roundTitle={fundingRound.title}
                isResubmission={true}
              />
            )
          )
          .with({ stage: ApplicationStage.Completed }, ({ fundingRound }) => (
            <ApplicationCompletedFeedback
              fundingType={fundingType}
              roundTitle={fundingRound.title}
            />
          ))
          .otherwise(() => null)
    )
    .otherwise(() => null);
}
