import { ReleaseCard } from '../../components/Dashboard/ReleaseCard';
import { IndexPage } from '../../components/IndexPage';
import { InfoBox } from '../../components/InfoBox';
import { useIsAdmin } from '../../hooks/useIsAdmin';
import { GetAllReleasesPageResult } from '../../promises/createGetAllReleasesPagePromise';

interface Props {
  data: GetAllReleasesPageResult;
}

export function AllReleasesView({ data }: Props) {
  const isAdmin = useIsAdmin();
  const { releases, artistId, artistName } = data;
  const canAddRelease = isAdmin || data.isOwner;

  return (
    <IndexPage
      breadcrumbs={[
        ...(isAdmin
          ? [
              {
                text: 'All Artists',
                to: `/admin/artists`,
              },
            ]
          : []),
        {
          text: isAdmin ? 'Artist Dashboard' : 'Dashboard',
          to: `/artist/${artistId}`,
        },
      ]}
      heading={
        <>
          <span className="text-normal">All Releases for </span>
          {artistName}
        </>
      }
      buttonText={canAddRelease ? 'Add Release' : undefined}
      buttonLinkTo={`/artist/${artistId}/releases/add`}
    >
      {releases.length ? (
        releases.map((release) => (
          <ReleaseCard
            id={release.id}
            artistId={artistId}
            key={release.id}
            title={release.title}
            eligible={release.isEligible}
            releaseDate={release.date}
            label={release.otherLabel ?? release.label.name}
          />
        ))
      ) : (
        <InfoBox>You don’t have any releases.</InfoBox>
      )}
    </IndexPage>
  );
}
