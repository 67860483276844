import cn from 'classnames';
import { ReactNode } from 'react';
import { Icon } from './Icon';

import styles from './CheckOption.module.scss';

interface CheckOptionProps {
  checked?: boolean;
  label?: string | ReactNode;
  disabled?: boolean;
  error?: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export function CheckOption({
  checked = false,
  label,
  disabled,
  error,
  onChange,
}: CheckOptionProps) {
  return (
    <label
      className={cn(styles.checkOption, {
        [styles.disabled]: disabled,
        [styles.error]: error,
      })}
    >
      <div className={styles.iconContainer}>
        {checked && <Icon name="checkmark" />}
      </div>
      <input
        type="checkbox"
        onChange={onChange}
        disabled={disabled}
        checked={checked}
      />

      {label}
    </label>
  );
}
