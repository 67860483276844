import { GraphQLClient } from 'graphql-request';
import { useNavigate } from 'react-router-dom';
import usePromise from 'react-use-promise';
import { AllArtistsView } from './AllArtistsView';
import { createGetAllArtistsPagePromise } from '../../promises/createGetAllArtistsPagePromise';
import { useIsAdmin } from '../../hooks/useIsAdmin';
import { Loading } from '../../components/Loading';
import { withDelay } from '../../utils/withDelay';
import { ErrorPage } from '../ErrorPage';
import { withErrorLogging } from '../../utils/withErrorLogging';

type Props = {
  client: GraphQLClient;
  accountId: string;
};

export function AllArtistsController({ client, accountId }: Props) {
  const [result, , state] = usePromise(
    withDelay(
      withErrorLogging(createGetAllArtistsPagePromise(client, { accountId }))
    ),
    []
  );
  const navigate = useNavigate();
  const isAdmin = useIsAdmin();

  if (state === 'pending') {
    return <Loading />;
  }

  if (state === 'rejected') {
    return <ErrorPage />;
  }

  if (isAdmin) {
    navigate('/admin');
    return null;
  }

  const data = {
    ...result,
    artists: result.artists.map((artist) => ({
      ...artist,
    })),
  };

  return <AllArtistsView data={data} />;
}
