import { GraphQLClient } from 'graphql-request';
import {
  CreateCoreAccountInvitationDocument,
  CreateCoreAccountInvitationMutationVariables,
  CreateAccountInvitationResponseCode,
  CreateOrionAccountInvitationDocument,
  CreateOrionAccountInvitationMutationVariables,
} from '../graphql/operations';
import { CompleteEligibilityState } from '../machines/Eligibility/state';
import { parsePositiveInteger } from '../utils/parsePositiveInteger';

export const createCreateCoreAccountInvitationPromise =
  (client: GraphQLClient) =>
  (variables: CreateCoreAccountInvitationMutationVariables) =>
    client.request(CreateCoreAccountInvitationDocument, variables);

export const createCreateOrionAccountInvitationPromise =
  (client: GraphQLClient) =>
  (variables: CreateOrionAccountInvitationMutationVariables) =>
    client.request(CreateOrionAccountInvitationDocument, variables);

export type CreateAccountInvitationPromise = ReturnType<
  | typeof createCreateCoreAccountInvitationPromise
  | typeof createCreateOrionAccountInvitationPromise
>;

export type CreateAccountInvitationPromiseResult = Awaited<
  ReturnType<CreateAccountInvitationPromise>
>;

export const isInvalidRequest = ({
  result,
}: CreateAccountInvitationPromiseResult) =>
  result?.response?.code === CreateAccountInvitationResponseCode.InvalidRequest;

export const isSuccess = ({ result }: CreateAccountInvitationPromiseResult) =>
  result?.response?.code === CreateAccountInvitationResponseCode.Success;

export const getInvitationId = ({
  result,
}: CreateAccountInvitationPromiseResult) =>
  result?.response?.invitationId ?? '';

export const mockDoneData: CreateAccountInvitationPromiseResult = {
  result: {
    response: {
      code: CreateAccountInvitationResponseCode.Success,
      invitationId: '00000000-0000-0000-0000-000000000001',
    },
  },
};

export const mockErrorData: unknown = new Error('This is a mock error.');

export const loadCreateCoreAccountInvitationMutationVariablesFromEligibilityStorage =
  (
    invitationEmail: string,
    state: CompleteEligibilityState
  ): CreateCoreAccountInvitationMutationVariables => ({
    invitationEmail,
    title: state.release.title,
    date: state.release.date,
    numberOfTracks: parsePositiveInteger(state.release.numberOfTracks),
    numberOfMinutes: parsePositiveInteger(state.release.numberOfMinutes),
    isMajorityEnglish: state.release.isMajorityEnglish,
    genreId: state.sales.genreId,
    labelId: state.release.labelId,
    otherLabel: state.release.otherLabel,
    distributorId: state.release.distributorId,
    otherDistributor: state.release.otherDistributor,
    releasesScanned: parsePositiveInteger(state.sales.releasesScanned),
    tracksDownloaded: parsePositiveInteger(state.sales.tracksDownloaded),
    tracksStreamed: parsePositiveInteger(state.sales.tracksStreamed),
    company: state.master.company,
    website: state.master.website ?? '',
    contact: state.master.contact,
    address: state.master.address,
    inCanada: state.master.inCanada,
    phoneNumber: state.master.phoneNumber,
    email: state.master.email,
    notes: state.master.notes ?? '',
  });
