import { Button } from './Button';
import { InfoBox } from './InfoBox';

import styles from './NoSearchResults.module.scss';

interface NoSearchResultsProps {
  onClear: () => void;
}

export function NoSearchResults({ onClear }: NoSearchResultsProps) {
  return (
    <InfoBox>
      There are no results matching your search criteria.
      <br />
      <Button
        className={styles.link}
        onClick={onClear}
        label="Clear all filters"
        variant="underline"
      />{' '}
      or adjust your search.
    </InfoBox>
  );
}
