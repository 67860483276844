import cn from 'classnames';
import { useMultipleSelection, useSelect } from 'downshift';
import { Icon } from './Icon';
import { SelectIcon } from './SelectIcon';

import styles from './Select.module.scss';
import multiSelectStyles from './MultiSelect.module.scss';

export type Props<Item> = {
  id: string;
  items: Item[];
  selectedItems: Item[];
  error?: boolean;
  disabled?: boolean;
  onItemAdded: (item: Item) => void;
  onItemRemoved: (item: Item) => void;
  onBlur?: () => void;
  onFocus?: () => void;
  getItemText: (option: Item) => string;
  className?: string;
  formFieldStatus?: 'default' | 'text' | 'error';
  isLoading?: boolean;
};

export function MultiSelect<Item>(props: Props<Item>) {
  const {
    id,
    items,
    error = false,
    disabled = false,
    selectedItems,
    onItemAdded,
    onItemRemoved,
    onBlur,
    onFocus,
    getItemText,
    className,
    formFieldStatus = 'default',
    isLoading,
  } = props;

  const { getSelectedItemProps, getDropdownProps } = useMultipleSelection({
    selectedItems,
  });

  const {
    isOpen,
    getToggleButtonProps,
    getMenuProps,
    highlightedIndex,
    getItemProps,
  } = useSelect({
    id,
    selectedItem: null,
    labelId: id,
    items,
    onStateChange: ({ type, selectedItem: newSelectedItem }) => {
      switch (type) {
        case useSelect.stateChangeTypes.ToggleButtonKeyDownEnter:
        case useSelect.stateChangeTypes.ToggleButtonKeyDownSpaceButton:
        case useSelect.stateChangeTypes.ItemClick:
        case useSelect.stateChangeTypes.ToggleButtonBlur:
          if (newSelectedItem) {
            onItemAdded(newSelectedItem);
          }
          break;
        default:
          break;
      }
    },
    onIsOpenChange: ({ isOpen }) => {
      if (isOpen) {
        onFocus?.();
      }

      if (!isOpen) {
        onBlur?.();
      }
    },
  });

  const isDisabled = disabled || isLoading;

  return (
    <div
      className={cn(
        styles.selectInput,
        {
          [styles.open]: isOpen === true,
          [styles.closed]: isOpen === false,
          [styles.default]: selectedItems === null,
          [styles.text]: formFieldStatus === 'text',
          [styles.error]: error || formFieldStatus === 'error',
        },
        className
      )}
    >
      <div
        id={id}
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        {...getDropdownProps(getToggleButtonProps())}
        className={cn(styles.button, multiSelectStyles.button, {
          [styles.buttonDefault]: !isDisabled && selectedItems === null,
          [styles.buttonDisabled]: isDisabled,
          [styles.buttonError]: error,
          [styles.buttonOpen]: isOpen === true,
        })}
      >
        <div className={multiSelectStyles.selectedItems}>
          {selectedItems.length > 0 ? (
            selectedItems.map((item, index) => (
              <span
                className={multiSelectStyles.selectedItem}
                key={`selected-item-${index}`}
                {...getSelectedItemProps({
                  selectedItem: item,
                  index,
                })}
              >
                <span className={multiSelectStyles.selectedItemText}>
                  {getItemText(item)}
                </span>
                <button
                  type="button"
                  className={multiSelectStyles.removeButton}
                  onClick={(e) => {
                    e.stopPropagation();
                    onItemRemoved(item);
                  }}
                >
                  <Icon name="x" width={20} height={20} />
                </button>
              </span>
            ))
          ) : (
            <span className={multiSelectStyles.none}>None</span>
          )}
        </div>
        <SelectIcon
          isOpen={isOpen}
          isLoading={isLoading}
          className={isDisabled ? styles.iconDisabled : styles.icon}
        />
      </div>
      <ul
        {...getMenuProps()}
        className={cn(styles.optionList, multiSelectStyles.optionList, {
          [styles.open]: isOpen === true,
        })}
      >
        {items.map((item, index) => (
          <li
            className={cn(styles.option, multiSelectStyles.option)}
            key={index}
          >
            <button
              type="button"
              className={cn(
                styles.optionButton,
                multiSelectStyles.optionButton,
                {
                  [styles.highlighted]: index === highlightedIndex,
                }
              )}
              {...getItemProps({ item, index })}
              value={getItemText(item)}
            >
              {getItemText(item)}
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
}
