import { GraphQLClient } from 'graphql-request';
import { CreateTourDateDocument } from '../../graphql/operations';
import { AddTourDate } from '../../events/AddTourDate';
import { upsertTourDateResultSchema } from '../../schemas/tourDates/upsertTourDateResultSchema';

export const createAddTourDatePromise =
  (client: GraphQLClient) =>
  async (_: unknown, { tourDate }: AddTourDate) => {
    const response = await client.request(CreateTourDateDocument, {
      tourDate,
    });

    return upsertTourDateResultSchema.parse(response.request?.tourDate);
  };

export type CreateTourDatePromise = ReturnType<typeof createAddTourDatePromise>;

export type CreateTourDatePromiseResult = Awaited<
  ReturnType<CreateTourDatePromise>
>;
