import { match } from 'ts-pattern';
import {
  ArtistManagementMachineSender,
  ArtistManagementMachineState,
} from '../../../machines/components/ArtistManagementMachine';
import { GrayBox } from '../../GrayBox';
import { Button } from '../../Button';
import { ManagementForm } from './ManagementForm';
import { findById } from '../../../utils/findById';
import { DescriptionList } from '../../DescriptionList';
import { ErrorFeedback } from '../../ErrorFeedback';
import { Loading } from '../../Loading';

import styles from './ArtistManagementView.module.scss';

interface ArtistManagementViewProps {
  state: ArtistManagementMachineState;
  send: ArtistManagementMachineSender;
  isAdmin: boolean;
}

export function ArtistManagementView({
  state,
  send,
  isAdmin,
}: ArtistManagementViewProps) {
  const isOwner = state.context.isOwner || isAdmin;

  return match(state)
    .when(
      () => state.matches('form'),
      () => (
        <div className={styles.container}>
          <ManagementForm state={state} send={send} />
        </div>
      )
    )
    .when(
      () => state.matches('saving') || state.matches('deleting'),
      () => (
        <GrayBox className={styles.container}>
          <div className={styles.center}>
            <Loading inline={true} />
          </div>
        </GrayBox>
      )
    )
    .when(
      () => state.matches('error'),
      () => (
        <GrayBox className={styles.container}>
          <ErrorFeedback />
        </GrayBox>
      )
    )
    .otherwise(() => {
      const { management, legalStatuses, countries } = state.context;
      return (
        <GrayBox className={styles.container}>
          <div className={styles.block}>
            {management === null ? (
              <>
                <h3 className={styles.heading}>Self-Managed</h3>
                {isOwner && (
                  <Button
                    size="small"
                    length="auto"
                    variant="neutral"
                    label="Add Management Company"
                    onClick={() => {
                      send('OPEN_FORM');
                    }}
                  />
                )}
              </>
            ) : (
              <>
                <h3 className={styles.heading}>{management.name}</h3>
                <div className={styles.details}>
                  <DescriptionList
                    list={{
                      'Management company nationality':
                        findById(countries, management.countryId)?.name || '',
                      'Contact Person': management.contactPerson,
                      Address: management.address,
                    }}
                  />
                  <DescriptionList
                    list={{
                      'Phone Number': management.phoneNumber,
                      Email: management.email,
                      'Company’s legal status': match(
                        findById(legalStatuses, management.legalStatus)
                      )
                        .with(undefined, () => '')
                        .otherwise(
                          ({ name }) =>
                            `${name}${
                              management.legalProvince
                                ? ` (${management.legalProvince})`
                                : ''
                            }`
                        ),
                    }}
                  />
                </div>
                {isOwner && (
                  <div className={styles.buttons}>
                    <Button
                      size="small"
                      variant="secondary"
                      label="Edit Management"
                      startIcon="editPencil"
                      onClick={() => send('OPEN_FORM')}
                    />
                    <Button
                      size="small"
                      variant="neutral"
                      label="Switch to Self-Managed"
                      onClick={() => {
                        if (
                          window.confirm(
                            'Do you really want to become self-managed?'
                          )
                        ) {
                          send('DELETE_MANAGEMENT');
                        }
                      }}
                    />
                  </div>
                )}
              </>
            )}
          </div>
        </GrayBox>
      );
    });
}
