import { createMachine } from 'xstate';
import type { EventFrom, MachineOptionsFrom, Sender } from 'xstate';
import { StateFrom } from '../../../utils/StateFrom';
import { GetEligibilityContextPromiseResult } from '../../../promises/getEligibilityContextPromise';
import { BackEvent } from '../../navigation';
import { logMachineError } from '../../../utils/logError';

type Events = BackEvent;

type Services = {
  getEligibilityContextPromise: {
    data: GetEligibilityContextPromiseResult;
  };
};

export const machine = createMachine(
  {
    predictableActionArguments: true,
    tsTypes: {} as import('./Machine.typegen').Typegen0,
    schema: {
      events: {} as Events,
      services: {} as Services,
    },
    id: 'orionEligibility',
    initial: 'init',
    states: {
      init: {
        invoke: {
          id: 'getEligibilityContextPromise',
          src: 'getEligibilityContextPromise',
          onDone: 'email',
          onError: 'error',
        },
      },
      error: { entry: 'logMachineError' },
      email: {
        invoke: {
          id: 'emailInvitationMachine',
          src: 'emailInvitationMachine',
        },
        on: {
          BACK: { actions: 'close' },
        },
      },
    },
  },
  {
    actions: { logMachineError },
  }
);

type Machine = typeof machine;

export type OrionEligibilityMachineState = StateFrom<Machine>;
export type OrionEligibilityMachineSender = Sender<EventFrom<Machine>>;
export type OrionEligibilityMachineOptions = MachineOptionsFrom<Machine, true>;
