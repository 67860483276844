import { match } from 'ts-pattern';
import classNames from 'classnames';

import { TextInput } from '../../components/TextInput';
import { Button } from '../../components/Button';
import { PageHeader } from '../../components/PageHeader';

import {
  ResetPasswordMachineSender,
  ResetPasswordMachineState,
} from '../../machines/ResetPasswordMachine';
import { FormField } from '../../components/FormField';
import { Form } from '../../components/Form';
import { Feedback } from '../../components/Feedback';
import { Loading } from '../../components/Loading';
import { FormControls } from '../../components/FormControls';

import styles from './ResetPasswordView.module.scss';

type ResetPasswordViewProps = {
  state: ResetPasswordMachineState;
  send: ResetPasswordMachineSender;
};

export function ResetPasswordView({ state, send }: ResetPasswordViewProps) {
  return match(state)
    .when(
      () => state.matches('form.in-progress') || state.matches('form.done'),
      () => (
        <Form
          className={styles.container}
          onSubmit={() => {
            send('SUBMIT');
          }}
        >
          <PageHeader heading="Create New Password">
            Enter the new password you would like to use for your account.
          </PageHeader>
          <div className={classNames(styles.formFields, 'max-w-md')}>
            <FormField
              label="New Password"
              formFieldStatus={
                state.matches('form.in-progress.password.invalid')
                  ? 'error'
                  : 'default'
              }
              infoLabel={match(state)
                .when(
                  () =>
                    state.matches('form.in-progress.password.invalid.length'),
                  () =>
                    `Passwords must be at least ${state.context.minimumPasswordLength} characters long.`
                )
                .when(
                  () =>
                    state.matches('form.in-progress.password.invalid.empty'),
                  () => `This field is required.`
                )
                .otherwise(() => '')}
              feedbackType="warning"
            >
              <TextInput
                id="password"
                type={state.context.showPassword ? 'text' : 'password'}
                label="Password"
                icon={state.context.showPassword ? 'eyeOn' : 'eyeOff'}
                onIconClick={() => send({ type: 'TOGGLE_PASSWORD_VISIBILITY' })}
                value={state.context.password}
                onChange={(password) =>
                  send({ type: 'UPDATE_PASSWORD', password })
                }
                onBlur={() => send('BLUR_PASSWORD')}
                onFocus={() => send('FOCUS_PASSWORD')}
              />
            </FormField>

            <FormField
              label="Confirm New Password"
              formFieldStatus={
                state.matches('form.in-progress.confirmPassword.invalid')
                  ? 'error'
                  : 'default'
              }
              infoLabel={match(state)
                .when(
                  () =>
                    state.matches(
                      'form.in-progress.confirmPassword.invalid.empty'
                    ),
                  () => 'This field is required.'
                )
                .when(
                  () =>
                    state.matches(
                      'form.in-progress.confirmPassword.invalid.mismatch'
                    ),
                  () => "Your passwords don't match."
                )
                .otherwise(() => '')}
              feedbackType="warning"
            >
              <TextInput
                id="confirmPassword"
                type={state.context.showConfirmPassword ? 'text' : 'password'}
                label="Confirm Password"
                icon={state.context.showConfirmPassword ? 'eyeOn' : 'eyeOff'}
                onIconClick={() =>
                  send({ type: 'TOGGLE_CONFIRM_PASSWORD_VISIBILITY' })
                }
                value={state.context.confirmPassword}
                onChange={(confirmPassword) =>
                  send({
                    type: 'UPDATE_CONFIRM_PASSWORD',
                    confirmPassword,
                  })
                }
                onBlur={() => send('BLUR_CONFIRM_PASSWORD')}
                onFocus={() => send('FOCUS_CONFIRM_PASSWORD')}
              />
            </FormField>
          </div>
          <FormControls className="max-w-md">
            <Button
              variant="primary"
              label="Reset Password"
              disabled={!state.matches('form.done')}
              type="submit"
            />
          </FormControls>
        </Form>
      )
    )
    .when(
      () => state.matches('form.reset.success'),
      () => (
        <>
          <PageHeader heading="Password Reset Success" />
          <div className="max-w-lg">
            <Feedback
              type="positive"
              title="Your password has been reset"
              icon="checkCircle"
            >
              You can now sign in with your new password.
              <Button
                variant="secondary"
                label="Sign In"
                length="auto"
                buttonClassName={styles.feedbackButton}
                linkTo="/sign-in"
              />
            </Feedback>
          </div>
        </>
      )
    )
    .when(
      () =>
        state.matches('status.pending') || state.matches('form.reset.pending'),
      () => (
        <>
          <PageHeader heading="Create New Password" />
          <Loading />
        </>
      )
    )
    .when(
      () =>
        state.matches('status.failure') || state.matches('form.reset.failure'),
      () => (
        <>
          <PageHeader heading="Create New Password" />
          <div className="max-w-md">
            <p className="body-text">
              This password reset request is either expired and can no longer be
              used or is not valid.
            </p>
          </div>
        </>
      )
    )
    .when(
      () => state.matches('status.error') || state.matches('form.reset.error'),
      () => (
        <>
          <PageHeader heading="Reset Password Unavailable" />
          <div className="max-w-md">
            <p className="body-text">
              We were unable to contact the server to validate your password
              reset request. Please ensure you have a functioning internet
              connection and try again.
            </p>
            <FormControls>
              <Button
                variant="primary"
                label="Try Again"
                onClick={() => send('SUBMIT')}
              />
            </FormControls>
          </div>
        </>
      )
    )
    .run();
}
