import { assign, createMachine } from 'xstate';
import type {
  EventFrom,
  MachineOptionsFrom,
  Sender,
  ActorRefFrom,
} from 'xstate';
import { BackEvent, NextEvent, back, next } from '../navigation';
import { match } from 'ts-pattern';
import { sendParent } from 'xstate/lib/actions';
import { StateFrom } from '../../utils/StateFrom';
import { isEmailValid } from '../../utils/isEmailValid';
import { isPhoneNumberValid } from '../../utils/isPhoneNumberValid';
import { GetRedeemAccountInvitationContextPromiseResult } from '../../promises/getRedeemAccountInvitationContext';
import { ReplayableEvent, createReplay } from '../../utils/replay';
import { RedeemAccountInvitationStorage } from './storage';
import { AddArtistStorage } from '../AddArtist/storage';
import { Context as ParentContext } from './Context';

type Countries = GetRedeemAccountInvitationContextPromiseResult['countries'];
type Country = Countries[0];

type Provinces = GetRedeemAccountInvitationContextPromiseResult['provinces'];
type Province = Provinces[0];

type LegalStatuses =
  GetRedeemAccountInvitationContextPromiseResult['legalStatuses'];
type LegalStatus = LegalStatuses[0];

type FocusNameEvent = ReplayableEvent<{ type: 'FOCUS_NAME' }>;
type FocusContactPersonEvent = ReplayableEvent<{
  type: 'FOCUS_CONTACT_PERSON';
}>;
type FocusAddressEvent = ReplayableEvent<{ type: 'FOCUS_ADDRESS' }>;
type FocusPhoneNumberEvent = ReplayableEvent<{ type: 'FOCUS_PHONE_NUMBER' }>;
type FocusEmailEvent = ReplayableEvent<{ type: 'FOCUS_EMAIL' }>;

type UpdateNameEvent = ReplayableEvent<{
  type: 'UPDATE_NAME';
  value: string;
}>;

type UpdateCountryEvent = ReplayableEvent<{
  type: 'UPDATE_COUNTRY';
  value: Country;
}>;

type UpdateContactPersonEvent = ReplayableEvent<{
  type: 'UPDATE_CONTACT_PERSON';
  value: string;
}>;

type UpdateAddressEvent = ReplayableEvent<{
  type: 'UPDATE_ADDRESS';
  value: string;
}>;

type UpdatePhoneNumberEvent = ReplayableEvent<{
  type: 'UPDATE_PHONE_NUMBER';
  value: string;
}>;

type UpdateEmailEvent = ReplayableEvent<{
  type: 'UPDATE_EMAIL';
  value: string;
}>;

type UpdateLegalStatusEvent = ReplayableEvent<{
  type: 'UPDATE_LEGAL_STATUS';
  value: LegalStatus;
}>;

type UpdateLegalProvinceEvent = ReplayableEvent<{
  type: 'UPDATE_LEGAL_PROVINCE';
  value: Province;
}>;

type BlurNameEvent = ReplayableEvent<{ type: 'BLUR_NAME' }>;
type BlurContactPersonEvent = ReplayableEvent<{ type: 'BLUR_CONTACT_PERSON' }>;
type BlurAddressEvent = ReplayableEvent<{ type: 'BLUR_ADDRESS' }>;
type BlurPhoneNumberEvent = ReplayableEvent<{ type: 'BLUR_PHONE_NUMBER' }>;
type BlurEmailEvent = ReplayableEvent<{ type: 'BLUR_EMAIL' }>;

type ReplayableEvents =
  | FocusNameEvent
  | FocusContactPersonEvent
  | FocusAddressEvent
  | FocusPhoneNumberEvent
  | FocusEmailEvent
  | UpdateNameEvent
  | UpdateContactPersonEvent
  | UpdateAddressEvent
  | UpdatePhoneNumberEvent
  | UpdateEmailEvent
  | UpdateCountryEvent
  | UpdateLegalStatusEvent
  | UpdateLegalProvinceEvent
  | BlurNameEvent
  | BlurContactPersonEvent
  | BlurAddressEvent
  | BlurPhoneNumberEvent
  | BlurEmailEvent;

type Events = NextEvent | BackEvent | ReplayableEvents;

type Context = {
  name: string;
  country?: Country;
  contactPerson: string;
  address: string;
  phoneNumber: string;
  email: string;
  legalStatus?: LegalStatus;
  legalProvince?: Province;
  provinces: Provinces;
  countries: Countries;
  legalStatuses: LegalStatuses;
  isOrion: boolean;
  isAddArtist: boolean;
};

type Storage = RedeemAccountInvitationStorage | AddArtistStorage;

const createContext =
  (isAddArtist: boolean) =>
  ({
    provinces,
    countries,
    legalStatuses,
    isOrion,
  }: Pick<
    ParentContext,
    'provinces' | 'countries' | 'legalStatuses' | 'isOrion'
  >): Context => {
    return {
      name: '',
      contactPerson: '',
      address: '',
      phoneNumber: '',
      email: '',
      provinces,
      countries,
      legalStatuses,
      isOrion,
      isAddArtist,
    };
  };

export const createManagementContextForAddArtist = (
  args: Pick<ParentContext, 'provinces' | 'countries' | 'legalStatuses'>
) => createContext(true)({ ...args, isOrion: false });

export const createManagementContext = createContext(false);

export const machine = createMachine(
  {
    predictableActionArguments: true,
    tsTypes: {} as import('./ManagementMachine.typegen').Typegen0,
    schema: {
      events: {} as Events,
      context: {} as Context,
    },
    type: 'parallel',
    invoke: {
      id: 'resume',
      src: 'resume',
    },
    states: {
      form: {
        initial: 'pending',
        states: {
          pending: {
            type: 'parallel',
            states: {
              name: {
                initial: 'pristine',
                states: {
                  pristine: { on: { NEXT: 'empty' } },
                  empty: {},
                  valid: { type: 'final' },
                },
                on: {
                  UPDATE_NAME: {
                    actions: ['setName', 'saveName'],
                  },
                  BLUR_NAME: [
                    { cond: 'isEmpty', target: '.empty' },
                    { target: '.valid' },
                  ],
                },
              },
              country: {
                initial: 'pristine',
                states: {
                  pristine: { on: { NEXT: 'empty' } },
                  empty: {},
                  valid: { type: 'final' },
                },
                on: {
                  UPDATE_COUNTRY: {
                    actions: ['setCountry', 'saveCountry'],
                    target: '.valid',
                  },
                },
              },
              'contact-person': {
                initial: 'pristine',
                states: {
                  pristine: { on: { NEXT: 'empty' } },
                  empty: {},
                  valid: { type: 'final' },
                },
                on: {
                  UPDATE_CONTACT_PERSON: {
                    actions: ['setContactPerson', 'saveContactPerson'],
                  },
                  BLUR_CONTACT_PERSON: [
                    { cond: 'isEmpty', target: '.empty' },
                    { target: '.valid' },
                  ],
                },
              },
              address: {
                initial: 'pristine',
                states: {
                  pristine: { on: { NEXT: 'empty' } },
                  empty: {},
                  valid: { type: 'final' },
                },
                on: {
                  UPDATE_ADDRESS: { actions: ['setAddress', 'saveAddress'] },
                  BLUR_ADDRESS: [
                    { cond: 'isEmpty', target: '.empty' },
                    { target: '.valid' },
                  ],
                },
              },
              'phone-number': {
                initial: 'pristine',
                states: {
                  pristine: { on: { NEXT: 'invalid' } },
                  invalid: {
                    initial: 'empty',
                    states: {
                      empty: {},
                      value: {},
                    },
                  },
                  valid: { type: 'final' },
                },
                on: {
                  UPDATE_PHONE_NUMBER: {
                    actions: ['setPhoneNumber', 'savePhoneNumber'],
                  },
                  BLUR_PHONE_NUMBER: [
                    { cond: 'isEmpty', target: '.invalid.empty' },
                    { cond: 'isValid', target: '.valid' },
                    { target: '.invalid.value' },
                  ],
                },
              },
              email: {
                initial: 'pristine',
                states: {
                  pristine: { on: { NEXT: 'invalid' } },
                  invalid: {
                    initial: 'empty',
                    states: {
                      empty: {},
                      value: {},
                    },
                  },
                  valid: { type: 'final' },
                },
                on: {
                  UPDATE_EMAIL: { actions: ['setEmail', 'saveEmail'] },
                  BLUR_EMAIL: [
                    { cond: 'isEmpty', target: '.invalid.empty' },
                    { cond: 'isValid', target: '.valid' },
                    { target: '.invalid.value' },
                  ],
                },
              },
              'legal-status': {
                initial: 'pristine',
                states: {
                  pristine: { on: { NEXT: 'empty' } },
                  empty: {},
                  partial: {
                    initial: 'default',
                    states: {
                      default: { on: { NEXT: 'empty' } },
                      empty: {},
                    },
                  },
                  valid: { type: 'final' },
                },
                on: {
                  UPDATE_LEGAL_STATUS: [
                    {
                      cond: 'requiresProvince',
                      actions: ['setLegalStatus', 'saveLegalStatus'],
                      target: '.partial',
                    },
                    {
                      actions: ['setLegalStatus', 'saveLegalStatus'],
                      target: '.valid',
                    },
                  ],
                  UPDATE_LEGAL_PROVINCE: {
                    actions: ['setLegalProvince', 'saveLegalProvince'],
                    target: '.valid',
                  },
                },
              },
              // We have to make 'pulse' and 'on' sibling nodes because final
              // states can not have any children. We need them to be final
              // states so that we can transition to the complete state when
              // the form is valid.
              warning: {
                initial: 'off',
                states: {
                  on: {
                    type: 'final',
                    on: { NEXT: 'pulse' },
                  },
                  off: {
                    type: 'final',
                    on: { NEXT: 'on' },
                  },
                  pulse: {
                    type: 'final',
                    after: { 1000: 'on' },
                  },
                },
              },
            },
            onDone: 'complete',
          },
          complete: {
            on: {
              FOCUS_NAME: {
                target: [
                  'pending.name.pristine',
                  'pending.country.valid',
                  'pending.contact-person.valid',
                  'pending.address.valid',
                  'pending.phone-number.valid',
                  'pending.email.valid',
                  'pending.legal-status.valid',
                ],
              },
              UPDATE_COUNTRY: {
                actions: ['setCountry', 'saveCountry'],
              },
              FOCUS_CONTACT_PERSON: {
                target: [
                  'pending.name.valid',
                  'pending.country.valid',
                  'pending.contact-person.pristine',
                  'pending.address.valid',
                  'pending.phone-number.valid',
                  'pending.email.valid',
                  'pending.legal-status.valid',
                ],
              },
              FOCUS_ADDRESS: {
                target: [
                  'pending.name.valid',
                  'pending.country.valid',
                  'pending.contact-person.valid',
                  'pending.address.pristine',
                  'pending.phone-number.valid',
                  'pending.email.valid',
                  'pending.legal-status.valid',
                ],
              },
              FOCUS_PHONE_NUMBER: {
                target: [
                  'pending.name.valid',
                  'pending.country.valid',
                  'pending.contact-person.valid',
                  'pending.address.valid',
                  'pending.phone-number.pristine',
                  'pending.email.valid',
                  'pending.legal-status.valid',
                ],
              },
              FOCUS_EMAIL: {
                target: [
                  'pending.name.valid',
                  'pending.country.valid',
                  'pending.contact-person.valid',
                  'pending.address.valid',
                  'pending.phone-number.valid',
                  'pending.email.pristine',
                  'pending.legal-status.valid',
                ],
              },
              UPDATE_LEGAL_STATUS: [
                {
                  cond: 'requiresProvince',
                  actions: ['setLegalStatus', 'saveLegalStatus'],
                  target: [
                    'pending.name.valid',
                    'pending.country.valid',
                    'pending.contact-person.valid',
                    'pending.address.valid',
                    'pending.phone-number.valid',
                    'pending.email.valid',
                    'pending.legal-status.partial',
                  ],
                },
                {
                  actions: ['setLegalStatus', 'saveLegalStatus'],
                },
              ],
              UPDATE_LEGAL_PROVINCE: {
                actions: ['setLegalProvince', 'saveLegalProvince'],
              },
              NEXT: { actions: 'next' },
            },
          },
        },
      },
      province: {
        initial: 'hidden',
        states: {
          hidden: {},
          shown: {},
        },
        on: {
          UPDATE_LEGAL_STATUS: [
            {
              cond: 'requiresProvince',
              target: '.shown',
            },
            { target: '.hidden' },
          ],
        },
      },
    },
    on: { BACK: { actions: 'back' } },
  },
  {
    guards: {
      isEmpty: (
        { name, contactPerson, address, phoneNumber, email },
        { type }
      ) =>
        match(type)
          .with('BLUR_NAME', () => name)
          .with('BLUR_CONTACT_PERSON', () => contactPerson)
          .with('BLUR_ADDRESS', () => address)
          .with('BLUR_PHONE_NUMBER', () => phoneNumber)
          .with('BLUR_EMAIL', () => email)
          .exhaustive()
          .trim() === '',
      isValid: ({ phoneNumber, email }, { type }) =>
        match(type)
          .with('BLUR_EMAIL', () => isEmailValid(email))
          .with('BLUR_PHONE_NUMBER', () => isPhoneNumberValid(phoneNumber))
          .exhaustive(),
      requiresProvince: (_, { value: { requiresProvince } }) =>
        requiresProvince,
    },
    actions: {
      back: sendParent(back()),
      next: sendParent(next()),
      setName: assign((_, { value: name }) => ({
        name,
      })),
      setCountry: assign((_, { value: country }) => ({
        country,
      })),
      setContactPerson: assign((_, { value: contactPerson }) => ({
        contactPerson,
      })),
      setAddress: assign((_, { value: address }) => ({
        address,
      })),
      setPhoneNumber: assign((_, { value: phoneNumber }) => ({
        phoneNumber,
      })),
      setEmail: assign((_, { value: email }) => ({
        email,
      })),
      setLegalStatus: assign((_, { value: legalStatus }) => ({
        legalStatus,
        legalProvince: undefined,
      })),
      setLegalProvince: assign((_, { value: legalProvince }) => ({
        legalProvince,
      })),
    },
  }
);

export const createManagementResumeService =
  (storage: Storage) =>
  ({ countries, provinces, legalStatuses }: Context) =>
  (send: ManagementMachineSender) => {
    const replay = createReplay<ReplayableEvents>();
    const { management } = storage.get();

    [
      ...(
        [
          [management?.name, 'FOCUS_NAME', 'UPDATE_NAME', 'BLUR_NAME'],
          [management?.email, 'FOCUS_EMAIL', 'UPDATE_EMAIL', 'BLUR_EMAIL'],
          [
            management?.contactPerson,
            'FOCUS_CONTACT_PERSON',
            'UPDATE_CONTACT_PERSON',
            'BLUR_CONTACT_PERSON',
          ],
          [
            management?.address,
            'FOCUS_ADDRESS',
            'UPDATE_ADDRESS',
            'BLUR_ADDRESS',
          ],
          [
            management?.phoneNumber,
            'FOCUS_PHONE_NUMBER',
            'UPDATE_PHONE_NUMBER',
            'BLUR_PHONE_NUMBER',
          ],
        ] satisfies Array<
          [
            string | undefined,
            ReplayableEvents['type'],
            ReplayableEvents['type'],
            ReplayableEvents['type']
          ]
        >
      ).reduce<ReplayableEvents[]>(
        (acc, [value, focus, type, blur]) =>
          value !== undefined
            ? [...acc, { type: focus }, { type, value }, { type: blur }]
            : acc,
        []
      ),
      ...(
        [
          [management?.country, 'UPDATE_COUNTRY'],
          // This event needs to be sent before the legal province since this
          // event always resets the province.
          [management?.legalStatus, 'UPDATE_LEGAL_STATUS'],
          [management?.legalProvince, 'UPDATE_LEGAL_PROVINCE'],
        ] satisfies Array<[string | undefined, ReplayableEvents['type']]>
      ).reduce<ReplayableEvents[]>((acc, [findId, type]) => {
        let event: ReplayableEvents | undefined;

        // Kind of annoying we can't do this better.
        if (type === 'UPDATE_COUNTRY') {
          const value = countries.find(({ id }) => id === findId);
          event = value !== undefined ? { type, value } : undefined;
        } else if (type === 'UPDATE_LEGAL_PROVINCE') {
          const value = provinces.find(({ id }) => id === findId);
          event = value !== undefined ? { type, value } : undefined;
        } else {
          const value = legalStatuses.find(({ id }) => id === findId);
          event = value !== undefined ? { type, value } : undefined;
        }

        return event !== undefined ? [...acc, event] : acc;
      }, []),
    ].forEach((event) => send(replay(event)));
  };

export const createManagementSaveActions = (storage: Storage) => ({
  saveName: (_: Context, { value: name, replay }: UpdateNameEvent) =>
    storage.add({ management: { name } }, replay),
  saveCountry: (
    _: Context,
    { value: { id: country }, replay }: UpdateCountryEvent
  ) => storage.add({ management: { country } }, replay),
  saveContactPerson: (
    _: Context,
    { value: contactPerson, replay }: UpdateContactPersonEvent
  ) => storage.add({ management: { contactPerson } }, replay),
  saveAddress: (_: Context, { value: address, replay }: UpdateAddressEvent) =>
    storage.add({ management: { address } }, replay),
  savePhoneNumber: (
    _: Context,
    { value: phoneNumber, replay }: UpdatePhoneNumberEvent
  ) => storage.add({ management: { phoneNumber } }, replay),
  saveEmail: (_: Context, { value: email, replay }: UpdateEmailEvent) =>
    storage.add({ management: { email } }, replay),
  saveLegalStatus: (
    _: Context,
    { value: { id: legalStatus }, replay }: UpdateLegalStatusEvent
  ) =>
    storage.add(
      { management: { legalStatus, legalProvince: undefined } },
      replay
    ),
  saveLegalProvince: (
    _: Context,
    { value: { id: legalProvince }, replay }: UpdateLegalProvinceEvent
  ) => storage.add({ management: { legalProvince } }, replay),
});

export type ManagementMachine = typeof machine;
export type ManagementMachineState = StateFrom<ManagementMachine>;
export type ManagementMachineSender = Sender<EventFrom<ManagementMachine>>;
export type ManagementMachineOptions = MachineOptionsFrom<
  ManagementMachine,
  true
>;

export type ManagementMachineActor = ActorRefFrom<typeof machine>;
