import { GraphQLClient } from 'graphql-request';
import { ApproveAndNotifyDocument } from '../../graphql/operations';
import { Context } from '../../machines/Admin/ApplicationReviewMachine';
import { adminApplicationFragmentSchema } from '../../schemas/admin/adminApplicationFragmentSchema';

export const createApproveAndNotifyPromise =
  (client: GraphQLClient) =>
  async ({ applicationId, contacts, additionalEmails }: Context) => {
    const emails = contacts
      .filter(({ checked }) => checked)
      .map(({ email }) => email)
      .concat(additionalEmails)
      .join(',');

    const request = await client.request(ApproveAndNotifyDocument, {
      applicationId,
      emails,
    });

    const application = request.result?.query?.application;

    return adminApplicationFragmentSchema.parseAsync(application);
  };

export type CreateApproveAndNotifyPromise = ReturnType<
  typeof createApproveAndNotifyPromise
>;
export type CreateApproveAndNotifyPromiseResult = Awaited<
  ReturnType<CreateApproveAndNotifyPromise>
>;
