import { GraphQLClient } from 'graphql-request';
import { SwapBoardMeetingBlocksDocument } from '../../graphql/operations';
import { Context } from '../../machines/BoardMeeting/BoardMeetingMachine';
import { boardMeetingBlockSchema } from '../../schemas/boardMeetingBlockSchema';

export const createSwapBoardMeetingBlockPromise =
  (client: GraphQLClient) =>
  async (
    { adminBlocks }: Context,
    event: { id: string; direction: 'up' | 'down' }
  ) => {
    const blockIndex = adminBlocks.findIndex(
      ({ block }) => block.id === event.id
    );
    const currentBlock = adminBlocks.at(blockIndex)?.block;
    const swappingBlock = adminBlocks.at(
      blockIndex + (event.direction === 'down' ? 1 : -1)
    )?.block;

    if (!currentBlock || !swappingBlock) {
      throw new Error('No blocks to swap');
    }
    const inputSchema = boardMeetingBlockSchema.omit({ attachments: true });

    const { result } = await client.request(SwapBoardMeetingBlocksDocument, {
      input: {
        currentBlock: inputSchema.parse(currentBlock),
        swappingBlock: inputSchema.parse(swappingBlock),
      },
    });

    return boardMeetingBlockSchema.array().parse(result?.blocks);
  };

export type SwapBoardMeetingBlockPromise = ReturnType<
  typeof createSwapBoardMeetingBlockPromise
>;
export type SwapBoardMeetingBlockPromiseResult = Awaited<
  ReturnType<SwapBoardMeetingBlockPromise>
>;
