import { useMachine } from '@xstate/react';
import queryString from 'query-string';
import { ApplicationSearchView } from './ApplicationSearchView';
import {
  machine,
  ApplicationSearchMachineOptions,
} from '../../machines/Admin/ApplicationSearchMachine';
import { useInitialWindowSize } from '../../hooks/useWindowSize';

type Props = {
  options: ApplicationSearchMachineOptions;
  fundingRoundTitles: string[];
  isBoardView?: boolean;
  boardRound?: string;
};

export function ApplicationSearchController({
  options,
  fundingRoundTitles,
  isBoardView,
  boardRound,
}: Props) {
  const { screen } = useInitialWindowSize();

  const searchVariables = queryString.parse(location.search);

  const [state, send] = useMachine(machine, {
    ...options,
    context: {
      searchVariables: {
        resultLimit: screen === 'desktop' ? 20 : 10,
        resultOffset: 0,
        ...searchVariables,
        ...(isBoardView ? { roundTitle: boardRound } : {}),
        ...(searchVariables.resultLimit
          ? { resultLimit: Number(searchVariables.resultLimit) }
          : {}),
        ...(searchVariables.resultOffset
          ? { resultOffset: Number(searchVariables.resultOffset) }
          : {}),
        ...(searchVariables.initialPaymentPaid
          ? { initialPaymentPaid: Boolean(searchVariables.initialPaymentPaid) }
          : {}),
      },
    },
  });

  return (
    <ApplicationSearchView
      state={state}
      send={send}
      roundTitles={fundingRoundTitles}
      isBoardView={isBoardView}
      boardRound={boardRound}
    />
  );
}
