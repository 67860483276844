import type { EventFrom, MachineOptionsFrom, Sender } from 'xstate';
import queryString from 'query-string';
import { assign, createMachine } from 'xstate';
import { SearchApplicationsQueryVariables } from '../../graphql/operations';
import { CreateSearchApplicationsPromiseResult } from '../../promises/createSearchApplicationsPromise';
import { logMachineError } from '../../utils/logError';
import { StateFrom } from '../../utils/StateFrom';

type Events =
  | {
      type: 'SET_SEARCH_VARIABLES';
      data: SearchApplicationsQueryVariables;
      resetOffset?: boolean;
    }
  | { type: 'SET_SEARCH_QUERY'; data: string; resetOffset?: boolean }
  | { type: 'SEARCH' };

type Services = {
  searchApplicationsPromise: {
    data: CreateSearchApplicationsPromiseResult;
  };
};

export type Context = {
  searchVariables: SearchApplicationsQueryVariables;
  applications: CreateSearchApplicationsPromiseResult['applications'];
  total: number;
};

export const machine = createMachine(
  {
    predictableActionArguments: true,
    tsTypes: {} as import('./ApplicationSearchMachine.typegen').Typegen0,
    id: 'applicationSearch',
    schema: {
      context: {} as Context,
      events: {} as Events,
      services: {} as Services,
    },
    initial: 'loading',
    states: {
      loading: {
        initial: 'initial',
        invoke: {
          id: 'searchApplicationsPromise',
          src: 'searchApplicationsPromise',
          onDone: {
            actions: 'setData',
            target: 'done',
          },
          onError: 'error',
        },
        states: {
          initial: {},
          reload: {},
        },
      },
      error: { entry: 'logMachineError' },
      done: {},
    },
    on: {
      SET_SEARCH_VARIABLES: {
        actions: 'setSearchVariables',
        target: 'loading.reload',
      },
      SET_SEARCH_QUERY: [
        {
          actions: 'setSearchQuery',
          target: 'loading.reload',
          cond: 'atLeastThreeCharsOrNone',
        },
        {
          actions: 'setSearchQuery',
        },
      ],
    },
  },
  {
    actions: {
      logMachineError,
      setData: assign((_, { data }) => ({
        applications: data.applications,
        total: data.total,
      })),
      setSearchVariables: assign((_, { data, resetOffset }) => {
        const searchVariables = {
          ...data,
          ...(resetOffset && { resultOffset: 0 }),
        };

        history.replaceState(
          null,
          '',
          `/admin/search-applications?${queryString.stringify(searchVariables)}`
        );

        return {
          searchVariables,
        };
      }),
      setSearchQuery: assign((context, { data, resetOffset }) => {
        const searchVariables = {
          ...context.searchVariables,
          searchQuery: data === '' ? undefined : data,
          ...(resetOffset && { resultOffset: 0 }),
        };

        history.replaceState(
          null,
          '',
          `/admin/search-applications?${queryString.stringify(searchVariables)}`
        );

        return {
          searchVariables,
        };
      }),
    },
    guards: {
      atLeastThreeCharsOrNone: (_, { data }) => {
        return data.length === 0 || data.length >= 3;
      },
    },
  }
);

type Machine = typeof machine;

export type ApplicationSearchMachineState = StateFrom<Machine>;
export type ApplicationSearchMachineSender = Sender<EventFrom<Machine>>;
export type ApplicationSearchMachineOptions = MachineOptionsFrom<Machine, true>;
