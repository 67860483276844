import { AdminFundingRequest } from '../../schemas/fundingRequests/fundingRequestSchema';
import { parsePositiveFloat } from '../../utils/parsePositiveFloat';

export function prepareAdminFundingRequest({
  approvedCostOverride,
  ...rest
}: AdminFundingRequest) {
  return {
    approvedCostOverride: parsePositiveFloat(approvedCostOverride).toString(),
    ...rest,
  };
}
