import { useNavigate } from 'react-router-dom';
import { useGraphQLClient } from '../../components/GraphQLClientProvider';

import { createPersistentEligibilityStorage } from '../../machines/Eligibility/storage';
import { createBrowserEligibilityHistory } from '../../machines/Eligibility/history';

import { EligibilityController } from './EligibilityController';

import {
  createResumeService,
  createRestartService,
  createSaveActions,
} from '../../machines/Eligibility/Machine';

import {
  machine as ResidentMachine,
  createResidentResume,
  createResidentSaveActions,
} from '../../machines/Eligibility/ResidentMachine';

import {
  machine as ReleaseMachine,
  createReleaseResume,
  createReleaseSaveActions,
} from '../../machines/Eligibility/ReleaseMachine';

import {
  machine as SalesMachine,
  createSalesResume,
  createSalesSaveActions,
} from '../../machines/Eligibility/SalesMachine';

import {
  machine as MasterRecordingMachine,
  createMasterRecordingResume,
  createMasterRecordingSaveActions,
} from '../../machines/Eligibility/MasterRecordingMachine';

import {
  machine as EmailInvitationMachine,
  createEmailInvitationMachineSaveActions,
} from '../../machines/Eligibility/EmailInvitationMachine';

import { createGetEligibilityContextPromise } from '../../promises/getEligibilityContextPromise';
import {
  createCreateCoreAccountInvitationPromise,
  loadCreateCoreAccountInvitationMutationVariablesFromEligibilityStorage,
} from '../../promises/createAccountInvitationPromise';
import { createUpdateAccountInvitationPromise } from '../../promises/updateAccountInvitationPromise';
import { parseCompleteEligibilityState } from '../../machines/Eligibility/state';
import { withDelay } from '../../utils/withDelay';

const storage = createPersistentEligibilityStorage();
const history = createBrowserEligibilityHistory();

export const Eligibility = () => {
  const client = useGraphQLClient();
  const navigate = useNavigate();

  return (
    <EligibilityController
      options={{
        actions: {
          close: () => navigate('/'),
          ...createSaveActions(storage, history),
        },
        services: {
          resume: createResumeService(storage),
          restart: createRestartService(storage, history),
          getEligibilityContextPromise: withDelay(
            createGetEligibilityContextPromise(client)
          ),
          residentMachine: ResidentMachine.withConfig({
            actions: createResidentSaveActions(storage),
            services: {
              resume: createResidentResume(storage),
            },
          }),
          releaseMachine: ReleaseMachine.withConfig({
            actions: createReleaseSaveActions(storage),
            services: {
              resume: createReleaseResume(storage),
            },
          }),
          salesMachine: SalesMachine.withConfig({
            actions: createSalesSaveActions(storage),
            services: {
              resume: createSalesResume(storage),
            },
          }),
          masterRecordingMachine: MasterRecordingMachine.withConfig({
            actions: createMasterRecordingSaveActions(storage),
            services: {
              resume: createMasterRecordingResume(storage),
            },
          }),
          emailInvitationMachine: EmailInvitationMachine.withConfig({
            actions: {
              ...createEmailInvitationMachineSaveActions(storage),
            },
            services: {
              createAccountInvitationPromise: withDelay(({ email }) =>
                parseCompleteEligibilityState(storage.get()).then((state) =>
                  createCreateCoreAccountInvitationPromise(client)(
                    loadCreateCoreAccountInvitationMutationVariablesFromEligibilityStorage(
                      email,
                      state
                    )
                  )
                )
              ),
              updateAccountInvitationPromise: withDelay(
                createUpdateAccountInvitationPromise(client)
              ),
            },
          }),
        },
      }}
    />
  );
};
