import { formatMoney } from '../../utils/formatMoney';
import { Feedback, FeedbackSize } from '../Feedback';

import styles from './InvalidRequestFeedback.module.scss';

interface InvalidRequestFeedbackProps {
  year: number;
  budget: number;
  title?: string;
  size: FeedbackSize;
  children?: React.ReactNode;
}

export function InvalidRequestFeedback({
  year,
  budget,
  title,
  size,
  children,
}: InvalidRequestFeedbackProps) {
  return (
    <Feedback title={title} size={size} type="warning">
      <p className={styles.feedback}>
        Your request exceeds your remaining budget of{' '}
        <span className="body-text-bold">{formatMoney(budget)}</span> for{' '}
        <span className="body-text-bold">{year}</span>.
      </p>
      {children}
    </Feedback>
  );
}
