import { ReactNode } from 'react';
import styles from './InlineFormHeading.module.scss';

interface Props {
  children: ReactNode;
}
export function InlineFormHeading(props: Props) {
  return (
    <div className={styles.container}>
      <h3 className={styles.heading}>{props.children}</h3>
      <div className={styles.line} />
    </div>
  );
}
