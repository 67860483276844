import { match } from 'ts-pattern';
import { PasswordMachineActor } from '../../machines/RedeemInvitation/PasswordMachine';
import { PasswordController } from '../../components/RedeemInvitation/Password/PasswordController';
import {
  RedeemArtistInvitationMachineSender,
  RedeemArtistInvitationMachineState,
} from '../../machines/RedeemArtistInvitation/Machine';
import { AccountController } from '../../components/RedeemInvitation/Account/AccountController';
import { AccountMachineActor } from '../../machines/RedeemInvitation/AccountMachine';
import { TransferController } from '../../components/RedeemArtistInvitation/Transfer/TransferController';
import { TransferMachineActor } from '../../machines/RedeemArtistInvitation/TransferMachine';
import { Loading } from '../../components/Loading';
import { ErrorPage } from '../ErrorPage';

interface RedeemArtistInvitaionViewProps {
  state: RedeemArtistInvitationMachineState;
  send: RedeemArtistInvitationMachineSender;
}

export function RedeemArtistInvitaionView({
  state,
}: RedeemArtistInvitaionViewProps) {
  return (
    <div className="max-w-lg">
      {match(state)
        .when(
          () => state.matches('init.pending'),
          () => <Loading />
        )
        .when(
          () =>
            state.matches('init.rejected') ||
            state.matches('redeeming.rejected'),
          () => <ErrorPage />
        )
        .when(
          () => state.matches('init.invalid-tag'),
          () => (
            <ErrorPage
              title="Invalid invitation tag provided."
              text="Please reach out to the Starmaker team for assistance."
            />
          )
        )
        .when(
          () => state.matches('redeeming.pending'),
          () => <Loading />
        )
        .when(
          () => state.matches('transferring.pending'),
          () => <Loading />
        )
        .when(
          () => state.matches('transferring.resolved'),
          () => <div>Transfer complete!</div>
        )
        .when(
          () => state.matches('transferring.rejected'),
          () => <div>There was an error transferring the invitation.</div>
        )
        .when(
          () => state.matches('linking.pending'),
          () => <Loading />
        )
        .when(
          () => state.matches('linking.rejected'),
          () => <div>There was an error linking the invitation.</div>
        )
        .when(
          () => state.matches('transfer'),
          () => (
            <TransferController
              actor={state.children.transferMachine as TransferMachineActor}
            />
          )
        )
        .when(
          () => state.matches('password'),
          () => (
            <PasswordController
              actor={state.children.passwordMachine as PasswordMachineActor}
            />
          )
        )
        .when(
          () => state.matches('account'),
          () => (
            <AccountController
              actor={state.children.accountMachine as AccountMachineActor}
            />
          )
        )
        .run()}
    </div>
  );
}
