import classNames from 'classnames';
import { match } from 'ts-pattern';
import {
  MasterRecordingMachineSender,
  MasterRecordingMachineState,
} from '../../../machines/Eligibility/MasterRecordingMachine';
import { PageHeader } from '../../PageHeader';
import { FormField } from '../../FormField';
import { TextInput } from '../../TextInput';
import { TextArea } from '../../TextArea';
import { Select } from '../../Select';
import { Button } from '../../Button';
import { Feedback } from '../../Feedback';
import { Link } from '../../Link';
import { Form } from '../../Form';
import { FormControls } from '../../FormControls';

import styles from './MasterRecordingView.module.scss';

type MasterRecordingViewProps = {
  state: MasterRecordingMachineState;
  send: MasterRecordingMachineSender;
  type: 'eligibility' | 'add-release';
};

export const MasterRecordingView = ({
  state,
  send,
  type,
}: MasterRecordingViewProps) => {
  const { label, endIcon, paginationSteps, paginationActive } =
    type === 'add-release'
      ? {
          label: 'Add Release',
          endIcon: undefined,
          paginationSteps: 3,
          paginationActive: 3,
        }
      : {
          label: 'Next',
          endIcon: 'rightChevron' as const,
          paginationSteps: 5,
          paginationActive: 4,
        };

  return (
    <Form className={styles.form} onSubmit={() => send('NEXT')}>
      <PageHeader
        heading="Who owns the master recording?"
        paginationSteps={paginationSteps}
        paginationActive={paginationActive}
        includeBackButton={true}
        backButtonOnClick={() => send('BACK')}
      >
        The master recordings for your release must be Canadian-owned to be
        eligible for funding.
      </PageHeader>
      <div className={classNames(styles.formFields, 'max-w-md')}>
        <FormField
          label="Company"
          formFieldStatus={state.matches('company.empty') ? 'error' : 'default'}
          feedbackType="warning"
          infoLabel={
            state.matches('company.empty') ||
            (state.matches('company.pristine') &&
              state.matches('submitted.yes'))
              ? 'This field is required.'
              : ''
          }
        >
          <TextInput
            id="company"
            label="company"
            value={state.context.company}
            onChange={(company) => send({ type: 'SET_COMPANY', company })}
            onBlur={() => send('BLUR_COMPANY')}
          />
        </FormField>
        <FormField label="Website" note="Optional">
          <TextInput
            id="website"
            label="website"
            value={state.context.website}
            onChange={(website) => send({ type: 'SET_WEBSITE', website })}
          />
        </FormField>
        <FormField
          label="Contact Person"
          formFieldStatus={state.matches('contact.empty') ? 'error' : 'default'}
          feedbackType="warning"
          infoLabel={
            state.matches('contact.empty') ||
            (state.matches('contact.pristine') &&
              state.matches('submitted.yes'))
              ? 'This field is required.'
              : ''
          }
        >
          <TextInput
            id="contact"
            label="contact"
            value={state.context.contact}
            onChange={(contact) => send({ type: 'SET_CONTACT', contact })}
            onBlur={() => send('BLUR_CONTACT')}
          />
        </FormField>
        <FormField
          label="Address"
          description="Include street address, city, prov/state, country, and postal code."
          formFieldStatus={state.matches('address.empty') ? 'error' : 'default'}
          feedbackType="warning"
          infoLabel={
            state.matches('address.empty') ||
            (state.matches('address.pristine') &&
              state.matches('submitted.yes'))
              ? 'This field is required.'
              : ''
          }
        >
          <TextArea
            id="address"
            label="address"
            size="small"
            value={state.context.address}
            onChange={(address) => send({ type: 'SET_ADDRESS', address })}
            onBlur={() => send('BLUR_ADDRESS')}
          />
        </FormField>
        <FormField
          label="Country"
          formFieldStatus={match(state)
            .when(
              (state) => state.matches('country.empty'),
              () => 'error' as const
            )
            .when(
              (state) => state.matches('country.invalid'),
              () => 'error' as const
            )
            .otherwise(() => 'default')}
          feedbackType="warning"
          infoLabel={
            state.matches('country.invalid')
              ? 'The master recording must be Canadian-owned to be eligible for funding.'
              : ''
          }
        >
          <Select
            id="country"
            label="address"
            selectedItem={
              state.context.inCanada ? 'Canada' : 'Outside of Canada'
            }
            items={['Canada', 'Outside of Canada']}
            itemToKey={(item) => item}
            getItemText={(text) => text}
            onChange={(country) =>
              send({
                type: 'SET_COUNTRY',
                inCanada: country === 'Canada',
              })
            }
          />
        </FormField>
        <FormField
          label="Phone Number"
          formFieldStatus={match(state)
            .when(
              (state) => state.matches('phoneNumber.empty'),
              () => 'error' as const
            )
            .when(
              (state) => state.matches('phoneNumber.invalid'),
              () => 'error' as const
            )
            .otherwise(() => 'default')}
          feedbackType="warning"
          infoLabel={
            state.matches('phoneNumber.empty') ||
            (state.matches('phoneNumber.pristine') &&
              state.matches('submitted.yes'))
              ? 'This field is required.'
              : state.matches('phoneNumber.invalid')
              ? 'This phone number appears to be invalid'
              : ''
          }
        >
          <TextInput
            id="phoneNumber"
            label="phone number"
            value={state.context.phoneNumber}
            onChange={(phoneNumber) =>
              send({ type: 'SET_PHONE_NUMBER', phoneNumber })
            }
            onBlur={() => send('BLUR_PHONE_NUMBER')}
          />
        </FormField>
        <FormField
          label="Email"
          formFieldStatus={match(state)
            .when(
              (state) => state.matches('email.empty'),
              () => 'error' as const
            )
            .when(
              (state) => state.matches('email.invalid'),
              () => 'error' as const
            )
            .otherwise(() => 'default')}
          feedbackType="warning"
          infoLabel={
            state.matches('email.empty') ||
            (state.matches('email.pristine') && state.matches('submitted.yes'))
              ? 'This field is required.'
              : state.matches('email.invalid')
              ? 'This email appears to be invalid'
              : ''
          }
        >
          <TextInput
            id="email"
            label="email"
            value={state.context.email}
            onChange={(email) => send({ type: 'SET_EMAIL', email })}
            onBlur={() => send('BLUR_EMAIL')}
          />
        </FormField>
        <FormField label="Additional Notes" note="Optional">
          <TextArea
            id="notes"
            label="notes"
            size="large"
            value={state.context.notes}
            onChange={(notes) => send({ type: 'SET_NOTES', notes })}
          />
        </FormField>
      </div>
      <FormControls>
        {match(state)
          .when(
            (state) => state.matches('country.invalid'),
            () => (
              <Feedback
                type="warning"
                title="This release is not eligible"
                pulse={state.matches('country.invalid.pulse')}
              >
                <div>
                  The master recording must be Canadian-owned to be eligible for
                  funding. If you have another release that may be eligible,
                  please go back and tell us about that release.
                </div>
                <div>
                  Learn more about the{' '}
                  <Link type="feedback" href="/requirements">
                    requirements and rules
                  </Link>
                  .
                </div>
              </Feedback>
            )
          )
          .when(
            (state) => state.matches('submitted.yes.missing'),
            () => (
              <Feedback
                type="warning"
                pulse={state.matches('submitted.yes.missing.pulse')}
              >
                Required fields are missing.
              </Feedback>
            )
          )
          .when(
            (state) => state.matches('submitted.yes.invalid'),
            () => (
              <Feedback
                type="warning"
                pulse={state.matches('submitted.yes.invalid.pulse')}
              >
                Please fix the issues highlighted above.
              </Feedback>
            )
          )
          .otherwise(() => null)}
        <div className="max-w-md">
          <Button type="submit" label={label} endIcon={endIcon} />
        </div>
      </FormControls>
    </Form>
  );
};
