import type {
  Sender,
  EventFrom,
  MachineOptionsFrom,
  ActorRefFrom,
} from 'xstate';
import { assign, createMachine } from 'xstate';
import { StateFrom } from '../../utils/StateFrom';
import { sendParent } from 'xstate/lib/actions';
import { findById } from '../../utils/findById';
import { logMachineError } from '../../utils/logError';
import { ArtistStatus } from '../../graphql/operations';
import {
  CreateUpdateArtistStatusPromiseResult,
  isSuccess,
} from '../../promises/Admin/createUpdateArtistStatusPromise';
import { CreateUpdateArtistDetailsPromiseResult } from '../../promises/createUpdateArtistDetailsPromise';
import { DashboardArtist } from '../../schemas/dashboard/dashboardArtistSchema';
import { Province } from '../../schemas/provinceSchema';
import { LegalStatus } from '../../schemas/legalStatusSchema';

type Artist = DashboardArtist;
type ArtistForm = Pick<
  Artist,
  'name' | 'legalStatus' | 'companyName' | 'provinceId' | 'companyLegalProvince'
>;

type Events =
  | { type: 'OPEN_FORM' }
  | { type: 'CANCEL_FORM' }
  | { type: 'SUBMIT_FORM' }
  | { type: 'SET_FIELD'; field: keyof ArtistForm; value: string }
  | { type: 'UNSET_COMPANY_PROVINCE' }
  | { type: 'OPEN_ADMIN_MENU' }
  | { type: 'CANCEL_ADMIN_MENU' }
  | { type: 'SAVE_ADMIN_MENU' }
  | { type: 'SELECT_ADMIN_MENU'; status: ArtistStatus };

export type Context = {
  artist: Artist;
  form: ArtistForm;
  adminMenu: { status: ArtistStatus };
  provinces: Province[];
  legalStatuses: LegalStatus[];
};

type Services = {
  updateArtistStatusPromise: {
    data: CreateUpdateArtistStatusPromiseResult;
  };
  updateArtistDetails: {
    data: CreateUpdateArtistDetailsPromiseResult;
  };
};

export const createContext = (
  artist: Artist,
  provinces: Province[],
  legalStatuses: LegalStatus[]
): Context => ({
  artist: artist,
  form: {
    name: artist.name,
    provinceId: artist.provinceId,
    companyLegalProvince: artist.companyLegalProvince,
    companyName: artist.companyName,
    legalStatus: artist.legalStatus,
  },
  adminMenu: { status: artist.status },
  provinces,
  legalStatuses,
});

export const machine = createMachine(
  {
    predictableActionArguments: true,
    tsTypes: {} as import('./ArtistDetailsBlockMachine.typegen').Typegen0,
    schema: {
      context: {} as Context,
      events: {} as Events,
      services: {} as Services,
    },
    id: 'artistDetails',
    type: 'parallel',
    states: {
      adminMenu: {
        initial: 'closed',
        states: {
          closed: {
            on: {
              OPEN_ADMIN_MENU: {
                target: 'open',
              },
            },
          },
          open: {
            on: {
              SAVE_ADMIN_MENU: {
                target: 'saving',
              },
              CANCEL_ADMIN_MENU: {
                actions: 'cancelAdminMenu',
                target: 'closed',
              },
              SELECT_ADMIN_MENU: { actions: 'selectStatus' },
            },
          },
          saving: {
            invoke: {
              id: 'updateArtistStatusPromise',
              src: 'updateArtistStatusPromise',
              onDone: [
                {
                  cond: 'isSuccess',
                  actions: ['refreshDashboard'],
                },
                { target: 'error.internal' },
              ],
              onError: { target: 'error.network' },
            },
          },
          error: {
            states: {
              network: {},
              internal: { entry: 'logMachineError' },
            },
          },
        },
      },
      artistForm: {
        initial: 'closed',
        states: {
          closed: {
            on: {
              OPEN_FORM: {
                target: 'open',
              },
            },
          },
          open: {
            initial: 'ready',
            states: {
              ready: {},
              invalid: {},
              error: { entry: 'logMachineError' },
            },
            on: {
              SET_FIELD: { actions: 'setField' },
              UNSET_COMPANY_PROVINCE: { actions: 'unsetCompanyProvince' },
              SUBMIT_FORM: [
                { cond: 'isSubmittable', target: 'saving' },
                { target: '.invalid' },
              ],
              CANCEL_FORM: { actions: 'resetForm', target: 'closed' },
            },
          },
          saving: {
            invoke: {
              id: 'updateArtistDetails',
              src: 'updateArtistDetails',
              onDone: {
                actions: ['sendUpdatedArtistToParent', 'updateArtist'],
                target: 'closed',
              },
              onError: 'open.error',
            },
          },
        },
      },
    },
  },
  {
    guards: {
      isSubmittable: ({ form, legalStatuses }) => {
        const { companyLegalProvince, legalStatus } = form;
        return Boolean(
          (companyLegalProvince ||
            findById(legalStatuses, legalStatus)?.requiresProvince === false) &&
            Object.values(form).every((value) =>
              typeof value === 'string' ? !!value.trim() : true
            )
        );
      },
      isSuccess: (_, { data }) => isSuccess(data),
    },
    actions: {
      logMachineError,
      resetForm: assign(({ artist }) => ({
        form: {
          name: artist.name,
          provinceId: artist.provinceId,
          companyLegalProvince: artist.companyLegalProvince,
          companyName: artist.companyName,
          legalStatus: artist.legalStatus,
        },
      })),
      setField: assign(({ form }, { field, value }) => ({
        form: { ...form, [field]: value },
      })),
      unsetCompanyProvince: assign(({ form }) => ({
        form: { ...form, companyLegalProvince: null },
      })),
      sendUpdatedArtistToParent: sendParent(({ artist, form }) => ({
        type: 'UPDATE_ARTIST',
        artist: { ...artist, ...form },
      })),
      updateArtist: assign(({ artist, form }) => ({
        artist: { ...artist, ...form },
      })),
      selectStatus: assign((_, { status }) => ({
        adminMenu: { status },
      })),
      cancelAdminMenu: assign(({ artist }) => ({
        adminMenu: { status: artist.status },
      })),
      refreshDashboard: sendParent(() => ({
        type: 'REFRESH_DASHBOARD',
      })),
    },
  }
);

type Machine = typeof machine;

export type ArtistDetailsBlockMachine = Machine;
export type ArtistDetailsBlockMachineState = StateFrom<Machine>;
export type ArtistDetailsBlockMachineSender = Sender<EventFrom<Machine>>;
export type ArtistDetailsBlockMachineEvent = EventFrom<Machine>;
export type ArtistDetailsBlockMachineOptions = MachineOptionsFrom<
  Machine,
  true
>;
export type ArtistDetailsBlockMachineActor =
  ActorRefFrom<ArtistDetailsBlockMachine>;
