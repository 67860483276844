import { GraphQLClient } from 'graphql-request';
import {
  UpdateReleaseSalesDocument,
  UpdateReleaseSalesInput,
  UpdateReleaseSalesResponseCode,
} from '../graphql/operations';

export function isSuccess({ result }: UpdateReleaseSalesResult) {
  return (
    result?.updateReleaseSalesResponse?.code ===
    UpdateReleaseSalesResponseCode.Success
  );
}

export const createUpdateReleaseSalesPromise =
  <TContext>(
    client: GraphQLClient,
    releaseId: string,
    contextToInput: (context: TContext) => UpdateReleaseSalesInput
  ) =>
  (context: TContext) =>
    client.request(UpdateReleaseSalesDocument, {
      releaseId: releaseId,
      input: contextToInput(context),
    });

export type UpdateReleaseSales = ReturnType<
  typeof createUpdateReleaseSalesPromise
>;
export type UpdateReleaseSalesResult = Awaited<ReturnType<UpdateReleaseSales>>;
