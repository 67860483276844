import { GraphQLClient } from 'graphql-request';
import {
  UpdateArtistStatusDocument,
  UpdateArtistStatusResponseCode,
} from '../../graphql/operations';
import { Context } from '../../machines/components/ArtistDetailsBlockMachine';

export const createUpdateArtistStatusPromise =
  (client: GraphQLClient) =>
  ({ artist, adminMenu }: Context) =>
    client.request(UpdateArtistStatusDocument, {
      artistId: artist.id,
      status: adminMenu.status,
    });

export type CreateUpdateArtistStatusPromise = ReturnType<
  typeof createUpdateArtistStatusPromise
>;

export type CreateUpdateArtistStatusPromiseResult = Awaited<
  ReturnType<CreateUpdateArtistStatusPromise>
>;

export function isSuccess({
  updateArtistStatus,
}: CreateUpdateArtistStatusPromiseResult) {
  return (
    updateArtistStatus?.response?.code ===
    UpdateArtistStatusResponseCode.Success
  );
}
