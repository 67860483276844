import { match, P } from 'ts-pattern';

export function getHelmetTitle(pathName: string) {
  const pathParts = pathName.split('/').filter((part) => part);

  const title = match(pathParts)
    .with(['about'], () => 'About Us')
    .with(['account', P._], () => 'Account Details')
    .with(['admin', 'accounts'], () => 'Accounts')
    .with(['admin', 'rounds'], () => 'Funding Rounds')
    .with(['admin', 'artists'], () => 'Artists')
    .with(['admin', 'search-applications'], () => 'Search Applications')
    .with(['admin', 'board-members'], () => 'Board Members')
    .with(['admin', 'board-meeting', P._], () => 'Board Meeting')
    .with(['admin'], () => 'Admin Dashboard')
    .with(['artists'], () => 'Artists')
    .with(['artist', P._, 'application', P._], () => 'Application')
    .with(['artist', P._, 'applications'], () => 'All Applications')
    .with(['artist', P._, 'release', P._], () => 'Release')
    .with(['artist', P._, 'releases', 'add'], () => 'Add Release')
    .with(['artist', P._, 'releases'], () => 'All Releases')
    .with(['artist', P._], () => 'Dashboard')
    .with(['board'], () => 'Board of Directors')
    .with(['board-meeting'], () => 'Board Meeting')
    .with(['board-meeting', 'applications'], () => 'Search Applications')
    .with(['contact'], () => 'Contact Us')
    .with(['deadlines'], () => 'Application Deadlines & Board Meeting Dates')
    .with(['eligibility'], () => 'Sign Up')
    .with(['eligibility', 'orion'], () => 'Sign Up')
    .with(['faq'], () => 'FAQ')
    .with(['finances'], () => 'Financial Statements')
    .with(['orion'], () => 'Orion Program')
    .with(['redeem-account-invitation', P._], () => 'Redeem Account Invitation')
    .with(['redeem-artist-invitation', P._], () => 'Redeem Artist Invitation')
    .with(['requirements'], () => 'Requirements & Rules')
    .with(['sign-in'], () => 'Sign In')
    .with(['unsubscribe'], () => 'Unsubscribe')
    .otherwise(() => '');

  return `${title ? `${title} | ` : ''}Canadian Starmaker Fund`;
}
