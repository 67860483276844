import type {
  Sender,
  EventFrom,
  MachineOptionsFrom,
  ActorRefFrom,
} from 'xstate';
import { createMachine, assign, sendParent } from 'xstate';
import { ZonedDateTime } from '@internationalized/date';
import { StateFrom } from '../../utils/StateFrom';
import { AddContactPromiseResult } from '../../promises/contacts/createAddContactPromise';
import { DeleteContactPromiseResult } from '../../promises/contacts/createDeleteContactPromise';
import { isEmailValid } from '../../utils/isEmailValid';
import { refreshApplication } from '../../events/RefreshApplication';
import { Contact } from '../../schemas/contactSchema';
import { LinkedAccount } from '../../schemas/linkedAccountSchema';
import { logMachineError } from '../../utils/logError';
import { UpdateApplicationPromiseResult } from '../../promises/createUpdateApplicationPromise';

type Events =
  | { type: 'ADD_CONTACT'; email: string }
  | { type: 'SELECT_CONTACT'; email: string }
  | { type: 'DELETE_CONTACT'; email: string }
  | { type: 'OPEN_FORM' }
  | { type: 'CANCEL_FORM' }
  | { type: 'SET_EMAIL'; email: string }
  | { type: 'BLUR_EMAIL' }
  | { type: 'SET_PRIMARY_CONTACT'; primaryContact: LinkedAccount };

export type Context = {
  isFormOpen: boolean;
  email: string;
  contacts: Contact[];
  linkedAccounts: LinkedAccount[];
  applicationId: string;
  primaryContact: LinkedAccount;
  submittedAt: ZonedDateTime | null;
};

type Services = {
  addContact: {
    data: AddContactPromiseResult;
  };
  deleteContact: {
    data: DeleteContactPromiseResult;
  };
  updateApplication: {
    data: UpdateApplicationPromiseResult;
  };
};

export const createContext = (
  applicationId: string,
  contacts: Contact[],
  linkedAccounts: LinkedAccount[],
  primaryContact: LinkedAccount,
  submittedAt: ZonedDateTime | null
) => ({
  isFormOpen: false,
  email: '',
  contacts,
  applicationId,
  linkedAccounts,
  primaryContact,
  submittedAt,
});

export const machine = createMachine(
  {
    predictableActionArguments: true,
    tsTypes: {} as import('./ContactsBlockMachine.typegen').Typegen0,
    schema: {
      context: {} as Context,
      events: {} as Events,
      services: {} as Services,
    },
    initial: 'default',
    id: 'form',
    states: {
      default: {},
      save: {
        invoke: {
          id: 'addContact',
          src: 'addContact',
          onDone: {
            actions: ['addContact', 'sendRefreshApplication'],
            target: 'default',
          },
          onError: 'error',
        },
      },
      delete: {
        invoke: {
          id: 'deleteContact',
          src: 'deleteContact',
          onDone: {
            actions: ['deleteContact', 'sendRefreshApplication'],
            target: 'default',
          },
          onError: 'error',
        },
      },
      'update-primary': {
        invoke: {
          id: 'updateApplication',
          src: 'updateApplication',
          onDone: [
            // Update context.contacts because changing the primary contact can remove rows from the contacts table.
            {
              actions: ['updateContacts', 'sendRefreshApplication'],
              target: 'default',
            },
          ],
          onError: 'error',
        },
      },
      invalid: { states: { default: {}, duplicate: {} } },
      error: { entry: 'logMachineError' },
    },
    on: {
      ADD_CONTACT: [
        { cond: 'isDuplicateEmail', target: '.invalid.duplicate' },
        { cond: 'isEmailValid', target: '.save' },
        '.invalid.default',
      ],
      SELECT_CONTACT: {
        actions: 'setEmail',
        target: '.save',
      },
      DELETE_CONTACT: '.delete',
      OPEN_FORM: { actions: 'openForm' },
      CANCEL_FORM: { actions: 'cancelForm' },
      SET_EMAIL: { actions: 'setEmail' },
      BLUR_EMAIL: [
        { cond: 'isEmailValid', target: '.default' },
        '.invalid.default',
      ],
      SET_PRIMARY_CONTACT: {
        actions: 'setPrimaryContact',
        target: '.update-primary',
      },
    },
  },
  {
    guards: {
      isEmailValid: ({ email }) => isEmailValid(email),
      isDuplicateEmail: ({ email, contacts, primaryContact }) =>
        contacts.some((account) => account.email === email) ||
        primaryContact.email === email,
    },
    actions: {
      logMachineError,
      openForm: assign((_) => ({
        isFormOpen: true,
      })),
      cancelForm: assign((_) => ({
        isFormOpen: false,
        email: '',
      })),
      setEmail: assign((_, { email }) => ({
        email,
      })),
      setPrimaryContact: assign((_, { primaryContact }) => ({
        primaryContact,
      })),
      addContact: assign(({ contacts }, { data: email }) => ({
        contacts: [...contacts, { email }],
        email: '',
        isFormOpen: false,
      })),
      deleteContact: assign(({ contacts }, { data: email }) => ({
        contacts: contacts.filter((contact) => contact.email !== email),
      })),
      updateContacts: assign(({ contacts }, { data }) => ({
        contacts: data.request?.application?.contacts ?? contacts,
      })),
      sendRefreshApplication: sendParent(refreshApplication()),
    },
  }
);

type Machine = typeof machine;

export type ContactsBlockMachineState = StateFrom<Machine>;
export type ContactsBlockMachineSender = Sender<EventFrom<Machine>>;
export type ContactsBlockMachineOptions = MachineOptionsFrom<Machine, true> & {
  context: Context;
};
export type ContactsBlockMachineEvent = EventFrom<Machine>;
export type ContactsBlockMachineActor = ActorRefFrom<Machine>;
