import { GraphQLClient } from 'graphql-request';
import {
  ResetPasswordStatusDocument,
  ResetPasswordStatusQueryVariables,
  ResetPasswordStatusResponseCode,
} from '../graphql/operations';

export const resetPasswordStatusPromise =
  (client: GraphQLClient) => (variables: ResetPasswordStatusQueryVariables) =>
    client.request(ResetPasswordStatusDocument, variables);

export type ResetPasswordStatusPromise = ReturnType<
  typeof resetPasswordStatusPromise
>;
export type ResetPasswordStatusPromiseResult = Awaited<
  ReturnType<ResetPasswordStatusPromise>
>;

export const isInvalidTag = ({ status }: ResetPasswordStatusPromiseResult) =>
  status?.code === ResetPasswordStatusResponseCode.InvalidTag;

export const isSuccess = ({ status }: ResetPasswordStatusPromiseResult) =>
  status?.code === ResetPasswordStatusResponseCode.Success;

export const getMinimumPasswordLength = ({
  minimumPasswordLength,
}: ResetPasswordStatusPromiseResult) => minimumPasswordLength ?? undefined;
