import { match } from 'ts-pattern';
import { DashboardBlock } from '../DashboardBlock';
import {
  ArtistDetailsBlockMachineSender,
  ArtistDetailsBlockMachineState,
} from '../../../machines/components/ArtistDetailsBlockMachine';
import { formatZonedDateTime } from '../../../utils/formatZonedDateTime';
import { DescriptionList } from '../../DescriptionList';
import { findById } from '../../../utils/findById';
import { ArtistForm } from './ArtistForm';
import { Loading } from '../../Loading';
import { AdminMenu } from './AdminMenu';

import styles from './ArtistDetailsBlockView.module.scss';

interface ArtistDetailsBlockViewProps {
  state: ArtistDetailsBlockMachineState;
  send: ArtistDetailsBlockMachineSender;
  isAdmin: boolean;
}

export function ArtistDetailsBlockView({
  state,
  send,
  isAdmin,
}: ArtistDetailsBlockViewProps) {
  const isOwner = state.context.artist.isOwner || isAdmin;

  return (
    <DashboardBlock
      title="Artist Details"
      buttonIcon="editPencil"
      buttonText={state.matches('artistForm.closed') && isOwner ? 'Edit' : ''}
      buttonOnClick={() => send('OPEN_FORM')}
    >
      <div className={styles.container}>
        {isAdmin && <AdminMenu state={state} send={send} />}
        {match(state)
          .when(
            () => state.matches('artistForm.open'),
            () => <ArtistForm state={state} send={send} />
          )
          .when(
            () => state.matches('artistForm.saving'),
            () => (
              <div className={styles.loading}>
                <Loading inline={true} />
              </div>
            )
          )
          .otherwise(() => {
            const { provinces, legalStatuses, artist } = state.context;
            const {
              provinceId,
              companyName,
              legalStatus: legalStatusId,
              companyLegalProvince,
              createdAt,
            } = artist;
            return (
              <div className={styles.details}>
                <DescriptionList
                  list={{
                    'Primary place of residency':
                      findById(provinces, provinceId)?.province || '',
                    'Artist’s company name': companyName,
                  }}
                />
                <DescriptionList
                  list={{
                    'Artist’s company legal status': match(
                      findById(legalStatuses, legalStatusId)
                    )
                      .with(undefined, () => '')
                      .otherwise(
                        ({ name }) =>
                          `${name}${
                            companyLegalProvince
                              ? ` (${companyLegalProvince})`
                              : ''
                          }`
                      ),
                    'Created on': formatZonedDateTime(createdAt),
                  }}
                />
              </div>
            );
          })}
      </div>
    </DashboardBlock>
  );
}
