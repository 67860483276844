import { useParams } from 'react-router-dom';
import { useGraphQLClient } from '../../components/GraphQLClientProvider';
import { createContext } from '../../machines/ResetPasswordMachine';
import { resetPasswordPromise } from '../../promises/resetPasswordPromise';
import { resetPasswordStatusPromise } from '../../promises/resetPasswordStatusPromise';
import { ResetPasswordController } from './ResetPasswordController';
import { withDelay } from '../../utils/withDelay';

export function ResetPassword() {
  const { tag } = useParams();
  const client = useGraphQLClient();

  return (
    <ResetPasswordController
      options={{
        context: createContext(tag ?? ''),
        services: {
          resetPasswordPromise: withDelay(resetPasswordPromise(client)),
          resetPasswordStatusPromise: withDelay(
            resetPasswordStatusPromise(client)
          ),
        },
      }}
    />
  );
}
