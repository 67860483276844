import { ZonedDateTime } from '@internationalized/date';

const locale = 'en-CA';

const dateOptions: Intl.DateTimeFormatOptions = {
  year: 'numeric',
  day: 'numeric',
};

const timeOptions: Intl.DateTimeFormatOptions = {
  hour: 'numeric',
  minute: 'numeric',
};

export const formatZonedDate = (
  date: ZonedDateTime | null,
  month: Intl.DateTimeFormatOptions['month'] = 'long'
) => {
  return date !== null
    ? date.toDate().toLocaleDateString(locale, {
        ...dateOptions,
        month,
        timeZone: date.timeZone,
      })
    : '—';
};

export const formatZonedTime = (
  date: ZonedDateTime | null,
  showTimezone = true
) => {
  return date !== null
    ? date.toDate().toLocaleTimeString(locale, {
        ...timeOptions,
        timeZoneName: showTimezone ? 'shortGeneric' : undefined,
        timeZone: date.timeZone,
      })
    : '—';
};

export const formatZonedDateTime = (
  date: ZonedDateTime | null,
  month: Intl.DateTimeFormatOptions['month'] = 'long',
  dateTimeSeparator = ' ',
  showTimezone = true
) => {
  return date !== null
    ? [formatZonedDate(date, month), formatZonedTime(date, showTimezone)].join(
        dateTimeSeparator
      )
    : '—';
};
