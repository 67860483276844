import { z } from 'zod';
import { GraphQLClient } from 'graphql-request';
import {
  FundingRoundStatusType,
  GetHomeContextDocument,
} from '../graphql/operations';
import { serverDateSchema } from '../schemas/dateSchema';

export const createGetHomeContextPromise =
  (client: GraphQLClient) => async () => {
    const result = await client.request(GetHomeContextDocument);

    const { rounds, now } = z
      .object({
        rounds: z
          .object({
            title: z.string(),
            endDate: z.string(),
            claimDeadline: z.string(),
            boardMeetingDate: serverDateSchema,
            status: z.nativeEnum(FundingRoundStatusType),
          })
          .array(),
        now: z.string(),
      })
      .parse(result);

    return {
      rounds: rounds.filter(
        ({ claimDeadline }) =>
          new Date(claimDeadline).getFullYear() >= new Date(now).getFullYear()
      ),
    };
  };

export type GetHomeContextPromise = ReturnType<
  typeof createGetHomeContextPromise
>;
export type GetHomeContextPromiseResult = NonNullable<
  Awaited<ReturnType<GetHomeContextPromise>>
>;
