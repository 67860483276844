import { match } from 'ts-pattern';
import { Button } from './Button';
import { ApplicationProgressTimeline } from './ApplicationProgressTimeline';
import { GetDashboardContextPromiseResult } from '../promises/getDashboardContextPromise';
import { ApplicationStatus, ApplicationType } from '../graphql/operations';
import { ApplicationStatusBadge } from './ApplicationStatusBadge';
import { DescriptionList } from './DescriptionList';
import { formatZonedDateTime } from '../utils/formatZonedDateTime';
import { formatMoney } from '../utils/formatMoney';

import styles from './ActiveApplicationCard.module.scss';

interface ActiveApplicationCardProps {
  application: GetDashboardContextPromiseResult['activeApplications'][0];
  applicationLink: string;
}

export function ActiveApplicationCard({
  application,
  applicationLink,
}: ActiveApplicationCardProps) {
  const {
    status,
    stage,
    type,
    fundingRound,
    createdAt,
    submittedAt,
    requestedTotal,
    approvedTotal,
    totalNumberOfTourDates,
  } = application;

  return (
    <div className={styles.card}>
      <div className={styles.header}>
        <div className={styles.collapseRow}>
          <h3>
            {type === ApplicationType.Core
              ? 'Core Funding Application'
              : 'Orion Application'}
          </h3>
          <ApplicationStatusBadge status={status} />
        </div>
        {status !== ApplicationStatus.InProgress && (
          <Button
            variant="secondary"
            size="small"
            label="View"
            linkTo={applicationLink}
          />
        )}
      </div>
      <DescriptionList
        className={styles.list}
        list={{
          Round: fundingRound?.title ?? '-',
          ...(submittedAt
            ? { Submitted: formatZonedDateTime(submittedAt) }
            : { Started: formatZonedDateTime(createdAt) }),
          ...(type === ApplicationType.Core
            ? { 'Tour count': totalNumberOfTourDates }
            : {}),
          ...(status === ApplicationStatus.Approved
            ? { 'Total approved': formatMoney(approvedTotal) }
            : { 'Total requested': formatMoney(requestedTotal) }),
        }}
      />
      {match(status)
        .with(ApplicationStatus.InProgress, () => (
          <Button
            length="auto"
            size="small"
            label="Continue Application"
            linkTo={applicationLink}
            className={styles.button}
          />
        ))
        .with(ApplicationStatus.Approved, () => (
          <ApplicationProgressTimeline
            stage={stage}
            applicationType={type}
            linkTo={applicationLink}
          />
        ))
        .otherwise(() => null)}
    </div>
  );
}
