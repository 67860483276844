import { useActor } from '@xstate/react';
import {
  ApplicationStage,
  InsertDirectDepositAndLinkToApplicationType,
} from '../../graphql/operations';
import { DirectDepositMachineActor } from '../../machines/ClaimFunds/DirectDepositMachine';
import { DirectDepositsMachineState } from '../../machines/ClaimFunds/DirectDepositsMachine';
import { DepositInfoView } from './DepositInfoView';

interface Props {
  depositInfoType: InsertDirectDepositAndLinkToApplicationType;
  isOwner: boolean;
  directDepositsState: DirectDepositsMachineState;
  directDepositActor: DirectDepositMachineActor;
  stage: ApplicationStage;
  claimedTotal: number;
}

export function DepositInfoController({
  depositInfoType,
  isOwner,
  directDepositActor,
  directDepositsState,
  stage,
  claimedTotal,
}: Props) {
  const [directDepositState, directDepositSend] = useActor(directDepositActor);

  return (
    <DepositInfoView
      depositInfoType={depositInfoType}
      isOwner={isOwner}
      stage={stage}
      claimedTotal={claimedTotal}
      directDepositState={directDepositState}
      directDepositSend={directDepositSend}
      directDepositsState={directDepositsState}
    />
  );
}
