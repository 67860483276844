import { ZonedDateTime } from '@internationalized/date';
import {
  formatZonedTime,
  formatZonedDate,
} from '../../utils/formatZonedDateTime';

interface Props {
  claimsResubmissionDeadline: ZonedDateTime | null;
}

export function ReopenDeadlineParagraph({ claimsResubmissionDeadline }: Props) {
  return (
    <p>
      Your claim will remain open until{' '}
      <span className="body-text-bold">
        {formatZonedTime(claimsResubmissionDeadline)} on{' '}
        {formatZonedDate(claimsResubmissionDeadline)}
      </span>
      , after which the file will be closed based on the paperwork already
      provided.
    </p>
  );
}
