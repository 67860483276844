import { FundingRequest } from '../../schemas/fundingRequests/fundingRequestSchema';

export type UpdateExpenses = {
  type: 'UPDATE_EXPENSES';
  request: FundingRequest;
};

export const updateExpenses = (request: FundingRequest): UpdateExpenses => ({
  type: 'UPDATE_EXPENSES',
  request,
});
