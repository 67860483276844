import z from 'zod';
import { parseAbsolute } from '@internationalized/date';
import { provinceSchema } from '../provinceSchema';
import { legalStatusSchema } from '../legalStatusSchema';
import {
  basicDashboardArtistSchema,
  transformBasicDashboardArtist,
} from './dashboardArtistSchema';
import { activeApplicationSchema } from './activeApplicationSchema';
import { countrySchema } from '../countrySchema';
import { applicationStatusSchema } from '../application/applicationStatusSchema';
import { applicationTypeSchema } from '../application/applicationTypeSchema';
import { serverDateTimeSchema } from '../dateTimeSchema';
import { serverDateSchema } from '../dateSchema';
import { parseAbsoluteOrNull } from '../../utils/parseAbsoluteOrNull';
import { applicationStageSchema } from '../application/applicationStageSchema';

export const dashboardContextSchema = z
  .object({
    timezone: z.string(),
    currentRound: z
      .object({
        id: z.string(),
        title: z.string(),
        timezone: z.string(),
        endDate: serverDateTimeSchema.unwrap(),
        boardMeetingDate: serverDateSchema,
        allowOrion: z.boolean(),
        closingSoon: z.boolean(),
      })
      .transform(({ timezone, endDate, ...rest }) => ({
        timezone,
        endDate: parseAbsolute(endDate, timezone),
        ...rest,
      }))
      .nullable(),
    nextRound: z
      .object({
        title: z.string(),
      })
      .nullable(),
    previousRound: z
      .object({
        title: z.string(),
        timezone: z.string(),
        endDate: serverDateTimeSchema.unwrap(),
      })
      .transform(({ timezone, endDate, ...rest }) => ({
        timezone,
        endDate: parseAbsolute(endDate, timezone),
        ...rest,
      }))
      .nullable(),
    nextOrionRound: z
      .object({
        title: z.string(),
      })
      .nullable(),
    countries: countrySchema.omit({ regionId: true }).array(),
    provinces: provinceSchema.array(),
    legalStatuses: legalStatusSchema.array(),
    artist: basicDashboardArtistSchema,
    releases: z
      .object({
        id: z.string(),
        title: z.string(),
        releaseDate: z.string(),
        eligible: z
          .boolean()
          .nullable()
          .transform((val) => val ?? false),
        label: z.object({
          name: z.string(),
        }),
        otherLabel: z.string().nullable(),
      })
      .array(),
    accounts: z
      .object({
        accountId: z.string(),
        email: z.string(),
        isYou: z.boolean(),
        name: z.string(),
      })
      .array(),
    activeApplications: activeApplicationSchema.array(),
    closedApplications: z
      .object({
        id: z.string(),
        fundingRound: z.object({
          title: z.string(),
        }),
        stage: applicationStageSchema,
        timezone: z.string(),
        submittedAt: serverDateTimeSchema,
        status: applicationStatusSchema,
        type: applicationTypeSchema,
      })
      .transform(({ timezone, submittedAt, ...rest }) => ({
        timezone,
        submittedAt: parseAbsoluteOrNull(submittedAt, timezone),
        ...rest,
      }))
      .array(),
    pendingAccountBindings: z
      .object({
        email: z.string(),
        invitationId: z.string().uuid(),
      })
      .array(),
    maximumFundingAmount: z.number(),
  })
  .transform(({ timezone, artist, ...rest }) => ({
    artist: transformBasicDashboardArtist(timezone, artist),
    ...rest,
  }));
