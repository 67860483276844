import { TourDate } from '../schemas/tourDates/tourDateSchema';

export type DeleteTourDate = {
  type: 'DELETE_TOUR_DATE';
  id: TourDate['id'];
};

export const deleteTourDate = (id: DeleteTourDate['id']): DeleteTourDate => ({
  type: 'DELETE_TOUR_DATE',
  id,
});
