import {
  ExpenseBlockMachineSender,
  ExpenseBlockMachineState,
} from '../../../machines/components/ExpenseBlockMachine';
import { ExpenseController } from './ExpenseController';
import { ExpenseCardController } from './ExpenseCardController';
import { match } from 'ts-pattern';
import { ErrorFeedback } from '../../ErrorFeedback';

import styles from './ExpenseBlockView.module.scss';

interface ExpenseBlockProps {
  state: ExpenseBlockMachineState;
  send: ExpenseBlockMachineSender;
  showEdit: boolean;
  showDelete: boolean;
}

export function ExpenseBlockView({
  state,
  showEdit,
  showDelete,
}: ExpenseBlockProps) {
  return state.matches('error') ? (
    <ErrorFeedback />
  ) : (
    <>
      {state.context.isOpen && (
        <div className={styles.content}>
          {state.context.extendedExpenses.map(({ open, expense, ref }) =>
            match(open)
              .with(false, () => (
                <ExpenseCardController
                  key={expense.id}
                  actor={ref}
                  showEdit={showEdit}
                  showDelete={showDelete}
                />
              ))
              .with(true, () => (
                <ExpenseController key={expense.id} actor={ref} />
              ))
              .exhaustive()
          )}
        </div>
      )}
      {state.context.openExpense && (
        <ExpenseController actor={state.context.openExpense} />
      )}
    </>
  );
}
