import { z } from 'zod';
import { ArtistInvitationStatusResponseCode } from '../graphql/operations';

export const artistInvitationStatusSchema = z.object({
  code: z.nativeEnum(ArtistInvitationStatusResponseCode),
  // The email can be an empty string on failure so don't validate it as
  // an email. At this point it will have been validated anyway and we are
  // purely using this value for display purposes.
  email: z.string(),
});

export type ArtistInvitationStatus = z.infer<
  typeof artistInvitationStatusSchema
>;
