import { assign, createMachine } from 'xstate';
import type {
  EventFrom,
  MachineOptionsFrom,
  Sender,
  ActorRefFrom,
} from 'xstate';
import { BackEvent, NextEvent, next } from '../navigation';
import { sendParent } from 'xstate/lib/actions';
import { StateFrom } from '../../utils/StateFrom';
import { RedeemArtistInvitationStorage } from './storage';
import { createReplay, ReplayableEvent } from '../../utils/replay';
import { isEmailValid } from '../../utils/isEmailValid';
import { Context as ParentContext } from './Context';

type FocusEmailEvent = ReplayableEvent<{ type: 'FOCUS_EMAIL' }>;
type UpdateEmailEvent = ReplayableEvent<{
  type: 'UPDATE_EMAIL';
  value: string;
}>;

type BlurEmailEvent = ReplayableEvent<{ type: 'BLUR_EMAIL' }>;

type ReplayableEvents = FocusEmailEvent | UpdateEmailEvent | BlurEmailEvent;

type Events = NextEvent | BackEvent | ReplayableEvents | { type: 'TRANSFER' };

type Context = {
  link: boolean;
  email: string;
  transferEmail: string;
};

export const createTransferContext = ({
  email,
  link,
}: Pick<ParentContext, 'link' | 'email'>): Context => ({
  link,
  email,
  transferEmail: '',
});

export const machine = createMachine(
  {
    predictableActionArguments: true,
    tsTypes: {} as import('./TransferMachine.typegen').Typegen0,
    schema: {
      events: {} as Events,
      context: {} as Context,
    },
    initial: 'form',
    invoke: {
      id: 'resume',
      src: 'resume',
    },
    states: {
      form: {
        initial: 'pristine',
        states: {
          pristine: { on: { TRANSFER: 'invalid' } },
          valid: {},
          invalid: {
            initial: 'type',
            states: {
              duplicate: {},
              type: {},
            },
            on: { TRANSFER: undefined },
          },
          duplicate: { on: { TRANSFER: undefined } },
        },
        on: {
          TRANSFER: 'done',
          UPDATE_EMAIL: { actions: ['setTransferEmail', 'saveTransferEmail'] },
          BLUR_EMAIL: [
            { cond: 'isTransferEmailDuplicate', target: '.invalid.duplicate' },
            { cond: 'isTransferEmailValid', target: '.valid' },
            { target: '.invalid' },
          ],
        },
      },
      done: {
        type: 'final',
      },
    },
    on: { NEXT: { actions: 'next' } },
  },
  {
    guards: {
      isTransferEmailDuplicate: ({ email, transferEmail }) =>
        email === transferEmail,
      isTransferEmailValid: ({ transferEmail }) => isEmailValid(transferEmail),
    },
    actions: {
      next: sendParent(next()),
      setTransferEmail: assign((_, { value: transferEmail }) => ({
        transferEmail,
      })),
    },
    services: {},
  }
);

export const createTransferResumeService =
  (storage: RedeemArtistInvitationStorage) =>
  () =>
  (send: TransferMachineSender) => {
    const replay = createReplay<ReplayableEvents>();
    const { account } = storage.get();

    (
      [
        [account?.name, 'FOCUS_EMAIL', 'UPDATE_EMAIL', 'BLUR_EMAIL'],
      ] satisfies Array<
        [
          string | undefined,
          ReplayableEvents['type'],
          ReplayableEvents['type'],
          ReplayableEvents['type']
        ]
      >
    ).forEach(([value, focus, type, blur]) => {
      if (value !== undefined) {
        send(replay({ type: focus }));
        send(replay({ type, value }));
        send(replay({ type: blur }));
      }
    });
  };

export const createTransferSaveActions = (
  storage: RedeemArtistInvitationStorage
) => ({
  saveTransferEmail: (_: Context, { value: email, replay }: UpdateEmailEvent) =>
    storage.add({ email }, replay),
});

type Machine = typeof machine;

export type TransferMachineState = StateFrom<Machine>;
export type TransferMachineSender = Sender<EventFrom<Machine>>;
export type TransferMachineOptions = MachineOptionsFrom<Machine, true>;
export type TransferMachineActor = ActorRefFrom<Machine>;
