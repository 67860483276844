import { FundingRequest } from '../schemas/fundingRequests/fundingRequestSchema';

export type DeleteFundingRequest = {
  type: 'DELETE_REQUEST';
  id: FundingRequest['id'];
};

export const deleteFundingRequest = (
  id: DeleteFundingRequest['id']
): DeleteFundingRequest => ({
  type: 'DELETE_REQUEST',
  id,
});
