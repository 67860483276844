import { useGraphQLClient } from '../../components/GraphQLClientProvider';
import { DashboardController } from './DashboardController';
import { useNavigate, useParams } from 'react-router-dom';
import { createApplicationPromise } from '../../promises/createApplicationPromise';
import { machine as AccountsBlockMachine } from '../../machines/components/AccountsBlockMachine';
import { machine as ArtistDetailsMachine } from '../../machines/components/ArtistDetailsBlockMachine';
import { machine as ArtistManagementMachine } from '../../machines/components/ArtistManagementMachine';
import { machine as EligibilityNoticeMachine } from '../../machines/EligibilityNoticeMachine';
import { createGetDashboardContextPromise } from '../../promises/getDashboardContextPromise';
import { createArtistInvitationPromise } from '../../promises/createArtistInvitationPromise';
import { deleteArtistInvitationPromise } from '../../promises/deleteArtistInvitationPromise';
import { createUpdateArtistDetailsPromise } from '../../promises/createUpdateArtistDetailsPromise';
import { createUpsertManagementDetails } from '../../promises/createUpsertManagementDetails';
import { createDeleteManagementDetailsPromise } from '../../promises/createDeleteManagementDetailsPromise';
import { deleteAccountArtistBindingPromise } from '../../promises/createDeleteAccountArtistBindingPromise';
import { createUpdateArtistStatusPromise } from '../../promises/Admin/createUpdateArtistStatusPromise';
import { withDelay } from '../../utils/withDelay';
import { createUpsertEligibilityNoticePromise } from '../../promises/createUpsertEligibilityNoticePromise';
import { createDeleteEligibilityNoticePromise } from '../../promises/deleteEligibilityNoticePromise';

export const Dashboard = () => {
  const { artistId } = useParams();
  const client = useGraphQLClient();
  const navigate = useNavigate();
  if (!artistId) {
    navigate('/dashboard');
    return null;
  }

  return (
    <DashboardController
      options={{
        actions: {
          navigateToApplication: ({ artist: { id: artistId } }, { data: id }) =>
            navigate(`/artist/${artistId}/application/${id}`),
        },
        services: {
          accountsBlockMachine: AccountsBlockMachine.withConfig({
            services: {
              createArtistInvitationPromise:
                createArtistInvitationPromise(client),
              deleteArtistInvitationPromise:
                deleteArtistInvitationPromise(client),
              deleteAccountArtistBindingPromise:
                deleteAccountArtistBindingPromise(client),
            },
          }),
          artistDetailsBlockMachine: ArtistDetailsMachine.withConfig({
            services: {
              updateArtistDetails: withDelay(
                createUpdateArtistDetailsPromise(client)
              ),
              updateArtistStatusPromise:
                createUpdateArtistStatusPromise(client),
            },
          }),
          artistManagementMachine: ArtistManagementMachine.withConfig({
            services: {
              upsertManagement: withDelay(
                createUpsertManagementDetails(client, ({ form }) => ({
                  artistId,
                  ...form,
                }))
              ),
              deleteManagement: withDelay(
                createDeleteManagementDetailsPromise(client, artistId)
              ),
            },
          }),
          eligibilityNoticeMachine: EligibilityNoticeMachine.withConfig({
            services: {
              upsertEligibilityNoticePromise: withDelay(
                createUpsertEligibilityNoticePromise(client)
              ),
              deleteEligibilityNoticePromise: withDelay(
                createDeleteEligibilityNoticePromise(client)
              ),
            },
          }),
          getDashboardContextPromise: withDelay(
            createGetDashboardContextPromise(client, artistId)
          ),
          createApplicationPromise: createApplicationPromise(client, artistId),
        },
      }}
    />
  );
};
