import type {
  Sender,
  EventFrom,
  MachineOptionsFrom,
  ActorRefFrom,
} from 'xstate';
import { createMachine, assign, sendParent } from 'xstate';
import { ZonedDateTime } from '@internationalized/date';
import { StateFrom } from '../../utils/StateFrom';
import { UploadFilePromiseResult } from '../../promises/Attachments/createUploadFilePromise';
import { DeleteFilePromiseResult } from '../../promises/Attachments/createDeleteFilePromise';
import { GetFilePromiseResult } from '../../promises/Attachments/createGetFilePromise';
import { refreshApplication } from '../../events/RefreshApplication';
import { Attachment } from '../../schemas/attachment';
import { logMachineError } from '../../utils/logError';

type Events =
  | { type: 'UPLOAD_FILES'; files: FileList }
  | {
      type: 'DELETE_FILE';
      file: Attachment;
    }
  | { type: 'GET_FILE'; file: Attachment };

export type Context = {
  applicationId: string;
  attachments: Attachment[];
  submittedAt: ZonedDateTime | null;
};

type Services = {
  getFilePromise: {
    data: GetFilePromiseResult;
  };
  uploadFilePromise: {
    data: UploadFilePromiseResult;
  };
  deleteFilePromise: {
    data: DeleteFilePromiseResult;
  };
};

export const createContext = (
  applicationId: string,
  attachments: Attachment[],
  submittedAt: ZonedDateTime | null
) => ({
  applicationId,
  attachments,
  submittedAt,
});

export const machine = createMachine(
  {
    predictableActionArguments: true,
    tsTypes: {} as import('./AttachmentsBlockMachine.typegen').Typegen0,
    schema: {
      context: {} as Context,
      events: {} as Events,
      services: {} as Services,
    },
    initial: 'form',
    id: 'attachments',
    states: {
      form: {
        initial: 'attachments',
        states: {
          attachments: {
            initial: 'default',
            states: {
              default: {},
              error: { entry: 'logMachineError' },
              upload: {
                invoke: {
                  id: 'uploadFilePromise',
                  src: 'uploadFilePromise',
                  onDone: {
                    actions: ['updateAttachments', 'sendRefreshApplication'],
                    target: 'default',
                  },
                  onError: 'error',
                },
              },
              get: {
                invoke: {
                  id: 'getFilePromise',
                  src: 'getFilePromise',
                  onDone: { actions: 'openAttachment', target: 'default' },
                  onError: 'error',
                },
              },
              delete: {
                invoke: {
                  id: 'deleteFilePromise',
                  src: 'deleteFilePromise',
                  onDone: {
                    actions: ['deleteAttachment', 'sendRefreshApplication'],
                    target: 'default',
                  },
                  onError: 'error',
                },
              },
            },
            on: {
              UPLOAD_FILES: { target: '.upload' },
              DELETE_FILE: { target: '.delete' },
              GET_FILE: { target: '.get' },
            },
          },
        },
      },
    },
  },
  {
    actions: {
      logMachineError,
      updateAttachments: assign(({ attachments }, { data }) => {
        return {
          attachments: [
            // remove any duplicate attachments
            ...attachments.filter(
              (attachment) => !data.find(({ name }) => name === attachment.name)
            ),
            ...data,
          ],
        };
      }),
      deleteAttachment: assign(
        ({ attachments }, { data: { name: nameToDelete } }) => ({
          attachments: attachments.filter(({ name }) => name !== nameToDelete),
        })
      ),
      sendRefreshApplication: sendParent(refreshApplication()),
    },
  }
);

type Machine = typeof machine;

export type AttachmentsBlockMachineState = StateFrom<Machine>;
export type AttachmentsBlockMachineSender = Sender<EventFrom<Machine>>;
export type AttachmentsBlockMachineOptions = MachineOptionsFrom<
  Machine,
  true
> & {
  context: Context;
};
export type AttachmentsBlockMachineEvent = EventFrom<Machine>;
export type AttachmentsBlockMachineActor = ActorRefFrom<Machine>;
