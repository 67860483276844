import { z } from 'zod';
import { moneySchema } from '../moneySchema';

export const applicationTotalsSchema = z.object({
  requestedTotal: moneySchema,
  requestedTotalForTourDates: moneySchema,
  requestedTotalForFundingRequests: moneySchema,

  tentativeTotal: moneySchema,
  tentativeTotalForTourDates: moneySchema,
  tentativeTotalForFundingRequests: moneySchema,

  approvedTotal: moneySchema,
  approvedTotalForTourDates: moneySchema,
  approvedTotalForFundingRequests: moneySchema,

  touringPaid: moneySchema,
  fundingRequestPaid: moneySchema,

  totalNumberOfTourDates: z.number().int(),
  totalNumberOfApprovedTourDates: z.number().int(),
  totalNumberOfClaimedTourDates: z.number().int(),
  totalNumberOfIneligibleTourDates: z.number().int(),
  totalNumberOfFundingRequests: z.number().int(),
  totalNumberOfApprovedFundingRequests: z.number().int(),
  totalNumberOfClaimedFundingRequests: z.number().int(),
  totalNumberOfIneligibleFundingRequests: z.number().int(),
  approvedFundingRequestsWithoutExpense: z.number().int(),

  claimedTotalForFundingRequests: moneySchema,
  claimedTotalForTourDates: moneySchema,
  claimedTotal: moneySchema,
});

export type ApplicationTotals = z.infer<typeof applicationTotalsSchema>;
