import type {
  Sender,
  EventFrom,
  MachineOptionsFrom,
  ActorRefFrom,
} from 'xstate';
import { createMachine, assign } from 'xstate';
import { CalendarDate } from '@internationalized/date';
import { StateFrom } from '../../utils/StateFrom';
import { StatesConfig } from '../../utils/StateConfig';
import { parsePositiveFloat } from '../../utils/parsePositiveFloat';
import { sendParent } from 'xstate/lib/actions';
import {
  EditContext,
  RefreshFundingRequestTotals,
} from '../../events/RefreshFundingRequestTotals';
import {
  PartialFundingRequest,
  CompleteFundingRequest,
} from '../../schemas/fundingRequests/fundingRequestSchema';
import { addFundingRequest } from '../../events/AddFundingRequest';
import { updateFundingRequest } from '../../events/UpdateFundingRequest';
import {
  CloseFundingRequest,
  closeFundingRequest,
} from '../../events/CloseFundingRequest';
import { formatNumber } from '../../utils/formatNumber';
import { isBeforeCutoff } from '../../utils/isBeforeCutoff';
import { isAfterCutoff } from '../../utils/isAfterCutoff';
import { ApplicationType } from '../../graphql/operations';

type Events =
  | RefreshFundingRequestTotals
  | { type: 'SAVE_REQUEST' }
  | CloseFundingRequest
  | { type: 'SET_FUNDING_TYPE'; fundingType: string }
  | { type: 'SET_COST'; cost: string }
  | { type: 'SET_DATE'; date: CalendarDate }
  | {
      type: 'SET_STRATEGY';
      strategy: string;
    }
  | {
      type: 'SET_REASON';
      reason: string;
    }
  | { type: 'BLUR_FUNDING_TYPE' }
  | { type: 'BLUR_COST' }
  | { type: 'BLUR_REASON' }
  | { type: 'FOCUS_FUNDING_TYPE' }
  | { type: 'FOCUS_COST' }
  | { type: 'FOCUS_DATE' }
  | { type: 'FOCUS_REASON' };

export type FundingRequestMode = 'view' | 'edit';

export type Context = {
  editContext?: EditContext;
  request: PartialFundingRequest;
  presentCutoffDate: CalendarDate;
  futureCutoffDate: CalendarDate | undefined;
  year: number;
  budgetForFundingRequests: number;
  applicationType: ApplicationType;
  alternate: boolean;
};

type Services = {
  validateFundingRequest: {
    data: CompleteFundingRequest;
  };
};

const inputStates: StatesConfig<Context, Events> = {
  pristine: { on: { SAVE_REQUEST: 'invalid' } },
  valid: { type: 'final' },
  invalid: {},
};

const isEmpty = (value: string) => value === '' || value === null;

export const machine = createMachine(
  {
    predictableActionArguments: true,
    tsTypes: {} as import('./FundingRequestFormMachine.typegen').Typegen0,
    schema: {
      context: {} as Context,
      events: {} as Events,
      services: {} as Services,
    },
    initial: 'form',
    id: 'form',
    states: {
      form: {
        type: 'parallel',
        states: {
          'funding-type': {
            initial: 'pristine',
            states: {
              ...inputStates,
            },
            on: {
              SET_FUNDING_TYPE: { actions: 'setFundingType' },
              BLUR_FUNDING_TYPE: [
                { cond: 'isFundingTypeEmpty', target: '.invalid' },
                '.valid',
              ],
            },
          },
          cost: {
            id: 'cost',
            initial: 'pristine',
            states: {
              ...inputStates,
              invalid: {
                initial: 'empty',
                states: {
                  type: {},
                  empty: {},
                  cap: {},
                },
              },
              setting: {
                entry: 'refreshFundingRequestTotals',
                always: 'checking',
              },
              checking: {
                always: [
                  { cond: 'isCostEmpty', target: 'invalid.empty' },
                  { cond: 'isCostAlphabetic', target: 'invalid.type' },
                  'formating',
                ],
              },
              formating: {
                entry: 'formatCostValue',
                always: [{ cond: 'isCapHit', target: 'invalid.cap' }, 'valid'],
              },
            },
            on: {
              SET_COST: {
                actions: 'setCost',
              },
              BLUR_COST: '.checking',
              REFRESH_FUNDING_REQUEST_TOTALS: '.setting',
            },
          },
          date: {
            initial: 'pristine',
            states: {
              ...inputStates,
              invalid: {
                initial: 'empty',
                states: {
                  empty: {},
                  past: {},
                  future: {},
                },
              },
              setting: {
                entry: 'setDate',
                always: [
                  { cond: 'isBeforeCutoff', target: 'invalid.past' },
                  { cond: 'isAfterCutoff', target: 'invalid.future' },
                  'valid',
                ],
              },
            },
            on: {
              SET_DATE: '.setting',
            },
          },
          strategy: {
            initial: 'valid',
            states: {
              ...inputStates,
            },
            on: {
              SET_STRATEGY: { actions: 'setStrategy' },
            },
          },
          reason: {
            initial: 'check',
            states: {
              check: {
                always: [
                  { cond: 'isAlternate', target: 'pristine' },
                  { target: 'valid' },
                ],
              },
              ...inputStates,
            },
            on: {
              SET_REASON: { actions: 'setReason' },
              BLUR_REASON: [
                { cond: 'isReasonEmpty', target: '.invalid' },
                '.valid',
              ],
            },
          },
          warning: {
            initial: 'off',
            states: {
              on: {
                type: 'final',
                on: {
                  SAVE_REQUEST: 'pulse',
                },
              },
              off: {
                type: 'final',
                on: {
                  SAVE_REQUEST: 'on',
                },
              },
              pulse: {
                type: 'final',
                after: { 1000: 'on' },
              },
              error: {},
            },
          },
        },
        onDone: 'complete',
      },
      complete: {
        on: {
          FOCUS_FUNDING_TYPE: {
            target: [
              'form.funding-type.pristine',
              'form.cost.valid',
              'form.date.valid',
              'form.strategy.valid',
              'form.reason.valid',
            ],
          },
          FOCUS_COST: {
            target: [
              'form.funding-type.valid',
              'form.cost.pristine',
              'form.date.valid',
              'form.strategy.valid',
              'form.reason.valid',
            ],
          },
          FOCUS_REASON: {
            target: [
              'form.funding-type.valid',
              'form.cost.valid',
              'form.date.valid',
              'form.strategy.valid',
              'form.reason.pristine',
            ],
          },
          REFRESH_FUNDING_REQUEST_TOTALS: {
            target: [
              'form.funding-type.valid',
              'form.cost.setting',
              'form.date.valid',
              'form.strategy.valid',
              'form.reason.valid',
            ],
          },
          SET_DATE: {
            target: [
              'form.funding-type.valid',
              'form.cost.valid',
              'form.date.setting',
              'form.strategy.valid',
              'form.reason.valid',
            ],
          },
          SET_STRATEGY: {
            actions: 'setStrategy',
          },
          SAVE_REQUEST: 'saving',
        },
      },
      saving: {
        invoke: {
          id: 'validateFundingRequest',
          src: 'validateFundingRequest',
          onDone: { target: 'complete', actions: 'addFundingRequest' },
          onError: { target: 'form.warning.error' },
        },
      },
    },
    on: {
      CLOSE_REQUEST: { actions: 'closeFundingRequest' },
    },
  },
  {
    guards: {
      isCostAlphabetic: (context) =>
        Number.isNaN(parsePositiveFloat(context.request.cost)),
      isCostEmpty: ({ request: { cost } }) => isEmpty(cost),
      isReasonEmpty: ({ request: { reason } }) => isEmpty(reason),
      isFundingTypeEmpty: ({ request: { fundingType } }) =>
        isEmpty(fundingType),
      isBeforeCutoff: ({ request: { date }, presentCutoffDate }) =>
        isBeforeCutoff(presentCutoffDate, date),
      isAfterCutoff: ({ request: { date }, futureCutoffDate }) =>
        futureCutoffDate !== undefined && isAfterCutoff(futureCutoffDate, date),
      isCapHit: ({ request, budgetForFundingRequests, editContext }) =>
        parsePositiveFloat(request.cost) -
          parsePositiveFloat(editContext?.cost ?? '0') >
        budgetForFundingRequests,
      isAlternate: ({ alternate }) => alternate,
    },
    actions: {
      addFundingRequest: sendParent(({ editContext }, { data: request }) =>
        editContext === undefined
          ? addFundingRequest(request)
          : updateFundingRequest(editContext.id, request)
      ),
      closeFundingRequest: sendParent(({ editContext }) =>
        closeFundingRequest(editContext?.id)
      ),
      setFundingType: assign((context, event) => ({
        request: {
          ...context.request,
          fundingType: event.fundingType,
        },
      })),
      setCost: assign((context, event) => ({
        request: {
          ...context.request,
          cost: event.cost,
        },
      })),
      setReason: assign((context, event) => ({
        request: {
          ...context.request,
          reason: event.reason,
        },
      })),
      formatCostValue: assign(({ request: { cost, ...request } }) => ({
        request: {
          ...request,
          cost: formatNumber(parsePositiveFloat(cost)),
        },
      })),
      setDate: assign((context, event) => ({
        request: {
          ...context.request,
          date: event.date,
        },
      })),
      setStrategy: assign((context, event) => ({
        request: {
          ...context.request,
          strategy: event.strategy,
        },
      })),
      refreshFundingRequestTotals: assign(
        (_, { year, budgetForFundingRequests, editContext }) => ({
          year,
          budgetForFundingRequests,
          editContext,
        })
      ),
    },
  }
);

export type FundingRequestFormMachine = typeof machine;

export type FundingRequestFormMachineState =
  StateFrom<FundingRequestFormMachine>;
export type FundingRequestFormMachineSender = Sender<
  EventFrom<FundingRequestFormMachine>
>;
export type FundingRequestFormMachineOptions = MachineOptionsFrom<
  FundingRequestFormMachine,
  true
>;
export type FundingRequestFormMachineEvent =
  EventFrom<FundingRequestFormMachine>;
export type FundingRequestFormMachineActor =
  ActorRefFrom<FundingRequestFormMachine>;
