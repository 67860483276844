import { match } from 'ts-pattern';
import { ErrorFeedback } from '../../../components/ErrorFeedback';
import { Feedback } from '../../../components/Feedback';
import { FormField } from '../../../components/FormField';
import { GrayBox } from '../../../components/GrayBox';
import { InlineFormContainer } from '../../../components/InlineFormContainer';
import { Loading } from '../../../components/Loading';
import { TextInput } from '../../../components/TextInput';
import {
  BoardMembersMachineSender,
  BoardMembersMachineState,
} from '../../../machines/Admin/BoardMembersMachine';

import styles from './AddMemberForm.module.scss';
interface AddMemberFormProps {
  state: BoardMembersMachineState;
  send: BoardMembersMachineSender;
}

export function AddMemberForm({ state, send }: AddMemberFormProps) {
  const isInvalidEmail = state.matches('ready.form.openForm.invalid');
  return match(state)
    .when(
      () => state.matches('ready.form.openForm'),
      () => (
        <InlineFormContainer
          primaryButtonText="Add Board Member"
          primaryButtonOnClick={() => {
            send('SUBMIT_FORM');
          }}
          secondaryButtonText="Close"
          secondaryButtonOnClick={() => {
            send('CANCEL_FORM');
          }}
        >
          <FormField
            formFieldStatus={isInvalidEmail ? 'error' : 'default'}
            feedbackType="warning"
            label="New board member email"
            infoLabel={
              isInvalidEmail ? 'Please enter a valid email address.' : ''
            }
          >
            <TextInput
              id="email"
              onChange={(value) => {
                send({ type: 'SET_EMAIL', value });
              }}
              value={state.context.emailInput}
            />
          </FormField>
          <Feedback size="compact" type="neutral" icon="infoCircle">
            If the board member does not yet have a Starmaker account,
            they&apos;ll be sent an invite email with a link to create an
            account.
          </Feedback>
          {state.matches('ready.form.openForm.duplicate') && (
            <Feedback type="warning">
              This email has already been invited to the board.
            </Feedback>
          )}
        </InlineFormContainer>
      )
    )
    .when(
      () => state.matches('ready.form.saving'),
      () => (
        <GrayBox className={styles.container}>
          <Loading inline={true} />
        </GrayBox>
      )
    )
    .when(
      () => state.matches('ready.form.error'),
      () => <ErrorFeedback />
    )
    .otherwise(() => <></>);
}
