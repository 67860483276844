import { z } from 'zod';
import { BoardMemberInvitationStatusResponseCode } from '../graphql/operations';

export const boardMemberInvitationStatusSchema = z.object({
  code: z.nativeEnum(BoardMemberInvitationStatusResponseCode),
  // The email can be an empty string on failure so don't validate it as
  // an email. At this point it will have been validated anyway and we are
  // purely using this value for display purposes.
  email: z.string(),
});
