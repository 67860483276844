import type {
  ActorRefFrom,
  EventFrom,
  MachineOptionsFrom,
  Sender,
} from 'xstate';
import { createMachine, assign } from 'xstate';
import { match } from 'ts-pattern';
import type { NextEvent, BackEvent } from '../navigation';
import { StateFrom } from '../../utils/StateFrom';
import { createArtistContextForAddArtist } from '../RedeemAccountInvitation/ArtistMachine';
import { createManagementContextForAddArtist } from '../RedeemAccountInvitation/ManagementMachine';

import { GetAddArtistContextPromiseResult } from '../../promises/getAddArtistContextPromise';
import { AddArtistPromiseResult } from '../../promises/addArtistPromise';

import { AddArtistStorage } from './storage';
import { AddArtistState } from './state';
import { AddArtistHistory } from './history';

type Events =
  | NextEvent
  | BackEvent
  | { type: 'RESUME_ARTIST' }
  | { type: 'RESUME_MANGEMENT' }
  | { type: 'RESTART' };

type Services = {
  getAddArtistContext: {
    data: GetAddArtistContextPromiseResult;
  };
  addArtist: {
    data: AddArtistPromiseResult;
  };
};

type Context = GetAddArtistContextPromiseResult & { isOrion: boolean };

export const machine = createMachine(
  {
    tsTypes: {} as import('./Machine.typegen').Typegen0,
    predictableActionArguments: true,
    schema: {
      context: {} as Context,
      events: {} as Events,
      services: {} as Services,
    },
    initial: 'init',
    invoke: {
      id: 'restart',
      src: 'restart',
    },
    context: {
      countries: [],
      provinces: [],
      legalStatuses: [],
      isOrion: false,
    },
    states: {
      init: {
        initial: 'pending',
        states: {
          pending: {
            invoke: {
              id: 'getAddArtistContext',
              src: 'getAddArtistContext',
              onDone: {
                actions: 'setContext',
                target: 'resolved',
              },
              onError: 'rejected',
            },
          },
          resolved: { type: 'final' },
          rejected: {},
        },
        onDone: 'resume',
      },
      resume: {
        invoke: {
          id: 'resume',
          src: 'resume',
        },
        on: {
          RESUME_ARTIST: 'artist',
          RESUME_MANGEMENT: 'management',
        },
      },
      artist: {
        entry: 'saveArtistStep',
        invoke: {
          id: 'artistMachine',
          src: 'artistMachine',
          data: createArtistContextForAddArtist,
        },
        on: {
          NEXT: [
            { cond: 'requiresManagement', target: 'management' },
            { target: 'adding' },
          ],
          BACK: { actions: 'relocateToAllArtists' },
        },
      },
      management: {
        entry: 'saveManagementStep',
        invoke: {
          id: 'managementMachine',
          src: 'managementMachine',
          data: createManagementContextForAddArtist,
        },
        on: {
          NEXT: 'adding',
          BACK: 'artist',
        },
      },
      adding: {
        initial: 'pending',
        states: {
          pending: {
            invoke: {
              id: 'addArtist',
              src: 'addArtist',
              onDone: {
                actions: ['resetAddArtistState', 'relocateToArtist'],
              },
              onError: 'rejected',
            },
          },
          rejected: {},
        },
      },
    },
    on: { RESTART: 'resume' },
  },
  {
    actions: {
      setContext: assign((_, { data }) => data),
    },
  }
);

type Machine = typeof machine;

export type AddArtistMachineState = StateFrom<Machine>;
export type AddArtistMachineSender = Sender<EventFrom<Machine>>;
export type AddArtistMachineOptions = MachineOptionsFrom<Machine, true>;
export type AddArtistMachineActor = ActorRefFrom<Machine>;

export const createGuards = (storage: AddArtistStorage) => ({
  requiresManagement: () => storage.get().artist?.isSelfManaged === false,
});

export const createResumeService =
  (storage: AddArtistStorage) => () => (send: AddArtistMachineSender) => {
    const { step } = storage.get();

    match(step)
      .with(undefined, () => send('RESUME_ARTIST'))
      .with('artist', () => send('RESUME_ARTIST'))
      .with('management', () => send('RESUME_MANGEMENT'))
      .exhaustive();
  };

export const createRestartService =
  (storage: AddArtistStorage, history: AddArtistHistory) =>
  () =>
  (send: AddArtistMachineSender) => {
    history.subscribe((state) => {
      storage.set(state);
      send('RESTART');
    });

    return history.unsubscribe;
  };

const createStepSaver =
  (
    storage: AddArtistStorage,
    history: AddArtistHistory,
    step: AddArtistState['step']
  ) =>
  // Every time the user navigates forwards or backwards in the flow we
  // push an entry onto the history stack. This gives us a snapshot to
  // restore to if the user every decides to use the back and forward
  // buttons on their browser.
  (_: Context, { type }: Events) => {
    // We only want to push history when the user has been the one
    // triggering movement. There are other events used by the resume
    // serivce to restore the state of the flow but those are not
    // triggered by humans in front of the screen and therefore we do
    // not want to push history onto the stack for those events.
    if (type === 'NEXT' || type === 'BACK') {
      // The reason we first replace the current state is because the user
      // will have changed the state on the page since they first entered this
      // state. This allows us to save all their work up till they navigated
      // to the next step.
      history.replace(storage.get());
      storage.add({ step });
      history.push(storage.get());
    }
  };

export const createSaveActions = (
  storage: AddArtistStorage,
  history: AddArtistHistory
) => ({
  saveArtistStep: createStepSaver(storage, history, 'artist'),
  saveManagementStep: createStepSaver(storage, history, 'management'),
  resetAddArtistState: storage.reset,
});
