import { useNavigate, useParams } from 'react-router-dom';
import { AllApplicationsController } from './AllApplicationsController';
import { useGraphQLClient } from '../../components/GraphQLClientProvider';
import { createApplicationPromise } from '../../promises/createApplicationPromise';
import { createGetAllApplicationsPagePromise } from '../../promises/createGetAllApplicationsPagePromise';
import { createContext } from '../../machines/Application/AllApplicationsMachine';
import { withDelay } from '../../utils/withDelay';

export const AllApplications = () => {
  const client = useGraphQLClient();
  const { artistId } = useParams();
  const navigate = useNavigate();

  if (!artistId) {
    navigate('/dashboard');
    return null;
  }

  return (
    <AllApplicationsController
      options={{
        context: createContext(artistId),
        actions: {
          navigateToApplication: ({ artistId }, { data }) => {
            navigate(`/artist/${artistId}/application/${data}`);
          },
        },
        services: {
          createApplicationPromise: createApplicationPromise(client, artistId),
          getAllApplicationsPagePromise: withDelay(
            createGetAllApplicationsPagePromise(client)
          ),
        },
      }}
    />
  );
};
