import { ArtistCard } from '../../components/ArtistCard';
import { IndexPage } from '../../components/IndexPage';
import { RoundStatus } from '../../components/RoundStatus';
import { GetAllArtistsPromiseResult } from '../../promises/createGetAllArtistsPagePromise';

interface Props {
  data: GetAllArtistsPromiseResult;
}

export function AllArtistsView({
  data: { accountName, artists, currentRound, nextRound, previousRound },
}: Props) {
  return (
    <>
      <RoundStatus
        currentRound={currentRound}
        nextRound={nextRound}
        previousRound={previousRound}
      />
      <IndexPage
        heading={
          <>
            <span className="text-normal">All Artists for </span>
            {accountName}
          </>
        }
        buttonText="Add Artist"
        buttonLinkTo="/artist/add"
      >
        {artists.length > 0
          ? artists.map((artist) => (
              <ArtistCard
                key={artist.id}
                name={artist.name}
                linkTo={`/artist/${artist.id}`}
              />
            ))
          : 'There are no artists associated with this account.'}
      </IndexPage>
    </>
  );
}
