import { Footer } from '../components/Footer';
import { HeroSection } from '../components/HeroSection';
import { PageContainerWithSidebar } from '../components/PageContainerWithSidebar';
import { ApplicationSidebar } from '../components/ApplicationSidebar';
import { Block } from '../components/Block';
import { Accordion } from '../components/Accordion';
import { useState } from 'react';

import styles from './FAQ.module.scss';

export function FAQ() {
  const [anchor] = useState<number>(0);

  return (
    <div className={styles.container}>
      <HeroSection
        title="Frequently Asked Questions"
        breadcrumbs={[{ text: 'Home', to: '/' }]}
      />
      <PageContainerWithSidebar>
        <ApplicationSidebar
          type="public"
          links={[
            'Does My Project Qualify?',
            'What Do I Need to Know to Apply?',
            'If I’m Approved for CSF Funding, What Happens Next?',
          ]}
        />
        <main className={styles.blocks}>
          <Block
            id="Does My Project Qualify?"
            headerText="Does My Project Qualify?"
            iconName="requirements"
            shadow={true}
            reduceFontOnMobile={true}
          >
            <Accordion
              id="1"
              forceOpen={anchor === 1}
              title="How many equivalent units must I have sold of any one previously released or currently released title to qualify for Canadian Starmaker funding?"
            >
              <p>Minimum Sales By Genre</p>
              <table className={styles.dataTable}>
                <tbody>
                  <tr>
                    <th>Pop</th>
                    <td>5,000 units</td>
                  </tr>
                  <tr>
                    <th>Rock</th>
                    <td>5,000 units</td>
                  </tr>
                  <tr>
                    <th>Hip-Hop/R&B</th>
                    <td>5,000 units</td>
                  </tr>
                  <tr>
                    <th>Country</th>
                    <td>5,000 units</td>
                  </tr>
                  <tr>
                    <th>Electronica</th>
                    <td>3,500 units</td>
                  </tr>
                  <tr>
                    <th>Folk</th>
                    <td>3,500 units</td>
                  </tr>
                  <tr>
                    <th>Global</th>
                    <td>2,000 units</td>
                  </tr>
                  <tr>
                    <th>Blues</th>
                    <td>2,000 units</td>
                  </tr>
                  <tr>
                    <th>Classical</th>
                    <td>2,000 units</td>
                  </tr>
                  <tr>
                    <th>Jazz</th>
                    <td>2,000 units</td>
                  </tr>
                </tbody>
              </table>

              <p>
                Please note: You may establish your track record from the sales
                of any previous title released within the last 5 years or from
                the sales of a current title. Any artist new to the fund or
                re-establishing sales eligibility must receive written approval
                of sales eligibility before the deadline for any round. The CSF
                allows the sale of digital downloads of full-length releases or
                bundles and physical releases to meet our net sales thresholds.
              </p>
              <p>
                Any artist new to the fund or re-establishing sales eligibility
                for our core programs must receive written approval of sales
                eligibility from Jerry Leibowitz (jerryleibowitz@starmaker.ca)
                no later than 1 week before the deadline for any round. That
                means that all proof of eligibility for artists must be provided
                significantly in advance of 1 week before the deadline so that
                all the material can be reviewed and further information
                provided if necessary before that 1 week’s advance period
                elapses.
              </p>
            </Accordion>
            <Accordion
              id="1"
              forceOpen={anchor === 1}
              title="What qualifies as a “release”?"
            >
              <p>
                A full-length release contains at least 6 unique studio
                recordings – not including acoustic re-recordings, remixes,
                versions, etc. – and at least 25 minutes of music. A bundle
                includes at least 3 unique, previously unreleased studio
                recordings – not including acoustic re-recordings, remixes,
                versions, etc. – and at least 9 minutes of music that were made
                available no earlier than 1 year prior to the release of the
                bundle.
              </p>
            </Accordion>
            <Accordion
              id="2"
              forceOpen={anchor === 2}
              title="How are “sales” calculated in relation to streaming?"
            >
              <p>
                Downloads of single tracks sold from titles released within the
                last 5 years will be allowed to meet the net sales thresholds. 5
                single-track downloads from a full-length release or bundle will
                constitute 1 sale toward achieving the minimum sales threshold
                under the eligibility requirements. Canadian streams of single
                tracks from titles released within the last 5 years will also be
                allowed to meet the net sales thresholds. 750 single-track
                streams from a full-length release or bundle will constitute 1
                sale toward achieving the minimum sales threshold under the
                eligibility requirements. The tracks must appear on the actual
                release – not remixes or versions of songs which don’t appear on
                the actual release. Only downloads and streams shown on
                Luminate/Soundscan Canada will be counted.
              </p>
            </Accordion>
            <Accordion
              id="3"
              forceOpen={anchor === 3}
              title="Will you count “Deluxe” editions in the sales eligibility?"
            >
              <p>
                We will allow the inclusion of deluxe editions of a release in
                the count towards sales eligibility as long as there are at
                least 3 additional songs added to the original version of the
                release (remixes or versions of existing tracks are acceptable).
                If the applicant has already applied for the regular version of
                the release, they should not add the deluxe version into the
                system. Still, you can include any sales or streams from the
                deluxe version should you re-apply under the original title. You
                will need to discuss the deluxe edition in the text of any new
                applications. If no applications were submitted for the regular
                version of the release, then the release date for the deluxe
                version is what applicants should use, as it will be the first
                release under which you apply for the title. You can include
                sales/streams from the previously released regular version when
                adding the deluxe edition into the system.
              </p>
            </Accordion>
            <Accordion
              id="4"
              forceOpen={anchor === 4}
              title="I am an artist releasing my first recording. Am I eligible to apply for CSF funding?"
            >
              <p>
                You are eligible once you meet the net sales minimum based on
                your genre. Please note if you do not have a previous sales
                track record, your record must be shipped or released before the
                application deadline in the round for which you are applying to
                qualify for funding
              </p>
            </Accordion>
            <Accordion
              id="5"
              forceOpen={anchor === 5}
              title="What constitutes “sales”?"
            >
              <p>
                Sales levels are determined by records, paid digital downloads,
                and streams in Canada only. Sales will be determined on “net
                sales” (“net sales” is defined as the number of units shipped,
                net of returns, excluding exports). However, this can include
                records sold offstage in Canada. You may be required on request
                to provide the Canadian Starmaker Fund with manufacturing
                receipts, distributor net sales reports and the number of units
                shipped. The Canadian Starmaker Fund can determine scanned,
                downloaded and streamed numbers for any record appearing within
                the Luminate/Soundscan Canada system, as the CSF currently
                subscribes to Luminate/Soundscan Canada. Should
                Luminate/Soundscan Canada indicate the applicant has reached but
                not exceeded the eligibility requirement for net sales, it is
                unnecessary to forward net sales reports to us.
              </p>
            </Accordion>
            <Accordion
              id="6"
              forceOpen={anchor === 6}
              title="What if Soundscan does not show enough sales/streams for eligibility?"
            >
              <p>
                Should Luminate/Soundscan Canada not indicate the net sales
                requirement has been met, a Canadian net sales report showing
                the title’s total Canadian shipments minus any returns must be
                submitted to us proving sales eligibility before the round
                deadline for an application to be considered. The information
                can be sent via email/jpeg. It can take the form of a concise
                print-out from the distributor’s computer system on the
                distributor’s letterhead or via a letter from the distributor on
                their letterhead. It must show the artist’s name, title, and
                release date and use “Canadian net sales” and the corresponding
                figure. Excel spreadsheets cannot be accepted. Manufacturing
                sheets will be accepted in specific situations, but that needs
                to be cleared with us first. Otherwise, the Canadian net sales
                report is what we need to see for the application to be
                considered. If the qualifying title that meets our net sales
                minimum for this artist is not the title for which you are
                applying, you must also send a Canadian net sales report from
                the distributor of the title from the last 5 years which meets
                our net sales criteria in the artist’s respective genre.
              </p>
            </Accordion>
            <Accordion
              id="7"
              forceOpen={anchor === 7}
              title="Under which of my releases should I apply?"
            >
              <p>
                All applications can be submitted under any bundle or
                full-length with sales eligibility from the last 5 years — you
                no longer need to apply under the most recent release. However,
                you are encouraged to indicate any new releases in your
                application. Tour dates are counted annually, so you have from
                January 1st through December 31st of each year to use the
                potential amount of available dates in each territory. Date
                counts will be reset to 0 for all territories on January 1st of
                each successive year. Remember that as long as there is no
                change in genre, sales eligibility lasts 5 years from an
                eligible title’s release date. If there is a genre or type of
                release change for the upcoming title, then you must still have
                sales eligibility with the new release to apply.
              </p>
            </Accordion>
            <Accordion
              id="8"
              forceOpen={anchor === 8}
              title="Is there a maximum number of units sold cap for artists?"
            >
              <p>
                Yes. To be eligible for the CSF, you cannot have sold more than
                150,000 equivalent units of any previously released record or
                bundle. Once any of your previous albums or bundles surpasses
                this cap, you will no longer be eligible to apply for funding.
              </p>
            </Accordion>
            <Accordion
              id="9"
              forceOpen={anchor === 9}
              title="I was in a successful band that exceeded the unit sales level cap. Does that disqualify me from applying for Canadian Starmaker funding as a solo artist?"
            >
              <p>
                No. As a solo artist, you cannot use your previous track record
                in a band as part of your unit-level requirement. If you are
                applying for funding as a solo artist, the number of units sold
                is based on your previous track record as a solo artist only. If
                you are a new solo artist, the unit-level requirement is based
                on the number of units sold on your current record. Please note
                if you do not have a previous sales track record, your record
                must be shipped or released before the application deadline in
                the round for which you are applying to qualify for funding.
              </p>
            </Accordion>
            <Accordion
              id="10"
              forceOpen={anchor === 10}
              title="Can an artist who previously capped out of the fund on sales be allowed to apply again?"
            >
              <p>No. Not under any circumstances.</p>
            </Accordion>
            <Accordion
              id="11"
              forceOpen={anchor === 11}
              title="Can I use my sales eligibility in one genre to qualify for a release in another genre?"
            >
              <p>
                You can use your sales eligibility in one genre to qualify for a
                release in another genre in certain circumstances. For example -
                if you previously qualified as a folk artist and sold 8,000
                copies of one of your folk releases from the last 5 years, you
                would automatically be eligible as a pop artist because you’ve
                already met the 5,000 unit minimum sales requirement for that
                genre. You can’t use your eligibility based on sales in a
                previous genre with a higher sales eligibility level to qualify
                under a new genre with a lower sales eligibility level. This
                means, for example, that an electronica artist can use their
                previous sales to prove rock eligibility but not the other way
                around, as rock has a higher sales eligibility level than
                electronica.
              </p>
            </Accordion>
            <Accordion
              id="12"
              forceOpen={anchor === 12}
              title="Can I still apply to the Canadian Starmaker Fund if I haven’t released a title in several years?"
            >
              <p>
                The Canadian Starmaker Fund will only consider sales from a
                bundle or full-length released within 5 years of the current
                round’s deadline to be eligible for funding.
              </p>
            </Accordion>
            <Accordion
              id="13"
              forceOpen={anchor === 13}
              title="Does the Canadian Starmaker Fund provide funding for artists releasing “live,” “greatest hits/best of,” or multiple-artist compilation records?"
            >
              <p>
                No. You can’t show sales eligibility from these types of
                releases.
              </p>
            </Accordion>
            <Accordion
              id="14"
              forceOpen={anchor === 14}
              title="Am I eligible to apply for Canadian Starmaker funding as a landed immigrant/permanent resident?"
            >
              <p>
                Yes. The Canadian Starmaker Fund will consider applications
                filed by Canadian residents who are not Canadian citizens but
                instead have been granted Canadian landed immigrant/permanent
                resident status. Any applicant who applies as a landed
                immigrant/permanent resident may be required to provide all
                pertinent documentation verifying your status.
              </p>
            </Accordion>
          </Block>
          <Block
            id="What Do I Need to Know to Apply?"
            headerText="What Do I Need to Know to Apply?"
            iconName="helpCircle"
            shadow={true}
            reduceFontOnMobile={true}
          >
            <Accordion
              id="15"
              forceOpen={anchor === 15}
              title="Can I apply for marketing (including funding for music videos) from the Canadian Starmaker Fund?"
            >
              <p>
                Yes. Applicants can apply for up to $30,000 per year to
                subsidize the creation of Digital Content (including music
                videos) in conjunction with our touring streams (domestic or
                international). These amounts are inclusive of taxes. Only costs
                that have yet to occur will be considered for funding. Artists
                signed to major labels or whose release is licensed to major
                labels in a specific territory will not be eligible to receive
                this funding.
              </p>
            </Accordion>
            <Accordion
              id="15"
              forceOpen={anchor === 15}
              title="What qualifies as “Digital Content”?"
            >
              <p>
                Digital Content funding means any combination of a videographer,
                media for online marketing (not the cost of placing any ads or
                photoshoots) and/or the cost of a music video as long as you
                list at least 3 confirmed dates in either Canada, the US or
                International (non-US) territories within 8 months of the
                deadline for the round.
              </p>
            </Accordion>
            <Accordion
              id="16"
              forceOpen={anchor === 16}
              title="Should I include music files with my application so the Board of Directors can listen to my music?"
            >
              <p>
                Yes, please attach one song from the release to any application
                unless the release is already available on a streaming platform.
                There will be no jury process that considers the artistic or
                commercial merit of the music. Before an application is
                submitted, the Canadian Starmaker Fund requires confirmation
                that the genre of music you have chosen to apply under in your
                application is appropriate for your music.
              </p>
            </Accordion>
            <Accordion
              id="17"
              forceOpen={anchor === 17}
              title="Can I apply for and receive FACTOR and Canadian Starmaker funding for the same project?"
            >
              <p>
                Yes. You may not receive funding reimbursement for any marketing
                expense you have already been reimbursed from another funding
                source, including FACTOR. As a condition of funding, you must
                sign an agreement allowing the Canadian Starmaker Fund to
                conduct full audits of your books and records to ensure the
                integrity of the funding and the accuracy of accounting. Any
                misappropriation of funds will be subject to penalties of
                repayment as well as possible suspension from using the CSF for
                up to 5 years as determined by the Board of Directors.
              </p>
              <p>
                IMPORTANT: Any CSF-approved funding for marketing initiatives
                must be 100% funded by the CSF for the expense to be considered.
                The CSF will not consider the expense if any other funding
                organization has previously recognized any portion of an
                individual invoice. If your project has received
                FACTOR/Musicaction funding (or money from any other funding
                source) or is pending approval from FACTOR/Musicaction, you are
                required to inform the CSF of your current FACTOR/Musicaction
                status by providing the CSF with the following information:
              </p>
              <ul>
                <li> Date of application filed to FACTOR/Musicaction</li>
                <li>
                  Current status in regards to your FACTOR/Musicaction
                  application
                </li>
                <li>
                  Approximate value request of your application to
                  FACTOR/Musicaction
                </li>
                <li>
                  You will also be required to provide the CSF with the final
                  details and date your FACTOR/Musicaction file is closed. Any
                  potential audit will not occur until your CSF and
                  FACTOR/Musicaction files for the same project have been
                  finalized.
                </li>
              </ul>
            </Accordion>
            <Accordion
              id="18"
              forceOpen={anchor === 18}
              title="How will I know my application has been successfully submitted?"
            >
              <p>
                You will receive an automated confirmation email for each
                application you successfully submit during a particular round.
                Should your application be approved, signed contracts will be
                necessary to receive funding.
              </p>
            </Accordion>
            <Accordion
              id="19"
              forceOpen={anchor === 19}
              title="What expenses will the Canadian Starmaker Fund cover regarding tour support?"
            >
              <p>
                The Starmaker Fund covers tour support with a direct subsidy.
                Only live appearances that have yet to take place will be
                considered for funding.
              </p>
            </Accordion>
            <Accordion
              id="20"
              forceOpen={anchor === 20}
              title="When I receive tour subsidy funding for a show from Starmaker, does that include HST?"
            >
              <p>
                No. There is no HST payable to you since you are not providing
                any “supply of property or services” to Starmaker under the
                legislation. This is a grant subsidy; therefore, you do not
                report any of the monies as HST. Please note that any funding
                you receive from the CSF will be considered taxable income.
              </p>
            </Accordion>
            <Accordion
              id="21"
              forceOpen={anchor === 21}
              title="Should my Digital Content request include any applicable taxes?"
            >
              <p>
                Yes. All Digital Content requests entered in your application
                should include all applicable taxes. If you are approved for
                $20,000 in funding from the Canadian Starmaker Fund, you are
                eligible to receive $20,000 based on invoice totals inclusive of
                applicable taxes. The total amount of funding paid by the
                Canadian Starmaker Fund effectively constitutes payment of
                approved funding based on the appropriate documentation
                submitted (verifying proof of payment of expenses). The
                applicant should treat it as grant income with no additional
                GST/HST in the total paid by the Canadian Starmaker Fund. Please
                note that any funding you receive from the CSF will be
                considered taxable income.
              </p>
            </Accordion>
            <Accordion
              id="23"
              forceOpen={anchor === 23}
              title="What is a 3rd party company?"
            >
              <p>
                The CSF defines a 3rd party company as a company where you own
                40% or less. Additionally, to be considered 3rd party, a sole
                proprietor or company must have at least 3 distinct sources of
                income from unaffiliated parties in a related field of work
                during a calendar year.
              </p>
            </Accordion>
            <Accordion
              id="24"
              forceOpen={anchor === 24}
              title="Does the CSF fund any touring or live appearances?"
            >
              <p>
                Yes. The CSF funds domestic and international touring and live
                appearances for eligible applicants with a subsidy of $2,500 per
                performance. The caps and potential subsidies are as follows:
              </p>
              <dl className={styles.dataList}>
                <div>
                  <dt>Domestic Touring Funding:</dt>
                  <dd>Canada 35 shows max. $2,500 per show</dd>
                </div>
                <div>
                  <dt>USA Touring Funding:</dt>
                  <dd>USA 35 shows max. $2,500 per show</dd>
                </div>
                <div>
                  <dt>International Touring Funding (excluding USA):</dt>
                  <dd>35 shows max. $2,500 per show</dd>
                </div>
              </dl>
              <p>
                You can apply for dates that take place before the deadline for
                the round in which you are applying. Only live appearances that
                have yet to take place will be considered for funding. You are
                welcome to apply for both domestic and international dates in
                the same round.
              </p>
            </Accordion>
          </Block>
          <Block
            id="If I’m Approved for CSF Funding, What Happens Next?"
            headerText="If I’m Approved for CSF Funding, What Happens
            Next?"
            iconName="checkCircle"
            shadow={true}
            reduceFontOnMobile={true}
          >
            <Accordion
              id="25"
              forceOpen={anchor === 25}
              title="Once my application is approved, will I get all of my approved funding at once?"
            >
              <p>
                All approved applicants will receive their Canadian Starmaker
                Funding according to the Canadian Starmaker Fund contract.
              </p>
              <ul>
                <li>
                  If you are approved for Touring, you will be paid 50% of the
                  approved funding (for confirmed tour dates only) upon receipt
                  of your signed CSF contract(s). The final 50% of funding will
                  be paid to you upon completion of your tour and written
                  confirmation of your tour dates/live appearances
                  (date/city/venue). Also, if you applied for domestic or
                  international touring dates from FACTOR for the same
                  appearances that you were approved for by the CSF, you are
                  required to inform FACTOR of the details of your CSF grant.
                </li>
                <li>
                  If you are approved for Digital Content, you will be paid 50%
                  of the approved funding upon receipt of your signed CSF
                  contract(s). Upon completing your approved initiatives and
                  submitting your online claim form, the final 50% of the
                  funding will be paid to you. Also, if you applied for
                  marketing monies from FACTOR for the same release that the CSF
                  approved for you, you are required to inform FACTOR of the
                  details of your CSF grant.
                </li>
                <li>
                  If approved for the Orion program, you will be paid 65% of the
                  approved funding upon receipt of your signed CSF contract(s).
                  Upon completing your approved initiatives and submitting your
                  online claim form, the final 35% of the funding will be paid
                  to you.
                </li>
                <li>
                  You will have up to a maximum of 12 months from the deadline
                  for the round to submit your final claim form to the Canadian
                  Starmaker Fund for payment representing 100% of the total
                  approved funding. Once your file has been fully reconciled,
                  any unused portion of your approved funding will be
                  reallocated back into the Fund and will no longer be available
                  to you. The CSF Board of Directors has adopted a
                  zero-tolerance policy regarding the late files. Failure to
                  submit your completed claim in time to meet your final funding
                  claims deadline will result in the forfeiting of your funding
                  and repayment of any CSF funding you have received to date for
                  your project. Unless otherwise indicated, all tour program
                  funding will be paid to the artist or artist representative.
                </li>
              </ul>
            </Accordion>
            <Accordion
              id="26"
              forceOpen={anchor === 26}
              title="Once I submit my application, how will I know if I have been approved for Canadian Starmaker funding?"
            >
              <p>
                The Board of Directors of the Canadian Starmaker Fund will meet
                within approximately 1 month of the application deadline to
                review all submitted applications. The dates of our Board
                meetings are posted on our website. The Board makes allocations
                based on available funding and in accordance with the
                fundamental objectives of the Fund. Consequently, not all the
                funding sought will be approved in most cases. The Canadian
                Starmaker Fund will inform you within approximately 5 business
                days of the Board meeting whether your application has been
                approved. You will then be asked to sign a Canadian Starmaker
                Fund contract outlining your approved dates for touring
                applications and Digital Content, if any. You will be required
                to sign and upload the contract to the CSF website within 90
                days of the application deadline for the round in which you have
                been approved for funding. Once we have received the fully
                signed CSF contracts, every effort will be made to send your
                first payment as quickly as possible.
              </p>
            </Accordion>
            <Accordion
              id="27"
              forceOpen={anchor === 27}
              title="Do I have any recourse if my application is not approved for funding?"
            >
              <p>
                The Board of Directors of the Canadian Starmaker Fund shall make
                the final determination on all funding requests, and their
                decision is final and binding and not subject to review or
                appeal.
              </p>
            </Accordion>
            <Accordion
              id="28"
              forceOpen={anchor === 28}
              title="If the Canadian Starmaker Fund approves my application, am I required to include the Canadian Starmaker Fund logo in any advertising, etc.?"
            >
              <p>No.</p>
            </Accordion>
            <Accordion
              id="29"
              forceOpen={anchor === 29}
              title="What happens if one of my approved touring dates is cancelled or my digital content request has changed? Can I substitute the cancelled date with another or change my digital content request?"
            >
              <p>
                The Canadian Starmaker Fund is committed to adapting to the
                changing needs of artists and labels. Every effort will be made
                to accommodate your situation (all substantial changes must be
                approved in writing before the change occurs). The protocol to
                make a change request in our system is to enter the change
                request in the “alternate request” section of your claim. Kindly
                void the original request(s) you are asking to change or any
                initiatives/dates that will not happen. We will be
                electronically notified of the request and either approve it or
                let you know if there’s a problem. Please note – we cannot
                approve any changes that have already happened. The change
                requests will have to be reviewed when the final paperwork is
                received, but you always want to ensure there are no issues
                before you make changes – not after. Any alteration to your
                approved funding initiatives without the Canadian Starmaker
                Fund’s prior written consent may invalidate your funding. All
                approved funding must be spent no later than 8 months after each
                round’s application deadline. Requests to change approved
                Digital Content funding or tour dates made in the last 2 months
                of the spending period will not be considered outside of
                extraordinary circumstances.
              </p>
            </Accordion>
          </Block>
        </main>
      </PageContainerWithSidebar>
      <Footer />
    </div>
  );
}
