import { match } from 'ts-pattern';
import { GraphQLClient } from 'graphql-request';
import {
  UpdateAdminFundingRequest,
  UpdateFundingRequest,
} from '../../events/UpdateFundingRequest';
import { UpdateFundingRequestDocument } from '../../graphql/operations';
import { upsertFundingRequestResultSchema } from '../../schemas/fundingRequests/upsertFundingRequestResultSchema';
import { prepareFundingRequest } from './prepareFundingRequest';
import { prepareAdminFundingRequest } from './prepareAdminFundingRequest';

export const createUpdateFundingRequestPromise =
  (client: GraphQLClient) =>
  async (
    _: unknown,
    event: UpdateFundingRequest | UpdateAdminFundingRequest
  ) => {
    const parsedRequest = match(event)
      .with({ type: 'UPDATE_REQUEST' }, ({ request }) =>
        prepareFundingRequest(request)
      )
      .with({ type: 'UPDATE_ADMIN_REQUEST' }, ({ request }) =>
        prepareAdminFundingRequest(request)
      )
      .exhaustive();

    const response = await client.request(UpdateFundingRequestDocument, {
      id: event.id,
      request: parsedRequest,
    });

    return upsertFundingRequestResultSchema.parse(
      response.request?.fundingRequest
    );
  };

export type CreateUpdateFundingRequestPromise = ReturnType<
  typeof createUpdateFundingRequestPromise
>;

export type CreateUpdateFundingRequestPromiseResult = Awaited<
  ReturnType<CreateUpdateFundingRequestPromise>
>;
