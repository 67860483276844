import { GraphQLClient } from 'graphql-request';
import { DeleteSingleDocument } from '../graphql/operations';

export const createDeleteSinglePromise =
  (client: GraphQLClient) => (_: unknown, event: { id: string }) => {
    return client.request(DeleteSingleDocument, {
      input: {
        id: event.id,
      },
    });
  };

export type DeleteSinglePromise = ReturnType<typeof createDeleteSinglePromise>;
export type DeleteSinglePromiseResult = Awaited<
  ReturnType<DeleteSinglePromise>
>;
