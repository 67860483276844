import cn from 'classnames';

import styles from './HeroSection.module.scss';
import { Breadcrumb, Breadcrumbs } from './Breadcrumbs';
import { Button } from './Button';
import { useWindowSize } from '../hooks/useWindowSize';

interface HeroSectionProps {
  title: string;
  breadcrumbs?: Breadcrumb[];
  rounded?: boolean;
  primaryButtonText?: string;
  secondaryButtonText?: string;
  primaryButtonLinkTo?: string;
  secondaryButtonLinkTo?: string;
  children?: React.ReactNode;
  rightPanel?: React.ReactNode;
  currentPageName?: string;
}

export function HeroSection({
  title,
  breadcrumbs,
  rounded = false,
  primaryButtonText,
  secondaryButtonText,
  primaryButtonLinkTo,
  secondaryButtonLinkTo,
  children,
  rightPanel,
  currentPageName,
}: HeroSectionProps) {
  const window = useWindowSize();

  return (
    <div
      className={cn(styles.container, {
        [styles.rounded]: rounded,
      })}
    >
      <div
        className={cn(
          {
            [styles.withBreadcrumbs]: breadcrumbs,
            [styles.withoutBreadcrumbs]: !breadcrumbs,
          },
          'max-w-xl'
        )}
      >
        {breadcrumbs && (
          <Breadcrumbs
            type="hero"
            breadcrumbs={breadcrumbs}
            currentPageName={currentPageName}
          />
        )}

        <div
          className={cn({
            [styles.oneCol]: !rightPanel,
            [styles.twoCol]: rightPanel,
          })}
        >
          <div className={cn(styles.content)}>
            <div className={styles.text}>
              <h1>{title}</h1>
              {children}
            </div>

            <div className={styles.buttons}>
              {primaryButtonText && (
                <Button
                  size="small"
                  length="full"
                  label={primaryButtonText}
                  linkTo={primaryButtonLinkTo}
                  className={styles.buttonContainer}
                  buttonClassName={styles.button}
                />
              )}
              {secondaryButtonText && (
                <Button
                  size="small"
                  length="full"
                  label={secondaryButtonText}
                  linkTo={secondaryButtonLinkTo}
                  className={styles.buttonContainer}
                  buttonClassName={styles.button}
                />
              )}
            </div>
          </div>

          {rightPanel}
        </div>
      </div>
      {rounded && window.screen !== 'mobile' && (
        <svg className={styles.mask}>
          <defs>
            <clipPath id="hero" clipPathUnits="objectBoundingBox">
              <path d="M0,0 H1 V0.876 C1,0.876,0.701,1,0.5,1 C0.299,1,0,0.876,0,0.876 V0" />
            </clipPath>
          </defs>
        </svg>
      )}
    </div>
  );
}
