import { assign, createMachine } from 'xstate';

type Events = { type: 'UPDATE_FORM_VALUE'; value: string } | { type: 'SUBMIT' };

export type Context = {
  formValue: string;
};

export const machine = createMachine(
  {
    predictableActionArguments: true,
    tsTypes: {} as import('./SaveableTextAreaMachine.typegen').Typegen0,
    schema: {
      events: {} as Events,
      context: {} as Context,
    },
    context: {
      formValue: '',
    },
    id: 'saveableTextArea',
    initial: 'dataEntry',
    states: {
      dataEntry: {
        on: {
          UPDATE_FORM_VALUE: {
            actions: 'updateFormValue',
          },
          SUBMIT: {
            target: 'submitting',
          },
        },
      },
      submitting: {
        after: [{ delay: 'uiDelay', target: 'dataEntry' }],
      },
    },
  },
  {
    actions: {
      updateFormValue: assign((_, { value }) => ({
        formValue: value,
      })),
    },
    delays: {
      uiDelay: 1000,
    },
  }
);
