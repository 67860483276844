import { match } from 'ts-pattern';
import { ArtistStatus } from '../../../graphql/operations';
import {
  ArtistDetailsBlockMachineSender,
  ArtistDetailsBlockMachineState,
} from '../../../machines/components/ArtistDetailsBlockMachine';
import { getArtistStatusLabel } from '../../../utils/getArtistStatusLabel';
import { Button } from '../../Button';
import { ErrorFeedback } from '../../ErrorFeedback';
import { Feedback } from '../../Feedback';
import { FormField } from '../../FormField';
import { InlineFormContainer } from '../../InlineFormContainer';
import { Select } from '../../Select';

import styles from './AdminMenu.module.scss';

interface AdminMenuProps {
  state: ArtistDetailsBlockMachineState;
  send: ArtistDetailsBlockMachineSender;
}

export function AdminMenu({ state, send }: AdminMenuProps) {
  const { status } = state.context.artist;
  const isPendingOrion = status === ArtistStatus.PendingOrion;
  const selectedItem = state.context.adminMenu.status;
  const isSaving = state.matches('adminMenu.saving');

  return match(state)
    .when(
      () => state.matches('adminMenu.closed'),
      () => (
        <Feedback
          type={isPendingOrion ? 'warning' : 'neutral'}
          icon={isPendingOrion ? 'clock' : 'star'}
          title={
            <p className={styles.title}>
              {!isPendingOrion && (
                <span className={styles.label}>Artist Status: </span>
              )}
              {getArtistStatusLabel(status)}
            </p>
          }
          size="compact"
          feedbackButtons={
            <Button
              variant="secondary"
              label="Change Artist Status"
              startIcon="settings"
              size="small"
              onClick={() => {
                send({
                  type: 'OPEN_ADMIN_MENU',
                });
              }}
            />
          }
        />
      )
    )
    .when(
      () => state.matches('adminMenu.open') || isSaving,
      () => (
        <InlineFormContainer
          primaryButtonOnClick={() => {
            send('SAVE_ADMIN_MENU');
          }}
          primaryButtonText="Change Status"
          secondaryButtonOnClick={() => {
            send('CANCEL_ADMIN_MENU');
          }}
          secondaryButtonText="Cancel"
          primaryButtonDisabled={selectedItem === status || isSaving}
        >
          <FormField label="Artist Status">
            <Select
              disabled={isSaving}
              id="statusMenu"
              items={[
                ArtistStatus.Core,
                ArtistStatus.Orion,
                ArtistStatus.PendingOrion,
              ]}
              getItemText={(item) => getArtistStatusLabel(item)}
              selectedItem={selectedItem}
              initialSelectedItem={status}
              onChange={(status) => {
                send({ type: 'SELECT_ADMIN_MENU', status });
              }}
              itemToKey={(item) => item}
            />
          </FormField>
        </InlineFormContainer>
      )
    )
    .when(
      () => state.matches('adminMenu.error'),
      () => <ErrorFeedback />
    )
    .otherwise(() => <></>);
}
