import { Icon, IconName } from './Icon';
import cn from 'classnames';

import styles from './Feedback.module.scss';

export type FeedbackType = 'neutral' | 'warning' | 'positive' | 'primary';

export type FeedbackSize = 'default' | 'compact';

interface FeedbackProps {
  type: FeedbackType;
  size?: FeedbackSize;
  icon?: IconName;
  title?: string | React.ReactElement;
  children?: React.ReactNode;
  className?: string;
  pulse?: boolean;
  feedbackButtons?: React.ReactNode;
}

export function Feedback({
  type,
  size = 'default',
  icon,
  title = <></>,
  children,
  className,
  pulse = false,
  feedbackButtons,
}: FeedbackProps) {
  if (!icon) {
    icon = type === 'positive' ? 'checkCircle' : 'alertTriangle';
  }

  if (feedbackButtons) {
    return (
      <div
        className={cn(
          styles.container,
          className,
          styles[type],
          styles.compact,
          styles.withButton,
          {
            [styles.pulse]: pulse,
          }
        )}
      >
        <div className={styles.header}>
          <div className={styles.iconContainer}>
            <Icon name={icon} className={styles.icon} />
            {typeof title === 'string' ? (
              <span className={styles.title}>{title}</span>
            ) : (
              title
            )}
          </div>
          <div className={styles.desktopDisplay}>{feedbackButtons}</div>
        </div>
        {children && <div className={styles.children}>{children}</div>}
        <div className={styles.mobileDisplay}>{feedbackButtons}</div>
      </div>
    );
  }

  return (
    <div
      className={cn(styles.container, className, styles[type], {
        [styles.compact]: size === 'compact',
        [styles.pulse]: pulse,
      })}
    >
      <div className={styles.iconContainer}>
        <Icon name={icon} className={styles.icon} />
      </div>
      <div className={styles.contents}>
        <div className={styles.textContainer}>
          {typeof title === 'string' ? (
            <div className={styles.title}>{title}</div>
          ) : (
            title
          )}
          {children && <div className={styles.children}>{children}</div>}
        </div>
      </div>
    </div>
  );
}
