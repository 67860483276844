import { match } from 'ts-pattern';
import { StatementType } from '../../utils/getApplicationStatementType';

import styles from './StatementInstructions.module.scss';
interface StatementInstructionsProps {
  type: StatementType;
}

export function StatementInstructions({ type }: StatementInstructionsProps) {
  return (
    <div className={styles.text}>
      {match(type)
        .with('Tour Strategy', () => (
          <>
            <p>
              Let us know about any notable aspects of this tour, including the
              following:
            </p>
            <ul>
              <li>Other performers on the date(s).</li>
              <li>
                A concise overview of the touring party, including whether this
                is a solo, duo or band tour.
              </li>
              <li>
                For artists new to the fund, a few sentences providing some
                background information and details on their career momentum.
              </li>
            </ul>
            <p>
              Include details on the non-ticketed or free live appearances for
              which you are applying, including information on the events
              themselves and why they should be funded – not just that they are
              free. Failure to do so will result in those dates being forfeited.
            </p>
          </>
        ))
        .with('Proposal', () => (
          <>
            <p>
              Add attachments below, elaborating on proof of eligibility,
              breakdowns of expenditures or other key information. Brief details
              on domestic or international activity and momentum (e.g. press
              clippings, letters of support, social media metrics, award
              nominations, etc.) should be included in the proposal to
              illustrate why your request should be funded.
            </p>
            <p>
              Money can be requested for any music related expenses – recording,
              promotion, publicity, advertising, digital marketing, touring,
              videos, etc. The maximum overall request will be capped at $20,000
              and artists will only be considered for one of these grants per
              calendar year.
            </p>
            <p>
              Please include an itemized breakdown of your proposed spending in
              the Funding Request section below.
            </p>
            <p>
              Approved applicants will receive two-thirds of the approved amount
              upfront and the remaining monies will be provided upon a review of
              a completion report and proof of expenditures. You must submit a
              completion report within 12 months of the deadline of the current
              round.
            </p>
          </>
        ))
        .exhaustive()}
    </div>
  );
}
