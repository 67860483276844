import { ApplicationType } from '../graphql/operations';

export type StatementType = 'Proposal' | 'Tour Strategy';

export function getApplicationStatementType(
  applicationType: ApplicationType
): StatementType {
  return applicationType === ApplicationType.Orion
    ? 'Proposal'
    : 'Tour Strategy';
}
