import { GraphQLClient } from 'graphql-request';
import { z } from 'zod';
import {
  GetAccountDetailsDocument,
  GetAccountDetailsQueryVariables,
} from '../graphql/operations';
import { accountDetailsSchema } from '../schemas/accountDetailsSchema';
import { IdNotFoundError } from '../errors/IdNotFoundError';

export const createGetAccountDetailsPromise =
  (client: GraphQLClient) =>
  async (variables: GetAccountDetailsQueryVariables) => {
    const request = await client.request(GetAccountDetailsDocument, variables);

    if (!request.account) {
      throw new IdNotFoundError();
    }

    return z
      .object({
        account: accountDetailsSchema,
        minimumPasswordLength: z.number(),
      })
      .parse(request);
  };

export type GetAccountDetailsPromise = ReturnType<
  typeof createGetAccountDetailsPromise
>;
export type GetAccountDetailsPromiseResult = Awaited<
  ReturnType<GetAccountDetailsPromise>
>;
