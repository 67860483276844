import { Feedback } from './Feedback';
import cn from 'classnames';

import styles from './FieldSet.module.scss';

interface FieldSetProps {
  label?: string;
  stretch?: 'none' | 'full';
  direction?: 'horizontal' | 'vertical';
  infoLabel?: string;
  required?: boolean;
  children: React.ReactNode;
  className?: string;
}

export function FieldSet({
  label,
  stretch = 'full',
  direction = 'vertical',
  infoLabel,
  required,
  children,
  className,
}: FieldSetProps) {
  return (
    <div className={cn(styles.fieldset, className)}>
      {label && (
        <div className={styles.row}>
          <legend
            className={cn(styles.label, {
              [styles.stretch]: stretch === 'full',
            })}
          >
            {label}
          </legend>
          {required && <div className={styles.requiredLabel}>required</div>}
        </div>
      )}
      <div className={cn(styles.options, styles[direction])}>{children}</div>
      {infoLabel && <Feedback type="neutral">{infoLabel}</Feedback>}
    </div>
  );
}
