import { useSearchParams } from 'react-router-dom';
import { useGraphQLClient } from '../../../components/GraphQLClientProvider';
import { useWindowSize } from '../../../hooks/useWindowSize';
import { createContext } from '../../../machines/Admin/Artists/SearchArtistsMachine';
import { createSearchArtistsPromise } from '../../../promises/Admin/createSearchArtistsPromise';
import { withDelay } from '../../../utils/withDelay';
import { SearchArtistsController } from './SearchArtistsController';

export const SearchArtists = () => {
  const client = useGraphQLClient();
  const { screen } = useWindowSize();
  const [searchParams] = useSearchParams();

  return (
    <SearchArtistsController
      options={{
        context: createContext(
          [],
          screen === 'mobile',
          0,
          searchParams.get('status') === 'pending-orion'
        ),
        actions: {
          // this isn't necessary for the Admin page as it isn't invoked as a child machine
          sendArtistsToParent: () => null,
        },
        services: {
          searchArtistsPromise: withDelay(createSearchArtistsPromise(client)),
        },
      }}
    />
  );
};
