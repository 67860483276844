import { Icon, IconName } from './Icon';
import cn from 'classnames';
import { match } from 'ts-pattern';

import styles from './RoundStatusBanner.module.scss';

type Status = 'open' | 'closed' | 'closingSoon';

interface RoundStatusBannerProps {
  status: Status;
  heading: string;
  subText: string;
}

export function RoundStatusBanner({
  status,
  heading,
  subText,
}: RoundStatusBannerProps) {
  return (
    <div
      className={cn(styles.container, {
        [styles.closedContainer]: status === 'closed',
        [styles.closingSoonContainer]: status === 'closingSoon',
      })}
    >
      <div>
        <Icon
          name={match<Status, IconName>(status)
            .with('open', () => 'star')
            .with('closed', () => 'lock')
            .with('closingSoon', () => 'alertTriangle')
            .exhaustive()}
          className={cn(styles.icon, styles[status])}
        />
      </div>
      <div className={styles.col}>
        <p className={styles.bold}>{heading}</p>
        <p className={styles.small}>{subText}</p>
      </div>
    </div>
  );
}
