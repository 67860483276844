import { ZonedDateTime } from '@internationalized/date';
import { formatZonedDate } from '../../utils/formatZonedDateTime';
import { Button } from '../Button';
import styles from './ReopenClaimsNotesView.module.scss';
import { ReopenDeadlineParagraph } from './ReopenDeadlineParagraph';

interface ReopenClaimsNotesViewProps {
  notes: string;
  onEdit: () => void;
  claimsResubmittedAt: ZonedDateTime | null;
  claimsReopenedAt: ZonedDateTime | null;
  claimsResubmissionDeadline: ZonedDateTime | null;
}

export function ReopenClaimsNotesView({
  notes,
  onEdit,
  claimsReopenedAt,
  claimsResubmittedAt,
  claimsResubmissionDeadline,
}: ReopenClaimsNotesViewProps) {
  return (
    <div className={styles.container}>
      <div className={styles.top}>
        <div className={styles.info}>
          <h3 className={styles.title}>Claim Review Notes</h3>
          <span className={styles.subtitle}>
            Created on:{' '}
            <span className={styles.bold}>
              {formatZonedDate(claimsReopenedAt, 'numeric')}
            </span>
          </span>
        </div>
        {!claimsResubmittedAt && (
          <Button
            size="small"
            variant="secondary"
            label="Edit"
            onClick={onEdit}
            startIcon="edit"
          />
        )}
      </div>
      <div className={styles.content}>
        <p>{notes}</p>
        <br />
        <ReopenDeadlineParagraph
          claimsResubmissionDeadline={claimsResubmissionDeadline}
        />
      </div>
    </div>
  );
}
