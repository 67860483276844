import { match } from 'ts-pattern';
import { formatZonedDateTime } from '../utils/formatZonedDateTime';
import { RoundStatusBanner } from './RoundStatusBanner';
import { RoundDetails } from '../schemas/roundDetailsSchema';

interface RoundStatusProps {
  previousRound: RoundDetails['previousRound'];
  currentRound: RoundDetails['currentRound'];
  nextRound: RoundDetails['nextRound'];
}

export function RoundStatus({
  currentRound,
  nextRound,
  previousRound,
}: RoundStatusProps) {
  return match(currentRound)
    .with(null, () => (
      <RoundStatusBanner
        status="closed"
        heading={
          previousRound
            ? `Funding Round #${
                previousRound.title
              } closed for applications on ${formatZonedDateTime(
                previousRound.endDate
              )}.`
            : 'We are not accepting applications at the moment.'
        }
        subText={
          nextRound
            ? `Details for the next Funding Round, #${nextRound.title}, will be announced soon.`
            : ''
        }
      />
    ))
    .with({ closingSoon: true }, (currentRound) => (
      <RoundStatusBanner
        status="closingSoon"
        heading={`Round #${
          currentRound.title
        } closes for applications on ${formatZonedDateTime(
          currentRound.endDate
        )}.`}
        subText="Please make sure to complete and submit your application before this deadline."
      />
    ))
    .with({ closingSoon: false }, (currentRound) => (
      <RoundStatusBanner
        status="open"
        heading={`Round #${currentRound.title} is open for funding.`}
        subText={`Round closes for applications on ${formatZonedDateTime(
          currentRound.endDate
        )}.`}
      />
    ))
    .exhaustive();
}
