import { EventFrom, Sender, spawn, assign, createMachine } from 'xstate';
import type { MachineOptionsWithContextFrom } from '../../utils/MachineOptionsWithContextFrom';
import type { StateFrom } from '../../utils/StateFrom';
import { logMachineError } from '../../utils/logError';
import { GetBoardMeetingContextPromiseResult } from '../../promises/getBoardMeetingContextPromise';
import { BoardMeetingContextQuery } from '../../schemas/boardMeetingContextQuerySchema';
import { BoardMeetingBlock } from '../../schemas/boardMeetingBlockSchema';
import {
  machine as AdminBlockMachine,
  createContext as createBlockContext,
  AdminBoardMeetingBlockMachineActor,
} from './AdminBoardMeetingBlockMachine';
import { AddBoardMeetingBlockPromiseResult } from '../../promises/Admin/createAddBoardMeetingBlockPromise';
import { DeleteBoardMeetingBlockPromiseResult } from '../../promises/Admin/createDeleteBoardMeetingBlockPromise';
import { UpdateBoardMeetingBlockPromiseResult } from '../../promises/Admin/createUpdateBoardMeetingBlockPromise';
import { SwapBoardMeetingBlockPromiseResult } from '../../promises/Admin/createSwapBoardMeetingBlocksPromise';
import { createUploadFilePromise } from '../../promises/Attachments/createUploadFilePromise';
import { createDeleteFilePromise } from '../../promises/Attachments/createDeleteFilePromise';
import { DownloadBoardAttachmentPromiseResult } from '../../promises/Attachments/createDownloadBoardAttachmentPromise';
import { Attachment } from '../../schemas/attachment';
import { findById } from '../../utils/findById';

type Events =
  | {
      type: 'ADD_BLOCK';
      title: string;
      content: string;
      roundId: string;
    }
  | {
      type: 'EDIT_BLOCK';
      title: string;
      content: string;
      id: string;
    }
  | { type: 'DELETE_BLOCK'; block: BoardMeetingBlock }
  | { type: 'MOVE_BLOCK'; id: string; direction: 'up' | 'down' }
  | { type: 'UPDATE_BLOCK'; data: BoardMeetingBlock }
  | { type: 'DOWNLOAD_ATTACHMENT'; file: Attachment; blockId: string };

type Services = {
  getBoardMeetingContext: {
    data: GetBoardMeetingContextPromiseResult;
  };
  addBoardMeetingBlock: {
    data: AddBoardMeetingBlockPromiseResult;
  };
  deleteBoardMeetingBlock: {
    data: DeleteBoardMeetingBlockPromiseResult;
  };
  updateBoardMeetingBlock: {
    data: UpdateBoardMeetingBlockPromiseResult;
  };
  moveBoardMeetingBlock: {
    data: SwapBoardMeetingBlockPromiseResult;
  };
  downloadAttachment: { data: DownloadBoardAttachmentPromiseResult };
};

export interface AdminBlock {
  block: BoardMeetingBlock;
  ref: AdminBoardMeetingBlockMachineActor;
}

export interface Context extends Omit<BoardMeetingContextQuery, 'pageBlocks'> {
  isAdmin: boolean;
  readOnlyBlocks: BoardMeetingBlock[];
  adminBlocks: AdminBlock[];
  addBlockRef?: AdminBoardMeetingBlockMachineActor;
}

export function createContext(isAdmin: boolean): Context {
  return {
    round: null,
    nextMeetingDate: null,
    readOnlyBlocks: [],
    isAdmin,
    adminBlocks: [],
    addBlockRef: undefined,
  };
}

function createRef(roundId: string, block?: BoardMeetingBlock) {
  return spawn(
    AdminBlockMachine.withContext(
      createBlockContext(roundId, block)
    ).withConfig({
      services: {
        uploadFilePromise: createUploadFilePromise({
          directory: 'board-meeting-attachments',
        }),
        deleteFilePromise: createDeleteFilePromise({
          directory: 'board-meeting-attachments',
        }),
      },
    })
  ) as AdminBoardMeetingBlockMachineActor;
}

function createAdminBlock(
  roundId: string,
  block: BoardMeetingBlock
): AdminBlock {
  return { block, ref: createRef(roundId, block) };
}

function createContextBlocks(
  blocks: BoardMeetingBlock[],
  round: GetBoardMeetingContextPromiseResult['round'],
  isAdmin: boolean
) {
  return isAdmin && round
    ? {
        adminBlocks: blocks.map((block) => createAdminBlock(round.id, block)),
        addBlockRef: createRef(round.id),
      }
    : { readOnlyBlocks: blocks };
}

export const machine = createMachine(
  {
    predictableActionArguments: true,
    tsTypes: {} as import('./BoardMeetingMachine.typegen').Typegen0,
    id: 'boardMeeting',
    schema: {
      context: {} as Context,
      events: {} as Events,
      services: {} as Services,
    },
    initial: 'loading',
    states: {
      loading: {
        invoke: {
          id: 'getBoardMeetingContext',
          src: 'getBoardMeetingContext',
          onDone: {
            actions: 'setContext',
            target: 'ready',
          },
          onError: 'error',
        },
      },
      error: { entry: 'logMachineError' },
      ready: {
        initial: 'default',
        states: {
          default: {},
          add: {
            invoke: {
              id: 'addBoardMeetingBlock',
              src: 'addBoardMeetingBlock',
              onDone: {
                actions: 'addBlock',
                target: 'default',
              },
              onError: '#boardMeeting.error',
            },
          },
          delete: {
            invoke: {
              id: 'deleteBoardMeetingBlock',
              src: 'deleteBoardMeetingBlock',
              onDone: {
                actions: 'deleteBlock',
                target: 'default',
              },
              onError: '#boardMeeting.error',
            },
          },
          update: {
            invoke: {
              id: 'updateBoardMeetingBlock',
              src: 'updateBoardMeetingBlock',
              onDone: {
                actions: 'updateBlock',
                target: 'default',
              },
              onError: '#boardMeeting.error',
            },
          },
          move: {
            invoke: {
              id: 'moveBoardMeetingBlock',
              src: 'moveBoardMeetingBlock',
              onDone: {
                actions: 'swapBlocks',
                target: 'default',
              },
              onError: '#boardMeeting.error',
            },
          },
          download: {
            invoke: {
              id: 'downloadAttachment',
              src: 'downloadAttachment',
              onDone: { actions: 'openAttachment', target: 'default' },
              onError: '#boardMeeting.error',
            },
          },
        },
        on: {
          ADD_BLOCK: '.add',
          EDIT_BLOCK: '.update',
          DELETE_BLOCK: '.delete',
          MOVE_BLOCK: '.move',
          UPDATE_BLOCK: {
            actions: 'updateBlock',
          },
          DOWNLOAD_ATTACHMENT: '.download',
        },
      },
    },
  },
  {
    actions: {
      logMachineError,
      setContext: assign(({ isAdmin }, { data }) => ({
        round: data.round,
        nextMeetingDate: data.nextMeetingDate,
        ...createContextBlocks(data.pageBlocks, data.round, isAdmin),
      })),
      addBlock: assign(({ adminBlocks }, { data }) => ({
        adminBlocks: [
          ...adminBlocks,
          createAdminBlock(data.fundingRoundId, data),
        ],
        addBlockRef: createRef(data.fundingRoundId),
      })),
      updateBlock: assign(({ adminBlocks }, { data }) => {
        return {
          adminBlocks: adminBlocks.map((item) =>
            item.block.id === data.id
              ? createAdminBlock(data.fundingRoundId, data)
              : item
          ),
        };
      }),
      deleteBlock: assign(({ adminBlocks }, { data }) => ({
        adminBlocks: adminBlocks.filter(({ block }) => block.id !== data.id),
      })),
      swapBlocks: assign(({ adminBlocks }, { data }) => ({
        adminBlocks: adminBlocks
          .map((item) => {
            const replacement = findById(data, item.block.id);
            return replacement
              ? createAdminBlock(replacement.fundingRoundId, replacement)
              : item;
          })
          .sort((a, b) => a.block.displayOrder - b.block.displayOrder),
      })),
      openAttachment: (_, event) => window.open(event.data),
    },
  }
);

type Machine = typeof machine;

export type BoardMeetingMachineState = StateFrom<Machine>;
export type BoardMeetingMachineSender = Sender<EventFrom<Machine>>;
export type BoardMeetingMachineOptions = MachineOptionsWithContextFrom<Machine>;
