import { match } from 'ts-pattern';
import type {
  Sender,
  EventFrom,
  MachineOptionsFrom,
  ActorRefFrom,
} from 'xstate';
import { createMachine, assign, sendParent } from 'xstate';
import {
  UpdateReleaseSalesResult,
  isSuccess,
} from '../../promises/createUpdateReleaseSalesPromise';
import { logMachineError } from '../../utils/logError';
import { parsePositiveInteger } from '../../utils/parsePositiveInteger';
import { StateFrom } from '../../utils/StateFrom';
import {
  Context as ParentContext,
  cancelEditSales,
  updateCachedRelease,
} from '../Release/ReleaseMachine';

type Events =
  | { type: 'ATTEMPT_SAVE' }
  | { type: 'EDIT' }
  | { type: 'CANCEL_EDITING' }
  | { type: 'UPDATE_GENRE'; genreId: string }
  | { type: 'BLUR_GENRE' }
  | { type: 'UPDATE_RELEASES_SCANNED'; releasesScanned: string }
  | { type: 'BLUR_RELEASES_SCANNED' }
  | { type: 'UPDATE_TRACKS_DOWNLOADED'; tracksDownloaded: string }
  | { type: 'BLUR_TRACKS_DOWNLOADED' }
  | { type: 'UPDATE_TRACKS_STREAMED'; tracksStreamed: string }
  | { type: 'BLUR_TRACKS_STREAMED' };

type Sales = {
  genreId: string;
  releasesScanned: string;
  tracksDownloaded: string;
  tracksStreamed: string;
};

export type Context = {
  releaseId: string;
  releaseIsRestricted: boolean;
  values: Partial<Sales>;
  genres: NonNullable<ParentContext['genres']>;
  releasesScannedDivisor: number;
  tracksStreamedDivisor: number;
  tracksDownloadedDivisor: number;
  isAdmin: boolean;
};

type Services = {
  updateReleaseSales: {
    data: UpdateReleaseSalesResult;
  };
};

export const createContext = (
  releaseId: string,
  releaseIsRestricted: boolean,
  values: Partial<Sales>,
  genres: NonNullable<ParentContext['genres']>,
  releasesScannedDivisor: number,
  tracksStreamedDivisor: number,
  tracksDownloadedDivisor: number,
  isAdmin: boolean
): Context => ({
  releaseId,
  releaseIsRestricted,
  values,
  genres,
  releasesScannedDivisor,
  tracksStreamedDivisor,
  tracksDownloadedDivisor,
  isAdmin,
});

export const machine = createMachine(
  {
    id: 'releaseSalesMachine',
    predictableActionArguments: true,
    tsTypes: {} as import('./ReleaseSalesMachine.typegen').Typegen0,
    schema: {
      context: {} as Context,
      events: {} as Events,
      services: {} as Services,
    },
    type: 'parallel',
    states: {
      ui: {
        initial: 'edit',
        states: {
          edit: {
            on: {
              CANCEL_EDITING: {
                actions: 'cancelEdit',
              },
              UPDATE_GENRE: {
                actions: 'updateGenre',
              },
              BLUR_GENRE: [
                {
                  cond: 'isEmpty',
                  target: '#releaseSalesMachine.genre.error.empty',
                },
                {
                  target: '#releaseSalesMachine.genre.noError',
                },
              ],
              UPDATE_RELEASES_SCANNED: {
                actions: 'updateReleasesScanned',
              },
              BLUR_RELEASES_SCANNED: [
                {
                  cond: 'isEmpty',
                  target: '#releaseSalesMachine.releasesScanned.error.empty',
                },
                {
                  cond: 'isNotNumeric',
                  target:
                    '#releaseSalesMachine.releasesScanned.error.incorrectType',
                },
                {
                  target: '#releaseSalesMachine.releasesScanned.noError',
                },
              ],
              UPDATE_TRACKS_DOWNLOADED: {
                actions: 'updateTracksDownloaded',
              },
              BLUR_TRACKS_DOWNLOADED: [
                {
                  cond: 'isEmpty',
                  target: '#releaseSalesMachine.tracksDownloaded.error.empty',
                },
                {
                  cond: 'isNotNumeric',
                  target:
                    '#releaseSalesMachine.tracksDownloaded.error.incorrectType',
                },
                {
                  target: '#releaseSalesMachine.tracksDownloaded.noError',
                },
              ],
              UPDATE_TRACKS_STREAMED: {
                actions: 'updateTracksStreamed',
              },
              BLUR_TRACKS_STREAMED: [
                {
                  cond: 'isEmpty',
                  target: '#releaseSalesMachine.tracksStreamed.error.empty',
                },
                {
                  cond: 'isNotNumeric',
                  target:
                    '#releaseSalesMachine.tracksStreamed.error.incorrectType',
                },
                {
                  target: '#releaseSalesMachine.tracksStreamed.noError',
                },
              ],
              ATTEMPT_SAVE: [
                {
                  cond: 'isFormValid',
                  target: 'saving',
                },
                {
                  target:
                    '#releaseSalesMachine.wholeForm.error.invalid.highlight',
                },
              ],
            },
          },
          saving: {
            invoke: {
              src: 'updateReleaseSales',
              onDone: [
                {
                  cond: 'isSuccess',
                  actions: ['updateParentCachedRelease', 'cancelEdit'],
                },
                {
                  target: [
                    '#releaseSalesMachine.wholeForm.error.internal',
                    'edit',
                  ],
                },
              ],
              onError: [
                {
                  target: [
                    '#releaseSalesMachine.wholeForm.error.network',
                    'edit',
                  ],
                },
              ],
            },
          },
        },
      },
      genre: {
        initial: 'noError',
        states: {
          noError: {},
          error: {
            initial: 'empty',
            states: {
              empty: {},
            },
          },
        },
      },
      releasesScanned: {
        initial: 'noError',
        states: {
          noError: {},
          error: {
            initial: 'empty',
            states: {
              empty: {},
              incorrectType: {},
            },
          },
        },
      },
      tracksDownloaded: {
        initial: 'noError',
        states: {
          noError: {},
          error: {
            initial: 'empty',
            states: {
              empty: {},
              incorrectType: {},
            },
          },
        },
      },
      tracksStreamed: {
        initial: 'noError',
        states: {
          noError: {},
          error: {
            initial: 'empty',
            states: {
              empty: {},
              incorrectType: {},
            },
          },
        },
      },
      totalUnits: {
        initial: 'noError',
        states: {
          noError: {},
          error: {
            initial: 'empty',
            states: {
              empty: {},
              incorrectType: {},
            },
          },
        },
      },
      wholeForm: {
        initial: 'noError',
        states: {
          noError: {},
          error: {
            initial: 'invalid',
            states: {
              invalid: {
                initial: 'default',
                states: {
                  default: {},
                  highlight: {
                    after: {
                      1000: { target: 'default' },
                    },
                  },
                },
              },
              network: {},
              internal: { entry: 'logMachineError' },
            },
          },
        },
      },
    },
  },
  {
    guards: {
      isFormValid: (_, __, { state }) => {
        return [
          'genre',
          'releasesScanned',
          'tracksDownloaded',
          'tracksStreamed',
          'totalUnits',
        ].every((field) => state.matches(`${field}.noError`));
      },
      isEmpty: ({ values }, { type }) =>
        match(type)
          .with('BLUR_GENRE', () => Boolean(values?.genreId === ''))
          .with('BLUR_RELEASES_SCANNED', () =>
            Boolean(values?.releasesScanned === '')
          )
          .with('BLUR_TRACKS_DOWNLOADED', () =>
            Boolean(values?.tracksDownloaded === '')
          )
          .with('BLUR_TRACKS_STREAMED', () =>
            Boolean(values?.tracksStreamed === '')
          )
          .otherwise(() => false),
      isNotNumeric: ({ values }, { type }) =>
        isNaN(
          parsePositiveInteger(
            match(type)
              .with('BLUR_RELEASES_SCANNED', () => values.releasesScanned ?? '')
              .with(
                'BLUR_TRACKS_DOWNLOADED',
                () => values.tracksDownloaded ?? ''
              )
              .with('BLUR_TRACKS_STREAMED', () => values.tracksStreamed ?? '')
              .exhaustive()
              .trim()
          )
        ),
      isSuccess: (_, { data }) => isSuccess(data),
    },
    actions: {
      logMachineError,
      updateGenre: assign((context, event) => ({
        values: {
          ...context.values,
          genreId: event.genreId,
        },
      })),
      updateReleasesScanned: assign((context, event) => ({
        values: {
          ...context.values,
          releasesScanned: event.releasesScanned,
        },
      })),
      updateTracksDownloaded: assign((context, event) => ({
        values: {
          ...context.values,
          tracksDownloaded: event.tracksDownloaded,
        },
      })),
      updateTracksStreamed: assign((context, event) => ({
        values: {
          ...context.values,
          tracksStreamed: event.tracksStreamed,
        },
      })),
      updateParentCachedRelease: sendParent((_, { data }) =>
        updateCachedRelease({ release: data.result?.query?.release })
      ),
      cancelEdit: sendParent(() => cancelEditSales()),
    },
  }
);

type Machine = typeof machine;

export type ReleaseSalesMachineState = StateFrom<Machine>;
export type ReleaseSalesMachineSender = Sender<EventFrom<Machine>>;
export type ReleaseSalesMachineOptions = MachineOptionsFrom<Machine, true> & {
  context: Context;
};
export type ReleaseSalesMachineEvent = EventFrom<Machine>;
export type ReleaseSalesMachineActor = ActorRefFrom<Machine>;
