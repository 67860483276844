import { useActor } from '@xstate/react';
import { ContactsBlockMachineActor } from '../../machines/components/ContactsBlockMachine';
import { ContactsBlockView } from './ContactsBlockView';

export function ContactsBlockController({
  actor,
}: {
  actor: ContactsBlockMachineActor;
}) {
  const [state, send] = useActor(actor);

  return <ContactsBlockView state={state} send={send} />;
}
