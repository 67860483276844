import { useMachine } from '@xstate/react';
import { AddReleaseView } from './AddReleaseView';
import {
  machine,
  AddReleaseMachineOptions,
} from '../../machines/AddRelease/Machine';

type AddReleaseControllerProps = {
  options: AddReleaseMachineOptions;
  artistId: string;
};

export function AddReleaseController({
  options,
  artistId,
}: AddReleaseControllerProps) {
  const [state] = useMachine(machine, options);

  return <AddReleaseView state={state} artistId={artistId} />;
}
