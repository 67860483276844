import classNames from 'classnames';
import { formatNumber } from '../utils/formatNumber';
import styles from './RangeSlider.module.scss';

type Range = {
  min: number;
  max: number;
};

interface RangeSliderProps {
  value: number;
  range?: Range;
  error?: boolean;
}

const calculateLeft = (value: number, { min, max }: Range) => {
  // https://css-tricks.com/value-bubbles-for-range-inputs/
  // this bar is split up into 3 pieces of 9%, 81.5%, 9%
  let percent;
  if (value > min + max) {
    percent = 100;
  } else if (value <= min) {
    percent = 9 * (value / min);
  } else if (value >= max) {
    const edgePercentage = (value - max) / min;
    percent = 91 + 9 * edgePercentage;
  } else {
    const edgePercentage = (value - min) / (max - min);
    percent = 9 + 81.5 * edgePercentage;
  }
  return `calc(${percent}%)`;
};

export function RangeSlider({ value, range, error }: RangeSliderProps) {
  return (
    <div className={styles.container}>
      <div className={styles.rangeSlider}>
        <div>
          <div className={styles.range}></div>
        </div>
        <div className={styles.numbers}>
          <div className={styles.numbersLabel}>
            {range !== undefined && formatNumber(range.min)} min
          </div>
          {range !== undefined && (
            <div
              className={styles.tipContainer}
              style={{ left: calculateLeft(value, range) }}
            >
              <div
                className={classNames(styles.tip, {
                  [styles.error]: error,
                })}
              >
                {formatNumber(value)}
              </div>
              <div
                className={classNames(styles.valueMarker, {
                  [styles.error]: error,
                })}
              />
            </div>
          )}
          <div className={styles.numbersLabel}>
            {range !== undefined && formatNumber(range.max)} max
          </div>
        </div>
      </div>
    </div>
  );
}
