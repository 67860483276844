import { useGraphQLClient } from '../../components/GraphQLClientProvider';
import { HomeController } from './HomeController';
import { createGetHomeContextPromise } from '../../promises/getHomeContextPromise';
import { withDelay } from '../../utils/withDelay';
import { useAuth } from '../../components/AuthProvider';

export const Home = () => {
  const client = useGraphQLClient();
  const { isSignedIn } = useAuth();

  return (
    <HomeController
      isSignedIn={isSignedIn}
      options={{
        context: {
          rounds: [],
        },
        services: {
          getHomeContextPromise: withDelay(createGetHomeContextPromise(client)),
        },
      }}
    />
  );
};
