import { GraphQLClient } from 'graphql-request';
import { CreateFundingRequestDocument } from '../../graphql/operations';
import { AddFundingRequest } from '../../events/AddFundingRequest';
import { prepareFundingRequest } from './prepareFundingRequest';
import { upsertFundingRequestResultSchema } from '../../schemas/fundingRequests/upsertFundingRequestResultSchema';

export const createAddFundingRequestPromise =
  (client: GraphQLClient) =>
  async (_: unknown, { request }: AddFundingRequest) => {
    const response = await client.request(CreateFundingRequestDocument, {
      request: prepareFundingRequest(request),
    });

    return upsertFundingRequestResultSchema.parse(
      response.request?.fundingRequest
    );
  };

export type CreateAddFundingRequestPromise = ReturnType<
  typeof createAddFundingRequestPromise
>;

export type CreateAddFundingRequestPromiseResult = Awaited<
  ReturnType<CreateAddFundingRequestPromise>
>;
