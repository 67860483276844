import classNames from 'classnames';
import { TextInput } from '../../TextInput';
import { PageHeader } from '../../PageHeader';
import {
  TransferMachineState,
  TransferMachineSender,
} from '../../../machines/RedeemArtistInvitation/TransferMachine';
import { FormField } from '../../FormField';
import { Button } from '../../Button';
import { match } from 'ts-pattern';
import { Form } from '../../Form';
import { FormControls } from '../../FormControls';

import styles from './TransferView.module.scss';

interface TransferViewProps {
  state: TransferMachineState;
  send: TransferMachineSender;
}

export function TransferView({ state, send }: TransferViewProps) {
  return (
    <Form
      className={styles.container}
      onSubmit={() => {
        send('TRANSFER');
      }}
    >
      <PageHeader heading="Redeem Artist Invitation">
        {state.context.link ? (
          <p>
            The account belonging to {state.context.email} has been invited to
            be linked to a Starmaker artist. Please confirm the linking below or
            transfer the invitation if you would like to use a different email
            address.
          </p>
        ) : (
          <p>
            A Starmaker artist has invited {state.context.email} to create an
            account and help manage their funding applications. Please proceed
            to create a new account below or transfer the invitation if you
            would like to use a different email address.
          </p>
        )}
      </PageHeader>
      <div className={classNames(styles.formFields, 'max-w-md')}>
        <FormField
          htmlFor="email"
          label="New email address"
          formFieldStatus={state.matches('form.invalid') ? 'error' : 'default'}
          feedbackType="warning"
          infoLabel={match(state)
            .when(
              () => state.matches('form.invalid.type'),
              () => 'This field must be a valid email address.'
            )
            .when(
              () => state.matches('form.invalid.duplicate'),
              () =>
                'This field should not be the same email address as the original invitation.'
            )
            .otherwise(() => '')}
        >
          <TextInput
            id="email"
            label="New email address"
            value={state.context.transferEmail}
            onFocus={() => send('FOCUS_EMAIL')}
            onChange={(value) => send({ type: 'UPDATE_EMAIL', value })}
            onBlur={() => send('BLUR_EMAIL')}
          />
        </FormField>
      </div>

      <FormControls className="max-w-md">
        <Button
          label="Transfer Invitation"
          type="submit"
          disabled={!state.matches('form.valid')}
        />
        <p className={styles.or}>OR</p>
        <Button
          label={state.context.link ? 'Link To Artist' : 'Create Account'}
          onClick={() => send('NEXT')}
        />
      </FormControls>
    </Form>
  );
}
