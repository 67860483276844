import type {
  Sender,
  EventFrom,
  MachineOptionsFrom,
  ActorRefFrom,
} from 'xstate';
import { createMachine, assign, spawn, sendParent, sendTo } from 'xstate';
import { CalendarDate, ZonedDateTime } from '@internationalized/date';
import { StateFrom } from '../../utils/StateFrom';
import {
  machine as TourDateMachine,
  TourDateMachineActor,
  TourDateMachineEvent,
} from '../../machines/components/TourDateMachine';
import { CreateTourDatePromiseResult } from '../../promises/TourDates/createAddTourDatePromise';
import { CreateDeleteTourDatePromiseResult } from '../../promises/TourDates/createDeleteTourDatePromise';
import { CreateUpdateTourDatePromiseResult } from '../../promises/TourDates/createUpdateTourDatePromise';
import { AddTourDate } from '../../events/AddTourDate';
import { UpdateTourDate } from '../../events/UpdateTourDate';
import { UpdateTourDates } from '../../events/UpdateTourDates';
import { DeleteTourDate } from '../../events/DeleteTourDate';
import { CloseTourDate } from '../../events/CloseTourDate';
import {
  EditContext,
  createEditContext,
  refreshTourDateRegions,
} from '../../events/RefreshTourDateRegions';
import {
  TourDate,
  PartialTourDate,
  completeTourDateSchema,
} from '../../schemas/tourDates/tourDateSchema';
import { TourDateType } from '../../schemas/tourDates/tourDateTypeSchema';
import { Country } from '../../schemas/countrySchema';
import { pure } from 'xstate/lib/actions';
import { refreshApplication } from '../../events/RefreshApplication';
import {
  createContext as createCardContext,
  TourDateCardMachineActor,
  machine as TourDateCardMachine,
} from './TourDateCardMachine';
import { OpenTourDate } from '../../events/OpenTourDate';
import { TourDateRegion } from '../../schemas/tourDates/tourDateRegionSchema';
import { logMachineError } from '../../utils/logError';
import { scrollElementIntoView } from '../../utils/scrollElementIntoView';
import { ApplicationStatus } from '../../graphql/operations';
import { CancelTourDate } from '../../events/CancelTourDate';
import { CreateCancelTourDatePromiseResult } from '../../promises/TourDates/createCancelTourDatePromise';

type Events =
  | AddTourDate
  | UpdateTourDate
  | UpdateTourDates
  | DeleteTourDate
  | CloseTourDate
  | OpenTourDate
  | CancelTourDate;

type ExtendedTourDate = {
  tourDate: TourDate;
  open: boolean;
  ref: TourDateMachineActor;
  cardRef: TourDateCardMachineActor;
};

export type Context = {
  tourDates: TourDate[];
  extendedTourDates: ExtendedTourDate[];
  openTourDate: TourDateMachineActor | null;
  applicationId: string;
  tourDateTypes: TourDateType[];
  tourDateRegions: TourDateRegion[];
  year: number;
  presentCutoffDate: CalendarDate;
  futureCutoffDate: CalendarDate | undefined;
  countries: Country[];
  submittedAt: ZonedDateTime | null;
  claimsSubmittedAt: ZonedDateTime | null;
  isAdmin: boolean;
  isBoard: boolean;
  applicationStatus: ApplicationStatus;
  alternateTourDatesAllowed: boolean;
};

type Services = {
  addTourDate: {
    data: CreateTourDatePromiseResult;
  };
  deleteTourDate: {
    data: CreateDeleteTourDatePromiseResult;
  };
  updateTourDate: {
    data: CreateUpdateTourDatePromiseResult;
  };
  cancelTourDate: {
    data: CreateCancelTourDatePromiseResult;
  };
};

export const createContext = (
  applicationId: string,
  tourDates: TourDate[],
  tourDateTypes: TourDateType[],
  tourDateRegions: TourDateRegion[],
  year: number,
  countries: Country[],
  submittedAt: ZonedDateTime | null,
  claimsSubmittedAt: ZonedDateTime | null,
  presentCutoffDate: CalendarDate,
  futureCutoffDate: CalendarDate | undefined,
  isAdmin: boolean,
  isBoard: boolean,
  applicationStatus: ApplicationStatus,
  alternateTourDatesAllowed: boolean
): Context => ({
  tourDates,
  tourDateTypes,
  tourDateRegions,
  year,
  openTourDate: null,
  presentCutoffDate,
  futureCutoffDate,
  applicationId,
  extendedTourDates: [],
  countries,
  submittedAt,
  claimsSubmittedAt,
  isAdmin,
  isBoard,
  applicationStatus,
  alternateTourDatesAllowed,
});

const createReplayEvents = (tourDate: TourDate): TourDateMachineEvent[] => [
  { type: 'FOCUS_TOUR_DATE' },
  { type: 'SET_TOUR_DATE', tourDate: tourDate.date },
  { type: 'SET_CONFIRMED', confirmed: tourDate.confirmed },
  { type: 'FOCUS_AVERAGE_TICKET_PRICE' },
  {
    type: 'SET_AVERAGE_TICKET_PRICE',
    averageTicketPrice: tourDate.averageTicketPrice,
  },
  { type: 'BLUR_AVERAGE_TICKET_PRICE' },
  { type: 'FOCUS_COUNTRY_SUBDIVISION' },
  {
    type: 'SET_COUNTRY_SUBDIVISION',
    countrySubdivision: tourDate.countrySubdivision,
  },
  { type: 'BLUR_COUNTRY_SUBDIVISION' },
  { type: 'FOCUS_VENUE_NAME' },
  { type: 'SET_VENUE_NAME', venueName: tourDate.venueName },
  { type: 'BLUR_VENUE_NAME' },
  { type: 'FOCUS_VENUE_CAPACITY' },
  { type: 'SET_VENUE_CAPACITY', venueCapacity: tourDate.venueCapacity },
  { type: 'BLUR_VENUE_CAPACITY' },
  { type: 'FOCUS_PAYMENT_GUARANTEE' },
  {
    type: 'SET_PAYMENT_GUARANTEE',
    paymentGuarantee: tourDate.paymentGuarantee,
  },
  { type: 'BLUR_PAYMENT_GUARANTEE' },
  { type: 'FOCUS_BOOKING_AGENCY' },
  { type: 'SET_BOOKING_AGENCY', bookingAgency: tourDate.bookingAgency },
  { type: 'BLUR_BOOKING_AGENCY' },
  { type: 'FOCUS_BOOKING_AGENT' },
  { type: 'SET_BOOKING_AGENT', bookingAgent: tourDate.bookingAgent },
  { type: 'BLUR_BOOKING_AGENT' },
  { type: 'FOCUS_PROMOTER' },
  { type: 'SET_PROMOTER', promoter: tourDate.promoter },
  { type: 'BLUR_PROMOTER' },
  ...((tourDate.alternate
    ? [
        { type: 'FOCUS_REASON' },
        { type: 'SET_REASON', reason: tourDate.reason },
        { type: 'BLUR_REASON' },
      ]
    : []) satisfies TourDateMachineEvent[]),
  { type: 'FOCUS_COUNTRY' },
  { type: 'SET_TOUR_COUNTRY', tourDateCountry: tourDate.tourDateCountry },
  { type: 'FOCUS_TOUR_DATE_TYPE' },
  { type: 'SET_TOUR_DATE_TYPE', tourDateType: tourDate.tourDateType },
];

const createRef = (
  editContext: EditContext | undefined,
  tourDate: PartialTourDate,
  tourDateTypes: TourDateType[],
  tourDateRegions: TourDateRegion[],
  year: number,
  countries: Country[],
  presentCutoffDate: CalendarDate,
  futureCutoffDate: CalendarDate | undefined,
  applicationStatus: ApplicationStatus
) =>
  spawn(
    TourDateMachine.withConfig({
      services: {
        validateCompleteTourDate: ({ tourDate }) =>
          completeTourDateSchema.parseAsync(tourDate),
      },
    }).withContext({
      editContext,
      tourDate,
      tourDateTypes,
      tourDateRegions,
      presentCutoffDate,
      futureCutoffDate,
      year,
      countries,
      alternate: applicationStatus === ApplicationStatus.Approved,
    })
  );

const createCardRef = (
  tourDate: TourDate,
  isAdmin: boolean,
  isBoard: boolean,
  presentCutoffDate: CalendarDate,
  futureCutoffDate: CalendarDate | undefined,
  applicationStatus: ApplicationStatus
) =>
  spawn(
    TourDateCardMachine.withContext(
      createCardContext(
        tourDate,
        isAdmin,
        isBoard,
        presentCutoffDate,
        futureCutoffDate,
        applicationStatus
      )
    )
  );

const createNewRequest = (
  applicationId: string,
  applicationStatus: ApplicationStatus,
  tourDateTypes: TourDateType[],
  tourDateRegions: TourDateRegion[],
  year: number,
  countries: Country[],
  presentCutoffDate: CalendarDate,
  futureCutoffDate: CalendarDate | undefined
) =>
  createRef(
    undefined,
    {
      applicationId,
      confirmed: null,
      countrySubdivision: '',
      venueName: '',
      venueCapacity: '',
      averageTicketPrice: '',
      paymentGuarantee: '',
      bookingAgency: '',
      bookingAgent: '',
      promoter: '',
      reason: '',
      date: null,
    },
    tourDateTypes,
    tourDateRegions,
    year,
    countries,
    presentCutoffDate,
    futureCutoffDate,
    applicationStatus
  );

const createExtendedTourDate = (
  tourDate: TourDate,
  tourDateTypes: TourDateType[],
  tourDateRegions: TourDateRegion[],
  year: number,
  countries: Country[],
  isAdmin: boolean,
  isBoard: boolean,
  presentCutoffDate: CalendarDate,
  futureCutoffDate: CalendarDate | undefined,
  applicationStatus: ApplicationStatus
): ExtendedTourDate => {
  return {
    tourDate,
    open: false,
    ref: createRef(
      createEditContext(tourDate),
      {
        applicationId: tourDate.applicationId,
        confirmed: null,
        countrySubdivision: '',
        venueName: '',
        venueCapacity: '',
        averageTicketPrice: '',
        paymentGuarantee: '',
        bookingAgency: '',
        bookingAgent: '',
        promoter: '',
        reason: '',
        date: null,
      },
      tourDateTypes,
      tourDateRegions,
      year,
      countries,
      presentCutoffDate,
      futureCutoffDate,
      applicationStatus
    ),
    cardRef: createCardRef(
      tourDate,
      isAdmin,
      isBoard,
      presentCutoffDate,
      futureCutoffDate,
      applicationStatus
    ),
  };
};

export const machine = createMachine(
  {
    predictableActionArguments: true,
    tsTypes: {} as import('./TourDatesBlockMachine.typegen').Typegen0,
    schema: {
      context: {} as Context,
      events: {} as Events,
      services: {} as Services,
    },
    initial: 'ready',
    id: 'form',
    states: {
      ready: {
        entry: 'createExtendedTourDates',
        initial: 'default',
        states: {
          default: {},
          error: { entry: 'logMachineError' },
          save: {
            invoke: {
              id: 'addTourDate',
              src: 'addTourDate',
              onDone: {
                actions: [
                  'addTourDate',
                  'sendRefreshTourDateRegions',
                  'sendRefreshApplication',
                  'scrollToTourDate',
                ],
                target: 'default',
              },
              onError: 'error',
            },
          },
          delete: {
            invoke: {
              id: 'deleteTourDate',
              src: 'deleteTourDate',
              onDone: {
                actions: [
                  'deleteTourDate',
                  'sendRefreshTourDateRegions',
                  'sendRefreshApplication',
                ],
                target: 'default',
              },
              onError: 'error',
            },
          },
          update: {
            invoke: {
              id: 'updateTourDate',
              src: 'updateTourDate',
              onDone: {
                actions: [
                  'updateTourDate',
                  'sendRefreshTourDateRegions',
                  'sendRefreshApplication',
                  'scrollToTourDate',
                ],
                target: 'default',
              },
              onError: 'error',
            },
          },
          cancel: {
            invoke: {
              id: 'cancelTourDate',
              src: 'cancelTourDate',
              onDone: {
                actions: [
                  'cancelTourDate',
                  'sendRefreshTourDateRegions',
                  'sendRefreshApplication',
                ],
                target: 'default',
              },
              onError: 'error',
            },
          },
        },
        on: {
          ADD_TOUR_DATE: '.save',
          DELETE_TOUR_DATE: '.delete',
          UPDATE_TOUR_DATE: '.update',
          CANCEL_TOUR_DATE: '.cancel',
          UPDATE_TOUR_DATES: {
            actions: ['updateTourDates', 'sendRefreshTourDates'],
          },
          OPEN_TOUR_DATE: { actions: ['resetTourDateForm', 'openTourDate'] },
          CLOSE_TOUR_DATE: {
            actions: ['closeTourDate', 'scrollToTourDate'],
            target: '.default',
          },
        },
      },
    },
  },
  {
    actions: {
      logMachineError,
      addTourDate: assign(
        (
          {
            tourDates,
            extendedTourDates,
            tourDateTypes,
            presentCutoffDate,
            futureCutoffDate,
            countries,
            isAdmin,
            isBoard,
            applicationStatus,
          },
          {
            data: {
              application: { tourDateRegions, year, alternateTourDatesAllowed },
              ...tourDate
            },
          }
        ) => ({
          tourDates: [...tourDates, tourDate].sort(
            ({ date: dateA }, { date: dateB }) => dateA.compare(dateB)
          ),
          extendedTourDates: [
            ...extendedTourDates,
            createExtendedTourDate(
              tourDate,
              tourDateTypes,
              tourDateRegions,
              year,
              countries,
              isAdmin,
              isBoard,
              presentCutoffDate,
              futureCutoffDate,
              applicationStatus
            ),
          ].sort(({ tourDate: a }, { tourDate: b }) => a.date.compare(b.date)),
          tourDateRegions,
          alternateTourDatesAllowed,
          openTourDate: null,
        })
      ),
      deleteTourDate: assign(
        (
          { tourDates, extendedTourDates },
          {
            data: {
              id: idToDelete,
              application: { year, tourDateRegions, alternateTourDatesAllowed },
            },
          }
        ) => ({
          tourDates: tourDates.filter(({ id }) => id !== idToDelete),
          extendedTourDates: extendedTourDates.filter(
            ({ tourDate: { id } }) => id !== idToDelete
          ),
          tourDateRegions,
          year,
          alternateTourDatesAllowed,
        })
      ),
      resetTourDateForm: ({ extendedTourDates }, { id }) => {
        extendedTourDates
          .filter(({ tourDate }) => tourDate.id === id)
          .forEach(({ tourDate, ref }) => {
            createReplayEvents(tourDate).forEach((event) => ref.send(event));
          });
      },
      openTourDate: assign(
        (
          {
            extendedTourDates,
            applicationId,
            applicationStatus,
            tourDateTypes,
            tourDateRegions,
            year,
            presentCutoffDate,
            futureCutoffDate,
            openTourDate,
            countries,
          },
          { id }
        ) => ({
          extendedTourDates: extendedTourDates.map((entry) => ({
            ...entry,
            open: entry.tourDate.id === id ? true : entry.open,
          })),
          openTourDate:
            id === undefined
              ? createNewRequest(
                  applicationId,
                  applicationStatus,
                  tourDateTypes,
                  tourDateRegions,
                  year,
                  countries,
                  presentCutoffDate,
                  futureCutoffDate
                )
              : openTourDate,
        })
      ),
      closeTourDate: assign(({ extendedTourDates, openTourDate }, { id }) => ({
        extendedTourDates: extendedTourDates.map((entry) => ({
          ...entry,
          open: entry.tourDate.id === id ? false : entry.open,
        })),
        openTourDate: id === undefined ? null : openTourDate,
      })),
      updateTourDate: assign(
        (
          context,
          {
            data: {
              application: { year, tourDateRegions, alternateTourDatesAllowed },
              ...data
            },
          }
        ) => ({
          tourDates: context.tourDates.map((tourDate) =>
            tourDate.id === data.id ? data : tourDate
          ),
          extendedTourDates: context.extendedTourDates.map((extendedTourDate) =>
            extendedTourDate.tourDate.id === data.id
              ? {
                  ...extendedTourDate,
                  tourDate: data,
                  open: false,
                  cardRef: createCardRef(
                    data,
                    context.isAdmin,
                    context.isBoard,
                    context.presentCutoffDate,
                    context.futureCutoffDate,
                    context.applicationStatus
                  ),
                }
              : extendedTourDate
          ),
          tourDateRegions,
          year,
          alternateTourDatesAllowed,
        })
      ),
      cancelTourDate: assign(
        (
          context,
          {
            data: {
              tourDate: {
                application: {
                  year,
                  tourDateRegions,
                  alternateTourDatesAllowed,
                },
                ...data
              },
            },
          }
        ) => ({
          tourDates: context.tourDates.map((tourDate) =>
            tourDate.id === data.id ? data : tourDate
          ),
          extendedTourDates: context.extendedTourDates.map((extendedTourDate) =>
            extendedTourDate.tourDate.id === data.id
              ? {
                  ...extendedTourDate,
                  tourDate: data,
                  open: false,
                  cardRef: createCardRef(
                    data,
                    context.isAdmin,
                    context.isBoard,
                    context.presentCutoffDate,
                    context.futureCutoffDate,
                    context.applicationStatus
                  ),
                }
              : extendedTourDate
          ),
          tourDateRegions,
          year,
          alternateTourDatesAllowed,
        })
      ),
      updateTourDates: assign((context, { tourDates }) => ({
        tourDates: context.tourDates.map(
          (tourDate) =>
            tourDates.find(({ id }) => id === tourDate.id) ?? tourDate
        ),
      })),
      createExtendedTourDates: assign(
        ({
          tourDates,
          tourDateTypes,
          tourDateRegions,
          year,
          presentCutoffDate,
          futureCutoffDate,
          countries,
          isAdmin,
          isBoard,
          applicationStatus,
        }) => ({
          extendedTourDates: tourDates.map((tourDate) =>
            createExtendedTourDate(
              tourDate,
              tourDateTypes,
              tourDateRegions,
              year,
              countries,
              isAdmin,
              isBoard,
              presentCutoffDate,
              futureCutoffDate,
              applicationStatus
            )
          ),
        })
      ),
      sendRefreshTourDates: pure(
        (
          {
            extendedTourDates,
            isAdmin,
            presentCutoffDate,
            futureCutoffDate,
            applicationStatus,
          },
          { tourDates }
        ) =>
          extendedTourDates
            .reduce<
              Array<{
                cardRef: TourDateCardMachineActor;
                tourDate: TourDate;
              }>
            >((acc, { cardRef, tourDate: { id: findId } }) => {
              const tourDate = tourDates.find(({ id }) => id === findId);

              return tourDate !== undefined
                ? [...acc, { cardRef, tourDate }]
                : acc;
            }, [])
            .map(({ cardRef, tourDate }) =>
              sendTo(cardRef, {
                type: 'UPDATE_CONTEXT',
                context: {
                  tourDate,
                  isAdmin,
                  presentCutoffDate,
                  futureCutoffDate,
                  applicationStatus,
                },
              })
            )
      ),
      sendRefreshTourDateRegions: pure(
        ({ extendedTourDates, tourDateRegions, year, openTourDate }) =>
          extendedTourDates
            .map(
              ({
                ref,
                tourDate,
              }): { ref: TourDateMachineActor; tourDate?: TourDate } => ({
                ref,
                tourDate,
              })
            )
            // Ensure we send to the add machine as well.
            .concat(
              openTourDate !== null
                ? [{ ref: openTourDate, tourDate: undefined }]
                : []
            )
            .map(({ ref, tourDate }) =>
              sendTo(
                ref,
                refreshTourDateRegions(
                  year,
                  tourDateRegions,
                  createEditContext(tourDate)
                )
              )
            )
      ),
      sendRefreshApplication: sendParent(refreshApplication()),
      scrollToTourDate: (_, event) => {
        if (event.type === 'CLOSE_TOUR_DATE') {
          scrollElementIntoView(event.id ?? 'addTourDateButton');
        } else {
          scrollElementIntoView(event.data.id, true);
        }
      },
    },
  }
);

type Machine = typeof machine;

export type TourDatesBlockMachineState = StateFrom<Machine>;
export type TourDatesBlockMachineSender = Sender<EventFrom<Machine>>;
export type TourDatesBlockMachineOptions = MachineOptionsFrom<Machine, true> & {
  context: Context;
};
export type TourDatesBlockMachineEvent = EventFrom<Machine>;
export type TourDatesBlockMachineActor = ActorRefFrom<Machine>;
