import cn from 'classnames';
import { formatNumber } from '../../utils/formatNumber';

import styles from './DashboardTitle.module.scss';

interface DashboardTitleProps {
  artistName: string;
  fundingGiven: number;
  showOrion: boolean;

  orionApprovalCount: number;
  orionApprovalLimit: number;
}

export function DashboardTitle({
  artistName,
  showOrion,
  orionApprovalCount,
  orionApprovalLimit,
}: DashboardTitleProps) {
  return (
    <div>
      <h1 className={styles.heading}>{artistName}</h1>
      {showOrion && (
        <p className={styles.subText}>
          Orion application approvals:{' '}
          <span
            className={cn(styles.bold, {
              [styles.warning]: orionApprovalLimit - orionApprovalCount < 2,
            })}
          >
            {formatNumber(orionApprovalCount)}
          </span>{' '}
          / {formatNumber(orionApprovalLimit)}
        </p>
      )}
    </div>
  );
}
