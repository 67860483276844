import { GraphQLClient } from 'graphql-request';
import {
  GetApplicationLogCountDocument,
  GetApplicationLogsDocument,
} from '../../graphql/operations';
import { Context } from '../../machines/Admin/ApplicationReviewMachine';
import {
  applicationLogSchema,
  transformApplicationLog,
} from '../../schemas/application/applicationLogSchema';

export const createGetApplicationLogsPromise =
  (client: GraphQLClient, applicationId: string) =>
  async (context: Context, { page }: { page: number }) => {
    const { application, timezone } = await client.request(
      GetApplicationLogsDocument,
      {
        applicationId,
        offset: (page - 1) * 10,
      }
    );

    const parsedLogs =
      application && application.logs && timezone
        ? applicationLogSchema
            .array()
            .parse(application.logs)
            .map(transformApplicationLog(timezone))
        : [];

    if (context.logs.pageTotal) {
      return {
        logs: parsedLogs,
        pageTotal: context.logs.pageTotal,
        currentPage: page,
      };
    }

    const { count } = await client.request(GetApplicationLogCountDocument, {
      applicationId,
    });
    return {
      logs: parsedLogs,
      pageTotal: count ? Math.ceil(count / 10) : 0,
      currentPage: page,
    };
  };

export type CreateGetApplicationLogsPromise = ReturnType<
  typeof createGetApplicationLogsPromise
>;

export type CreateGetApplicationLogsPromiseResult = Awaited<
  ReturnType<CreateGetApplicationLogsPromise>
>;
