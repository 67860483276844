export const approvedArtistsByRound = {
  '1': [
    'Bruce Guthro',
    'David Usher',
    'Gob',
    'Jimmy Rankin',
    'Montreal Jubilation Gospel Choir',
    'Rascalz',
    'Ron Sexsmith',
    'Serial Joe',
    'Sloan',
    'Sum 41',
    'Terry Kelly',
    'The Ennis Sisters',
    'Wave',
  ],
  '2': [
    'Alpha Yaya Diallo',
    'Bruce Guthro',
    'Choclair',
    'Grimskunk',
    'Jimmy Rankin',
    'Paul Reddick and the Sidemen',
    'Project Wyze',
    'Quartetto Gelato',
    'Ron Sexsmith',
    'Slainte Mhath',
    'Sum 41',
    'Swollen Members',
    'The Be Good Tanyas',
    'The Lowest of the Low',
  ],
  '3': [
    'Adam Gregory',
    'Bif Naked',
    'Chris Cummings',
    'Fred Penner',
    'Holly McNarland',
    'Molly Johnson',
    'Remy Shand',
    'Robert Michaels',
    'Swollen Members',
    'The Be Good Tanyas',
    'Treble Charger',
  ],
  '4': [
    'Bif Naked',
    'Carlos del Junco',
    'Crush',
    'Daniel Taylor',
    'Hayden',
    'John Landry',
    'Sum 41',
    'Wave',
    'Wide Mouth Mason',
  ],
  '5': [
    'Bet.e and Stef',
    'Canadian Brass',
    'Holly McNarland',
    'Naida Cole',
    'Not By Choice',
    'Remy Shand',
    'Sam Roberts',
    'Shawn Desman',
    'Swollen Members',
    'Theory Of A Deadman',
  ],
  '6': [
    '5440',
    'Angèle Dubeau &amp; La Pietà',
    'Blackie and the Rodeo Kings',
    'Jeszcze Raz',
    'Jorane',
    'Kathleen Edwards',
    'Shawn Desman',
    'Shocore',
    'Sum 41',
    'Swollen Members',
    'Treble Charger',
  ],
  '7': [
    'Bif Naked',
    'Corb Lund Band',
    'Delerium',
    'Denzal Sinclaire',
    'Glamour Puss',
    'Gob',
    'Sam Roberts',
    'Sloan',
    'The Be Good Tanyas',
    'The Paperboys',
    'Theory Of A Deadman',
  ],
  '8': [
    'Bruce Cockburn',
    'Crush',
    'Daniel Taylor',
    'David Usher',
    'Delerium',
    'Doc Walker',
    'Glengarry Bhoys',
    'Hawksley Workman',
    'Holly Cole',
    'Intakto',
    'Jimmy Rankin',
    'Oh Susanna',
    'Sum 41',
  ],
  '9': [
    'Alain Lefèvre',
    'Buck 65',
    'Choclair',
    'Gob',
    'Hawksley Workman',
    'Jimmy Rankin',
    'Kid Koala',
    'Natalie MacMaster',
    'Swollen Members',
  ],
  '10': [
    'Alexisonfire',
    'Fefe Dobson',
    'Florent Vollant',
    'jacksoul',
    'Jane Bunnett',
    'Jason McCoy',
    'Pilate',
    'Quartetto Gelato',
    'Ryan Malcolm',
    'Sarah Harmer',
    'Shaye',
    'Taima',
    'The Bills',
    'Yannick Nézet-Séguin',
  ],
  '11': [
    'Barnard + Brohm',
    'Beverley Mahood',
    'Buck 65',
    'Coral Egan',
    'Francesca Gagnon',
    'Jorane',
    'Lhasa de Sela',
    'Metric',
    'Pavlo',
    'Ron Korb',
    'Suzie LeBlanc',
    'Thornley',
  ],
  '12': [
    'Blackie and the Rodeo Kings',
    'Bob Walsh',
    'Florent Vollant',
    'Hayden',
    'Ivana Santilli',
    'Jane Bunnett',
    'Keshia Chanté',
    'Les Voix Humaines',
    'Luke Doucet',
    'MadViolet',
    'Molly Johnson',
    'Not By Choice',
    'Ron Sexsmith',
    'Sam Roberts',
    'Sarah Slean',
    'Sloan',
    'Sum 41',
    'Susie Arioli Band',
    'The Bills',
    'The Trews',
  ],
  '13': [
    'Angèle Dubeau &amp; La Pietà',
    'Blackie and the Rodeo Kings',
    'Broken Social Scene',
    'Corb Lund Band',
    'Death From Above 1979',
    'Delerium',
    'Ember Swift',
    'Hayden',
    'Ian Tyson',
    'Jakalope',
    'John Gracie',
    'Jonas',
    'Jorane',
    'k-os',
    'Kalan Porter',
    'Kazzer',
    'Les Voix Humaines',
    'Lhasa De Sela',
    'Lucie Idlout',
    'Madviolet',
    'Molly Johnson',
    'Quartetto Gelato',
    'Rez Official',
    'Slangblossom',
    'soulDecision',
    'Stabilo',
    'Sum 41',
    'The Bills',
    'The Waking Eyes',
    'Wayne Lavallee',
  ],
  '14': [
    'Alain Lefèvre',
    'Buck 65',
    'Colin Linden',
    'Danko Jones',
    'Death From Above 1979',
    'Feist',
    'Hayden',
    'John Landry',
    'k-os',
    'Kathleen Edwards',
    'Les Voix Humaines',
    'Lhasa De Sela',
    'Metric',
    'Natalie Choquette',
    'Not By Choice',
    'Pilate',
    'Serena Ryder',
    'Shawn Desman',
    'Sloan',
    'Sophie Milman',
    'Stabilo',
    'Stars',
    'Sweatshop Union',
    'The Bills',
    'The Dope Poet Society',
    'Thornley',
  ],
  '15': [
    'Broken Social Scene',
    'Buck 65',
    'Ember Swift',
    'Hawksley Workman',
    'Joel Plaskett',
    'Madviolet',
    'Martha Wainwright',
    'Massari',
    'Matt Mays',
    'Ron Korb',
    'Samina',
    'Serena Ryder',
    'Sweatshop Union',
    'The Dears',
    'The Dope Poet Society',
    'Theory of a Deadman',
    'Yannick Nézet-Séguin',
  ],
  '16': [
    'Bedouin Soundclash',
    'Broken Social Scene',
    'Buck 65',
    'Corb Lund',
    'Daniel Taylor',
    'Divine Brown',
    'Feist',
    'Joel Plaskett',
    'Jully Black',
    'Luke Doucet',
    'Metric',
    'Moka Only',
    'Pavlo',
    'Robert Michaels',
    'Stars',
    'The New Pornographers',
    'The Road Hammers',
    'The Salads',
    'The Trews',
  ],
  '17': [
    'Angel Forest',
    'Bedouin Soundclash',
    'Broken Social Scene',
    'City and Colour',
    'Cuff the Duke',
    'Hedley',
    'John M. Landry',
    'Johnny Reid',
    'K’naan',
    'Kardinal Offishall',
    'Les Voix Humaines',
    'Luke Doucet',
    'Martha Wainwright',
    'Massari',
    'Melissa O’Neil',
    'Metric',
    'Michel Cusson',
    'Sarah Harmer',
    'The New Pornographers',
    'Theory Of A Deadman',
    'Waking Eyes',
  ],
  '18': [
    'Alain Lefèvre',
    'Amanda Wilkinson',
    'Corb Lund',
    'Danko Jones',
    'Jeff Healey &amp; The Jazz Wizards',
    'Joel Plaskett',
    'Jonas',
    'Matt Mays &amp; El Torpedo',
    'Mentake',
    'Metric',
    'Pilate',
    'Rex Goudie',
    'Sarah Harmer',
    'Sloan',
    'Sophie Milman',
    'Stabilo',
    'The New Pornographers',
    'The Salads',
    'The Susie Arioli Band',
  ],
  '19': [
    'Adam Gregory',
    'Alexisonfire',
    'Buck 65',
    'Carl Henry',
    'Corb Lund',
    'Hedley',
    'Islands',
    'John Landry',
    'Martha Wainwright',
    'Mobile',
    'Roxanne Potvin',
    'Sam Roberts',
    'Sophie Milman',
    'Stars',
    'The Dears',
  ],
  '20': [
    'Alain Lefèvre',
    'Alexisonfire',
    'Amy Millan',
    'Canadian Brass',
    'Carl Henry',
    'Carolyn Dawn Johnson',
    'Classified',
    'Corb Lund',
    'Elliott Brood',
    'Ember Swift',
    'Emily Haines',
    'Florence K.',
    'George Canyon',
    'Islands',
    'Joel Plaskett',
    'Karl Wolf',
    'K’naan',
    'Le Violons Du Roy',
    'Mobile',
    'Mstrkrft',
    'Neverending White Lights',
    'Pavlo',
    'Protest The Hero',
    'Ron Sexsmith',
    'Sophie Milman',
    'Stabilo',
    'Terez Montcalm',
    'The Be Good Tanyas',
    'The Dears',
    'The Sadies',
  ],
  '21': [
    'Aaron Pritchett',
    'Alain Lefèvre',
    'Amy Millan',
    'Cadence Weapon',
    'Champion',
    'City and Colour',
    'Doc Walker',
    'Elliott Brood',
    'Emily Haines',
    'Eva Avila',
    'Final Fantasy',
    'Hedley',
    'Jonas',
    'Karina Gauvin',
    'Keshia Chante',
    'Le Violins du Roy',
    'Matt Mays &amp; El Torpedo',
    'Metric',
    'Molly Johnson',
    'Patrick Watson',
    'Protest The Hero',
    'Sophie Milman',
    'Suzie LeBlanc',
    'Terez Montcalm',
    'The Be Good Tanyas',
    'The Dears',
    'The Hylozoists',
    'The New Pornographers',
    'The Sadies',
    'The Wailin’ Jennys',
  ],
  '22': [
    'Aaron Lines',
    'Amon Tobin',
    'Angèle Dubeau',
    'Arcade Fire',
    'Bedouin Soundclash',
    'Corb Lund',
    'Elliott Brood',
    'Feist',
    'Final Fantasy',
    'George',
    'Jeremy Fisher',
    'Jim Cuddy',
    'Jimmy Rankin',
    'Joel Plaskett Emergency',
    'Johnny Reid',
    'Jonas',
    'Justin Rutledge',
    'K’naan',
    'Kid Koala',
    'Les Voix Humaines',
    'Matt Mays &amp; El Torpedo',
    'Mobile',
    'MSTRKRFT',
    'Patrick Watson',
    'Stephan Moccio',
    'Terez Montcalm',
    'The Dears',
    'The Hylozoists',
    'The Sadies',
    'Wintersleep',
  ],
  '23': [
    'Aaron Pritchett',
    'Alexisonfire',
    'Bedouin Soundclash',
    'Belly',
    'David Gogo',
    'Emily Haines',
    'Feist',
    'Holly McNarland',
    'Jeremy Fisher',
    'Jesse Cook',
    'Jill Barber',
    'Johnny Reid',
    'K’naan',
    'Les Voix Humaines',
    'Marianas Trench',
    'Matt Mays &amp; El Torpedo',
    'Pascale Picard',
    'Patrick Watson',
    'Priestess',
    'Serena Ryder',
    'Shout Out Out Out Out',
    'Sophie Milman',
    'Stephan Moccio',
    'Ten Second Epic',
    'The Be Good Tanyas',
    'The New Pornographers',
    'Wintersleep',
  ],
  '24': [
    'Aaron Lines',
    'Angèle Dubeau &amp; La Pietà',
    'Bedouin Soundclash',
    'Belly',
    'Champion',
    'Corb Lund',
    'Dee',
    'Doc Walker',
    'Faber Drive',
    'Feist',
    'Final Fantasy',
    'George Canyon',
    'Gob',
    'illScarlett',
    'Joel Plaskett Emergency',
    'Johnny Reid',
    'Justin Rutledge',
    'Les Voix Humaines',
    'Luke Doucet',
    'Molly Johnson',
    'Neverending White Lights',
    'Patrick Watson',
    'Shout Out Out Out Out',
    'Social Code',
    'Stars',
    'Tegan and Sara',
    'The New Pornographers',
    'The Sadies',
    'The Salads',
    'The Trews',
    'Wintersleep',
    'Yannick Nézet-Séguin',
  ],
  '25': [
    'Alexisonfire',
    'Angèle Dubeau &amp; La Pietà',
    'Bedouin Soundclash',
    'Brian Melo',
    'Buck 65',
    'Champion',
    'City and Colour',
    'Classified',
    'Corb Lund',
    'Corneille',
    'Cuff The Duke',
    'David Gogo',
    'Faber Drive',
    'George Canyon',
    'Hawksley Workman',
    'Hedley',
    'Jessie Farrell',
    'Jimmy Rankin',
    'Johnny Reid',
    'Jully Black',
    'Kevin Drew',
    'Le Violons du Roy',
    'Luke Doucet',
    'Patrick Watson',
    'Quartetto Gelato',
    'Robert Michaels',
    'Ron Sexsmith',
    'Serena Ryder',
    'Sophie Milman',
    'State of Shock',
    'Syvain Cossette',
    'The New Pornographers',
    'The Sadies',
    'Wintersleep',
  ],
  '26': [
    'Aaron Lines',
    'Alain Lefèvre',
    'Alexisonfire',
    'Bedouin Soundclash',
    'Brian Melo',
    'Broken Social Scene',
    'Buck 65',
    'Chromeo',
    'City and Colour',
    'Corb Lund',
    'Elliott Brood',
    'Feist',
    'Hawksley Workman',
    'Hayden',
    'Hayley Sales',
    'Jesse Cook',
    'Jessie Farrell',
    'Jully Black',
    'Justin Rutledge',
    'Kathleen Edwards',
    'Luke Doucet',
    'Matthew Good',
    'Michael Kaeshammer',
    'Pascale Picard',
    'Patrick Watson',
    'Protest The Hero',
    'Raine Maida',
    'Shout Out Out Out Out',
    'Social Code',
    'Stars',
    'State of Shock',
    'Suzie McNeil',
    'Sylvain Cossette',
    'Tegan &amp; Sara',
    'The Sadies',
    'The Stills',
    'The Trews',
    'The Wailin’ Jennys',
    'Theory of a Deadman',
    'Wintersleep',
  ],
  '27': [
    'Bedouin Soundclash',
    'Buck 65',
    'Champion',
    'City and Colour',
    'Crystal Castles',
    'David Gogo',
    'DK Ibomeka',
    'Doc Walker',
    'Faber Drive',
    'God Made Me Funky',
    'Great Lake Swimmers',
    'Hayley Sales',
    'illScarlett',
    'Jesse Cook',
    'Jully Black',
    'Kathleen Edwards',
    'Les Voix Humaines',
    'Luke Doucet',
    'Martha Wainwright',
    'Matt Mays &amp; El Torpedo',
    'Matthew Good',
    'Patrick Watson',
    'Priestess',
    'Sarah Slean',
    'Serena Ryder',
    'Sophie Milman',
    'Stars',
    'Suzie McNeil',
    'Sweatshop Union',
    'The Lost Fingers',
    'The New Pornographers',
    'The Sadies',
    'The Stills',
    'The Trews',
    'Tokyo Police Club',
    'Wintersleep',
  ],
  '28': [
    'Aaron Pritchett',
    'Alain Lefèvre',
    'Angèle Dubeau &amp; La Pietà',
    'Attack In Black',
    'Basia Bulat',
    'Bedouin Soundclash',
    'Cancer Bats',
    'Catherine MacLellan',
    'Chromeo',
    'Corb Lund',
    'Crystal Castles',
    'Divine Brown',
    'Doc Walker',
    'Elliott Brood',
    'Final Fantasy',
    'God Made Me Funky',
    'Hawksley Workman',
    'Hayden',
    'Jaydee Bixby',
    'Jesse Cook',
    'Jessie Farrell',
    'Justin Rutledge',
    'Kreesha Turner',
    'Luke Doucet',
    'Madviolet',
    'Melissa McClelland',
    'Mobile',
    'Ndidi Onukwulu',
    'Oh Susanna',
    'Protest The Hero',
    'Ron Sexsmith',
    'Serena Ryder',
    'Shad',
    'Shout Out Out Out Out',
    'State of Shock',
    'Tanya Tagaq',
    'Ten Second Epic',
    'The Dears',
    'The Lost Fingers',
    'The New Pornographers',
    'The Sadies',
    'The Stills',
    'The Trews',
    'Tokyo Police Club',
    'Wintersleep',
    'Yannick Nézet-Séguin',
  ],
  '29': [
    'Aaron Pritchett',
    'Angèle Dubeau &amp; La Pietà',
    'Attack In Black',
    'Bedouin Soundclash',
    'Cancer Bats',
    'Classified',
    'Comeback Kid',
    'Corb Lund',
    'Danny Fernandes',
    'Divine Brown',
    'Doc Walker',
    'Eva Avila',
    'Final Fantasy',
    'George Canyon',
    'Hawksley Workman',
    'Hayley Sales',
    'illScarlett',
    'Justin Rutledge',
    'Karina Gauvin',
    'Kathleen Edwards',
    'K’naan',
    'Les Voix Humaines',
    'Lucie Idlout',
    'Luke Doucet',
    'Madison Violet',
    'Martha Wainwright',
    'Matt Mays &amp; El Torpedo',
    'Molly Johnson',
    'Ndidi Onukwulu',
    'Pascale Picard',
    'Priestess',
    'Propagandhi',
    'Shad',
    'State of Shock',
    'Tanya Tagaq',
    'Tara Oram',
    'Ten Second Epic',
    'The Dears',
    'The Lost Fingers',
    'The Midway State',
    'Thornley',
    'Tokyo Police Club',
    'Wintersleep',
  ],
  '30': [
    'Alexisonfire',
    'Attack In Black',
    'Basia Bulat',
    'Beast',
    'Bell Orchestre',
    'Cancer Bats',
    'Catherine MacLellan',
    'Classified',
    'Comeback Kid',
    'Constantines',
    'Corb Lund',
    'Cuff The Duke',
    'Divine Brown',
    'Dragonette',
    'Elliott Brood',
    'Florence K.',
    'Great Lake Swimmers',
    'Ian Kelly',
    'Jaydee Bixby',
    'Jesse Cook',
    'Jessie Farrell',
    'Jill Barber',
    'Johnny Reid',
    'Kathleen Edwards',
    'K’naan',
    'Kreesha Turner',
    'Les Voix Humaines',
    'Lucie Idlout',
    'Luke Doucet',
    'Madison Violet',
    'Marianas Trench',
    'Matt Mays &amp; El Torpedo',
    'Matthew Barber',
    'Melissa McClelland',
    'Metric',
    'Mobile',
    'Moneen',
    'Ndidi Onukwulu',
    'Nikki Yanofsky',
    'Oh Susanna',
    'Patrick Watson',
    'Plants and Animals',
    'Propagandhi',
    'Protest The Hero',
    'Quartetto Gelato',
    'Serena Ryder',
    'Shad',
    'Shout Out Out Out Out',
    'State of Shock',
    'Tanya Tagaq',
    'Ten Second Epic',
    'The Canadian Tenors',
    'The Lost Fingers',
    'The Midway State',
    'The Sadies',
    'The Stills',
    'The Trews',
    'Thornley',
    'Tokyo Police Club',
    'Wintersleep',
  ],
  '31': [
    'Alexisonfire',
    'Angèle Dubeau &amp; La Pietà',
    'Basia Bulat',
    'Beast',
    'Bedouin Soundclash',
    'Bell Orchestre',
    'Cancer Bats',
    'Catherine MacLellan',
    'Chromeo',
    'Classified',
    'Codie Prevost',
    'Comeback Kid',
    'Constantines',
    'Danny Fernandes',
    'Doc Walker',
    'Elliott Brood',
    'Eva Avila',
    'Final Fantasy',
    'George Canyon',
    'Gord Bamford',
    'Hey Rosetta!',
    'illScarlett',
    'Jenn Grant',
    'Jesse Cook',
    'Joel Plaskett',
    'Justin Nozuka',
    'Lhasa',
    'Luke Doucet',
    'Marianas Trench',
    'Melissa McClelland',
    'Metric',
    'Michael Kaeshammer',
    'Moneen',
    'Patrick Watson',
    'Plants and Animals',
    'Propagandhi',
    'Sarah Slean',
    'Serena Ryder',
    'Shout Out Out Out Out',
    'Sophie Milman',
    'Sylvain Cossette',
    'Ten Second Epic',
    'The Sadies',
    'The Stills',
  ],
  '32': [
    'Alain Lefèvre',
    'Alex Cuba',
    'Alexisonfire',
    'Amanda Martinez',
    'Amy Millan',
    'Arkells',
    'Beast',
    'Bell Orchestre',
    'Champion',
    'Comeback Kid',
    'Default',
    'Doc Walker',
    'Dragonette',
    'Elliott Brood',
    'Final Fantasy',
    'George Canyon',
    'Great Lake Swimmers',
    'Hawksley Workman',
    'Hayley Sales',
    'Hey Rosetta!',
    'Ian Kelly',
    'illScarlett',
    'James Keelaghan',
    'Jenn Grant',
    'Jesse Cook',
    'Jessie Farrell',
    'Jill Barber',
    'Jim Byrnes',
    'Jully Black',
    'Justin Rutledge',
    'K’naan',
    'Luke Doucet',
    'Marianas Trench',
    'Massari',
    'Matthew Good',
    'Melissa McClelland',
    'Metric',
    'Misstress Barbara',
    'Michael Kaeshammer',
    'Moneen',
    'Ndidi Onukwulu',
    'Patrick Watson',
    'Plants and Animals',
    'Priestess',
    'Propagandhi',
    'Protest The Hero',
    'Shout Out Out Out Out',
    'Social Code',
    'Sophie Milman',
    'The Canadian Tenors',
    'The Lost Fingers',
    'The New Cities',
    'The Stills',
    'Tokyo Police Club',
  ],
  '33': [
    'Alain Lefèvre',
    'Alexisonfire',
    'Amanda Martinez',
    'Angèle Dubeau &amp; La Pietà',
    'Basia Bulat',
    'Beast',
    'Cancer Bats',
    'Catherine MacLellan',
    'Champion',
    'City and Colour',
    'Corb Lund',
    'Cuff The Duke',
    'Default',
    'Doc Walker',
    'Dragonette',
    'Elliott Brood',
    'Faber Drive',
    'Gord Bamford',
    'Great Lake Swimmers',
    'Hawksley Workman',
    'Hey Rosetta!',
    'Ian Kelly',
    'illScarlett',
    'Jaydee Bixby',
    'Jenn Grant',
    'Jesse Cook',
    'Jessie Farrell',
    'Johnny Reid',
    'Jully Black',
    'Lights',
    'Lynn Miles',
    'Matt Dusk',
    'Matthew Good',
    'Metric',
    'Moneen',
    'Nadja',
    'Patrick Watson',
    'Plants and Animals',
    'Propagandhi',
    'Protest The Hero',
    'Sarah Harmer',
    'Shiloh',
    'Shout Out Out Out Out',
    'Social Code',
    'Sophie Milman',
    'State of Shock',
    'Stereos',
    'Sultans of String',
    'Tara Oram',
    'Ten Second Epic',
    'The Midway State',
    'The New Cities',
    'The Sadies',
    'The Wailin’ Jennys',
  ],
  '34': [
    'Aaron Lines',
    'Alex J. Robinson',
    'Alex Cuba',
    'Amanda Martinez',
    'Angèle Dubeau &amp; La Pietà',
    'Arkells',
    'Attack In Black',
    'Bahamas',
    'Beast',
    'Broken Social Scene',
    'Cancer Bats',
    'Champion',
    'Comeback Kid',
    'Crash Karma',
    'Cuff The Duke',
    'Dan Mangan',
    'Doc Walker',
    'Down With Webster',
    'Elisapie Isaac',
    'Faber Drive',
    'God Made Me Funky',
    'Hawksley Workman',
    'Hey Rosetta!',
    'Ian Kelly',
    'James Keelaghan',
    'Jaydee Bixby',
    'Jesse Cook',
    'Jully Black',
    'Justin Rutledge',
    'K’naan',
    'Lights',
    'Luke Doucet',
    'Madison Violet',
    'Matthew Good',
    'Melissa McClelland',
    'Moneen',
    'Mother Mother',
    'Nadja',
    'One More Girl',
    'Patrick Watson',
    'Plants and Animals',
    'Priestess',
    'Protest The Hero',
    'Sean McCann',
    'Serena Ryder',
    'Shad',
    'Shane Yellowbird',
    'Shout Out Out Out Out',
    'Stereos',
    'Ten Second Epic',
    'The Canadian Tenors',
    'The Higgins',
    'The New Pornographers',
    'The Wailin’ Jennys',
    'The Wooden Sky',
  ],
  '35': [
    'Aaron Lines',
    'Alex Cuba',
    'Alex J. Robinson',
    'Alexisonfire',
    'Amanda Martinez',
    'Amelia Curran',
    'Basia Bulat',
    'Beast',
    'Bobby Bazini',
    'Broken Social Scene',
    'Cancer Bats',
    'Catherine MacLellan',
    'Chromeo',
    'Corb Lund',
    'Crash Karma',
    'Cuff The Duke',
    'Dan Mangan',
    'Danny Fernandes',
    'Elisapie Isaac',
    'Ensemble Caprice',
    'Gord Bamford',
    'Hawksley Workman',
    'Hollerado',
    'Ian Kelly',
    'illScarlett',
    'Jo Hikk',
    'Joel Plaskett',
    'Jonas',
    'Jully Black',
    'Justin Nozuka',
    'Justin Rutledge',
    'Kathleen Edwards',
    'Lights',
    'Luke Doucet',
    'Marc Hervieux',
    'Misstress Barbara',
    'Moneen',
    'Nadja',
    'Ohbijou',
    'One More Girl',
    'Patrick Watson',
    'Plants and Animals',
    'Ridley Bent',
    'Sarah Harmer',
    'Shad',
    'Shout Out Out Out Out',
    'Stars',
    'Stereos',
    'Susie Arioli',
    'The Higgins',
    'The John Henrys',
    'The New Pornographers',
    'The Sadies',
    'The Stills',
    'The Wooden Sky',
    'Timber Timbre',
    'Tokyo Police Club',
    'Wintersleep',
  ],
  '36': [
    'Alex Cuba',
    'Amanda Martinez',
    'Amelia Curran',
    'Angèle Dubeau &amp; La Pietà',
    'Arkells',
    'Beast',
    'Bedouin Soundclash',
    'Bobby Bazini',
    'Broken Social Scene',
    'Buck 65',
    'Catherine MacLellan',
    'Chromeo',
    'Comeback Kid',
    'Dan Mangan',
    'Danko Jones',
    'Danny Fernandes',
    'Dean Brody',
    'Down With Webster',
    'Dragonette',
    'Elisapie Isaac',
    'Elise Estrada',
    'Elliott Brood',
    'Good Lovelies',
    'Gord Downie &amp; The Country of Miracles',
    'Hawksley Workman',
    'Hedley',
    'Hey Rosetta!',
    'Hollerado',
    'Ian Kelly',
    'Jenn Grant',
    'Jesse Cook',
    'Jim Byrnes',
    'Joel Plaskett',
    'Johnny Reid',
    'Jonas',
    'Jully Black',
    'Justin Nozuka',
    'Justin Rutledge',
    'K’naan',
    'Lucky Uke',
    'Luke Doucet',
    'Madison Violet',
    'Melissa McClelland',
    'Metric',
    'Nikki Yanofsky',
    'Ohbijou',
    'Plants and Animals',
    'Sarah Harmer',
    'Serena Ryder',
    'Shad',
    'Shawn Desman',
    'Shout Out Out Out Out',
    'Stars',
    'Ten Second Epic',
    'The Midway State',
    'The New Pornographers',
    'The Trews',
    'The Wailin’ Jennys',
    'The Wooden Sky',
    'Timber Timbre',
    'Tokyo Police Club',
    'Wintersleep',
  ],
  '37': [
    'Alain Lefèvre',
    'Alex Cuba',
    'Angèle Dubeau &amp; La Pietà',
    'Bahamas',
    'Bobby Bazini',
    'Bran Van 3000',
    'Brian Melo',
    'Crystal Castles',
    'Cuff The Duke',
    'Danko Jones',
    'Dan Mangan',
    'Danny Fernandes',
    'Doc Walker',
    'Down With Webster',
    'Elliott Brood',
    'Good Lovelies',
    'Gord Bamford',
    'Gord Downie &amp; The Country of Miracles',
    'Hayley Sales',
    'Hey Rosetta!',
    'Hollerado',
    'Ian Kelly',
    'illScarlett',
    'Jenn Grant',
    'Johnny Reid',
    'Jonas',
    'JRDN',
    'Lights',
    'Lynn Miles',
    'Marianas Trench',
    'Marie-Josée Lord',
    'My Darkest Days',
    'Nikki Yanofsky',
    'Peter Katz',
    'Priestess',
    'Protest The Hero',
    'Ron Korb',
    'Shad',
    'Stereos',
    'Steve Bell',
    'Sylvain Cosette',
    'The John Henrys',
    'The New Pornographers',
    'The Sadies',
    'The Wailin’ Jennys',
    'The Wooden Sky',
    'Timber Timbre',
    'Tokyo Police Club',
    'Wintersleep',
  ],
  '38': [
    'Aaron Pritchett',
    'Abandon All Ships',
    'Alex Cuba',
    'Amelia Curran',
    'Angèle Dubeau &amp; La Pietà',
    'Arkells',
    'Basia Bulat',
    'Broken Social Scene',
    'Buck 65',
    'Cancer Bats',
    'Catherine MacLellan',
    'City and Colour',
    'Classified',
    'Dan Mangan',
    'Danny Fernandes',
    'Delhi 2 Dublin',
    'Down With Webster',
    'Elisapie Isaac',
    'Emilie Claire Barlow',
    'Fefe Dobson',
    'George Canyon',
    'Good Lovelies',
    'Gord Bamford',
    'Gord Downie',
    'Hayley Sales',
    'Hey Rosetta!',
    'Hollerado',
    'Ian Kelly',
    'Jaydee Bixby',
    'Jenn Grant',
    'Jimmy Rankin',
    'Johnny Reid',
    'Jonas',
    'JRDN',
    'Ladies of the Canyon',
    'Marco Calliari',
    'Melissa McClelland',
    'Mother Mother',
    'My Darkest Days',
    'Nikki Yanofsky',
    'Plants and Animals',
    'Protest The Hero',
    'Ridley Bent',
    'Sean McCann',
    'Serena Ryder',
    'Shad',
    'Silverstein',
    'Stereos',
    'The Sadies',
    'The Wailin’ Jennys',
    'The Wooden Sky',
    'These Kids Wear Crowns',
    'Timber Timbre',
    'Tokyo Police Club',
    'Wintersleep',
  ],
  '39': [
    'Alex Cuba',
    'Alyssa Reid',
    'Bahamas',
    'Basia Bulat',
    'Broken Social Scene',
    'City and Colour',
    'Classified',
    'Dan Mangan',
    'Danny Fernandes',
    'Dean Brody',
    'Doc Walker',
    'Elisapie Isaac',
    'George Canyon',
    'Good Lovelies',
    'Hannah Georgas',
    'Hey Rosetta!',
    'Ian Kelly',
    'illScarlett',
    'IMA',
    'Jenn Grant',
    'Jill Barber',
    'Jimmy Rankin',
    'Jo Hikk',
    'JRDN',
    'Justin Nozuka',
    'KO',
    'Luke Doucet',
    'Lynn Miles',
    'Matthew Good',
    'Melissa McClelland',
    'Misteur Valaire',
    'Mother Mother',
    'My Darkest Days',
    'Neverest',
    'Nikki Yanofsky',
    'Patrick Watson',
    'Pavlo',
    'Propagandhi',
    'Protest The Hero',
    'Sean McCann',
    'Silverstein',
    'Sweet Thing',
    'Tara Oram',
    'The Deep Dark Woods',
    'The Sadies',
    'These Kids Wear Crowns',
    'The Trews',
    'The Wailin’ Jennys',
    'Timber Timbre',
  ],
  '40': [
    'Abandon All Ships',
    'Alex Cuba',
    'Alyssa Reid',
    'Amanda Martinez',
    'Amelia Curran',
    'Angèle Dubeau &amp; La Pietà',
    'Arkells',
    'Bahamas',
    'Blake McGrath',
    'Bobby Bazini',
    'Catherine MacLellan',
    'Chad VanGaalen',
    'Chic Gamine',
    'City and Colour',
    'Classified',
    'Cuff The Duke',
    'Dan Mangan',
    'Danny Fernandes',
    'Delhi 2 Dublin',
    'Doc Walker',
    'Elisapie Isaac',
    'Elliott Brood',
    'Ensemble Caprice',
    'Feist',
    'Frazey Ford',
    'Good Lovelies',
    'Gord Bamford',
    'Great Lake Swimmers',
    'Hey Rosetta!',
    'Hollerado',
    'Ian Kelly',
    'Jaydee Bixby',
    'Jessie Farrell',
    'Jill Barber',
    'Jim Cuddy',
    'Jonas',
    'JRDN',
    'Kathleen Edwards',
    'Ladies of the Canyon',
    'Lights',
    'Madison Violet',
    'Marc Hervieux',
    'Marco Calliari',
    'Matthew Barber',
    'Misteur Valaire',
    'Mother Mother',
    'Neverest',
    'Nikki Yanofsky',
    'Pascale Picard Band',
    'Peter Jackson',
    'Ron Sexsmith',
    'Royal Wood',
    'Sarah Slean',
    'Sean McCann',
    'Sophie Milman',
    'Steve Bell',
    'Sultans of String',
    'Susie Arioli',
    'Tara Oram',
    'Ten Second Epic',
    'The Deep Dark Woods',
    'The New Cities',
    'The Rural Alberta Advantage',
    'The Sadies',
    'The Sheepdogs',
    'The Trews',
    'The Wailin’ Jennys',
    'These Kids Wear Crowns',
    'Timber Timbre',
    'Tokyo Police Club',
    'Zeus',
  ],
  '41': [
    'Abandon All Ships',
    'Adam Cohen',
    'Alain Lefèvre',
    'Amanda Martinez',
    'Amelia Curran',
    'Angèle Dubeau &amp; La Pietà',
    'Arkells',
    'Blake McGrath',
    'Chad Brownlee',
    'Comeback Kid',
    'Cuff The Duke',
    'Dan Mangan',
    'Danny Fernandes',
    'Diamond Rings',
    'Down With Webster',
    'Elliott Brood',
    'Emilie Claire Barlow',
    'Good Lovelies',
    'Gord Bamford',
    'Great Lake Swimmers',
    'Hannah Georgas',
    'Hayley Sales',
    'Hey Rosetta!',
    'Ian Kelly',
    'IMA',
    'Jenn Grant',
    'Jill Barber',
    'Jimmy Rankin',
    'JRDN',
    'Justin Hines',
    'Kathleen Edwards',
    'Keshia Chante',
    'Kreesha Turner',
    'Lights',
    'Madison Violet',
    'Marianas Trench',
    'Matthew Good',
    'Mia Martina',
    'Patrick Watson',
    'Plants and Animals',
    'Raghav',
    'Said The Whale',
    'Sarah Slean',
    'Shawn Desman',
    'Silverstein',
    'Sophie Milman',
    'State of Shock',
    'Sultans of String',
    'Susie Arioli',
    'Ten Second Epic',
    'The Barr Brothers',
    'The Deep Dark Woods',
    'The Midway State',
    'The New Cities',
    'The Sheepdogs',
    'These Kids Wear Crowns',
    'Timber Timbre',
    'Tokyo Police Club',
    'Whitehorse',
    'Zeus',
  ],
  '42': [
    'Adam Cohen',
    'Alain Lefèvre',
    'Alyssa Reid',
    'Ariane Moffatt',
    'Arkells',
    'Austra',
    'Bahamas',
    'Cancer Bats',
    'Carly Rae Jepsen',
    'Chad Brownlee',
    'Chad VanGaalen',
    'Classified',
    'Comeback Kid',
    'Dan Mangan',
    'Dean Brody',
    'Down With Webster',
    'Dragonette',
    'Élage Diouf',
    'Elliott Brood',
    'Good Lovelies',
    'Gord Bamford',
    'Great Lake Swimmers',
    'Hannah Georgas',
    'Hayley Sales',
    'Hey Rosetta!',
    'Ian Kelly',
    'Jenn Grant',
    'Joel Plaskett',
    'Johnny Reid',
    'Jonas',
    'Justin Hines',
    'Karl Wolf',
    'Kathleen Edwards',
    'Kristina Maria',
    'Lights',
    'Madison Violet',
    'Manafest',
    'Marianas Trench',
    'Mia Martina',
    'My Darkest Days',
    'Neverest',
    'Nikki Yanofsky',
    'Patrick Watson',
    'Peter Jackson',
    'Plants and Animals',
    'Said The Whale',
    'Silverstein',
    'Ten Second Epic',
    'The Barr Brothers',
    'The Deep Dark Woods',
    'The New Cities',
    'The Rural Alberta Advantage',
    'The Wooden Sky',
    'These Kids Wear Crowns',
    'Timber Timbre',
    'Whitehorse',
    'Zeus',
  ],
  '43': [
    'Adam Cohen',
    'Alan Doyle',
    'Alyssa Reid',
    'Ariane Moffatt',
    'Arkells',
    'Austra',
    'Bahamas',
    'Belly',
    'Catherine MacLellan',
    'Chic Gamine',
    'Chilly Gonzales',
    'Crystal Shawanda',
    'Cuff The Duke',
    'Current Swell',
    'Dallas Smith',
    'Dan Mangan',
    'Danny Fernandes',
    'David Francey',
    'David Myles',
    'Dean Brody',
    'Delhi 2 Dublin',
    'Élage Diouf',
    'Elliott Brood',
    'Hey Rosetta!',
    'Imaginary Cities',
    'Jenn Grant',
    'Jonas',
    'Karina Gauvin',
    'Karl Wolf',
    'Kathleen Edwards',
    'Lights',
    'Lucky Uke',
    'Madison Violet',
    'Manafest',
    'Marianas Trench',
    'Misteur Valaire',
    'Nikki Yanofsky',
    'Patrick Watson',
    'Peter Jackson',
    'Plants and Animals',
    'Royal Wood',
    'Said The Whale',
    'Sarah Slean',
    'Shad',
    'Sophie Milman',
    'Susie Arioli',
    'The Barr Brothers',
    'The Deep Dark Woods',
    'The New Cities',
    'The Rural Alberta Advantage',
    'The Sadies',
    'The Sheepdogs',
    'The Trews',
    'The Wooden Sky',
    'Timber Timbre',
    'Tokyo Police Club',
    'Wintersleep',
    'Zeus',
  ],
  '44': [
    'Abandon All Ships',
    'Adam Cohen',
    'Alex Cuba',
    'Amelia Curran',
    'Angèle Dubeau &amp; La Pietà',
    'Ariane Moffatt',
    'Arkells',
    'Austra',
    'Bahamas',
    'Cancer Bats',
    'Carly Rae Jepsen',
    'Chad Brownlee',
    'Chic Gamine',
    'Chilly Gonzales',
    'Crystal Shawanda',
    'Cuff The Duke',
    'Current Swell',
    'Dallas Smith',
    'Dan Mangan',
    'Danko Jones',
    'Danny Michel',
    'Dean Brody',
    'Diamond Rings',
    'Doc Walker',
    'Dragonette',
    'Elisapie Isaac',
    'Elizabeth Shepherd',
    'Elliott Brood',
    'Faber Drive',
    'George Canyon',
    'Good Lovelies',
    'Great Lake Swimmers',
    'Hannah Georgas',
    'Hayden',
    'Hey Rosetta!',
    'Jenn Grant',
    'Jon and Roy',
    'Jonas',
    'Kathleen Edwards',
    'Madison Violet',
    'Marianas Trench',
    'Marie-Josée Lord',
    'Martha Wainwright',
    'Matt Mays',
    'My Darkest Days',
    'Old Man Luedecke',
    'Patrick Watson',
    'Royal Wood',
    'Said The Whale',
    'Stars',
    'Susie Arioli',
    'The Barr Brothers',
    'The New Cities',
    'The Pack A.D.',
    'The Rural Alberta Advantage',
    'The Sadies',
    'The Wooden Sky',
    'Timber Timbre',
    'Victoria Duffield',
    'Whitehorse',
    'Wintersleep',
    'Zeus',
  ],
  '45': [
    'Adam Cohen',
    'Alex Cuba',
    'Amelia Curran',
    'Andrée Watters',
    'Angèle Dubeau &amp; La Pietà',
    'Ariane Moffatt',
    'Arkells',
    'Austra',
    'Chic Gamine',
    'Classified',
    'Cold Specks',
    'Cuff The Duke',
    'Current Swell',
    'Danko Jones',
    'Danny Michel',
    'Dragonette',
    'Elisapie Isaac',
    'Elizabeth Shepherd',
    'Elliott Brood',
    'Faber Drive',
    'Good Lovelies',
    'Great Lake Swimmers',
    'Hannah Georgas',
    'Hey Ocean!',
    'Hollerado',
    'Ian Kelly',
    'Imaginary Cities',
    'Jenn Grant',
    'Jesse Cook',
    'Jesse Labelle',
    'Jimmy Rankin',
    'Jon and Roy',
    'Kristina Maria',
    'Manafest',
    'Martha Wainwright',
    'Matt Dusk',
    'Matt Mays',
    'Mother Mother',
    'Old Man Luedecke',
    'Pavlo',
    'Ron Sexsmith',
    'Said The Whale',
    'Sarah Slean',
    'Serena Ryder',
    'Sophie Milman',
    'Stars',
    'Sultans of String',
    'Susie Arioli',
    'The Barr Brothers',
    'The Deep Dark Woods',
    'The Sheepdogs',
    'The Trews',
    'The Weeknd',
    'Victoria Duffield',
    'Whitehorse',
    'Zeus',
  ],
  '46': [
    'Abandon All Ships',
    'Alan Doyle',
    'Alex Cuba',
    'Amanda Martinez',
    'Amelia Curran',
    'Ariane Moffatt',
    'Arkells',
    'Austra',
    'Bahamas',
    'Carly Rae Jepsen',
    'Catherine MacLellan',
    'Chad Brownlee',
    'Chic Gamine',
    'Chilly Gonzales',
    'City and Colour',
    'Classified',
    'Cold Specks',
    'Cuff The Duke',
    'Current Swell',
    'Dallas Smith',
    'Danko Jones',
    'Delhi 2 Dublin',
    'Diamond Rings',
    'Down With Webster',
    'Dragonette',
    'Elisapie Isaac',
    'Elizabeth Shepherd',
    'Elliott Brood',
    'Emerson Drive',
    'Good Lovelies',
    'Grimes',
    'Half Moon Run',
    'Hannah Georgas',
    'Hollerado',
    'Ian Kelly',
    'Imaginary Cities',
    'Jenn Grant',
    'Jesse Labelle',
    'Jill Barber',
    'Jonas',
    'Justin Hines',
    'Lindi Ortega',
    'Madison Violet',
    'Martha Wainwright',
    'Massari',
    'Matt Mays',
    'Mother Mother',
    'Old Man Luedecke',
    'Plants and Animals',
    'Purity Ring',
    'Ron Sexsmith',
    'Royal Wood',
    'Said The Whale',
    'Sarah Slean',
    'Serena Ryder',
    'Shad',
    'Shawn Desman',
    'Stars',
    'Steve Bell',
    'Susie Arioli',
    'The Barr Brothers',
    'The Deep Dark Woods',
    'The Sheepdogs',
    'Whitehorse',
    'Wintersleep',
    'Yukon Blonde',
    'Zeus',
  ],
  '47': [
    'Alan Doyle',
    'Amelia Curran',
    'Arkells',
    'Austra',
    'Ben Caplan',
    'Catherine MacLellan',
    'Chic Gamine',
    'Classified',
    'Crystal Shawanda',
    'Cuff The Duke',
    'Current Swell',
    'Danny Michel',
    'David Francey',
    'Del Barber',
    'Elisapie Isaac',
    'Elizabeth Shepherd',
    'Good Lovelies',
    'Greg Sczebel',
    'Hey Ocean!',
    'Imaginary Cities',
    'Jonas',
    'Marianas Trench',
    'Martha Wainwright',
    'Massari',
    'Matt Andersen',
    'Mia Martina',
    'Mo Kenney',
    'Old Man Luedecke',
    'Purity Ring',
    'Rose Cousins',
    'Said The Whale',
    'Sarah Slean',
    'Serena Ryder',
    'Shad',
    'Snak The Ripper',
    'Sultans of String',
    'The Barr Brothers',
    'The Rural Alberta Advantage',
    'The Sheepdogs',
    'The Wailin’ Jennys',
    'Whitehorse',
    'Young Empires',
  ],
  '48': [
    'Abandon All Ships',
    'Alex Cuba',
    'Amanda Martinez',
    'Amelia Curran',
    'Andrée Watters',
    'Austra',
    'Bahamas',
    'Basia Bulat',
    'Cancer Bats',
    'Chad Brownlee',
    'Chromeo',
    'Colin Stetson',
    'Crash Karma',
    'Current Swell',
    'Dallas Smith',
    'Danko Jones',
    'Danny Fernandes',
    'David Myles',
    'Dean Brody',
    'Del Barber',
    'Delhi 2 Dublin',
    'Down With Webster',
    'Elisapie Isaac',
    'Elizabeth Shepherd',
    'Good Lovelies',
    'Gord Bamford',
    'Groenland',
    'Half Moon Run',
    'Hannah Georgas',
    'Hey Ocean!',
    'Ian Kelly',
    'Imaginary Cities',
    'Jaydee Bixby',
    'Jenn Grant',
    'Jon and Roy',
    'JRDN',
    'Ladies of the Canyon',
    'Leif Vollebekk',
    'Manafest',
    'Matt Andersen',
    'Matt Dusk',
    'Matt Mays',
    'Mia Martina',
    'Misteur Valaire',
    'Mo Kenney',
    'Monster Truck',
    'Mother Mother',
    'New Country Rehab',
    'Nikki Yanofsky',
    'Old Man Luedecke',
    'Purity Ring',
    'Rose Cousins',
    'Royal Wood',
    'Ruth Moody',
    'Said The Whale',
    'Sarah Slean',
    'Serena Ryder',
    'Shad',
    'Susie Arioli',
    'The Barr Brothers',
    'The Deep Dark Woods',
    'The Pack A.D.',
    'The Sheepdogs',
    'The Wailin’ Jennys',
    'The Weeknd',
    'Whitehorse',
    'Young Empires',
    'Yukon Blonde',
  ],
  '49': [
    'A Tribe Called Red',
    'Alyssa Reid',
    'Amanda Martinez',
    'Autumn Hill',
    'Bahamas',
    'Basia Bulat',
    'Ben Caplan',
    'Blackie and the Rodeo Kings',
    'Brett Kissel',
    'Catherine MacLellan',
    'Danny Fernandes',
    'Dean Brody',
    'Del Barber',
    'Deric Ruttan',
    'Elisapie Isaac',
    'Elizabeth Shepherd',
    'Hannah Georgas',
    'Headstones',
    'Hollerado',
    'Jason Blaine',
    'JRDN',
    'Ladies of the Canyon',
    'Martha Wainwright',
    'Matt Andersen',
    'Matt Dusk',
    'Matthew Good',
    'Mo Kenney',
    'Monster Truck',
    'New Country Rehab',
    'Royal Wood',
    'Ruth Moody',
    'Said The Whale',
    'Shad',
    'Shawn Desman',
    'Sultans of String',
    'Susie Arioli',
    'The Deep Dark Woods',
    'The Pack A.D.',
    'The Rural Alberta Advantage',
    'The Wailin’ Jennys',
    'Tim Hicks',
    'Timber Timbre',
    'Whitehorse',
    'Zeus',
  ],
  '50': [
    'Abandon All Ships',
    'Amanda Martinez',
    'A Tribe Called Red',
    'Austra',
    'Basia Bulat',
    'Ben Caplan',
    'Brett Kissel',
    'Bry Webb',
    'Catherine MacLellan',
    'Chromeo',
    'Cold Specks',
    'Colin Stetson',
    'Current Swell',
    'Dallas Smith',
    'Danny Fernandes',
    'David Francey',
    'David Myles',
    'Del Barber',
    'Down With Webster',
    'Elizabeth Shepherd',
    'Fefe Dobson',
    'Florence K.',
    'Good Lovelies',
    'Gord Downie',
    'Greg Sczebel',
    'Half Moon Run',
    'Hollerado',
    'Ian Kelly',
    'Jay Malinowski',
    'Jon and Roy',
    'Jordan Officer',
    'JRDN',
    'July Talk',
    'Karina Gauvin',
    'Lindi Ortega',
    'Matt Andersen',
    'Meaghan Smith',
    'Mo Kenney',
    'Monster Truck',
    'Nikki Yanofsky',
    'Old Man Luedecke',
    'One More Girl',
    'Pascale Picard',
    'Ruth Moody',
    'Sarah Slean',
    'Sultans of String',
    'Susie Arioli',
    'The Pack A.D.',
    'The Strumbellas',
    'The Wailin’ Jennys',
    'Timber Timbre',
    'Tokyo Police Club',
    'U.S.S.',
  ],
  '51': [
    'Adam Cohen',
    'Alejandra Ribera',
    'Alex Cuba',
    'Amanda Martinez',
    'Arkells',
    'A Tribe Called Red',
    'Austra',
    'Bahamas',
    'Ben Caplan',
    'Bobby Bazini',
    'Bry Webb',
    'Chromeo',
    'Cold Specks',
    'Current Swell',
    'Dallas Smith',
    'David Myles',
    'Del Barber',
    'Delhi 2 Dublin',
    'Down With Webster',
    'Elisapie Isaac',
    'Elizabeth Shepherd',
    'Florence K.',
    'Fucked Up',
    'Gord Downie',
    'Hannah Georgas',
    'Hollerado',
    'Jill Barber',
    'Jon and Roy',
    'Jordan Officer',
    'July Talk',
    'Kira Isabella',
    'Leif Vollebekk',
    'Manafest',
    'Massari',
    'Matt Andersen',
    'Mia Martina',
    'Misteur Valaire',
    'Mo Kenney',
    'Monster Truck',
    'Nikki Yanofsky',
    'One More Girl',
    'Ruth Moody',
    'Said The Whale',
    'Scarlett Jane',
    'Snak The Ripper',
    'Susie Arioli',
    'The Barr Brothers',
    'The Deep Dark Woods',
    'The Pack A.D.',
    'The Rural Alberta Advantage',
    'The Seasons',
    'The Strumbellas',
    'The Wailin’ Jennys',
    'Timber Timbre',
    'Victoria Duffield',
    'Virginia To Vegas',
    'Yukon Blonde',
    'Zeus',
  ],
  '52': [
    'Adam Cohen',
    'Alan Doyle',
    'Alejandra Ribera',
    'Alex Cuba',
    'Alyssa Reid',
    'Amanda Martinez',
    'Amelia Curran',
    'Angèle Dubeau &amp; La Pietà',
    'Arkells',
    'Austra',
    'Autumn Hill',
    'Bahamas',
    'Basia Bulat',
    'Ben Caplan',
    'Bobby Bazini',
    'Brett Kissel',
    'Bry Webb',
    'Cold Specks',
    'Colin Stetson',
    'Current Swell',
    'David Francey',
    'David Myles',
    'Dean Brody',
    'Del Barber',
    'Diana Panton',
    'Doc Walker',
    'Down With Webster',
    'Elizabeth Shepherd',
    'Elliott Brood',
    'Florence K.',
    'Frazey Ford',
    'Fucked Up',
    'Hey Rosetta!',
    'Hollerado',
    'Jon and Roy',
    'Jordan Officer',
    'July Talk',
    'Kira Isabella',
    'Leif Vollebekk',
    'Lights',
    'Lindi Ortega',
    'Marie-Josée Lord',
    'Massari',
    'Matt Andersen',
    'Mia Martina',
    'Mo Kenney',
    'Monkeyjunk',
    'Monster Truck',
    'Mother Mother',
    'Nikki Yanofsky',
    'One More Girl',
    'Royal Wood',
    'Ruth Moody',
    'Said The Whale',
    'Snak The Ripper',
    'Steve Bell',
    'Sultans of String',
    'Susie Arioli',
    'The Barr Brothers',
    'The Deep Dark Woods',
    'The Glorious Sons',
    'The New Pornographers',
    'The Once',
    'The Pack A.D.',
    'The Road Hammers',
    'The Rural Alberta Advantage',
    'The Seasons',
    'The Strumbellas',
    'The Trews',
    'The Wailin’ Jennys',
    'The Wooden Sky',
    'Tim Hicks',
    'Timber Timbre',
    'U.S.S.',
    'Virginia To Vegas',
    'Young Galaxy',
    'Your Favorite Enemies',
    'Zeus',
  ],
  '53': [
    'Adam Cohen',
    'Amelia Curran',
    'Arkells',
    'Autumn Hill',
    'Basia Bulat',
    'Ben Caplan',
    'Bobby Wills',
    'Bobs and LoLo',
    'Bran Van 3000',
    'Brett Kissel',
    'Chad Brownlee',
    'Cold Specks',
    'Colin Stetson',
    'Current Swell',
    'Dallas Smith',
    'Danny Michel',
    'David Francey',
    'Death From Above 1979',
    'Del Barber',
    'Delhi 2 Dublin',
    'Elizabeth Shepherd',
    'Florence K.',
    'Frazey Ford',
    'Jess Moskaluke',
    'Jill Barber',
    'Jon and Roy',
    'Jordan Officer',
    'Kira Isabella',
    'Leif Vollebekk',
    'Lights',
    'Massari',
    'Matt Andersen',
    'Measha Brueggergosman',
    'Mia Martina',
    'Monster Truck',
    'Nikki Yanofsky',
    'One Bad Son',
    'One More Girl',
    'Rose Cousins',
    'Ruth Moody',
    'Susie Arioli',
    'Tanya Tagaq',
    'The Barr Brothers',
    'The Once',
    'The Pack A.D.',
    'The Strumbellas',
    'Tim Hicks',
    'The Wailin’ Jennys',
    'Thousand Foot Krutch',
    'Virginia To Vegas',
    'Whitehorse',
  ],
  '54': [
    'Adam Cohen',
    'Alan Doyle',
    'Alejandra Ribera',
    'Amelia Curran',
    'Alvvays',
    'Amanda Martinez',
    'Arkells',
    'A Tribe Called Red',
    'Autumn Hill',
    'Bahamas',
    'Ben Caplan',
    'Boogat',
    'Brett Kissel',
    'Chilly Gonzales',
    'Cold Specks',
    'Current Swell',
    'Danko Jones',
    'Danny Michel',
    'Death From Above 1979',
    'Delhi 2 Dublin',
    'Diana Panton',
    'Doug Crawford',
    'Elizabeth Shepherd',
    'Florence K.',
    'Frazey Ford',
    'Fucked Up',
    'Great Lake Swimmers',
    'Groenland',
    'High Valley',
    'Joel Plaskett',
    'Jon and Roy',
    'Jordan Officer',
    'Leif Vollebekk',
    'Lights',
    'Mo Kenney',
    'Molly Johnson',
    'Purity Ring',
    'Reuben and the Dark',
    'Rose Cousins',
    'Royal Wood',
    'Sarah Slean',
    'Snak The Ripper',
    'Steve Bell',
    'Tanya Tagaq',
    'The Barr Brothers',
    'The Glorious Sons',
    'The Once',
    'The Rural Alberta Advantage',
    'The Stellas',
    'Tim Hicks',
    'Whitehorse',
    'Yukon Blonde',
    'Zeds Dead',
  ],
  '55': [
    'Alejandra Ribera',
    'Alvvays',
    'Alyssa Reid',
    'Amelia Curran',
    'A Tribe Called Red',
    'Austra',
    'BadBadNotGood',
    'Ben Caplan',
    'Bia',
    'Bobs and Lolo',
    'Boogat',
    'Born Ruffians',
    'Bry Webb',
    'Carly Rae Jepsen',
    'Chilly Gonzales',
    'Cold Specks',
    'Colin Stetson',
    'Cœur de Pirate',
    'Current Swell',
    'Dallas Smith',
    'Danny Michel',
    'David Myles',
    'Dean Brody',
    'Dear Rouge',
    'Del Barber',
    'Delhi 2 Dublin',
    'Diana Panton',
    'Doug Crawford',
    'Florence K.',
    'Frazey Ford',
    'Fucked Up',
    'Good Lovelies',
    'Jill Barber',
    'Jordan Officer',
    'Kandle',
    'Leif Vollebekk',
    'Marianas Trench',
    'Massari',
    'Matt Andersen',
    'Milk &amp; Bone',
    'Mo Kenney',
    'Monster Truck',
    'Mother Mother',
    'Purity Ring',
    'Reuben and the Dark',
    'Rose Cousins',
    'Ruth Moody',
    'Scarlett Jane',
    'Snak The Ripper',
    'SonReal',
    'Steve Hill',
    'Sultans of String',
    'Tanya Tagaq',
    'The Barr Brothers',
    'The Glorious Sons',
    'The Harpoonist &amp; The Axe Murderer',
    'The Rural Alberta Advantage',
    'The Strumbellas',
    'The Wooden Sky',
    'Tyler Shaw',
    'Virginia To Vegas',
    'Yoan',
    'Young Empires',
    'Your Favorite Enemies',
    'Yukon Blonde',
  ],
  '56': [
    'Alan Doyle',
    'Alejandra Ribera',
    'Amanda Martinez',
    'Angèle Dubeau &amp; La Pietà',
    'Autumn Hill',
    'Ben Caplan',
    'Bia',
    'Bobs and LoLo',
    'Boogat',
    'Born Ruffians',
    'Brett Kissel',
    'Cœur de Pirate',
    'Cold Specks',
    'Current Swell',
    'Dave Gunning',
    'David Francey',
    'Dear Rouge',
    'Delhi 2 Dublin',
    'Diana Panton',
    'David Myles',
    'Donovan Woods',
    'Dragonette',
    'Elizabeth Shepherd',
    'Emilie Claire Barlow',
    'Frazey Ford',
    'Fucked Up',
    'Good Lovelies',
    'Grimes',
    'Half Moon Run',
    'High Valley',
    'Jill Barber',
    'Joel Plaskett',
    'Jon and Roy',
    'Jordan Officer',
    'Lee Harvey Osmond',
    'Lindi Ortega',
    'Majical Cloudz',
    'Massari',
    'Misteur Valaire',
    'Mo Kenney',
    'Old Man Luedecke',
    'Purity Ring',
    'Rah Rah',
    'Reuben and the Dark',
    'Rose Cousins',
    'Scarlett Jane',
    'Stéphane Tétreault',
    'Steve Hill',
    'Sultans of String',
    'Tanya Tagaq',
    'The Bros. Landreth',
    'The Franklin Electric',
    'The Glorious Sons',
    'The Once',
    'The Sheepdogs',
    'The Strumbellas',
    'The Wooden Sky',
    'Tim Hicks',
    'Tyler Shaw',
    'Wes Mack',
    'Whitehorse',
    'Yoan',
    'Young Empires',
    'Young Galaxy',
    'Yukon Blonde',
  ],
  '57': [
    'Alan Doyle',
    'Alejandra Ribera',
    'Alvvays',
    'Alyssa Reid',
    'A Tribe Called Red',
    'Autumn Hill',
    'Bahamas',
    'Ben Caplan',
    'Bia',
    'Bobs and LoLo',
    'Boogat',
    'Brett Kissel',
    'Coleman Hell',
    'Current Swell',
    'Dean Brody',
    'Del Barber',
    'Diana Panton',
    'Donovan Woods',
    'Frazey Ford',
    'Half Moon Run',
    'Head of the Herd',
    'Jason Blaine',
    'Jean-Willy Kunz',
    'Jon and Roy',
    'Jordan Officer',
    'Kevin Bazinet',
    'Lindi Ortega',
    'Majical Cloudz',
    'Massari',
    'Matt Andersen',
    'Matt Dusk',
    'Mo Kenney',
    'Monster Truck',
    'One Bad Son',
    'Pierre Kwenders',
    'Purity Ring',
    'Rose Cousins',
    'SonReal',
    'Sultans of String',
    'Susie Arioli',
    'Tanya Tagaq',
    'Tebey',
    'The Bros. Landreth',
    'The Franklin Electric',
    'The Sheepdogs',
    'The Strumbellas',
    'The Wooden Sky',
    'Thousand Foot Krutch',
    'Tyler Shaw',
    'U.S.S.',
    'Yoan',
    'Young Empires',
    'Young Galaxy',
    'Your Favorite Enemies',
  ],
  '58': [
    'A Tribe Called Red',
    'Aaron Pritchett',
    'Alan Doyle',
    'Alejandra Ribera',
    'Alvvays',
    'Angèle Dubeau &amp; La Pietà',
    'Autumn Hill',
    'Basia Bulat',
    'Boogat',
    'Born Ruffians',
    'Cœur de Pirate',
    'Cold Creek County',
    'Coleman Hell',
    'Colin Stetson',
    'Current Swell',
    'Dave Gunning',
    'David Francey',
    'David Myles',
    'Del Barber',
    'Delhi 2 Dublin',
    'Diana Panton',
    'Donovan Woods',
    'Emilie &amp; Ogden',
    'Emilie Claire Barlow',
    'Florence K.',
    'Frazey Ford',
    'Half Moon Run',
    'Jordan Officer',
    'Joshua Hyslop',
    'Lee Harvey Osmond',
    'Massari',
    'Matt Andersen',
    'Matt Dusk &amp; Florence K.',
    'Milk &amp; Bone',
    'Mo Kenney',
    'Monster Truck',
    'Natalie MacMaster and Donnell Leahy',
    'Old Man Luedecke',
    'Rah Rah',
    'Ria Mae',
    'Rose Cousins',
    'Royal Wood',
    'Ruth Moody',
    'Sarah Slean',
    'Snak The Ripper',
    'Sultans of String',
    'Susie Arioli',
    'Tanya Tagaq',
    'The Bros. Landreth',
    'The Franklin Electric',
    'The Glorious Sons',
    'The Harpoonist &amp; The Axe Murder',
    'The Jerry Cans',
    'The Once',
    'The Strumbellas',
    'Thousand Foot Krutch',
    'Tim and the Glory Boys',
    'Whitehorse',
    'Young Empires',
    'Yukon Blonde',
  ],
  '59': [
    'Alan Doyle',
    'Alejandra Ribera',
    'A Tribe Called Red',
    'Autumn Hill',
    'Ben Caplan',
    'Betty Bonifassi',
    'Bia',
    'Boogat',
    'Born Ruffians',
    'Braids',
    'Brett Kissel',
    'Charlie A’Court',
    'Cold Creek County',
    'Cold Specks',
    'Coleman Hell',
    'David Francey',
    'David Myles',
    'Dead Obies',
    'Del Barber',
    'Dear Rouge',
    'Delhi 2 Dublin',
    'Diana Panton',
    'Doc Walker',
    'Donovan Woods',
    'Elizabeth Shepherd',
    'Emilie Claire Barlow',
    'Emilie &amp; Ogden',
    'Frazey Ford',
    'Half Moon Run',
    'Hannah Georgas',
    'Jenn Grant',
    'Jordan Officer',
    'Joshua Hyslop',
    'July Talk',
    'Lindi Ortega',
    'Matt Andersen',
    'Milk &amp; Bone',
    'Mo Kenney',
    'Monster Truck',
    'Natalie MacMaster and Donnell Leahy',
    'Paper Lions',
    'Pierre Kwenders',
    'Preoccupations',
    'Pup',
    'Purity Ring',
    'Reuben and the Dark',
    'Ria Mae',
    'Royal Canoe',
    'Royal Wood',
    'Ruth Moody',
    'Scarlett Jane',
    'Silverstein',
    'Snak The Ripper',
    'SonReal',
    'Steve Hill',
    'Sultans of String',
    'Susie Arioli',
    'The Glorious Sons',
    'The Pack A.D.',
    'The Strumbellas',
    'The Zolas',
    'Thousand Foot Krutch',
    'Tim Neufeld',
    'Tyler Shaw',
    'Wes Mack',
    'Whitehorse',
    'Young Empires',
    'Young Galaxy',
    'Yukon Blonde',
    'Zeds Dead',
  ],
  '60': [
    'Alejandra Ribera',
    'A Tribe Called Red',
    'Austra',
    'Bahamas',
    'Basia Bulat',
    'Bears of Legend',
    'Ben Caplan',
    'Betty Bonifassi',
    'Bobby Wills',
    'Boogat',
    'Born Ruffians',
    'Braids',
    'Charlotte Cardin',
    'Cold Creek County',
    'Cold Specks',
    'Coleman Hell',
    'Crystal Castles',
    'David Myles',
    'Dead Obies',
    'Delhi 2 Dublin',
    'Diana Panton',
    'Doc Walker',
    'Donovan Woods',
    'Doug Crawford',
    'Emilie &amp; Ogden',
    'Fortunate Ones',
    'Half Moon Run',
    'Japandroids',
    'Jesse Roper',
    'Jon and Roy',
    'Jordan Officer',
    'Joshua Hyslop',
    'July Talk',
    'Karl Wolf',
    'Lee Harvey Osmond',
    'Madeline Merlo',
    'Martha Wainwright',
    'Matt Andersen',
    'Matt Holubowski',
    'Meghan Patrick',
    'Mo Kenney',
    'Paper Lions',
    'Philip Sayce',
    'Pierre Kwenders',
    'Plants and Animals',
    'Preoccupations',
    'PUP',
    'Reuben and the Dark',
    'Rose Cousins',
    'Royal Canoe',
    'Royal Wood',
    'Scarlett Jane',
    'Silverstein',
    'SonReal',
    'Steve Hill',
    'Susie Arioli',
    'Tanya Tagaq',
    'Terra Lightfoot',
    'The Bros. Landreth',
    'The Harpoonist &amp; the Axe Murderer',
    'The Pack A.D.',
    'The Rural Alberta Advantage',
    'The Strumbellas',
    'The Wooden Sky',
    'The Zolas',
    'Thousand Foot Krutch',
    'Tim Hicks',
    'Tim Neufeld',
    'Twin Bandit',
    'Tyler Shaw',
    'Zeds Dead',
  ],
  '61': [
    'Alan Doyle',
    'Alvvays',
    'Andy Shauf',
    'Arkells',
    'A Tribe Called Red',
    'Badbadnotgood',
    'Betty Bonifassi',
    'Bia',
    'Chad Brownlee',
    'Charles Richard-Hamelin',
    'Charlotte Cardin',
    'Cœur de Pirate',
    'Coleman Hell',
    'Dave Gunning',
    'David Myles',
    'Donovan Woods',
    'Dragonette',
    'Emilie Claire Barlow',
    'Emilie &amp; Ogden',
    'Fortunate Ones',
    'Grimes',
    'Half Moon Run',
    'Hannah Georgas',
    'Hollerado',
    'Japandroids',
    'Jean-Michel Blais',
    'Jenn Grant',
    'Jordan Officer',
    'July Talk',
    'Kid Koala',
    'Lindi Ortega',
    'Lisa LeBlanc',
    'Manafest',
    'Matt Dusk',
    'Matt Holubowski',
    'Natalie MacMaster and Donnell Leahy',
    'Nikki Yanofsky',
    'One Bad Son',
    'Paper Lions',
    'PUP',
    'Ria Mae',
    'Rose Cousins',
    'Royal Canoe',
    'Royal Wood',
    'Silverstein',
    'Snak The Ripper',
    'SonReal',
    'Steve Hill',
    'Susie Arioli',
    'Tanya Tagaq',
    'Tebey',
    'The Franklin Electric',
    'The Harpoonist &amp; the Axe Murderer',
    'The Jerry Cans',
    'The Strumbellas',
    'The Wooden Sky',
    'Thousand Foot Krutch',
    'Tyler Shaw',
    'U.S.S.',
    'Virginia to Vegas',
    'Zeds Dead',
  ],
  '62': [
    'Alan Doyle',
    'Alejandra Ribera',
    'Alex Cuba',
    'Alvvays',
    'Amelia Curran',
    'Andy Shauf',
    'A Tribe Called Red',
    'Austra',
    'Betty Bonifassi',
    'Boogat',
    'Braids',
    'Coco Méliès',
    'Current Swell',
    'Dead Obies',
    'Emilie &amp; Ogden',
    'Fortunate Ones',
    'Groenland',
    'Half Moon Run',
    'Hollerado',
    'Jean-Michel Blais',
    'Jesse Roper',
    'Jon and Roy',
    'Leif Vollebekk',
    'Lindi Ortega',
    'Lisa LeBlanc',
    'Mac DeMarco',
    'Madeline Merlo',
    'Martha Wainwright',
    'Matt Holubowski',
    'Mother Mother',
    'Neon Dreams',
    'Paper Lions',
    'Preoccupations',
    'Purity Ring',
    'Reuben and the Dark',
    'Rose Cousins',
    'Royal Wood',
    'Said The Whale',
    'SonReal',
    'Steve Hill',
    'Tanya Tagaq',
    'The Franklin Electric',
    'The Harpoonist &amp; the Axe Murderer',
    'The Jerry Cans',
    'The Rural Alberta Advantage',
    'The Strumbellas',
    'Tim Hicks',
    'Tim Neufeld',
    'U.S.S.',
    'Virginia to Vegas',
    'Whitehorse',
  ],
  '63': [
    'Alan Doyle',
    'Alejandra Ribera',
    'Alvvays',
    'Andy Shauf',
    'A Tribe Called Red',
    'Austra',
    'Badbadnotgood',
    'Betty Bonifassi',
    'Beyries',
    'Boogat',
    'Charlotte Cardin',
    'Cold Creek County',
    'Cold Specks',
    'David Francey',
    'Dead Obies',
    'Delhi 2 Dublin',
    'Donovan Woods',
    'Dragonette',
    'Emilie Claire Barlow',
    'Geoffroy',
    'Greg Sczebel',
    'Groenland',
    'Hannah Georgas',
    'Japandroids',
    'Jean-Michel Blais',
    'Kid Koala',
    'Leif Vollebekk',
    'Lindi Ortega',
    'Mac DeMarco',
    'Manafest',
    'Metz',
    'Mo Kenney',
    'Mother Mother',
    'Neon Dreams',
    'One Bad Son',
    'Pierre Kwenders',
    'Preoccupations',
    'Pup',
    'Royal Canoe',
    'Royal Wood',
    'Ruth Moody',
    'Splash’N Boots',
    'Steve Hill',
    'The Franklin Electric',
    'The Glorious Sons',
    'The Jerry Cans',
    'The Strumbellas',
    'Thousand Foot Krutch',
    'Tim Neufeld',
    'U.S.S.',
    'Virginia to Vegas',
    'Yukon Blonde',
  ],
  '64': [
    'Alejandra Ribera',
    'Alexandre Da Costa',
    'Allan Rayman',
    'Alvvays',
    'Andy Shauf',
    'A Tribe Called Red',
    'Bears of Legend',
    'Betty Bonifassi',
    'Beyries',
    'Bobby Wills',
    'Boogat',
    'Born Ruffians',
    'Charlotte Cardin',
    'Charlotte Day Wilson',
    'Current Swell',
    'Daniel Caesar',
    'David Myles',
    'Dead Obies',
    'Death From Above 1979',
    'Diana Panton',
    'Frazey Ford',
    'Gabriella',
    'Geoffroy',
    'James Barker Band',
    'Jean-Michel Blais',
    'Jon and Roy',
    'Jonathan Roy',
    'Lemon Bucket Orkestra',
    'Lindi Ortega',
    'Lisa LeBlanc',
    'Mac DeMarco',
    'Matt Mays',
    'Metz',
    'Natalie MacMaster and Donnell Leahy',
    'Neon Dreams',
    'One Bad Son',
    'Purity Ring',
    'Reuben and the Dark',
    'Ria Mae',
    'Scott Helman',
    'SonReal',
    'Splash’N Boots',
    'Steve Hill',
    'Tanya Tagaq',
    'The Bros. Landreth',
    'The Franklin Electric',
    'The Glorious Sons',
    'The Harpoonist &amp; the Axe Murderer',
    'The Rural Alberta Advantage',
    'Tim Neufeld',
    'Yukon Blonde',
  ],
  '65': [
    'Aaron Goodvin',
    'Alejandra Ribera',
    'Alexandre Da Costa',
    'Allie X',
    'Alyssa Reid',
    'Andy Shauf',
    'Beyries',
    'Boogat',
    'Born Ruffians',
    'Charlotte Cardin',
    'Charlotte Day Wilson',
    'Coco Melies',
    'Cold Creek County',
    'Current Swell',
    'Dan Davidson',
    'Daniel Caesar',
    'Dave Gunning',
    'Dead Obies',
    'Death From Above 1979',
    'Donovan Woods',
    'Emilie &amp; Ogden',
    'Emilie Claire Barlow',
    'Fortunate Ones',
    'Geoffroy',
    'James Barker Band',
    'Jean-Michel Blais',
    'Jesse Roper',
    'Joshua Hyslop',
    'Leif Vollebekk',
    'Lisa LeBlanc',
    'Marie-Josee Lord',
    'Meghan Patrick',
    'Mo Kenney',
    'Pierre Kwenders',
    'Pup',
    'Ria Mae',
    'Rose Cousins',
    'Said The Whale',
    'Scott Helman',
    'Shawn Hook',
    'Terra Lightfoot',
    'The Franklin Electric',
    'The Glorious Sons',
    'The Jerry Cans',
    'The Washboard Union',
    'U.S.S.',
    'Virginia To Vegas',
    'Young Galaxy',
  ],
  '66': [
    'Alan Doyle',
    'Alvvays',
    'Angele Dubeau &amp; La Pieta',
    'A Tribe Called Red',
    'Bahamas',
    'Ben Caplan',
    'Boogat',
    'Born Ruffians',
    'Braids',
    'Brett Kissel',
    'Charlotte Cardin',
    'Charlotte Day Wilson',
    'Daniel Caesar',
    'David Myles',
    'Dear Rouge',
    'Donovan Woods',
    'Fortunate Ones',
    'Geoffroy',
    'Gord Bamford',
    'Hunter Brothers',
    'James Barker Band',
    'Japandroids',
    'Jean-Michel Blais',
    'Jerry Granelli',
    'Jesse Roper',
    'Jessie Reyez',
    'Jon and Roy',
    'Lemon Bucket Orkestra',
    'Lindi Ortega',
    'Lisa LeBlanc',
    'Matt Holubowski',
    'Milk &amp; Bone',
    'Mo Kenney',
    'Neon Dreams',
    'Pierre Kwenders',
    'Reuben and the Dark',
    'Rhye',
    'Ria Mae',
    'Royal Wood',
    'Scott Helman',
    'SonReal',
    'Splash N Boots',
    'Terra Lightfoot',
    'The Dead South',
    'The Glorious Sons',
    'The Harpoonist &amp; The Axe Murderer',
    'The Rural Alberta Advantage',
    'The Weather Station',
    'Tim Hicks',
    'Virginia To Vegas',
    'Young Galaxy',
    'Yukon Blonde',
  ],
  '67': [
    'Alexandre Da Costa',
    'Allan Rayman',
    'Allie X',
    'Arkells',
    'A Tribe Called Red',
    'Bears of Legend',
    'Beyries',
    'Born Ruffians',
    'Charlotte Cardin',
    'Charlotte Day Wilson',
    'Chromeo',
    'Dead Obies',
    'Dear Rouge',
    'Death From Above 1979',
    'Donovan Woods',
    'Ellen Doty',
    'Fortunate Ones',
    'Frazey Ford',
    'Geoffroy',
    'James Barker Band',
    'Jazz Cartier',
    'Jean-Michel Blais',
    'Jennifer Castle',
    'Jesse Cook',
    'Jessie Reyez',
    'Jordan Officer',
    'Joshua Hyslop',
    'Killy',
    'Leif Vollebekk',
    'Lindi Ortega',
    'Lisa LeBlanc',
    'Matt Holubowski',
    'Matt Mays',
    'Metz',
    'Milk &amp; Bone',
    'Mo Kenney',
    'Monkeyjunk',
    'Neon Dreams',
    'New City',
    'One Bad Son',
    'Pierre Kwenders',
    'Port Cities',
    'Reuben and the Dark',
    'Rhye',
    'Ria Mae',
    'Rose Cousins',
    'Scott Helman',
    'Shred Kelly',
    'Steve Hill',
    'Terra Lightfoot',
    'The Brothers Landreth',
    'The Dead South',
    'The Elwins',
    'The Glorious Sons',
    'The Jerry Cans',
    'The Weather Station',
    'Tim Neufeld',
    'Wes Mack',
    'Yukon Blonde',
  ],
  '68': [
    'A Tribe Called Red',
    'Ahi',
    'Alan Doyle',
    'Allan Rayman',
    'Andy Shauf',
    'Bulow',
    'Charlotte Cardin',
    'Charlotte Day Wilson',
    'Chilly Gonzales',
    'Dan Davidson',
    'David Myles',
    'Dear Rouge',
    'Donovan Woods',
    'Ellen Doty',
    'Elliot Maginot',
    'Geoffroy',
    'Gord Bamford',
    'James Barker Band',
    'Jason Benoit',
    'Jean-Michel Blais',
    'Jennifer Castle',
    'Jessie Reyez',
    'Jill Barber',
    'Jimmy Rankin',
    'Joshua Hyslop',
    'Kandle',
    'Killy',
    'Leif Vollebekk',
    'Lisa LeBlanc',
    'Madeline Merlo',
    'Matt Holubowski',
    'Matt Mays',
    'Meghan Patrick',
    'Milk &amp; Bone',
    'Neon Dreams',
    'Old Man Luedecke',
    'One Bad Son',
    'Pierre Kwenders',
    'Port Cities',
    'Pup',
    'Ralph',
    'Reuben and the Dark',
    'Ria Mae',
    'Robotaki',
    'Rose Cousins',
    'Royal Canoe',
    'Royal Wood',
    'Scott Helman',
    'Shad',
    'Splash N Boots',
    'Tanya Tagaq',
    'The Beaches',
    'The Dead South',
    'The Dirty Nil',
    'The Glorious Sons',
    'The Harpoonist &amp; The Axe Murderer',
    'The Jerry Cans',
    'The Reklaws',
    'The Weather Station',
    'The Zolas',
    'Tim Neufeld',
    'Tyler Shaw',
    'U.S.S.',
    'Wild Rivers',
    'Yukon Blonde',
  ],
  '69': [
    'Aaron Goodvin',
    'Aaron Pritchett',
    'Ahi',
    'Alan Doyle',
    'Alexandra Streliski',
    'Allan Rayman',
    'Arkells',
    'A Tribe Called Red',
    'Bears of Legend',
    'Beyries',
    'Dizzy',
    'Donovan Woods',
    'Elisapie Isaac',
    'Elliot Maginot',
    'Emilie &amp; Ogden',
    'Fjord',
    'Hunter Brothers',
    'Jazz Cartier',
    'Jean-Michel Blais',
    'Jennifer Castle',
    'Jeremy Dutcher',
    'Jess Moskaluke',
    'Jesse Cook',
    'Jesse Roper',
    'Jessica Mitchell',
    'Jessie Reyez',
    'Jon and Roy',
    'Joshua Hyslop',
    'July Talk',
    'Kira Isabella',
    'Lee Harvey Osmond',
    'Lights',
    'Lisa LeBlanc',
    'Milk &amp; Bone',
    'Mother Mother',
    'New City',
    'One Bad Son',
    'Pierre Kwenders',
    'Port Cities',
    'Ralph',
    'Reuben and the Dark',
    'Ria Mae',
    'Rose Cousins',
    'Samantha Martin &amp; Delta Sugar',
    'Scott Helman',
    'SonReal',
    'Splash N Boots',
    'Steve Hill',
    'Tebey',
    'The Color',
    'The Dirty Nil',
    'The Franklin Electric',
    'The Harpoonist &amp; The Axe Murderer',
    'The Reklaws',
    'The Washboard Union',
    'Yukon Blonde',
  ],
  '70': [
    'Aaron Goodvin',
    'Ahi',
    'Alan Doyle',
    'Alex Henry Foster',
    'Alexandra Streliski',
    'Allan Rayman',
    'Allie X',
    'Andy Shauf',
    'Arkells',
    'A Tribe Called Red',
    'Basia Bulat',
    'Ben Caplan',
    'Bia',
    'Dave Gunning',
    'Dead Obies',
    'Dear Rouge',
    'Donovan Woods',
    'Elisapie Isaac',
    'Ellen Doty',
    'Emilie &amp; Ogden',
    'Fortunate Ones',
    'Geoffroy',
    'Hunter Brothers',
    'James Barker Band',
    'Jane Bunnett',
    'Jean-Michel Blais',
    'Jennifer Castle',
    'Jeremy Dutcher',
    'Jordan Officer',
    'Lee Harvey Osmond',
    'Matt Andersen',
    'Matt Holubowski',
    'Milk &amp; Bone',
    'Neon Dreams',
    'Night Lovell',
    'Northern Haze',
    'Pierre Kwenders',
    'Pup',
    'Ralph',
    'Rhye',
    'Robotaki',
    'Rose Cousins',
    'Royal Tusk',
    'Said The Whale',
    'Samantha Martin &amp; Delta Sugar',
    'Scott Helman',
    'SonReal',
    'Splash N Boots',
    'Tanya Tagaq',
    'The Color',
    'The Dead South',
    'The Dirty Nil',
    'The Glorious Sons',
    'The Harpoonist &amp; The Axe Murderer',
    'The Reklaws',
    'The Strumbellas',
    'The Washboard Union',
    'Tim Neufeld',
    'Tops',
    'U.S. Girls',
    'Wild Rivers',
  ],
  '71': [
    'Aaron Goodvin',
    'Ahi',
    'Alan Doyle',
    'Alexandra Streliski',
    'Allie X',
    'Andy Shauf',
    'Arkells',
    'A Tribe Called Red',
    'Bahamas',
    'Bears of Legend',
    'Beyries',
    'Braids',
    'Brett Kissel',
    'Calpurnia',
    'Chad Brownlee',
    'Charlotte Cardin',
    'Charlotte Day Wilson',
    'Chris Buck Band',
    'Daniel Caesar',
    'David Myles',
    'Dead Obies',
    'Dizzy',
    'Dominique Fils-Aime',
    'Elisapie Isaac',
    'Elliot Maginot',
    'Emilie Kahn',
    'Fjord',
    'Half Moon Run',
    'Homeshake',
    'Honors',
    'Hunter Brothers',
    'Intervals',
    'Jade Eagleson',
    'James Barker Band',
    'Jason Benoit',
    'Jean-Michel Blais',
    'Jennifer Castle',
    'Jeremy Dutcher',
    'Jordan Officer',
    'Killy',
    'Kira Isabella',
    'Leif Vollebekk',
    'Mac DeMarco',
    'Madeline Merlo',
    'Matt Andersen',
    'Metz',
    'Milk &amp; Bone',
    'Mo Kenney',
    'Mother Mother',
    'Neon Dreams',
    'Night Lovell',
    'Old Man Luedecke',
    'Pierre Kwenders',
    'Port Cities',
    'Pup',
    'Rhye',
    'Ria Mae',
    'Robotaki',
    'Royal Tusk',
    'Royal Wood',
    'Samantha Martin &amp; Delta Sugar',
    'Scott Helman',
    'SonReal',
    'The Beaches',
    'The Blue Stones',
    'The Color',
    'The Dead South',
    'The Dirty Nil',
    'The East Pointers',
    'The Franklin Electric',
    'The Jerry Cans',
    'The Reklaws',
    'The Wild',
    'Tim Baker',
    'Tops',
    'U.S. Girls',
    'Wes Mack',
    'Wild Rivers',
    'William Prince',
  ],
  '72': [
    'Aaron Goodvin',
    'Ahi',
    'Alexandra Streliski',
    'Allan Rayman',
    'Allie X',
    'Arkells',
    'A Tribe Called Red',
    'Banners',
    'bbno$',
    'Bulow',
    'Chad Brownlee',
    'David James',
    'Dear Rouge',
    'Dominique Fils-Aime',
    'Donovan Woods',
    'Elisapie Isaac',
    'Elliot Maginot',
    'Emilie Kahn',
    'Felix Cartal',
    'Fjord',
    'Fortunate Ones',
    'Frazey Ford',
    'Geoffroy',
    'Half Moon Run',
    'Homeshake',
    'Honors',
    'Hunter Brothers',
    'Jade Eagleson',
    'James Barker Band',
    'Jane Bunnett',
    'Jess Moskaluke',
    'Jessica Mitchell',
    'July Talk',
    'Keys N Krates',
    'Leeroy Stagger',
    'Leif Vollebekk',
    'Metz',
    'Milk &amp; Bone',
    'Neon Dreams',
    'Northern Haze',
    'Pup',
    'Ralph',
    'Reuben and the Dark',
    'Robotaki',
    'Rose Cousins',
    'Royal Tusk',
    'Scott Helman',
    'Single Mothers',
    'Splash N Boots',
    'Tanya Tagaq',
    'Terra Lightfoot',
    'The Beaches',
    'The Bros. Landreth',
    'The Color',
    'The Dead South',
    'The Dirty Nil',
    'The Elwins',
    'The Franklin Electric',
    'The Harpoonist &amp; the Axe Murderer',
    'The Reklaws',
    'The Strumbellas',
    'The Washboard Union',
    'Tim Baker',
    'TWRP',
    'Virginia to Vegas',
    'William Prince',
  ],
  '73': [
    'Aaron Goodvin',
    'Alex Cuba',
    'Alex Henry Foster',
    'Aliocha',
    'Allan Rayman',
    'Allie X',
    'Andy Shauf',
    'Austra',
    'Basia Bulat',
    'bbno$',
    'Bulow',
    'Brett Kissel',
    'Dizzy',
    'Elisapie Isaac',
    'Geoffroy',
    'Hannah Georgas',
    'Jason Benoit',
    'Jean-Michel Blais',
    'Jeremy Dutcher',
    'Jesse Cook',
    'Jesse Roper',
    'Jocelyn Alice',
    'Joel Plaskett',
    'Kaytranada',
    'Keys N Krates',
    'Leeroy Stagger',
    'Leif Vollebekk',
    'Matt Holubowski',
    'Meghan Patrick',
    'MorMor',
    'Neon Dreams',
    'Old Man Luedecke',
    'Ralph',
    'Reuben and the Dark',
    'Ria Mae',
    'Royal Tusk',
    'Samantha Martin &amp; Delta Sugar',
    'Shred Kelly',
    'Single Mothers',
    'Splash N Boots',
    'Tanya Tagaq',
    'Tebey',
    'The Color',
    'The Dirty Nil',
    'The Flatliners',
    'The Glorious Sons',
    'The Jerry Cans',
    'The Washboard Union',
    'Tim Baker',
    'TOPS',
    'U.S. Girls',
    'Virginia to Vegas',
    'William Prince',
  ],
  '74': [
    'Aaron Goodvin',
    'Alan Doyle',
    'Alex Henry Foster',
    'Aliocha',
    'Allan Rayman',
    'Allie X',
    'Andy Shauf',
    'bbno$',
    'Braids',
    'Bulow',
    'Busty and the Bass',
    'Chris Buck Band',
    'David James',
    'Dominique Fils-Aime',
    'Donovan Woods',
    'Elisapie Isaac',
    'Eric Ethridge',
    'Emilie Claire Barlow',
    'Geoffroy',
    'Half Moon Run',
    'Hannah Georgas',
    'Homeshake',
    'Jeremy Dutcher',
    'Jess Moskaluke',
    'Jesse Cook',
    'Jojo Mason',
    'Jon Bryant',
    'Keys N Krates',
    'Lee Harvey Osmond',
    'Matt Holubowski',
    'Matt Lang',
    'Merkules',
    'Metz',
    'Neon Dreams',
    'Ralph',
    'Ria Mae',
    'Robotaki',
    'Rose Cousins',
    'Samantha Martin &amp; Delta Sugar',
    'Sef Lemelin',
    'Shred Kelly',
    'Single Mothers',
    'Snotty Nose Rez Kids',
    'Tanya Tagaq',
    'Tebey',
    'The Beaches',
    'The Blue Stones',
    'The Color',
    'The Dead South',
    'The Franklin Electric',
    'The Harpoonist &amp; The Axe Murderer',
    'The Reklaws',
    'The Wild!',
    'Tim Hicks',
    'Tops',
    'TWRP',
    'U.S. Girls',
    'Wild Rivers',
    'William Prince',
  ],
  '75': [
    'Aaron Goodvin',
    'A Tribe Called Red',
    'bbno$',
    'Braids',
    'Brett Kissel',
    'Busty and the Bass',
    'Chad Brownlee',
    'Chilly Gonzales',
    'Chris Buck Band',
    'Dead Obies',
    'Dizzy',
    'Dominique Fils-Aime',
    'Elisapie Isaac',
    'Geoffroy',
    'Grimes',
    'Half Moon Run',
    'Hannah Georgas',
    'Jade Eagleson',
    'James Barker Band',
    'Jean-Michel Blais',
    'Jeffery Straker',
    'Jeremy Dutcher',
    'Jocelyn Alice',
    'July Talk',
    'Kaytranada',
    'Leif Vollebekk',
    'Neon Dreams',
    'Rose Cousins',
    'Shred Kelly',
    'Single Mothers',
    'Snotty Nose Rez Kids',
    'The Jerry Cans',
    'The Reklaws',
    'The Washboard Union',
    'Tim Baker',
    'Tops',
    'Virginia To Vegas',
    'William Prince',
    'Yukon Blonde',
    'Zeds Dead',
  ],
  '75b': [
    'A Tribe Called Red',
    'Aaron Goodvin',
    'Alan Doyle',
    'Alex Cuba',
    'Alex Henry Foster',
    'Alexandra Streliski',
    'Aliocha',
    'Alvvays',
    'Bahamas',
    'Bbno$',
    'Ben Caplan',
    'Braids',
    'Brett Kissel',
    'Bulow',
    'Dan Davidson',
    'Dominique Fils-Aime',
    'Elisapie Isaac',
    'Eric Ethridge',
    'Geoffroy',
    'Gord Bamford',
    'Half Moon Run',
    'Jage Eagleson',
    'Jane Bunnett',
    'Jason Benoit',
    'Jeremy Dutcher',
    'Jesse Roper',
    'Jon and Roy',
    'Leeroy Stagger',
    'Leif Vollebekk',
    'Madeline Merlo',
    'Matt Andersen',
    'Neon Dreams',
    'Night Lovell',
    'Ralph',
    'Reuben and the Dark',
    'Ria Mae',
    'Robotaki',
    'Rose Cousins',
    'Sef Lemelin',
    'Shred Kelly',
    'Single Mothers',
    'Snotty Nose Rez Kids',
    'SonReal',
    'Splash N Boots',
    'The Beaches',
    'The Color',
    'The Dead South',
    'The Flatliners',
    'The Franklin Electric',
    'The Jerry Cans',
    'The Reklaws',
    'The Wild!',
    'Tim &amp; The Glory Boys',
    'Tops',
    'William Prince',
  ],
  '76': [
    'Aaron Goodvin',
    'AHI',
    'Aliocha',
    'bbno$',
    'Ben Caplan',
    'Bobby Wills',
    'Busty and the Bass',
    'Chad Brownlee',
    'Chris Buck Band',
    'David James',
    'Dear Rouge',
    'Diana Panton',
    'Dizzy',
    'Dominique Fils-Aime',
    'Donovan Woods',
    'Elisapie Isaac',
    'Elise LeGrow',
    'Famba',
    'Geoffroy',
    'Jade Eagleson',
    'James Barker Band',
    'Jason Benoit',
    'Jason Blaine',
    'Jeremy Dutcher',
    'Jess Moskaluke',
    'JJ Shiplett',
    'Jocelyn Alice',
    'Kaytranada',
    'Land of Talk',
    'Leeroy Stagger',
    'Matt Andersen',
    'Matt Holubowski',
    'Matt Lang',
    'Meghan Patrick',
    'Neon Dreams',
    'Ria Mae',
    'Rose Cousins',
    'Sef Lemelin',
    'Serena Ryder',
    'Shred Kelly',
    'Splash N Boots',
    'Tebey',
    'The Color',
    'The Dirty Nil',
    'The Reklaws',
    'Tim Baker',
    'Tim &amp; The Glory Boys',
    'Zeds Dead',
  ],
  '77': [
    'Aaron Goodvin',
    'Aasiva',
    'Adrian Sutherland',
    'AHI',
    'Alan Doyle',
    'Alex Porat',
    'Amanda Rheaume',
    'A Tribe Called Red',
    'bbno$',
    'Beverly Glenn-Copeland',
    'Beyries',
    'Born Ruffians',
    'Carabine',
    'Carmanah',
    'CARYS',
    'Celeigh Cardinal',
    'City Fidelia',
    'Clairmont The Second',
    'cleopatrick',
    'CMDWN',
    'Crystal Shawanda',
    'Dawn Tyler Watson',
    'Dee Daniels',
    'Digging Roots',
    'DJ Shub',
    'Dominique Fils-Aime',
    'Don Amero',
    'DRU',
    'DYLYN',
    'Elisapie Isaac',
    'Felix Cartal',
    'Fortunate Ones',
    'George Canyon',
    'Girl Pow-R',
    'Harrison Kennedy',
    'Haviah Mighty',
    'HERO',
    'Hunnah',
    'Indian City',
    'iskwē',
    'Jade Eagleson',
    'James Barker Band',
    'Jazz Cartier',
    'Jean-Michel Blais',
    'Jeremy Dutcher',
    'Jess Moskaluke',
    'JJ Wilde',
    'Jojo Mason',
    'J Poet',
    'Julian Taylor',
    'Junia-T',
    'Justin Nozuka',
    'Kaia Kater',
    'Kaytranada',
    'Kelly Krow',
    'Keys N Krates',
    'LB Spiffy',
    'Leela Gilday',
    'Leeroy Stagger',
    'Lido Pimienta',
    'Lou Phelps',
    'LU KALA',
    'Lydia Persaud',
    'Manila Grey',
    'Matt Andersen',
    'Meghan Patrick',
    'Melanie Durrant',
    'Milk &amp; Bone',
    'Neon Dreams',
    'Northsidebenji',
    'Nuela Charles',
    'Ocie Elliott',
    'OKAN',
    'Pantayo',
    'Pierre Kwenders',
    'Rajan',
    'Rhye',
    'Ruben Young',
    'Ruby Waters',
    'Ryland James',
    'SATE',
    'Schwey',
    'Scott Helman',
    'Sean Jones',
    'Serena Ryder',
    'Shred Kelly',
    'Silla and Rise',
    'Single Mothers',
    'So Loki',
    'Splash N Boots',
    'Swagger Rite',
    'Sylo Nozra',
    'Tanika Charles',
    'Tara Kannangara',
    'The Blue Stones',
    'The Reklaws',
    'The Washboard Union',
    'The Weather Station',
    'Tim Baker',
    'Tim &amp; The Glory Boys',
    'U.S.S.',
    'Virginia To Vegas',
    'Wasiu',
    'Witch Prophet',
    'Yamatanka//Sonic Titan',
    'Yung Tory',
    'Yves Jarvis',
  ],
  '78': [
    '88GLAM',
    'Aaron Goodvin',
    'AHI',
    'Alee',
    'Alex Henry Foster',
    'Alexandra Stréliski',
    'Bahamas',
    'bbno$',
    'Beyries',
    'Bobby Wills',
    'Chad Brownlee',
    'Chris Buck Band',
    'cleopatrick',
    'CMDWN',
    'David James',
    'Dear Rouge',
    'Dominique Fils-Aime',
    'Eric Ethridge',
    'Famba',
    'Felix Cartal',
    'Jade Eagleson',
    'James Barker Band',
    'Jess Moskaluke',
    'Justin Nozuka',
    'Kayla Diamond',
    'Matt Holubowski',
    'Matt Lang',
    'Neon Dreams',
    'Northsidebenji',
    'Pressa',
    'Ria Mae',
    'Sef Lemelin',
    'Serena Ryder',
    'Shred Kelly',
    'SonReal',
    'Splash N Boots',
    'Tebey',
    'The Blue Stones',
    'The Color',
    'The Dirty Nil',
    'The Reklaws',
    'The Washboard Union',
    'The Weather Station',
    'The Zolas',
    'TWRP',
    'Tyler Joe Miller',
    'Tyler Shaw',
    'Virginia To Vegas',
  ],
  '79': [
    'Aaron Goodvin',
    'AHI',
    'Alex Cuba',
    'a&nbsp;l&nbsp;l&nbsp;i&nbsp;e',
    'Allison Au',
    'Ammoye',
    'Aniara’s Dream',
    'Armond Duck Chief',
    'AR Paisley',
    'A Tribe Called Red',
    'BADBADNOTGOOD',
    'Battle of Santiago',
    'bbno$',
    'Beatrice Deer',
    'Beyries',
    'Bryden Gwiss',
    'Burna Bandz',
    'Cadence Weapon',
    'Celeigh Cardinal',
    'Chad Brownlee',
    'Chad Price',
    'Charlotte Cardin',
    'cleopatrick',
    'Crown Lands',
    'Dabin',
    'Deep Jandu',
    'Derek Miller',
    'Digawolf',
    'DillanPonders',
    'Dominique Fils-Aime',
    'Don Amero',
    'DUVY',
    'Dylan Sinclair',
    'Elisapie Isaac',
    'Elko',
    'Emmanuel Jal',
    'Eric Ethridge',
    'Eric Reprid',
    'Exco Levi',
    'Geoffroy',
    'Ginalina',
    'Guerilla-Ha!',
    'Harrison',
    'Haviah Mighty',
    'Homeshake',
    'IAMTHELIVING',
    'Jade Eagleson',
    'JAHKOY',
    'James Barker Band',
    'Jason Benoit',
    'Jeffery Straker',
    'Jeremie Albino',
    'Jeremy Dutcher',
    'Jerusalem In My Heart',
    'Jess Moskaluke',
    'JJ Wilde',
    'Joël',
    'Joey Stylez',
    'Jon and Roy',
    'JYDN',
    'Kelly Krow',
    'Kennedy Rd.',
    'Kenny “Blues Boss” Wayne',
    'Keys N Krates',
    'Land of Talk',
    'Lococity',
    'LØLØ',
    'Lorraine Klaasen',
    'Meghan Patrick',
    'Mustafa',
    'Neon Dreams',
    'Northern Cree',
    'Northsidebenji',
    'Once A Tree',
    'Pierre Kwenders',
    'Plaza',
    'Puffy L’z',
    'Raj Ramayya',
    'Robotaki',
    'Ruben Young',
    'Ruby Waters',
    'Savannah Re',
    'Shad',
    'Shantel May',
    'Shred Kelly',
    'SLMN',
    'Snotty Nose Rez Kids',
    'Splash N Boots',
    'STORRY',
    'Terrell Safadi',
    'Terrell Morris',
    'Terry Uyarak',
    'The Beaches',
    'The Color',
    'The Dirty Nil',
    'The OBGMs',
    'The Washboard Union',
    'The Weather Station',
    'Tops',
    'Twin Flames',
    'Tyler Joe Miller',
    'Virginia To Vegas',
    'Witch Prophet',
    'William Prince',
    'YSN Fab',
    'Zeds Dead',
  ],
  '80': [
    'Aaron Goodvin',
    'Adria Kain',
    'AHI',
    'Alex Henry Foster',
    'Alex Porat',
    'Andy Shauf',
    'BANNERS',
    'bbno$',
    'Beyries',
    'Brett Kissel',
    'BROS',
    'Burna Bandz',
    'CARYS',
    'Cat Clyde',
    'Charlotte Cardin',
    'Crack Cloud',
    'David James',
    'Dear Rouge',
    'Dizzy',
    'DJ Shub',
    'Dominique Fils-Aime',
    'EverythingOShauN',
    'Fast Romantics',
    'Felix Cartal',
    'Friyie',
    'Geoffroy',
    'Hanorah',
    'Indian City',
    'J. Hutton',
    'Jade Eagleson',
    'Jane Bunnett',
    'Jayd Ink',
    'Jeremie Albino',
    'Jeremy Sean Hector',
    'Jess Moskaluke',
    'JJ Wilde',
    'Jonathan Roy',
    'Julian Taylor',
    'July Talk',
    'K. Forest',
    'Keys N Krates',
    'Khanvict',
    'Liza',
    'Lou Phelps',
    'Lou Val',
    'Luca Fogale',
    'Manila Grey',
    'Meghan Patrick',
    'Mother Mother',
    'Mustafa',
    'Naya Ali',
    'Night Lovell',
    'Northsidebenji',
    'Nuela Charles',
    'Ocie Elliott',
    'Petraa',
    'Pressa',
    'RALPH',
    'Ramriddlz',
    'Ria Mae',
    'Rochester',
    'Royal Tusk',
    'Ruby Waters',
    'Sacha',
    'Samantha Martin &amp; Delta Sugar',
    'Shred Kelly',
    'Snotty Nose Rez Kids',
    'Sue Foley',
    'The Beaches',
    'The Dead South',
    'The Dirty Nil',
    'The Washboard Union',
    'The Weather Station',
    'Tim &amp; the Glory Boys',
    'Tommy Genesis',
    'TwoTiime',
    'TWRP',
    'Tyler Joe Miller',
    'Valley',
    'Wild Rivers',
    'William Prince',
    'Young Bombs',
    'Zoon',
  ],
  '81': [
    'Aaron Goodvin',
    'AHI',
    'Alan Doyle',
    'Alicia Moffet',
    'Allie X',
    'bbno$',
    'Beyries',
    'Carmanah',
    'Charlotte Cardin',
    'Charlotte Day Wilson',
    'Chilly Gonzales',
    'Cleopatrick',
    'Dominique Fils-Aime',
    'Donovan Woods',
    'Eric Ethridge',
    'Fortunate Ones',
    'Homeshake',
    'Jean-Michel Blais',
    'Jeremie Albino',
    'Jess Moskaluke',
    'JJ Shiplett',
    'JJ Wilde',
    'Jon &amp; Roy',
    'Julian Taylor',
    'July Talk',
    'Land of Talk',
    'Leeroy Stagger',
    'Leif Vollebekk',
    'Lido Pimienta',
    'Martin Kerr',
    'Matt Andersen',
    'Nate Haller',
    'Neon Dreams',
    'Plaza',
    'Rose Cousins',
    'Samantha Martin &amp; Delta Sugar',
    'Sue Foley',
    'Tebey',
    'The Dead South',
    'The Flatliners',
    'The Halluci Nation',
    'The Harpoonist &amp; The Axe Murderer',
    'The Washboard Union',
    'The Weather Station',
    'Tim &amp; the Glory Boys',
    'TOPS',
    'TWRP',
    'U.S.S.',
    'Valley',
    'William Prince',
  ],
  '82': [
    '3MFrench',
    '88GLAM',
    'Aaron Goodvin',
    'Adria Kain',
    'Adrian Sutherland',
    'AHI',
    'Akintoye',
    'Alex Henry Foster',
    'Alex Porat',
    'Allan Rayman',
    'Allie X',
    'Amaal',
    'anders',
    'Andy Shauf',
    'Angele Dubeau &amp; La Pieta',
    'Aniara’s Dream',
    'ari hicks',
    'AR Paisley',
    'Bahamas',
    'BANNERS',
    'bbno$',
    'Beverly Glenn-Copeland',
    'Beyries',
    'Born Ruffians',
    'Bryden Gwiss',
    'Bulow',
    'Celeigh Cardinal',
    'Charlotte Cardin',
    'Charlotte Day Wilson',
    'Charmaine',
    'cleopatrick',
    'CRi',
    'Dabin',
    'Dear Rouge',
    'Dee Daniels',
    'Deuce Fantastick',
    'DijahSB',
    'Dominique Fils-Aime',
    'Don Amero',
    'Dylan Joshua',
    'Dylan Sinclair',
    'Elko',
    'Exmiranda',
    'Fame Holiday',
    'Felix Cartal',
    'Geoffroy',
    'Ginalina',
    'Girl Pow-R',
    'Guerilla-Ha!',
    'Hank Mccoy',
    'Haviah Mighty',
    'James Barker Band',
    'Jason Benoit',
    'Jayd Ink',
    'Jean-Michel Blais',
    'Jeremie Albino',
    'Jess Moskaluke',
    'Jon &amp; Roy',
    'J Poet',
    'Julian Taylor',
    'JYDN Hill',
    'K-Cuzz',
    'Kennedy Rd.',
    'K. Forest',
    'Kobo Town',
    'KTOE',
    'Land of Talk',
    'Leif Vollebekk',
    'Lido Pimienta',
    'Lil Berete',
    'Lorraine Klaasen',
    'Luca Fogale',
    'Lucid afterlife',
    'LU KALA',
    'Luna Li',
    'Lyle Kam',
    'Maurice Moore',
    'Merkules',
    'Monowhales',
    'Neon Dreams',
    'Night Lovell',
    'Nuela Charles',
    'Ocie Elliott',
    'OKAN',
    'Pierre Kwenders',
    'Primo Green',
    'PUP',
    'RAAHiiM',
    'Rajan',
    'Ramriddlz',
    'Roney',
    'S4G4',
    'Sacha',
    'Savannah Ré',
    'Sean Jones',
    'Sef Lemelin',
    'Shopé',
    'Shred Kelly',
    'Sirreal',
    'Snotty Nose Rez Kids',
    'Splash N Boots',
    'Status/Non-Status',
    'Swisha T',
    'Tanika Charles',
    'Tanya Tagaq',
    'Tebey',
    'Terell Safadi',
    'The Color',
    'The Dead South',
    'The OBGMs',
    'The Washboard Union',
    'The Weather Station',
    'Tim Hicks',
    'TOPS',
    'TwoTiime',
    'TWRP',
    'Valley',
    'Wasiu',
    'WE ARE FURY Music Inc.',
    'Wild Rivers',
    'William Prince',
    'Witch Prophet',
    'Yung Tory',
    'Zach Zoya',
    'Zenesoul',
    'Zoon',
  ],
  '83': [
    'Aaron Allen',
    'Aaron Goodvin',
    'Abigail Lapell',
    'AHI',
    'Alan Doyle',
    'Alex Henry Foster',
    'Alicia Moffet',
    'Allan Rayman',
    'Anders',
    'Andy Shauf',
    'Apashe',
    'bbno$',
    'Busty and the Bass',
    'Cleopatrick',
    'CRi',
    'David James',
    'Dear Rouge',
    'Delaney Jane',
    'Dizzy',
    'Dominique Fils-Aime',
    'Don Amero',
    'Donovan Woods',
    'Felix Cartal',
    'Jade Eagleson',
    'James Barker Band',
    'Jean-Michel Blais',
    'Jeremie Albino',
    'Jess Moskaluke',
    'JJ Wilde',
    'Jojo Mason',
    'Julian Taylor',
    'July Talk',
    'Leif Vollebekk',
    'LOLO',
    'Louis-Etienne Santais',
    'Luca Fogale',
    'Manila Grey',
    'Matt Andersen',
    'Meghan Patrick',
    'Monowhales',
    'Mother Mother',
    'Nate Haller',
    'Neon Dreams',
    'Pressa',
    'Ruby Waters',
    'Sacha',
    'Sef Lemelin',
    'Shred Kelly',
    'Single Mothers',
    'Snotty Nose Rez Kids',
    'Terra Lightfoot',
    'The Beaches',
    'The Flatliners',
    'The Halluci Nation',
    'The Reklaws',
    'The Washboard Union',
    'The Weather Station',
    'Tim &amp; the Glory Boys',
    'Tim Baker',
    'TOPS',
    'Tyler Joe Miller',
    'Valley',
    'Virginia to Vegas',
    'Wild Rivers',
    'Young Bombs',
  ],
  '84': [
    '2Kz',
    '80purpp',
    'AHI',
    'Alex Porat',
    'Allan Rayman',
    'a l l I e',
    'Apashe',
    'August Rigo',
    'Backxwash',
    'bbno$',
    'Beny Esguerra',
    'Blue Moon Marquee',
    'Bolu Ajibade',
    'Boslen',
    'Burna Bandz',
    'Carmanah',
    'Cat Clyde',
    'Cerena',
    'Charlotte Cardin',
    'Clairmont the Second',
    'CMagic5',
    'CRi',
    'Dan Davidson',
    'David James',
    'DAX',
    'Dear Rouge',
    'Delon Dior',
    'Desiree Dawson',
    'Digawolf',
    'DillanPonders',
    'DJ Charlie B',
    'DJ Shub',
    'Dominique Fils-Aime',
    'Don Amero',
    'DYLYN',
    'Edwin Raphael',
    'Emmanuel Jal',
    'Erez Zobary',
    'Eric Ethridge',
    'Eric Reprid',
    'Exco Levi',
    'Fortunate Ones',
    'Garth Prince',
    'Haley Smalls',
    'Hanorah',
    'IAMTHELIVING',
    'Indian City',
    'iskwe',
    'J Hutton',
    'J Soul',
    'Jade Eagleson',
    'JAHKOY',
    'Jayli Wolf',
    'Jean-Michel Blais',
    'Jennifer Lewin',
    'Jeremie Albino',
    'Jeremy Dutcher',
    'Jess Moskaluke',
    'JJ Wilde',
    'Joey Stylez',
    'Jordan Astra',
    'Julian Taylor',
    'Junia-T',
    'JV',
    'K Money',
    'Kairo McLean',
    'Kam Prada',
    'Kavale',
    'Kay Bandz',
    'KHEM',
    'Kid Riz',
    'KILLY',
    'Leela Gilday',
    'Lengaia Salsa Brava',
    'Lido Pimienta',
    'Liza',
    'LOONY',
    'Lou Phelps',
    'Lou Val',
    'MAG$',
    'Men I Trust',
    'Myles Lloyd',
    'Nate Haller',
    'Neon Dreams',
    'Night Lovell',
    'NoJoke Jigsaw',
    'Northsidebenji',
    'Ocie Elliott',
    'Ombiigizi',
    'REZZ',
    'Ria Mae',
    'Road Runner',
    'Robin Banks',
    'Rochelle Jordan',
    'Rochester',
    'Ryan Ofei',
    'Sacha',
    'Sage Harris',
    'Samantha Martin &amp; Delta Sugar',
    'Sean Leon',
    'SHALLI',
    'Shawnee Kish',
    'Slim Dinero',
    'Snotty Nose Rez Kids',
    'Splash N Boots',
    'STORRY',
    'Tasha Angela',
    'Tebey',
    'Terry Uyarak',
    'The Beaches',
    'The Color',
    'The Dead South',
    'The OBGMs',
    'The Reklaws',
    'Tim Baker',
    'Tim Hicks',
    'TOPS',
    'Twin Flames',
    'TWRP',
    'Tyler Joe Miller',
    'Tyler Shaw',
    'Virginia to Vegas',
    'Why G',
    'Wild Rivers',
    'William Prince',
    'YSN Fab',
    'Zoon',
  ],
  '85': [
    'Aaron Goodvin',
    'Alan Doyle',
    'Alicia Moffet',
    'Apashe',
    'BANNERS',
    'Billy Raffoul',
    'Carmanah',
    'Cat Clyde',
    'Charles-Richard Hamelin',
    'Coleman Hell',
    'CRi',
    'Don Amero',
    'Geoffroy',
    'Jeremie Albino',
    'Jesse Roper',
    'Jonathan Roy',
    'Jon Bryant',
    'Julian Taylor',
    'July Talk',
    'LOLO',
    'Louis-Etienne Santais',
    'Luca Fogale',
    'Merkules',
    'Milk &amp; Bone',
    'Mother Mother',
    'Nate Haller',
    'Neon Dreams',
    'Ocie Elliott',
    'Pelch',
    'Ruby Waters',
    'Sacha',
    'Splash N Boots',
    'The Dead South',
    'Twin Flames',
    'U.S. Girls',
    'Valley',
    'William Prince',
    'Zach Zoya',
    'Zoon',
  ],
  '86': [
    'Aaron Goodvin',
    'Afta Hill',
    'Alex Henry Foster',
    'Alex Porat',
    'Allan Rayman',
    'Altameda',
    'Alysha Brilla',
    'Amaal',
    'Amanda Rheaume',
    'Angelique Francis',
    'Apashe',
    'Aquakultre',
    'ari hicks',
    'Ash',
    'August Rigo',
    'Aysanabee',
    'B.ROB',
    'Battle of Santiago',
    'Beatchild',
    'Beverly Glenn-Copeland',
    'Billy Raffoul',
    'Blue Moon Marquee',
    'Boy Golden',
    'Bridge Music',
    'Carmanah',
    'Cat Clyde',
    'Cerena',
    'Clay and Friends',
    'Connor Price',
    'CRi',
    'Da Crook',
    'Dear Rouge',
    'Dee Daniels',
    'Digging Roots',
    'Dizzy',
    'Dominique Fils-Aime',
    'Donovan Woods',
    'Dylan Joshua',
    'Dylan Sinclair',
    'Ekali',
    'ELIO',
    'Erez Zobary',
    'EverythingOShauN',
    'Exmiranda',
    'Felix Cartal',
    'Flore Laurentienne',
    'Fortunate Ones',
    'G Milla',
    'Geoffroy',
    'Ginalina',
    'Girl Pow-R',
    'Harrison Kennedy',
    'Ikky',
    'iskwe',
    'Jack Trades',
    'Jade Eagleson',
    'Jean-Michel Blais',
    'Jeremie Albino',
    'Jhyve',
    'Joéllen',
    'Jonah Yano',
    'Jonathan Roy',
    'Jordan Astra',
    'Julian Taylor',
    'July Talk',
    'Justin OH',
    'JYDN HILL',
    'K Forest',
    'K-Cuzz',
    'Kaia Kater',
    'Kam Prada',
    'Katherine Li',
    'Kennedy Road',
    'Kimezaa',
    'Kris The $pirit',
    'Leanne Betasamosake Simpson',
    'Legendary Brasstacks',
    'Leonard Sumner',
    'Lil Ot',
    'Loving',
    'Luca Fogale',
    'Luna Elle',
    'Lyle Kam',
    'Matt Holubowski',
    'Merkules',
    'MICO',
    'MKSTN',
    'Monowhales',
    'Nate Haller',
    'Neon Dreams',
    'Night Lovell',
    'noelle',
    'Ocie Elliott',
    'Ombiigizi',
    'Ouri',
    'Preston Pablo',
    'Priyanka',
    'Raj Ramayya',
    'Ramon Chicharron',
    'Ramriddlz',
    'Rare Americans',
    'REEZY FN',
    'REZZ',
    'Ruby Waters',
    'Ryan Ofei',
    'Sacha',
    'Sage Harris',
    'Sara Phillips',
    'Savannah Re',
    'Sean Jones',
    'Sean Leon',
    'Shawn Austin',
    'Shawnee Kish',
    'Shred Kelly',
    'Snotty Nose Rez Kids',
    'SonReal',
    'Status/Non-Status',
    'Steven Lee Olsen',
    'STORRY',
    'Sykamore',
    'Sylo Nozra',
    'Tanika Charles',
    'Tasha Angela',
    'Telmary',
    'Terell Safadi',
    'Terrell Morris',
    'Terry Uyarak',
    'The Beaches',
    'The Dirty Nil',
    'The Washboard Union',
    'The Weather Station',
    'THEHONESTGUY',
    'Tim &amp; the Glory Boys',
    'Tim Baker',
    'TWRP',
    'Tyler Joe Miller',
    'Virginia to Vegas',
    'William Prince',
    'Wolf Saga',
    'XL The Band',
    'Zenesoul',
    'Zoon',
  ],
  '87': [
    'Aaron Allen',
    'Aaron Goodvin',
    'Abigail Lapell',
    'Alexandra Streliski',
    'Alex Porat',
    'Alvvays',
    'Apashe',
    'Aysanabee',
    'bbno$',
    'Billy Raffoul',
    'Boy Golden',
    'Busty and the Bass',
    'Charles-Richard Hamelin',
    'Clay and Friends',
    'cleopatrick',
    'CRi',
    'Dizzy',
    'DJ Shub',
    'Dominique Fils-Aime',
    'Don Amero',
    'elijah woods',
    'Elisapie Isaac',
    'Eric Ethridge',
    'Felix Cartal',
    'Flore Laurentienne',
    'Geoffroy',
    'Good Kid',
    'Gord Bamford',
    'Jade Eagleson',
    'James Barker Band',
    'Jean-Michel Blais',
    'Jeremie Albino',
    'Jess Moskaluke',
    'Jonathan Roy',
    'Josh Ross',
    'Julian Taylor',
    'Lido Pimienta',
    'LOLO',
    'LOONY',
    'Manila Grey',
    'Matt Holubowski',
    'Mindflip',
    'Monowhales',
    'Nate Haller',
    'Night Lovell',
    'Northern Haze',
    'Ocie Elliott',
    'Ollie',
    'Pelch',
    'REZZ',
    'Ruby Waters',
    'Sacha',
    'Samantha Martin &amp; Delta Sugar',
    'Shawn Austin',
    'Snotty Nose Rez Kids',
    'Steven Lee Olsen',
    'Sykamore',
    'TALK',
    'Tep No',
    'Terry Uyarak',
    'The Beaches',
    'The Dirty Nil',
    'The Washboard Union',
    'Tim &amp; the Glory Boys',
    'TOPS',
    'Tyler Joe Miller',
    'Vandelux',
    'Wild Rivers',
    'Zach Zoya',
    'Zoon',
  ],
  '88': [
    '2Kz',
    'Aaron Allen',
    'Aaron Goodvin',
    'Alicia Moffet',
    'Ammoye',
    'Andy Shauf',
    'Apashe',
    'Bahamas',
    'Benita',
    'Billy Raffoul',
    'Bolu Ajibade',
    'Boyfrn',
    'Boy Golden',
    'Brett Kissel',
    'Busty and the Bass',
    'CARYS',
    'Cat Clyde',
    'Chad Price',
    'Charles-Richard Hamelin',
    'Charlotte Cardin',
    'Clay and Friends',
    'Clerel',
    'CMAGIC5',
    'CRi',
    'DEBBY FRIDAY',
    'Delon Dior',
    'DeLorean Black',
    'DijahSB',
    'Dominique Fils-Aime',
    'Don Amero',
    'Donovan Woods',
    'dvsn',
    'Edwin Raphael',
    'Ekali',
    'elijah woods',
    'ELIO',
    'Elisapie Isaac',
    'Eric Ryan',
    'Ethan Low',
    'Five Roses',
    'Flore Laurentienne',
    'Fortunate Ones',
    'Gayance',
    'Good Kid',
    'Hanorah',
    'Hotel Mira',
    'IDMAN',
    'Jayda G',
    'Jennifer Lewin',
    'Jeremie Albino',
    'Jess Moskaluke',
    'Jesse Ryan',
    'Jonathan Roy',
    'J Poet',
    'JV (Jon Vinyl)',
    'Khanvict',
    'Kid Riz',
    'KILLY',
    'Koven Wei',
    'Kresnt',
    'Kyle McKearney',
    'Lido Pimienta',
    'Lil Berete',
    'Liza',
    'LOLO',
    'Loving',
    'MAG$',
    'Majo',
    'Merkules',
    'Mikayla Geier',
    'Mindflip',
    'Monowhales',
    'Moonshine',
    'Nate Haller',
    'Nemahsis',
    'Neon Dreams',
    'Nonso Amadi',
    'Ocie Elliott',
    'Paris Richards',
    'Pressa',
    'RAAHiiM',
    'Ralph',
    'Road Runner',
    'Rochelle Jordan',
    'Rochester',
    'Rohan',
    'Roy Woods',
    'Ruby Waters',
    'Sacha',
    'SABAI',
    'Savannah Ré',
    'Snotty Nose Rez Kids',
    'Swisha T',
    'Tafari Anthony',
    'TALK',
    'Tebey',
    'Tedy',
    'Tei Shi',
    'TEKE::TEKE',
    'Tep No',
    'The Beaches',
    'The Washboard Union',
    'Tim Baker',
    'Tim Hicks',
    'Tim &amp; the Glory Boys',
    'TOPS',
    'TVGUCCI',
    'Tyler Joe Miller',
    'Valley',
    'Vandelux',
    'WE ARE FURY',
    'Wild Rivers',
    'William Prince',
    'Young Bombs',
  ],
  '89': [
    'Aaron Pritchett',
    'Alexandra Streliski',
    'Alex Henry Foster',
    'Alex Porat',
    'Allie X',
    'Alvvays',
    'Apashe',
    'Ashley Sienna',
    'Aysanabee',
    'Billy Raffoul',
    'Boy Golden',
    'Busty and the Bass',
    'Cat Clyde',
    'Charles-Richard Hamelin',
    'CRi',
    'Dominique Fils-Aime',
    'Elisapie Isaac',
    'Felix Cartal',
    'Flore Laurentienne',
    'Fortunate Ones',
    'Good Kid',
    'Hotel Mira',
    'Hunter Brothers',
    'Ikky',
    'Jade Eagleson',
    'Jeremie Albino',
    'Joshua Hyslop',
    'Julian Taylor',
    'KILLY',
    'LOLO',
    'Majid Jordan',
    'Matt Holubowski',
    'Michael Bernard Fitzgerald',
    'Mindflip',
    'Monowhales',
    'MorMor',
    'Ocie Elliott',
    'Pelch',
    'Pressa',
    'Robyn Ottolini',
    'Roy Woods',
    'SABAI',
    'Sacha',
    'Steven Lee Olsen',
    'Tep No',
    'Terry Uyarak',
    'The Beaches',
    'The Dead South',
    'The Prairie States',
    'Tim Baker',
    'Vandelux',
    'Virginia To Vegas',
    'Why G',
    'William Prince',
    'Zoon',
  ],
};
