import { DirectDepositsMachineState } from '../../machines/ClaimFunds/DirectDepositsMachine';
import {
  DirectDepositMachineSender,
  DirectDepositMachineState,
} from '../../machines/ClaimFunds/DirectDepositMachine';
import {
  ApplicationStage,
  ApplicationType,
  InsertDirectDepositAndLinkToApplicationType,
} from '../../graphql/operations';
import { match } from 'ts-pattern';
import { Button } from '../Button';

import { SetDepositInfoController } from './SetDepositInfoController';
import { SetDepositInfoMachineActor } from '../../machines/ClaimFunds/SetDepositInfoMachine';
import { DirectDepositAccountPreview } from './DirectDepositAccountPreview';
import { formatDirectDepositNumbers } from './utils';
import { UpsertPaymentController } from './UpsertPaymentController';
import { UpsertPaymentMachineActor } from '../../machines/ClaimFunds/UpsertPaymentMachine';
import { hasSubmittedClaims } from '../../utils/applicationStages';
import styles from './ClaimFundsView.module.scss';

interface Props {
  depositInfoType: InsertDirectDepositAndLinkToApplicationType;
  isOwner: boolean;
  directDepositsState: DirectDepositsMachineState;
  directDepositState: DirectDepositMachineState;
  directDepositSend: DirectDepositMachineSender;
  stage: ApplicationStage;
  claimedTotal: number;
}

export function DepositInfoView({
  depositInfoType,
  isOwner,
  directDepositsState,
  directDepositState,
  directDepositSend,
  stage,
  claimedTotal,
}: Props) {
  const { applicationType } = directDepositsState.context;

  const depositTypeAlias = match({
    applicationType,
    depositInfoType,
  })
    .with(
      {
        depositInfoType: InsertDirectDepositAndLinkToApplicationType.Touring,
      },
      () => 'Tour Deposit'
    )
    .with(
      {
        applicationType: ApplicationType.Core,
        depositInfoType:
          InsertDirectDepositAndLinkToApplicationType.FundingRequest,
      },
      () => 'Digital Content Deposit'
    )
    .with({ applicationType: ApplicationType.Orion }, () => 'Direct Deposit')
    .exhaustive();

  return (
    <>
      {!directDepositState.children.setDepositMachine &&
        !directDepositState.context.directDepositAccount &&
        isOwner && (
          <Button
            className={styles.buttonWrapper}
            variant="addnew"
            label={`Set ${depositTypeAlias} Info`}
            onClick={() => directDepositSend({ type: 'SET_DEPOSIT_INFO' })}
          />
        )}

      {!directDepositState.context.directDepositAccount &&
        directDepositState.children.setDepositMachine && (
          <div className={styles.formWrapper}>
            <SetDepositInfoController
              actor={
                directDepositState.children
                  .setDepositMachine as SetDepositInfoMachineActor
              }
            />
          </div>
        )}

      {directDepositState.context.directDepositAccount && (
        <div className={styles.previewWrapper}>
          <DirectDepositAccountPreview
            isAdmin={directDepositState.context.isAdmin}
            title={formatDirectDepositNumbers(
              directDepositState.context.directDepositAccount
            )}
            approvedAmount={
              depositInfoType ===
              InsertDirectDepositAndLinkToApplicationType.Touring
                ? directDepositsState.context.approvedTotalForTourDates
                : directDepositsState.context.approvedTotalForFundingRequests
            }
            totalPaid={directDepositState.context.directDepositAccount.paid}
            showClaimed={hasSubmittedClaims(stage)}
            claimedTotal={claimedTotal}
            payments={directDepositState.context.directDepositAccount.payments}
            paymentRefs={directDepositState.context.paymentRefs}
            note={`${depositTypeAlias} Info`}
            showChangeButton={match(stage)
              .with(
                ApplicationStage.ContractAndDirectDepositPending,
                ApplicationStage.ContractAndDirectDepositUploaded,
                () => true
              )
              .otherwise(() => false)}
            onChange={() => directDepositSend({ type: 'SET_DEPOSIT_INFO' })}
            onAddPaymentClick={() => directDepositSend({ type: 'ADD_PAYMENT' })}
          >
            {directDepositState.context.directDepositAccount &&
              directDepositState.children.setDepositMachine && (
                <div>
                  <SetDepositInfoController
                    actor={
                      directDepositState.children
                        .setDepositMachine as SetDepositInfoMachineActor
                    }
                  />
                </div>
              )}

            {directDepositState.children.upsertPaymentMachine && (
              <div>
                <UpsertPaymentController
                  actor={
                    directDepositState.children
                      .upsertPaymentMachine as UpsertPaymentMachineActor
                  }
                />
              </div>
            )}
          </DirectDepositAccountPreview>
        </div>
      )}
    </>
  );
}
