import { GraphQLClient } from 'graphql-request';
import {
  CompleteApplicationDocument,
  CompleteApplicationMutationVariables,
} from '../graphql/operations';

export const createCompleteApplicationPromise =
  (client: GraphQLClient) =>
  ({ applicationId }: CompleteApplicationMutationVariables) =>
    client.request(CompleteApplicationDocument, { applicationId });

export type CompleteApplicationPromise = ReturnType<
  typeof createCompleteApplicationPromise
>;
export type CompleteApplicationPromiseResult = Awaited<
  ReturnType<CompleteApplicationPromise>
>;
