import cn from 'classnames';
import { match } from 'ts-pattern';
import { Loading } from '../../Loading';
import { GrayBox } from '../../GrayBox';
import { Accordion } from '../../Accordion';
import {
  formatZonedDateTime,
  formatZonedTime,
} from '../../../utils/formatZonedDateTime';
import {
  ApplicationReviewMachineState,
  ApplicationReviewMachineSender,
} from '../../../machines/Admin/ApplicationReviewMachine';
import { Link } from '../../Link';
import { DescriptionList } from '../../DescriptionList';
import { PagePagination } from '../../PagePagination';
import { ErrorFeedback } from '../../ErrorFeedback';

import styles from './ApplicationLogs.module.scss';

interface Props {
  state: ApplicationReviewMachineState;
  send: ApplicationReviewMachineSender;
}

export function ApplicationLogs({ state, send }: Props) {
  const { latestLog } = state.context;

  if (!latestLog) {
    return null;
  }

  const { currentPage, logPages, pageTotal } = state.context.logs;
  const currentPageLogs = logPages[currentPage];

  return (
    <div className={styles.container}>
      <Accordion
        id="admin-application-logs"
        className={styles.accordion}
        onToggleOpen={() => {
          send({ type: 'FETCH_LOGS', page: 1 });
        }}
        title={
          <p className={styles.title}>
            Last updated by {latestLog.name ?? 'Starmaker System'}
            <span className={styles.date}>
              {'   '}
              {formatZonedDateTime(latestLog.logTransactionDate)}
            </span>
          </p>
        }
      >
        <div className={styles.accordionChild}>
          {match(state)
            .when(
              () => state.matches('adminLogs.fetching'),
              () => (
                <GrayBox className={styles.loading}>
                  <Loading inline={true} />
                </GrayBox>
              )
            )
            .when(
              () => state.matches('adminLogs.error'),
              () => <ErrorFeedback />
            )
            .otherwise(() => (
              <>
                {currentPageLogs?.map(({ date, logs }) => (
                  <div className={styles.itemContainer} key={date}>
                    <h2 className={styles.dateHeader}>{date}</h2>
                    {logs.map(
                      ({
                        name,
                        logMessage,
                        logAccountId,
                        logTransactionDate,
                      }) => (
                        <Accordion
                          id={logMessage.id}
                          key={logMessage.id}
                          className={cn(
                            styles.accordion,
                            styles.accordionBorder
                          )}
                          headerClassName={styles.itemHeader}
                          contentClassName={styles.expandedContent}
                          title={
                            <h3 className={styles.itemTitle}>
                              {formatZonedTime(logTransactionDate)}
                              {'  '}
                              {logAccountId && name ? (
                                <Link
                                  href={`/account/${logAccountId}`}
                                  className={styles.name}
                                >
                                  {name}
                                </Link>
                              ) : (
                                <span className={styles.systemName}>
                                  Starmaker System
                                </span>
                              )}{' '}
                              {logMessage.title}
                            </h3>
                          }
                          forceOpen={false}
                        >
                          <>
                            {logMessage.item && (
                              <DescriptionList list={logMessage.item} />
                            )}
                            {logMessage.changes &&
                              logMessage.changes.map(({ column, from, to }) => (
                                <DescriptionList
                                  key={logMessage.id + column}
                                  title={`Changed ${column}`}
                                  list={{ From: from, To: to }}
                                  titleClassname={styles.changeTitle}
                                  className={styles.changeContent}
                                />
                              ))}
                          </>
                        </Accordion>
                      )
                    )}
                  </div>
                ))}
              </>
            ))}
          {pageTotal && pageTotal > 1 && (
            <PagePagination
              className={styles.pagination}
              active={currentPage}
              numberOfPages={pageTotal}
              onPageClick={(page) => {
                send({ type: 'FETCH_LOGS', page });
              }}
              shrink={true}
            />
          )}
        </div>
      </Accordion>
    </div>
  );
}
