import { GraphQLClient } from 'graphql-request';
import {
  DeleteArtistEligibilityNoticeDocument,
  DeleteArtistEligibilityNoticeMutation,
} from '../graphql/operations';
import { Context } from '../machines/EligibilityNoticeMachine';

export const isSuccess = ({ result }: DeleteArtistEligibilityNoticeMutation) =>
  !!result?.deletedEligibilityNoticeId;

export const createDeleteEligibilityNoticePromise =
  (client: GraphQLClient) => (context: Context) => {
    return client.request(DeleteArtistEligibilityNoticeDocument, {
      input: {
        artistId: context.artistId,
      },
    });
  };

export type DeleteEligibilityNoticePromise = ReturnType<
  typeof createDeleteEligibilityNoticePromise
>;
export type DeleteEligibilityNoticePromiseResult = Awaited<
  ReturnType<DeleteEligibilityNoticePromise>
>;
