import { assign, EventFrom, Sender, StateFrom, createMachine } from 'xstate';
import { AddBoardMemberPromiseResult } from '../../promises/Admin/createAddBoardMemberPromise';
import { DeleteBoardInvitationPromiseResult } from '../../promises/Admin/createDeleteBoardInvitationPromise';
import { GetBoardMembersPromiseResult } from '../../promises/Admin/createGetBoardMembersPromise';
import { RemoveBoardMemberPromiseResult } from '../../promises/Admin/createRemoveBoardMemberPromise';
import { BoardMember } from '../../schemas/boardMemberSchema';
import { Contact } from '../../schemas/contactSchema';
import { isEmailValid } from '../../utils/isEmailValid';
import { logMachineError } from '../../utils/logError';
import { MachineOptionsWithContextFrom } from '../../utils/MachineOptionsWithContextFrom';
import { CreateBoardMemberInvitationResponseCode } from '../../graphql/operations';

export type Context = {
  boardMembers: BoardMember[];
  pendingMembers: Contact[];
  emailInput: string;
};

type Events =
  | { type: 'OPEN_FORM' }
  | { type: 'CANCEL_FORM' }
  | { type: 'SUBMIT_FORM' }
  | { type: 'SET_EMAIL'; value: string }
  | { type: 'REMOVE_MEMBER'; id: string }
  | { type: 'RESCIND_INVITATION'; email: string };

type Services = {
  getBoardMembersPromise: { data: GetBoardMembersPromiseResult };
  removeBoardMember: { data: RemoveBoardMemberPromiseResult };
  addBoardMember: { data: AddBoardMemberPromiseResult };
  deleteBoardInvitation: { data: DeleteBoardInvitationPromiseResult };
};

export function createContext() {
  return {
    boardMembers: [],
    pendingMembers: [],
    emailInput: '',
  };
}

export const machine = createMachine(
  {
    predictableActionArguments: true,
    tsTypes: {} as import('./BoardMembersMachine.typegen').Typegen0,
    id: 'boardMembers',
    schema: {
      context: {} as Context,
      events: {} as Events,
      services: {} as Services,
    },
    initial: 'loading',
    states: {
      loading: {
        invoke: {
          id: 'getBoardMembersPromise',
          src: 'getBoardMembersPromise',
          onDone: { actions: 'setBoard', target: 'ready' },
          onError: 'error',
        },
      },
      ready: {
        type: 'parallel',
        states: {
          form: {
            initial: 'closedForm',
            states: {
              openForm: {
                initial: 'default',
                states: {
                  default: {},
                  invalid: {},
                  duplicate: {},
                },
                on: {
                  SUBMIT_FORM: [
                    { cond: 'isInvalid', target: '.invalid' },
                    { cond: 'isDuplicate', target: '.duplicate' },
                    { target: 'saving' },
                  ],
                  CANCEL_FORM: { actions: 'resetForm', target: 'closedForm' },
                  SET_EMAIL: { actions: 'setEmail', target: '.default' },
                },
              },
              closedForm: { on: { OPEN_FORM: 'openForm' } },
              saving: {
                invoke: {
                  id: 'addBoardMember',
                  src: 'addBoardMember',
                  onDone: {
                    actions: ['addToMemberList', 'resetForm'],
                    target: 'closedForm',
                  },
                  onError: 'error',
                },
              },
              error: { entry: 'logMachineError' },
            },
          },
          member: {
            initial: 'default',
            states: {
              default: {
                on: {
                  REMOVE_MEMBER: 'deleting',
                  RESCIND_INVITATION: 'uninviting',
                },
              },
              deleting: {
                invoke: {
                  id: 'removeBoardMember',
                  src: 'removeBoardMember',
                  onDone: [
                    {
                      actions: 'removeFromBoardList',
                      target: 'default',
                    },
                  ],
                  onError: '#boardMembers.error',
                },
              },
              uninviting: {
                invoke: {
                  id: 'deleteBoardInvitation',
                  src: 'deleteBoardInvitation',
                  onDone: [
                    {
                      actions: 'removeFromPendingList',
                      target: 'default',
                    },
                  ],
                  onError: '#boardMembers.error',
                },
              },
            },
          },
        },
      },
      error: { entry: 'logMachineError' },
    },
  },
  {
    actions: {
      logMachineError,
      setBoard: assign((_, { data }) => data),
      setEmail: assign((_, { value }) => ({ emailInput: value.trim() })),
      resetForm: assign((_) => ({ emailInput: '' })),
      removeFromBoardList: assign(({ boardMembers }, { data }) => ({
        boardMembers: boardMembers.filter(
          ({ accountId }) => accountId !== data.accountId
        ),
      })),
      removeFromPendingList: assign(({ pendingMembers }, { data }) => ({
        pendingMembers: pendingMembers.filter(
          ({ email }) => email !== data.email
        ),
      })),
      addToMemberList: assign(({ boardMembers, pendingMembers }, { data }) =>
        data.code === CreateBoardMemberInvitationResponseCode.AccountLinked
          ? {
              boardMembers: [
                {
                  email: data.email,
                  accountId: data.accountId,
                  name: data.name,
                },
                ...boardMembers,
              ],
            }
          : { pendingMembers: [{ email: data.email }, ...pendingMembers] }
      ),
    },
    guards: {
      isInvalid: ({ emailInput }) => !isEmailValid(emailInput),
      isDuplicate: ({ emailInput, boardMembers, pendingMembers }) =>
        pendingMembers.some(({ email }) => email === emailInput) ||
        boardMembers.some(({ email }) => email === emailInput),
    },
  }
);

export type BoardMembersMachine = typeof machine;
export type BoardMembersMachineState = StateFrom<BoardMembersMachine>;
export type BoardMembersMachineEvents = EventFrom<BoardMembersMachine>;
export type BoardMembersMachineSender = Sender<BoardMembersMachineEvents>;
export type BoardMembersMachineOptions =
  MachineOptionsWithContextFrom<BoardMembersMachine>;
