import { useActor } from '@xstate/react';
import { ApplicationStage } from '../../graphql/operations';
import { DirectDepositMachineActor } from '../../machines/ClaimFunds/DirectDepositMachine';
import {
  DirectDepositsMachineSender,
  DirectDepositsMachineState,
} from '../../machines/ClaimFunds/DirectDepositsMachine';
import { CollapsedDepositInfoView } from './CollapsedDepositInfoView';

interface Props {
  directDepositsState: DirectDepositsMachineState;
  directDepositsSend: DirectDepositsMachineSender;
  touringDirectDepositActor: DirectDepositMachineActor;
  fundingRequestDirectDepositActor: DirectDepositMachineActor;
  stage: ApplicationStage;
  claimedTotal: number;
}

export function CollapsedDepositInfoController({
  touringDirectDepositActor,
  fundingRequestDirectDepositActor,
  directDepositsState,
  directDepositsSend,
  stage,
  claimedTotal,
}: Props) {
  const [touringDirectDepositState, touringDirectDepositSend] = useActor(
    touringDirectDepositActor
  );
  const [fundingRequestDirectDepositState] = useActor(
    fundingRequestDirectDepositActor
  );

  return (
    <CollapsedDepositInfoView
      stage={stage}
      claimedTotal={claimedTotal}
      directDepositsState={directDepositsState}
      directDepositsSend={directDepositsSend}
      touringDirectDepositState={touringDirectDepositState}
      touringDirectDepositSend={touringDirectDepositSend}
      fundingRequestDirectDepositState={fundingRequestDirectDepositState}
    />
  );
}
