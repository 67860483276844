import { P, match } from 'ts-pattern';
import { InlineFormContainer } from '../InlineFormContainer';
import { InlineFormHeading } from '../InlineFormHeading';
import { FormField } from '../FormField';
import { TextArea } from '../TextArea';
import { TextInput } from '../TextInput';
import {
  TourDateMachineSender,
  TourDateMachineState,
} from '../../machines/components/TourDateMachine';
import { Select } from '../Select';
import { RadioOption } from '../RadioOption';
import { Feedback, FeedbackSize } from '../Feedback';
import { VenueCapacityFeedback } from './VenueCapacityFeedback';
import { formatNumber } from '../../utils/formatNumber';
import { formatCalendarDate } from '../../utils/formatCalendarDate';
import { ErrorFeedback } from '../ErrorFeedback';
import { DatePicker } from '../DatePicker/DatePicker';

import styles from './TourDateView.module.scss';

interface TourDateViewProps {
  state: TourDateMachineState;
  send: TourDateMachineSender;
}

function InvalidRequestFeedback({
  date: { tourDateCountry },
  year,
  tourDateRegions,
  title,
  size,
  children,
}: {
  date: TourDateMachineState['context']['tourDate'];
  year: TourDateMachineState['context']['year'];
  tourDateRegions: TourDateMachineState['context']['tourDateRegions'];
  title?: string;
  size: FeedbackSize;
  children?: React.ReactNode;
}) {
  const region = tourDateRegions.find(
    ({ id }) => id === tourDateCountry?.regionId
  );

  return region !== undefined ? (
    <Feedback title={title} type="warning" size={size}>
      <p className={styles.feedback}>
        You have reached the limit of{' '}
        <span className="body-text-bold">{region.maximumTourDates}</span> tour
        dates for the {region.title} region for{' '}
        <span className="body-text-bold">{year}</span>.
      </p>
      {children}
    </Feedback>
  ) : null;
}

export function TourDateView({ state, send }: TourDateViewProps) {
  const { title, primaryButtonText } = match(state.context)
    .with({ alternate: true, editContext: undefined }, () => ({
      title: 'Add an Alternate Tour Date',
      primaryButtonText: 'Add Alternate Tour Date',
    }))
    .with({ alternate: true, editContext: P._ }, () => ({
      title: 'Edit an Alternate Tour Date',
      primaryButtonText: 'Save Alternate Tour Date',
    }))
    .with({ alternate: false, editContext: undefined }, () => ({
      title: 'Add a Tour Date',
      primaryButtonText: 'Add Tour Date',
    }))
    .with({ alternate: false, editContext: P._ }, () => ({
      title: 'Edit a Tour Date',
      primaryButtonText: 'Save Tour Date',
    }))
    .exhaustive();

  return (
    <InlineFormContainer
      primaryButtonText={primaryButtonText}
      secondaryButtonText="Cancel"
      primaryButtonOnClick={() => send('SAVE_TOUR_DATE')}
      secondaryButtonOnClick={() => send('CLOSE_TOUR_DATE')}
      rawFeedback={match(state.context.tourDate)
        .with(
          {
            tourDateType: {
              minimumTicketedVenueCapacity: P.number,
              maximumTicketedVenueCapacity: P.number,
            },
          },
          ({
            tourDateType: {
              title,
              minimumTicketedVenueCapacity,
              maximumTicketedVenueCapacity,
            },
          }) =>
            state.matches('form.ticketed-venue-capacity.above') ? (
              <VenueCapacityFeedback
                type="above"
                title={title}
                limit={maximumTicketedVenueCapacity}
                size="compact"
              />
            ) : state.matches('form.ticketed-venue-capacity.below') ? (
              <VenueCapacityFeedback
                type="below"
                title={title}
                limit={minimumTicketedVenueCapacity}
              />
            ) : null
        )
        .with(
          { tourDateType: { maximumTicketedVenueCapacity: P.number } },
          ({ tourDateType: { title, maximumTicketedVenueCapacity } }) =>
            state.matches('form.ticketed-venue-capacity.above') && (
              <VenueCapacityFeedback
                type="above"
                title={title}
                limit={maximumTicketedVenueCapacity}
              />
            )
        )
        .with(
          { tourDateType: { minimumTicketedVenueCapacity: P.number } },
          ({ tourDateType: { title, minimumTicketedVenueCapacity } }) =>
            state.matches('form.ticketed-venue-capacity.below') && (
              <VenueCapacityFeedback
                type="below"
                title={title}
                limit={minimumTicketedVenueCapacity}
              />
            )
        )
        .otherwise(
          () =>
            state.matches('form.country.invalid.region') && (
              <InvalidRequestFeedback
                title="This Tour Date is not eligible"
                size="default"
                date={state.context.tourDate}
                year={state.context.year}
                tourDateRegions={state.context.tourDateRegions}
              >
                <p className={styles.feedback}>
                  Please change the country or remove one of your previously
                  added dates.
                </p>
              </InvalidRequestFeedback>
            )
        )}
    >
      <InlineFormHeading>{title}</InlineFormHeading>
      <div className={styles.row}>
        <FormField
          htmlFor="date"
          label="Date"
          formFieldStatus={
            state.matches('form.tour-date.invalid') ? 'error' : 'default'
          }
          feedbackType="warning"
          infoLabel={match(state)
            .when(
              (state) => state.matches('form.tour-date.invalid.empty'),
              () => 'This field is required.'
            )
            .when(
              (state) => state.matches('form.tour-date.invalid.past'),
              () =>
                state.context.alternate
                  ? `Date must be on or after application submission date.`
                  : `Date must be on or after ${formatCalendarDate(
                      state.context.presentCutoffDate
                    )}.`
            )
            .when(
              (state) => state.matches('form.tour-date.invalid.future'),
              () =>
                state.context.futureCutoffDate === undefined
                  ? // The first string should never be displayed since you can't be
                    // too far ahead of a date that is undefined but if we somehow get
                    // into that state at least some error will be displayed to the user.
                    'Date is too far in the future.'
                  : `Date must be on or before ${formatCalendarDate(
                      state.context.futureCutoffDate
                    )}.`
            )
            .otherwise(() => '')}
        >
          <DatePicker
            aria-label="Tour date"
            value={state.context.tourDate.date}
            onChange={(tourDate) => send({ type: 'SET_TOUR_DATE', tourDate })}
            onFocus={() => send({ type: 'FOCUS_TOUR_DATE' })}
          />
        </FormField>
        <FormField
          htmlFor="type-toggle-button"
          label="Type"
          formFieldStatus={
            state.matches('form.tour-date-type.invalid') ? 'error' : 'default'
          }
          feedbackType="warning"
          infoLabel={
            state.matches('form.tour-date-type.invalid')
              ? 'This field is required.'
              : ''
          }
        >
          <Select
            id="type"
            items={state.context.tourDateTypes}
            itemToKey={(item) => item?.id}
            label="Select a type..."
            selectedItem={
              state.context.tourDateTypes.find(
                (type) => type.id === state.context.tourDate.tourDateType?.id
              ) ?? null
            }
            onChange={(tourDateType) =>
              send({
                type: 'SET_TOUR_DATE_TYPE',
                tourDateType,
              })
            }
            getItemText={({ title }) => title}
            onFocus={() => send({ type: 'FOCUS_TOUR_DATE_TYPE' })}
          />
        </FormField>
      </div>
      <FormField
        htmlFor="confirmed"
        label="Is this tour date confirmed?"
        formFieldStatus={
          state.matches('form.confirmed.invalid') ? 'error' : 'default'
        }
        feedbackType="warning"
        infoLabel={
          state.matches('form.confirmed.invalid')
            ? 'This field is required.'
            : ''
        }
      >
        <>
          <RadioOption
            label="This tour date is confirmed"
            onChange={() => send({ type: 'SET_CONFIRMED', confirmed: true })}
            checked={
              (state.context.tourDate.confirmed !== null &&
                state.context.tourDate.confirmed) ??
              false
            }
          />
          <RadioOption
            label="This tour date is tentative"
            onChange={() => send({ type: 'SET_CONFIRMED', confirmed: false })}
            checked={
              (state.context.tourDate.confirmed !== null &&
                !state.context.tourDate.confirmed) ??
              false
            }
          />
        </>
      </FormField>
      <InlineFormHeading>Location</InlineFormHeading>
      <div className={styles.row}>
        <FormField
          htmlFor="city"
          label="City, Province/State"
          formFieldStatus={
            state.matches('form.country-subdivision.invalid')
              ? 'error'
              : 'default'
          }
          feedbackType="warning"
          infoLabel={
            state.matches('form.country-subdivision.invalid')
              ? 'This field is required.'
              : ''
          }
        >
          <TextInput
            id="city"
            value={state.context.tourDate.countrySubdivision}
            onChange={(countrySubdivision) =>
              send({
                type: 'SET_COUNTRY_SUBDIVISION',
                countrySubdivision,
              })
            }
            onBlur={() => send({ type: 'BLUR_COUNTRY_SUBDIVISION' })}
            onFocus={() => send({ type: 'FOCUS_COUNTRY_SUBDIVISION' })}
          />
        </FormField>
        <FormField
          htmlFor="country-toggle-button"
          label="Country"
          formFieldStatus={
            state.matches('form.country.invalid') ? 'error' : 'default'
          }
          feedbackType="warning"
          infoLabel={
            state.matches('form.country.invalid.empty')
              ? 'This field is required.'
              : ''
          }
        >
          <Select
            id="country"
            items={state.context.countries}
            itemToKey={(item) => item?.id}
            label="Select a country..."
            selectedItem={
              state.context.countries.find(
                (country) =>
                  country.id === state.context.tourDate.tourDateCountry?.id
              ) ?? null
            }
            onChange={(tourDateCountry) =>
              send({
                type: 'SET_TOUR_COUNTRY',
                tourDateCountry,
              })
            }
            getItemText={({ name }) => name}
            onFocus={() => send({ type: 'FOCUS_COUNTRY' })}
          />
        </FormField>
      </div>
      {state.matches('form.country.invalid.region') && (
        <InvalidRequestFeedback
          size="compact"
          date={state.context.tourDate}
          year={state.context.year}
          tourDateRegions={state.context.tourDateRegions}
        />
      )}
      <div className={styles.row}>
        <FormField
          htmlFor="venue"
          label="Venue"
          formFieldStatus={
            state.matches('form.venue-name.invalid') ? 'error' : 'default'
          }
          feedbackType="warning"
          infoLabel={
            state.matches('form.venue-name.invalid')
              ? 'This field is required.'
              : ''
          }
        >
          <TextInput
            id="venue"
            value={state.context.tourDate.venueName}
            onChange={(venueName) =>
              send({ type: 'SET_VENUE_NAME', venueName })
            }
            onBlur={() => send({ type: 'BLUR_VENUE_NAME' })}
            onFocus={() => send({ type: 'FOCUS_VENUE_NAME' })}
          />
        </FormField>
        <FormField
          htmlFor="venue-capacity"
          label="Venue capacity"
          formFieldStatus={
            state.matches('form.venue-capacity.invalid') ||
            state.matches('form.ticketed-venue-capacity.above') ||
            state.matches('form.ticketed-venue-capacity.below')
              ? 'error'
              : 'default'
          }
          feedbackType="warning"
          infoLabel={match(state)
            .when(
              (state) => state.matches('form.venue-capacity.invalid.empty'),
              () => 'This field is required.'
            )
            .when(
              (state) => state.matches('form.venue-capacity.invalid.type'),
              () => 'This field must be numeric.'
            )
            .otherwise(() => '')}
        >
          <TextInput
            id="venue-capacity"
            value={state.context.tourDate.venueCapacity}
            onChange={(venueCapacity) =>
              send({
                type: 'SET_VENUE_CAPACITY',
                venueCapacity: venueCapacity,
              })
            }
            onBlur={() => send({ type: 'BLUR_VENUE_CAPACITY' })}
            onFocus={() => send({ type: 'FOCUS_VENUE_CAPACITY' })}
          />
        </FormField>
      </div>
      {match(state.context.tourDate.tourDateType)
        .with(
          {
            minimumTicketedVenueCapacity: P.number,
            maximumTicketedVenueCapacity: P.number,
          },
          ({
            title,
            minimumTicketedVenueCapacity,
            maximumTicketedVenueCapacity,
          }) =>
            state.matches('form.ticketed-venue-capacity.above') ? (
              <VenueCapacityFeedback
                type="above"
                title={title}
                limit={maximumTicketedVenueCapacity}
                size="compact"
              />
            ) : state.matches('form.ticketed-venue-capacity.below') ? (
              <VenueCapacityFeedback
                type="below"
                title={title}
                limit={minimumTicketedVenueCapacity}
                size="compact"
              />
            ) : (
              <Feedback type="neutral" size="compact">
                <p className={styles.feedback}>
                  <strong>Venue capacity</strong> may not be less than{' '}
                  {formatNumber(minimumTicketedVenueCapacity)} or more than{' '}
                  {formatNumber(maximumTicketedVenueCapacity)} for ticketed
                  performances.
                </p>
              </Feedback>
            )
        )
        .with(
          { maximumTicketedVenueCapacity: P.number },
          ({ title, maximumTicketedVenueCapacity }) =>
            state.matches('form.ticketed-venue-capacity.above') ? (
              <VenueCapacityFeedback
                type="above"
                title={title}
                limit={maximumTicketedVenueCapacity}
                size="compact"
              />
            ) : (
              <Feedback type="neutral" size="compact">
                <p className={styles.feedback}>
                  <strong>Venue capacity</strong> may not be more than{' '}
                  {formatNumber(maximumTicketedVenueCapacity)} for ticketed
                  performances.
                </p>
              </Feedback>
            )
        )
        .with(
          { minimumTicketedVenueCapacity: P.number },
          ({ title, minimumTicketedVenueCapacity }) =>
            state.matches('form.ticketed-venue-capacity.below') ? (
              <VenueCapacityFeedback
                type="below"
                title={title}
                limit={minimumTicketedVenueCapacity}
                size="compact"
              />
            ) : (
              <Feedback type="neutral" size="compact">
                <p className={styles.feedback}>
                  <strong>Venue capacity</strong> may not be less than{' '}
                  {formatNumber(minimumTicketedVenueCapacity)} for ticketed
                  performances.
                </p>
              </Feedback>
            )
        )
        .otherwise(() => undefined)}
      <InlineFormHeading>Finances</InlineFormHeading>
      <div className={styles.row}>
        <FormField
          htmlFor="average-ticket-price"
          label="Average ticket price"
          description="In Canadian dollars"
          formFieldStatus={
            state.matches('form.average-ticket-price.invalid')
              ? 'error'
              : 'default'
          }
          feedbackType="warning"
          infoLabel={match(state)
            .when(
              (state) =>
                state.matches('form.average-ticket-price.invalid.empty'),
              () => 'This field is required.'
            )
            .when(
              (state) =>
                state.matches('form.average-ticket-price.invalid.type'),
              () => 'This field must be numeric.'
            )
            .otherwise(() => '')}
        >
          <TextInput
            id="average-ticket-price"
            value={state.context.tourDate.averageTicketPrice}
            onChange={(averageTicketPrice) =>
              send({
                type: 'SET_AVERAGE_TICKET_PRICE',
                averageTicketPrice,
              })
            }
            onBlur={() => send({ type: 'BLUR_AVERAGE_TICKET_PRICE' })}
            onFocus={() => send({ type: 'FOCUS_AVERAGE_TICKET_PRICE' })}
          />
        </FormField>
        <FormField
          htmlFor="payment-guarantee"
          label="Payment guarantee"
          description="In Canadian dollars"
          formFieldStatus={
            state.matches('form.payment-guarantee.invalid')
              ? 'error'
              : 'default'
          }
          feedbackType="warning"
          infoLabel={match(state)
            .when(
              (state) => state.matches('form.payment-guarantee.invalid.empty'),
              () => 'This field is required.'
            )
            .when(
              (state) => state.matches('form.payment-guarantee.invalid.type'),
              () => 'This field must be numeric.'
            )
            .otherwise(() => '')}
        >
          <TextInput
            id="payment-guarantee"
            value={state.context.tourDate.paymentGuarantee}
            onChange={(paymentGuarantee) =>
              send({ type: 'SET_PAYMENT_GUARANTEE', paymentGuarantee })
            }
            onBlur={() => send({ type: 'BLUR_PAYMENT_GUARANTEE' })}
            onFocus={() => send({ type: 'FOCUS_PAYMENT_GUARANTEE' })}
          />
        </FormField>
      </div>
      <InlineFormHeading>Booking & Promotion</InlineFormHeading>
      <div className={styles.row}>
        <FormField
          htmlFor="booking-agency-company"
          label="Booking agency company"
          formFieldStatus={
            state.matches('form.booking-agency.invalid') ? 'error' : 'default'
          }
          feedbackType="warning"
          infoLabel={
            state.matches('form.booking-agency.invalid')
              ? 'This field is required.'
              : ''
          }
        >
          <TextInput
            id="booking-agency-company"
            value={state.context.tourDate.bookingAgency}
            onChange={(bookingAgency) =>
              send({ type: 'SET_BOOKING_AGENCY', bookingAgency })
            }
            onBlur={() => send({ type: 'BLUR_BOOKING_AGENCY' })}
            onFocus={() => send({ type: 'FOCUS_BOOKING_AGENCY' })}
          />
        </FormField>
        <FormField
          htmlFor="booking-agent"
          label="Booking agent"
          formFieldStatus={
            state.matches('form.booking-agent.invalid') ? 'error' : 'default'
          }
          feedbackType="warning"
          infoLabel={
            state.matches('form.booking-agent.invalid')
              ? 'This field is required.'
              : ''
          }
        >
          <TextInput
            id="booking-agent"
            value={state.context.tourDate.bookingAgent}
            onChange={(bookingAgent) =>
              send({ type: 'SET_BOOKING_AGENT', bookingAgent })
            }
            onBlur={() => send({ type: 'BLUR_BOOKING_AGENT' })}
            onFocus={() => send({ type: 'FOCUS_BOOKING_AGENT' })}
          />
        </FormField>
      </div>
      <div className={styles.row}>
        <FormField
          htmlFor="promoter"
          label="Promoter"
          formFieldStatus={
            state.matches('form.promoter.invalid') ? 'error' : 'default'
          }
          feedbackType="warning"
          infoLabel={
            state.matches('form.promoter.invalid')
              ? 'This field is required.'
              : ''
          }
        >
          <TextInput
            id="promoter"
            value={state.context.tourDate.promoter}
            onChange={(promoter) => send({ type: 'SET_PROMOTER', promoter })}
            onBlur={() => send({ type: 'BLUR_PROMOTER' })}
            onFocus={() => send({ type: 'FOCUS_PROMOTER' })}
          />
        </FormField>
      </div>
      {state.context.alternate && (
        <FormField
          id="reason"
          label="Reason for Alternate Tour Date"
          feedbackType="warning"
          formFieldStatus={
            state.matches('form.reason.invalid') ? 'error' : 'default'
          }
          infoLabel={match(state)
            .when(
              ({ matches }) => matches('form.reason.invalid'),
              () => 'This field is required.'
            )
            .otherwise(() => '')}
        >
          <TextArea
            id="reason"
            size="large"
            label="reason"
            value={state.context.tourDate.reason}
            onChange={(reason) => send({ type: 'SET_REASON', reason })}
            onBlur={() => send({ type: 'BLUR_REASON' })}
            onFocus={() => send({ type: 'FOCUS_REASON' })}
          />
        </FormField>
      )}
      {(state.matches('form.warning.on') ||
        state.matches('form.warning.pulse')) && (
        <Feedback type="warning" pulse={state.matches('form.warning.pulse')}>
          Please address all issues in the fields above.
        </Feedback>
      )}
      {state.matches('form.warning.error') && <ErrorFeedback />}
    </InlineFormContainer>
  );
}
