import z from 'zod';
import { parseAbsolute } from '@internationalized/date';
import { serverDateTimeSchema } from './dateTimeSchema';

export const roundDetailsSchema = z.object({
  currentRound: z
    .object({
      title: z.string(),
      timezone: z.string(),
      endDate: serverDateTimeSchema.unwrap(),
      closingSoon: z.boolean(),
    })
    .transform(({ timezone, endDate, ...rest }) => ({
      endDate: parseAbsolute(endDate, timezone),
      ...rest,
    }))
    .nullable(),
  previousRound: z
    .object({
      title: z.string(),
      timezone: z.string(),
      endDate: serverDateTimeSchema.unwrap(),
    })
    .transform(({ timezone, endDate, ...rest }) => ({
      endDate: parseAbsolute(endDate, timezone),
      ...rest,
    }))
    .nullable(),
  nextRound: z
    .object({
      title: z.string(),
    })
    .nullable(),
});

export type RoundDetails = z.infer<typeof roundDetailsSchema>;
