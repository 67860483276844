import { useRef } from 'react';
import { useDateField } from 'react-aria';
import { DateFieldStateOptions, useDateFieldState } from 'react-stately';

import { DateSegment } from './DateSegment';

import styles from './DateField.module.scss';

export function DateField(props: DateFieldStateOptions) {
  const state = useDateFieldState(props);
  const ref = useRef(null);
  const { fieldProps } = useDateField(props, state, ref);

  return (
    <div
      {...fieldProps}
      ref={ref}
      className={styles.dateField}
      onBlur={props.onBlur}
    >
      {state.segments.map((segment, i) => (
        <DateSegment key={i} segment={segment} state={state} />
      ))}
    </div>
  );
}
