import { TourDate } from '../schemas/tourDates/tourDateSchema';

export type UpdateTourDates = {
  type: 'UPDATE_TOUR_DATES';
  tourDates: TourDate[];
};

export const updateTourDates = (
  tourDates: UpdateTourDates['tourDates']
): UpdateTourDates => ({ type: 'UPDATE_TOUR_DATES', tourDates });
