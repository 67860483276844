import { GraphQLClient } from 'graphql-request';
import {
  UpdateAccountInvitationDocument,
  UpdateAccountInvitationMutationVariables,
  UpdateAccountInvitationResponseCode,
} from '../graphql/operations';

export const createUpdateAccountInvitationPromise =
  (client: GraphQLClient) =>
  (variables: UpdateAccountInvitationMutationVariables) =>
    client.request(UpdateAccountInvitationDocument, variables);

export type UpdateAccountInvitationPromise = ReturnType<
  typeof createUpdateAccountInvitationPromise
>;

export type UpdateAccountInvitationPromiseResult = Awaited<
  ReturnType<UpdateAccountInvitationPromise>
>;

export const isInvalidRequest = ({
  result,
}: UpdateAccountInvitationPromiseResult) =>
  result?.response?.code === UpdateAccountInvitationResponseCode.InvalidRequest;

export const isSuccess = ({ result }: UpdateAccountInvitationPromiseResult) =>
  result?.response?.code === UpdateAccountInvitationResponseCode.Success;

export const mockDoneData: UpdateAccountInvitationPromiseResult = {
  result: {
    response: {
      code: UpdateAccountInvitationResponseCode.Success,
    },
  },
};

export const mockErrorData: unknown = new Error('This is a mock error.');
