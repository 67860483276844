import { GraphQLClient } from 'graphql-request';
import { AddBoardMeetingBlockDocument } from '../../graphql/operations';
import { Context } from '../../machines/BoardMeeting/BoardMeetingMachine';
import { boardMeetingBlockSchema } from '../../schemas/boardMeetingBlockSchema';

export const createAddBoardMeetingBlockPromise =
  (client: GraphQLClient) =>
  async (
    _: Context,
    input: { title: string; content: string; roundId: string }
  ) => {
    const { result } = await client.request(
      AddBoardMeetingBlockDocument,
      input
    );

    return boardMeetingBlockSchema.parse(result?.block);
  };

export type AddBoardMeetingBlockPromise = ReturnType<
  typeof createAddBoardMeetingBlockPromise
>;

export type AddBoardMeetingBlockPromiseResult = Awaited<
  ReturnType<AddBoardMeetingBlockPromise>
>;
