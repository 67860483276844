import { useMachine } from '@xstate/react';
import { AccountView } from './AccountView';
import {
  machine,
  AccountMachineOptions,
} from '../../machines/AccountDetailsMachine';

type AccountControllerProps = {
  options: AccountMachineOptions;
};

export function AccountController({ options }: AccountControllerProps) {
  const [state, send] = useMachine(machine, options);

  return <AccountView state={state} send={send} />;
}
