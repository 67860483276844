import { Attachment } from '../../schemas/attachment';
import { BoardMeetingBlock } from '../../schemas/boardMeetingBlockSchema';
import { getSignedUrl } from './utils';

interface GetFileProps {
  file: Attachment;
}

interface Props {
  applicationId?: string;
  block?: BoardMeetingBlock;
}

export const createGetFilePromise =
  ({ directory }: { directory: string }) =>
  async ({ applicationId, block }: Props, { file }: GetFileProps) => {
    const referenceId = applicationId || block?.id;

    if (!referenceId) {
      throw Error('No reference id provided');
    }

    return getSignedUrl({ referenceId, directory, fileName: file.name });
  };

export type GetFilePromise = ReturnType<typeof createGetFilePromise>;
export type GetFilePromiseResult = Awaited<ReturnType<GetFilePromise>>;
