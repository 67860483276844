import { GraphQLClient } from 'graphql-request';
import {
  SubscribeToNewsletterDocument,
  SubscribeToNewsletterMutationVariables,
} from '../graphql/operations';

export const createSubscribeToNewsletterPromise =
  (client: GraphQLClient) =>
  (variables: SubscribeToNewsletterMutationVariables) =>
    client.request(SubscribeToNewsletterDocument, variables);

export type SubscribeToNewsletterPromise = ReturnType<
  typeof createSubscribeToNewsletterPromise
>;
export type SubscribeToNewsletterPromiseResult = Awaited<
  ReturnType<SubscribeToNewsletterPromise>
>;
