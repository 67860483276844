import { Feedback } from '../Feedback';

interface ApplicationCompletedFeedbackProps {
  fundingType: string;
  roundTitle: string;
}

export function ApplicationCompletedFeedback({
  fundingType,
  roundTitle,
}: ApplicationCompletedFeedbackProps) {
  return (
    <Feedback
      type="positive"
      icon="checkCircle"
      title="You've received the final funding!"
    >
      {`Your ${fundingType} claim for the for round #${roundTitle} has been completed.`}
    </Feedback>
  );
}
