import { z } from 'zod';
import { serverDateSchema } from './dateSchema';
import { serverDateTimeSchema } from '../schemas/dateTimeSchema';
import { moneySchema } from './moneySchema';

export const paymentSchema = z.object({
  id: z.string(),
  applicationId: z.string(),
  directDepositAccountId: z.string(),
  createdAt: serverDateTimeSchema.unwrap(),
  invoiceDate: serverDateSchema,
  transactionNumber: z.string(),
  notes: z.string().nullable(),
  amount: moneySchema,
});

export type Payment = z.infer<typeof paymentSchema>;
