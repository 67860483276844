import { Expense } from '../../graphql/operations';
import { CompleteExpense } from '../../schemas/fundingRequests/expenses/expenseSchema';

export type UpdateExpense = {
  type: 'UPDATE_EXPENSE';
  id: Expense['id'];
  expense: CompleteExpense;
};

export const updateExpense = (
  id: Expense['id'],
  expense: UpdateExpense['expense']
): UpdateExpense => ({ type: 'UPDATE_EXPENSE', id, expense });
