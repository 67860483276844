import { GraphQLClient } from 'graphql-request';
import { z } from 'zod';
import { IdNotFoundError } from '../errors/IdNotFoundError';
import {
  GetAllReleasesPageDocument,
  GetAllReleasesPageQueryVariables,
} from '../graphql/operations';

export const createGetAllReleasesPagePromise =
  (client: GraphQLClient, variables: GetAllReleasesPageQueryVariables) =>
  async () => {
    const request = await client.request(GetAllReleasesPageDocument, variables);

    if (!request.artist) {
      throw new IdNotFoundError();
    }

    return z
      .object({
        artistId: z.string().uuid(),
        artistName: z.string(),
        releases: z.array(
          z.object({
            id: z.string().uuid(),
            title: z.string(),
            isEligible: z.boolean(),
            date: z.string(),
            label: z.object({
              name: z.string(),
            }),
            otherLabel: z.string().nullable(),
          })
        ),
        isOwner: z.boolean(),
      })
      .parse({
        artistId: request.artist.id,
        artistName: request.artist.name,
        releases: request.artist.releases ?? [],
        isOwner: request.artist.isOwner,
      });
  };

export type GetAllReleasesPage = ReturnType<
  typeof createGetAllReleasesPagePromise
>;
export type GetAllReleasesPageResult = Awaited<ReturnType<GetAllReleasesPage>>;
