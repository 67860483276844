import { match } from 'ts-pattern';
import classNames from 'classnames';
import { ApplicationReviewStatus } from '../../../graphql/operations';
import {
  ApplicationReviewMachineSender,
  ApplicationReviewMachineState,
} from '../../../machines/Admin/ApplicationReviewMachine';
import { Button } from '../../Button';
import { FormField } from '../../FormField';
import { MultiSelect } from '../../MultiSelect';
import { Select } from '../../Select';
import { formatReviewStatus } from '../../../utils/formatApplicationReviewStatus';
import { TextArea } from '../../TextArea';

import styles from './SecondaryControls.module.scss';

interface SecondaryControlsProps {
  state: ApplicationReviewMachineState;
  send: ApplicationReviewMachineSender;
}

export function SecondaryControls({ state, send }: SecondaryControlsProps) {
  return (
    <>
      <div className={styles.controlsSection}>
        <FormField
          label="Review Status"
          feedbackType="warning"
          formFieldStatus={
            state.matches('reviewStatus.error') ? 'error' : 'default'
          }
          infoLabel={match(state)
            .when(
              () => state.matches('reviewStatus.error'),
              () => 'Something went wrong.'
            )
            .otherwise(() => '')}
        >
          <Select
            label="None"
            id="reviewStatus"
            items={Object.values(ApplicationReviewStatus).filter(
              (status) => status !== ApplicationReviewStatus.None
            )}
            itemToKey={(item) => item}
            selectedItem={
              state.context.reviewStatus === ApplicationReviewStatus.None
                ? null
                : state.context.reviewStatus ?? null
            }
            selectedItemClassName="body-text-bold"
            onChange={(reviewStatus) =>
              send({
                type: 'UPDATE_REVIEW_STATUS',
                reviewStatus,
              })
            }
            getItemText={formatReviewStatus}
            isLoading={state.matches('reviewStatus.updating')}
          />
        </FormField>
        <FormField
          label="Restrict Board Access"
          feedbackType="warning"
          formFieldStatus={
            state.matches('boardAccess.error') ? 'error' : 'default'
          }
          infoLabel={match(state)
            .when(
              () => state.matches('boardAccess.error'),
              () => 'Something went wrong.'
            )
            .otherwise(() => '')}
        >
          <MultiSelect
            id="boardAccess"
            items={state.context.boardMembers}
            selectedItems={state.context.boardMembers.filter((boardMember) =>
              state.context.boardBlocks?.find(
                (block) => boardMember.accountId === block.accountId
              )
            )}
            onItemAdded={(item) =>
              send({ type: 'ADD_BOARD_BLOCK', accountId: item.accountId })
            }
            onItemRemoved={(item) =>
              send({ type: 'REMOVE_BOARD_BLOCK', accountId: item.accountId })
            }
            getItemText={(item) => item.name}
            isLoading={
              state.matches('boardAccess.addingBoardBlock') ||
              state.matches('boardAccess.removingBoardBlock')
            }
          />
        </FormField>
      </div>
      <div
        className={classNames(
          styles.controlsSection,
          styles.secondaryControlsBottom
        )}
      >
        {match(state)
          .when(
            () => state.matches('boardNotes.closed'),
            () => (
              <Button
                variant="addnew"
                label="Add Internal Notes"
                startIcon="plus"
                onClick={() => send({ type: 'OPEN_BOARD_NOTES' })}
              />
            )
          )
          .when(
            () => state.matches('boardNotes.open'),
            () => (
              <>
                <FormField
                  label="Internal Notes for Board"
                  feedbackType="warning"
                  formFieldStatus={
                    state.matches('boardNotes.open.error') ? 'error' : 'default'
                  }
                  infoLabel={match(state)
                    .when(
                      () => state.matches('boardNotes.open.error'),
                      () => 'Something went wrong.'
                    )
                    .otherwise(() => '')}
                >
                  <TextArea
                    id="board-notes"
                    label=""
                    value={state.context.boardNotesInputValue}
                    onChange={(value) =>
                      send({ type: 'UPDATE_BOARD_NOTES_INPUT_VALUE', value })
                    }
                    size="small"
                  />
                </FormField>
                <div>
                  <Button
                    className={styles.internalNotesSaveButton}
                    label="Save"
                    size="small"
                    variant="secondary"
                    disabled={
                      state.context.boardNotes?.notes ===
                      state.context.boardNotesInputValue
                    }
                    onClick={() => send({ type: 'UPSERT_BOARD_NOTES' })}
                  />
                </div>
              </>
            )
          )
          .otherwise(() => null)}
      </div>
    </>
  );
}
