import { ZonedDateTime, toCalendarDateTime } from '@internationalized/date';
import { CompleteFundingRound } from '../../schemas/fundingRound/fundingRoundSchema';

const toString = (date: ZonedDateTime) => toCalendarDateTime(date).toString();
const toStringOrNull = (date: ZonedDateTime | null) =>
  date !== null ? toString(date) : null;

export function prepareFundingRound({
  title,
  allowOrion,
  startDate,
  endDate,
  boardStartDate,
  boardEndDate,
  boardMeetingDate,
}: CompleteFundingRound) {
  return {
    title,
    allowOrion,
    startDate: toStringOrNull(startDate),
    endDate: toString(endDate),
    boardStartDate: toStringOrNull(boardStartDate),
    boardEndDate: toStringOrNull(boardEndDate),
    boardMeetingDate: boardMeetingDate.toString(),
  };
}
