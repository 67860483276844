import { useActor } from '@xstate/react';
import { MasterRecordingView } from './MasterRecordingView';
import { MasterRecordingActor } from '../../../machines/Eligibility/MasterRecordingMachine';

export function MasterRecordingController({
  actor,
  type,
}: {
  actor: MasterRecordingActor;
  type: 'eligibility' | 'add-release';
}) {
  const [state, send] = useActor(actor);

  return <MasterRecordingView state={state} send={send} type={type} />;
}
