import {
  FundingRound,
  CompleteFundingRound,
} from '../schemas/fundingRound/fundingRoundSchema';

export type UpdateFundingRound = {
  type: 'UPDATE_FUNDING_ROUND';
  id: FundingRound['id'];
  round: CompleteFundingRound;
};

export const updateFundingRound = (
  id: UpdateFundingRound['id'],
  round: UpdateFundingRound['round']
): UpdateFundingRound => ({ type: 'UPDATE_FUNDING_ROUND', id, round });
