import { Icon } from '../../components/Icon';
import cn from 'classnames';
import {
  UnsubscribeMachineSender,
  UnsubscribeMachineState,
} from '../../machines/UnsubscribeMachine';

import styles from './Unsubscribe.module.scss';
import { ErrorPage } from '../ErrorPage';

interface UnsubscribeViewProps {
  state: UnsubscribeMachineState;
  send: UnsubscribeMachineSender;
}

export function UnsubscribeView({ state }: UnsubscribeViewProps) {
  if (state.matches('error')) {
    return <ErrorPage />;
  }

  return (
    <div>
      {state.matches('done') && (
        <div className={cn(styles.container, 'max-w-md')}>
          <Icon name="unsubscribe" className={styles.icon} />
          <h1 className={styles.heading}>You’ve Unsubscribed Successfully!</h1>
          <p className={styles.bodyText}>
            You will no longer receive our newsletter emails.
          </p>
        </div>
      )}
    </div>
  );
}
