import { ReactNode } from 'react';
import { Link } from './Link';
import cn from 'classnames';
import styles from './Sidebar.module.scss';

interface Link {
  title: string;
  href: string;
}

interface SidebarProps {
  links: Link[];
  children?: ReactNode;
  className?: string;
  linksClassName?: string;
}

export function Sidebar({
  links,
  children,
  className,
  linksClassName,
}: SidebarProps) {
  return (
    <aside className={cn([styles.sidebar, className])}>
      {links.length > 0 && (
        <div className={cn([styles.links, linksClassName])}>
          {links.map((link) => (
            <Link href={link.href} key={link.href} className={styles.link}>
              {link.title}
            </Link>
          ))}
        </div>
      )}
      {children !== undefined && <div>{children}</div>}
    </aside>
  );
}
