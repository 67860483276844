import type {
  Sender,
  EventFrom,
  MachineOptionsFrom,
  ActorRefFrom,
} from 'xstate';
import { assign, sendParent, createMachine, send } from 'xstate';
import { CalendarDate } from '@internationalized/date';
import { StateFrom } from '../../utils/StateFrom';
import { StatesConfig } from '../../utils/StateConfig';
import { match } from 'ts-pattern';
import { parsePositiveInteger } from '../../utils/parsePositiveInteger';
import { parsePositiveFloat } from '../../utils/parsePositiveFloat';
import { isBeforeCutoff } from '../../utils/isBeforeCutoff';
import { isAfterCutoff } from '../../utils/isAfterCutoff';
import { addTourDate } from '../../events/AddTourDate';
import { updateTourDate } from '../../events/UpdateTourDate';
import { closeTourDate } from '../../events/CloseTourDate';
import {
  EditContext,
  RefreshTourDateRegions,
} from '../../events/RefreshTourDateRegions';
import {
  PartialTourDate,
  CompleteTourDate,
} from '../../schemas/tourDates/tourDateSchema';
import { TourDateType } from '../../schemas/tourDates/tourDateTypeSchema';
import { TourDateRegion } from '../../schemas/tourDates/tourDateRegionSchema';
import { Country } from '../../schemas/countrySchema';
import { formatNumber } from '../../utils/formatNumber';
import { logMachineError } from '../../utils/logError';
import { prepareTourDate } from '../../utils/prepareTourDate';

type Events =
  | RefreshTourDateRegions
  | { type: 'CLOSE_TOUR_DATE' }
  | { type: 'SAVE_TOUR_DATE' }
  | { type: 'SET_TOUR_DATE'; tourDate: CompleteTourDate['date'] }
  | {
      type: 'SET_TOUR_DATE_TYPE';
      tourDateType: CompleteTourDate['tourDateType'];
    }
  | { type: 'SET_CONFIRMED'; confirmed: CompleteTourDate['confirmed'] }
  | {
      type: 'SET_COUNTRY_SUBDIVISION';
      countrySubdivision: CompleteTourDate['countrySubdivision'];
    }
  | { type: 'SET_VENUE_NAME'; venueName: CompleteTourDate['venueName'] }
  | {
      type: 'SET_VENUE_CAPACITY';
      venueCapacity: CompleteTourDate['venueCapacity'];
    }
  | {
      type: 'SET_AVERAGE_TICKET_PRICE';
      averageTicketPrice: CompleteTourDate['averageTicketPrice'];
    }
  | {
      type: 'SET_PAYMENT_GUARANTEE';
      paymentGuarantee: CompleteTourDate['paymentGuarantee'];
    }
  | {
      type: 'SET_BOOKING_AGENCY';
      bookingAgency: CompleteTourDate['bookingAgency'];
    }
  | {
      type: 'SET_BOOKING_AGENT';
      bookingAgent: CompleteTourDate['bookingAgent'];
    }
  | { type: 'SET_PROMOTER'; promoter: CompleteTourDate['promoter'] }
  | {
      type: 'SET_TOUR_COUNTRY';
      tourDateCountry: CompleteTourDate['tourDateCountry'];
    }
  | { type: 'SET_REASON'; reason: CompleteTourDate['reason'] }
  | { type: 'BLUR_COUNTRY_SUBDIVISION' }
  | { type: 'BLUR_VENUE_NAME' }
  | { type: 'BLUR_VENUE_CAPACITY' }
  | { type: 'BLUR_AVERAGE_TICKET_PRICE' }
  | { type: 'BLUR_PAYMENT_GUARANTEE' }
  | { type: 'BLUR_BOOKING_AGENCY' }
  | { type: 'BLUR_BOOKING_AGENT' }
  | { type: 'BLUR_PROMOTER' }
  | { type: 'BLUR_REASON' }
  | { type: 'FOCUS_COUNTRY_SUBDIVISION' }
  | { type: 'FOCUS_VENUE_NAME' }
  | { type: 'FOCUS_VENUE_CAPACITY' }
  | { type: 'FOCUS_AVERAGE_TICKET_PRICE' }
  | { type: 'FOCUS_PAYMENT_GUARANTEE' }
  | { type: 'FOCUS_BOOKING_AGENCY' }
  | { type: 'FOCUS_BOOKING_AGENT' }
  | { type: 'FOCUS_PROMOTER' }
  | { type: 'FOCUS_REASON' }
  | { type: 'FOCUS_COUNTRY' }
  | { type: 'FOCUS_TOUR_DATE_TYPE' }
  | { type: 'FOCUS_TOUR_DATE' }
  | { type: 'CHECK_TICKETED_VENUE_CAPACITY' };

export type Context = {
  editContext?: EditContext;
  tourDate: PartialTourDate;
  tourDateTypes: TourDateType[];
  tourDateRegions: TourDateRegion[];
  year: number;
  presentCutoffDate: CalendarDate;
  futureCutoffDate: CalendarDate | undefined;
  countries: Country[];
  alternate: boolean;
};

type Services = {
  validateCompleteTourDate: {
    data: CompleteTourDate;
  };
};

const inputStates: StatesConfig<Context, Events> = {
  pristine: { on: { SAVE_TOUR_DATE: 'invalid' } },
  valid: { type: 'final' },
  invalid: {},
};

const belowMinimumTicketedVenueCapacity = (
  cap: number,
  price: number,
  type?: TourDateType
) =>
  !isNaN(cap) &&
  !isNaN(price) &&
  price > 0 &&
  type !== undefined &&
  type.minimumTicketedVenueCapacity !== null &&
  cap < type.minimumTicketedVenueCapacity;

const aboveMaximumTicketedVenueCapacity = (
  cap: number,
  price: number,
  type?: TourDateType
) =>
  !isNaN(cap) &&
  !isNaN(price) &&
  price > 0 &&
  type !== undefined &&
  type.maximumTicketedVenueCapacity !== null &&
  cap > type.maximumTicketedVenueCapacity;

export const machine = createMachine(
  {
    predictableActionArguments: true,
    tsTypes: {} as import('./TourDateMachine.typegen').Typegen0,
    schema: {
      context: {} as Context,
      events: {} as Events,
      services: {} as Services,
    },
    initial: 'form',
    id: 'form',
    states: {
      form: {
        type: 'parallel',
        states: {
          'tour-date': {
            initial: 'pristine',
            states: {
              ...inputStates,
              invalid: {
                initial: 'empty',
                states: {
                  empty: {},
                  past: {},
                  future: {},
                },
              },
              setting: {
                entry: 'setTourDate',
                always: [
                  { cond: 'isBeforeCutoff', target: 'invalid.past' },
                  { cond: 'isAfterCutoff', target: 'invalid.future' },
                  'valid',
                ],
              },
            },
            on: {
              SET_TOUR_DATE: '.setting',
            },
          },
          'tour-date-type': {
            initial: 'pristine',
            states: {
              ...inputStates,
              setting: {
                entry: ['setTourDateType', 'checkTicketedVenueCapacity'],
                always: 'valid',
              },
            },
            on: {
              SET_TOUR_DATE_TYPE: '.setting',
            },
          },
          confirmed: {
            initial: 'pristine',
            states: {
              ...inputStates,
              setting: { entry: 'setConfirmed', always: 'valid' },
            },
            on: {
              SET_CONFIRMED: '.setting',
            },
          },
          'country-subdivision': {
            initial: 'pristine',
            states: {
              ...inputStates,
              invalid: {
                initial: 'empty',
                states: {
                  empty: {},
                },
              },
            },
            on: {
              SET_COUNTRY_SUBDIVISION: { actions: 'setCountrySubdivision' },
              BLUR_COUNTRY_SUBDIVISION: [
                { cond: 'isEmpty', target: '.invalid.empty' },
                '.valid',
              ],
            },
          },
          country: {
            initial: 'pristine',
            states: {
              ...inputStates,
              invalid: {
                initial: 'empty',
                states: {
                  empty: {},
                  region: {},
                },
              },
              'setting-country': {
                entry: 'setCountry',
                always: 'checking',
              },
              'setting-region': {
                entry: 'refreshTourDateRegions',
                always: 'checking',
              },
              checking: {
                always: [
                  { cond: 'isCapHit', target: 'invalid.region' },
                  { target: 'valid' },
                ],
              },
            },
            on: {
              SET_TOUR_COUNTRY: '.setting-country',
              REFRESH_TOUR_DATE_REGIONS: '.setting-region',
            },
          },
          'venue-name': {
            initial: 'pristine',
            states: inputStates,
            on: {
              SET_VENUE_NAME: { actions: 'setVenueName' },
              BLUR_VENUE_NAME: [
                { cond: 'isEmpty', target: '.invalid' },
                '.valid',
              ],
            },
          },
          'venue-capacity': {
            initial: 'pristine',
            states: {
              ...inputStates,
              invalid: {
                initial: 'empty',
                states: {
                  empty: {},
                  type: {},
                },
              },
            },
            on: {
              SET_VENUE_CAPACITY: { actions: 'setVenueCapacity' },
              BLUR_VENUE_CAPACITY: [
                { cond: 'isEmpty', target: '.invalid.empty' },
                { cond: 'isNumeric', target: '.invalid.type' },
                {
                  actions: ['checkTicketedVenueCapacity', 'formatNumericValue'],
                  target: '.valid',
                },
              ],
            },
          },
          'average-ticket-price': {
            initial: 'pristine',
            states: {
              ...inputStates,
              invalid: {
                initial: 'empty',
                states: {
                  empty: {},
                  type: {},
                },
              },
            },
            on: {
              SET_AVERAGE_TICKET_PRICE: {
                actions: 'setAverageTicketPrice',
              },
              BLUR_AVERAGE_TICKET_PRICE: [
                { cond: 'isEmpty', target: '.invalid' },
                { cond: 'isNumeric', target: '.invalid.type' },
                {
                  actions: ['checkTicketedVenueCapacity', 'formatNumericValue'],
                  target: '.valid',
                },
              ],
            },
          },
          'payment-guarantee': {
            initial: 'pristine',
            states: {
              ...inputStates,
              invalid: {
                initial: 'empty',
                states: {
                  empty: {},
                  type: {},
                },
              },
            },
            on: {
              SET_PAYMENT_GUARANTEE: { actions: 'setPaymentGuaranteee' },
              BLUR_PAYMENT_GUARANTEE: [
                { cond: 'isEmpty', target: '.invalid.empty' },
                { cond: 'isNumeric', target: '.invalid.type' },
                {
                  actions: 'formatNumericValue',
                  target: '.valid',
                },
              ],
            },
          },
          'booking-agency': {
            initial: 'pristine',
            states: inputStates,
            on: {
              SET_BOOKING_AGENCY: { actions: 'setBookingAgency' },
              BLUR_BOOKING_AGENCY: [
                { cond: 'isEmpty', target: '.invalid' },
                '.valid',
              ],
            },
          },
          'booking-agent': {
            initial: 'pristine',
            states: inputStates,
            on: {
              SET_BOOKING_AGENT: { actions: 'setBookingAgent' },
              BLUR_BOOKING_AGENT: [
                { cond: 'isEmpty', target: '.invalid' },
                '.valid',
              ],
            },
          },
          promoter: {
            initial: 'pristine',
            states: inputStates,
            on: {
              SET_PROMOTER: { actions: 'setPromoter' },
              BLUR_PROMOTER: [
                { cond: 'isEmpty', target: '.invalid' },
                '.valid',
              ],
            },
          },
          reason: {
            initial: 'check',
            states: {
              check: {
                always: [
                  { cond: 'isAlternate', target: 'pristine' },
                  { target: 'valid' },
                ],
              },
              ...inputStates,
            },
            on: {
              SET_REASON: { actions: 'setReason' },
              BLUR_REASON: [{ cond: 'isEmpty', target: '.invalid' }, '.valid'],
            },
          },
          'ticketed-venue-capacity': {
            initial: 'pristine',
            states: {
              pristine: {},
              valid: { type: 'final' },
              below: {},
              above: {},
              checking: {
                always: [
                  {
                    cond: 'aboveMaximumTicketedVenueCapacity',
                    target: 'above',
                  },
                  {
                    cond: 'belowMinimumTicketedVenueCapacity',
                    target: 'below',
                  },
                  'valid',
                ],
              },
            },
            on: {
              CHECK_TICKETED_VENUE_CAPACITY: '.checking',
            },
          },
          warning: {
            initial: 'off',
            states: {
              on: {
                type: 'final',
                on: { SAVE_TOUR_DATE: 'pulse' },
              },
              off: {
                type: 'final',
                on: { SAVE_TOUR_DATE: 'on' },
              },
              pulse: {
                type: 'final',
                after: { 1000: 'on' },
              },
              error: { entry: 'logMachineError' },
            },
          },
        },
        onDone: 'complete',
      },
      complete: {
        on: {
          SAVE_TOUR_DATE: 'saving',
          FOCUS_COUNTRY_SUBDIVISION: {
            target: [
              'form.country-subdivision.pristine',
              'form.confirmed.valid',
              'form.venue-name.valid',
              'form.venue-capacity.valid',
              'form.average-ticket-price.valid',
              'form.payment-guarantee.valid',
              'form.booking-agency.valid',
              'form.booking-agent.valid',
              'form.promoter.valid',
              'form.country.valid',
              'form.tour-date-type.valid',
              'form.tour-date.valid',
              'form.ticketed-venue-capacity.valid',
              'form.reason.valid',
            ],
          },
          SET_CONFIRMED: {
            target: [
              'form.country-subdivision.valid',
              'form.confirmed.setting',
              'form.venue-name.valid',
              'form.venue-capacity.valid',
              'form.average-ticket-price.valid',
              'form.payment-guarantee.valid',
              'form.booking-agency.valid',
              'form.booking-agent.valid',
              'form.promoter.valid',
              'form.country.valid',
              'form.tour-date-type.valid',
              'form.tour-date.valid',
              'form.ticketed-venue-capacity.valid',
              'form.reason.valid',
            ],
          },
          FOCUS_VENUE_NAME: {
            target: [
              'form.country-subdivision.valid',
              'form.confirmed.valid',
              'form.venue-name.pristine',
              'form.venue-capacity.valid',
              'form.average-ticket-price.valid',
              'form.payment-guarantee.valid',
              'form.booking-agency.valid',
              'form.booking-agent.valid',
              'form.promoter.valid',
              'form.country.valid',
              'form.tour-date-type.valid',
              'form.tour-date.valid',
              'form.ticketed-venue-capacity.valid',
              'form.reason.valid',
            ],
          },
          FOCUS_VENUE_CAPACITY: {
            target: [
              'form.country-subdivision.valid',
              'form.confirmed.valid',
              'form.venue-name.valid',
              'form.venue-capacity.pristine',
              'form.average-ticket-price.valid',
              'form.payment-guarantee.valid',
              'form.booking-agency.valid',
              'form.booking-agent.valid',
              'form.promoter.valid',
              'form.country.valid',
              'form.tour-date-type.valid',
              'form.tour-date.valid',
              'form.ticketed-venue-capacity.pristine',
              'form.reason.valid',
            ],
          },
          FOCUS_AVERAGE_TICKET_PRICE: {
            target: [
              'form.country-subdivision.valid',
              'form.confirmed.valid',
              'form.venue-name.valid',
              'form.venue-capacity.valid',
              'form.average-ticket-price.pristine',
              'form.payment-guarantee.valid',
              'form.booking-agency.valid',
              'form.booking-agent.valid',
              'form.promoter.valid',
              'form.country.valid',
              'form.tour-date-type.valid',
              'form.tour-date.valid',
              'form.ticketed-venue-capacity.pristine',
              'form.reason.valid',
            ],
          },
          FOCUS_PAYMENT_GUARANTEE: {
            target: [
              'form.country-subdivision.valid',
              'form.confirmed.valid',
              'form.venue-name.valid',
              'form.venue-capacity.valid',
              'form.average-ticket-price.valid',
              'form.payment-guarantee.pristine',
              'form.booking-agency.valid',
              'form.booking-agent.valid',
              'form.promoter.valid',
              'form.country.valid',
              'form.tour-date-type.valid',
              'form.tour-date.valid',
              'form.ticketed-venue-capacity.valid',
              'form.reason.valid',
            ],
          },
          FOCUS_BOOKING_AGENCY: {
            target: [
              'form.country-subdivision.valid',
              'form.confirmed.valid',
              'form.venue-name.valid',
              'form.venue-capacity.valid',
              'form.average-ticket-price.valid',
              'form.payment-guarantee.valid',
              'form.booking-agency.pristine',
              'form.booking-agent.valid',
              'form.promoter.valid',
              'form.country.valid',
              'form.tour-date-type.valid',
              'form.tour-date.valid',
              'form.ticketed-venue-capacity.valid',
              'form.reason.valid',
            ],
          },
          FOCUS_BOOKING_AGENT: {
            target: [
              'form.country-subdivision.valid',
              'form.confirmed.valid',
              'form.venue-name.valid',
              'form.venue-capacity.valid',
              'form.average-ticket-price.valid',
              'form.payment-guarantee.valid',
              'form.booking-agency.valid',
              'form.booking-agent.pristine',
              'form.promoter.valid',
              'form.country.valid',
              'form.tour-date-type.valid',
              'form.tour-date.valid',
              'form.ticketed-venue-capacity.valid',
              'form.reason.valid',
            ],
          },
          FOCUS_PROMOTER: {
            target: [
              'form.country-subdivision.valid',
              'form.confirmed.valid',
              'form.venue-name.valid',
              'form.venue-capacity.valid',
              'form.average-ticket-price.valid',
              'form.payment-guarantee.valid',
              'form.booking-agency.valid',
              'form.booking-agent.valid',
              'form.promoter.pristine',
              'form.country.valid',
              'form.tour-date-type.valid',
              'form.tour-date.valid',
              'form.ticketed-venue-capacity.valid',
              'form.reason.valid',
            ],
          },
          FOCUS_REASON: {
            target: [
              'form.country-subdivision.valid',
              'form.confirmed.valid',
              'form.venue-name.valid',
              'form.venue-capacity.valid',
              'form.average-ticket-price.valid',
              'form.payment-guarantee.valid',
              'form.booking-agency.valid',
              'form.booking-agent.valid',
              'form.promoter.valid',
              'form.country.valid',
              'form.tour-date-type.valid',
              'form.tour-date.valid',
              'form.ticketed-venue-capacity.valid',
              'form.reason.pristine',
            ],
          },
          SET_TOUR_COUNTRY: {
            target: [
              'form.country-subdivision.valid',
              'form.confirmed.valid',
              'form.venue-name.valid',
              'form.venue-capacity.valid',
              'form.average-ticket-price.valid',
              'form.payment-guarantee.valid',
              'form.booking-agency.valid',
              'form.booking-agent.valid',
              'form.promoter.valid',
              'form.country.setting-country',
              'form.tour-date-type.valid',
              'form.tour-date.valid',
              'form.ticketed-venue-capacity.valid',
              'form.reason.valid',
            ],
          },
          SET_TOUR_DATE_TYPE: {
            target: [
              'form.country-subdivision.valid',
              'form.confirmed.valid',
              'form.venue-name.valid',
              'form.venue-capacity.valid',
              'form.average-ticket-price.valid',
              'form.payment-guarantee.valid',
              'form.booking-agency.valid',
              'form.booking-agent.valid',
              'form.promoter.valid',
              'form.country.valid',
              'form.tour-date-type.setting',
              'form.tour-date.valid',
              'form.ticketed-venue-capacity.pristine',
              'form.reason.valid',
            ],
          },
          SET_TOUR_DATE: {
            target: [
              'form.country-subdivision.valid',
              'form.confirmed.valid',
              'form.venue-name.valid',
              'form.venue-capacity.valid',
              'form.average-ticket-price.valid',
              'form.payment-guarantee.valid',
              'form.booking-agency.valid',
              'form.booking-agent.valid',
              'form.promoter.valid',
              'form.country.valid',
              'form.tour-date-type.valid',
              'form.tour-date.setting',
              'form.ticketed-venue-capacity.valid',
              'form.reason.valid',
            ],
          },
          CHECK_TICKETED_VENUE_CAPACITY: {
            target: [
              'form.country-subdivision.valid',
              'form.confirmed.valid',
              'form.venue-name.valid',
              'form.venue-capacity.valid',
              'form.average-ticket-price.valid',
              'form.payment-guarantee.valid',
              'form.booking-agency.valid',
              'form.booking-agent.valid',
              'form.promoter.valid',
              'form.country.valid',
              'form.tour-date-type.valid',
              'form.tour-date.valid',
              'form.ticketed-venue-capacity.checking',
              'form.reason.valid',
            ],
          },
          REFRESH_TOUR_DATE_REGIONS: {
            target: [
              'form.country-subdivision.valid',
              'form.confirmed.valid',
              'form.venue-name.valid',
              'form.venue-capacity.valid',
              'form.average-ticket-price.valid',
              'form.payment-guarantee.valid',
              'form.booking-agency.valid',
              'form.booking-agent.valid',
              'form.promoter.valid',
              'form.country.setting-region',
              'form.tour-date-type.valid',
              'form.tour-date.valid',
              'form.ticketed-venue-capacity.valid',
              'form.reason.valid',
            ],
          },
        },
      },
      saving: {
        invoke: {
          id: 'validateCompleteTourDate',
          src: 'validateCompleteTourDate',
          onDone: { target: 'complete', actions: 'addTourDate' },
          onError: 'form.warning.error',
        },
      },
    },
    on: {
      CLOSE_TOUR_DATE: { actions: 'closeTourDate' },
    },
  },
  {
    guards: {
      isEmpty: ({ tourDate }, { type }) =>
        match(type)
          .with('BLUR_COUNTRY_SUBDIVISION', () => tourDate.countrySubdivision)
          .with('BLUR_VENUE_NAME', () => tourDate.venueName)
          .with('BLUR_VENUE_CAPACITY', () => tourDate.venueCapacity)
          .with('BLUR_AVERAGE_TICKET_PRICE', () => tourDate.averageTicketPrice)
          .with('BLUR_PAYMENT_GUARANTEE', () => tourDate.paymentGuarantee)
          .with('BLUR_BOOKING_AGENCY', () => tourDate.bookingAgency)
          .with('BLUR_BOOKING_AGENT', () => tourDate.bookingAgent)
          .with('BLUR_PROMOTER', () => tourDate.promoter)
          .with('BLUR_REASON', () => tourDate.reason)
          .exhaustive() === '',

      isNumeric: ({ tourDate }, { type }) =>
        isNaN(
          match(type)
            .with('BLUR_VENUE_CAPACITY', () =>
              parsePositiveInteger(tourDate.venueCapacity)
            )
            .with('BLUR_AVERAGE_TICKET_PRICE', () =>
              parsePositiveFloat(tourDate.averageTicketPrice)
            )
            .with('BLUR_PAYMENT_GUARANTEE', () =>
              parsePositiveFloat(tourDate.paymentGuarantee)
            )
            .run()
        ),
      isBeforeCutoff: ({ tourDate: { date }, presentCutoffDate }) =>
        isBeforeCutoff(presentCutoffDate, date),
      isAfterCutoff: ({ tourDate: { date }, futureCutoffDate }) =>
        futureCutoffDate !== undefined && isAfterCutoff(futureCutoffDate, date),
      aboveMaximumTicketedVenueCapacity: ({
        tourDate: { tourDateType, venueCapacity, averageTicketPrice },
      }) =>
        aboveMaximumTicketedVenueCapacity(
          parsePositiveInteger(venueCapacity),
          parsePositiveFloat(averageTicketPrice),
          tourDateType
        ),
      belowMinimumTicketedVenueCapacity: ({
        tourDate: { tourDateType, venueCapacity, averageTicketPrice },
      }) =>
        belowMinimumTicketedVenueCapacity(
          parsePositiveInteger(venueCapacity),
          parsePositiveFloat(averageTicketPrice),
          tourDateType
        ),
      isCapHit: ({
        tourDate: { tourDateCountry },
        tourDateRegions,
        editContext,
      }) => {
        const region = tourDateRegions.find(
          ({ id }) => id === tourDateCountry?.regionId
        );

        return (
          region !== undefined &&
          region.availableTourDates === 0 &&
          // You only exceed the cap if the tour date is new or
          // if the year/region has change into a year/region with
          // no capacity.
          (editContext === undefined || editContext.regionId !== region.id)
        );
      },
      isAlternate: ({ alternate }) => alternate,
    },
    actions: {
      logMachineError,
      addTourDate: sendParent(({ editContext }, { data: tourDate }) =>
        editContext === undefined
          ? addTourDate(prepareTourDate(tourDate))
          : updateTourDate(editContext.id, prepareTourDate(tourDate))
      ),
      closeTourDate: sendParent(({ editContext }) =>
        closeTourDate(editContext?.id)
      ),
      setTourDate: assign((context, event) => ({
        tourDate: {
          ...context.tourDate,
          date: event.tourDate,
        },
      })),
      setTourDateType: assign((context, { tourDateType }) => ({
        tourDate: {
          ...context.tourDate,
          tourDateType,
        },
      })),
      setConfirmed: assign((context, event) => ({
        tourDate: {
          ...context.tourDate,
          confirmed: event.confirmed,
        },
      })),
      setCountrySubdivision: assign((context, event) => ({
        tourDate: {
          ...context.tourDate,
          countrySubdivision: event.countrySubdivision,
        },
      })),
      setCountry: assign((context, event) => ({
        tourDate: {
          ...context.tourDate,
          tourDateCountry: event.tourDateCountry,
        },
      })),
      setVenueName: assign((context, event) => ({
        tourDate: {
          ...context.tourDate,
          venueName: event.venueName,
        },
      })),
      setVenueCapacity: assign((context, event) => ({
        tourDate: {
          ...context.tourDate,
          venueCapacity: event.venueCapacity,
        },
      })),
      setAverageTicketPrice: assign((context, event) => ({
        tourDate: {
          ...context.tourDate,
          averageTicketPrice: event.averageTicketPrice,
        },
      })),
      setPaymentGuaranteee: assign((context, event) => ({
        tourDate: {
          ...context.tourDate,
          paymentGuarantee: event.paymentGuarantee,
        },
      })),
      setBookingAgency: assign((context, event) => ({
        tourDate: {
          ...context.tourDate,
          bookingAgency: event.bookingAgency,
        },
      })),
      setBookingAgent: assign((context, event) => ({
        tourDate: {
          ...context.tourDate,
          bookingAgent: event.bookingAgent,
        },
      })),
      setPromoter: assign((context, event) => ({
        tourDate: {
          ...context.tourDate,
          promoter: event.promoter,
        },
      })),
      setReason: assign((context, event) => ({
        tourDate: {
          ...context.tourDate,
          reason: event.reason,
        },
      })),
      formatNumericValue: assign(({ tourDate }, event) => ({
        tourDate: {
          ...tourDate,
          ...match(event.type)
            .with('BLUR_VENUE_CAPACITY', () => ({
              venueCapacity: formatNumber(
                parsePositiveInteger(tourDate.venueCapacity)
              ),
            }))
            .with('BLUR_AVERAGE_TICKET_PRICE', () => ({
              averageTicketPrice: formatNumber(
                parsePositiveFloat(tourDate.averageTicketPrice)
              ),
            }))
            .with('BLUR_PAYMENT_GUARANTEE', () => ({
              paymentGuarantee: formatNumber(
                parsePositiveFloat(tourDate.paymentGuarantee)
              ),
            }))
            .exhaustive(),
        },
      })),
      refreshTourDateRegions: assign((_, { tourDateRegions, editContext }) => ({
        tourDateRegions,
        editContext,
      })),
      checkTicketedVenueCapacity: send({
        type: 'CHECK_TICKETED_VENUE_CAPACITY',
      }),
    },
  }
);

type Machine = typeof machine;

export type TourDateMachine = Machine;
export type TourDateMachineState = StateFrom<Machine>;
export type TourDateMachineSender = Sender<EventFrom<Machine>>;
export type TourDateMachineEvent = EventFrom<Machine>;
export type TourDateMachineOptions = MachineOptionsFrom<Machine, true>;
export type TourDateMachineActor = ActorRefFrom<TourDateMachine>;
