import type {
  Sender,
  EventFrom,
  MachineOptionsFrom,
  ActorRefFrom,
} from 'xstate';
import { createMachine, assign, sendParent } from 'xstate';
import { DeleteFilePromiseResult } from '../../promises/Attachments/createDeleteFilePromise';
import { GetFilePromiseResult } from '../../promises/Attachments/createGetFilePromise';
import { UploadFilePromiseResult } from '../../promises/Attachments/createUploadFilePromise';
import { Context as ParentContext } from '../Application/ApplicationMachine';
import { StateFrom } from '../../utils/StateFrom';
import { Attachment } from '../../schemas/attachment';
import { logMachineError } from '../../utils/logError';
import { refreshApplication } from '../../events/RefreshApplication';
import { ApplicationType } from '../../graphql/operations';

type Events =
  | { type: 'UPLOAD_TOUR_SUMMARY'; files: FileList }
  | { type: 'GET_TOUR_SUMMARY'; file: Attachment }
  | { type: 'DELETE_TOUR_SUMMARY'; file: Attachment };

export type Context = {
  applicationId: string;
  tourSummary: ParentContext['tourSummary'];
  applicationType: ApplicationType;
};

type Services = {
  getFilePromise: {
    data: GetFilePromiseResult;
  };
  uploadFilePromise: {
    data: UploadFilePromiseResult;
  };
  deleteFilePromise: {
    data: DeleteFilePromiseResult;
  };
};

export const createContext = (context: Context): Context => context;

export const machine = createMachine(
  {
    id: 'tourSummaryMachine',
    predictableActionArguments: true,
    tsTypes: {} as import('./TourSummaryMachine.typegen').Typegen0,
    schema: {
      context: {} as Context,
      events: {} as Events,
      services: {} as Services,
    },
    initial: 'default',
    on: {
      UPLOAD_TOUR_SUMMARY: 'uploading',
      DELETE_TOUR_SUMMARY: 'deleting',
      GET_TOUR_SUMMARY: 'fetchingUrl',
    },
    states: {
      default: {},
      uploading: {
        invoke: {
          id: 'uploadFilePromise',
          src: 'uploadFilePromise',
          onDone: {
            actions: ['updateTourSummary', 'refreshApplication'],
            target: 'default',
          },
          onError: '#tourSummaryMachine.error',
        },
      },
      deleting: {
        invoke: {
          id: 'deleteFilePromise',
          src: 'deleteFilePromise',
          onDone: {
            actions: ['deleteTourSummary', 'refreshApplication'],
            target: 'default',
          },
          onError: '#tourSummaryMachine.error',
        },
      },
      fetchingUrl: {
        invoke: {
          id: 'getFilePromise',
          src: 'getFilePromise',
          onDone: { actions: 'openAttachment', target: 'default' },
          onError: '#tourSummaryMachine.error',
        },
      },
      error: { entry: 'logMachineError' },
    },
  },
  {
    actions: {
      logMachineError,
      refreshApplication: sendParent(refreshApplication()),
      updateTourSummary: assign({
        tourSummary: (_, { data }) => data[0],
      }),
      deleteTourSummary: assign({
        tourSummary: (_) => null,
      }),
    },
  }
);

type Machine = typeof machine;

export type TourSummaryMachineState = StateFrom<Machine>;
export type TourSummaryMachineSender = Sender<EventFrom<Machine>>;
export type TourSummaryMachineOptions = MachineOptionsFrom<Machine, true> & {
  context: Context;
};
export type TourSummaryMachineEvent = EventFrom<Machine>;
export type TourSummaryMachineActor = ActorRefFrom<Machine>;
