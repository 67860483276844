import type { EventFrom, Sender } from 'xstate';
import { assign, createMachine } from 'xstate';
import type { StateFrom } from '../utils/StateFrom';
import type { MachineOptionsWithContextFrom } from '../utils/MachineOptionsWithContextFrom';
import { GetAccountDetailsPromiseResult } from '../promises/createGetAccountDetailsPromise';
import { match } from 'ts-pattern';
import { isEmailValid } from '../utils/isEmailValid';
import { isPhoneNumberValid } from '../utils/isPhoneNumberValid';
import { UpdateAccountDetailsPromiseResult } from '../promises/createUpdateAccountDetailsPromise';
import { ChangePasswordPromiseResult } from '../promises/createChangePasswordPromise';
import { ChangePasswordResponseCode } from '../graphql/operations';
import { DeleteAccountArtistBinding } from '../events/DeleteAccountArtistBinding';
import { CreateSearchArtistsPromiseResult } from '../promises/Admin/createSearchArtistsPromise';
import { sendTo } from 'xstate/lib/actions';
import { CreateAccountArtistBinding } from '../events/CreateAccountArtistBinding';
import { CreateAccountArtistBindingPromiseResult } from '../promises/Admin/createCreateAccountArtistBindingPromise';
import { DeleteAccountArtistBindingAdminPromiseResult } from '../promises/Admin/createDeleteAccountArtistBindingAdminPromise';
import { StatesConfig } from '../utils/StateConfig';
import { isIdNotFound } from '../errors/IdNotFoundError';
import { logMachineError } from '../utils/logError';

type Events =
  | { type: 'SIGN_OUT' }
  | { type: 'CHANGE_PASSWORD' }
  | { type: 'TOGGLE_EDIT_DETAILS' }
  | { type: 'TOGGLE_EDIT_PASSWORD' }
  | { type: 'SET_NAME'; name: string }
  | { type: 'BLUR_NAME' }
  | { type: 'FOCUS_NAME' }
  | { type: 'SET_EMAIL'; email: string }
  | { type: 'BLUR_EMAIL' }
  | { type: 'FOCUS_EMAIL' }
  | { type: 'SET_PHONE_NUMBER'; phoneNumber: string }
  | { type: 'BLUR_PHONE_NUMBER' }
  | { type: 'FOCUS_PHONE_NUMBER' }
  | { type: 'SET_ADDRESS'; address: string }
  | { type: 'BLUR_ADDRESS' }
  | { type: 'FOCUS_ADDRESS' }
  | { type: 'SAVE_DETAILS' }
  | { type: 'SET_CURRENT_PASSWORD'; currentPassword: string }
  | { type: 'BLUR_CURRENT_PASSWORD' }
  | { type: 'FOCUS_CURRENT_PASSWORD' }
  | { type: 'SET_NEW_PASSWORD'; newPassword: string }
  | { type: 'BLUR_NEW_PASSWORD' }
  | { type: 'FOCUS_NEW_PASSWORD' }
  | { type: 'SET_CONFIRM_PASSWORD'; confirmPassword: string }
  | { type: 'BLUR_CONFIRM_PASSWORD' }
  | { type: 'FOCUS_CONFIRM_PASSWORD' }
  | { type: 'TOGGLE_CURRENT_PASSWORD_VISIBILITY' }
  | { type: 'TOGGLE_NEW_PASSWORD_VISIBILITY' }
  | { type: 'TOGGLE_CONFIRM_PASSWORD_VISIBILITY' }
  | { type: 'SAVE_PASSWORD' }
  | { type: 'TOGGLE_SHOW_ADD_ARTIST' }
  | {
      type: 'RECEIVE_SEARCHED_ARTISTS';
      artists: CreateSearchArtistsPromiseResult;
    }
  | { type: 'SET_SEARCH_TERM'; searchTerm: string }
  | { type: 'UPDATE_ACCOUNT_ID'; accountId: string }
  | CreateAccountArtistBinding
  | DeleteAccountArtistBinding;

type Services = {
  getAccountDetailsPromise: {
    data: GetAccountDetailsPromiseResult;
  };
  updateAccountDetailsPromise: {
    data: UpdateAccountDetailsPromiseResult;
  };
  changePasswordPromise: {
    data: ChangePasswordPromiseResult;
  };
  createAccountArtistBinding: {
    data: CreateAccountArtistBindingPromiseResult;
  };
  deleteAccountArtistBinding: {
    data: DeleteAccountArtistBindingAdminPromiseResult;
  };
};

const inputStates: StatesConfig<Context, Events> = {
  pristine: { on: { SAVE_DETAILS: 'invalid', SAVE_PASSWORD: 'invalid' } },
  valid: { type: 'final' },
  invalid: {},
};

interface AccountDetails {
  name: string;
  phoneNumber: string;
  createdAt?: string;
  email: string;
  address: string;
}

export type Context = {
  accountId: string;
  initialDetails: AccountDetails;
  editedDetails: AccountDetails;
  currentPassword: string;
  newPassword: string;
  confirmPassword: string;
  minimumPasswordLength: number;
  showCurrentPassword: boolean;
  showNewPassword: boolean;
  showConfirmPassword: boolean;
  artists: GetAccountDetailsPromiseResult['account']['artists'];
  searchTerm: string;
  searchedArtists: CreateSearchArtistsPromiseResult;
  isAdmin: boolean;
  isYou: boolean;
};

export const createContext = (
  accountId: string,
  isAdmin: boolean,
  isYou: boolean
): Context => ({
  accountId,
  initialDetails: {
    name: '',
    phoneNumber: '',
    createdAt: '',
    email: '',
    address: '',
  },
  editedDetails: {
    name: '',
    phoneNumber: '',
    email: '',
    address: '',
  },
  currentPassword: '',
  newPassword: '',
  confirmPassword: '',
  minimumPasswordLength: 0,
  showCurrentPassword: false,
  showNewPassword: false,
  showConfirmPassword: false,
  artists: [],
  searchTerm: '',
  searchedArtists: [],
  isAdmin,
  isYou,
});

export const machine = createMachine(
  {
    predictableActionArguments: true,
    tsTypes: {} as import('./AccountDetailsMachine.typegen').Typegen0,
    id: 'account',
    schema: {
      context: {} as Context,
      events: {} as Events,
      services: {} as Services,
    },
    initial: 'init',
    states: {
      init: {
        invoke: {
          id: 'getAccountDetailsPromise',
          src: 'getAccountDetailsPromise',
          onDone: {
            actions: 'setContext',
            target: 'ready',
          },
          onError: [
            { cond: 'isIdNotFound', target: 'notFound' },
            { target: 'initError' },
          ],
        },
      },
      initError: { entry: 'logMachineError' },
      notFound: {},
      ready: {
        initial: 'none',
        states: {
          none: {},
          'updated-details': {},
          'updated-password': {},
          'artist-added': {},
          'artist-removed': {},
          'artist-error': { entry: 'logMachineError' },
          'update-error': { entry: 'logMachineError' },
        },
        on: {
          TOGGLE_EDIT_DETAILS: {
            target: 'details',
          },
          TOGGLE_EDIT_PASSWORD: {
            target: 'password',
          },
          DELETE_ACCOUNT_ARTIST_BINDING: 'delete-binding',
          TOGGLE_SHOW_ADD_ARTIST: {
            target: 'search',
          },
        },
      },
      password: {
        initial: 'in-progress',
        states: {
          'in-progress': {
            type: 'parallel',
            states: {
              current: {
                initial: 'pristine',
                states: {
                  ...inputStates,
                  invalid: {
                    initial: 'empty',
                    states: {
                      empty: {},
                      incorrect: {},
                    },
                  },
                },
                on: {
                  SET_CURRENT_PASSWORD: {
                    actions: 'setCurrentPassword',
                  },
                  BLUR_CURRENT_PASSWORD: [
                    { cond: 'isEmpty', target: '.invalid.empty' },
                    '.valid',
                  ],
                },
              },
              new: {
                initial: 'pristine',
                states: {
                  ...inputStates,
                  invalid: {
                    initial: 'empty',
                    states: {
                      empty: {},
                      length: {},
                    },
                  },
                },
                on: {
                  SET_NEW_PASSWORD: {
                    actions: 'setNewPassword',
                  },
                  BLUR_NEW_PASSWORD: [
                    { cond: 'isEmpty', target: '.invalid.empty' },
                    {
                      cond: 'isNewPasswordLengthInvalid',
                      target: '.invalid.length',
                    },
                    '.valid',
                  ],
                },
              },
              confirm: {
                initial: 'pristine',
                states: {
                  ...inputStates,
                  invalid: {
                    initial: 'empty',
                    states: {
                      empty: {},
                      length: {},
                      different: {
                        on: {
                          BLUR_NEW_PASSWORD: [
                            {
                              cond: 'arePasswordsDifferent',
                            },
                            '#account.password.in-progress.confirm.valid',
                          ],
                        },
                      },
                    },
                  },
                },
                on: {
                  SET_CONFIRM_PASSWORD: {
                    actions: 'setConfirmPassword',
                  },
                  BLUR_NEW_PASSWORD: [
                    {
                      cond: 'arePasswordsDifferent',
                      target: '.invalid.different',
                    },
                  ],
                  BLUR_CONFIRM_PASSWORD: [
                    { cond: 'isEmpty', target: '.invalid.empty' },

                    {
                      cond: 'arePasswordsDifferent',
                      target: '.invalid.different',
                    },
                    '.valid',
                  ],
                },
              },
              warning: {
                initial: 'off',
                states: {
                  on: {
                    type: 'final',
                    on: { SAVE_PASSWORD: 'pulse' },
                  },
                  off: {
                    type: 'final',
                    on: { SAVE_PASSWORD: 'on' },
                  },
                  pulse: {
                    type: 'final',
                    after: { 1000: 'on' },
                  },
                },
              },
            },
            on: {
              TOGGLE_EDIT_DETAILS: {
                actions: 'resetFields',
                target: '#account.details',
              },
            },
            onDone: 'complete',
          },
          complete: {
            on: {
              FOCUS_CURRENT_PASSWORD: {
                target: [
                  'in-progress.current.pristine',
                  'in-progress.new.valid',
                  'in-progress.confirm.valid',
                ],
              },
              FOCUS_NEW_PASSWORD: {
                target: [
                  'in-progress.current.valid',
                  'in-progress.new.pristine',
                  'in-progress.confirm.valid',
                ],
              },
              FOCUS_CONFIRM_PASSWORD: {
                target: [
                  'in-progress.current.valid',
                  'in-progress.new.valid',
                  'in-progress.confirm.pristine',
                ],
              },
              SAVE_PASSWORD: 'saving',
            },
          },
          saving: {
            invoke: {
              id: 'changePasswordPromise',
              src: 'changePasswordPromise',
              onDone: [
                {
                  cond: 'isPasswordIncorrect',
                  target: [
                    '#account.password.in-progress.current.invalid.incorrect',
                    '#account.password.in-progress.new.valid',
                    '#account.password.in-progress.confirm.valid',
                  ],
                },
                {
                  target: '#account.ready.updated-password',
                },
              ],
              onError: '#account.ready.update-error',
            },
          },
        },
        on: {
          TOGGLE_EDIT_PASSWORD: {
            actions: 'resetFields',
            target: '#account.ready',
          },
          TOGGLE_CURRENT_PASSWORD_VISIBILITY: {
            actions: 'toggleCurrentPasswordVisibility',
          },
          TOGGLE_NEW_PASSWORD_VISIBILITY: {
            actions: 'toggleNewPasswordVisibility',
          },
          TOGGLE_CONFIRM_PASSWORD_VISIBILITY: {
            actions: 'toggleConfirmPasswordVisibility',
          },
        },
      },
      details: {
        initial: 'in-progress',
        states: {
          'in-progress': {
            type: 'parallel',
            states: {
              name: {
                initial: 'valid',
                states: inputStates,
                on: {
                  SET_NAME: {
                    actions: 'setName',
                  },
                  BLUR_NAME: [
                    { cond: 'isEmpty', target: '.invalid' },
                    '.valid',
                  ],
                },
              },
              email: {
                initial: 'valid',
                states: {
                  ...inputStates,
                  invalid: {
                    states: {
                      empty: {},
                      value: {},
                    },
                  },
                },
                on: {
                  SET_EMAIL: {
                    actions: 'setEmail',
                  },
                  BLUR_EMAIL: [
                    { cond: 'isEmpty', target: '.invalid.empty' },
                    { cond: 'isEmailInvalid', target: '.invalid.value' },
                    '.valid',
                  ],
                },
              },
              phone: {
                initial: 'valid',
                states: {
                  ...inputStates,
                  invalid: {
                    states: {
                      empty: {},
                      value: {},
                    },
                  },
                },
                on: {
                  SET_PHONE_NUMBER: {
                    actions: 'setPhoneNumber',
                  },
                  BLUR_PHONE_NUMBER: [
                    { cond: 'isEmpty', target: '.invalid.empty' },
                    { cond: 'isPhoneNumberInvalid', target: '.invalid.value' },
                    '.valid',
                  ],
                },
              },
              address: {
                initial: 'valid',
                states: inputStates,
                on: {
                  SET_ADDRESS: {
                    actions: 'setAddress',
                  },
                  BLUR_ADDRESS: [
                    { cond: 'isEmpty', target: '.invalid' },
                    '.valid',
                  ],
                },
              },
              warning: {
                initial: 'off',
                states: {
                  on: {
                    type: 'final',
                    on: { SAVE_DETAILS: 'pulse' },
                  },
                  off: {
                    type: 'final',
                    on: { SAVE_DETAILS: 'on' },
                  },
                  pulse: {
                    type: 'final',
                    after: { 1000: 'on' },
                  },
                },
              },
            },
            onDone: 'complete',
          },
          complete: {
            on: {
              FOCUS_NAME: {
                target: [
                  'in-progress.name.pristine',
                  'in-progress.email.valid',
                  'in-progress.phone.valid',
                  'in-progress.address.valid',
                ],
              },
              FOCUS_EMAIL: {
                target: [
                  'in-progress.name.valid',
                  'in-progress.email.pristine',
                  'in-progress.phone.valid',
                  'in-progress.address.valid',
                ],
              },
              FOCUS_PHONE_NUMBER: {
                target: [
                  'in-progress.name.valid',
                  'in-progress.email.valid',
                  'in-progress.phone.pristine',
                  'in-progress.address.valid',
                ],
              },
              FOCUS_ADDRESS: {
                target: [
                  'in-progress.name.valid',
                  'in-progress.email.valid',
                  'in-progress.phone.valid',
                  'in-progress.address.pristine',
                ],
              },
              SAVE_DETAILS: 'saving',
            },
          },
          saving: {
            invoke: {
              id: 'updateAccountDetailsPromise',
              src: 'updateAccountDetailsPromise',
              onDone: {
                actions: 'setUpdateContext',
                target: '#account.ready.updated-details',
              },
              onError: '#account.ready.update-error',
            },
          },
        },
        on: {
          TOGGLE_EDIT_DETAILS: {
            actions: 'resetFields',
            target: '#account.ready',
          },
          TOGGLE_EDIT_PASSWORD: {
            actions: 'resetFields',
            target: '#account.password',
          },
        },
      },
      'delete-binding': {
        invoke: {
          id: 'deleteAccountArtistBinding',
          src: 'deleteAccountArtistBinding',
          onDone: {
            actions: 'removeArtistBinding',
            target: 'ready.artist-removed',
          },
          onError: '#account.ready.artist-error',
        },
      },
      'create-binding': {
        invoke: {
          id: 'createAccountArtistBinding',
          src: 'createAccountArtistBinding',
          onDone: {
            actions: 'createArtistBinding',
            target: 'ready.artist-added',
          },
          onError: '#account.ready.artist-error',
        },
      },
      search: {
        exit: 'resetSearchTerm',
        invoke: {
          id: 'artistSearchMachine',
          src: 'artistSearchMachine',
        },
        on: {
          RECEIVE_SEARCHED_ARTISTS: {
            actions: 'setSearchedArtists',
          },
          SET_SEARCH_TERM: {
            actions: ['setSearchTerm', 'sendSearchTerm'],
          },
          CREATE_ACCOUNT_ARTIST_BINDING: 'create-binding',
          TOGGLE_SHOW_ADD_ARTIST: 'ready',
        },
      },
    },
    on: {
      SIGN_OUT: {
        actions: 'signOut',
      },
      UPDATE_ACCOUNT_ID: {
        actions: 'updateAccountId',
        target: '.init',
      },
    },
  },
  {
    guards: {
      isEmpty: (
        { editedDetails, currentPassword, newPassword, confirmPassword },
        { type }
      ) => {
        const value = match(type)
          .with('BLUR_NAME', () => editedDetails.name)
          .with('BLUR_EMAIL', () => editedDetails.email)
          .with('BLUR_PHONE_NUMBER', () => editedDetails.phoneNumber)
          .with('BLUR_ADDRESS', () => editedDetails.address)
          .with('BLUR_CURRENT_PASSWORD', () => currentPassword)
          .with('BLUR_NEW_PASSWORD', () => newPassword)
          .with('BLUR_CONFIRM_PASSWORD', () => confirmPassword)
          .exhaustive();

        return value === '' || value === null;
      },
      isPhoneNumberInvalid: ({ editedDetails: { phoneNumber } }) =>
        !isPhoneNumberValid(phoneNumber),
      isEmailInvalid: ({ editedDetails: { email } }) => !isEmailValid(email),
      isNewPasswordLengthInvalid: ({ newPassword, minimumPasswordLength }) =>
        newPassword.length < minimumPasswordLength,
      arePasswordsDifferent: ({ newPassword, confirmPassword }) =>
        confirmPassword !== '' && newPassword !== confirmPassword,
      isPasswordIncorrect: (_, e) =>
        e.data.response.code === ChangePasswordResponseCode.WrongPassword,
      isIdNotFound: (_, { data }) => isIdNotFound(data),
    },
    actions: {
      logMachineError,
      setContext: assign((_, { data }) => ({
        initialDetails: data.account,
        editedDetails: {
          name: data.account.name,
          phoneNumber: data.account.phoneNumber,
          email: data.account.email,
          address: data.account.address,
        },
        minimumPasswordLength: data?.minimumPasswordLength,
        artists: data.account.artists,
      })),
      setUpdateContext: assign((_, { data }) => ({
        initialDetails: data.account,
        editedDetails: {
          name: data.account.name,
          phoneNumber: data.account.phoneNumber,
          email: data.account.email,
          address: data.account.address,
        },
      })),
      setName: assign((context, event) => ({
        editedDetails: {
          ...context.editedDetails,
          name: event.name,
        },
      })),
      setEmail: assign((context, event) => ({
        editedDetails: {
          ...context.editedDetails,
          email: event.email,
        },
      })),
      setPhoneNumber: assign((context, event) => ({
        editedDetails: {
          ...context.editedDetails,
          phoneNumber: event.phoneNumber,
        },
      })),
      setAddress: assign((context, event) => ({
        editedDetails: {
          ...context.editedDetails,
          address: event.address,
        },
      })),
      setCurrentPassword: assign((_, event) => ({
        currentPassword: event.currentPassword,
      })),
      setNewPassword: assign((_, event) => ({
        newPassword: event.newPassword,
      })),
      setConfirmPassword: assign((_, event) => ({
        confirmPassword: event.confirmPassword,
      })),
      toggleCurrentPasswordVisibility: assign((context) => ({
        showCurrentPassword: !context.showCurrentPassword,
      })),
      toggleNewPasswordVisibility: assign((context) => ({
        showNewPassword: !context.showNewPassword,
      })),
      toggleConfirmPasswordVisibility: assign((context) => ({
        showConfirmPassword: !context.showConfirmPassword,
      })),
      resetFields: assign((context) => ({
        editedDetails: {
          name: context.initialDetails.name,
          phoneNumber: context.initialDetails.phoneNumber,
          email: context.initialDetails.email,
          address: context.initialDetails.address,
        },
        currentPassword: '',
        newPassword: '',
        confirmPassword: '',
      })),
      resetSearchTerm: assign((_) => ({
        searchTerm: '',
      })),
      createArtistBinding: assign((context, event) => ({
        artists: [
          ...context.artists,
          {
            artist: {
              id: event.data.id,
              name: event.data.name,
            },
          },
        ],
      })),
      removeArtistBinding: assign((context, event) => ({
        artists: context.artists.filter(
          ({ artist }) => artist.id !== event.data.artistId
        ),
      })),
      setSearchedArtists: assign((context, event) => ({
        searchedArtists: event.artists,
      })),
      setSearchTerm: assign((_, { searchTerm }) => ({
        searchTerm,
      })),
      sendSearchTerm: sendTo('artistSearchMachine', (_, { searchTerm }) => ({
        type: 'SET_SEARCH_TERM',
        searchTerm,
      })),
      updateAccountId: assign((_, event) => ({
        accountId: event.accountId,
      })),
    },
  }
);

type Machine = typeof machine;

export type AccountMachineState = StateFrom<Machine>;
export type AccountMachineSender = Sender<EventFrom<Machine>>;
export type AccountMachineOptions = MachineOptionsWithContextFrom<Machine>;
