import { parsePositiveInteger } from './parsePositiveInteger';
import { zeroNaNs } from './zeroNaNs';

export type Sales = {
  value: string;
  divisor: number;
};

export const totalUnits = (sales: Sales[]) =>
  sales.reduce(
    (total, { value, divisor }) =>
      total + Math.floor(zeroNaNs(parsePositiveInteger(value)) / divisor),
    0
  );
