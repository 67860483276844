import { useMachine } from '@xstate/react';
import {
  machine,
  ApplicationUseMachineOptions,
} from '../../machines/Application/ApplicationMachine';
import { ApplicationView } from './ApplicationView';

type ApplicationControllerProps = {
  options: ApplicationUseMachineOptions;
};

export function ApplicationController({ options }: ApplicationControllerProps) {
  const [state, send] = useMachine(machine, options);

  return <ApplicationView state={state} send={send} />;
}
