import { FormField } from '../../FormField';
import { InlineFormContainer } from '../../InlineFormContainer';
import { TextInput } from '../../TextInput';
import {
  ArtistManagementMachineSender,
  ArtistManagementMachineState,
} from '../../../machines/components/ArtistManagementMachine';
import { InlineFormHeading } from '../../InlineFormHeading';
import { Select } from '../../Select';
import { RadioOption } from '../../RadioOption';
import { Feedback } from '../../Feedback';
import { findById } from '../../../utils/findById';
import { ErrorFeedback } from '../../ErrorFeedback';
import { RequiredFormField } from '../../RequiredFormField';
import { TextArea } from '../../TextArea';

interface ManagementFormProps {
  state: ArtistManagementMachineState;
  send: ArtistManagementMachineSender;
}

export function ManagementForm({ state, send }: ManagementFormProps) {
  const { provinces, legalStatuses, countries, management } = state.context;
  const {
    address,
    contactPerson,
    countryId,
    email,
    legalProvince,
    legalStatus: legalStatusId,
    name,
    phoneNumber,
  } = state.context.form;
  const currentLegalStatus = findById(legalStatuses, legalStatusId);

  const showWarning = state.matches('form.status.invalid');

  return (
    <InlineFormContainer
      primaryButtonText="Save Management"
      secondaryButtonText="Cancel"
      primaryButtonOnClick={() => {
        send('SUBMIT_FORM');
      }}
      secondaryButtonOnClick={() => {
        send('CANCEL_FORM');
      }}
    >
      <InlineFormHeading>
        {management ? 'Edit Management' : 'Add Management'}
      </InlineFormHeading>
      <RequiredFormField
        label="Management company name"
        showWarning={showWarning}
        isEmpty={!name.trim()}
      >
        <TextInput
          id="company-name"
          value={name}
          onChange={(value) => {
            send({ type: 'SET_FIELD', field: 'name', value });
          }}
        />
      </RequiredFormField>
      <RequiredFormField
        label="Management company nationality"
        showWarning={showWarning}
        isEmpty={!countryId}
      >
        <Select
          id="company-nationality"
          items={state.context.countries}
          itemToKey={(item) => item?.id}
          getItemText={(country) => country?.name ?? 'Choose an option'}
          selectedItem={findById(countries, countryId)}
          onChange={(country) => {
            if (country) {
              send({
                type: 'SET_FIELD',
                field: 'countryId',
                value: country.id,
              });
            }
          }}
        />
      </RequiredFormField>
      <RequiredFormField
        label="Contact person"
        showWarning={showWarning}
        isEmpty={!contactPerson.trim()}
      >
        <TextInput
          id="contact-person"
          value={contactPerson}
          onChange={(value) => {
            send({
              type: 'SET_FIELD',
              field: 'contactPerson',
              value,
            });
          }}
        />
      </RequiredFormField>
      <RequiredFormField
        label="Address"
        description="Include street address, city, prov/state, country, and postal code."
        showWarning={showWarning}
        isEmpty={!address.trim()}
      >
        <TextArea
          id="address"
          size="small"
          label="address"
          value={address}
          onChange={(value) => {
            send({ type: 'SET_FIELD', field: 'address', value });
          }}
        />
      </RequiredFormField>
      <FormField
        label="Phone number"
        formFieldStatus={
          state.matches('form.inputWarning.phone.on') ? 'error' : 'default'
        }
        feedbackType="warning"
        infoLabel={
          state.matches('form.inputWarning.phone.on')
            ? 'You must enter a valid phone number.'
            : ''
        }
      >
        <TextInput
          id="phone"
          value={phoneNumber}
          onChange={(value) => {
            send({
              type: 'SET_FIELD',
              field: 'phoneNumber',
              value,
            });
            send('CLEAR_PHONE_WARNING');
          }}
        />
      </FormField>
      <FormField
        label="Email"
        formFieldStatus={
          state.matches('form.inputWarning.email.on') ? 'error' : 'default'
        }
        feedbackType="warning"
        infoLabel={
          state.matches('form.inputWarning.email.on')
            ? 'You must enter a valid email.'
            : ''
        }
      >
        <TextInput
          id="email"
          value={email}
          onChange={(value) => {
            send({
              type: 'SET_FIELD',
              field: 'email',
              value,
            });
            send('CLEAR_EMAIL_WARNING');
          }}
        />
      </FormField>
      <RequiredFormField
        label="Company’s legal status"
        showWarning={showWarning}
        isEmpty={!legalStatusId}
      >
        <>
          {legalStatuses.map((status) => (
            <RadioOption
              key={status.id}
              label={status.name}
              description={status.description ?? ''}
              checked={status.id === legalStatusId}
              onChange={() => {
                send({
                  type: 'SET_FIELD',
                  field: 'legalStatus',
                  value: status.id,
                });
                if (!status.requiresProvince) {
                  send({
                    type: 'UNSET_COMPANY_PROVINCE',
                  });
                }
              }}
            />
          ))}
        </>
      </RequiredFormField>
      {currentLegalStatus?.requiresProvince &&
        currentLegalStatus.provinceTitle && (
          <RequiredFormField
            label={currentLegalStatus.provinceTitle}
            showWarning={showWarning}
            isEmpty={!legalProvince}
          >
            <Select
              id="management-province"
              items={provinces}
              itemToKey={(item) => item?.id}
              getItemText={(el) => el?.province ?? 'Choose an option'}
              selectedItem={findById(provinces, legalProvince ?? '')}
              onChange={(province) => {
                if (province) {
                  send({
                    type: 'SET_FIELD',
                    field: 'legalProvince',
                    value: province.id,
                  });
                }
              }}
            ></Select>
          </RequiredFormField>
        )}
      {showWarning && (
        <Feedback type="warning">Please address all the issues above.</Feedback>
      )}
      {state.matches('form.status.error') && <ErrorFeedback />}
    </InlineFormContainer>
  );
}
