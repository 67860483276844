import type {
  ActorRefFrom,
  EventFrom,
  MachineOptionsFrom,
  Sender,
} from 'xstate';
import { createMachine, sendParent } from 'xstate';
import { ReplayableEvent, createReplay } from '../../utils/replay';
import { back, next } from '../navigation';

import { StateFrom } from '../../utils/StateFrom';
import { EligibilityStorage } from './storage';

type YesEvent = ReplayableEvent<{ type: 'YES' }>;
type NoEvent = ReplayableEvent<{ type: 'NO' }>;
type ReplayableEvents = YesEvent | NoEvent;

type Events = { type: 'NEXT' } | { type: 'BACK' } | ReplayableEvents;

export const machine = createMachine(
  {
    predictableActionArguments: true,
    tsTypes: {} as import('./ResidentMachine.typegen').Typegen0,
    schema: {
      events: {} as Events,
    },
    id: 'eligibility',
    invoke: {
      id: 'resume',
      src: 'resume',
    },
    initial: 'pristine',
    states: {
      pristine: {
        on: {
          YES: { target: 'yes', actions: 'saveYes' },
          NO: { target: 'no.option.selected', actions: 'saveNo' },
          NEXT: 'no.option.unselected',
        },
      },
      yes: {
        on: {
          NEXT: { actions: 'next' },
          NO: { target: 'no.option.selected', actions: 'saveNo' },
        },
      },
      no: {
        type: 'parallel',
        states: {
          highlight: {
            initial: 'off',
            states: {
              on: {
                after: {
                  1000: { target: 'off' },
                },
              },
              off: {
                on: {
                  NEXT: { target: 'on' },
                },
              },
            },
          },
          option: {
            initial: 'unselected',
            states: {
              selected: {},
              unselected: {
                on: {
                  NO: { target: 'selected', actions: 'saveNo' },
                },
              },
            },
          },
        },
        on: {
          YES: { target: 'yes', actions: 'saveYes' },
        },
      },
    },
    on: {
      BACK: { actions: 'back' },
    },
  },
  {
    actions: {
      next: sendParent(next()),
      back: sendParent(back()),
    },
  }
);

type Machine = typeof machine;

export type ResidentMachineState = StateFrom<Machine>;
export type ResidentMachineSender = Sender<EventFrom<Machine>>;
export type ResidentMachineOptions = MachineOptionsFrom<Machine, true>;
export type ResidentMachineActor = ActorRefFrom<Machine>;

export const createResidentResume =
  (storage: EligibilityStorage) => () => (send: ResidentMachineSender) => {
    const replay = createReplay<ReplayableEvents>();

    const { resident } = storage.get();

    if (resident !== undefined) {
      send(replay({ type: 'YES' }));
      if (resident === false) {
        send(replay({ type: 'NO' }));
      }
    }
  };

export const createResidentSaveActions = (storage: EligibilityStorage) => ({
  saveYes: (_: unknown, { replay }: YesEvent) =>
    storage.add({ resident: true }, replay),
  saveNo: (_: unknown, { replay }: NoEvent) =>
    storage.add({ resident: false }, replay),
});
