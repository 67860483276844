import { Expense } from '../../schemas/fundingRequests/expenses/expenseSchema';

export type DeleteExpense = {
  type: 'DELETE_EXPENSE';
  id: Expense['id'];
};

export const deleteExpense = (id: DeleteExpense['id']): DeleteExpense => ({
  type: 'DELETE_EXPENSE',
  id,
});
