import { z } from 'zod';
import { GraphQLClient } from 'graphql-request';
import { ClearFluxCapacitorDocument } from '../graphql/operations';
import { serverDateTimeSchema } from '../schemas/dateTimeSchema';
import { parseAbsoluteOrNull } from '../utils/parseAbsoluteOrNull';

export const clearFluxCapacitor = (client: GraphQLClient) => async () => {
  const result = await client.request(ClearFluxCapacitorDocument);

  return z
    .object({ time: serverDateTimeSchema, timezone: z.string() })
    .transform(({ time, timezone }) => ({
      time: parseAbsoluteOrNull(time, timezone),
      timezone,
    }))
    .parse(result.result);
};

export type ClearFluxCapacitorPromise = ReturnType<typeof clearFluxCapacitor>;
export type ClearFluxCapacitorPromiseResult = Awaited<
  ReturnType<ClearFluxCapacitorPromise>
>;
