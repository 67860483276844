import { z } from 'zod';

const completeRedeemBoardInvitationStateSchema = z.object({
  account: z.object({
    name: z.string(),
    phoneNumber: z.string(),
    address: z.string(),
    password: z.string(),
    confirmPassword: z.string(),
  }),
});

const redeemBoardInvitationStateSchema =
  completeRedeemBoardInvitationStateSchema.deepPartial().extend({
    step: z.union([z.literal('password'), z.literal('account')]).optional(),
  });

export type RedeemBoardInvitationState = z.infer<
  typeof redeemBoardInvitationStateSchema
>;
export type CompleteRedeemBoardInvitationState = z.infer<
  typeof completeRedeemBoardInvitationStateSchema
>;

export const parseRedeemBoardInvitationState = (data: unknown) =>
  redeemBoardInvitationStateSchema.parse(data);

export const parseCompleteRedeemBoardInvitationState = (data: unknown) =>
  completeRedeemBoardInvitationStateSchema.parseAsync(data);
