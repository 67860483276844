import type { EventFrom, Sender } from 'xstate';
import { createMachine } from 'xstate';
import type { StateFrom } from '../utils/StateFrom';
import type { MachineOptionsWithContextFrom } from '../utils/MachineOptionsWithContextFrom';
import { VerifyNewsletterSubscriptionPromiseResult } from '../promises/createVerifyNewsletterSubscriptionPromise';

type Services = {
  verifyNewsletterSubscriptionPromise: {
    data: VerifyNewsletterSubscriptionPromiseResult;
  };
};

export type Context = {
  tag: string;
};

export const machine = createMachine({
  predictableActionArguments: true,
  tsTypes: {} as import('./SubscribeMachine.typegen').Typegen0,
  schema: {
    context: {} as Context,
    services: {} as Services,
  },
  initial: 'init',
  states: {
    init: {
      invoke: {
        id: 'verifyNewsletterSubscriptionPromise',
        src: 'verifyNewsletterSubscriptionPromise',
        onDone: 'done',
        onError: 'error',
      },
    },
    done: {},
    error: {},
  },
});

type Machine = typeof machine;

export type SubscribeMachineState = StateFrom<Machine>;
export type SubscribeMachineSender = Sender<EventFrom<Machine>>;
export type SubscribeMachineOptions = MachineOptionsWithContextFrom<Machine>;
