import type {
  Sender,
  EventFrom,
  MachineOptionsFrom,
  ActorRefFrom,
} from 'xstate';
import { createMachine } from 'xstate';
import { StateFrom } from '../../utils/StateFrom';
import { FundingRequest } from '../../schemas/fundingRequests/fundingRequestSchema';
import { ExpenseBlockMachineActor } from './ExpenseBlockMachine';
import {
  closeFundingRequest,
  CloseFundingRequest,
} from '../../events/CloseFundingRequest';
import { sendParent } from 'xstate/lib/actions';
import { ApplicationStatus } from '../../graphql/operations';
import {
  updateExpenses,
  UpdateExpenses,
} from '../../events/Expense/UpdateExpenses';
import {
  deleteExpense,
  DeleteExpense,
} from '../../events/Expense/DeleteExpense';

export type Events = CloseFundingRequest | UpdateExpenses | DeleteExpense;

export type Context = {
  request: FundingRequest;
  applicationStatus: ApplicationStatus;
  expenseBlockRef: ExpenseBlockMachineActor;
};

export const createContext = (
  request: FundingRequest,
  applicationStatus: ApplicationStatus,
  expenseBlockRef: ExpenseBlockMachineActor
): Context => ({
  request,
  applicationStatus,
  expenseBlockRef,
});

export const machine = createMachine(
  {
    predictableActionArguments: true,
    tsTypes:
      {} as import('./SubmittedFundingRequestCardMachine.typegen').Typegen0,
    schema: {
      context: {} as Context,
      events: {} as Events,
    },
    initial: 'ready',
    states: {
      ready: {
        on: {
          CLOSE_REQUEST: {
            actions: 'closeRequest',
          },
          UPDATE_EXPENSES: {
            actions: 'updateExpenses',
          },
          DELETE_EXPENSE: {
            actions: 'deleteExpense',
          },
        },
      },
    },
  },
  {
    guards: {},
    actions: {
      closeRequest: sendParent((_, event) => closeFundingRequest(event.id)),
      updateExpenses: sendParent((_, event) => updateExpenses(event.request)),
      deleteExpense: sendParent((_, event) => deleteExpense(event.id)),
    },
  }
);

type Machine = typeof machine;

export type SubmittedFundingRequestCardMachine = Machine;
export type SubmittedFundingRequestCardMachineState = StateFrom<Machine>;
export type SubmittedFundingRequestCardMachineSender = Sender<
  EventFrom<Machine>
>;
export type SubmittedFundingRequestCardMachineEvent = EventFrom<Machine>;
export type SubmittedFundingRequestCardMachineOptions = MachineOptionsFrom<
  Machine,
  true
>;
export type SubmittedFundingRequestCardMachineActor =
  ActorRefFrom<SubmittedFundingRequestCardMachine>;
