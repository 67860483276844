import { captureException } from '@sentry/react';

export function logError(
  e: Error,
  context?: { extra: Record<string, unknown> }
) {
  if (import.meta.env.MODE === 'development') {
    context ? console.error(e, context) : console.error(e);
  }
  captureException(e, context);
}

export function logMachineError(
  _: unknown,
  { data, type }: { data: unknown; type?: string }
) {
  if (data instanceof Error) {
    logError(data);
  } else {
    logError(new Error(type ? `Error in ${type}` : 'Unknown machine error'), {
      extra: { data: JSON.stringify(data) },
    });
  }
}
