import { useNavigate, useParams } from 'react-router-dom';
import {
  machine as PasswordMachine,
  createPasswordResumeService,
  createPasswordSaveActions,
} from '../../machines/RedeemInvitation/PasswordMachine';
import {
  machine as AccountMachine,
  createAccountResumeService,
  createAccountSaveActions,
} from '../../machines/RedeemInvitation/AccountMachine';
import {
  machine as TransferInvitationMachine,
  createTransferResumeService,
  createTransferSaveActions,
} from '../../machines/RedeemArtistInvitation/TransferMachine';

import { RedeemArtistInvitationController } from './RedeemArtistInvitationController';
import { useGraphQLClient } from '../../components/GraphQLClientProvider';
import { createGetRedeemArtistInvitationContext } from '../../promises/getRedeemArtistInvitationContext';
import { createMemoryRedeemArtistInvitationStorage } from '../../machines/RedeemArtistInvitation/storage';
import { redeemArtistInvitationPromise } from '../../promises/redeemInvitationPromise';
import { linkArtistInvitationPromise } from '../../promises/linkInvitationPromise';
import { updateArtistInvitationPromise } from '../../promises/updateArtistInvitationPromise';
import {
  parseCompleteRedeemArtistInvitationState,
  parseCompleteTransferArtistInvitationState,
} from '../../machines/RedeemArtistInvitation/state';
import {
  createResumeService,
  createRestartService,
  createSaveActions,
} from '../../machines/RedeemArtistInvitation/Machine';
import { createBrowserRedeemArtistInvitationHistory } from '../../machines/RedeemArtistInvitation/history';
import { useAuth } from '../../components/AuthProvider';
import { withDelay } from '../../utils/withDelay';

const storage = createMemoryRedeemArtistInvitationStorage();
const history = createBrowserRedeemArtistInvitationHistory();

export const RedeemArtistInvitation = () => {
  const client = useGraphQLClient();
  const navigate = useNavigate();
  const tag = useParams().tag ?? '';
  const { signIn } = useAuth();

  return (
    <RedeemArtistInvitationController
      options={{
        actions: {
          signIn: (_, { data }) => {
            if (data.message === 'signed-in') {
              signIn(data.id, data.roles, data.artists);
              navigate('/artists');
            }
          },
          ...createSaveActions(storage, history),
        },
        services: {
          resume: createResumeService(storage),
          restart: createRestartService(storage, history),
          getRedeemArtistInvitationContext: withDelay(
            createGetRedeemArtistInvitationContext(client, { tag })
          ),
          redeemArtistInvitation: withDelay(() =>
            parseCompleteRedeemArtistInvitationState(storage.get()).then(
              redeemArtistInvitationPromise(tag)
            )
          ),
          transferArtistInvitation: withDelay(() =>
            parseCompleteTransferArtistInvitationState(storage.get()).then(
              updateArtistInvitationPromise(client)(tag)
            )
          ),
          linkArtistInvitation: withDelay(linkArtistInvitationPromise(tag)),
          transferMachine: TransferInvitationMachine.withConfig({
            services: {
              resume: createTransferResumeService(storage),
            },
            actions: createTransferSaveActions(storage),
          }),
          passwordMachine: PasswordMachine.withConfig({
            services: {
              resume: createPasswordResumeService(storage),
            },
            actions: createPasswordSaveActions(storage),
          }),
          accountMachine: AccountMachine.withConfig({
            services: { resume: createAccountResumeService(storage) },
            actions: createAccountSaveActions(storage),
          }),
        },
      }}
    />
  );
};
