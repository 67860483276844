import { z } from 'zod';
import { ZonedDateTime } from '@internationalized/date';

export const localDateTimeSchema = z.instanceof(ZonedDateTime).nullable();

export const serverDateTimeSchema = z
  .string()
  .datetime({ offset: true })
  .nullable();

export type LocalDateTime = z.infer<typeof localDateTimeSchema>;
