import { CalendarDate } from '@internationalized/date';
import { formatCalendarDate } from '../../utils/formatCalendarDate';

import styles from './NoMeetings.module.scss';

interface NoMeetingsProps {
  nextMeetingDate?: CalendarDate | null;
}

export function NoMeetings({ nextMeetingDate }: NoMeetingsProps) {
  return (
    <div className={styles.container}>
      <h1>Welcome to Starmaker!</h1>
      <p>
        Currently, there are no active board meetings.
        {nextMeetingDate
          ? ` The next board meeting is
        scheduled for ${formatCalendarDate(nextMeetingDate)}.`
          : ''}
      </p>
    </div>
  );
}
