import cn from 'classnames';
import { Icon } from '../components/Icon';
import { Pagination } from './Pagination';
import { OptionalPropGroup } from '../utils/OptionalPropGroup';
import OrionLogo from '../assets/images/logo-orion.svg';

import styles from './PageHeader.module.scss';

type CloseButtonProps = OptionalPropGroup<{
  includeCloseButton: boolean;
  closeButtonOnClick: () => void;
}>;

type BackButtonProps = OptionalPropGroup<{
  includeBackButton: boolean;
  backButtonOnClick: () => void;
}>;

type Props = {
  heading?: string;
  paginationSteps?: number;
  paginationActive?: number;
  wide?: boolean;
  children?: React.ReactNode;
  isOrion?: boolean;
} & CloseButtonProps &
  BackButtonProps;

export function PageHeader(props: Props) {
  const compensatePadding =
    !props.paginationSteps && !props.paginationActive && !props.children;

  return (
    <div
      className={cn(styles.headingBar, {
        'max-w-lg': !props.wide,
        [styles.extraPadding]: compensatePadding,
      })}
    >
      <div
        className={cn(styles.headingAndControls, {
          [styles.orion]: props.isOrion,
        })}
      >
        <div className={styles.back}>
          {props.includeBackButton && (
            <button
              type="button"
              className={styles.iconButton}
              aria-label="back"
              onClick={props.backButtonOnClick}
            >
              <Icon name="leftChevron" />
            </button>
          )}
        </div>
        {props.isOrion && <img src={OrionLogo} alt="Orion Logo" />}
        {!props.isOrion && props.heading && (
          <div className={cn(styles.heading)}>
            <h1>{props.heading}</h1>
          </div>
        )}
        <div className={styles.close}>
          {props.includeCloseButton && (
            <button
              type="button"
              className={styles.iconButton}
              aria-label="close"
              onClick={props.closeButtonOnClick}
            >
              <Icon name="x" />
            </button>
          )}
        </div>
      </div>
      {props.isOrion && props.heading && (
        <div className={(styles.heading, styles.center)}>
          <h1>{props.heading}</h1>
        </div>
      )}
      {props.paginationSteps && props.paginationActive && (
        <div className={styles.pagination}>
          <Pagination
            steps={props.paginationSteps}
            active={props.paginationActive}
          />
        </div>
      )}
      {props.children && <div className="body-text">{props.children}</div>}
    </div>
  );
}
