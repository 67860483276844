import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { FundingRoundStatusType } from '../../graphql/operations';
import cn from 'classnames';
import { CalendarDate } from '@internationalized/date';

import styles from './ScheduleTable.module.scss';

interface Round {
  title: string;
  endDate: string;
  boardMeetingDate: CalendarDate;
  status: FundingRoundStatusType;
}
interface RoundTableProps {
  rounds: Round[];
}

const format = (value: Date) =>
  value.toLocaleDateString('en-CA', {
    month: 'long',
    day: 'numeric',
    year: 'numeric',
  });

const columnHelper = createColumnHelper<Round>();

const columns = [
  columnHelper.accessor('title', {
    header: () => <span>Round&nbsp;#</span>,
    cell: (info) => <span className={styles.heading}>{info.getValue()}</span>,
  }),
  columnHelper.accessor('endDate', {
    header: 'Application Deadline Date',
    cell: (info) => format(new Date(info.getValue())),
  }),
  columnHelper.accessor('boardMeetingDate', {
    header: 'Board Meeting Date',
    cell: (info) => format(info.getValue().toDate('America/Toronto')),
  }),
];

export function ScheduleTable({ rounds }: RoundTableProps) {
  const table = useReactTable({
    data: rounds,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <table className={styles.table}>
      <thead className={styles.headings}>
        {table.getHeaderGroups().map((headerGroup) => (
          <tr key={headerGroup.id}>
            {headerGroup.headers.map((header) => (
              <th key={header.id}>
                {header.isPlaceholder
                  ? null
                  : flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody>
        {table.getRowModel().rows.map((row) => (
          <tr
            key={row.id}
            className={cn({
              [styles.active]:
                row.original.status ===
                FundingRoundStatusType.OpenForApplications,
            })}
          >
            {row.getVisibleCells().map((cell) => (
              <td key={cell.id}>
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
}
